/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./jackpot-counter.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./jackpot-counter.component";
import * as i3 from "../../../shared/pipes/currency-format.pipe";
var styles_JackpotCounterComponent = [i0.styles];
var RenderType_JackpotCounterComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_JackpotCounterComponent, data: {} });
export { RenderType_JackpotCounterComponent as RenderType_JackpotCounterComponent };
export function View_JackpotCounterComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { animatedDigit: 0 }), (_l()(), i1.ɵeld(1, 0, [[1, 0], ["animatedDigit", 1]], null, 0, "div", [], null, null, null, null, null))], null, null); }
export function View_JackpotCounterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-jackpot-counter", [], null, null, null, View_JackpotCounterComponent_0, RenderType_JackpotCounterComponent)), i1.ɵdid(1, 4767744, null, 0, i2.JackpotCounterComponent, [i3.CurrencyFormatPipe], null, null)], null, null); }
var JackpotCounterComponentNgFactory = i1.ɵccf("app-jackpot-counter", i2.JackpotCounterComponent, View_JackpotCounterComponent_Host_0, { currencySymbol: "currencySymbol", duration: "duration", digit: "digit", steps: "steps" }, {}, []);
export { JackpotCounterComponentNgFactory as JackpotCounterComponentNgFactory };
