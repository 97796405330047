<ng-container
  *marketBasedComponentDisplay="{'flowType': UserFlowTypes.ZIMPLER_FLOW,'langCode': languageCode }"
>
  <div *ngIf="isNewsTickerBannerAvailable" class="page-body no-padding">
    <app-news-ticker></app-news-ticker>
  </div>
</ng-container>

<div class="app-container page-body homepage padding">
  <ng-container *ngIf="languageCode">
    <app-custom-banner
      class="clearfix"
      [zoneId]="(zimplerMarketsList.indexOf(languageCode) <= -1)?activeLobbyName:'livecasino_banner_HIR'"
    ></app-custom-banner>
  </ng-container>

  <ng-container *ngIf="!languageCode">
    <div class="stz-banner clearfix">
      <div class="stz-banner__item">
        <div class="mobile-banner">
          <div class="banner-media skeleton-loader"></div>
        </div>
        <div class="desktop-banner">
          <div class="banner-body">
            <div class="banner-media skeleton-loader"></div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <app-game-lobby
    class="clearfix"
    [callingFrom]="'liveCasinoLobby'"
    (callOnSelectedLobby)="onSelectedLobby($event)"
  ></app-game-lobby>
  <div class="liveCasino liveCasino__wrapper clearfix">
    <div class="liveCasino__games-container">
      <ng-container *ngIf="finalGamesList && finalGamesList.length > 0 && !isLoading">
        <app-live-casino-group-display
          class="liveCasino__game-group clearfix"
          [finalLobbyGames]="finalLobbyGamesList"
          [lobbyProviderData]="activeLobbyProviderData"
          [groupData]="gameGGroupfilterByLobbyList[0]"
          [games]="finalGamesList"
          [activeLobbyName]="activeLobbyName"
        ></app-live-casino-group-display>
      </ng-container>
      <ng-container
        *ngIf="
        finalGamesList && 
          finalGamesList.length === 0 && !isLoading && activeLobbyName === 'all-live' && isLastPlayedLiveGamesExist"
      >
        <app-live-casino-play-again
          class="playAgain__wrapper"
        ></app-live-casino-play-again>
      </ng-container>
      <div
        *ngIf="
        finalGamesList && 
        finalGamesList.length === 0 && 
        !isLoading && isLoaded && 
        ((activeLobbyName === 'all-live' && !isLastPlayedLiveGamesExist) || activeLobbyName !== 'all-live')"
      >
        <div class="no-results text-center">
          <img
            src="/assets/img/logo.svg"
            class="p-16 no-results__logo"
            alt="{{'general.site_name' | translate}}"
            title="{{'general.site_name' | translate}}"
            width="100"
            height="100%"
          />
          <p>{{'gamegroup.nogame_found' | translate}}</p>
        </div>
      </div>
      <div class="liveCasino__game-group" *ngIf="isLoading">
        <div
          class="game-card livecasino-card noHoverOnSkelton"
          *ngFor="let number of  [].constructor(15)"
        >
          <div class="game-card__body">
            <div class="game-card__mask skeleton-loader"></div>
          </div>
          <div class="livecasino-card__footer">
            <div
              class="livecasino-card--game-name skeleton-loader round-skelton"
            ></div>
            <div
              class="livecasino-card--provider-name skeleton-loader round-skelton"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <div class="liveCasino__side-widgets">
      <app-provider-filter
        class="liveCasino__side-widgets--item"
        (callOnProvidersChanged)="onProviderListChanged($event)"
      ></app-provider-filter>
      
      <app-provider-lobby
        class="liveCasino__side-widgets--item"
        [games]="finalLobbyGamesList"
        *ngIf="finalLobbyGamesList && finalLobbyGamesList.length > 0"
        [lobbyProviderData]="activeLobbyProviderData"
      ></app-provider-lobby>
    </div>
  </div>

  <div
    class="liveCasino__seo clearfix"
    *ngIf="activeLobbyData && activeLobbyData.content"
    [innerHtml]="activeLobbyData.content"
  ></div>
</div>
