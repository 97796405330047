import { BehaviorSubject, Subscription } from "rxjs";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";

// Environments
import { environment } from "src/environments/environment";

// Libraries
import {
  LivespinsPropertiesConfigurations,
  addScriptDynamically,
  LivespinsSDKClient,
  LivespinsSDK,
} from "livespins/@livespins";

// Reducers
import { AppState } from "src/app/store/reducers";

// Selectors
import { selectLanguageCode } from "src/app/modules/multi-languages/store/selectors/languages.selectors";
import { selectAuthLoginIsLoggedIn } from "src/app/modules/auth/store/selectors/auth.selectors";

// Services
import { MultiLanguageService } from "src/app/modules/multi-languages/services/multi-language.service";
import { SessionService } from "src/app/modules/auth/services/session.service";

@Injectable({
  providedIn: "root",
})
export class LivespinsService {
  // Strings
  languageCode: string = "";

  // Booleans
  isInitStarted: boolean = false;

  // Others
  public static langCodeToLivespinsLanguageCode: Record<string, string> = {
    "en-row": "en",
    "en-ca": "en",
    "en-nz": "en",
    "en-eu": "en",
    "de-de": "de",
    "nb-no": "no",
    "fi-fi": "fi",
    "hu-hu": "hu",
  };

  // Subjects
  clientSubject: BehaviorSubject<LivespinsSDKClient | null> =
    new BehaviorSubject<LivespinsSDKClient | null>(null);
  sdkSubject: BehaviorSubject<LivespinsSDK | null> =
    new BehaviorSubject<LivespinsSDK | null>(null);

  // Subscriptions
  languageCodeSubscription: Subscription;
  subscription: Subscription;

  constructor(
    private multiLanguageService: MultiLanguageService,
    private sessionService: SessionService,
    private store: Store<AppState>
  ) {
    this.languageCode = this.multiLanguageService.getLanguageCode();

    this.languageCodeSubscription = this.store
      .select(selectLanguageCode)
      .subscribe((languageCode: string) => {
        this.languageCode = languageCode;
      });
  }

  // ----------------------------------------------------------
  // Get Methods
  get client(): LivespinsSDKClient | null {
    return this.clientSubject.value;
  }

  get sdk(): LivespinsSDK | null {
    return this.sdkSubject.value;
  }

  // ----------------------------------------------------------
  // Set Methods
  onInitialiseLivespinsSDK(): void {
    // This can be called multiple times, but we only need to initialise the
    // client once.
    this.isInitStarted = true;

    document.addEventListener("LIVESPINS_LOADED", () => {
      console.log("Livespins SDK module ready to be used 💪", window.livespins);

      let tenant: string = "";

      switch (this.languageCode) {
        case "fi-fi":
        case "en-nz":
        case "en-ca": {
          tenant = "HIREE";
          break;
        }
        default: {
          tenant = "HIR";
          break;
        }
      }

      let livespinsConfigurations: LivespinsPropertiesConfigurations = {
        ...environment.livespins.configurations,
        tenant,
      };

      const livespinsSDK: LivespinsSDK = window.livespins.sdk({
        ...livespinsConfigurations,
      });

      this.sdkSubject.next(livespinsSDK);

      livespinsSDK.Client().then((client: LivespinsSDKClient) => {
        console.log({ client });

        this.clientSubject.next(client);
      });
    });

    addScriptDynamically("https://assets.livespins.com/sdk/js/livespins.js");
  }

  onInitialise(): void {
    if (!this.isInitStarted && this.sessionService.getIsUserLoggedIn()) {
      this.onInitialiseLivespinsSDK();
    }

    this.subscription = this.store
      .select(selectAuthLoginIsLoggedIn)
      .subscribe((isLoggedIn: boolean) => {
        if (!this.isInitStarted && isLoggedIn) {
          this.onInitialiseLivespinsSDK();
        }
      });
  }

  // -----------------------------------------------------------------
  // On Destroy
  ngOnDestroy(): void {
    if (this.languageCodeSubscription)
      this.languageCodeSubscription.unsubscribe();

    if (this.subscription) this.subscription.unsubscribe();
  }
}
