<div
  class="payment-bonus swiper__outer clearfix"
  *marketBasedComponentDisplay="{
    'flowType': UserFlowTypes.NORMAL_FLOW,
    'langCode': languageCode
  }"
>
  <swiper
    class="mySwiper swiperPaymentBonus clearfix swiper-control"
    [config]="swiperPaymentBonus"
    [(index)]="selectedBonusIndex"
    watchSlidesProgress
  >
    <ng-container *ngIf="campaignDetailsList">
      <div
        class="payment-bonus__list"
        *ngFor="let bonus of campaignDetailsList;let i=index"
      >
        <app-cashback-promo-reward
          [bonusDetails]="bonus"
          [callingFrom]="'payment-bonus'"
          [selectedBonus]="selectedBonus"
          (callOnBonusSelected)="onGetPromoCashback()"
          [rewardSize]="rewardSize"
          (callOpenRewardTCPopUp)="onOpenRewardTCPopUp($event)"
        >
        </app-cashback-promo-reward>
      </div>
    </ng-container>
    <div
      class="payment-bonus__list"
      *ngFor="let bonus of depositBonusDetailsList;let i=index"
    >
      <app-deposit-reward-card
        [bonusDetails]="bonus"
        [callingFrom]="callingFrom"
        [selectedBonus]="selectedBonus"
        [rewardSize]="rewardSize"
        (callOnBonusSelected)="onSelectBonus($event)"
        (callOpenRewardTCPopUp)="onOpenRewardTCPopUp($event)"
        (callOnNavigateToDeposit)="onNavigateToDeposit()"
      >
      </app-deposit-reward-card>
    </div>
  </swiper>
  <div
    class="paybonus-pagination swipper-pagination clearfix"
  ></div>
</div>

<!-- PNP flow bonus cards -->
<ng-container
  *marketBasedComponentDisplay="{
    'flowType': UserFlowTypes.ZIMPLER_FLOW,
    'langCode': languageCode
  }"
>
  <ng-container *ngIf="!isLoading">
    <h3
      class="pnp-bonuses--title"
      *ngIf="rewardSize === 'basic'"
    >
      {{'rewards.add_bonus_popup_title' | translate}}
    </h3>
    <ng-container *ngIf="campaignDetailsList">
      <div
        class="payment-bonus__list"
        *ngFor="let bonus of campaignDetailsList;let i=index"
      >
        <app-cashback-promo-reward
          [bonusDetails]="bonus"
          [rewardSize]="rewardSize"
          [callingFrom]="callingFrom"
          [selectedBonus]="selectedBonus"
          (callOnBonusSelected)="onGetPromoCashback()"
          (callOnNavigateToDeposit)="onNavigateToDeposit()"
          (callOpenRewardTCPopUp)="onOpenRewardTCPopUp($event)"
        >
        </app-cashback-promo-reward>
      </div>
    </ng-container>
    <div
      class="isPNPBonuses"
      [class.m-b-16]="rewardSize === 'basic'"
      *ngFor="let bonus of depositBonusDetailsList;let i=index"
    >
      <app-deposit-reward-card
        [bonusDetails]="bonus"
        [callingFrom]="callingFrom"
        [selectedBonus]="selectedBonus"
        [rewardSize]="rewardSize"
        (callOnBonusSelected)="onSelectBonus($event)"
        (callOpenRewardTCPopUp)="onOpenRewardTCPopUp($event)"
        (callOnNavigateToDeposit)="onNavigateToDeposit()"
      >
      </app-deposit-reward-card>
    </div>

    <div
      class="pnp-bonuses--cancel-link"
      *ngIf="rewardSize === 'basic'"
      (click)="onNavigateToDeposit()"
    >
      {{'rewards.no_thanks_continue_deposit'| translate}}
    </div>
  </ng-container>

  <p
    class="pnp-bonuses--loader valign-center"
    [class.j1]="rewardSize === 'shorter'"
    *ngIf="isLoading"
  >
    <app-loader></app-loader>
    <br />{{ 'general.loading' | translate}}
  </p>
</ng-container>
