import { map, catchError, tap, share } from "rxjs/operators";
import { BehaviorSubject, throwError, Subject, of, } from "rxjs";
// API Interactors
import { ApiInteractors } from "src/app/models/interactors/api.interactor";
// Enums
import { StatusResponse } from "src/app/models/api/status.response";
import { Project } from "src/app/models/environments/project.enum";
// Libraries
import * as _ from "underscore";
// Selectors
import { selectAuthLoginIsLoggedOut, selectAuthLoginIsLoggedIn, } from "src/app/modules/auth/store/selectors/auth.selectors";
// Utilities
import { getProcessGameGroup } from "src/app/modules/game-groups/utilities/process-game-group.utilities";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@ngrx/store";
export class GameGroupsService {
    constructor(httpClient, store) {
        this.httpClient = httpClient;
        this.store = store;
        // Booleans
        this.isLoggedIn = false;
        // Arrays of Objects
        this.lobbyWithGameGroupsList = [];
        this.lastPlayedGamesList = [];
        this.gameList = [];
        // Others
        this.providerLevelGamesList = {};
        // --------------------------------------------------------
        // Subject - Is Play Again Clicked
        this.isPlayAgainClickedSubject = new Subject();
        this.isPlayAgainClickedSubject$ = this.isPlayAgainClickedSubject.asObservable();
        // --------------------------------------------------------
        // Behaviour Subject - Is Live Games Last Played Available
        this.isLiveGamesLastPlayedAvailableBehaviorSubject = new BehaviorSubject(false);
        this.isLiveGamesLastPlayedAvailableBehaviorSubject$ = this.isLiveGamesLastPlayedAvailableBehaviorSubject.asObservable();
        // --------------------------------------------------------
        // Subscriptions
        this.subscriptions = [];
        this.apiInteractor = new ApiInteractors(this.httpClient);
        this.subscriptions = [
            this.store
                .select(selectAuthLoginIsLoggedIn)
                .subscribe((isLoggedIn) => (this.isLoggedIn = isLoggedIn)),
            this.store
                .select(selectAuthLoginIsLoggedOut)
                .subscribe((isLoggedOut) => {
                if (isLoggedOut) {
                    this.isLoggedIn = false;
                }
            }),
        ];
    }
    // -----------------------------------------------------------------
    // Get Methods
    /*****************************************Get Game group by lobby*********************************************** */
    /*
      Below Function Iternates through gameGroups vs games Data & filter each and every gameGroup related
      games & push into it related game group object
    
      So when we run this below code final output will be all the game groupes and it's related games as
      array with the group object will be returned.
    */
    getProcessGameGroupGames(lobbyGameGroupList, games, lastPlayedGames = [], allFavoriteGames = []) {
        return getProcessGameGroup(lobbyGameGroupList, games, lastPlayedGames, allFavoriteGames, this.isLoggedIn);
    }
    // -----------------------------------------------------------------
    // Get Methods - Observables - Get All Games List
    onGetGamePregmaticList(isForce) {
        if (!_.isEmpty(this.gameList) || isForce) {
            return of(this.gameList);
        }
        else {
            return this.apiInteractor
                .get(`/ajax/game/getLobbyGameGroupGames`)
                .pipe(tap((gameList) => {
                if (gameList && gameList.length > 0) {
                    this.gameList = gameList;
                }
            }), map((gameList) => {
                return gameList;
            }), catchError((error) => {
                return throwError(error);
            }), share());
        }
    }
    // -----------------------------------------------------------------
    // Get Methods - Observables - Get Favourite Games List
    /*
      Mostly after we call toggleFavorite api call...we need to pass true flag
      to get updated data from backoffice...
    */
    onGetFavoriteGamesList(isForce = false) {
        if (!_.isEmpty(this.favouriteGameList) && !isForce) {
            return of(this.favouriteGameList);
        }
        else {
            return this.apiInteractor
                .post(`/ajax/profile/getFavouriteGames`)
                .pipe(tap((favouriteGameList) => {
                if (favouriteGameList.favorite &&
                    favouriteGameList.favorite.favoriteGamesList.length > 0) {
                    this.favouriteGameList = favouriteGameList;
                }
            }), map((favouriteGameList) => {
                return favouriteGameList;
            }), catchError((error) => {
                return throwError(error);
            }), share());
        }
    }
    // -----------------------------------------------------------------
    // Get Methods - Observables - Get Last Played Games List
    onGetLastedPlayedGamesList(isForce = false) {
        if (isForce) {
            this.lastPlayedGamesList = undefined;
        }
        if (!_.isEmpty(this.lastPlayedGamesList) && !isForce) {
            return of(this.lastPlayedGamesList);
        }
        else {
            return this.apiInteractor
                .get(`/ajax/profile/getLastPlayedGames`, null, Project.Shotz)
                .pipe(tap((lastPlayedGamesList) => {
                if (lastPlayedGamesList &&
                    lastPlayedGamesList.status === StatusResponse.SUCCESS &&
                    lastPlayedGamesList.gameDetails.length > 0) {
                    this.lastPlayedGamesList = lastPlayedGamesList.gameDetails;
                }
                else {
                    this.lastPlayedGamesList = [];
                }
            }), map((lastPlayedGamesList) => {
                if (lastPlayedGamesList &&
                    lastPlayedGamesList.status === StatusResponse.SUCCESS &&
                    lastPlayedGamesList.gameDetails.length > 0) {
                    return lastPlayedGamesList.gameDetails;
                }
                else {
                    return [];
                }
            }), catchError((error) => {
                return throwError(error);
            }), share());
        }
    }
    // -----------------------------------------------------------------
    // Get Methods - Observables - Get Last Played Games List
    onGetPromiseLobbyPregmaticList(isForce = false) {
        return new Promise((resolve, reject) => {
            if (!_.isEmpty(this.lobbyWithGameGroupsList) && !isForce) {
                resolve(this.lobbyWithGameGroupsList);
            }
            else {
                Promise.resolve(this.onGetLobbyPregmaticList().toPromise()).then((response) => {
                    if (response && response.length > 0) {
                        this.lobbyWithGameGroupsList = response;
                        resolve(this.lobbyWithGameGroupsList);
                    }
                });
            }
        });
    }
    onGetLobbyPregmaticList(isForce = false) {
        if (!_.isEmpty(this.lobbyWithGameGroupsList) && !isForce) {
            return of(this.lobbyWithGameGroupsList);
        }
        else {
            return this.apiInteractor
                .get(`/ajax/game/getLobbyListWithGameGroups`)
                .pipe(tap((response) => {
                this.lobbyWithGameGroupsList = response;
            }), map((response) => {
                return response;
            }), catchError((error) => {
                return throwError(error);
            }), share());
        }
    }
    // -----------------------------------------------------------------
    // Get Methods - Observables - Get Free Game
    onGetFreeGame(gameId) {
        return this.apiInteractor
            .get(`/ajax/launcher/getFreeGames`, {
            gameSymbol: gameId,
        })
            .pipe(map((freeGames) => {
            return freeGames;
        }), catchError((error) => {
            return throwError(error);
        }), share());
    }
    // -----------------------------------------------------------------
    // Get Methods - Promises
    /*
      This Methods Give each provider level Live , Non-Live & All games List
    */
    onGetPromiseProviderLevelGamesList() {
        return new Promise((resolve, reject) => {
            if (this.providerLevelGamesList &&
                Object.keys(this.providerLevelGamesList).length > 0) {
                resolve(this.providerLevelGamesList);
            }
            else {
                Promise.resolve(this.onGetGamePregmaticList().toPromise()).then((gamesList) => {
                    if (gamesList && gamesList.length > 0) {
                        _.each(gamesList, (game) => {
                            if (!this.providerLevelGamesList.hasOwnProperty(game.vendorCode)) {
                                this.providerLevelGamesList[game.vendorCode] = {
                                    vendorName: game.vendorName,
                                    "non-live-games": [],
                                    "live-games": [],
                                    all: [],
                                };
                            }
                            this.providerLevelGamesList[game.vendorCode].all.push(game);
                            if (game.gameType === "live_dealer" ||
                                game.gameType === "live_casino") {
                                this.providerLevelGamesList[game.vendorCode]["live-games"].push(game);
                            }
                            else {
                                this.providerLevelGamesList[game.vendorCode]["non-live-games"].push(game);
                            }
                        });
                    }
                    resolve(this.providerLevelGamesList);
                });
            }
        });
    }
    // -----------------------------------------------------------------
    // Set Methods - on Broad Is Play Again Clicked
    onBroadIsPlayAgainClicked(isPlayAgainClicked) {
        this.isPlayAgainClickedSubject.next(isPlayAgainClicked);
    }
    // -----------------------------------------------------------------
    // Set Methods - on Broad Is Play Again Clicked
    onLastPlayedLiveCasinoGameAvailable(isLiveGamesLastPlayedAvailable) {
        this.isLiveGamesLastPlayedAvailableBehaviorSubject.next(isLiveGamesLastPlayedAvailable);
    }
    // -----------------------------------------------------------------
    // On Destroy
    ngOnDestroy() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
}
GameGroupsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GameGroupsService_Factory() { return new GameGroupsService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.Store)); }, token: GameGroupsService, providedIn: "root" });
