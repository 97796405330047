// Configurations
import { localStorageKeys } from "src/app/modules/multi-languages/configurations/localstorage-keys.configurations";
// Environments
import { environment } from "src/environments/environment";
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
export class AffiliateService {
    constructor(router) {
        this.router = router;
        // Strings
        this.affiliateLangKey = `${environment.websiteCode}_affiliateLang`;
        this.trackerIdKey = `${environment.websiteCode}_trackerId`;
        this.bannerIdKey = `${environment.websiteCode}_bannerId`;
        this.referrerKey = `${environment.websiteCode}_referrer`;
        this.affliateIdKey = `${environment.websiteCode}_affId`;
    }
    // -----------------------------------------------------------------
    // Get Methods
    getIsUserLoggedIn() {
        const isUserLoggedIn = JSON.parse(localStorage.getItem(localStorageKeys.stz_user));
        return isUserLoggedIn ? true : false;
    }
    getAffiliateCookies(cookiekey) {
        const name = `${environment.websiteCode}_${cookiekey}=`;
        const ca = document.cookie.split(";");
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === " ") {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }
    getAffiliateData() {
        let affiliateData = {};
        if (this.getAffiliateCookies("bannerId")) {
            affiliateData.bannerId = this.getAffiliateCookies("bannerId");
        }
        if (this.getAffiliateCookies("affId")) {
            affiliateData.affiliateId = this.getAffiliateCookies("affId");
        }
        if (this.getAffiliateCookies("trackerId")) {
            affiliateData.trackerId = this.getAffiliateCookies("trackerId");
        }
        return affiliateData;
    }
    // -----------------------------------------------------------------
    // Set Methods
    onNavigateToLandingPage(params, userLanguage, path) {
        if (this.getIsUserLoggedIn()) {
            if (path !== undefined) {
                this.router.navigate([`${userLanguage}/${path}`], {
                    queryParams: params,
                });
            }
            else {
                this.router.navigate([`${userLanguage}/casino`], {
                    queryParams: params,
                });
            }
        }
    }
    onSetAffiliateCookie(affiliateId, affilateLanguage) {
        const date = new Date();
        date.setDate(date.getDate() + 30);
        const expires = `expires=${date.toUTCString()}`;
        if (affiliateId) {
            document.cookie = `${this.affliateIdKey}=${affiliateId};${expires};path=/`;
        }
        if (affilateLanguage) {
            document.cookie = `${this.affiliateLangKey}=${affilateLanguage};${expires};path=/`;
        }
    }
    onDeleteAffiliateCookies() {
        let expiration = "; expires=Thu, 01 Jan 1970 00:00:01 GMT ;path=/";
        this.onSetCookie(this.affliateIdKey, expiration);
        this.onSetCookie(this.bannerIdKey, expiration);
        this.onSetCookie(this.trackerIdKey, expiration);
        this.onSetCookie(this.referrerKey, expiration);
        this.onSetCookie(this.affiliateLangKey, expiration);
    }
    onSetCookie(key, value) {
        document.cookie = `${key}=${value}`;
    }
    onSetAffiliateCookies(affiliateId, trackerId, bannerId) {
        const date = new Date();
        date.setDate(date.getDate() + 30);
        const expires = `expires=${date.toUTCString()}`;
        if (affiliateId) {
            document.cookie = `${this.affliateIdKey}=${affiliateId};${expires};path=/`;
        }
        if (trackerId) {
            document.cookie = `${this.trackerIdKey}=${trackerId};${expires};path=/`;
        }
        if (bannerId) {
            document.cookie = `${this.bannerIdKey}=${bannerId};${expires};path=/`;
        }
        if (document.referrer &&
            !document.referrer.includes(window.location.origin)) {
            document.cookie = `${this.referrerKey}=${document.referrer};${expires};path=/`;
        }
    }
}
AffiliateService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AffiliateService_Factory() { return new AffiliateService(i0.ɵɵinject(i1.Router)); }, token: AffiliateService, providedIn: "root" });
