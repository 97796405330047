import { Subject, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
// API Interactors
import { ApiInteractors } from "src/app/models/interactors/api.interactor";
// Enums
import { Project } from "src/app/models/environments/project.enum";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class CashbackPromoService {
    constructor(httpClient) {
        this.httpClient = httpClient;
        // Numbers
        this.userLastAuthTime = 0;
        // Booleans
        this.isPromoOptnSuccess = false;
        // --------------------------------------------------------
        // Subject - Promo Cashback Optn Success
        this.promoCashbackOptnSuccessSubject = new Subject();
        this.promoCashbackOptnSuccessSubject$ = this.promoCashbackOptnSuccessSubject.asObservable();
        // --------------------------------------------------------
        // Subject - Stop Cashback Promo
        this.stopCashbackPromoSubject = new Subject();
        this.stopCashbackPromoSubject$ = this.stopCashbackPromoSubject.asObservable();
        // --------------------------------------------------------
        // Subject - Cashback Offer Amount
        this.cashbackOfferAmountSubject = new Subject();
        this.cashbackOfferAmountSubject$ = this.cashbackOfferAmountSubject.asObservable();
        // --------------------------------------------------------
        // Subject - Cashback Started Promo Popup
        this.cashbackStartedPromoPopupSubject = new Subject();
        this.cashbackStartedPromoPopupSubject$ = this.cashbackStartedPromoPopupSubject.asObservable();
        this.apiInteractor = new ApiInteractors(this.httpClient);
    }
    // -----------------------------------------------------------------
    // Get Methods
    getUserLastAuthTime() {
        return this.userLastAuthTime;
    }
    getCampaignDetails() {
        return this.campaignDetails;
    }
    // -----------------------------------------------------------------
    // Get Observables - Get User Campaigns
    onGetUserCampaigns() {
        return this.apiInteractor
            .get(`/ajax/bonus/getUserCampaigns`, null, Project.Shotz)
            .pipe(map((response) => {
            return response;
        }), catchError((error) => {
            return throwError(error);
        }));
    }
    // -----------------------------------------------------------------
    // Get Observables - Get Promo Configurations
    onGetPromoConfigurationsDetails(request) {
        return this.apiInteractor
            .get(`/ajax/bonus/getPromoConfigDetails`, request, Project.Shotz)
            .pipe(map((promoConfigurationsDetailsResponse) => {
            return promoConfigurationsDetailsResponse;
        }), catchError((error) => {
            return throwError(error);
        }));
    }
    // -----------------------------------------------------------------
    // Get Observables - Get Opt In
    onGetOptIn(optInRequest) {
        return this.apiInteractor
            .get(`/ajax/bonus/optIn`, optInRequest, Project.Shotz)
            .pipe(map((optInResponse) => {
            return optInResponse;
        }), catchError((error) => {
            return throwError(error);
        }));
    }
    // -----------------------------------------------------------------
    // Get Observables - Get Opt Out
    onGetOptOut(optOutRequest) {
        return this.apiInteractor
            .get(`/ajax/bonus/optOut`, optOutRequest, Project.Shotz)
            .pipe(map((optOutResponse) => {
            return optOutResponse;
        }), catchError((error) => {
            return throwError(error);
        }));
    }
    // -----------------------------------------------------------------
    // Get Observables - Get Player Promo Progress
    onGetPlayerPromoProgress(playerPromoProgressRequest) {
        return this.apiInteractor
            .get(`/ajax/bonus/getPlayerPromoProgress`, playerPromoProgressRequest, Project.Shotz)
            .pipe(map((playerPromoProgressResponse) => {
            return playerPromoProgressResponse;
        }), catchError((error) => {
            return throwError(error);
        }));
    }
    // -----------------------------------------------------------------
    // Set Methods - on Broadcast Promo Cashback Optn Success
    onBroadcastPromoCashbackOptnSuccess() {
        this.promoCashbackOptnSuccessSubject.next();
    }
    // -----------------------------------------------------------------
    // Set Methods - on Broadcast Stop Cashback Promo
    onBroadcastStopCashbackPromo(stopCashbackPromo) {
        this.stopCashbackPromoSubject.next(stopCashbackPromo);
    }
    // -----------------------------------------------------------------
    // Set Methods - on Broadcast Cashback Offer Amount
    onBroadcastCashbackOfferAmount(cashbackOfferAmount) {
        this.cashbackOfferAmountSubject.next(cashbackOfferAmount);
    }
    // -----------------------------------------------------------------
    // Set Methods - on Broadcast Cashback Offer Amount
    onSetUserLastAuthTime(lastLoginTimeInMilliseconds) {
        this.userLastAuthTime = lastLoginTimeInMilliseconds * 1000;
    }
    // -----------------------------------------------------------------
    // Set Methods - on Broadcast Cashback Started Promo Popup
    onBroadcastCashbackStartedPromoPopup(campaignDetailsItem) {
        this.cashbackStartedPromoPopupSubject.next(campaignDetailsItem);
    }
    // -----------------------------------------------------------------
    // Set Methods - on Set User Cashback Promo Info
    onSetCampaignDetails(campaignDetails) {
        this.campaignDetails = campaignDetails;
    }
}
CashbackPromoService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CashbackPromoService_Factory() { return new CashbackPromoService(i0.ɵɵinject(i1.HttpClient)); }, token: CashbackPromoService, providedIn: "root" });
