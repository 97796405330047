<div
  class="pending-withdraw__container swiper__outer clearfix"
  *ngIf="transactionsList && transactionsList.length > 0 && !isLoading"
>
  <swiper
    class="mySwiper swiperPendingWithdraw clearfix swiper-control"
    [config]="swiperPendingWithdraw"
    [class.m-b-16]="transactionsList.length > 1"
    watchSlidesProgress
  >
    <div
      class="pending-withdraw--list clearfix"
      *ngFor="let withdrawal of transactionsList;let i = index"
    >
      <div class="col-left">
        <span class="icon">
          <i class="icon-wallet"></i>
        </span>
        <span class="text">
          <h5>
            {{'pending_withdrawal.pendingWithdraw' |
            translate}}
          </h5>
          <p
            *marketBasedComponentDisplay="{
              'flowType': UserFlowTypes.ZIMPLER_FLOW,
              'langCode': languageCode
            }"
          >
            {{withdrawal.amountInEcrCurrency |
            currencyformat:currencySymbol}}
          </p>
          <p
            *marketBasedComponentDisplay="{
              'flowType': UserFlowTypes.NORMAL_FLOW,
              'langCode': languageCode
            }"
          >
            {{getAmountCurrencyFormat(withdrawal.amount)}}
          </p>
        </span>
      </div>
      <div class="col-right">
        <button
          aria-label="Cancel"
          class="btn btn-sm btn-primary btn-round"
          (click)="onOpenConfirmationPopup(withdrawal)"
        >
          {{'general.cancel' | translate}}
        </button>
      </div>
    </div>
  </swiper>
  <div class="swipper-pagination clearfix"></div>
</div>

<div class="pending-withdraw__container" *ngIf="isLoading">
  <div class="pending-withdraw--list clearfix">
    <app-loader class="m-12 m-auto"></app-loader>
  </div>
</div>

<section
  class="c-dialog__backdrop atLeftMenu"
  *ngIf="isPendingWithdrawConfirmationPopup"
>
  <div class="c-dialog">
    <div class="c-dialog__header">
      <img
        src="/assets/img/logo.svg"
        alt="{{'general.site_name' | translate}}"
        width="100%"
        height="100%"
      />
    </div>
    <div class="c-dialog__body">
      <p>
        {{'pending_withdrawal.confirmationMessage' |
        translate:selectedTransaction}}
        <ng-container
          *marketBasedComponentDisplay="{
            'flowType': UserFlowTypes.ZIMPLER_FLOW,
            'langCode': languageCode
          }"
        >
          <ng-container
            *ngIf="selectedTransaction && selectedTransaction.amountInEcrCurrency"
          >
            {{ selectedTransaction.amountInEcrCurrency |
            currencyformat:currencySymbol}}
          </ng-container>
        </ng-container>
      </p>
    </div>
    <div class="c-dialog__footer">
      <a
        aria-label="Yes"
        class="btn-yes"
        *marketBasedComponentDisplay="{
          'flowType': UserFlowTypes.NORMAL_FLOW,
          'langCode': languageCode
        }"
        (click)="onCancelTransaction(selectedTransaction.transactionId)"
      >
        {{'general.yes' | translate}}
      </a>

      <a
        aria-label="Yes"
        class="btn-yes"
        *marketBasedComponentDisplay="{
          'flowType': UserFlowTypes.ZIMPLER_FLOW,
          'langCode': languageCode
        }"
        (click)="onCancelPnPTransaction(selectedTransaction)"
      >
        {{'general.yes' | translate}}
      </a>

      <a
        aria-label="No"
        class="btn-no"
        (click)="onCloseConfirmationPopup()"
      >
        {{'general.no' | translate}}
      </a>
    </div>
  </div>
</section>
