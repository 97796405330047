import { createReducer, on } from "@ngrx/store";
// Actions
import { countryBlockLoaded } from "src/app/modules/country-block/store/actions/country-block.actions";
export const CountryBlockStateFeatureKey = "country-block-collection";
export const initialState = {
    countryBlockResponse: null,
};
const ɵ0 = (state, { countryBlockResponse }) => {
    return Object.assign({}, state, { countryBlockResponse });
};
// Reducer
const CountryBlockReducer = createReducer(initialState, on(countryBlockLoaded, ɵ0));
export function reducer(state, action) {
    return CountryBlockReducer(state, action);
}
export { ɵ0 };
