import { Component, HostListener } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Subject, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { Store } from "@ngrx/store";

// Actions
import {
  payNPlayLoginRequested,
  loginStatusRequested,
} from "src/app/modules/auth/store/actions/auth.actions";

// Components
import { FormValidationComponent } from "src/app/modules/shared/components/form-validation/form-validation.component";

// Configurations
import { localStorageKeys } from "src/app/modules/multi-languages/configurations/localstorage-keys.configurations";
import { languageToPnpConfigurations } from "src/app/configurations/main.configurations";

// Enums
import { WorldCountryCode } from "src/app/models/configurations/enums/localization/world-countries.enum";
import { StatusResponse } from "src/app/models/api/status.response";

// Environments
import { environment } from "src/environments/environment";

// Libraries
import * as _ from "underscore";
import * as $ from "jquery";

// Models
import { CashierMakeWalletWithdrwalResponse } from "src/app/modules/account/models/cashier-make-wallet-withdrawal/cashier-make-wallet-withdrawal-response.model";
import { CashierMakeWalletWithdrwalRequest } from "src/app/modules/account/models/cashier-make-wallet-withdrawal/cashier-make-wallet-withdrawal-request.model";
import { PayNPlayTransactionStatusResponse } from "src/app/modules/account/models/pay-n-play-transaction-status/pay-n-play-transaction-status-response.model";
import { PayNPlayFromTransactionResponse } from "src/app/modules/account/models/pay-n-play-from-transaction/pay-n-play-from-transaction-response.model";
import { PayNPlayFromTransactionRequest } from "src/app/modules/account/models/pay-n-play-from-transaction/pay-n-play-from-transaction-request.model";
import { QuickRegisterZimplerResponse } from "src/app/modules/account/models/quick-register-zimpler/quick-register-zimpler-response.model";
import { QuickRegisterZimplerRequest } from "src/app/modules/account/models/quick-register-zimpler/quick-register-zimpler-request.model";
import { ZimplerPayAndPlayResponse } from "src/app/modules/account/models/zimpler-pay-and-play/zimpler-pay-and-play-response.model";
import { ZimplerPayAndPlayRequest } from "src/app/modules/account/models/zimpler-pay-and-play/zimpler-pay-and-play-request.model";
import { PnPMinMaxLimitsRequest } from "src/app/modules/account/models/pnp-min-max-limits/pnp-min-max-limits-request.model";
import { PayNPlayLoginResponse } from "src/app/modules/account/models/pay-n-play-login/pay-n-play-login-response.model";
import { PayNPlayLoginRequest } from "src/app/modules/account/models/pay-n-play-login/pay-n-play-login-request.model";
import { CashierDetailsRequest } from "src/app/modules/account/models/cashier-details/cashier-details-request.model";
import {
  PayNPlayTransactionStatusRequest,
  TransactionStatusEnum,
} from "src/app/modules/account/models/pay-n-play-transaction-status/pay-n-play-transaction-status-request.model";
import { PnPMinMaxLimits } from "src/app/modules/account/models/pnp-min-max-limits/pnp-min-max-limits.model";
import { DataBeforeQuickRegister } from "src/app/modules/account/models/data-before-quick-register.model";
import { InitiateDeposit } from "src/app/modules/account/models/initiate-deposit/initiate-deposit.model";
import { GtmDepositTransaction } from "src/app/modules/shared/models/gtm/gtm-deposit-transaction.model";
import { UserBalanceDetails } from "src/app/modules/shared/models/profiles/user-balance-details.model";
import { ProfileBalance } from "src/app/modules/auth/models/profile-balance.model";
import { DepositBonus } from "src/app/modules/account/models/deposit-bonus.model";
import { LoginStatus } from "src/app/modules/auth/models/login-status.model";
import { UserData } from "src/app/modules/user/models/user-data.model";

// Reducers
import { AppState } from "src/app/store/reducers";

// Selectors
import { selectLanguageCode } from "src/app/modules/multi-languages/store/selectors/languages.selectors";
import {
  selectAuthPayNPlayLoggedIn,
  selectAuthLoginIsLoggedOut,
  selectAuthLoginIsLoggedIn,
  selectAuthLoginStatus,
} from "src/app/modules/auth/store/selectors/auth.selectors";

// Services
import { MultiLanguageService } from "src/app/modules/multi-languages/services/multi-language.service";
import { PayNPlayCashierService } from "src/app/modules/account/services/pay-n-play-cashier.service";
import { TranslationService } from "src/app/modules/multi-languages/services/translation.service";
import { UserDetailsService } from "src/app/modules/user/services/user-details.service";
import { DeepLinksService } from "src/app/modules/shared/services/deep-links.service";
import { AffiliateService } from "src/app/modules/auth/services/affiliate.service";
import { CashierService } from "src/app/modules/account/services/cashier.service";
import { EmitterService } from "src/app/modules/shared/services/emitter.service";
import { UtilityService } from "src/app/modules/shared/services/utility.service";
import { ProfileService } from "src/app/modules/user/services/profile.service";
import { SessionService } from "src/app/modules/auth/services/session.service";
import { GtmService } from "src/app/modules/shared/services/gtm.service";

// Validators
import { CharactersValidator } from "src/app/modules/validators/validators/characters.validators";

@Component({
  selector: "app-pay-n-play-cashier",
  templateUrl: "./pay-n-play-cashier.component.html",
  styleUrls: ["./pay-n-play-cashier.component.scss"],
})
export class PayNPlayCashierComponent extends FormValidationComponent {
  // Numbers
  requestLoopCount: number = 0;
  minDWLimitValue: number = 0;
  maxDWLimitValue: number = 0;
  depositAmount: number = 0;

  // Strings
  errorMaximumAmountNumber: string = "0";
  errorMinimumAmountNumber: string = "0";
  transactionSuccessMessage: string = "";
  paymentProcessStep: string = "step1";
  transactionErrorMessage: string = "";
  calledFromComponent: string = "";
  currencySymbol: string = "";
  languageCode: string = "";
  errorMessage: string = "";
  callingFrom: string = "";

  // Booleans
  isInitiateDepositTriggered: boolean = false;
  isClearSelectedBonus: boolean = false;
  isButtonLoader: boolean = false;
  isLoggedIn: boolean = false;
  isBonusData: boolean = true;
  isVisible: boolean = false;
  isFocus: boolean = false;

  // Enums
  windowType: "device" | "mobile" = "device";

  // Arrays
  linkList: string[] = [
    "bottomNavigation",
    "payment-rewards",
    "accountMenu",
    "all-rewards",
    "pnp-cashier",
    "leftMenu",
    "deepLink",
  ];

  // Forms
  zimplerForm: FormGroup;

  // Objects
  tnxDataBeforeQuickRegister: DataBeforeQuickRegister;
  selectedBonus: DepositBonus;

  // Timeouts
  timerInstance: NodeJS.Timer;

  // Subjects
  destroy$: Subject<boolean> = new Subject<boolean>();

  // Subscriptions
  userPayNPlayTransactionStatusSubscription: Subscription;
  profileBalanceCurrencySubscription: Subscription;
  pnpUserFromTransactionSubscription: Subscription;
  quickRegisterZimplerSubscription: Subscription;
  pnpMinMaxTxnLimitsSubscription: Subscription;
  zimplerPayAndPlaySubscription: Subscription;
  zimplerWithdrawalSubscription: Subscription;
  cashoutTxnLimitsSubscription: Subscription;
  profileBalanceSubscription: Subscription;
  payNPlayLoginSubscription: Subscription;
  subscription: Subscription;

  subscriptions: Subscription[] = [];

  constructor(
    private payNPlayCashierService: PayNPlayCashierService,
    private multiLanguageService: MultiLanguageService,
    private userDetailsService: UserDetailsService,
    private translationService: TranslationService,
    private affiliateService: AffiliateService,
    private deepLinksService: DeepLinksService,
    private profileService: ProfileService,
    private emitterService: EmitterService,
    private cashierService: CashierService,
    private sessionService: SessionService,
    private utilityService: UtilityService,
    private formBuilder: FormBuilder,
    private gtmService: GtmService,
    private store: Store<AppState>
  ) {
    super();

    this.zimplerForm = this.formBuilder.group({
      amount: ["", [Validators.required]],
    });
  }

  // -----------------------------------------------------------------
  // Lifecycle Hooks
  ngOnInit(): void {
    this.isLoggedIn = this.sessionService.getIsUserLoggedIn();

    this.languageCode = this.multiLanguageService.getLanguageCode();

    this.onGetPNPMinMaxTxnLimits();

    this.getWindowType();

    if (this.isLoggedIn) {
      this.onGetCashoutTxnLimits();
    }

    /*
      This call is need on refresh to check emial Id updated by user or not
      If not we open a popup & ask user to update it..
    */
    this.onLoginStatus();

    this.subscriptions = [
      this.userDetailsService.currencySymbolBehaviourSubject$
        .pipe(takeUntil(this.destroy$))
        .subscribe((currencySymbol: string) => {
          this.currencySymbol = currencySymbol;
        }),
      this.store
        .select(selectLanguageCode)
        .pipe(takeUntil(this.destroy$))
        .subscribe((languageCode: string) => {
          this.languageCode = languageCode;
        }),
      this.store
        .select(selectAuthLoginIsLoggedIn)
        .subscribe((isLoggedIn: boolean) => {
          this.isLoggedIn = isLoggedIn;

          if (this.isLoggedIn) {
            this.onGetCashoutTxnLimits();
          }
        }),
      this.store
        .select(selectAuthLoginIsLoggedOut)
        .subscribe((isLoggedOut: boolean) => {
          if (isLoggedOut) {
            this.isLoggedIn = false;
          }
        }),
      this.payNPlayCashierService.initiateTxnSubject$
        .pipe(takeUntil(this.destroy$))
        .subscribe((txnType: string) => {
          this.onInitializeZimplerPayAndPlay(txnType);
        }),
      this.payNPlayCashierService.initiateDepositSubject$
        .pipe(takeUntil(this.destroy$))
        .subscribe((initiateDeposit: InitiateDeposit) => {
          this.callingFrom = "deposit";

          this.isInitiateDepositTriggered = true;

          this.onInitializeDeposit(initiateDeposit);
        }),
      this.payNPlayCashierService.initiateWithdrawSubject$
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          this.callingFrom = "withdraw";

          this.paymentProcessStep = "step1";

          this.onUpdateLimits();

          this.onSetOverFlowToHidden();

          this.isInitiateDepositTriggered = true;
        }),
      this.payNPlayCashierService.processUserTransactionSubject$
        .pipe(takeUntil(this.destroy$))
        .subscribe((message: string) => {
          if (message && message === "deposit-transactionStatus") {
            this.paymentProcessStep = "step4";

            this.isInitiateDepositTriggered = true;

            this.onGetPNPUserFromTransaction();
          }

          if (message && message === "withdraw-transactionStatus") {
            this.paymentProcessStep = "step4";

            this.isInitiateDepositTriggered = true;

            this.onGetWithdrawalTransactionStatus();
          }
        }),
    ];
  }

  // -----------------------------------------------------------------
  // Host Listeners
  @HostListener("window:resize")
  onResize(): void {
    this.getWindowType();
  }

  @HostListener("window:orientationchange")
  onRotate(): void {
    this.getWindowType();
  }

  @HostListener("window:message", ["$event"])
  onMessage(messageEvent: MessageEvent): void {
    if (
      messageEvent &&
      messageEvent.data &&
      messageEvent.data.message === "deposit-transactionStatus"
    ) {
      this.paymentProcessStep = "step4";

      if (!this.isInitiateDepositTriggered) {
        this.isInitiateDepositTriggered = true;
      }

      this.onGetPNPUserFromTransaction();
    }

    if (
      messageEvent &&
      messageEvent.data &&
      messageEvent.data.message === "withdraw-transactionStatus"
    ) {
      this.paymentProcessStep = "step4";

      if (!this.isInitiateDepositTriggered) {
        this.isInitiateDepositTriggered = true;
      }

      this.onGetWithdrawalTransactionStatus();
    }
  }

  @HostListener("keydown", ["$event"])
  onKeydown(keyboardEvent: KeyboardEvent): void {
    if (document.getElementById("formInputValue")) {
      let inputValue: number =
        (document.getElementById("formInputValue") as HTMLInputElement).value
          .length - 1;

      if (keyboardEvent.which === 8 && inputValue <= 0) {
        this.isVisible = false;
      } else {
        this.isVisible = true;
      }
    }
  }

  // -----------------------------------------------------------------
  // Window Type
  getWindowType(): void {
    let clientWidth: number = document.body.clientWidth;

    if (clientWidth >= 1024) {
      this.windowType = "device";
    } else {
      this.windowType = "mobile";
    }
  }

  // -----------------------------------------------------------------
  // Get Methods
  getPayNPlayRequestData(txnType: string): ZimplerPayAndPlayRequest {
    let amount: number =
      txnType === "DEPOSIT" ? this.zimplerForm.controls["amount"].value : 0;

    /*
      If we have bonuscode we can pass below in the request object 
      & property name is bonusCode
      Please add below line for redirection url before you deploy
         successURL:environment.siteUrl+'/redirection.html',
         failureURL:environment.siteUrl+'/redirection.html',
         redirectURL:environment.siteUrl+'/redirection.html'
    */
    let zimplerPayAndPlayRequest: ZimplerPayAndPlayRequest = {
      amount,
      countryCode: WorldCountryCode.FI,
      language: languageToPnpConfigurations[this.languageCode],
      currencyCode: this.userDetailsService.getCurrencyCode(),
      site: environment.websiteName,
      siteDisplayName: environment.website,
      txnType: txnType,
      successURL: `${window.location.origin}/redirection.html?transactionType=deposit`,
      failureURL: `${window.location.origin}/redirection.html?transactionType=deposit`,
      redirectURL: `${window.location.origin}/redirection.html?transactionType=deposit`,
    };

    if (this.getActiveBonus()) {
      zimplerPayAndPlayRequest.bonusCode = this.getActiveBonus();
    }

    return zimplerPayAndPlayRequest;
  }

  getActiveBonus(): string {
    const bonusDetails: DepositBonus =
      this.cashierService.getActiveDepositBonus();

    if (bonusDetails && Object.keys(bonusDetails).length > 0) {
      return bonusDetails.bonusCode;
    }

    return "";
  }

  // -----------------------------------------------------------------
  // Set Methods
  onSetDecideDepositFlow(): void {
    if (this.callingFrom === "deposit") {
      this.onInitializeZimplerPayAndPlay();
    } else if (this.callingFrom === "withdraw") {
      this.onZimplerWithdrawal();
    }
  }

  onInitializeDeposit(initiateDeposit: InitiateDeposit): void {
    if (
      initiateDeposit &&
      this.linkList.indexOf(initiateDeposit.callingFrom) > -1
    ) {
      this.onClearIFrameData();

      this.paymentProcessStep = "step1";

      this.onUpdateLimits();

      this.isInitiateDepositTriggered = true;

      this.onSetOverFlowToHidden();

      if (this.isLoggedIn) {
        this.onSelectActiveBonus(true);
      }
    } else if (initiateDeposit.callingFrom === "customBanner") {
      this.paymentProcessStep = "step4";

      this.onUpdateLimits();

      this.zimplerForm.controls["amount"].setValue(initiateDeposit.amount);

      this.isInitiateDepositTriggered = true;

      if (this.isLoggedIn) {
        this.onShowBonusCards();
      } else {
        this.onInitializeZimplerPayAndPlay();
      }
    }
  }

  onUpdateLimits(): void {
    if (this.callingFrom === "deposit") {
      this.onUpdateDepositLimits();
    }

    if (this.callingFrom === "withdraw") {
      this.onUpdateWithdrawingLimits();
    }
  }

  onUpdateDepositLimits(): void {
    let pnPMinMaxLimits: PnPMinMaxLimits =
      this.payNPlayCashierService.depositLimits;

    if (pnPMinMaxLimits.minLimit && pnPMinMaxLimits.maxLimit) {
      this.onApplyMinMaxFromValidation(
        pnPMinMaxLimits.minLimit,
        pnPMinMaxLimits.maxLimit,
        "deposit"
      );
    } else {
      this.onGetPNPMinMaxTxnLimits();
    }
  }

  onUpdateWithdrawingLimits(): void {
    let withdrawLimits: PnPMinMaxLimits =
      this.payNPlayCashierService.cashoutLimits;

    if (withdrawLimits.minLimit && withdrawLimits.maxLimit) {
      this.onApplyMinMaxFromValidation(
        withdrawLimits.minLimit,
        withdrawLimits.maxLimit,
        "withdraw"
      );
    } else {
      this.onGetCashoutTxnLimits(true);
    }
  }

  onApplyMinMaxFromValidation(
    minLimit: number,
    maxLimit: number,
    callingFrom: string
  ): void {
    if (callingFrom === "withdraw") {
      let withdrawableBalance: number =
        this.userDetailsService.getWithdrawableBalance();

      this.zimplerForm.controls["amount"].setValidators(
        CharactersValidator.validateMinNumericValue(
          `${minLimit}`,
          `${maxLimit}`,
          callingFrom,
          withdrawableBalance
        )
      );
    } else {
      this.zimplerForm.controls["amount"].setValidators(
        CharactersValidator.validateMinNumericValue(
          `${minLimit}`,
          `${maxLimit}`,
          callingFrom
        )
      );
    }

    this.errorMaximumAmountNumber = `${maxLimit}`;

    this.errorMinimumAmountNumber = `${minLimit}`;
  }

  onLoginStatus(): void {
    this.store.dispatch(loginStatusRequested());

    this.subscription = this.store
      .select(selectAuthLoginStatus)
      .subscribe(
        (value: {
          isLoading: boolean;
          isLoaded: boolean;
          loginStatus: LoginStatus;
        }) => {
          if (value && value.isLoaded && value.loginStatus.status) {
            this.onVerifyEmailUpdate();
          }
        }
      );
  }

  onGetPNPMinMaxTxnLimits(): void {
    let pnpMinMaxLimitsRequest: PnPMinMaxLimitsRequest = {
      option: "ZIMPLER_DIRECT",
      currency: this.userDetailsService.getCurrencyCode(),
    };

    this.pnpMinMaxTxnLimitsSubscription = this.payNPlayCashierService
      .onGetPNPMinMaxTxnLimits(pnpMinMaxLimitsRequest)
      .pipe(takeUntil(this.destroy$))
      .subscribe((pnPMinMaxLimits: PnPMinMaxLimits) => {
        if (
          pnPMinMaxLimits &&
          pnPMinMaxLimits.minLimit &&
          pnPMinMaxLimits.maxLimit
        ) {
          this.onApplyMinMaxFromValidation(
            pnPMinMaxLimits.minLimit,
            pnPMinMaxLimits.maxLimit,
            "deposit"
          );
        }
      });
  }

  onGetCashoutTxnLimits(isApplyValidation: boolean = false): void {
    let cashierDetailsRequest: CashierDetailsRequest = {
      txnType: "cashout",
    };

    this.cashoutTxnLimitsSubscription = this.payNPlayCashierService
      .onGetCashoutTxnLimits(cashierDetailsRequest)
      .pipe(takeUntil(this.destroy$))
      .subscribe((cashOutData: PnPMinMaxLimits) => {
        if (cashOutData && cashOutData.minLimit && cashOutData.maxLimit) {
          this.minDWLimitValue = cashOutData.minLimit;

          this.maxDWLimitValue = cashOutData.maxLimit;

          if (isApplyValidation) {
            this.onApplyMinMaxFromValidation(
              cashOutData.minLimit,
              cashOutData.maxLimit,
              "withdraw"
            );
          }
        }
      });
  }

  onShowBonusCards(): void {
    this.paymentProcessStep = "step2";

    this.onSetOverFlowToHidden();
  }

  onInitializeZimplerPayAndPlay(txnType: string = "DEPOSIT"): void {
    this.isButtonLoader = true;

    this.onClearIFrameData();

    if (
      (this.zimplerForm.valid && txnType === "DEPOSIT") ||
      txnType === "LOGIN"
    ) {
      let zimplerPayAndPlayRequest: ZimplerPayAndPlayRequest =
        this.getPayNPlayRequestData(txnType);

      this.zimplerPayAndPlaySubscription = this.payNPlayCashierService
        .onZimplerPayAndPlay(zimplerPayAndPlayRequest)
        .pipe(takeUntil(this.destroy$))
        .subscribe((zimplerPayAndPlayResponse: ZimplerPayAndPlayResponse) => {
          this.isButtonLoader = false;

          if (
            zimplerPayAndPlayResponse.status === StatusResponse.SUCCESS &&
            zimplerPayAndPlayResponse.redirectURL
          ) {
            sessionStorage.setItem(
              "txnId",
              zimplerPayAndPlayResponse.txnId.toString()
            );

            if (this.windowType === "mobile") {
              window.open(zimplerPayAndPlayResponse.redirectURL, "_self");
            } else {
              this.paymentProcessStep = "step3";

              this.isInitiateDepositTriggered = true;

              setTimeout(() => {
                let $iframe = $(
                  `<iframe src="${zimplerPayAndPlayResponse.redirectURL}"></iframe>`
                );

                $(".zimplerIframeContainer").append($iframe);
              });

              this.onSetOverFlowToHidden();
            }

            if (txnType === "LOGIN") {
              this.gtmService.onTrackLoginGTMEvent("open");
            } else if (txnType === "DEPOSIT") {
              this.gtmService.onTrackDepositGTMEvent("open");
            }
          } else {
            this.isInitiateDepositTriggered = true;

            this.onSwitchToNextStep(
              "failure",
              "step5",
              "zimpler.something_went_wrong_redirection"
            );
          }
        });
    }
  }

  onClearIFrameData(): void {
    if ($(".zimplerIframeContainer")) {
      $(".zimplerIframeContainer").empty();
    }

    if (this.transactionErrorMessage) {
      this.transactionErrorMessage = "";
    }

    if (this.transactionSuccessMessage) {
      this.transactionSuccessMessage = "";
    }

    if (this.timerInstance) clearInterval(this.timerInstance);
  }

  onGetPNPUserFromTransaction(): void {
    this.onSetOverFlowToHidden();

    let payNPlayFromTransactionRequest: PayNPlayFromTransactionRequest = {
      txnId: +sessionStorage.getItem("txnId"),
    };

    this.pnpUserFromTransactionSubscription = this.payNPlayCashierService
      .onGetPNPUserFromTransaction(payNPlayFromTransactionRequest)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (payNPlayFromTransactionResponse: PayNPlayFromTransactionResponse) => {
          this.onRequestLooping(payNPlayFromTransactionResponse);
        }
      );
  }

  onRequestLooping(
    payNPlayFromTransactionResponse: PayNPlayFromTransactionResponse
  ): void {
    if (
      this.requestLoopCount < 5 &&
      payNPlayFromTransactionResponse &&
      payNPlayFromTransactionResponse.txnStatus === "KYC_VERIFIED"
    ) {
      setTimeout(() => {
        this.requestLoopCount++;

        this.onGetPNPUserFromTransaction();
      }, 3000);
    } else {
      this.requestLoopCount = 0;

      if (payNPlayFromTransactionResponse) {
        this.onNavigateBasedOnTxStatus(payNPlayFromTransactionResponse);
      }
    }
  }

  onNavigateBasedOnTxStatus(
    payNPlayFromTransactionResponse: PayNPlayFromTransactionResponse
  ): void {
    const { externalEcrId, txnStatus, errorCode, status } =
      payNPlayFromTransactionResponse;

    this.onVerifyAndPushGTMEvent(
      status,
      txnStatus,
      payNPlayFromTransactionResponse
    );

    if (status === StatusResponse.SUCCESS && externalEcrId) {
      this.onTransactionSuccess(txnStatus, payNPlayFromTransactionResponse);
    }

    if (status === StatusResponse.FAILURE && errorCode === 100159) {
      this.onTransactionFailure(txnStatus);
    }
  }

  onVerifyAndPushGTMEvent(
    status: StatusResponse,
    txnStatus: string,
    payNPlayFromTransactionResponse: PayNPlayFromTransactionResponse
  ): void {
    /*
      This IF case occur only for user deposit for first on our production
      At this time user is not yet registered
      so in this case we have persist data & push end of 3rd step
      step1:successful quick registration,
      step 2:successful login then after we can have to push data..
    */
    if (
      status === StatusResponse.FAILURE &&
      txnStatus === "DEPOSIT_SUCCESS" &&
      !localStorage.getItem(localStorageKeys.stz_user)
    ) {
      this.tnxDataBeforeQuickRegister = {
        txnStatus: txnStatus,
        txnId: payNPlayFromTransactionResponse.txnId,
        amount: payNPlayFromTransactionResponse.amount,
      };
    } else if (txnStatus !== "LOGIN_WITHOUT_DEPOSIT" && txnStatus !== "INIT") {
      const userProfileData: UserData =
        this.userDetailsService.getUserProfileDetails();

      if (userProfileData) {
        payNPlayFromTransactionResponse = {
          ...payNPlayFromTransactionResponse,
          ...{
            externalEcrId: userProfileData.playerID,
          },
        };
      }

      this.gtmService.onTrackDepositGTMEvent(
        status,
        payNPlayFromTransactionResponse
      );
    }
  }

  onTransactionSuccess(
    txnStatus: string,
    payNPlayFromTransactionResponse: PayNPlayFromTransactionResponse
  ): void {
    switch (txnStatus) {
      case "CASHIER_INIT_DEPOSIT_SUCCESS":
      case "CASHIER_TRANSFER_SUCCESS":
      case "DEPOSIT_SUCCESS": {
        if (!localStorage.getItem(localStorageKeys.stz_user)) {
          this.onGetPNPThirdPartyLogin(
            payNPlayFromTransactionResponse.txnId,
            "deposit"
          );
        } else {
          this.onSwitchToNextStep(
            "successful",
            "step5",
            "zimpler.deposit_success_msg"
          );
        }
        break;
      }
      case "KYC_VERIFIED": {
        this.onGoToBackToCashier();

        break;
      }
      case "CASHIER_INIT_DEPOSIT_FAILED": {
        this.onSwitchToNextStep(
          "failure",
          "step5",
          "zimpler.cashier_init_deposit_failed"
        );

        break;
      }
      case "LOGIN_WITHOUT_DEPOSIT": {
        /*
          Else condition encounter when user is already logged in with
          DEMOUSER2 & try to deposit with DEMOUSER4 user
          In general world on Production we don't encounter this case.
        */
        if (!localStorage.getItem(localStorageKeys.stz_user)) {
          this.onGetPNPThirdPartyLogin(
            payNPlayFromTransactionResponse.txnId,
            "login"
          );
        } else {
          this.onSwitchToNextStep(
            "failure",
            "step5",
            "zimpler.something_went_wrong"
          );
        }

        break;
      }
      case "CATEGORY_LIMITS_EXCEEDED": {
        this.onSwitchToNextStep(
          "failure",
          "step5",
          "zimpler.category_limits_excceed"
        );

        break;
      }
      case "DEPOSIT_NOT_ALLOWED": {
        this.onSwitchToNextStep(
          "failure",
          "step5",
          "zimpler.deposit_not_allowed"
        );

        break;
      }
      case "RG_COOL_OFF": {
        this.onSwitchToNextStep("failure", "step5", "zimpler.rg_cool_off");

        break;
      }
    }
  }

  onTransactionFailure(txnStatus: string): void {
    switch (txnStatus) {
      case "LOGIN_WITHOUT_DEPOSIT": {
        this.onSwitchToNextStep(
          "failure",
          "step5",
          "zimpler.login_without_depositFailureMsg"
        );

        break;
      }
      case "DEPOSIT_SUCCESS": {
        if (!localStorage.getItem(localStorageKeys.stz_user)) {
          this.onGetQuickRegisterZimpler();
        } else {
          this.onSwitchToNextStep(
            "failure",
            "step5",
            "zimpler.something_went_wrong"
          );
        }

        break;
      }
      case "KYC_VERIFIED":
      case "INIT": {
        this.onGoToBackToCashier();

        break;
      }
    }
  }

  onGetQuickRegisterZimpler(): void {
    let quickRegisterZimplerRequest: QuickRegisterZimplerRequest = {
      txnId: +sessionStorage.getItem("txnId"),
      emailId: sessionStorage.getItem("txnId") + "@" + environment.website,
      password: Math.random().toString(36).slice(-8),
    };

    if (this.affiliateService.getAffiliateData()) {
      quickRegisterZimplerRequest = {
        ...quickRegisterZimplerRequest,
        ...this.affiliateService.getAffiliateData(),
      };
    }

    this.quickRegisterZimplerSubscription = this.payNPlayCashierService
      .onGetQuickRegisterZimpler(quickRegisterZimplerRequest)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (quickRegisterZimplerResponse: QuickRegisterZimplerResponse) => {
          if (quickRegisterZimplerResponse.status === StatusResponse.SUCCESS) {
            this.affiliateService.onDeleteAffiliateCookies();

            this.onGetPNPThirdPartyLogin(
              +sessionStorage.getItem("txnId"),
              "quick-registration"
            );
          } else {
            this.onSwitchToNextStep(
              "failure",
              "step5",
              "zimpler.quickRegisterFailureMsg"
            );
          }
        }
      );
  }

  onGetPNPThirdPartyLogin(txnId: number, callingFrom: string): void {
    if (txnId) {
      let payNPlayLoginRequest: PayNPlayLoginRequest = {
        txnId,
      };

      this.store.dispatch(payNPlayLoginRequested({ payNPlayLoginRequest }));

      this.payNPlayLoginSubscription = this.store
        .select(selectAuthPayNPlayLoggedIn)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (response: {
            payNPlayLoginResponse: PayNPlayLoginResponse;
            isLoaded: boolean;
            isLoading: boolean;
          }) => {
            if (response && response.isLoaded) {
              if (
                response.payNPlayLoginResponse &&
                response.payNPlayLoginResponse.status === StatusResponse.SUCCESS
              ) {
                this.onPayNPlayLoginSuccess(callingFrom);

                this.affiliateService.onDeleteAffiliateCookies();
              } else if (
                response.payNPlayLoginResponse.errorCode === 100173 &&
                response.payNPlayLoginResponse.errorDescription
              ) {
                let message: string =
                  response.payNPlayLoginResponse.errorDescription;

                /*
                  Below logic/work around is required to remove crossbrowser issue in safari, firefox & windows
                */
                let accountLockedDate: Date = new Date(
                  Date.parse(
                    message
                      .substr(message.indexOf(":") + 1)
                      .trim()
                      .replace(" ", "T") + "+02:00"
                  )
                );

                const errorDescMessage: string = this.translationService.get(
                  "zimpler.error_100173_rg_cool_off"
                );

                this.errorMessage = `${errorDescMessage}${accountLockedDate}`;

                this.onSwitchToNextStep("failure", "step5", this.errorMessage);
              } else {
                this.tnxDataBeforeQuickRegister = undefined;

                this.onPayNPlayLoginFailure(callingFrom);
              }
            }
          }
        );
    }
  }

  onPayNPlayLoginSuccess(callingFrom: string): void {
    if (callingFrom === "quick-registration") {
      this.onSwitchToNextStep("", "step6", "", "quick-registration");

      this.onGetProfileBalanceCurrency();
    } else {
      let messageKey: string = "";

      switch (callingFrom) {
        case "login":
          messageKey = "zimpler.loginSuccessMsg";
          break;
        case "deposit":
          messageKey = "zimpler.depositSuccessMsg";
          break;
        default:
          messageKey = "zimpler.loginSuccessDefaultMsg";
      }

      this.onSwitchToNextStep("successful", "step5", messageKey, callingFrom);

      this.deepLinksService.onNavigateAfterLogin();
    }
  }

  onPayNPlayLoginFailure(callingFrom: string): void {
    let messageKey: string = "";

    switch (callingFrom) {
      case "login":
        messageKey = "zimpler.loginFailureMsg";
        break;
      case "deposit":
        messageKey = "zimpler.deposit_LoginFailureMsg";
        break;
      case "quick-registration":
        messageKey = "zimpler.quick_registration_LoginFailureMsg";
        break;
      default:
        messageKey = "zimpler.something_went_wrong";
    }

    this.onSwitchToNextStep("failure", "step5", messageKey);
  }

  onEmailSubscriptionUpdate(): void {
    /*
      This will triggered only when user doing is first deposit
    */
    if (this.tnxDataBeforeQuickRegister) {
      const userProfileData: UserData =
        this.userDetailsService.getUserProfileDetails();

      if (userProfileData) {
        this.tnxDataBeforeQuickRegister.externalEcrId =
          userProfileData.playerID;
      }

      let { txnStatus, txnId, amount } = this.tnxDataBeforeQuickRegister;

      let transactionData: GtmDepositTransaction = {
        txnStatus,
        txnId,
        amount,
      };

      this.gtmService.onTrackDepositGTMEvent("SUCCESS", transactionData, true);

      this.tnxDataBeforeQuickRegister = undefined;
    }

    this.onGoToBackToCashier();
  }

  onSwitchToNextStep(
    status: string,
    stepNumber: string,
    messageKey: string,
    callingFrom?: string
  ): void {
    this.paymentProcessStep = stepNumber;

    if (status === "failure" && messageKey) {
      this.transactionErrorMessage = this.translationService.get(messageKey);
    }

    if (status === "successful" && messageKey) {
      this.transactionSuccessMessage = this.translationService.get(messageKey);
    }

    if (callingFrom !== "quick-registration") {
      this.timerInstance = setTimeout(() => {
        this.onGoToBackToCashier();
      }, 3000);
    }
  }

  onSetTransactionAmount(event): void {
    if (event) {
      this.zimplerForm.controls["amount"].setValue(event.target.value);

      this.isVisible = true;
    }
  }

  onUncheckQuickPicks(): void {
    const inputRadioElement: NodeListOf<Element> = document.querySelectorAll(
      '.quickPick__wrap input[type="radio"]:checked'
    ) as NodeListOf<Element>;

    if (inputRadioElement && inputRadioElement.length > 0) {
      (inputRadioElement[0] as HTMLInputElement).checked = false;
    }
  }

  onGoToBackToCashier(): void {
    this.isButtonLoader = false;

    this.isVisible = false;

    this.paymentProcessStep = "step1";

    sessionStorage.removeItem("txnId");

    this.onRemoveOverFlowFromHidden();

    this.isInitiateDepositTriggered = false;

    this.paymentProcessStep = "step1";

    this.zimplerForm.controls["amount"].setValue("");

    this.zimplerForm.reset();

    this.payNPlayCashierService.onBroadcastZimplerProcessCompleted();

    this.selectedBonus = undefined;

    if (localStorage.getItem(localStorageKeys.stz_user)) {
      this.onVerifyEmailUpdate("loginSucces");
    }

    if (this.cashierService.getActiveDepositBonus()) {
      this.cashierService.onSetActiveDepositBonus({});
    }
  }

  onSetOverFlowToHidden(): void {
    this.utilityService.onAddClassToAppBody("overflow-hidden zimplerModalOpen");
  }

  onRemoveOverFlowFromHidden(): void {
    this.utilityService.onRemoveClassFromAppBody(
      "overflow-hidden zimplerModalOpen"
    );
  }

  onVerifyEmailUpdate(callingFrom?: string): void {
    const userProfileDetails: UserBalanceDetails =
      this.userDetailsService.userBalanceDetails;

    if (userProfileDetails && userProfileDetails.email) {
      this.onEmailValidation(userProfileDetails.email);
    } else {
      this.profileBalanceSubscription = this.profileService
        .onGetProfileBalanceCurrency()
        .subscribe((profileBalance: ProfileBalance) => {
          if (
            profileBalance &&
            profileBalance.profile &&
            profileBalance.profile.email
          ) {
            this.onEmailValidation(profileBalance.profile.email);
          }
        });
    }
  }

  onEmailValidation(emailId: string): void {
    let numberRegex: RegExp = new RegExp("^[0-9]+$");

    if (emailId) {
      let emailSegmentsList: string[] = emailId.split("@");

      if (
        numberRegex.test(emailSegmentsList[0]) &&
        emailSegmentsList[1].startsWith(environment.website.toLowerCase())
      ) {
        this.paymentProcessStep = "step6";

        this.isInitiateDepositTriggered = true;

        this.onSetOverFlowToHidden();
      }
    }
  }

  /*
    This functional will be called from payment rewards component
    after user clicks add bonus or No thanks button then we proccess
    zimpler payment init flow to complete deposit
  */
  onNavigateToDeposit(): void {
    this.onInitializeZimplerPayAndPlay();
  }

  onZimplerWithdrawal(txnType: string = "WITHDRAW"): void {
    this.isButtonLoader = true;

    this.onClearIFrameData();

    if (this.zimplerForm.valid && txnType === "WITHDRAW") {
      /*
        For deposit redirection urls are coming from CMS,
        where as for withdraw redirection url is from php code level which
        is hard coded on php side
        just adding here for reference
        whether success or failure use will be naviagted to below url
        which we are handing from app router only widthdraw due some
        dependance from php side
        cashier/callback/ZIMPLER_DIRECT/ctctrvctrctrtvctrctrctctctc?paymentType=cashout
      */
      let cashierMakeWalletWithdrwalRequest: CashierMakeWalletWithdrwalRequest =
        {
          depositAmount: this.zimplerForm.controls["amount"].value,
          paymentMethod: "ZIMPLER_DIRECT",
          comments: "system",
        };

      this.zimplerWithdrawalSubscription = this.payNPlayCashierService
        .onGetZimplerWithdrawal(cashierMakeWalletWithdrwalRequest)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (
            cashierMakeWalletWithdrwalResponse: CashierMakeWalletWithdrwalResponse
          ) => {
            if (
              cashierMakeWalletWithdrwalResponse.status ===
                StatusResponse.SUCCESS &&
              cashierMakeWalletWithdrwalResponse.redirectURL
            ) {
              sessionStorage.setItem(
                "txnId",
                cashierMakeWalletWithdrwalResponse.txnId.toString()
              );

              if (this.windowType === "mobile") {
                window.open(
                  cashierMakeWalletWithdrwalResponse.redirectURL,
                  "_self"
                );
              } else {
                this.paymentProcessStep = "step3";

                this.isInitiateDepositTriggered = true;

                setTimeout(() => {
                  let $iframe = $(
                    `<iframe src="${cashierMakeWalletWithdrwalResponse.redirectURL}"></iframe>`
                  );

                  $(".zimplerIframeContainer").append($iframe);
                });

                this.onSetOverFlowToHidden();
              }
            } else if (
              cashierMakeWalletWithdrwalResponse.status ===
              StatusResponse.SUCCESS
            ) {
              this.onSwitchToNextStep(
                "successful",
                "step5",
                "zimpler.withdrawSuccessWaitingApproval"
              );
            } else {
              this.onSwitchToNextStep(
                "failure",
                "step5",
                "zimpler.something_went_wrong_redirection"
              );
            }

            this.isButtonLoader = false;
          }
        );
    }
  }

  onGetWithdrawalTransactionStatus(): void {
    if (sessionStorage.getItem("txnId")) {
      const payNPlayTransactionStatusRequest: PayNPlayTransactionStatusRequest =
        {
          txnId: sessionStorage.getItem("txnId"),
          type: TransactionStatusEnum.CASHOUT,
        };

      this.userPayNPlayTransactionStatusSubscription =
        this.payNPlayCashierService
          .onGetUserPayNPlayTransactionStatus(payNPlayTransactionStatusRequest)
          .pipe(takeUntil(this.destroy$))
          .subscribe(
            (
              payNPlayTransactionStatusResponse: PayNPlayTransactionStatusResponse
            ) => {
              if (
                payNPlayTransactionStatusResponse &&
                payNPlayTransactionStatusResponse.status ===
                  StatusResponse.SUCCESS
              ) {
                this.onWithdrawalTnxSuccess(payNPlayTransactionStatusResponse);
              } else {
                this.onWithdrawalTnxFailure();
              }
            }
          );
    }
  }

  onWithdrawalTnxSuccess(
    payNPlayTransactionStatusResponse: PayNPlayTransactionStatusResponse
  ): void {
    let messageKey: string = "";

    if (payNPlayTransactionStatusResponse.actualStatusInDB === "co_initiated") {
      messageKey = "zimpler.withdrawSuccessWaitingApproval";
    } else {
      messageKey = "zimpler.withdrawSuccess";
    }

    this.onSwitchToNextStep("successful", "step5", messageKey);
  }

  onWithdrawalTnxFailure(): void {
    this.onSwitchToNextStep(
      "failure",
      "step5",
      "zimpler.withdrawRequestfailed"
    );
  }

  /*
    Below code complete deal with passing of bonus code
    in make transaction
    
    Below we check is there any active bonus already been selected &
    user navigated to cashier like we have reward area where user can click on
    reward card & land on cashier page
    so we store bonus selected in rewards component into service
    & fetch back here to apply & passed it on pass in payment flow
  */
  onSelectActiveBonus(isEligbleBonus?: boolean): void {
    let activeDepositBonus: DepositBonus =
      this.cashierService.getActiveDepositBonus();

    if (activeDepositBonus && activeDepositBonus.bonusCode) {
      this.selectedBonus = activeDepositBonus;
    }
    if (!isEligbleBonus) {
      this.isBonusData = false;
    } else {
      this.isBonusData = true;
    }
  }

  onSelectedBonus(activeDepositBonus: DepositBonus): void {
    this.selectedBonus = activeDepositBonus;

    if (activeDepositBonus && activeDepositBonus.bonusCode) {
      this.isClearSelectedBonus = false;
    } else {
      this.isClearSelectedBonus = true;
    }
  }

  onGetProfileBalanceCurrency(): void {
    this.profileBalanceCurrencySubscription = this.profileService
      .onGetProfileBalanceCurrency()
      .subscribe((profileBalance: ProfileBalance) => {
        if (profileBalance && profileBalance.profile.jurisdiction) {
          this.profileService.onSetUserJurisdiction(
            profileBalance.profile.jurisdiction
          );

          if (this.profileService.getUserJurisdiction() === "estonia") {
            this.emitterService.onBroadcastLimitsRegulatoryPopup(true);
          }
        }
      });
  }

  // -----------------------------------------------------------------
  // On Destroy
  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();

    if (this.cashierService.getActiveDepositBonus()) {
      this.cashierService.onSetActiveDepositBonus(undefined);
    }

    if (this.userPayNPlayTransactionStatusSubscription)
      this.userPayNPlayTransactionStatusSubscription.unsubscribe();

    if (this.profileBalanceCurrencySubscription)
      this.profileBalanceCurrencySubscription.unsubscribe();

    if (this.quickRegisterZimplerSubscription)
      this.quickRegisterZimplerSubscription.unsubscribe();

    if (this.pnpUserFromTransactionSubscription)
      this.pnpUserFromTransactionSubscription.unsubscribe();

    if (this.pnpMinMaxTxnLimitsSubscription)
      this.pnpMinMaxTxnLimitsSubscription.unsubscribe();

    if (this.zimplerPayAndPlaySubscription)
      this.zimplerPayAndPlaySubscription.unsubscribe();

    if (this.cashoutTxnLimitsSubscription)
      this.cashoutTxnLimitsSubscription.unsubscribe();

    if (this.zimplerWithdrawalSubscription)
      this.zimplerWithdrawalSubscription.unsubscribe();

    if (this.profileBalanceSubscription)
      this.profileBalanceSubscription.unsubscribe();

    if (this.payNPlayLoginSubscription)
      this.payNPlayLoginSubscription.unsubscribe();

    if (this.subscription) this.subscription.unsubscribe();

    this.subscriptions.forEach((subscription: Subscription) =>
      subscription.unsubscribe()
    );
  }
}
