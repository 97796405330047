export interface KYCStatusResponse {
  documents: KYCDocuments;
  validation_checks: boolean[];
}

export interface KYCDocuments {
  isVerified: boolean;
  identityStatus: KYCStatus;
  addressStatus: KYCStatus;
  ageStatus: KYCStatus;
  ccStatus: null;
  cafStatus: KYCStatus;
  sourceOfIncomeStatus?: KYCStatus;
  kycDocumentDetails: KycDocumentDetails[];
  sourceOfIncomeDetails: null;
  paymentInstrumentVerificationStatus: KYCStatus;
}

export interface KycDocumentDetails {
  docType: KYCDocType;
  docStatus: KYCStatus;
  comment?: string;
}

export enum KYCStatus {
  "vrfn_new" = "vrfn_new",
  "vrfn_init" = "vrfn_init",
  "vrfn_verified" = "vrfn_verified",
  "vrfn_failed" = "vrfn_failed",
}

export enum KYCDocType {
  "identityVerificationData" = "identityVerificationData",
  "addressVerificationDetails" = "addressVerificationDetails",
  "contactVerificationDetails" = "contactVerificationDetails",
  "ageVerificationDetails" = "ageVerificationDetails",
  "paymentInstruementVerificationDetails" = "paymentInstruementVerificationDetails",
  "sourceOfIncomeVerificationDetails" = "sourceOfIncomeVerificationDetails",
}
