import { Component, Input, SimpleChanges } from "@angular/core";

@Component({
  selector: "app-csn-livespins-iframe",
  templateUrl: "./csn-livespins-iframe.component.html",
  styleUrls: ["./csn-livespins-iframe.component.scss"],
})
export class CsnLivespinsIFrameComponent {
  // Inputs
  @Input() iframeURL: string = "";

  // Strings
  iframe: string = "";

  // -----------------------------------------------------------------
  // Lifecycle Hooks
  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes["iframeURL"].currentValue &&
      changes["iframeURL"].previousValue != changes["iframeURL"].currentValue
    ) {
      this.iframe = changes["iframeURL"].currentValue;
    }
  }
}
