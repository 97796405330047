/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./livespins.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../csn-livespins-room/csn-livespins-room.component.ngfactory";
import * as i3 from "../csn-livespins-room/csn-livespins-room.component";
import * as i4 from "@angular/common";
import * as i5 from "../csn-livespins-schedule-room/csn-livespins-schedule-room.component.ngfactory";
import * as i6 from "../csn-livespins-schedule-room/csn-livespins-schedule-room.component";
import * as i7 from "../../../../../../node_modules/ngx-swiper-wrapper/dist/ngx-swiper-wrapper.ngfactory";
import * as i8 from "ngx-swiper-wrapper";
import * as i9 from "../../../shared/directives/swiper-resize.directive";
import * as i10 from "./livespins.component";
import * as i11 from "../../../multi-languages/services/multi-language.service";
import * as i12 from "../../services/livespins.service";
import * as i13 from "@angular/router";
var styles_LiveSpinsComponent = [i0.styles];
var RenderType_LiveSpinsComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_LiveSpinsComponent, data: {} });
export { RenderType_LiveSpinsComponent as RenderType_LiveSpinsComponent };
function View_LiveSpinsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_LiveSpinsComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-csn-livespins-room", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onPlay(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_CsnLivespinsRoomComponent_0, i2.RenderType_CsnLivespinsRoomComponent)), i1.ɵdid(2, 573440, null, 0, i3.CsnLivespinsRoomComponent, [], { liveStream: [0, "liveStream"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 2, 0, currVal_0); }, null); }
function View_LiveSpinsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_LiveSpinsComponent_4)), i1.ɵdid(2, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.liveStream$)); var currVal_1 = _co.getLivespinsStreamsTrackBy; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_LiveSpinsComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-csn-livespins-schedule-room", [], null, null, null, i5.View_CsnLivespinsScheduleRoomComponent_0, i5.RenderType_CsnLivespinsScheduleRoomComponent)), i1.ɵdid(2, 573440, null, 0, i6.CsnLivespinsScheduleRoomComponent, [], { livespinsScheduleRoom: [0, "livespinsScheduleRoom"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 2, 0, currVal_0); }, null); }
function View_LiveSpinsComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_LiveSpinsComponent_6)), i1.ɵdid(2, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.liveScheduledStream$)); _ck(_v, 2, 0, currVal_0); }, null); }
function View_LiveSpinsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 14, "div", [["class", "game-group__container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "game-group--title"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LiveSpinsComponent_2)), i1.ɵdid(4, 540672, null, 0, i4.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 10, "div", [["class", "swiper__outer hoverspace"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 9, "div", [["class", "swiper__inner swiper__full"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 8, "swiper", [["class", "mySwiper swiper-control"], ["id", "livespins-swiper"], ["watchSlidesProgress", ""]], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 9).onResize() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_SwiperComponent_0, i7.RenderType_SwiperComponent)), i1.ɵdid(8, 4374528, [[1, 4]], 0, i8.SwiperComponent, [i1.NgZone, i1.ChangeDetectorRef, i1.PLATFORM_ID, [2, i8.SWIPER_CONFIG]], { config: [0, "config"] }, null), i1.ɵdid(9, 16384, null, 0, i9.SwiperResizeDirective, [i8.SwiperComponent], null, null), (_l()(), i1.ɵand(16777216, null, 0, 2, null, View_LiveSpinsComponent_3)), i1.ɵdid(11, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, 0, 2, null, View_LiveSpinsComponent_5)), i1.ɵdid(14, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v.parent, 3); _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.livespinsSwiper; _ck(_v, 8, 0, currVal_1); var currVal_2 = (i1.ɵunv(_v, 11, 0, i1.ɵnov(_v, 12).transform(_co.liveStream$)).length > 0); _ck(_v, 11, 0, currVal_2); var currVal_3 = (i1.ɵunv(_v, 14, 0, i1.ɵnov(_v, 15).transform(_co.liveScheduledStream$)).length > 0); _ck(_v, 14, 0, currVal_3); }, null); }
function View_LiveSpinsComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["class", "livespins-link"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["alt", "Livespins"], ["height", "18"], ["src", "/assets/img/livespins.svg"], ["title", "Livespins"], ["width", "auto"]], null, null, null, null, null))], null, null); }
function View_LiveSpinsComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_LiveSpinsComponent_8)), i1.ɵdid(1, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.hasStreams; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_LiveSpinsComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { componentRef: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LiveSpinsComponent_1)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, [["streamsTemplate", 2]], null, 0, null, View_LiveSpinsComponent_7))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isLiveSpinsEnabled; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_LiveSpinsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-livespins", [], null, null, null, View_LiveSpinsComponent_0, RenderType_LiveSpinsComponent)), i1.ɵdid(1, 4440064, null, 0, i10.LiveSpinsComponent, [i11.MultiLanguageService, i12.LivespinsService, i13.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LiveSpinsComponentNgFactory = i1.ɵccf("app-livespins", i10.LiveSpinsComponent, View_LiveSpinsComponent_Host_0, { livespinsSwiper: "livespinsSwiper", isLiveSpinsEnabled: "isLiveSpinsEnabled", livespinsLanguages: "livespinsLanguages", language: "language" }, {}, []);
export { LiveSpinsComponentNgFactory as LiveSpinsComponentNgFactory };
