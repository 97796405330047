import { TranslateService } from "@ngx-translate/core";
import { Injectable } from "@angular/core";

// Environments
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class TranslationService {
  // Strings
  languageCode: string = environment.defaultLanguage;

  constructor(private translateService: TranslateService) {}

  // -----------------------------------------------------------------
  // Get Methods
  get(key: string | string[], interPolateParams?: Object): string {
    if (interPolateParams) {
      return this.translateService.instant(key, interPolateParams);
    } else {
      return this.translateService.instant(key);
    }
  }

  getLanguages(): string[] {
    return this.translateService.getLangs();
  }

  // -----------------------------------------------------------------
  // Set Methods
  onAddLanguages(languages: string[]): void {
    this.translateService.addLangs(languages);
  }

  /*
    Change the Language Currently used
    by passing Langauge Code
  */
  onUseLanguage(languageCode: string): void {
    this.languageCode = languageCode;

    this.translateService.use(languageCode).subscribe();
  }
}
