import { Action, createReducer, on } from "@ngrx/store";

// Actions
import { languageCodeLoaded } from "src/app/modules/multi-languages/store/actions/languages.actions";

// Enums
import { LanguageCodes } from "src/app/models/configurations/enums/language-codes.enum";

// Environments
import { environment } from "src/environments/environment";

export const LanguageStateFeatureKey = "language-collection";

// Reducer States
export interface LanguagesReducerState {
  language: LanguageCodes;
  languageCode: string;
}

export const initialState: LanguagesReducerState = {
  language: LanguageCodes.en,
  languageCode: environment.defaultLanguage,
};

// Reducer
const LanguagesReducer = createReducer(
  initialState,
  on(languageCodeLoaded, (state, { language, languageCode }) => {
    return {
      ...state,
      language,
      languageCode,
    };
  })
);

export function reducer(
  state: LanguagesReducerState | undefined,
  action: Action
) {
  return LanguagesReducer(state, action);
}
