/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./limits.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/ngx-accordion/AccordionGroup.ngfactory";
import * as i3 from "ngx-accordion/AccordionGroup";
import * as i4 from "ngx-accordion/Accordion";
import * as i5 from "../../../../../../node_modules/ngx-accordion/AccordionHeading.ngfactory";
import * as i6 from "ngx-accordion/AccordionHeading";
import * as i7 from "@ngx-translate/core";
import * as i8 from "../rg-limits/rg-limits.component.ngfactory";
import * as i9 from "../rg-limits/rg-limits.component";
import * as i10 from "../../../multi-languages/services/translation.service";
import * as i11 from "../../../user/services/user-details.service";
import * as i12 from "../../../shared/pipes/currency-format.pipe";
import * as i13 from "../../services/limits.service";
import * as i14 from "../../../dynamic-form/services/form.service";
import * as i15 from "@angular/forms";
import * as i16 from "../../../../../../node_modules/ngx-accordion/Accordion.ngfactory";
import * as i17 from "@angular/common";
import * as i18 from "../../../navigation/components/side-menu-modal/side-menu-modal.component.ngfactory";
import * as i19 from "../../../navigation/components/side-menu-modal/side-menu-modal.component";
import * as i20 from "../../../multi-languages/services/multi-language.service";
import * as i21 from "../../../shared/services/utility.service";
import * as i22 from "@ngrx/store";
import * as i23 from "@angular/router";
import * as i24 from "./limits.component";
import * as i25 from "../../../user/services/profile.service";
var styles_LimitsComponent = [i0.styles];
var RenderType_LimitsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LimitsComponent, data: {} });
export { RenderType_LimitsComponent as RenderType_LimitsComponent };
function View_LimitsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_LimitsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "accordion-group", [], null, null, null, i2.View_AccordionGroup_0, i2.RenderType_AccordionGroup)), i1.ɵdid(2, 49152, [[1, 4]], 1, i3.AccordionGroup, [i4.Accordion, i1.ChangeDetectorRef], null, null), i1.ɵqud(335544320, 2, { toggler: 0 }), (_l()(), i1.ɵeld(4, 0, null, 0, 3, "accordion-heading", [], null, null, null, i5.View_AccordionHeading_0, i5.RenderType_AccordionHeading)), i1.ɵdid(5, 49152, null, 0, i6.AccordionHeading, [], null, null), (_l()(), i1.ɵted(6, 0, [" ", " "])), i1.ɵpid(131072, i7.TranslatePipe, [i7.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(8, 0, null, 1, 1, "app-rg-limits", [], null, null, null, i8.View_RgLimitsComponent_0, i8.RenderType_RgLimitsComponent)), i1.ɵdid(9, 770048, null, 0, i9.RgLimitsComponent, [i10.TranslationService, i11.UserDetailsService, i12.CurrencyFormatPipe, i13.LimitsService, i14.FormService, i15.FormBuilder], { limitType: [0, "limitType"] }, null)], function (_ck, _v) { var currVal_1 = "deposit"; _ck(_v, 9, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform("limits.NetDeposit_limit")); _ck(_v, 6, 0, currVal_0); }); }
function View_LimitsComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "accordion-group", [], null, null, null, i2.View_AccordionGroup_0, i2.RenderType_AccordionGroup)), i1.ɵdid(2, 49152, [[1, 4]], 1, i3.AccordionGroup, [i4.Accordion, i1.ChangeDetectorRef], null, null), i1.ɵqud(335544320, 3, { toggler: 0 }), (_l()(), i1.ɵeld(4, 0, null, 0, 3, "accordion-heading", [], null, null, null, i5.View_AccordionHeading_0, i5.RenderType_AccordionHeading)), i1.ɵdid(5, 49152, null, 0, i6.AccordionHeading, [], null, null), (_l()(), i1.ɵted(6, 0, [" ", " "])), i1.ɵpid(131072, i7.TranslatePipe, [i7.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(8, 0, null, 1, 1, "app-rg-limits", [], null, null, null, i8.View_RgLimitsComponent_0, i8.RenderType_RgLimitsComponent)), i1.ɵdid(9, 770048, null, 0, i9.RgLimitsComponent, [i10.TranslationService, i11.UserDetailsService, i12.CurrencyFormatPipe, i13.LimitsService, i14.FormService, i15.FormBuilder], { limitType: [0, "limitType"] }, null)], function (_ck, _v) { var currVal_1 = "loss"; _ck(_v, 9, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform("limits.loss_limit")); _ck(_v, 6, 0, currVal_0); }); }
function View_LimitsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "accordion", [["class", "accordion m-t-16"]], null, null, null, i16.View_Accordion_0, i16.RenderType_Accordion)), i1.ɵdid(1, 1228800, null, 1, i4.Accordion, [], { closeOthers: [0, "closeOthers"], showArrows: [1, "showArrows"], expandAll: [2, "expandAll"] }, null), i1.ɵqud(603979776, 1, { groups: 1 }), (_l()(), i1.ɵand(16777216, null, 0, 2, null, View_LimitsComponent_3)), i1.ɵdid(4, 16384, null, 0, i17.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpad(5, 2), (_l()(), i1.ɵand(16777216, null, 0, 2, null, View_LimitsComponent_4)), i1.ɵdid(7, 16384, null, 0, i17.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpad(8, 1)], function (_ck, _v) { var _co = _v.component; var currVal_0 = true; var currVal_1 = true; var currVal_2 = false; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = (_ck(_v, 5, 0, "estonia", "curacao").indexOf(_co.userJurisdiction) > (0 - 1)); _ck(_v, 4, 0, currVal_3); var currVal_4 = (_ck(_v, 8, 0, "estonia").indexOf(_co.userJurisdiction) > (0 - 1)); _ck(_v, 7, 0, currVal_4); }, null); }
export function View_LimitsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "app-side-menu-modal", [], null, null, null, i18.View_SideMenuModalComponent_0, i18.RenderType_SideMenuModalComponent)), i1.ɵdid(1, 770048, null, 0, i19.SideMenuModalComponent, [i20.MultiLanguageService, i21.UtilityService, i22.Store, i23.Router], { sideMenuTitle: [0, "sideMenuTitle"], customClass: [1, "customClass"] }, null), (_l()(), i1.ɵeld(2, 0, null, 1, 8, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "p", [["class", "text-center small-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, [" ", " "])), i1.ɵpid(131072, i7.TranslatePipe, [i7.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(6, 0, null, null, 2, "p", [["class", "text-center small-text m-b-16"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, [" ", " "])), i1.ɵpid(131072, i7.TranslatePipe, [i7.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LimitsComponent_1)), i1.ɵdid(10, 540672, null, 0, i17.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null), (_l()(), i1.ɵand(0, [["accordionTemplate", 2]], null, 0, null, View_LimitsComponent_2))], function (_ck, _v) { var currVal_0 = "limits.page_title"; var currVal_1 = "limits"; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_4 = i1.ɵnov(_v, 11); _ck(_v, 10, 0, currVal_4); }, function (_ck, _v) { var currVal_2 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform("limits.description_para1")); _ck(_v, 4, 0, currVal_2); var currVal_3 = i1.ɵunv(_v, 7, 0, i1.ɵnov(_v, 8).transform("limits.description_para2")); _ck(_v, 7, 0, currVal_3); }); }
export function View_LimitsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-limits", [], null, null, null, View_LimitsComponent_0, RenderType_LimitsComponent)), i1.ɵdid(1, 245760, null, 0, i24.LimitsComponent, [i25.ProfileService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LimitsComponentNgFactory = i1.ɵccf("app-limits", i24.LimitsComponent, View_LimitsComponent_Host_0, {}, {}, []);
export { LimitsComponentNgFactory as LimitsComponentNgFactory };
