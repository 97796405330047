import { ViewEncapsulation, HostListener, Component } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";

// Components
import { FormValidationComponent } from "src/app/modules/shared/components/form-validation/form-validation.component";

// Models
import { PnPMinMaxLimitsRequest } from "src/app/modules/account/models/pnp-min-max-limits/pnp-min-max-limits-request.model";
import { PnPMinMaxLimits } from "src/app/modules/account/models/pnp-min-max-limits/pnp-min-max-limits.model";

// Reducers
import { AppState } from "src/app/store/reducers";

// Selectors
import {
  selectAuthLoginIsLoggedOut,
  selectAuthLoginIsLoggedIn,
} from "src/app/modules/auth/store/selectors/auth.selectors";

// Services
import { PayNPlayCashierService } from "src/app/modules/account/services/pay-n-play-cashier.service";
import { UserDetailsService } from "src/app/modules/user/services/user-details.service";
import { SessionService } from "src/app/modules/auth/services/session.service";

// Validators
import { CharactersValidator } from "src/app/modules/validators/validators/characters.validators";

@Component({
  selector: "app-quick-pick-amount",
  templateUrl: "./quick-pick-amount.component.html",
  styleUrls: ["./quick-pick-amount.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class QuickpickAmountComponent extends FormValidationComponent {
  // Numbers
  minValue: number = 0;
  maxValue: number = 0;

  // Strings
  errorMinimumAmountNumber: string = "0";
  errorMaximumAmountNumber: string = "0";
  currencySymbol: string = "";
  methodCalled: string = "";

  // Booleans
  isLoggedIn: boolean = false;
  isVisible: boolean = false;
  isFocus: boolean = false;

  // Forms
  zimplerForm: FormGroup;

  // Subscriptions
  pnpMinMaxTxnLimitsSubscription: Subscription;

  subscriptions: Subscription[] = [];

  constructor(
    private payNPlayCashierService: PayNPlayCashierService,
    private userDetailsService: UserDetailsService,
    private sessionService: SessionService,
    private formBuilder: FormBuilder,
    private store: Store<AppState>
  ) {
    super();

    this.zimplerForm = this.formBuilder.group({
      amount: ["", [Validators.required]],
    });
  }

  // -----------------------------------------------------------------
  // Lifecycle Hooks
  ngOnInit(): void {
    this.onGetPNPMinMaxTxnLimits();

    this.isLoggedIn = this.sessionService.getIsUserLoggedIn();

    this.subscriptions = [
      this.store
        .select(selectAuthLoginIsLoggedIn)
        .subscribe((isLoggedIn: boolean) => {
          this.isLoggedIn = isLoggedIn;
        }),
      this.store
        .select(selectAuthLoginIsLoggedOut)
        .subscribe((isLoggedOut: boolean) => {
          if (isLoggedOut) {
            this.isLoggedIn = false;
          }
        }),
      this.payNPlayCashierService.zimplerProcessCompletedSubject$.subscribe(
        () => {
          this.zimplerForm.patchValue({
            amount: "",
          });

          this.zimplerForm.reset();
        }
      ),
      this.userDetailsService.currencySymbolBehaviourSubject$.subscribe(
        (currencySymbol: string) => {
          this.currencySymbol = currencySymbol;
        }
      ),
    ];
  }

  // -----------------------------------------------------------------
  // Host Listeners
  @HostListener("keydown", ["$event"])
  onKeydown(event: KeyboardEvent): void {
    if (document.getElementById("qucikPickInputValue")) {
      let inputValue: number =
        (document.getElementById("qucikPickInputValue") as HTMLInputElement)
          .value.length - 1;

      if (event.which === 8 && inputValue <= 0) {
        this.isVisible = false;
      } else {
        this.isVisible = true;
      }
    }
  }

  // -----------------------------------------------------------------
  // Set Methods
  onSetTransactionAmount(event): void {
    if (event) {
      this.zimplerForm.patchValue({
        amount: event.target.value,
      });

      this.isVisible = true;
    }
  }

  onUncheckQuickPicks(): void {
    const inputRadioElement: NodeListOf<Element> = document.querySelectorAll(
      '.pnp-qd__btn-wrapper input[type="radio"]:checked'
    ) as NodeListOf<Element>;

    if (inputRadioElement && inputRadioElement.length > 0) {
      (inputRadioElement[0] as HTMLInputElement).checked = false;
    }
  }

  onGetPNPMinMaxTxnLimits(): void {
    let pnpMinMaxLimitsRequest: PnPMinMaxLimitsRequest = {
      option: "ZIMPLER_DIRECT",
      currency: this.userDetailsService.getCurrencyCode(),
    };

    this.pnpMinMaxTxnLimitsSubscription = this.payNPlayCashierService
      .onGetPNPMinMaxTxnLimits(pnpMinMaxLimitsRequest)
      .subscribe((pnpMinMaxLimitsResponse: PnPMinMaxLimits) => {
        if (
          pnpMinMaxLimitsResponse &&
          pnpMinMaxLimitsResponse.minLimit &&
          pnpMinMaxLimitsResponse.maxLimit
        ) {
          this.minValue = pnpMinMaxLimitsResponse.minLimit;

          this.maxValue = pnpMinMaxLimitsResponse.maxLimit;

          this.errorMinimumAmountNumber = `${pnpMinMaxLimitsResponse.minLimit}`;

          this.errorMaximumAmountNumber = `${pnpMinMaxLimitsResponse.maxLimit}`;

          this.methodCalled = "Deposit";

          this.zimplerForm.setValidators(
            CharactersValidator.validateMinNumericValueFunctional(
              `${pnpMinMaxLimitsResponse.minLimit}`,
              `${pnpMinMaxLimitsResponse.maxLimit}`,
              this.methodCalled
            ),
          );

          this.zimplerForm.get("amount").updateValueAndValidity();
        }
      });
  }

  onZimplerPayAndPlayInitialize(): void {
    if (this.zimplerForm.valid) {
      this.payNPlayCashierService.onBroadcastInitiateDeposit({
        callingFrom: "customBanner",
        amount: this.zimplerForm.controls["amount"].value,
      });
    }
  }

  // -----------------------------------------------------------------
  // On Destroy
  ngOnDestroy(): void {
    if (this.pnpMinMaxTxnLimitsSubscription)
      this.pnpMinMaxTxnLimitsSubscription.unsubscribe();

    this.subscriptions.forEach((subscription: Subscription) =>
      subscription.unsubscribe()
    );
  }
}
