// Configurations
import { localStorageKeys } from "src/app/modules/multi-languages/configurations/localstorage-keys.configurations";

// Environments
import { environment } from "src/environments/environment";

export const extractProperLanguage = (language: string): string => {
  switch (language) {
    // Canada
    case "en-ca": {
      return "en-ca";
    }
    // New Zealand
    case "en-nz": {
      return "en-ca";
    }
    // Ireland
    case "en-ie": {
      return "en-ie";
    }
    // Norwegian
    case "nb-no": {
      return "nb-no";
    }
    // Finland
    case "fi-fi": {
      return "fi-fi";
    }
    // Hungarian
    case "hu-hu": {
      return "hu-hu";
    }
    // Default
    default: {
      return "en-row";
    }
  }
};

export const getURLLanguageCode = (path: string): string => {
  const urlList: string[] = path.split("/");

  return urlList[1];
};

export const loadProperLanguage = (windowPathName: string): string => {
  const defaultLanguage: string = environment.defaultLanguage;

  let languageCode: string = localStorage.getItem(localStorageKeys.languageCode)
    ? localStorage.getItem(localStorageKeys.languageCode)
    : defaultLanguage;

  let tempLanguageCode: string = "";

  windowPathName = getURLLanguageCode(windowPathName);

  if (windowPathName !== "" && windowPathName !== "/") {
    // always return a correct language code
    tempLanguageCode = extractProperLanguage(windowPathName.replace("/", ""));

    if (tempLanguageCode != languageCode) {
      languageCode = tempLanguageCode;

      localStorage.setItem(localStorageKeys.languageCode, languageCode);
    }
  }

  return languageCode;
};
