<ng-container *ngIf="isInitiateDepositTriggered">
  <div class="zimplerModal zimplerModal--backdrop">
    <div
      class="zimplerModal__wrapper"
      [class.height100]="!(paymentProcessStep === 'step6')"
      [class.isBonusAvailable]="paymentProcessStep === 'step2'"
    >
      <div class="zimplerModal__container">
        <ng-container *ngIf="paymentProcessStep !== 'step6'">
          <ng-container
            *ngTemplateOutlet="closeZimplerProcessModalTemplate"
          ></ng-container>
        </ng-container>

        <div class="zimplerModal__body">
          <ng-container
            *ngIf="isLoggedIn && callingFrom === 'deposit' && paymentProcessStep === 'step1'"
          >
            <div class="journey1">
              <ng-container *ngIf="isBonusData">
                <h2 class="quickPick--title journey1--title">
                  {{'rewards.add_bonus_popup_title' | translate}}
                </h2>
              </ng-container>

              <div class="journey1--container">
                <div class="journey1--container--inner">
                  <app-payment-bonus
                    class="leftmenu--bonus"
                    [callingFrom]="'pnp-cashier'"
                    [rewardSize]="'shorter'"
                    [clearSelectedBonus]="isClearSelectedBonus"
                    [activeBonusData]="selectedBonus"
                    (callOnNavigateToDeposit)="onSelectActiveBonus(false)"
                    (callOnSelectBonus)="onSelectedBonus($event)"
                  ></app-payment-bonus>
                </div>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="paymentProcessStep === 'step1'">
            <ng-container
              *ngTemplateOutlet="zimplerProcessStep1Template"
            ></ng-container>
          </ng-container>

          <ng-container *ngIf="paymentProcessStep === 'step2'">
            <app-payment-bonus
              class="customBanner--bonus"
              [callingFrom]="'pnp-cashier'"
              [rewardSize]="'basic'"
              (callOnNavigateToDeposit)="onNavigateToDeposit()"
            ></app-payment-bonus>
          </ng-container>

          <ng-container *ngIf="paymentProcessStep === 'step3'">
            <div class="zimplerIframeContainer"></div>
          </ng-container>

          <ng-container *ngIf="paymentProcessStep === 'step4'">
            <div class="zimplerLoader">
              <div class="loader">
                <svg class="circular" viewBox="25 25 50 50">
                  <circle
                    class="path"
                    cx="50"
                    cy="50"
                    r="20"
                    fill="none"
                    stroke-width="2"
                    stroke-miterlimit="10"
                  />
                </svg>
              </div>

              <p>
                {{'zimpler.paymentProgressMessage' | translate}}
              </p>
            </div>
          </ng-container>

          <ng-container *ngIf="paymentProcessStep === 'step5'">
            <ng-container *ngIf="transactionSuccessMessage">
              <div class="alert alert-success text-center m-16">
                <p>{{transactionSuccessMessage}}</p>
              </div>
            </ng-container>

            <ng-container *ngIf="transactionErrorMessage">
              <div class="alert alert-danger text-center m-16">
                <p>{{transactionErrorMessage}}</p>
              </div>
            </ng-container>
          </ng-container>

          <ng-container *ngIf="paymentProcessStep === 'step6'">
            <app-subscribe-offers
              (callOnEmailSubscriptionUpdate)="onEmailSubscriptionUpdate()"
            ></app-subscribe-offers>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<!-- 
  ------------------------------------------------------
  
  Error Templates 
  
  ------------------------------------------------------
-->

<!-- 
  ------------------------------------------------------
  
  Templates 
  
  ------------------------------------------------------
-->
<!-- Close Zimpler Process Modal Template -->
<ng-template #closeZimplerProcessModalTemplate>
  <button
    class="btn modal-popup--close-btn closeZimplerModal"
    [class.withdrawCloseBtn]="callingFrom === 'withdraw'"
    (click)="paymentProcessStep === 'step2'? onNavigateToDeposit() : onGoToBackToCashier()"
  >
    <i class="icon-close"></i>
  </button>
</ng-template>

<!-- 
  ZimplerProcess Step 1 Template

  Step: 1

  Features:
  - Shows Title as Deposit / Withdraw Titles
  - Shows Payment Method Options
  - Withdraw Message

-->
<ng-template #zimplerProcessStep1Template>
  <div class="fromDepositBtn quickPick">
    <ng-container
      *ngTemplateOutlet="zimplerPaymentStep1TitleTemplate"
    ></ng-container>

    <ng-container
      *ngTemplateOutlet="zimplerPaymentStep1FormTemplate"
    ></ng-container>

    <ng-container
      *ngTemplateOutlet="zimplerPaymentStep1MethodsOptionsTemplate"
    ></ng-container>
  </div>
</ng-template>

<!-- 
  ------------------------------------------------------
  
  Other Templates used in step 1
  
  ------------------------------------------------------
-->
<!-- Step 1: Title Template -->
<ng-template #zimplerPaymentStep1TitleTemplate>
  <h2 class="quickPick--title" [class.isNotLoggedIn]="!isLoggedIn">
    <ng-container [ngSwitch]="callingFrom">
      <ng-container *ngSwitchCase="'deposit'">
        {{ 'zimpler.deposit' | translate}}
      </ng-container>
      <ng-container *ngSwitchDefault>
        {{ 'zimpler.withdraw' | translate}}
      </ng-container>
    </ng-container>
  </h2>

  <ng-container *ngIf="callingFrom === 'withdraw'">
    <p class="withdraw-msg">
      {{'zimpler.withdraw_msg' | translate}}
    </p>
  </ng-container>
</ng-template>

<!-- Step 1: Zimpler Payment Methods Options Template -->
<ng-template #zimplerPaymentStep1MethodsOptionsTemplate>
  <div class="quickPick--row pnpMethods">
    <div class="pnpMethods--row">
      <span>
        <img
          alt="Nordea"
          src="/assets/img/pnp-methods/nordea.svg"
          class="nordea"
        />
      </span>
      <span>
        <img
          alt="OP pohjola"
          src="/assets/img/pnp-methods/op-pohjola.svg"
          class="oppohjola"
        />
      </span>
      <span>
        <img
          alt="aktia"
          src="/assets/img/pnp-methods/aktia.svg"
          class="aktia"
        />
      </span>
    </div>

    <div class="pnpMethods--row">
      <span>
        <img
          alt="Alandsbanken"
          src="/assets/img/pnp-methods/alandsbanken.svg"
          class="alandsbanken"
        />
      </span>
      <span>
        <img
          alt="sasstopankii"
          src="/assets/img/pnp-methods/saastopankki.svg"
          class="saastopankki"
        />
      </span>
      <span>
        <img
          alt="S-PAnkki"
          src="/assets/img/pnp-methods/s-pankki.svg"
          class="spankki"
        />
      </span>
    </div>

    <div class="pnpMethods--row">
      <span>
        <img
          alt="OmaSP"
          src="/assets/img/pnp-methods/OmaSP.svg"
          class="omasp"
        />
      </span>

      <span>
        <img
          alt="Danske Bank"
          src="/assets/img/pnp-methods/Danske-Bank.svg"
          class="danskebank"
        />
      </span>

      <span>
        <img
          alt="POP Pankki"
          src="/assets/img/pnp-methods/pop-pankki.svg"
          class="poppankki"
        />
      </span>
    </div>

    <div class="pnpMethods--row">
      <span>
        <img
          alt="Handelsbanken"
          src="/assets/img/pnp-methods/handelsbanken.svg"
          class="handelsbanken"
        />
      </span>
    </div>
  </div>
</ng-template>

<!-- Step 1: Quick Pick Template -->
<ng-template #zimplerPaymentStep1FormTemplate>
  <div class="quickPick__wrap" [formGroup]="zimplerForm">
    <div class="quickPick--row quickPick__buttons">
      <div class="quickPick--btn">
        <input
          type="radio"
          id="pnpQuickAmount50"
          name="amount"
          class="btn-radio"
          formControlName="amount"
          value="50"
          (click)="onSetTransactionAmount($event)"
        />

        <label class="btn" for="pnpQuickAmount50">
          50 {{currencySymbol}}
        </label>
      </div>

      <div class="quickPick--btn">
        <input
          type="radio"
          id="pnpQuickAmount150"
          name="amount"
          class="btn-radio"
          formControlName="amount"
          value="150"
          (click)="onSetTransactionAmount($event)"
          checked
        />

        <label class="btn" for="pnpQuickAmount150">
          150 {{currencySymbol}}
        </label>
      </div>

      <div class="quickPick--btn">
        <input
          type="radio"
          id="pnpQuickAmount250"
          name="amount"
          class="btn-radio"
          formControlName="amount"
          value="250"
          (click)="onSetTransactionAmount($event)"
        />

        <label class="btn" for="pnpQuickAmount250">
          250 {{currencySymbol}}
        </label>
      </div>
    </div>

    <div class="quickPick--row quickPick--customAmount">
      <div
        class="quickpick-inputwrap"
        [ngClass]="isFocus?'isFocus' : ''"
        [class.has-error]="zimplerForm.invalid && zimplerForm.touched && zimplerForm.controls['amount'].errors"
      >
        <ng-container *ngIf="callingFrom === 'deposit'">
          <input
            type="tel"
            id="formInputValue"
            name="amount"
            class="number-only qucik-input qi2"
            [class.error-input]="zimplerForm.invalid && zimplerForm.touched && zimplerForm.controls['amount'].errors"
            formControlName="amount"
            maxlength="8"
            (focus)="isFocus = true"
            (blur)="isFocus = false"
            [class.isFocus]="isFocus"
            (input)="onUncheckQuickPicks()"
            placeholder="{{'zimpler.Orenteramount' | translate }}"
          />
        </ng-container>

        <ng-container *ngIf="callingFrom === 'withdraw'">
          <input
            type="tel"
            id="formInputValue"
            name="amount"
            class="qucik-input qi2"
            [class.error-input]="zimplerForm.invalid && zimplerForm.touched && zimplerForm.controls['amount'].errors"
            formControlName="amount"
            maxlength="8"
            (focus)="isFocus = true"
            (blur)="isFocus = false"
            [class.isFocus]="isFocus"
            placeholder="{{'zimpler.Orenteramount' | translate }}"
          />
        </ng-container>

        <span class="currency-symbol" [class.isVisible]="isVisible">
          €
        </span>
      </div>

      <ng-container *ngTemplateOutlet="amountErrorTemplate"></ng-container>
    </div>

    <button
      class="quickPick--row quickPick--CTA"
      [setLoader]="isButtonLoader"
      [disabled]="zimplerForm.invalid || zimplerForm.pending || isButtonLoader"
      (click)="onSetDecideDepositFlow()"
    >
      {{'zimpler.continue' | translate}}
    </button>
  </div>
</ng-template>

<!-- 
  ------------------------------------------------------
  
  Error Templates 
  
  ------------------------------------------------------
-->
<ng-template #amountErrorTemplate>
  <div
    class="cashier-error-msg"
    *ngIf="
      zimplerForm.controls['amount'].errors
        && 
      zimplerForm.controls['amount'].touched
    "
  >
    <ng-container>
      <!-- {{ zimplerForm.controls['amount'].errors | json }}  -->
      <div [hidden]="!zimplerForm.controls['amount'].errors.required">
        {{ "errors.error1" | translate }}
      </div>
      <div [hidden]="!zimplerForm.controls['amount'].errors.minimumAmount">
        {{ "errors.error10" | translate }} {{errorMinimumAmountNumber}}
      </div>
      <div [hidden]="!zimplerForm.controls['amount'].errors.maximumAmount">
        {{ "errors.error11" | translate }} {{errorMaximumAmountNumber}}
      </div>
      <div [hidden]="!zimplerForm.controls['amount'].errors.onlyNumbers">
        {{ "errors.error12" | translate }}
      </div>
      <div [hidden]="!zimplerForm.controls['amount'].errors.notEnoughBalance">
        {{ "errors.error67" | translate: ({ 'withdrawalAmount':
        zimplerForm.controls['amount'].errors.withdrawableBalance, 'minValue':
        zimplerForm.controls['amount'].errors.minValue }) }}
      </div>
      <div
        [hidden]="!zimplerForm.controls['amount'].errors.exceedMaximumAmount"
      >
        {{ "errors.error68" | translate: ({ 'withdrawalAmount':
        zimplerForm.controls['amount'].errors.withdrawalAmount, 'maxValue':
        zimplerForm.controls['amount'].errors.maxValue }) }}
      </div>
      <div [hidden]="!zimplerForm.controls['amount'].errors.balanceAmount">
        {{ "errors.error69" | translate: ({ 'withdrawalAmount':
        zimplerForm.controls['amount'].errors.withdrawalAmount }) }}
      </div>
    </ng-container>
  </div>
</ng-template>
