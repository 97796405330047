/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./promotion-content-page.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@ngx-translate/core";
import * as i4 from "../../../shared/pipes/safe-html.pipe";
import * as i5 from "@angular/platform-browser";
import * as i6 from "./promotion-content-page.component";
import * as i7 from "../../../multi-languages/services/multi-language.service";
import * as i8 from "../../../registration/services/registration.service";
import * as i9 from "../../../multi-languages/services/translation.service";
import * as i10 from "../../services/promotions.service";
import * as i11 from "../../../game-groups/services/game-play.service";
import * as i12 from "@angular/router";
import * as i13 from "../../../shared/services/utility.service";
import * as i14 from "@ngrx/store";
var styles_PromotionContentPageComponent = [i0.styles];
var RenderType_PromotionContentPageComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_PromotionContentPageComponent, data: {} });
export { RenderType_PromotionContentPageComponent as RenderType_PromotionContentPageComponent };
function View_PromotionContentPageComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["id", "promotionContent"]], [[8, "innerHTML", 1]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onOpenContent($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵppd(2, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent, 0), _co.activePromotion.content)); _ck(_v, 1, 0, currVal_0); }); }
function View_PromotionContentPageComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "p", [["class", "skeleton-loader round-skelton"]], null, null, null, null, null))], null, null); }
function View_PromotionContentPageComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "loader fade-in-up"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 5, "div", [["class", "promo-page__body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "div", [["class", "promo-page__img skeleton-loader"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 3, "div", [["class", "promo-page__content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "h2", [["class", "skeleton-loader round-skelton"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PromotionContentPageComponent_3)), i1.ɵdid(7, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_0 = i1.ɵEMPTY_ARRAY.constructor(8); _ck(_v, 7, 0, currVal_0); }, null); }
function View_PromotionContentPageComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "no-results text-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("promotion.nopromotions_content_configured")); _ck(_v, 2, 0, currVal_0); }); }
export function View_PromotionContentPageComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i4.SafeHtmlPipe, [i5.DomSanitizer]), i1.ɵqud(671088640, 1, { pageHeader: 0 }), i1.ɵqud(671088640, 2, { popupOver: 0 }), (_l()(), i1.ɵeld(3, 0, null, null, 16, "section", [["class", "popout__outerWrap promotion-page fade-in all-cards page-view clearfix"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, [[1, 0], ["pageHeader", 1]], null, 8, "div", [["class", "popout__header padding isFixed fade-in-down"], ["id", "pageHeader"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(6, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(7, { pageHeaderBg: 0 }), (_l()(), i1.ɵeld(8, 0, null, null, 1, "button", [["aria-label", "Back"], ["class", "btn btn-circle btn-secondary float-left ml-16"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onGoToAllPromotions() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, "i", [["class", "icon-chevron-left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 2, "div", [["class", "page-header--title"]], null, null, null, null, null)), (_l()(), i1.ɵted(11, null, [" ", " "])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(13, 0, null, null, 6, "div", [["class", "padding popout__body promotion__content clearfix"], ["id", "popoutOverlay"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PromotionContentPageComponent_1)), i1.ɵdid(15, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PromotionContentPageComponent_2)), i1.ɵdid(17, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PromotionContentPageComponent_4)), i1.ɵdid(19, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "popout__header padding isFixed fade-in-down"; var currVal_1 = _ck(_v, 7, 0, _co.isPageHeaderBg); _ck(_v, 6, 0, currVal_0, currVal_1); var currVal_3 = (_co.activePromotion && _co.activePromotion.content); _ck(_v, 15, 0, currVal_3); var currVal_4 = (!_co.isPageAvailable && _co.isLoading); _ck(_v, 17, 0, currVal_4); var currVal_5 = (!_co.isPageAvailable && !_co.isLoading); _ck(_v, 19, 0, currVal_5); }, function (_ck, _v) { var currVal_2 = i1.ɵunv(_v, 11, 0, i1.ɵnov(_v, 12).transform("promotion.promotion")); _ck(_v, 11, 0, currVal_2); }); }
export function View_PromotionContentPageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-promotion-content-page", [], null, [["window", "scroll"]], function (_v, en, $event) { var ad = true; if (("window:scroll" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onScroll() !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_PromotionContentPageComponent_0, RenderType_PromotionContentPageComponent)), i1.ɵdid(1, 245760, null, 0, i6.PromotionContentPageComponent, [i7.MultiLanguageService, i8.RegistrationService, i9.TranslationService, i10.PromotionsService, i11.GamePlayService, i12.ActivatedRoute, i13.UtilityService, i14.Store, i12.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PromotionContentPageComponentNgFactory = i1.ɵccf("app-promotion-content-page", i6.PromotionContentPageComponent, View_PromotionContentPageComponent_Host_0, {}, {}, []);
export { PromotionContentPageComponentNgFactory as PromotionContentPageComponentNgFactory };
