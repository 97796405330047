<div
  class="header-mobile"
  id="mobileHeader"
  *ngIf="windowType === 'mobile' && !isHidden"
  [ngClass]="isNewsTickerBannerAvailable ? 'newsTickerAvailable':'newsTickerNotAvailable'"
  [class.isLP]="isLandingPage"
  [class.isPNP]="zimplerMarketsList.indexOf(languageCode) >= 0"
>
  <div class="header-mobile__logo-row">
    <div class="logo-m" (click)="onNavigateToHome()">
      <img
        src="/assets/img/logo.svg"
        alt="{{'general.site_name' | translate}}"
        title="{{'general.site_name' | translate}}"
        width="100%"
        height="100%"
      />
    </div>
  </div>
</div>

<div
  id="stickyHeader"
  class="stickyHeader padding"
  *ngIf="windowType === 'desktop' && isSearchRequired && !isHidden"
>
  <div class="stickyHeader--inner">
    <app-recent-search-games [callingFrom]="'header'"></app-recent-search-games>
  </div>
</div>
<div
  class="overlay-bg custom-overlay"
  *ngIf="isShowSearchResult"
  (click)="onCloseSearchResults()"
></div>
