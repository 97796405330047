/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./game-play-full-window.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../shared/components/loader/loader.component.ngfactory";
import * as i3 from "../../../shared/components/loader/loader.component";
import * as i4 from "./game-play-full-window.component";
import * as i5 from "../../services/game-play.service";
import * as i6 from "@angular/router";
import * as i7 from "../../../shared/services/utility.service";
import * as i8 from "@angular/common/http";
import * as i9 from "@ngrx/store";
var styles_GamePlayFullWindowComponent = [i0.styles];
var RenderType_GamePlayFullWindowComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_GamePlayFullWindowComponent, data: {} });
export { RenderType_GamePlayFullWindowComponent as RenderType_GamePlayFullWindowComponent };
export function View_GamePlayFullWindowComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "game-page-full aspectRatio-16by9"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "game-is-loading"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-loader", [], null, null, null, i2.View_LoaderComponent_0, i2.RenderType_LoaderComponent)), i1.ɵdid(3, 49152, null, 0, i3.LoaderComponent, [], null, null), (_l()(), i1.ɵeld(4, 0, null, null, 0, "div", [["class", "gameIframeWrapper"], ["id", "gameIframeWrapper"]], null, null, null, null, null))], null, null); }
export function View_GamePlayFullWindowComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-game-play-full-window", [], null, null, null, View_GamePlayFullWindowComponent_0, RenderType_GamePlayFullWindowComponent)), i1.ɵdid(1, 245760, null, 0, i4.GamePlayFullWindowComponent, [i5.GamePlayService, i6.ActivatedRoute, i7.UtilityService, i8.HttpClient, i9.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GamePlayFullWindowComponentNgFactory = i1.ɵccf("app-game-play-full-window", i4.GamePlayFullWindowComponent, View_GamePlayFullWindowComponent_Host_0, {}, {}, []);
export { GamePlayFullWindowComponentNgFactory as GamePlayFullWindowComponentNgFactory };
