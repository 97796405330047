import { createReducer, on } from "@ngrx/store";
// Actions
import { languageCodeLoaded } from "src/app/modules/multi-languages/store/actions/languages.actions";
// Enums
import { LanguageCodes } from "src/app/models/configurations/enums/language-codes.enum";
// Environments
import { environment } from "src/environments/environment";
export const LanguageStateFeatureKey = "language-collection";
export const initialState = {
    language: LanguageCodes.en,
    languageCode: environment.defaultLanguage,
};
const ɵ0 = (state, { language, languageCode }) => {
    return Object.assign({}, state, { language,
        languageCode });
};
// Reducer
const LanguagesReducer = createReducer(initialState, on(languageCodeLoaded, ɵ0));
export function reducer(state, action) {
    return LanguagesReducer(state, action);
}
export { ɵ0 };
