import { of, Subject, throwError } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";
// API Interactors
import { ApiInteractors } from "src/app/models/interactors/api.interactor";
// Libraries
import * as _ from "underscore";
import * as i0 from "@angular/core";
import * as i1 from "../../shared/services/emitter.service";
import * as i2 from "@angular/common/http";
export class NewsTickerService {
    constructor(emitterService, httpClient) {
        this.emitterService = emitterService;
        this.httpClient = httpClient;
        // Arrays
        this.bannersList = [];
        // Objects
        this.bannerDataByZoneId = {};
        // --------------------------------------------------------
        // Subject and Behaviour Subject
        this.isNewBannerAvailableSubject = new Subject();
        this.isNewBannerAvailableSubject$ = this.isNewBannerAvailableSubject.asObservable();
        this.apiInteractor = new ApiInteractors(this.httpClient);
        this.subscription =
            this.emitterService.isSuccessfulDepositSubject$.subscribe(() => {
                this.bannerDataByZoneId = {};
            });
    }
    // -----------------------------------------------------------------
    // Get Methods - Observable
    onGetStickerBanners(bannerRequest, isForce) {
        if (!_.isEmpty(this.bannersList) || isForce) {
            return of(this.bannersList);
        }
        else {
            return this.apiInteractor
                .get(`/ajax/banner/getBanners`, bannerRequest)
                .pipe(tap((bannersList) => {
                if (bannersList && bannersList.length > 0) {
                    this.bannersList = bannersList;
                }
            }), map((bannersList) => {
                return bannersList;
            }), catchError((error) => {
                return throwError(error);
            }));
        }
    }
    // -----------------------------------------------------------------
    // Set Methods
    onBroadcastNewIsBannerAvialable(isNewBannerAvailable) {
        this.isNewBannerAvailableSubject.next(isNewBannerAvailable);
    }
}
NewsTickerService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NewsTickerService_Factory() { return new NewsTickerService(i0.ɵɵinject(i1.EmitterService), i0.ɵɵinject(i2.HttpClient)); }, token: NewsTickerService, providedIn: "root" });
