import { catchError, map, share } from "rxjs/operators";
import { throwError } from "rxjs";
// API Interactors
import { ApiInteractors } from "src/app/models/interactors/api.interactor";
// Enums
import { Project } from "src/app/models/environments/project.enum";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class LimitsService {
    constructor(httpClient) {
        this.httpClient = httpClient;
        this.apiInteractor = new ApiInteractors(this.httpClient);
    }
    // -----------------------------------------------------------------
    // Get Methods - Observables Limits
    onCancelDepositLimits(cancelDepositLimitsRequest) {
        return this.apiInteractor
            .post(`/ajax/ResponsibleGaming/confirmDepositLimits`, cancelDepositLimitsRequest)
            .pipe(map((response) => {
            return response;
        }), catchError((error) => {
            return throwError(error);
        }));
    }
    // -----------------------------------------------------------------
    // Get Methods - Observables Cancel Limits
    onCancelLimits(cancelLimitsRequest) {
        return this.apiInteractor
            .post(`/ajax/ResponsibleGaming/confirmLimits`, cancelLimitsRequest, Project.Shotz)
            .pipe(map((response) => {
            return response;
        }), catchError((error) => {
            return throwError(error);
        }));
    }
    // -----------------------------------------------------------------
    // Get Methods - Observables Net Deposit Limits
    onGetNetDepositLimits() {
        return this.apiInteractor
            .get(`/ajax/ResponsibleGaming/getNetDepositLimits`, null, Project.Shotz)
            .pipe(map((response) => {
            return response;
        }), catchError((error) => {
            return throwError(error);
        }), share());
    }
    // -----------------------------------------------------------------
    // Get Methods - Observables Cancel Net Deposit Limits
    onCancelNetDepositLimits(cancelNetDepositLimitsRequest) {
        return this.apiInteractor
            .post(`/ajax/ResponsibleGaming/confirmNetDepositLimit`, cancelNetDepositLimitsRequest, Project.Shotz)
            .pipe(map((response) => {
            return response;
        }), catchError((error) => {
            return throwError(error);
        }));
    }
    // -----------------------------------------------------------------
    // Get Methods - Observables Set Net Deposit Limits
    onSetNetDepositLimits(rgLimitsRequest) {
        return this.apiInteractor
            .post(`/ajax/ResponsibleGaming/setNetDepositLimit`, rgLimitsRequest, Project.Shotz)
            .pipe(map((response) => {
            return response;
        }), catchError((error) => {
            return throwError(error);
        }));
    }
    // -----------------------------------------------------------------
    // Get Methods - Observables Responsible Gaming
    onSetResponsibleGamingLimits(rgLimitsRequest) {
        return this.apiInteractor
            .post(`/ajax/ResponsibleGaming/setLimits`, rgLimitsRequest, Project.Shotz)
            .pipe(map((response) => {
            return response;
        }), catchError((error) => {
            return throwError(error);
        }), share());
    }
    // -----------------------------------------------------------------
    // Get Methods - Observable Responsible Gaming Limits
    onGetResponsibleGamingLimits(type) {
        let endpointURL = "";
        if (type === "deposit") {
            endpointURL = `/ajax/balance/getDepositLimit`;
        }
        else if (type === "loss") {
            endpointURL = `/ajax/balance/getLossLimit`;
        }
        else if (type === "wager") {
            endpointURL = `/ajax/balance/getWagerLimit`;
        }
        else if (type === "session") {
            endpointURL = `/ajax/balance/getSessionLimit`;
        }
        return this.apiInteractor
            .get(endpointURL, null, Project.Shotz)
            .pipe(map((response) => {
            return response;
        }), catchError((error) => {
            return throwError(error);
        }));
    }
    // -----------------------------------------------------------------
    // Get Methods - Observable Responsible Gaming Limits
    onResponsibleGamingLockAccount(request) {
        return this.apiInteractor
            .post(`/ajax/ResponsibleGaming/lockAccount`, request, Project.Shotz)
            .pipe(map((response) => {
            return response;
        }), catchError((error) => {
            return throwError(error);
        }));
    }
}
LimitsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LimitsService_Factory() { return new LimitsService(i0.ɵɵinject(i1.HttpClient)); }, token: LimitsService, providedIn: "root" });
