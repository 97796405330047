import { HttpClient } from "@angular/common/http";
import { map, catchError } from "rxjs/operators";
import { Observable, throwError } from "rxjs";
import { Injectable } from "@angular/core";

// API Interactors
import { ApiInteractors } from "src/app/models/interactors/api.interactor";

// Enums
import { Project } from "src/app/models/environments/project.enum";

// Footer
import { FaqQuestions } from "src/app/modules/shared/models/faq-categories/faq-questions.model";

@Injectable({
  providedIn: "root",
})
export class StaticPageService {
  // API Interactions
  apiInteractor: ApiInteractors;

  constructor(private httpClient: HttpClient) {
    this.apiInteractor = new ApiInteractors(this.httpClient);
  }

  // -----------------------------------------------------------------
  // Get Methods
  onGetStaticContent<T, K>(request: T, optional?: Project): Observable<K> {
    return this.apiInteractor
      .get<T, K>(`/ajax/staticPage/getPage`, request, Project.Shotz)
      .pipe(
        map((response: K) => {
          return response;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  onGetFaqQuestionAndCategories(): Observable<FaqQuestions> {
    return this.apiInteractor
      .get<null, FaqQuestions>(`/ajax/faq/getQuestions`, null, Project.Shotz)
      .pipe(
        map((response: FaqQuestions) => {
          return response;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
}
