<app-side-menu-modal
  [customClass]="'changePwd'"
  [showBackBtn]="false"
  [sideMenuTitle]="'forgotpwdComponent.forgot_pwd'"
>
  <!-- modal body -->
  <ng-container sideMenuBody>
    <p class="text-center m-b-12">
      {{'forgotpwdComponent.forgot_pwd_description' | translate}}
    </p>

    <ng-container *ngIf="isEmailSent">
      <ng-container *ngTemplateOutlet="emailSentTemplate"></ng-container>
    </ng-container>

    <form [formGroup]="forgotPasswordForm" novalidate *ngIf="!isEmailSent">
      <div class="alert alert-danger fade-in-up clearfix" *ngIf="serverError">
        {{serverError}}
      </div>

      <div
        class="form-group clearfix"
        [ngClass]="{
          'has-error': forgotPasswordForm.invalid && forgotPasswordForm.touched && forgotPasswordForm.controls['txtEmail'].errors,
          'has-success': forgotPasswordForm.valid && forgotPasswordForm.touched && !forgotPasswordForm.controls['txtEmail'].errors
        }"
      >
        <div class="controls">
          <input
            type="email"
            id="txtEmail"
            name="txtEmail"
            formControlName="txtEmail"
            autocomplete="off"
            placeholder="{{'forgotpwdComponent.enter_your_email' | translate}}"
            required
          />

          <ng-container *ngTemplateOutlet="emailErrorTemplate"></ng-container>
        </div>
      </div>

      <button
        type="button"
        id="btnForgotPassword"
        class="btn btn-secondary btn-md full-width m-t-24 m-b-24 btn-round form-button"
        [setLoader]="isButtonLoader"
        [disabled]="forgotPasswordForm.invalid || forgotPasswordForm.pending || isButtonLoader"
        aria-label="Submit"
        (click)="onSendResetPasswordLink()"
      >
        {{'forgotpwdComponent.send_me_code' | translate}}
      </button>

      <ng-container *ngTemplateOutlet="backToLoginTemplate"></ng-container>
    </form>
  </ng-container>
</app-side-menu-modal>

<!-- 
  ------------------------------------------------------
  
  Error Templates 
  
  ------------------------------------------------------
-->
<ng-template #emailErrorTemplate>
  <div
    class="error-msg fade-in-down"
    *ngIf="
      forgotPasswordForm.controls['txtEmail'].errors
        && 
      forgotPasswordForm.controls['txtEmail'].touched
    "
  >
    <ng-container>
      <!-- {{ forgotPasswordForm.controls['txtEmail'].errors | json }}  -->
      <div [hidden]="!forgotPasswordForm.controls['txtEmail'].errors.required">
        {{ "errors.error1" | translate }}
      </div>
      <div
        [hidden]="!forgotPasswordForm.controls['txtEmail'].errors.invalidEmail"
      >
        {{ "errors.error22" | translate }}
      </div>
      <div
        [hidden]="!forgotPasswordForm.controls['txtEmail'].errors.cannotBeLessThan"
      >
        {{ "errors.error61" | translate }}
      </div>
      <div
        [hidden]="!forgotPasswordForm.controls['txtEmail'].errors.mustContainMinimum"
      >
        {{ "errors.error23" | translate }}
      </div>
      <div
        [hidden]="!forgotPasswordForm.controls['txtEmail'].errors?.invalidEmailResponse"
      >
        {{ "errors.error28" | translate }}
      </div>
      <div
        [hidden]="!forgotPasswordForm.controls['txtEmail'].errors?.invalidNicknameResponse"
      >
        {{ "errors.error25" | translate }}
      </div>
      <div
        [hidden]="!forgotPasswordForm.controls['txtEmail'].errors?.notUniqueEmail"
      >
        {{ "errors.error29" | translate }}
      </div>
      <div
        [hidden]="!forgotPasswordForm.controls['txtEmail'].errors?.notUniqueNickname"
      >
        {{ "errors.error30" | translate }}
      </div>
    </ng-container>
  </div>
</ng-template>

<!-- 
  ------------------------------------------------------
  
  Templates 
  
  ------------------------------------------------------
-->
<!-- Sent Email Template -->
<ng-template #emailSentTemplate>
  <div class="alert alert-success text-center fade-in-up clearfix m-t-24">
    {{'forgotpwdComponent.sent_email' | translate}}
    <strong>{{emailId}}</strong>
    {{'forgotpwdComponent.instructions' | translate}}
  </div>

  <p class="link-text text-center m-t-16 m-b-16">
    <strong class="text-underline cursor-hand" (click)="onGoToLoginPage()"
      >{{'forgotpwdComponent.backto_login' | translate}}</strong
    >
  </p>
</ng-template>

<!-- Back to Login Template -->
<ng-template #backToLoginTemplate>
  <p class="link-text text-center m-t-4">
    <strong class="text-underline cursor-hand" (click)="onGoToLoginPage()"
      >{{'forgotpwdComponent.backto_login' | translate}}</strong
    >
  </p>
</ng-template>
