import { Subject } from "rxjs";
// Configurations
import { localStorageKeys } from "src/app/modules/multi-languages/configurations/localstorage-keys.configurations";
import * as i0 from "@angular/core";
import * as i1 from "../../limits/services/limits.service";
export class SessionService {
    constructor(limitsService) {
        this.limitsService = limitsService;
        // Strings
        this.userCountryLanguage = "";
        this.languageCode = "";
        this.sessionTimeUpdatedSubject = new Subject();
        this.sessionTimeUpdatedSubject$ = this.sessionTimeUpdatedSubject.asObservable();
    }
    // -----------------------------------------------------------------
    // Get Methods
    getIsUserLoggedIn() {
        const isUserLoggedIn = JSON.parse(localStorage.getItem(localStorageKeys.stz_user));
        return isUserLoggedIn ? true : false;
    }
    // -----------------------------------------------------------------
    // Set Methods
    onSetSessionVariable(sessionKey, sessionLimit) {
        sessionStorage.setItem(sessionKey, JSON.stringify(sessionLimit));
    }
    onSessionContinue() {
        let session = JSON.parse(sessionStorage.getItem("session"));
        if (session && session.sessionLimit) {
            this.onSetSessionVariable("session", {
                sessionLimit: session.sessionLimit,
                availableSessionLimit: session.sessionLimit * 60,
            });
            this.onStartLoginSession();
        }
        else {
            this.limitsService
                .onGetResponsibleGamingLimits("session")
                .subscribe((limitsResponse) => {
                if (limitsResponse &&
                    limitsResponse.limits &&
                    limitsResponse.limits.overall) {
                    let limits = limitsResponse.limits.overall.value;
                    this.onSetSessionVariable("session", {
                        sessionLimit: limits,
                        availableSessionLimit: limits * 60,
                    });
                    this.onStartLoginSession();
                }
            });
        }
    }
    onBroadcastSessionUpdated(isSessionTimeUpdated) {
        this.sessionTimeUpdatedSubject.next(isSessionTimeUpdated);
    }
    onStartLoginSession() {
        this.onBroadcastSessionUpdated(true);
        if (!this.sessionLimitInterval) {
            this.sessionLimitInterval = setInterval(() => {
                let session = JSON.parse(sessionStorage.getItem("session"));
                if (session) {
                    if (session.availableSessionLimit - 1 > 0) {
                        session.availableSessionLimit = session.availableSessionLimit - 1;
                        sessionStorage.setItem("session", JSON.stringify(session));
                    }
                    else {
                        clearInterval(this.sessionLimitInterval);
                        this.sessionLimitInterval = undefined;
                        setTimeout(() => {
                            document.getElementById("sessionPopup").style.display = "flex";
                            document.body.classList.add("overflow-hidden");
                        }, 100);
                    }
                }
                else {
                    clearInterval(this.sessionLimitInterval);
                    this.sessionLimitInterval = undefined;
                }
            }, 1000);
        }
    }
    /*
      Information related to socket is stored her
      we use this data in socket service ts file
    */
    onSetSocketDetails(socketDetails) {
        this.socketDetails = socketDetails;
    }
}
SessionService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SessionService_Factory() { return new SessionService(i0.ɵɵinject(i1.LimitsService)); }, token: SessionService, providedIn: "root" });
