/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./hosted-cashier.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../payment-bonus/payment-bonus.component.ngfactory";
import * as i3 from "../payment-bonus/payment-bonus.component";
import * as i4 from "../../../rewards/services/cashback-promo.service";
import * as i5 from "../../../multi-languages/services/multi-language.service";
import * as i6 from "../../../user/services/user-details.service";
import * as i7 from "../../../rewards/services/reward.service";
import * as i8 from "../../../shared/services/utility.service";
import * as i9 from "../../services/cashier.service";
import * as i10 from "@ngrx/store";
import * as i11 from "@angular/common";
import * as i12 from "../../../shared/components/loader/loader.component.ngfactory";
import * as i13 from "../../../shared/components/loader/loader.component";
import * as i14 from "@ngx-translate/core";
import * as i15 from "../../../navigation/components/side-menu-modal/side-menu-modal.component.ngfactory";
import * as i16 from "../../../navigation/components/side-menu-modal/side-menu-modal.component";
import * as i17 from "@angular/router";
import * as i18 from "./hosted-cashier.component";
import * as i19 from "../../../multi-languages/services/translation.service";
import * as i20 from "../../../shared/services/emitter.service";
import * as i21 from "../../../shared/services/gtm.service";
var styles_HostedCashierComponent = [i0.styles];
var RenderType_HostedCashierComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HostedCashierComponent, data: {} });
export { RenderType_HostedCashierComponent as RenderType_HostedCashierComponent };
function View_HostedCashierComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-payment-bonus", [], null, [[null, "callOnSelectBonus"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("callOnSelectBonus" === en)) {
        var pd_0 = (_co.onSelectedBonus($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_PaymentBonusComponent_0, i2.RenderType_PaymentBonusComponent)), i1.ɵdid(1, 638976, null, 0, i3.PaymentBonusComponent, [i4.CashbackPromoService, i5.MultiLanguageService, i6.UserDetailsService, i7.RewardsService, i8.UtilityService, i9.CashierService, i10.Store], { clearSelectedBonus: [0, "clearSelectedBonus"], activeBonusData: [1, "activeBonusData"], callingFrom: [2, "callingFrom"], rewardSize: [3, "rewardSize"] }, { callOnSelectBonus: "callOnSelectBonus" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isClearSelectedBonus; var currVal_1 = _co.selectedBonus; var currVal_2 = "hostedCashier"; var currVal_3 = "basic"; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
function View_HostedCashierComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HostedCashierComponent_2)), i1.ɵdid(2, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.cashierType === "deposit") && !_co.isRedirectNewProviderWindow); _ck(_v, 2, 0, currVal_0); }, null); }
function View_HostedCashierComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "piq-loader"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-loader", [], null, null, null, i12.View_LoaderComponent_0, i12.RenderType_LoaderComponent)), i1.ɵdid(2, 49152, null, 0, i13.LoaderComponent, [], null, null), (_l()(), i1.ɵeld(3, 0, null, null, 2, "p", [["class", "m-t-12 m-b-12 text-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, [" ", " "])), i1.ɵpid(131072, i14.TranslatePipe, [i14.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform("cashier.fetchingPymentMethods")); _ck(_v, 4, 0, currVal_0); }); }
function View_HostedCashierComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "p", [["class", "text-center forefiet-msg-text"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵpid(131072, i14.TranslatePipe, [i14.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("cashier.withdraw_forefeit_bonus")); _ck(_v, 2, 0, currVal_0); }); }
export function View_HostedCashierComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "app-side-menu-modal", [], null, null, null, i15.View_SideMenuModalComponent_0, i15.RenderType_SideMenuModalComponent)), i1.ɵdid(1, 770048, null, 0, i16.SideMenuModalComponent, [i5.MultiLanguageService, i8.UtilityService, i10.Store, i17.Router], { isShowBackButton: [0, "isShowBackButton"], sideMenuTitle: [1, "sideMenuTitle"], customClass: [2, "customClass"] }, null), (_l()(), i1.ɵeld(2, 0, null, 1, 7, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HostedCashierComponent_1)), i1.ɵdid(4, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HostedCashierComponent_3)), i1.ɵdid(6, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HostedCashierComponent_4)), i1.ɵdid(8, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 0, "div", [["id", "cashier"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isShowBackButton; var currVal_1 = _co.sideMenuTitleText; var currVal_2 = "hostedCashier"; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = _co.isCashierIFrameLoaded; _ck(_v, 4, 0, currVal_3); var currVal_4 = _co.isLoading; _ck(_v, 6, 0, currVal_4); var currVal_5 = ((_co.cashierType === "withdrawal") && _co.isCashierIFrameLoaded); _ck(_v, 8, 0, currVal_5); }, null); }
export function View_HostedCashierComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-hosted-cashier", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onResize() !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_HostedCashierComponent_0, RenderType_HostedCashierComponent)), i1.ɵdid(1, 770048, null, 0, i18.HostedCashierComponent, [i6.UserDetailsService, i19.TranslationService, i9.CashierService, i20.EmitterService, i8.UtilityService, i21.GtmService, i10.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HostedCashierComponentNgFactory = i1.ɵccf("app-hosted-cashier", i18.HostedCashierComponent, View_HostedCashierComponent_Host_0, { cashierType: "cashierType", isShowBackButton: "isShowBackButton", cashierStep: "cashierStep" }, {}, []);
export { HostedCashierComponentNgFactory as HostedCashierComponentNgFactory };
