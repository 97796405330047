import { Injectable, Inject } from "@angular/core";
import { DOCUMENT } from "@angular/common";

// Configurations
import { localStorageKeys } from "src/app/modules/multi-languages/configurations/localstorage-keys.configurations";
import {
  seoLanguageCodeUpdateConfigurations,
  localToCmsLanguageConfigurations,
  hrefLanguageConfigurations,
} from "src/app/configurations/main.configurations";

// Environments
import { environment } from "src/environments/environment";

// Models
import { MultiLanguageURL } from "src/app/modules/shared/models/multi-language.model";

// Utilities
import { supportedMarketsList } from "src/app/modules/multi-languages/utilities/languages.utilities";

@Injectable({
  providedIn: "root",
})
export class MultiLanguageService {
  // Strings
  languageCode: string = environment.defaultLanguage;

  // Arrays
  acceptedLanguagesList: string[] = [];

  constructor(@Inject(DOCUMENT) private document: Document) {
    this.acceptedLanguagesList = supportedMarketsList();
  }

  // -----------------------------------------------------------------
  // Get Methods
  getLanguageCode(): string {
    if (localStorage.getItem(localStorageKeys.languageCode)) {
      return localStorage.getItem(localStorageKeys.languageCode);
    } else {
      const urlSegmentsList: string[] = this.getDecodedCurrentPath().split("/");

      let languageCode: string =
        urlSegmentsList && urlSegmentsList.length >= 2
          ? urlSegmentsList[1]
          : "";

      if (languageCode && this.acceptedLanguagesList.includes(languageCode)) {
        languageCode = urlSegmentsList[1];
      } else {
        languageCode = environment.defaultLanguage;
      }

      return languageCode;
    }
  }

  getDecodedCurrentPath(): string {
    return decodeURIComponent(window.location.pathname);
  }

  getActiveLobby(path: string): string {
    let activeLobby: string = "";

    switch (path) {
      case "spielautomaten":
      case "slots":
      case "spilleautomater":
      case "kolikkopelit":
      case "nyerogepek": {
        activeLobby = "slots";

        break;
      }
      case "table-games":
      case "tischspiele":
      case "bordspill":
      case "kolikkopelit":
      case "asztali-jatekok": {
        activeLobby = "table-games";

        break;
      }
      case "all":
      case "alle":
      case "kaikki":
      case "osszes": {
        activeLobby = "all";

        break;
      }
      case "game":
      case "spiel":
      case "spill":
      case "peli":
      case "jatek": {
        activeLobby = "game";

        break;
      }
      case "promotions":
      case "aktionen":
      case "kampanjer":
      case "kampanjat":
      case "promociok": {
        activeLobby = "promotions";

        break;
      }
      case "rewards":
      case "boni":
      case "premier":
      case "palkinnot":
      case "jutalmak": {
        activeLobby = "rewards";

        break;
      }
      default: {
        activeLobby = path;

        break;
      }
    }

    return activeLobby;
  }

  getTranslatedText(value: string, languageCode: string): string {
    switch (languageCode) {
      case "en-row":
      case "en-ez":
      case "en-ie":
        if (value === "promotions") {
          return "promotions";
        }
        break;
      case "de-eu":
        if (value === "promotions") {
          return "aktionen";
        }
        break;
      case "fi-fi":
        if (value === "promotions") {
          return "kampanjat";
        }
        break;
      case "en-ca":
        if (value === "promotions") {
          return "promotions";
        }
        break;
      case "nb-no":
        if (value === "promotions") {
          return "kampanjer";
        }
        break;
      default:
        if (value === "promotions") {
          return "promotions";
        }
    }
  }

  // -----------------------------------------------------------------
  // Set Methods
  onSetCanonicalURL(multiLanguageURL: MultiLanguageURL | string): void {
    let urlPath: string = "";

    if (typeof multiLanguageURL === "string") {
      switch (multiLanguageURL) {
        case "homepage": {
          multiLanguageURL = {
            en: "casino",
            ja: "casino",
            fi: "casino",
            cd: "casino",
            nl: "casino",
            nz: "casino",
            no: "casino",
            at: "casino",
            ie: "casino",
            hu: "casino",
          };
          break;
        }
        case "liveCasinoPage": {
          multiLanguageURL = {
            en: "live-casino",
            ja: "live-casino",
            fi: "live-casino",
            cd: "live-casino",
            nl: "live-casino",
            nz: "live-casino",
            no: "live-casino",
            at: "live-casino",
            ie: "live-casino",
            hu: "live-casino",
          };
          break;
        }
        case "promotionspage": {
          multiLanguageURL = {
            en: "promotions",
            no: "kampanjer",
            fi: "kampanjat",
            ja: "promotions",
            nz: "promotions",
            nl: "promotions",
            cd: "promotions",
            at: "aktionen",
            ie: "promotions",
            hu: "promociok",
          };
          break;
        }
        case "landingpage": {
          multiLanguageURL = {
            en: "",
            ja: "",
            fi: "",
            cd: "",
            nl: "",
            nz: "",
            no: "",
            at: "",
            ie: "",
            hu: "",
          };
          break;
        }
        case "studiopage": {
          let path: string = this.getDecodedCurrentPath().replace(
            `/${this.languageCode}/`,
            ""
          );

          multiLanguageURL = {
            en: path,
            ja: path,
            fi: path,
            cd: path,
            nl: path,
            nz: path,
            no: path,
            at: path,
            ie: path,
            hu: path,
          };
          break;
        }
      }
    }

    if (multiLanguageURL && Object.keys(multiLanguageURL).length > 0) {
      urlPath =
        multiLanguageURL[localToCmsLanguageConfigurations[this.languageCode]];

      this.onSetCanonicalLink(urlPath);

      this.onSetAlternateLinks(multiLanguageURL);

      this.onSetDefaultLink(multiLanguageURL);

      document
        .querySelector("html")
        .setAttribute(
          "lang",
          seoLanguageCodeUpdateConfigurations[this.languageCode]
        );
    }
  }

  onSetCanonicalLink(urlPath: string): void {
    let canonicalLink: HTMLLinkElement =
      this.document.querySelector(`link[rel='canonical']`) || null;

    if (!canonicalLink) {
      const link: HTMLLinkElement = this.document.createElement("link");

      link.setAttribute("rel", "canonical");

      this.document.head.appendChild(link);

      link.setAttribute(
        "href",
        `${environment.siteUrl}/${this.languageCode}/${urlPath}`
      );

      link.setAttribute(
        "hreflang",
        hrefLanguageConfigurations[this.languageCode]
      );
    } else {
      canonicalLink.setAttribute("rel", "canonical");

      canonicalLink.setAttribute(
        "href",
        `${environment.siteUrl}/${this.languageCode}/${urlPath}`
      );

      canonicalLink.setAttribute(
        "hreflang",
        hrefLanguageConfigurations[this.languageCode]
      );
    }
  }

  onSetAlternateLinks(multiLanguageURL: string | MultiLanguageURL): void {
    let alternateLink: NodeListOf<Element> = this.document.querySelectorAll(
      `link[rel='alternate']`
    );

    if (alternateLink.length > 0) {
      alternateLink.forEach((removeExistedAlternateLink: Element) =>
        removeExistedAlternateLink.remove()
      );
    }

    supportedMarketsList().forEach((language: string) => {
      const link: HTMLLinkElement = this.document.createElement("link");

      let urlPath: string =
        multiLanguageURL[localToCmsLanguageConfigurations[language]];

      link.setAttribute("rel", "alternate");

      this.document.head.appendChild(link);

      link.setAttribute(
        "href",
        `${environment.siteUrl}/${language}/${urlPath}`
      );

      link.setAttribute("hreflang", hrefLanguageConfigurations[language]);
    });
  }

  onSetDefaultLink(multiLanguageURL: string | MultiLanguageURL): void {
    const linkDefault: HTMLLinkElement = this.document.createElement("link");

    let urlPath: string =
      multiLanguageURL[
        localToCmsLanguageConfigurations[environment.defaultLanguage]
      ];

    linkDefault.setAttribute("rel", "alternate");

    this.document.head.appendChild(linkDefault);

    linkDefault.setAttribute(
      "href",
      `${environment.siteUrl}/${environment.defaultLanguage}/${urlPath}`
    );

    linkDefault.setAttribute("hreflang", "x-default");
  }
}
