<app-side-menu-modal
  [customClass]="'selfExclusion'"
  [sideMenuTitle]="'limits.self_exclusion'"
>
  <!-- modal body -->

  <ng-container sideMenuBody>
    <ng-container *ngTemplateOutlet="responseTemplate"></ng-container>
    
    <p>
      {{ "limits.self_exclusion_para1" | translate }}
      <a href="mailto:support@highroller.com">support@highroller.com.</a>
    </p>

    <p>
      {{ "limits.self_exclusion_para2" | translate }}
      <a href="mailto:support@highroller.com">support@highroller.com </a>
      {{ "limits.self_exclusion_para2a" | translate }}
    </p>

    <div class="duration-btn-group m-t-16">
      <div
        class="btn btn-sm btn-round"
        [ngClass]="suspendAccountForm.controls['rgCoolOffPeriodInDays'].value === 1 ? 'btn-primary' : 'btn-limits'"
        (click)="onSetExclusionPeriod(1)"
      >
        {{ "limits.24_hrs" | translate }}
      </div>

      <div
        class="btn btn-sm btn-round"
        [ngClass]="suspendAccountForm.controls['rgCoolOffPeriodInDays'].value === 7 ? 'btn-primary' : 'btn-limits'"
        (click)="onSetExclusionPeriod(7)"
      >
        {{ "limits.7_days" | translate }}
      </div>
    </div>

    <button
      class="btn btn-primary btn-md full-width btn-round"
      [disabled]="suspendAccountForm.invalid || suspendAccountForm.pending"
      (click)="onOpenConfirmationDailogue()"
    >
      {{ "limits.self_exclude" | translate }}
    </button>
  </ng-container>
</app-side-menu-modal>

<!-- Enter your Password to suspend your account 180 Days -->
<ng-container *ngTemplateOutlet="selfExcludedModalTemplate"></ng-container>

<!-- 
  ------------------------------------------------------
  
  Error Templates 
  
  ------------------------------------------------------
-->
<ng-template #passwordErrorTemplate>
  <div
    class="error-msg fade-in-down"
    *ngIf="
      suspendAccountForm.controls['password'].errors
        && 
      suspendAccountForm.controls['password'].touched
    "
  >
    <ng-container>
      <!-- 
        {{ suspendAccountForm.controls['password'].errors | json }}
        {{ suspendAccountForm.errors | json }} 
      -->
      <div [hidden]="!suspendAccountForm.controls['password'].errors.required">
        {{ "errors.error1" | translate }}
      </div>
    </ng-container>
  </div>
</ng-template>

<!-- 
  ------------------------------------------------------
  
  Response Templates 
  
  ------------------------------------------------------
-->
<ng-template #responseTemplate>
  <ng-container *ngIf="serverError">
    <div class="alert alert-danger fade-in-up clearfix">
      {{serverError}}
    </div>
  </ng-container>
</ng-template>


<!-- 
  ------------------------------------------------------
  
  Modal Templates 
  
  ------------------------------------------------------
-->
<!-- Self Excluded Modal Template -->
<ng-template #selfExcludedModalTemplate>
  <!-- Enter your Password to suspend your account 180 Days -->
  <section
    class="c-dialog__backdrop atLeftMenu exclusion-popup"
    *ngIf="isExclusionPopup"
    id="selfExclusionPopup"
  >
    <div class="c-dialog">
      <ng-container *ngTemplateOutlet="dialogHeaderTemplate"></ng-container>

      <ng-container *ngTemplateOutlet="dailogBodyTemplate"></ng-container>

      <ng-container *ngTemplateOutlet="dialogFooterTemplate"></ng-container>
    </div>
  </section>
</ng-template>

<!-- 
  ------------------------------------------------------
  
  Dialog Templates 
  
  ------------------------------------------------------
-->
<ng-template #dialogHeaderTemplate>
  <div class="c-dialog__header">
    <img
      src="/assets/img/logo.svg"
      alt="{{'general.site_name' | translate}}"
      width="100%"
      height="100%"
    />
  </div>
</ng-template>

<ng-template #dailogBodyTemplate>
  <div class="c-dialog__body" [formGroup]="suspendAccountForm">
    <p>
      {{'limits.sure_want_self_exclude' | translate}}
      <strong>{{ startDateFormatted }}</strong> {{'limits.to' | translate}}
      <strong>{{ endDateFormatted }}</strong> ?
    </p>

    <div
      *marketBasedComponentDisplay="{'flowType': UserFlowTypes.NORMAL_FLOW, 'langCode':languageCode}"
      class="form-group clearfix password-input"
      [ngClass]="{
        'has-error': suspendAccountForm.invalid && suspendAccountForm.touched && suspendAccountForm.controls['password'].errors,
        'has-success': suspendAccountForm.valid && suspendAccountForm.touched && !suspendAccountForm.controls['password'].errors
      }"
    >
      <div class="controls">
        <input
          [type]="isPassword ? 'text' : 'password'"
          id="Password"
          class="isPassword"
          formControlName="password"
          placeholder="{{'limits.enter_password' | translate}}"
        />

        <span class="icon-suffix" (click)="isPassword = !isPassword">
          <i [className]="!isPassword ? 'icon-eye' : 'icon-eye-hide'"></i>
        </span>

        <ng-container *ngTemplateOutlet="passwordErrorTemplate"></ng-container>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #dialogFooterTemplate>
  <div class="c-dialog__footer">
    <a
      class="btn-yes"
      [class.disabled]="suspendAccountForm.invalid || suspendAccountForm.pending"
      aria-label="Yes"
      (click)="onConfirmSuspendAccount()"
      >{{'general.yes' | translate}}</a
    >

    <a class="btn-no" aria-label="No" (click)="onCloseConfirmationDailogue()"
      >{{'general.no' | translate}}</a
    >
  </div>
</ng-template>
