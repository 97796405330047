/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./deposit-reward-card.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../cashout-reward/cashout-reward.component.ngfactory";
import * as i3 from "../cashout-reward/cashout-reward.component";
import * as i4 from "../../../user/services/user-details.service";
import * as i5 from "../release-restrict-reward/release-restrict-reward.component.ngfactory";
import * as i6 from "../release-restrict-reward/release-restrict-reward.component";
import * as i7 from "@angular/common";
import * as i8 from "./deposit-reward-card.component";
import * as i9 from "../../../account/services/pay-n-play-cashier.service";
import * as i10 from "../../../shared/services/utility.service";
import * as i11 from "../../../account/services/cashier.service";
import * as i12 from "@ngrx/store";
var styles_DepositRewardCardComponent = [i0.styles];
var RenderType_DepositRewardCardComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DepositRewardCardComponent, data: {} });
export { RenderType_DepositRewardCardComponent as RenderType_DepositRewardCardComponent };
function View_DepositRewardCardComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-cashout-reward", [], null, [[null, "callOpenRewardTCPopUp"], [null, "callOnBonusSelected"], [null, "callOnOpenDeposit"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("callOpenRewardTCPopUp" === en)) {
        var pd_0 = (_co.onOpenRewardTCPopUp($event) !== false);
        ad = (pd_0 && ad);
    } if (("callOnBonusSelected" === en)) {
        var pd_1 = (_co.onSelectBonus($event) !== false);
        ad = (pd_1 && ad);
    } if (("callOnOpenDeposit" === en)) {
        var pd_2 = (_co.onOpenDepositView($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i2.View_CashoutRewardComponent_0, i2.RenderType_CashoutRewardComponent)), i1.ɵdid(2, 638976, null, 0, i3.CashoutRewardComponent, [i4.UserDetailsService], { currencyCode: [0, "currencyCode"], isRealUser: [1, "isRealUser"], selectedBonus: [2, "selectedBonus"], bonusDetails: [3, "bonusDetails"], callingFrom: [4, "callingFrom"], rewardSize: [5, "rewardSize"] }, { callOpenRewardTCPopUp: "callOpenRewardTCPopUp", callOnBonusSelected: "callOnBonusSelected", callOnOpenDeposit: "callOnOpenDeposit" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.currencyCode; var currVal_1 = _co.isRealUser; var currVal_2 = _co.selectedBonus; var currVal_3 = _co.bonusDetails; var currVal_4 = _co.callingFrom; var currVal_5 = _co.rewardSize; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, null); }
function View_DepositRewardCardComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-release-restriction", [], null, [[null, "callOpenRewardTCPopUp"], [null, "callOnBonusSelected"], [null, "callOnOpenDeposit"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("callOpenRewardTCPopUp" === en)) {
        var pd_0 = (_co.onOpenRewardTCPopUp($event) !== false);
        ad = (pd_0 && ad);
    } if (("callOnBonusSelected" === en)) {
        var pd_1 = (_co.onSelectBonus($event) !== false);
        ad = (pd_1 && ad);
    } if (("callOnOpenDeposit" === en)) {
        var pd_2 = (_co.onOpenDepositView($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i5.View_ReleaseRestrictionRewardComponent_0, i5.RenderType_ReleaseRestrictionRewardComponent)), i1.ɵdid(2, 638976, null, 0, i6.ReleaseRestrictionRewardComponent, [i4.UserDetailsService], { currencyCode: [0, "currencyCode"], selectedBonus: [1, "selectedBonus"], isRealUser: [2, "isRealUser"], bonusDetails: [3, "bonusDetails"], callingFrom: [4, "callingFrom"], rewardSize: [5, "rewardSize"] }, { callOpenRewardTCPopUp: "callOpenRewardTCPopUp", callOnBonusSelected: "callOnBonusSelected", callOnOpenDeposit: "callOnOpenDeposit" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.currencyCode; var currVal_1 = _co.selectedBonus; var currVal_2 = _co.isRealUser; var currVal_3 = _co.bonusDetails; var currVal_4 = _co.callingFrom; var currVal_5 = _co.rewardSize; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, null); }
function View_DepositRewardCardComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DepositRewardCardComponent_2)), i1.ɵdid(2, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DepositRewardCardComponent_3)), i1.ɵdid(4, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (((_co.bonusDetails == null) ? null : _co.bonusDetails.bonusCategory) === "CASHOUT_RESTRICT"); _ck(_v, 2, 0, currVal_0); var currVal_1 = (((_co.bonusDetails == null) ? null : _co.bonusDetails.bonusCategory) === "RELEASE_RESTRICT"); _ck(_v, 4, 0, currVal_1); }, null); }
export function View_DepositRewardCardComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_DepositRewardCardComponent_1)), i1.ɵdid(1, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.bonusDetails; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_DepositRewardCardComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-deposit-reward-card", [], null, null, null, View_DepositRewardCardComponent_0, RenderType_DepositRewardCardComponent)), i1.ɵdid(1, 770048, null, 0, i8.DepositRewardCardComponent, [i9.PayNPlayCashierService, i4.UserDetailsService, i10.UtilityService, i11.CashierService, i12.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DepositRewardCardComponentNgFactory = i1.ɵccf("app-deposit-reward-card", i8.DepositRewardCardComponent, View_DepositRewardCardComponent_Host_0, { selectedBonus: "selectedBonus", bonusDetails: "bonusDetails", callingFrom: "callingFrom", rewardSize: "rewardSize" }, { callOpenRewardTCPopUp: "callOpenRewardTCPopUp", callOnNavigateToDeposit: "callOnNavigateToDeposit", callOnBonusSelected: "callOnBonusSelected" }, []);
export { DepositRewardCardComponentNgFactory as DepositRewardCardComponentNgFactory };
