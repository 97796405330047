export const replaceAllUtility = (
  value: string,
  mapper: {
    [key: string]: string;
  }
) => {
  return value.replace(
    new RegExp(Object.keys(mapper).join("|"), "gi"),
    (matched: string) => mapper[matched]
  );
};
