// Models
import { RegilyWelcomeOffer } from "src/app/modules/registration/models/regily-welcome-offer.model";

// Models
import { TranslationService } from "src/app/modules/multi-languages/services/translation.service";
import { AffiliateService } from "src/app/modules/auth/services/affiliate.service";

export const getRegilyWelcomeOffer = (
  affiliateService: AffiliateService,
  translationService: TranslationService,
  languageCode: string
): RegilyWelcomeOffer => {
  let title_key: string = "registration.register_welcome_bonus_firstline";

  let description_key: string =
    "registration.register_welcome_bonus_secondline";

  let affilateLanguage: string =
    affiliateService.getAffiliateCookies("affiliateLang");

  let getAffiliateValue: string = "";

  let affiliateValuesList: string[] = translationService
    .get("affiliateids.affiliateValue")
    .split(",");

  if (affiliateService.getAffiliateCookies("affId")) {
    getAffiliateValue = affiliateService
      .getAffiliateCookies("affId")
      .split("_")[0];
  }

  if (
    getAffiliateValue &&
    affilateLanguage &&
    affiliateValuesList &&
    affiliateValuesList.indexOf(getAffiliateValue) > -1 &&
    affilateLanguage === languageCode
  ) {
    title_key = `affiliate.${getAffiliateValue}_regily_line1`;

    description_key = `affiliate.${getAffiliateValue}_free_spins`;
  } else if (window.location.pathname.split("/")[2] === "live-casino") {
    title_key = "registration.register_livecasino_welcome_bonus_firstline";

    description_key =
      "registration.register_livecasino_welcome_bonus_secondline";
  }

  let regilyWelcomeOffer: RegilyWelcomeOffer = {
    welcomeModal: false,
    bonusConfig: {
      title: translationService.get(title_key),
      description: translationService.get(description_key),
    },
  };

  return regilyWelcomeOffer;
};

export const getRegilyVariantByLocale = (
  languageCode: string
): "v2" | "core" => {
  if (languageCode === "en-eu") {
    return "v2";
  } else {
    return "core";
  }
};
