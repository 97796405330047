import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

// Modules
import { SharedModule } from "src/app/modules/shared/shared.module";

@NgModule({
  imports: [
    ReactiveFormsModule,
    TranslateModule,
    CommonModule,
    SharedModule,
    FormsModule,
  ],
})
export class ValidatorsModule {}
