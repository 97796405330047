import { map, catchError, pairwise, filter } from "rxjs/operators";
import { Router, RoutesRecognized } from "@angular/router";
import { Injectable, Inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { DOCUMENT } from "@angular/common";
import { Store } from "@ngrx/store";
import {
  BehaviorSubject,
  Subscription,
  Observable,
  throwError,
  Subject,
  of,
} from "rxjs";

// Actions
import {
  userProfileBalanceRequested,
  profileBalanceRequested,
} from "src/app/modules/user/store/actions/profile.actions";

// API Interactors
import { ApiInteractors } from "src/app/models/interactors/api.interactor";

// Configurations
import { marketLocaleCurrencyMappingConfigurations } from "src/app/configurations/main.configurations";

// Enums
import { WorldCurrencyCode } from "src/app/models/configurations/enums/localization/world-currencies.enum";
import { StatusResponse } from "src/app/models/api/status.response";
import { Project } from "src/app/models/environments/project.enum";

// Environments
import { environment } from "src/environments/environment";

// Libraries
import * as _ from "underscore";

// Models
import { UpdateUserCredentialsResponse } from "src/app/modules/shared/models/update-user-credentials-response.model";
import { UpdateUserCredentialsRequest } from "src/app/modules/shared/models/update-user-credentials-request.model";
import { KYCUserDetailsResponse } from "src/app/modules/kyc/models/kyc-details/kyc-user-details-response.model";
import { ValidationResponse } from "src/app/modules/registration/models/validation/validation-response.model";
import { ValidationRequest } from "src/app/modules/registration/models/validation/validation-request.model";
import { NavigationAfterLogin } from "src/app/modules/shared/models/navigation/navigate-after-login.model";
import { KYCDetailsToUnblock } from "src/app/modules/kyc/models/kyc-details/kyc-details-to-unblock.model";
import { UserProfileBalance } from "src/app/modules/shared/models/profiles/user-profile-balance.model";
import { NavigationData } from "src/app/modules/shared/models/navigation/navigation.model";
import { ActiveTab } from "src/app/modules/shared/models/active-tab.model";

// Reducers
import { AppState } from "src/app/store/reducers";

// Selectors
import { selectLanguageCode } from "src/app/modules/multi-languages/store/selectors/languages.selectors";
import {
  selectAuthLoginIsLoggedOut,
  selectAuthLoginIsLoggedIn,
} from "src/app/modules/auth/store/selectors/auth.selectors";

// Services
import { TranslationService } from "src/app/modules/multi-languages/services/translation.service";
import { UserDetailsService } from "src/app/modules/user/services/user-details.service";
import { ProfileService } from "src/app/modules/user/services/profile.service";
import { MainService } from "src/app/modules/shared/services/main.service";

// Utilities
import { supportedMarketsList } from "src/app/modules/multi-languages/utilities/languages.utilities";

@Injectable({
  providedIn: "root",
})
export class CommonService {
  // API Interactions
  apiInteractor: ApiInteractors;

  // Strings
  previousComponentURL: string = "";
  activeAccountTab: string = "";
  activeLobbyName: string = "";
  languageCode: string = "";
  previousURL: string = "";

  // Booleans
  isKycBlocked: boolean = true;

  // To Discover
  profileCountryDetails: any;

  // Others
  navigateAfterLogin: NavigationAfterLogin = {};

  // --------------------------------------------------------
  // Subject - Active Account View
  private activeAccountViewSubject: Subject<ActiveTab> = new Subject<ActiveTab>();
  public activeAccountViewSubject$: Observable<ActiveTab> =
    this.activeAccountViewSubject.asObservable();

  // --------------------------------------------------------
  // Subject - Active Left Menu
  private activeLeftMenuSubject: Subject<string> = new Subject<string>();
  public activeLeftMenuSubject$: Observable<string> =
    this.activeLeftMenuSubject.asObservable();

  // --------------------------------------------------------
  // Subject - Navigation Data
  public navigationDataSubject: Subject<NavigationData> =
    new Subject<NavigationData>();
  public navigationDataSubject$: Observable<NavigationData> =
    this.navigationDataSubject.asObservable();

  // --------------------------------------------------------
  // Subject - Is Game Window Regily Pop Up Timer Update
  private isGameWindowRegilyPopUpTimerUpdateSubject: Subject<boolean> =
    new Subject<boolean>();
  public isGameWindowRegilyPopUpTimerUpdateSubject$: Observable<boolean> =
    this.isGameWindowRegilyPopUpTimerUpdateSubject.asObservable();

  // --------------------------------------------------------
  // Subject - User Account KYC Status

  // Subscriptions
  subscription: Subscription;

  subscriptions: Subscription[] = [];

  constructor(
    private translationService: TranslationService,
    private userDetailService: UserDetailsService,
    @Inject(DOCUMENT) private document: Document,
    private profileService: ProfileService,
    private mainService: MainService,
    private httpClient: HttpClient,
    private store: Store<AppState>,
    private router: Router
  ) {
    this.apiInteractor = new ApiInteractors(this.httpClient);

    this.subscriptions = [
      this.store
        .select(selectLanguageCode)
        .subscribe((languageCode: string) => {
          this.languageCode = languageCode;
        }),
      this.store
        .select(selectAuthLoginIsLoggedIn)
        .subscribe((isLoggedIn: boolean) => {
          if (isLoggedIn) {
            this.store.dispatch(profileBalanceRequested());

            this.store.dispatch(userProfileBalanceRequested());
          }
        }),
      /* 
        Game window back navigation handling related code
      */
      this.router.events
        .pipe(
          filter((evt: RoutesRecognized) => evt instanceof RoutesRecognized),
          pairwise()
        )
        .subscribe((events: RoutesRecognized[]) => {
          let urlAfterRedirect: string = decodeURIComponent(
            events[0].urlAfterRedirects
          );

          let temp: string[] = this.getDecodedCurrentPath().split("/");

          let translatedString: string = this.translationService
            .get("url.game")
            .toLowerCase();

          if (temp.length > 2 && temp[2] != translatedString) {
            this.previousComponentURL = urlAfterRedirect;

            if (temp[2] != "studio") {
              this.previousURL = this.previousComponentURL;
            }
          } else if (temp.length == 2) {
            this.previousComponentURL = undefined;

            this.previousURL = undefined;
          }
        }),
    ];
  }

  // -----------------------------------------------------------------
  // Get Methods
  getActiveLobby(): string {
    return this.activeLobbyName;
  }

  getNavigateAfterLogin(): NavigationAfterLogin {
    return this.navigateAfterLogin;
  }

  getActiveAccountMenu(): string {
    return this.activeAccountTab;
  }

  // -----------------------------------------------------------------
  // Get Observables
  onGetValidateUniqueness(
    fieldToValidate: ValidationRequest
  ): Observable<ValidationResponse> {
    let url: string = "";

    _.each(fieldToValidate, (_, key: string) => {
      url =
        key == "txtNickname"
          ? "/ajax/registration/isUniqueNickname"
          : "/ajax/registration/isUniqueEmail";
    });

    return this.apiInteractor
      .post<ValidationRequest, ValidationResponse>(
        url,
        fieldToValidate,
        Project.Shotz
      )
      .pipe(
        map((validationResponse: ValidationResponse) => {
          return validationResponse;
        })
      );
  }

  // -----------------------------------------------------------------
  // Set Methods
  onBroadcastActiveAcountView(activeTab: ActiveTab): void {
    this.activeAccountViewSubject.next(activeTab);
  }

  onBroadcastActiveLeftMenu(tabName: string): void {
    this.activeLeftMenuSubject.next(tabName);
  }

  onSetActiveLobby(activeLobbyName: string): void {
    this.activeLobbyName = activeLobbyName;
  }

  onBroadcastNavigationData(navigationData?: NavigationData): void {
    if (navigationData && navigationData.lobby) {
      this.activeLobbyName = navigationData.lobby;
    }

    this.navigationDataSubject.next(navigationData);
  }

  onSetNavigateAfterLogin(navigateAfterLogin: NavigationAfterLogin): void {
    this.navigateAfterLogin = navigateAfterLogin;
  }

  onBroadcastIsGameWindowRegilyPopUpTimerUpdate(): void {
    this.isGameWindowRegilyPopUpTimerUpdateSubject.next(true);
  }

  // -----------------------------------------------------------------
  // To Discover
  /*
    ApI calls...
  */

  doProfileUpdate(requestObj): Observable<any> {
    return this.mainService.onProfileUpdate(requestObj).pipe(
      map((response) => {
        if (response) {
          this.userDetailService.onSetUserProfileDetails(response);
        }
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  getProfileCountryDetails(): Observable<any> {
    if (!_.isEmpty(this.profileCountryDetails)) {
      return of(this.profileCountryDetails);
    } else {
      return this.mainService.onGetProfileCountryDetails().pipe(
        map((response) => {
          this.profileCountryDetails = response;
          return response;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
    }
  }

  onForceGetUserProfileDetails(): Observable<UserProfileBalance> {
    return this.mainService.apiInteractor
      .get<null, UserProfileBalance>(
        `/ajax/profile/getBalance`,
        null,
        Project.Shotz
      )
      .pipe(
        map((balanceDetails: UserProfileBalance) => {
          if (balanceDetails) {
            this.userDetailService.onSetUserBalanceDetails(balanceDetails);
          }
          return balanceDetails;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  private quickDepositToggle = new Subject<boolean>();
  public quickDepositToggle$ = this.quickDepositToggle.asObservable();

  broadcastQuickDepositToggle(flag: boolean): void {
    this.quickDepositToggle.next(flag);
  }

  private pendingWithdrawCancel = new Subject<boolean>();
  public pendingWithdrawCancel$ = this.pendingWithdrawCancel.asObservable();

  broadcastPendingWithdrawCancel(flag) {
    this.pendingWithdrawCancel.next(flag);
  }

  getZendeskToken(): Observable<any> {
    return this.mainService.onGetZendeskToken().pipe(
      map((response) => {
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  countryCode: string;
  private updateCountryCode: Subject<string> = new Subject<string>();
  public updateCountryCode$ = this.updateCountryCode.asObservable();

  broadcastUpdateCountryCode(countryCode) {
    this.setCountryCode(countryCode);
    this.updateCountryCode.next(countryCode);
  }

  setCountryCode(countryCode: string): void {
    this.countryCode = countryCode;
  }

  getCountryCode(): string {
    return this.countryCode;
  }

  setCurrencyByLocality() {
    let languageCodeFromURL = this.getDecodedCurrentPath().split("/")[1];
    if (
      languageCodeFromURL &&
      languageCodeFromURL !== this.languageCode &&
      _.contains(supportedMarketsList(), languageCodeFromURL)
    ) {
      this.languageCode = languageCodeFromURL;
    }
    if (
      this.languageCode &&
      marketLocaleCurrencyMappingConfigurations &&
      marketLocaleCurrencyMappingConfigurations.hasOwnProperty(
        this.languageCode
      )
    ) {
      let marketLocaleCurrencyMappingConfigClone = {
        ...marketLocaleCurrencyMappingConfigurations,
      };
      this.userDetailService.onSetCurrencySymbol(
        marketLocaleCurrencyMappingConfigClone[this.languageCode][
          "currencySymbol"
        ]
      );
      this.userDetailService.onSetUserCurrencyCode(
        marketLocaleCurrencyMappingConfigClone[this.languageCode][
          "currencyCode"
        ]
      );
    } else {
      this.userDetailService.onSetCurrencySymbol(
        environment.defaultCurrencySymbol
      );
      this.userDetailService.onSetUserCurrencyCode(
        WorldCurrencyCode[environment.defaultCurrencyCode]
      );
    }
  }

  getZendeskRubikoDetails(userName): Observable<any> {
    return this.mainService.getZendeskRubikoDetails(userName).pipe(
      map((response) => {
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }
  public gameplayFooterHover = new BehaviorSubject<Boolean>(false);
  public gameplayFooterHover$: Observable<any> =
    this.gameplayFooterHover.asObservable();
  public isGamesToasterOpen = new BehaviorSubject<Boolean>(false);
  public isGamesToasterOpen$: Observable<any> =
    this.isGamesToasterOpen.asObservable();
  public closeGame = new BehaviorSubject<Boolean>(false);
  public closeGame$: Observable<any> = this.closeGame.asObservable();
  getDecodedCurrentPath() {
    return decodeURIComponent(window.location.pathname);
  }

  private isLiveCasinoPage = new BehaviorSubject<any>(false);
  public isLiveCasinoPage$ = this.isLiveCasinoPage.asObservable();

  broadCastIsLiveCasinoPage(flag: boolean) {
    this.isLiveCasinoPage.next(flag);
  }

  updateUserCredentials(
    updateUserCredentialsRequest: UpdateUserCredentialsRequest
  ): Observable<UpdateUserCredentialsResponse> {
    return this.mainService.updateUserCredentails(updateUserCredentialsRequest);
  }

  getUserCountry() {
    if (this.userDetailService.getUserCountryCode()) {
      return this.userDetailService.getUserCountryCode();
    } else {
      this.profileService.onGetProfileBalanceCurrency().subscribe((data) => {
        return data["profile"]["country"];
      });
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) =>
      subscription.unsubscribe()
    );
  }
}
