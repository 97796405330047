/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./form-label.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./form-label.component";
var styles_FormLabelComponent = [i0.styles];
var RenderType_FormLabelComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FormLabelComponent, data: {} });
export { RenderType_FormLabelComponent as RenderType_FormLabelComponent };
export function View_FormLabelComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "has-error": 0, "show": 1, "hide": 2 }), (_l()(), i1.ɵted(4, null, [" ", "\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.config.class, ""); var currVal_1 = _ck(_v, 3, 0, _co.getShouldShowErrors(_co.config.key, _co.formGroup), ((_co.isUsedAccount && _co.config.visibleOnRepeatPayment) || (!_co.isUsedAccount && _co.config.visibleOnNewPayment)), ((_co.isUsedAccount && !_co.config.visibleOnRepeatPayment) || (!_co.isUsedAccount && !_co.config.visibleOnNewPayment))); _ck(_v, 2, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.config.name; _ck(_v, 4, 0, currVal_2); }); }
export function View_FormLabelComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-form-label", [], null, null, null, View_FormLabelComponent_0, RenderType_FormLabelComponent)), i1.ɵdid(1, 49152, null, 0, i3.FormLabelComponent, [], null, null)], null, null); }
var FormLabelComponentNgFactory = i1.ɵccf("app-form-label", i3.FormLabelComponent, View_FormLabelComponent_Host_0, {}, {}, []);
export { FormLabelComponentNgFactory as FormLabelComponentNgFactory };
