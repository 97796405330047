<section
  class="popout__outerWrap promotion-page fade-in all-cards page-view clearfix"
>
  <div
    id="pageHeader"
    class="popout__header padding isFixed fade-in-down"
    #pageHeader
    [ngClass]="{ pageHeaderBg: isPageHeaderBg }"
  >
    <button
      class="btn btn-circle btn-secondary float-left ml-16"
      aria-label="Back"
      (click)="onGoToAllPromotions()"
    >
      <i class="icon-chevron-left"></i>
    </button>

    <div class="page-header--title">
      {{ "promotion.promotion" | translate }}
    </div>
  </div>

  <div
    id="popoutOverlay"
    class="padding popout__body promotion__content clearfix"
  >
    <ng-container
      *ngIf="activePromotion && activePromotion.content"
    >
      <div
        id="promotionContent"
        [innerHtml]="activePromotion.content | safeHtml"
        (click)="onOpenContent($event)"
      ></div>
    </ng-container>

    <ng-container *ngIf="!isPageAvailable && isLoading">
      <div class="loader fade-in-up">
        <div class="promo-page__body">
          <div
            class="promo-page__img skeleton-loader"
          ></div>
          <div class="promo-page__content">
            <h2 class="skeleton-loader round-skelton"></h2>
            <p
              class="skeleton-loader round-skelton"
              *ngFor="
                let number of [].constructor(8);
                let i = index
              "
            ></p>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="!isPageAvailable && !isLoading">
      <div class="no-results text-center">
        {{
          "promotion.nopromotions_content_configured"
            | translate
        }}
      </div>
    </ng-container>
  </div>
</section>
