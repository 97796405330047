// Enums
import { WorldCurrencyCode } from "src/app/models/configurations/enums/localization/world-currencies.enum";
import { StatusResponse } from "src/app/models/api/status.response";

// Models
import { GamePregmatic } from "src/app/modules/game-groups/models/game.model";
import {
  EligibleBonusAmountType,
  EligibleBonusCategory,
  CurrencyTier,
} from "src/app/modules/rewards/models/eligible-bonus.model";

export interface UserCampaign {
  status: StatusResponse;
  errorCode: string | null;
  errorDescription: string | null;
  ecrExternalId: string | null;
  campaignDetails: CampaignDetails[];
}

export interface CampaignDetails {
  campaignName: string;
  campaignStartDate: number;
  campaignEndDate: number;
  campaignType: CampaignType;
  campaignStatus: CampaignStatus;
  campaignCurrency: WorldCurrencyCode;
  optinValidityDuration: number;
  playerOptinTime: number;
  depositRequired: boolean;
  minDepositDone: boolean;
  optInRequired: boolean;
  claimTargetAwardType: string | null;
  claimValidityDate: string | null;
  claimAvailabilityDate: string | null;
  claimValidityDays: number | null;
  claimAvailabilityDays: number | null;
  offerOnActivityData: string | null;
  offerOnActivityCondition: string | null;

  userEligibleToClaim: boolean;
  targetAward: boolean;
  userClaimed: boolean;
  promoParentId: number;
  promoRaceId: number;

  // Added as common fields
  bonusTypeFD?: string;
  freeSpinGameIds?: string[];
  gameData?: GamePregmatic;
  gameIds?: string[];
  levelId?: number;
  freespinExpiryDate?: string;
  freeSpinCount?: number;
  freeSpinValue?: number;
  bonusCode?: string;
  targetValidityDate?: string;
  amountType?: EligibleBonusAmountType;
  issueMultiplier?: number;
  currencyTier?: CurrencyTier;
  bonusCategory?: EligibleBonusCategory;
}

export enum CampaignType {
  PROMO_RACE = "PROMO_RACE",
  UCB = "UCB",
  CASHBACK_PROMO = "CASHBACK_PROMO",
}

export enum CampaignStatus {
  ACTIVE = "ACTIVE",
  COMPLETED = "COMPLETED",
}

export enum CampaignTargetAwardType {
  Instant = "Instant",
  "Not Instant" = "Not Instant",
  "Offer on Activity" = "Offer on Activity",
}
