import { environment } from "src/environments/environment";

export const supportedMarketsList = (): string[] => {
  let languages: string[] = ["en-row", "nb-no"];

  if (environment.features.shotzLanguages) {
    // languages = [...languages, "fi-fi", "en-ca", "en-nz", "de-eu", "en-ie"];
    languages = [...languages, "fi-fi"];

    if (environment.features.hungarian) {
    //  languages = [...languages, "hu-hu"];
    }
  }

  return languages;
};

export const supportedLanguagesList = (): string[] => {
  let languages: string[] = ["en", "no"];

  if (environment.features.shotzLanguages) {
    // languages = [...languages, "fi", "cd", "nz", "at", "ie"];
    languages = [...languages, "fi"];

    if (environment.features.hungarian) {
    //  languages = [...languages, "hu"];
    }
  }

  return languages;
};
