<ng-container
  *ngIf="filteredGameGroupsWithGamesList && filteredGameGroupsWithGamesList.length > 0 && !isLoading"
>
  <ng-container *ngFor="let gameGroup of filteredGameGroupsWithGamesList;  let i = index">
    <div *ngIf="isLoggedIn && lobby === 'casino' && i === 1" class="livespins__list game-group__list">
      <app-livespins></app-livespins>
    </div>

    <ng-container [ngSwitch]="gameGroup.game_asset_style">
      <ng-container *ngSwitchCase="'poster'">
        <div
          [id]="utilityService.convertGameNameToUrl(gameGroup.name)"
          *ngIf="gameGroup.games && gameGroup.games.length >0"
          class="game-group__list"
        >
          <app-posters [gameGroupData]="gameGroup" [callingFromlobby]="lobby">
          </app-posters>
        </div>
      </ng-container>

      <ng-container *ngSwitchCase="'poster-jackpot'">
        <div
          [id]="utilityService.convertGameNameToUrl(gameGroup.name)"
          class="game-group__list"
          *ngIf="gameGroup.games && gameGroup.games.length >0"
        >
          <app-poster-jockpot
            [gameGroupData]="gameGroup"
            [callingFromlobby]="lobby"
          ></app-poster-jockpot>
        </div>
      </ng-container>

      <ng-container *ngSwitchCase="'default-casino'">
        <div
          [id]="utilityService.convertGameNameToUrl(gameGroup.name)"
          class="game-group__list"
          *ngIf="gameGroup.games && gameGroup.games.length >0"
        >
          <app-default-casino
            [gameGroupData]="gameGroup"
            [callingFromlobby]="lobby"
          ></app-default-casino>
        </div>
      </ng-container>

      <ng-container *ngIf="windowType === 'device'">
        <div
          [id]="utilityService.convertGameNameToUrl(gameGroup.name)"
          *ngSwitchCase="'showcase'"
          class="game-group__list m-b-32"
        >
          <app-show-case
            *ngIf="gameGroup.games && gameGroup.games.length >0"
            [callingFromlobby]="lobby"
            [gameGroupData]="gameGroup"
          ></app-show-case>
        </div>
      </ng-container>

      <ng-container *ngSwitchCase="'default-live-casino'">
        <div
          [id]="utilityService.convertGameNameToUrl(gameGroup.name)"
          class="game-group__list"
          *ngIf="gameGroup.games && gameGroup.games.length >0"
        >
          <app-default-live-casino
            [gameGroupData]="gameGroup"
            [callingFromlobby]="lobby"
          >
          </app-default-live-casino>
        </div>
      </ng-container>

      <ng-container *ngSwitchCase="'video-showreel'">
        <div
          [id]="utilityService.convertGameNameToUrl(gameGroup.name)"
          class="game-group__list m-b-32"
          *ngIf="gameGroup.games && gameGroup.games.length > 0"
        >
          <app-show-reel [gameGroupData]="gameGroup" [callingFromlobby]="lobby">
          </app-show-reel>
        </div>
      </ng-container>

      <ng-container *ngSwitchCase="'quicklink'">
        <div
          [id]="utilityService.convertGameNameToUrl(gameGroup.name)"
          class="game-group__list"
          *ngIf="gameGroup.games && gameGroup.games.length >0"
        >
          <app-game-provider
            [gameGroupData]="gameGroup"
            [callingFromlobby]="lobby"
          ></app-game-provider>
        </div>
      </ng-container>

      <ng-container *ngSwitchCase="'poster-live-casino'">
        <div
          [id]="utilityService.convertGameNameToUrl(gameGroup.name)"
          class="game-group__list"
          *ngIf="gameGroup.games && gameGroup.games.length >0"
        >
          <app-poster-live-casino
            [gameGroupData]="gameGroup"
            [callingFromlobby]="lobby"
          >
          </app-poster-live-casino>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
  <!--Here we load meta content of game group which we configured on CMS -->
  <div
    class="meta-content"
    *ngIf="activeGameGroupLobby && activeGameGroupLobby.content"
  >
    <div [innerHtml]="activeGameGroupLobby.content"></div>
  </div>
</ng-container>

<ng-container
  *ngIf="filteredGameGroupsWithGamesList && filteredGameGroupsWithGamesList.length === 0 && !isLoading && isGamesLoaded"
>
  <div class="no-results text-center">
    <img
      class="p-16 no-results__logo"
      src="/assets/img/logo.svg"
      alt="{{'general.site_name' | translate}}"
      title="{{'general.site_name' | translate}}"
      width="100"
      height="100%"
    />
    <p class="p-16">{{'gamegroup.gamegroup_notconfig' | translate}}</p>
  </div>
</ng-container>

<!-- Loader comes -->
<ng-container *ngIf="isGamesLoading && !isGamesLoaded">
  <div
    class="game-group__list skeleton-loader__wrap"
    *ngFor="let number of [].constructor(4)"
  >
    <div class="game-group--title clearfix">
      <span class="group-title skeleton-loader round-skelton float-left"></span>
      <span class="viewall-link skeleton-loader round-skelton"></span>
    </div>
    <div
      class="game-card poster noHoverOnSkelton"
      *ngFor="let number of [].constructor(10)"
    >
      <div class="game-card__body">
        <div class="game-card__mask skeleton-loader"></div>
      </div>
      <div class="game-card__footer">
        <div class="game-title skeleton-loader round-skelton"></div>
      </div>
    </div>
  </div>
</ng-container>
