import { Subscription } from "rxjs";
import {
  EventEmitter,
  SimpleChange,
  Component,
  OnChanges,
  Output,
  Input,
} from "@angular/core";

// Enums
import { WorldCurrencyCode } from "src/app/models/configurations/enums/localization/world-currencies.enum";

// Models
import { CampaignItem } from "src/app/modules/rewards/models/campaign-items.model";

// Services
import { UserDetailsService } from "src/app/modules/user/services/user-details.service";

@Component({
  selector: "app-release-restriction",
  templateUrl: "./release-restrict-reward.component.html",
  styleUrls: ["./release-restrict-reward.component.scss"],
})
export class ReleaseRestrictionRewardComponent implements OnChanges {
  // Outputs
  @Output() callOpenRewardTCPopUp: EventEmitter<MouseEvent> =
    new EventEmitter<MouseEvent>();
  @Output() callOnBonusSelected: EventEmitter<CampaignItem> =
    new EventEmitter<CampaignItem>();
  @Output() callOnOpenDeposit: EventEmitter<CampaignItem> =
    new EventEmitter<CampaignItem>();

  // Inputs
  @Input() currencyCode: WorldCurrencyCode;
  @Input() selectedBonus: CampaignItem;
  @Input() isRealUser: boolean = false;
  @Input() bonusDetails: CampaignItem;
  @Input() callingFrom: string = "";
  @Input() rewardSize: string = "";

  // Strings
  currencySymbol: string = "";

  // Subscriptions
  subscription: Subscription;

  constructor(private userDetailsService: UserDetailsService) {}

  // -----------------------------------------------------------------
  // Lifecycle Hooks
  ngOnInit(): void {
    this.subscription =
      this.userDetailsService.currencySymbolBehaviourSubject$.subscribe(
        (currencySymbol: string) => {
          this.currencySymbol = currencySymbol;
        }
      );
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
    if (
      changes["bonusDetails"] &&
      changes["bonusDetails"].previousValue !==
        changes["bonusDetails"].currentValue
    ) {
      this.bonusDetails = changes["bonusDetails"].currentValue;
    }

    if (
      changes["callingFrom"] &&
      changes["callingFrom"].previousValue !==
        changes["callingFrom"].currentValue
    ) {
      this.callingFrom = changes["callingFrom"].currentValue;
    }

    if (
      changes["rewardSize"] &&
      changes["rewardSize"].previousValue !== changes["rewardSize"].currentValue
    ) {
      this.rewardSize = changes["rewardSize"].currentValue;
    }

    if (
      changes["isRealUser"] &&
      changes["isRealUser"].previousValue !== changes["isRealUser"].currentValue
    ) {
      this.isRealUser = changes["isRealUser"].currentValue;
    }
    if (
      changes["currencyCode"] &&
      changes["currencyCode"].previousValue !==
        changes["currencyCode"].currentValue
    ) {
      this.currencyCode = changes["currencyCode"].currentValue;
    }

    if (
      changes["selectedBonus"] &&
      changes["selectedBonus"].previousValue !==
        changes["selectedBonus"].currentValue
    ) {
      this.selectedBonus = changes["selectedBonus"].currentValue;
    }
  }

  // -----------------------------------------------------------------
  // Set Methods
  onSelectBonus(bonusDetails: CampaignItem): void {
    this.callOnBonusSelected.emit(bonusDetails);
  }

  onOpenDepositView(bonusDetails: CampaignItem): void {
    this.callOnOpenDeposit.emit(bonusDetails);
  }

  onOpenRewardTCPopUp(event: MouseEvent): void {
    this.callOpenRewardTCPopUp.emit(event);
  }

  // -----------------------------------------------------------------
  // On Destroy
  ngOnDestory(): void {
    if (this.subscription) this.subscription.unsubscribe();
  }
}
