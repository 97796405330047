import { Observable, of, Subscription, throwError } from "rxjs";
import { catchError, map, share, tap } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";

// API Interactors
import { ApiInteractors } from "src/app/models/interactors/api.interactor";

// Enums
import { Project } from "src/app/models/environments/project.enum";

// Libraries
import * as _ from "underscore";

// Models
import { Promotion } from "src/app/modules/promotions/models/promotion.model";

// Reducers
import { AppState } from "src/app/store/reducers";

// Selectors
import { selectAuthLoginIsLoggedOut } from "src/app/modules/auth/store/selectors/auth.selectors";

@Injectable({
  providedIn: "root",
})
export class PromotionsService {
  // API Interactions
  apiInteractor: ApiInteractors;

  // Arrays
  promotionList: Promotion[] = [];

  // Subscriptions
  subscription: Subscription;

  constructor(private store: Store<AppState>, private httpClient: HttpClient) {
    this.apiInteractor = new ApiInteractors(this.httpClient);

    this.subscription = this.store
      .select(selectAuthLoginIsLoggedOut)
      .subscribe((isLoggedOut: boolean) => {
        if (isLoggedOut) {
          this.promotionList = undefined;
        }
      });
  }

  // -----------------------------------------------------------------
  // Get Methods
  onGetPromotionList(isForce: boolean = false): Observable<Promotion[]> {
    if (!_.isEmpty(this.promotionList) && !isForce) {
      return of(this.promotionList);
    } else {
      return this.apiInteractor
        .get<null, Promotion[]>(
          `/ajax/promotion/getPromotions`,
          null,
          Project.Highroller
        )
        .pipe(
          tap((promotionList: Promotion[]) => {
            this.promotionList = promotionList;
          }),
          map((response: Promotion[]) => {
            return response;
          }),
          catchError((error) => {
            return throwError(error);
          }),
          share()
        );
    }
  }

  // -----------------------------------------------------------------
  // On Destroy
  ngOnDestroy(): void {
    if (this.subscription) this.subscription.unsubscribe();
  }
}
