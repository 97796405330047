<iframe
  [src]="iframe | safe"
  width="100%"
  height="100%"
  marginheight="0"
  frameborder="0"
  border="0"
  scrolling="auto"
  allow="clipboard-read;clipboard-write;autoplay;fullscreen;"
></iframe>
