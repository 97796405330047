import { Subject, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { Component } from "@angular/core";
import { Store } from "@ngrx/store";

// Actions
import { countryBlockRequested } from "src/app/modules/country-block/store/actions/country-block.actions";

// Constants
import { UserFlowTypes } from "src/app/constants/costants";

// Environments
import { environment } from "src/environments/environment";

// Models
import { CountryBlockResponse } from "src/app/modules/country-block/models/country-block-response.model";

// Reducers
import { AppState } from "src/app/store/reducers";

// Selectors
import { selectCountryBlock } from "src/app/modules/country-block/store/selectors/country-block.selectors";
import { selectLanguageCode } from "src/app/modules/multi-languages/store/selectors/languages.selectors";

// Services
import { MultiLanguageService } from "src/app/modules/multi-languages/services/multi-language.service";
import { TranslationService } from "src/app/modules/multi-languages/services/translation.service";
import { LivespinsService } from "src/app/modules/livespins/services/livespins.service";
import { NewsTickerService } from "src/app/modules/news/services/news-ticker.service";
import { UtilityService } from "src/app/modules/shared/services/utility.service";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent {
  // Strings
  languageCode: string = "";
  activeLobby: string = "";

  // Booleans
  isNewsTickerBannerAvailable: boolean = true;
  isCountryBlockLoading: boolean = true;

  // Arrays
  zimplerMarketsList: string[] = environment.zimplerMarkets;

  // Enums
  UserFlowTypes = UserFlowTypes;

  // Objects
  countryBlockResponse: CountryBlockResponse;

  // Subjects
  destroy$: Subject<boolean> = new Subject<boolean>();

  // Subscriptions
  countryBlockInitialSubscription: Subscription;

  subscriptions: Subscription[] = [];

  constructor(
    private multiLanguageService: MultiLanguageService,
    private translationService: TranslationService,
    private newsTickerService: NewsTickerService,
    private livespinsService: LivespinsService,
    private utilityService: UtilityService,
    private store: Store<AppState>
  ) {
    this.onLoad();
  }

  // -----------------------------------------------------------------
  // Lifecycle Hooks
  ngOnInit(): void {
    this.languageCode = this.multiLanguageService.getLanguageCode();

    this.subscriptions = [
      this.store
        .select(selectLanguageCode)
        .pipe(takeUntil(this.destroy$))
        .subscribe((languageCode: string) => {
          this.languageCode = languageCode;

          this.onUpdateSEOContent();
        }),
      this.newsTickerService.isNewBannerAvailableSubject$
        .pipe(takeUntil(this.destroy$))
        .subscribe((isNewsTickerBannerAvailable: boolean) => {
          this.isNewsTickerBannerAvailable = isNewsTickerBannerAvailable;
        }),
    ];

    this.livespinsService.onInitialise();
  }

  ngAfterViewInit(): void {
    this.onUpdateSEOContent();
  }

  // -----------------------------------------------------------------
  // Set Methods
  onLoad(): void {
    this.countryBlockInitialSubscription = this.store
      .select(selectCountryBlock)
      .subscribe((countryBlockResponse: CountryBlockResponse) => {
        if (countryBlockResponse) {
          this.countryBlockResponse = countryBlockResponse;

          this.isCountryBlockLoading = false;
        } else {
          this.isCountryBlockLoading = true;

          this.store.dispatch(countryBlockRequested());
        }
      });
  }

  onSelectedLobby(activeLobby: string): void {
    this.activeLobby = activeLobby;
  }

  onUpdateSEOContent(): void {
    this.utilityService.setSEO({
      metaTitle: this.translationService.get("SEO.casinoHomePage_metaTitle"),
      metaDescription: this.translationService.get(
        "SEO.casinoHomePage_metaDescription"
      ),
      metaTags: this.translationService.get("SEO.casinoHomePage_metaTag"),
      title: this.translationService.get("SEO.casinoHomePage_title"),
    });

    this.multiLanguageService.onSetCanonicalURL("homepage");
  }

  // -----------------------------------------------------------------
  // On Destroy
  ngOnDestroy(): void {
    this.destroy$.next(true);

    this.destroy$.unsubscribe();

    if (this.countryBlockInitialSubscription)
      this.countryBlockInitialSubscription.unsubscribe();

    this.subscriptions.forEach((subscription: Subscription) =>
      subscription.unsubscribe()
    );
  }
}
