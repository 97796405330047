/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./all-promotions.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "./all-promotions.component";
import * as i5 from "../../../multi-languages/services/multi-language.service";
import * as i6 from "../../../multi-languages/services/translation.service";
import * as i7 from "../../services/promotions.service";
import * as i8 from "../../../shared/services/emitter.service";
import * as i9 from "@angular/router";
import * as i10 from "../../../shared/services/utility.service";
import * as i11 from "../../../shared/services/common.service";
import * as i12 from "@ngrx/store";
var styles_AllPromotionsComponent = [i0.styles];
var RenderType_AllPromotionsComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_AllPromotionsComponent, data: {} });
export { RenderType_AllPromotionsComponent as RenderType_AllPromotionsComponent };
function View_AllPromotionsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "promotion-item clearfix"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "promotion-card"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [["class", "promotion-body"]], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "button", [["aria-label", "Read more"], ["class", "btn btn-md btn-primary promotion--cta btn-round"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onGoToPromotionPage(_v.context.$implicit.url) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(4, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.snippet; _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform("promotion.read_more")); _ck(_v, 4, 0, currVal_1); }); }
function View_AllPromotionsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AllPromotionsComponent_2)), i1.ɵdid(2, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.promotionList; _ck(_v, 2, 0, currVal_0); }, null); }
function View_AllPromotionsComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "promotion-item isLoading clearfix"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "promotion-card"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [["class", "promotion__img skeleton-loader"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 6, "div", [["class", "promotion__body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "h4", [["class", "promotion--title round-skelton skeleton-loader"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 3, "div", [["class", "promotion--text"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "p", [["class", "skeleton-loader t1 round-skelton"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "p", [["class", "skeleton-loader t1 round-skelton"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "p", [["class", "skeleton-loader t2 round-skelton"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, "button", [["aria-label", "loader"], ["class", "btn btn-md btn-primary promotion--cta btn-round skeleton-loader"]], null, null, null, null, null))], null, null); }
function View_AllPromotionsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AllPromotionsComponent_4)), i1.ɵdid(2, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = i1.ɵEMPTY_ARRAY.constructor(6); _ck(_v, 2, 0, currVal_0); }, null); }
function View_AllPromotionsComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "no-results text-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("promotion.nopromotions_configured")); _ck(_v, 2, 0, currVal_0); }); }
export function View_AllPromotionsComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { pageHeader: 0 }), i1.ɵqud(671088640, 2, { popupOver: 0 }), (_l()(), i1.ɵeld(2, 0, null, null, 16, "section", [["class", "popout__outerWrap promotion-page fade-in all-cards clearfix"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, [[1, 0], ["pageHeader", 1]], null, 8, "div", [["class", "popout__header isFixed"], ["id", "pageHeader"]], null, null, null, null, null)), i1.ɵprd(512, null, i3.ɵNgClassImpl, i3.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(5, 278528, null, 0, i3.NgClass, [i3.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(6, { pageHeaderBg: 0 }), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "popout__header--title promotions"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(10, 0, null, null, 1, "button", [["aria-label", "Close"], ["class", "btn btn-circle isMobileShow close-btn closePopoutView"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onBackToHome() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 0, "i", [["class", "icon-close"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 6, "div", [["class", "padding promotion-page popout__body clearfix"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AllPromotionsComponent_1)), i1.ɵdid(14, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AllPromotionsComponent_3)), i1.ɵdid(16, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AllPromotionsComponent_5)), i1.ɵdid(18, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "popout__header isFixed"; var currVal_1 = _ck(_v, 6, 0, _co.isPageHeaderBg); _ck(_v, 5, 0, currVal_0, currVal_1); var currVal_3 = !_co.isLoading; _ck(_v, 14, 0, currVal_3); var currVal_4 = _co.isLoading; _ck(_v, 16, 0, currVal_4); var currVal_5 = (_co.promotionList && (_co.promotionList.length === 0)); _ck(_v, 18, 0, currVal_5); }, function (_ck, _v) { var currVal_2 = i1.ɵunv(_v, 8, 0, i1.ɵnov(_v, 9).transform("promotion.promotions")); _ck(_v, 8, 0, currVal_2); }); }
export function View_AllPromotionsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-all-promotions", [], null, [["window", "scroll"]], function (_v, en, $event) { var ad = true; if (("window:scroll" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onScroll() !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_AllPromotionsComponent_0, RenderType_AllPromotionsComponent)), i1.ɵdid(1, 245760, null, 0, i4.AllPromotionsComponent, [i5.MultiLanguageService, i6.TranslationService, i7.PromotionsService, i8.EmitterService, i9.ActivatedRoute, i10.UtilityService, i11.CommonService, i12.Store, i9.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AllPromotionsComponentNgFactory = i1.ɵccf("app-all-promotions", i4.AllPromotionsComponent, View_AllPromotionsComponent_Host_0, {}, {}, []);
export { AllPromotionsComponentNgFactory as AllPromotionsComponentNgFactory };
