import { createEntityAdapter } from "@ngrx/entity";
import { createReducer, on } from "@ngrx/store";
// Actions
import { lastPlayedGamesRequested, lastPlayedGamesLoaded, lastPlayedGamesError, gamesRequested, lobbyRequested, gamesLoaded, lobbyLoaded, gamesError, lobbyError, } from "src/app/modules/game-groups/store/actions/games.actions";
export const GamesStateFeatureKey = "games-collection";
const ɵ0 = (game) => `${game.gameCode}`;
// Adapter for Games
export const adapterGames = createEntityAdapter({
    selectId: ɵ0,
});
const overviewGamesInitialState = adapterGames.getInitialState({
    isLoaded: false,
    isLoading: true,
});
const ɵ1 = (lobbyPregmatic) => `${lobbyPregmatic.id}-${lobbyPregmatic.name}`;
// Adapter for Lobby Pregmatic
export const adapterLobbyPregmatic = createEntityAdapter({
    selectId: ɵ1,
});
const overviewLobbyPregmaticInitialState = adapterLobbyPregmatic.getInitialState({
    isLoaded: false,
});
export const initialState = {
    gamesList: overviewGamesInitialState,
    gameError: null,
    lobbyPregmaticList: overviewLobbyPregmaticInitialState,
    lobbyPregmaticError: null,
    lastPlayedList: [],
    lastPlayedError: null,
};
const ɵ2 = (state) => {
    return Object.assign({}, state, { gamesList: adapterGames.removeAll(Object.assign({}, state.gamesList, { isLoading: true, isLoaded: false })), gameError: null });
}, ɵ3 = (state, { gamesList }) => {
    return Object.assign({}, state, { gamesList: adapterGames.upsertMany(gamesList, Object.assign({}, state.gamesList, { isLoading: false, isLoaded: true })), gameError: null });
}, ɵ4 = (state) => {
    return Object.assign({}, state, { gameError: "Error occurred whilst retrieving Games List" });
}, ɵ5 = (state) => {
    return Object.assign({}, state, { lobbyPregmaticList: adapterLobbyPregmatic.removeAll(state.lobbyPregmaticList), lobbyPregmaticError: null });
}, ɵ6 = (state, { lobbyPregmaticList }) => {
    return Object.assign({}, state, { lobbyPregmaticList: adapterLobbyPregmatic.upsertMany(lobbyPregmaticList, state.lobbyPregmaticList), lobbyPregmaticError: null });
}, ɵ7 = (state) => {
    return Object.assign({}, state, { lobbyPregmaticError: "Error occurred whilst retrieving Lobby Pregmatic List" });
}, ɵ8 = (state) => {
    return Object.assign({}, state, { lastPlayedError: null });
}, ɵ9 = (state, { lastPlayedList }) => {
    return Object.assign({}, state, { lastPlayedList, lastPlayedError: null });
}, ɵ10 = (state) => {
    return Object.assign({}, state, { lastPlayedError: "Error occurred whilst retrieving Last Played List" });
};
// Reducer
const GamesReducer = createReducer(initialState, 
// Games
on(gamesRequested, ɵ2), on(gamesLoaded, ɵ3), on(gamesError, ɵ4), 
// Lobby Pregmatic
on(lobbyRequested, ɵ5), on(lobbyLoaded, ɵ6), on(lobbyError, ɵ7), 
// Last Played
on(lastPlayedGamesRequested, ɵ8), on(lastPlayedGamesLoaded, ɵ9), on(lastPlayedGamesError, ɵ10));
export function reducer(state, action) {
    return GamesReducer(state, action);
}
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10 };
