import { Action, createReducer, on } from "@ngrx/store";

// Actions
import { countryBlockLoaded } from "src/app/modules/country-block/store/actions/country-block.actions";

// Models
import { CountryBlockResponse } from "src/app/modules/country-block/models/country-block-response.model";

export const CountryBlockStateFeatureKey = "country-block-collection";

// Reducer States
export interface CountryBlockReducerState {
  countryBlockResponse: CountryBlockResponse;
}

export const initialState: CountryBlockReducerState = {
  countryBlockResponse: null,
};

// Reducer
const CountryBlockReducer = createReducer(
  initialState,
  on(countryBlockLoaded, (state, { countryBlockResponse }) => {
    return {
      ...state,
      countryBlockResponse,
    };
  })
);

export function reducer(
  state: CountryBlockReducerState | undefined,
  action: Action
) {
  return CountryBlockReducer(state, action);
}
