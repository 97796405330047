import { createReducer, on } from "@ngrx/store";
// Actions
import { payNPlayLoginRequested, loginStatusRequested, payNPlayLoginLoaded, payNPlayLoginError, loginStatusLoaded, loginStatusError, logoutRequested, loginRequested, loggedInStatus, logoutLoaded, loginLoaded, logoutError, loginError, } from "src/app/modules/auth/store/actions/auth.actions";
export const AuthStateFeatureKey = "auth";
export const initialState = {
    // Login
    loggedIn: null,
    payNPlayLoggedIn: null,
    isLoaded: false,
    isLoading: false,
    loginError: null,
    // is Logged In
    isLoggedIn: false,
    isLoggedOut: false,
    // Login Status
    loginStatus: null,
    isLoadedLoginStatus: false,
    isLoadingLoginStatus: false,
    loginErrorLoginStatus: null,
    // Logout
    logout: null,
    isLoadedLogout: false,
    isLoadingLogout: false,
    loginErrorLogout: null,
};
const ɵ0 = (state) => {
    return Object.assign({}, state, { loggedIn: null, payNPlayLoggedIn: null, isLoading: true, isLoaded: false, loginError: null, logoutResponse: null, isLoadingLogout: false, isLoadedLogout: false, loginErrorLogout: null });
}, ɵ1 = (state, { loggedIn }) => {
    return Object.assign({}, state, { loggedIn, payNPlayLoggedIn: null, isLoading: false, isLoaded: true, loginError: null, isLoggedOut: !loggedIn });
}, ɵ2 = (state) => {
    return Object.assign({}, state, { loggedIn: null, payNPlayLoggedIn: null, isLoading: false, isLoaded: false, loginError: "Error occurred whilst retrieving Logging in" });
}, ɵ3 = (state) => {
    return Object.assign({}, state, { loggedIn: null, payNPlayLoggedIn: null, isLoading: true, isLoaded: false, loginError: null, logoutResponse: null, isLoadingLogout: false, isLoadedLogout: false, loginErrorLogout: null });
}, ɵ4 = (state, { payNPlayLoggedIn }) => {
    return Object.assign({}, state, { loggedIn: null, payNPlayLoggedIn, isLoading: false, isLoaded: true, loginError: null, isLoggedIn: true });
}, ɵ5 = (state) => {
    return Object.assign({}, state, { loggedIn: null, payNPlayLoggedIn: null, isLoading: false, isLoaded: false, loginError: "Error occurred whilst retrieving Logging in" });
}, ɵ6 = (state, { isLoggedIn }) => {
    return Object.assign({}, state, { isLoggedIn, isLoggedOut: !isLoggedIn });
}, ɵ7 = (state) => {
    return Object.assign({}, state, { loginStatus: null, isLoadingLoginStatus: true, isLoadedLoginStatus: false, loginErrorLoginStatus: null });
}, ɵ8 = (state, { loginStatus }) => {
    return Object.assign({}, state, { loginStatus, isLoadingLoginStatus: false, isLoadedLoginStatus: true, loginErrorLoginStatus: null });
}, ɵ9 = (state) => {
    return Object.assign({}, state, { loginStatus: null, isLoadingLoginStatus: false, isLoadedLoginStatus: false, loginErrorLoginStatus: "Error occurred whilst retrieving Login Status" });
}, ɵ10 = (state) => {
    return Object.assign({}, state, { isLoggedOut: false, logoutResponse: null, isLoadingLogout: true, isLoadedLogout: false, loginErrorLogout: null });
}, ɵ11 = (state, { logoutResponse }) => {
    return Object.assign({}, state, { 
        // Login
        loggedIn: null, payNPlayLoggedIn: null, isLoading: false, isLoaded: false, loginError: null, isLoggedIn: false, isLoggedOut: true, 
        // Login Status
        loginStatus: null, isLoadingLoginStatus: false, isLoadedLoginStatus: false, loginErrorLoginStatus: null, 
        // Logout
        logoutResponse, isLoadingLogout: false, isLoadedLogout: true, loginErrorLogout: null });
}, ɵ12 = (state) => {
    return Object.assign({}, state, { logoutResponse: null, isLoadingLogout: false, isLoadedLogout: false, loginErrorLogout: "Error occurred whilst retrieving Logout" });
};
// Reducer
const AuthReducer = createReducer(initialState, 
// Login
on(loginRequested, ɵ0), on(loginLoaded, ɵ1), on(loginError, ɵ2), 
// Pay N Play Login
on(payNPlayLoginRequested, ɵ3), on(payNPlayLoginLoaded, ɵ4), on(payNPlayLoginError, ɵ5), 
// Login In Status
on(loggedInStatus, ɵ6), 
// Login Status
on(loginStatusRequested, ɵ7), on(loginStatusLoaded, ɵ8), on(loginStatusError, ɵ9), 
// Logout
on(logoutRequested, ɵ10), on(logoutLoaded, logoutRequested, ɵ11), on(logoutError, ɵ12));
export function reducer(state, action) {
    return AuthReducer(state, action);
}
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12 };
