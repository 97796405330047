import { Component, Input, SimpleChanges } from "@angular/core";

// Libraries
import { LiveStream } from "livespins/@livespins";

@Component({
  selector: "app-csn-livespins-room",
  templateUrl: "./csn-livespins-room.component.html",
  styleUrls: ["./csn-livespins-room.component.scss"],
})
export class CsnLivespinsRoomComponent {
  // Inputs
  @Input() liveStream: LiveStream;

  // -----------------------------------------------------------------
  // Lifecycle Hooks
  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes["liveStream"].currentValue &&
      changes["liveStream"].previousValue != changes["liveStream"].currentValue
    ) {
      this.liveStream = changes["liveStream"].currentValue;
    }
  }
}
