import { throwError, Subject } from "rxjs";
import { map, catchError } from "rxjs/operators";
// API Interactors
import { ApiInteractors } from "src/app/models/interactors/api.interactor";
import { Project } from "src/app/models/environments/project.enum";
// Libraries
import * as _ from "underscore";
import * as $ from "jquery";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class CashierService {
    constructor(httpClient) {
        this.httpClient = httpClient;
        // Objects
        this.activeDepositBonus = {
            bonusCode: "",
        };
        // --------------------------------------------------------
        // Subject and Behaviour Subject
        this.transactionSuccessStatusSubject = new Subject();
        this.transactionSuccessStatusSubject$ = this.transactionSuccessStatusSubject.asObservable();
        this.apiInteractor = new ApiInteractors(this.httpClient);
    }
    // -----------------------------------------------------------------
    // Get Methods
    getActiveDepositBonus() {
        return this.activeDepositBonus;
    }
    getIframe(redirectOutput) {
        let $content;
        let $form;
        if (!redirectOutput.html && _.isEmpty(redirectOutput.parameters)) {
            if (redirectOutput.container !== "window") {
                $content = $(`<${redirectOutput.container} name="cciFrame" id="loaderIframe" src="${redirectOutput.url}" frameborder="0">
           </${redirectOutput.container}>`);
            }
            else {
                window.location.href = redirectOutput.url;
            }
        }
        else {
            $content = $(`<${redirectOutput.container} name="cciFrame" id="loaderIframe" src="" frameborder="0">
        </${redirectOutput.container}>`);
            if (redirectOutput.html) {
                $content = redirectOutput.html;
            }
            else {
                $form =
                    '<form id="proxy_redirect" method="' +
                        redirectOutput["method"] +
                        '" action="' +
                        redirectOutput["url"] +
                        '" class="hide" target="_self">';
                _.forEach(redirectOutput["parameters"], function (value, key) {
                    if (value != null) {
                        $form =
                            $form +
                                ('<input type="hidden" name="' +
                                    key +
                                    '" value="' +
                                    value +
                                    '" />');
                    }
                });
                $form = $form + "</form>";
                if (!redirectOutput["container"].includes("window")) {
                    $form = $($form);
                }
            }
        }
        let iFramePayment = {
            content: $content,
            form: $form,
        };
        return iFramePayment;
    }
    getIframe2(processPaymentRedirection) {
        let $content = "";
        let $form = "";
        if (!processPaymentRedirection.html &&
            _.isEmpty(processPaymentRedirection.parameters)) {
            if (processPaymentRedirection.container !== "window") {
                $content = $(`<${processPaymentRedirection.container}' name="cciFrame" id="loaderIframe" src="${processPaymentRedirection.url}" frameborder="0">
           </${processPaymentRedirection.container}>`);
            }
            else {
                window.location.href = processPaymentRedirection.url;
            }
        }
        else {
            $content = $(`<${processPaymentRedirection.container} name="cciFrame" id="loaderIframe" src="" frameborder="0">
        </${processPaymentRedirection.container}>`);
            if (processPaymentRedirection.html) {
                $content = processPaymentRedirection.html;
            }
            else {
                $form = `<form id="proxy_redirect" method="${processPaymentRedirection.method}" action="${processPaymentRedirection.url}" class="hide" target="_self">`;
                processPaymentRedirection.parameters.forEach((processPaymentParameter) => {
                    if (processPaymentParameter.value != null) {
                        $form = `${$form}
                <input type="hidden" name="${processPaymentParameter.key}" value="${processPaymentParameter.value}" />`;
                    }
                });
                $form = `${$form}</form>`;
                if (!processPaymentRedirection.container.includes("window")) {
                    $form = $($form);
                }
            }
        }
        let iFramePayment = {
            content: $content,
            form: $form,
        };
        return iFramePayment;
    }
    getPaymentMethodsBasedOnBonus(selectedBonus, piqMethodsList) {
        const filteredMethods = {
            piqMethods: [],
            nonApplicableBonus: [],
        };
        if (_.isEmpty(selectedBonus) || selectedBonus.depositOptions[0] === "ANY") {
            filteredMethods.piqMethods = piqMethodsList;
            return filteredMethods;
        }
        if (!_.isEmpty(selectedBonus) && selectedBonus.depositOptions) {
            piqMethodsList.forEach((method, index) => {
                if (selectedBonus.depositOptions.indexOf(method.providerType) > -1 ||
                    selectedBonus.depositOptions.indexOf(`${method.providerType}_HOSTED`) > -1) {
                    filteredMethods.piqMethods.push(piqMethodsList[index]);
                }
                else {
                    filteredMethods.nonApplicableBonus.push(piqMethodsList[index]);
                }
            });
            return filteredMethods;
        }
    }
    getBonusDescription(selectedBonus, inputAmount, currencyCode, selectedPaymentMethod) {
        /*
          Checking whther this bonus is eligible for this selected payment method or not
          Here we add _HOSTED at end of payment selected Method while comparing..
          because we get PIQ methods as _hosted at end
          to differentiate from PS payment methods.
        */
        if (selectedBonus.depositOptions.indexOf("ANY") ||
            selectedBonus.depositOptions.indexOf(`${selectedPaymentMethod}_HOSTED`)) {
            /*
              Fetching cuurencyCode bases currencytier
              & sending message
            */
            if (selectedBonus && currencyCode && selectedBonus.currencyTier) {
                const bonusCurrencyTier = selectedBonus.currencyTier[currencyCode][0];
                if (bonusCurrencyTier) {
                    const minDepositValue = bonusCurrencyTier.minDepositValue / 100;
                    const maxDepositValue = bonusCurrencyTier.maxDepositValue / 100;
                    const maxOfferValue = bonusCurrencyTier.maxOfferValue / 100;
                    if (selectedBonus.amountType === "ABS" &&
                        inputAmount >= minDepositValue) {
                        const bonusDescription = {
                            offerType: selectedBonus.amountType,
                            maxOfferValue,
                            amountAfterBonusApplied: Number(maxOfferValue) + Number(inputAmount),
                        };
                        return bonusDescription;
                    }
                    else if (selectedBonus.amountType === "PCT" &&
                        inputAmount >= minDepositValue) {
                        const percentageValue = bonusCurrencyTier.percentageValue;
                        let offeredValue = (inputAmount * percentageValue) / 100;
                        if (offeredValue > maxOfferValue) {
                            offeredValue = maxOfferValue;
                        }
                        const bonusDescription = {
                            offerType: selectedBonus.amountType,
                            maxOfferValue: offeredValue,
                            amountAfterBonusApplied: Number(offeredValue) + Number(inputAmount),
                        };
                        return bonusDescription;
                    }
                    else if (inputAmount <= maxDepositValue) {
                        return "remove-bonus";
                    }
                    else {
                        return {};
                    }
                }
            }
        }
        else {
            return "";
        }
    }
    // -----------------------------------------------------------------
    // Observables
    onGetToken(tokenRequest) {
        return this.apiInteractor
            .get(`/ajax/token/getToken`, tokenRequest, Project.Shotz)
            .pipe(map((tokenResponse) => {
            return tokenResponse;
        }), catchError((error) => {
            if (!tokenRequest || _.isEmpty(tokenRequest) || !tokenRequest.token) {
                return throwError("RequiredDetails Not available");
            }
            return throwError(error);
        }));
    }
    // -----------------------------------------------------------------
    // Set Methods - Transaction Success Status
    onSetCastTransactionSuccessStatus(typeOfTransaction) {
        this.transactionSuccessStatusSubject.next(typeOfTransaction);
    }
    // -----------------------------------------------------------------
    // Set Methods - Active Deposit Bonus
    onSetActiveDepositBonus(activeDepositBonus) {
        this.activeDepositBonus = activeDepositBonus;
    }
}
CashierService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CashierService_Factory() { return new CashierService(i0.ɵɵinject(i1.HttpClient)); }, token: CashierService, providedIn: "root" });
