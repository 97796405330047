<section class="liveCasino__side-widgets--list">
  <ul
    class="livecasino-provider checkbox-items"
    *ngIf="liveProviderList && liveProviderList.length > 0 && !isLoading"
  >
    <li
      class="livecasino-provider--list"
      *ngFor="let provider of liveProviderList"
    >
      <label class="checkbox" [attr.for]="provider.vendorDisplayName">
        <input
          class="checkbox--native"
          [attr.id]="provider.vendorDisplayName"
          type="checkbox"
          [(ngModel)]="provider.isChecked"
          (change)="onChangeSelection(provider.vendorDisplayName, provider.isChecked)"
        />
        <span class="checkbox--input">
          <img
            src="/assets/img/livecasino/checkbox-checked.svg"
            class="checkbox-shape fade-in"
            [class.active]="provider.isChecked"
            alt="{{provider.vendorDisplayName}}"
          />
          <img
            src="/assets/img/livecasino/checkbox-unchecked.svg"
            class="checkbox-shape fade-in"
            [class.active]="!(provider.isChecked)"
            alt="{{provider.vendorDisplayName}}"
          />
        </span>
        <span class="checkbox--text">{{provider.vendorDisplayName}}</span>
      </label>
    </li>
  </ul>

  <ul class="livecasino-provider checkbox-items" *ngIf="isLoading">
    <li
      class="livecasino-provider--list"
      *ngFor="let number of [].constructor(4)"
    >
      <label class="checkbox">
        <span class="checkbox--input skeleton-loader round-skelton"> </span>
        <span class="checkbox--text skeleton-loader round-skelton"></span>
      </label>
    </li>
  </ul>
</section>
