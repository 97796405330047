import { FormControl, FormGroup } from "@angular/forms";
import * as i0 from "@angular/core";
export class FormService {
    constructor() { }
    // -----------------------------------------------------------------
    // Get Methods
    isButtonDisabled(formGroup) {
        return formGroup.invalid || formGroup.pending;
    }
    // -----------------------------------------------------------------
    // Get Methods
    transformFormToData(formGroup, data) {
        Object.keys(formGroup.controls).forEach((field) => {
            const control = formGroup.get(field);
            if (control instanceof FormControl) {
                data[field] = control.value;
            }
            else if (control instanceof FormGroup) {
                this.transformFormToData(control, data);
            }
        });
        return data;
    }
    // -----------------------------------------------------------------
    // Set Methods
    onResetFormFields(formGroup, isForceReset) {
        if (isForceReset) {
            formGroup.reset();
        }
        else {
            Object.keys(formGroup.controls).forEach((field) => {
                const control = formGroup.get(field);
                if (control instanceof FormControl) {
                    control.setValue(null);
                }
                else if (control instanceof FormGroup) {
                    this.onResetFormFields(control);
                }
            });
        }
    }
    onRemoveAllFormFieldsValidation(formGroup) {
        Object.keys(formGroup.controls).forEach((field) => {
            const control = formGroup.get(field);
            if (control instanceof FormControl) {
                control.markAsUntouched({ onlySelf: true });
                control.markAsPristine({ onlySelf: true });
            }
            else if (control instanceof FormGroup) {
                this.onRemoveAllFormFieldsValidation(control);
            }
        });
    }
}
FormService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FormService_Factory() { return new FormService(); }, token: FormService, providedIn: "root" });
