<app-side-menu-modal
  [customClass]="'limits'"
  [sideMenuTitle]="'limits.page_title'"
>
  <!-- modal body -->
  <ng-container sideMenuBody>
    <p class="text-center small-text">
      {{ "limits.description_para1" | translate }}
    </p>

    <p class="text-center small-text m-b-16">
      {{ "limits.description_para2" | translate }}
    </p>

    <ng-container *ngTemplateOutlet="accordionTemplate"></ng-container>
  </ng-container>
</app-side-menu-modal>

<!-- 
  ------------------------------------------------------
  
  Templates 
  
  ------------------------------------------------------
-->
<!-- Accordion Template -->
<ng-template #accordionTemplate>
  <!--Deposit Limit Start-->
  <accordion
    class="accordion m-t-16"
    [showArrows]="true"
    [closeOthers]="true"
    [expandAll]="false"
  >
    <!-- Deposit -->
    <ng-container *ngIf="['estonia', 'curacao'].indexOf(userJurisdiction) > -1">
      <accordion-group>
        <accordion-heading>
          {{ "limits.NetDeposit_limit" | translate }}
        </accordion-heading>

        <app-rg-limits [limitType]="'deposit'"></app-rg-limits>
      </accordion-group>
    </ng-container>

    <!-- Loss: disabled in the part of IVP-508 -->
    <ng-container *ngIf="['estonia'].indexOf(userJurisdiction) > -1">
      <accordion-group>
        <accordion-heading>
          {{ "limits.loss_limit" | translate }}
        </accordion-heading>

        <app-rg-limits [limitType]="'loss'"></app-rg-limits>
      </accordion-group>
    </ng-container>
  </accordion>
</ng-template>
