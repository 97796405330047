<div class="accordion--content--innerwrap clearfix">
  <ng-container *ngTemplateOutlet="responseTemplate"></ng-container>

  <form [formGroup]="rgLimitsForm" class="rgLimitsForm">
    <ng-container *ngTemplateOutlet="periodSelectionTemplate"></ng-container>

    <ng-container
      *ngTemplateOutlet="currentSelectedLimitTemplate"
    ></ng-container>

    <ng-container *ngIf="cancelLimitsErrorMessage">
      <div>{{ cancelLimitsErrorMessage }}</div>
    </ng-container>

    <ng-container *ngTemplateOutlet="pendingLimitsTemplate"></ng-container>

    <div
      class="form-group limits-input clearfix has-label"
      [ngClass]="{
        'has-error':
          rgLimitsForm.invalid &&
          rgLimitsForm.touched &&
          rgLimitsForm.controls['limit'].errors,
        'has-success':
          rgLimitsForm.valid &&
          rgLimitsForm.touched &&
          !rgLimitsForm.controls['limit'].errors
      }"
    >
      <div class="controls">
        <label for="deposit_amnt">
          {{ "limits.enter_amount" | translate }}
        </label>

        <div class="rglimits-inputbox">
          <input
            type="number"
            id="deposit_amnt"
            name="deposit_amnt"
            class="number-only limit-input"
            formControlName="limit"
            autocomplete="off"
            appInputCurrencyPlacement
            placeholder="{{
              'limits.enter_LabelAmount' | translate
            }}"
          />
        </div>

        <ng-container *ngTemplateOutlet="limitErrorTemplate"></ng-container>
      </div>
    </div>

    <button
      class="btn btn-primary btn-md full-width btn-round"
      [setLoader]="isButtonLoader"
      [disabled]="
        rgLimitsForm.invalid ||
        rgLimitsForm.pending ||
        isButtonLoader
      "
      aria-label="Set limits"
      (click)="
        limitType === 'deposit'
          ? onUpdateNetDepositLimits()
          : onUpdateLimits()
      "
    >
      {{ "limits.set_limits" | translate }}
    </button>
  </form>
</div>

<!-- 
  ------------------------------------------------------
  
  Error Templates 
  
  ------------------------------------------------------
-->
<ng-template #limitErrorTemplate>
  <div
    class="error-msg fade-in-down"
    *ngIf="
      rgLimitsForm.controls['limit'].errors 
        &&
      rgLimitsForm.controls['limit'].touched
    "
  >
    <ng-container>
      <div
        [hidden]="
          !rgLimitsForm.controls['limit'].errors.required
        "
      >
        {{ "errors.error1" | translate }}
      </div>
      <div [hidden]="!rgLimitsForm.controls['limit'].errors.onlyNumbers">
        {{ "errors.error9" | translate }}
      </div>
      <div
        [hidden]="
          !rgLimitsForm.controls['limit'].errors.minimumAmount
        "
      >
        {{ "errors.error38" | translate }} {{ formattedUserMinimum }}
      </div>
      <div
        [hidden]="
          !rgLimitsForm.controls['limit'].errors.dailyLimitsShouldBeLessThanWeeklyAndMonthlyLimits
        "
      >
        {{ "errors.error39" | translate }}
      </div>
      <div
        [hidden]="
          !rgLimitsForm.controls['limit'].errors
            .weeklyLimitsShouldBeHigherThanDailyLimits
        "
      >
        {{ "errors.error40" | translate }}
      </div>
      <div
        [hidden]="
          !rgLimitsForm.controls['limit'].errors.weeklyLimitsMustBeLowerThanMonthlyLimits
        "
      >
        {{ "errors.error41" | translate }}
      </div>
      <div
        [hidden]="
          !rgLimitsForm.controls['limit'].errors.dailyLimitsShouldBeHigherThanDailyLimits
        "
      >
        {{ "errors.error59" | translate }}
      </div>
      <div
        [hidden]="
          !rgLimitsForm.controls['limit'].errors.monthlyLimitsMustBeHigherThanWeeklyLimits
        "
      >
        {{ "errors.error42" | translate }}
      </div>
      <div
        [hidden]="
          !rgLimitsForm.controls['limit'].errors.monthlyLimitsMustBeHigherThanDailyAndWeeklyLimits
        "
      >
        {{ "errors.error60" | translate }}
      </div>
    </ng-container>
  </div>
</ng-template>

<!-- 
  ------------------------------------------------------
  
  Templates 
  
  ------------------------------------------------------
-->
<!-- Response Template -->
<ng-template #responseTemplate>
  <div
    class="alert alert-success p-16 m-b-16 fade-in"
    *ngIf="successResponseMessage"
  >
    {{ successResponseMessage }}
  </div>

  <div
    class="alert alert-danger p-16 m-b-16 fade-in"
    *ngIf="failedResponseMessage"
  >
    {{ failedResponseMessage }}
  </div>
</ng-template>

<!-- Period Selection between Weekly and Monthly Limits -->
<ng-template #periodSelectionTemplate>
  <div class="duration-btn-group clearfix">
    <ng-container *ngIf="limitType !== 'deposit'">
      <div
        class="btn btn-sm btn-round"
        [ngClass]="
          rgLimitsForm.controls['period'].value ===
          PeriodTags.daily
            ? 'btn-primary'
            : 'btn-limits'
        "
        (click)="onSetActivePeriod(PeriodTags.daily)"
      >
        {{ "limits.daily" | translate }}
      </div>
    </ng-container>

    <div
      class="btn btn-sm btn-round"
      [ngClass]="
        rgLimitsForm.controls['period'].value ===
        PeriodTags.weekly
          ? 'btn-primary'
          : 'btn-limits'
      "
      (click)="onSetActivePeriod(PeriodTags.weekly)"
    >
      {{ "limits.weekly" | translate }}
    </div>

    <div
      class="btn btn-sm btn-round"
      [ngClass]="
        rgLimitsForm.controls['period'].value ===
        PeriodTags.monthly
          ? 'btn-primary'
          : 'btn-limits'
      "
      (click)="onSetActivePeriod(PeriodTags.monthly)"
    >
      {{ "limits.monthly" | translate }}
    </div>
  </div>
</ng-template>

<!-- Current Selected Limit Template -->
<ng-template #currentSelectedLimitTemplate>
  <div *ngIf="rgLimitsForm.controls['period'].value">
    <p class="limits-text">
      {{ "limits.current_" + rgLimitsForm.controls["period"].value + "limit" |
      translate }} {{ periodSpecificLimits.currentLimit | currencyformat:
      currencySymbol }}
    </p>

    <p class="limits-text">
      {{ "limits.remaining_" + rgLimitsForm.controls["period"].value + "limit" |
      translate }} {{ periodSpecificLimits.remainingLimit | currencyformat:
      currencySymbol }}
    </p>
  </div>
</ng-template>

<!-- Pending Limits Templates -->
<ng-template #pendingLimitsTemplate>
  <div
    class="alert alert-info text-left p-16 m-t-16"
    *ngIf="periodSpecificLimits.pendingLimit"
  >
    <h5>
      {{ "limits.newpending_limits" | translate }} : {{
      periodSpecificLimits.pendingLimit | currencyformat: currencySymbol }}
    </h5>

    <p class="m-t-8 m-b-8">
      {{ "limits.limits_available" | translate }} {{
      periodSpecificLimits.pendingTime | date: "dd/MM/yyyy, h:mm a" }}. {{
      "limits.request_anytime" | translate }}
    </p>

    <div class="btn-group m-t-12">
      <button
        class="btn btn-primary btn-sm full-width btn-round"
        [setLoader]="isSettingLoader"
        [disabled]="isSettingLoader"
        aria-label="Cancel"
        (click)="
          limitType === 'deposit'
            ? onCancelNetDepositLimits()
            : onCancelLimits()
        "
      >
        {{ "limits.cancel" | translate }}
      </button>
    </div>
  </div>
</ng-template>
