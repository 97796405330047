import { Subject, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
// API Interactors
import { ApiInteractors } from "src/app/models/interactors/api.interactor";
// Configurations
import { localStorageKeys } from "src/app/modules/multi-languages/configurations/localstorage-keys.configurations";
// Enums
import { StatusResponse } from "src/app/models/api/status.response";
import * as i0 from "@angular/core";
import * as i1 from "../../rewards/services/cashback-promo.service";
import * as i2 from "../../user/services/profile.service";
import * as i3 from "../../shared/services/socket.service";
import * as i4 from "../../shared/services/gtm.service";
import * as i5 from "@angular/common/http";
export class PayNPlayCashierService {
    constructor(cashbackPromoService, profileService, socketService, gtmService, httpClient) {
        this.cashbackPromoService = cashbackPromoService;
        this.profileService = profileService;
        this.socketService = socketService;
        this.gtmService = gtmService;
        this.httpClient = httpClient;
        // Strings
        this.userTractionFetchingRequired = "";
        // Objects
        this.depositLimits = {
            minLimit: 0,
            maxLimit: 0,
        };
        this.cashoutLimits = {
            minLimit: 0,
            maxLimit: 0,
        };
        // --------------------------------------------------------
        // Subject - Initialize Txn
        this.initiateTxnSubject = new Subject();
        this.initiateTxnSubject$ = this.initiateTxnSubject.asObservable();
        // --------------------------------------------------------
        // Subject - Deposit
        this.initiateDepositSubject = new Subject();
        this.initiateDepositSubject$ = this.initiateDepositSubject.asObservable();
        // --------------------------------------------------------
        // Subject - Withdraw
        this.initiateWithdrawSubject = new Subject();
        this.initiateWithdrawSubject$ = this.initiateWithdrawSubject.asObservable();
        // --------------------------------------------------------
        // Subject - Process User Transaction
        this.processUserTransactionSubject = new Subject();
        this.processUserTransactionSubject$ = this.processUserTransactionSubject.asObservable();
        // --------------------------------------------------------
        // Subject - Zimpler Process Completed
        this.zimplerProcessCompletedSubject = new Subject();
        this.zimplerProcessCompletedSubject$ = this.zimplerProcessCompletedSubject.asObservable();
        this.apiInteractor = new ApiInteractors(this.httpClient);
    }
    // -----------------------------------------------------------------
    // Get Methods
    getUserLoggedTime() {
        return this.userLoggedTime;
    }
    // -----------------------------------------------------------------
    // Get Observables
    onGetPNPMinMaxTxnLimits(pnpMinMaxLimitsRequest) {
        return this.apiInteractor
            .get(`/ajax/PayAndPlayTrustly/getPNPMinMaxTxnLimits`, pnpMinMaxLimitsRequest)
            .pipe(map((response) => {
            if (response.status === StatusResponse.SUCCESS &&
                response.minMaxTxnLimitsData &&
                response.minMaxTxnLimitsData[0]) {
                this.depositLimits = {
                    minLimit: response.minMaxTxnLimitsData[0].minTxnLimit,
                    maxLimit: response.minMaxTxnLimitsData[0].maxTxnLimit,
                };
            }
            return this.depositLimits;
        }), catchError((error) => {
            return throwError(error);
        }));
    }
    onGetPendingCashouts(pendingCashoutsRequest) {
        return this.apiInteractor
            .get(`/ajax/cashier/getPendingCashouts`, pendingCashoutsRequest)
            .pipe(map((response) => {
            return response;
        }), catchError((error) => {
            return throwError(error);
        }));
    }
    onGetWithdrawalReversal(revertWithdrawalRequest) {
        return this.apiInteractor
            .post(`/ajax/Cashier/revertWithdrawal`, revertWithdrawalRequest)
            .pipe(map((response) => {
            return response;
        }), catchError((error) => {
            return throwError(error);
        }));
    }
    onGetCashoutTxnLimits(cashierDetailsRequest) {
        return this.apiInteractor
            .get(`/ajax/cashier/getCashierDetails`, cashierDetailsRequest)
            .pipe(map((response) => {
            if (response &&
                response.cashierData &&
                response.cashierData.cashoutMethods) {
                let cashoutMethod = response.cashierData.cashoutMethods.ZIMPLER_DIRECT;
                this.cashoutLimits = {
                    minLimit: +cashoutMethod.limits.minTxnLimit,
                    maxLimit: +cashoutMethod.limits.maxTxnLimit,
                };
            }
            return this.cashoutLimits;
        }), catchError((error) => {
            return throwError(error);
        }));
    }
    onZimplerPayAndPlay(zimplerPayAndPlayRequest) {
        return this.apiInteractor
            .post(`/ajax/zimpler/zimplerPayAndPlayInit`, zimplerPayAndPlayRequest)
            .pipe(map((response) => {
            return response;
        }), catchError((error) => {
            return throwError(error);
        }));
    }
    onGetPNPUserFromTransaction(payNPlayFromTransactionRequest) {
        return this.apiInteractor
            .get(`/ajax/payAndPlayTrustly/getPNPUserFromTransaction`, payNPlayFromTransactionRequest)
            .pipe(map((payNPlayFromTransactionResponse) => {
            return payNPlayFromTransactionResponse;
        }), catchError((error) => {
            return throwError(error);
        }));
    }
    onGetQuickRegisterZimpler(quickRegisterZimplerRequest) {
        return this.apiInteractor
            .post(`/ajax/zimpler/quickRegisterZimpler`, quickRegisterZimplerRequest)
            .pipe(map((quickRegisterZimplerResponse) => {
            return quickRegisterZimplerResponse;
        }), catchError((error) => {
            return throwError(error);
        }));
    }
    onGetZimplerWithdrawal(cashierMakeWalletWithdrwalRequest) {
        return this.apiInteractor
            .post(`/ajax/cashier/makeWalletWithdrawal`, cashierMakeWalletWithdrwalRequest)
            .pipe(map((cashierMakeWalletWithdrwalResponse) => {
            return cashierMakeWalletWithdrwalResponse;
        }), catchError((error) => {
            return throwError(error);
        }));
    }
    onPayNPlayLogin(payNPlayLoginRequest) {
        return this.apiInteractor
            .get(`/ajax/PayAndPlayTrustly/getPNPThirdPartyLogin`, payNPlayLoginRequest)
            .pipe(map((payNPlayLoginResponse) => {
            if (payNPlayLoginResponse &&
                payNPlayLoginResponse.status === StatusResponse.SUCCESS) {
                this.socketService.onConnectToSockets(payNPlayLoginResponse.pragmaticUrl, payNPlayLoginResponse.pragmaticSessionId, true);
                this.userLoggedTime = new Date();
                localStorage.setItem(localStorageKeys.loggedInTime, this.userLoggedTime.toString());
                this.onPushGtmLogin(payNPlayLoginResponse);
                localStorage.setItem(localStorageKeys.stz_user, "true");
                if (payNPlayLoginResponse.lastAuthTime) {
                    this.cashbackPromoService.onSetUserLastAuthTime(+payNPlayLoginResponse.lastAuthTime);
                }
            }
            return payNPlayLoginResponse;
        }));
    }
    onGetUserPayNPlayTransactionStatus(payNPlayTransactionStatusRequest) {
        return this.apiInteractor
            .post(`/ajax/cashier/gettransationStatus`, payNPlayTransactionStatusRequest)
            .pipe(map((payNPlayTransactionStatusResponse) => {
            return payNPlayTransactionStatusResponse;
        }), catchError((error) => {
            return throwError(error);
        }));
    }
    // -----------------------------------------------------------------
    // Set Methods
    onBroadcastInitiateLogin(txnType) {
        this.initiateTxnSubject.next(txnType);
    }
    onBroadcastInitiateDeposit(initiateDeposit) {
        this.initiateDepositSubject.next(initiateDeposit);
    }
    onBroadcastInitiateWithdraw() {
        this.initiateWithdrawSubject.next();
    }
    onBroadcastProcessUserTransaction(type) {
        this.processUserTransactionSubject.next(type);
    }
    onBroadcastZimplerProcessCompleted() {
        this.zimplerProcessCompletedSubject.next();
    }
    onSetUserLoggedTime(userLoggedTime) {
        this.userLoggedTime = userLoggedTime;
    }
    onPushGtmLogin(payNPlayLoginResponse) {
        this.subscription = this.profileService
            .onGetProfileBalanceCurrency()
            .subscribe((profileBalance) => {
            if (profileBalance) {
                if (profileBalance.profile) {
                    payNPlayLoginResponse.countryCode = profileBalance.profile.country;
                }
                this.gtmService.onTrackLoginGTMEvent("login-success", payNPlayLoginResponse);
            }
        });
    }
    // -----------------------------------------------------------------
    // On Destroy
    ngOnDestroy() {
        if (this.subscription)
            this.subscription.unsubscribe();
    }
}
PayNPlayCashierService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PayNPlayCashierService_Factory() { return new PayNPlayCashierService(i0.ɵɵinject(i1.CashbackPromoService), i0.ɵɵinject(i2.ProfileService), i0.ɵɵinject(i3.SocketService), i0.ɵɵinject(i4.GtmService), i0.ɵɵinject(i5.HttpClient)); }, token: PayNPlayCashierService, providedIn: "root" });
