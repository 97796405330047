<div class="game-group__container" *ngIf="gameURL">
  <div class="game-group--title clearfix">{{ gameGroupData.name }}</div>

  <div id="iframeBox" class="gotw" #iframeBox>
    <div class="gotw--bgimg">
      <img
        alt="{{ gameGroupData.name }} {{game.name}}"
        title="{{gameGroupData.name}} {{game.name}}"
        src="{{gamesUrlPath}}/{{game.gameCode}}/{{game.gameCode}}_casino.jpg"
      />
    </div>

    <div class="gotw__iframeBox">
      <div class="gotw__iframe">
        <iframe
          title="{{gameGroupData.name}}"
          *ngIf="isVisible"
          [src]="gameURL"
        >
        </iframe>
      </div>
    </div>

    <div class="gotw__details">
      <div class="gotw--name">
        {{ (game.name.length > 25) ? (game.name | slice:0:25)+'..' : (game.name)
        }}
      </div>

      <div class="gotw--partner">
        <img
          src="{{mediaUrlPath}}/game-provider/{{game.vendorCode}}.png{{imgixParams}}"
          alt="{{game.vendorCode}}"
        />
        <img
          src="{{mediaUrlPath}}/game-provider/{{websiteName}}.png{{imgixParams}}"
          alt="{{'general.site_name' | translate}}"
        />
      </div>

      <div class="gotw--desc gotw-text">
        {{ (game.metaDescription.length > 104)? (game.metaDescription |
        slice:0:104)+'..':(game.gdContent) }}
      </div>

      <button
        aria-label="Play real money"
        class="btn btn-md btn-primary btn-round"
        (click)="onGameLaunch(game.name)"
      >
        {{ 'gamegroup.Play_realmoney'| translate}}
      </button>
    </div>
  </div>
</div>
