<div
  class="leftnav__container"
  [class.isGameStage]="activePage === 'game'"
>
  <nav class="leftnav">
    <div class="leftnav__inner">
      <div class="leftnav__primary">
        <div class="logo">
          <img
            (click)="onNavigateTo()"
            src="/assets/img/logo.svg"
            alt="{{ 'general.site_name' | translate }}"
            title="{{ 'general.site_name' | translate }}"
            width="100%"
            height="100%"
          />
        </div>

        <ng-container *ngIf="!isLoggedIn">
          <div class="login-section clearfix userNotLogged">
            <button
              class="btn btn-primary btn-md btn-round btn-block"
              [class.hungarian]="isHungarianStyle"
              *marketBasedComponentDisplay="{
                flowType: UserFlowTypes.NORMAL_FLOW,
                langCode: languageCode
              }"
              aria-label="Join now button"
              (click)="onOpenRegistrationPopup()"
            >
              {{ "general.joinnow" | translate }}
            </button>

            <button
              class="btn btn-primary btn-md btn-round btn-block"
              *marketBasedComponentDisplay="{
                flowType: UserFlowTypes.ZIMPLER_FLOW,
                langCode: languageCode
              }"
              aria-label="deposit now button"
              (click)="onInitiateDeposit()"
            >
              {{ "general.deposit" | translate }}
            </button>

            <button
              class="btn btn-secondary login-btn btn-md btn-round btn-block"
              aria-label="Login button"
              (click)="onNavigateTo('login')"
            >
              {{ "general.login" | translate }}
            </button>
          </div>
        </ng-container>

        <ng-container *ngIf="isLoggedIn">
          <div class="userLogged rewards">
            <div
              class="rewards__inner"
              [class.isGameStage]="activePage === 'game'"
            >
              <app-rewards-indicator></app-rewards-indicator>

              <p>
                <span
                  class="user-name cursor-hand"
                  (click)="onOpenAccountMenuList()"
                >
                  {{ username }}
                </span>
              </p>

              <ng-container *ngIf="activePage !== 'game'">
                <ng-container *ngIf="!isBalanceLoading">
                  <a
                    class="m-t-8 m-auto cursor-hand"
                    aria-label="user-balance"
                    (click)="onInitiateDeposit()"
                  >
                    <span class="user-balance cursor-hand">
                      {{
                        userTotalBalance
                          | currencyformat: currencySymbol
                      }}
                    </span>
                  </a>
                </ng-container>

                <ng-container *ngIf="isBalanceLoading">
                  <a
                    class="m-t-8 m-auto"
                    aria-label="skeleton-loader"
                  >
                    <span
                      class="user-balance skeleton-loader"
                    >
                    </span>
                  </a>
                </ng-container>
              </ng-container>
            </div>
          </div>
        </ng-container>
      </div>

      <div class="navigation">
        <ng-container *ngIf="isLoggedIn">
          <button
            class="btn btn-primary btn-md btn-block btn-round m-t-16 m-b-16"
            aria-label="Deposit button"
            (click)="onInitiateDeposit()"
          >
            {{ "general.deposit" | translate }}
          </button>
        </ng-container>

        <div class="leftnav-links clearfix">
          <ng-container
            *ngFor="
              let data of menuConfigurationType;
              let i = index
            "
          >
            <ng-container
              *ngIf="
                (data.onlyAfterLogin
                  ? isLoggedIn
                    ? true
                    : false
                  : true) &&
                !(
                  data.navigateTo === 'payments' &&
                  countryCode != 'CA'
                )
              "
            >
              <div
                class="leftnav-links__list"
                *ngIf="
                  (data.onlyAfterLogin
                    ? isLoggedIn
                      ? true
                      : false
                    : true) &&
                  !(
                    data.navigateTo === 'payments' &&
                    countryCode != 'CA'
                  )
                "
                (click)="onNavigateTo(data.navigateTo)"
              >
                <a
                  [ngClass]="{
                    active:
                      activeLeftMenu === data.navigateTo
                  }"
                >
                  {{ data.linkText | translate }}

                  <ng-container
                    *marketBasedComponentDisplay="{
                      flowType: UserFlowTypes.ZIMPLER_FLOW,
                      langCode: languageCode
                    }"
                  >
                    <ng-container
                      *ngIf="
                        data.navigateTo === 'rewards' &&
                        levelUpNotificationCount > 0
                      "
                    >
                      <span class="levelup-notify">
                        {{ levelUpNotificationCount }}
                      </span>
                    </ng-container>
                  </ng-container>
                </a>
              </div>
            </ng-container>
          </ng-container>

          <div
            class="leftnav-links__list"
            (click)="onNavigateTo('livespins')"
          >
            <a
              [ngClass]="{
                active: activeLeftMenu === 'livespins'
              }"
            >
              Livespins
            </a>
          </div>

          <ng-container *ngIf="isLoggedIn">
            <app-promo-race-counter></app-promo-race-counter>
          </ng-container>
        </div>
      </div>
    </div>

    <ng-container
      *ngIf="
        isLoggedIn &&
        ['estonia'].indexOf(userJurisdiction) > -1
      "
    >
      <app-session-timer></app-session-timer>
    </ng-container>
  </nav>

  <div class="clearfix"></div>

  <ng-container *ngIf="activePage != 'game'">
    <div class="leftnav__footer user-time">
      <app-time></app-time>

      <ng-container *ngIf="isLoggedIn">
        <span
          class="menu-icon nav-icon"
          (click)="onOpenAccountMenuList()"
        >
          <i class="icon-menu"></i>
        </span>
      </ng-container>

      <ng-container *ngIf="!isLoggedIn">
        <span
          class="user-chat nav-icon"
          (click)="onInitializeLiveChat()"
        >
          <i class="icon-chat"></i>
        </span>
      </ng-container>
    </div>
  </ng-container>
</div>
