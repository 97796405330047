/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./home.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../news/components/news-ticker/news-ticker.component.ngfactory";
import * as i3 from "../../../news/components/news-ticker/news-ticker.component";
import * as i4 from "../../../news/services/news-ticker.service";
import * as i5 from "@ngrx/store";
import * as i6 from "@angular/common";
import * as i7 from "../../../banner/components/custom-banner/custom-banner.component.ngfactory";
import * as i8 from "../../../banner/components/custom-banner/custom-banner.component";
import * as i9 from "../../../multi-languages/services/multi-language.service";
import * as i10 from "../../../banner/services/custom-banner.service";
import * as i11 from "../../../registration/services/registration.service";
import * as i12 from "../../../multi-languages/services/translation.service";
import * as i13 from "../../../user/services/user-details.service";
import * as i14 from "../../../auth/services/affiliate.service";
import * as i15 from "../../../game-groups/services/game-play.service";
import * as i16 from "../../../auth/services/session.service";
import * as i17 from "../../services/emitter.service";
import * as i18 from "../../../user/services/profile.service";
import * as i19 from "../../services/utility.service";
import * as i20 from "../../../game-groups/components/game-groups-display/game-groups-display.component.ngfactory";
import * as i21 from "../../../game-groups/components/game-groups-display/game-groups-display.component";
import * as i22 from "../../pipes/games-filter.pipe";
import * as i23 from "@angular/router";
import * as i24 from "../../../game-groups/components/all-games/all-games.component.ngfactory";
import * as i25 from "../../../game-groups/components/all-games/all-games.component";
import * as i26 from "../../../game-groups/services/game-groups.service";
import * as i27 from "../../directives/market-based-component-display.directive";
import * as i28 from "../../../game-groups/components/game-lobby/game-lobby.component.ngfactory";
import * as i29 from "../../../game-groups/components/game-lobby/game-lobby.component";
import * as i30 from "../../services/mix-panel.service";
import * as i31 from "../../services/common.service";
import * as i32 from "./home.component";
import * as i33 from "../../../livespins/services/livespins.service";
var styles_HomeComponent = [i0.styles];
var RenderType_HomeComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HomeComponent, data: {} });
export { RenderType_HomeComponent as RenderType_HomeComponent };
function View_HomeComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "page-body no-padding"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-news-ticker", [], null, null, null, i2.View_NewsTickerComponent_0, i2.RenderType_NewsTickerComponent)), i1.ɵdid(3, 245760, null, 0, i3.NewsTickerComponent, [i4.NewsTickerService, i5.Store], null, null)], function (_ck, _v) { _ck(_v, 3, 0); }, null); }
function View_HomeComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HomeComponent_3)), i1.ɵdid(2, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isNewsTickerBannerAvailable; _ck(_v, 2, 0, currVal_0); }, null); }
function View_HomeComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-custom-banner", [["class", "clearfix"]], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onResize() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_CustomBannerComponent_0, i7.RenderType_CustomBannerComponent)), i1.ɵdid(2, 770048, null, 0, i8.CustomBannerComponent, [i9.MultiLanguageService, i10.CustomBannerService, i11.RegistrationService, i12.TranslationService, i13.UserDetailsService, i14.AffiliateService, i15.GamePlayService, i16.SessionService, i17.EmitterService, i18.ProfileService, i19.UtilityService, i5.Store], { zoneId: [0, "zoneId"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.zimplerMarketsList.indexOf(_co.languageCode) <= (0 - 1)) ? _co.activeLobby : "casino_banner_HIR"); _ck(_v, 2, 0, currVal_0); }, null); }
function View_HomeComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "stz-banner clearfix"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 5, "div", [["class", "stz-banner__item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "mobile-banner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "div", [["class", "banner-media skeleton-loader"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "desktop-banner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "banner-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "div", [["class", "banner-media skeleton-loader"]], null, null, null, null, null))], null, null); }
function View_HomeComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-game-groups-display", [["class", "game-group"]], null, [["window", "resize"], ["window", "scroll"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onResize() !== false);
        ad = (pd_0 && ad);
    } if (("window:scroll" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).onWindowScroll() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i20.View_GameGoupsDisplayComponent_0, i20.RenderType_GameGoupsDisplayComponent)), i1.ɵdid(1, 770048, null, 0, i21.GameGoupsDisplayComponent, [i9.MultiLanguageService, i12.TranslationService, i22.GamesFilterPipe, i16.SessionService, i19.UtilityService, i5.Store, i23.Router], { lobby: [0, "lobby"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.activeLobby; _ck(_v, 1, 0, currVal_0); }, null); }
function View_HomeComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-all-games", [["class", "clearfix"]], null, [["window", "resize"], ["window", "scroll"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onResize() !== false);
        ad = (pd_0 && ad);
    } if (("window:scroll" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).onWindowScroll() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i24.View_AllGamesComponent_0, i24.RenderType_AllGamesComponent)), i1.ɵdid(1, 770048, null, 0, i25.AllGamesComponent, [i26.GameGroupsService, i19.UtilityService, i5.Store, i23.Router], { activeLobby: [0, "activeLobby"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.activeLobby; _ck(_v, 1, 0, currVal_0); }, null); }
function View_HomeComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_HomeComponent_2)), i1.ɵdid(2, 16384, null, 0, i27.MarketBasedComponentDisplayDirective, [i1.ViewContainerRef, i1.TemplateRef], { marketBasedComponentDisplay: [0, "marketBasedComponentDisplay"] }, null), i1.ɵpod(3, { "flowType": 0, "langCode": 1 }), (_l()(), i1.ɵeld(4, 0, null, null, 10, "div", [["class", "app-container page-body homepage padding"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HomeComponent_4)), i1.ɵdid(6, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HomeComponent_5)), i1.ɵdid(8, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 1, "app-game-lobby", [["class", "clearfix"]], null, [[null, "callOnSelectedLobby"], ["document", "touchend"], ["document", "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("document:touchend" === en)) {
        var pd_0 = (i1.ɵnov(_v, 10).onClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("document:click" === en)) {
        var pd_1 = (i1.ɵnov(_v, 10).onClick($event) !== false);
        ad = (pd_1 && ad);
    } if (("callOnSelectedLobby" === en)) {
        var pd_2 = (_co.onSelectedLobby($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i28.View_GameLobbyComponent_0, i28.RenderType_GameLobbyComponent)), i1.ɵdid(10, 770048, null, 0, i29.GameLobbyComponent, [i26.GameGroupsService, i30.MixPanelService, i23.ActivatedRoute, i19.UtilityService, i16.SessionService, i31.CommonService, i5.Store, i23.Router], { callingFrom: [0, "callingFrom"] }, { callOnSelectedLobby: "callOnSelectedLobby" }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HomeComponent_6)), i1.ɵdid(12, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HomeComponent_7)), i1.ɵdid(14, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, _co.UserFlowTypes.ZIMPLER_FLOW, _co.languageCode); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.languageCode; _ck(_v, 6, 0, currVal_1); var currVal_2 = !_co.languageCode; _ck(_v, 8, 0, currVal_2); var currVal_3 = "casinoLobby"; _ck(_v, 10, 0, currVal_3); var currVal_4 = (_co.activeLobby && (_co.activeLobby !== "all")); _ck(_v, 12, 0, currVal_4); var currVal_5 = (_co.activeLobby && (_co.activeLobby === "all")); _ck(_v, 14, 0, currVal_5); }, null); }
export function View_HomeComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_HomeComponent_1)), i1.ɵdid(1, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.countryBlockResponse && !_co.countryBlockResponse.isIpBlocked) && !_co.isCountryBlockLoading); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_HomeComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-home", [], null, null, null, View_HomeComponent_0, RenderType_HomeComponent)), i1.ɵdid(1, 4440064, null, 0, i32.HomeComponent, [i9.MultiLanguageService, i12.TranslationService, i4.NewsTickerService, i33.LivespinsService, i19.UtilityService, i5.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HomeComponentNgFactory = i1.ɵccf("app-home", i32.HomeComponent, View_HomeComponent_Host_0, {}, {}, []);
export { HomeComponentNgFactory as HomeComponentNgFactory };
