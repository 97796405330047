import { Subscription } from "rxjs";
import {
  EventEmitter,
  SimpleChange,
  Component,
  Output,
  Input,
} from "@angular/core";

// Enums
import { StatusResponse } from "src/app/models/api/status.response";

// Environments
import { environment } from "src/environments/environment";

// Models
import { ClaimLevelUpBonusResponse } from "src/app/modules/rewards/models/claim-level/claim-level-up-bonus-response.model";
import { ClaimLevelUpBonusRequest } from "src/app/modules/rewards/models/claim-level/claim-level-up-bonus-request.model";
import { CampaignItem } from "src/app/modules/rewards/models/campaign-items.model";
import { ActiveTab } from "src/app/modules/shared/models/active-tab.model";

// Services
import { LiveChatService } from "src/app/modules/chat/services/live-chat.service";
import { RewardsService } from "src/app/modules/rewards/services/reward.service";
import { UtilityService } from "src/app/modules/shared/services/utility.service";

@Component({
  selector: "app-unclaimed-free-spin-reward-card",
  templateUrl: "./unclaimed-free-spin-reward-card.component.html",
  styleUrls: ["./unclaimed-free-spin-reward-card.component.scss"],
})
export class UnclaimedFreeSpinRewardCardComponent {
  // Outputs
  @Output() callOpenRewardTCPopUp: EventEmitter<MouseEvent> =
    new EventEmitter<MouseEvent>();
  @Output() callOnLevelupClaimed: EventEmitter<void> = new EventEmitter<void>();

  // Inputs
  @Input() bonusDetails: CampaignItem;

  // Strings
  apiUrlPath: string = environment.apiUrl;
  bonusType: string = "";

  // Booleans
  isLevelUpClaimFailed: boolean = false;
  isRequestInProcess: boolean = false;
  isButtonLoader: boolean = false;

  // Subscriptions
  claimLevelUpBonusSubscription: Subscription;

  constructor(
    private liveChatService: LiveChatService,
    private rewardsService: RewardsService,
    private utilityService: UtilityService
  ) {}

  // -----------------------------------------------------------------
  // Lifecycle Hooks
  ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
    if (
      changes["bonusDetails"] &&
      changes["bonusDetails"].previousValue !==
        changes["bonusDetails"].currentValue
    ) {
      this.bonusDetails = changes["bonusDetails"].currentValue;

      this.bonusType = this.bonusDetails["bonusTypeFD"];
    }
  }

  // -----------------------------------------------------------------
  // Set Methods
  applyLevelupBonus(levelId: number): void {
    if (this.utilityService.isPnpFlow()) {
      this.onSetClaimLevelUpBonus(levelId);
    } else {
      this.rewardsService.onSetLevelUpDetails({
        newLevelId: levelId,
        dataFrom: "rewards",
      });

      const activeTab: ActiveTab = {
        tabName: "reward-congratulations",
        showBackButton: true,
      };

      this.utilityService.openAccountComponent(activeTab);
    }
  }

  onSetClaimLevelUpBonus(levelId: number): void {
    this.isRequestInProcess = true;

    this.isLevelUpClaimFailed = false;

    let claimLevelUpBonusRequest: ClaimLevelUpBonusRequest = {
      levelId,
    };

    this.claimLevelUpBonusSubscription = this.rewardsService
      .onGetClaimLevelUpBonus(claimLevelUpBonusRequest)
      .subscribe((claimLevelUpBonusResponse: ClaimLevelUpBonusResponse) => {
        if (
          claimLevelUpBonusResponse &&
          claimLevelUpBonusResponse.status === StatusResponse.SUCCESS
        ) {
          this.callOnLevelupClaimed.emit();
        } else {
          this.isLevelUpClaimFailed = true;
        }

        this.isRequestInProcess = false;
      });
  }

  onOpenRewardTCPopUp(event: MouseEvent): void {
    this.callOpenRewardTCPopUp.emit(event);
  }

  onInitializeChat(): void {
    this.liveChatService.onInitializeChat();

    this.utilityService.closeAccountComponent();
  }

  // -----------------------------------------------------------------
  // On Destroy
  ngOnDestroy(): void {
    if (this.claimLevelUpBonusSubscription)
      this.claimLevelUpBonusSubscription.unsubscribe();
  }
}
