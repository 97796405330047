<ng-container
  *ngIf="!isLoading && promoConfigInfo && !isAbsolute"
>
  <ng-container *ngIf="rewardSize === 'basic'">
    <div
      class="rewardBonusCard"
      (click)="onAddBonus(bonusDetails)"
    >
      <div class="rewardBonusCard__bg">
        <img
          src="/assets/img/rewards/bonus-img/shotz-rewards.png"
          alt="bonus card bg"
        />
      </div>

      <div class="rewardBonusCard__container">
        <div class="rewardBonusCard__icon">
          <img
            src="/assets/img/rewards/bonus-img/cashback.png"
            class="rewardBonusCardImg"
            alt="CashBack"
          />

          <ng-container
            *ngIf="promoConfigInfo.campaignEndDate"
          >
            <div class="countdown-timer">
              <app-countdown-timer
                [expiryDate]="
                  promoConfigInfo.campaignEndDate
                "
              ></app-countdown-timer>
            </div>
          </ng-container>
        </div>

        <div class="rewardBonusCard__details">
          <div class="rewardBonusCard__text-inside">
            <div class="rewardBonusCard--title">
              {{ userSlabInfo.cashBackPercentage + "%" }}
            </div>

            <div class="rewardBonusCard--desc">
              {{ "rewards.cashback" | translate }}<br />
            </div>
          </div>

          <button
            class="btn btn-primary btn-round btn-xs"
            aria-label="Claim offer"
          >
            {{ "rewards.activate_now" | translate }}
          </button>

          <ng-container
            *ngIf="callingFrom === 'payment-bonus'"
          >
            <div class="payment-bonus-swich">
              <div
                class="toggleSwitch"
                [class.active]="
                  bonusDetails?.playerOptinTime
                "
              ></div>

              <span class="bonus-status">
                <ng-container
                  *ngIf="!bonusDetails?.playerOptinTime"
                >
                  {{ "rewards.add_bonus" | translate }}
                </ng-container>

                <ng-container
                  *ngIf="bonusDetails?.playerOptinTime"
                >
                  {{ "rewards.bonus_added" | translate }}
                </ng-container>
              </span>
            </div>
          </ng-container>

          <ng-container
            *ngIf="callingFrom === 'all-rewards'"
          >
            <button class="btn btn-info" aria-label="Terms">
              <i
                class="icon-info"
                (click)="onOpenRewardTCPopUp($event)"
              ></i>
            </button>
          </ng-container>
        </div>
      </div>
    </div>
  </ng-container>

  <!--
    Basic difference between basic & shorter card is we don't have background game image, 
    reward title & button area will be completely different 
  -->
  <ng-container *ngIf="rewardSize === 'shorter'">
    <div
      class="pnp-short-bonus bonus-card"
      (click)="onAddBonus(bonusDetails)"
    >
      <div class="bonus-card--info">
        {{ userSlabInfo.cashBackPercentage + "%" }}
        {{ "rewards.promo_cashback" | translate }}
      </div>

      <div class="choose-bonuse">
        <ng-container
          *ngIf="!bonusDetails?.playerOptinTime"
        >
          <i class="icon-checkbox"></i>
          {{ "rewards.yes_add_bonus" | translate }}
        </ng-container>

        <ng-container *ngIf="bonusDetails?.playerOptinTime">
          <i class="icon-checkbox-checked checked"></i>
          {{ "rewards.bonus_added" | translate }}
        </ng-container>
      </div>

      <ng-container
        *ngIf="
          promoConfigInfo && promoConfigInfo.campaignEndDate
        "
      >
        <div class="bonus-card--expiry">
          <app-countdown-timer
            [expiryDate]="promoConfigInfo.campaignEndDate"
          ></app-countdown-timer>
        </div>
      </ng-container>

      <button aria-label="Terms" class="bonus-card--terms">
        <i
          class="icon-info"
          (click)="onOpenRewardTCPopUp($event)"
        ></i>
      </button>
    </div>
  </ng-container>
</ng-container>

<ng-container *ngIf="isLoading">
  <div>
    <p>
      <app-loader></app-loader>
    </p>

    <p>{{ "general.loading" | translate }}</p>
  </div>
</ng-container>

<ng-container *ngIf="isCashbackPopup">
  <section class="c-dialog__backdrop atLeftMenu">
    <div class="c-dialog">
      <div class="c-dialog__header">
        <img
          src="/assets/img/logo.svg"
          alt="{{ 'general.site_name' | translate }}"
          width="100%"
          height="100%"
        />
      </div>

      <div class="c-dialog__body">
        <ng-container *ngIf="isSuccessResponse">
          <div>
            {{ "rewards.cashbackSuccess" | translate }}
            <br />
            {{ "rewards.for" | translate }}
            {{ optinValidityDuration }}
          </div>
        </ng-container>

        <ng-container *ngIf="!isSuccessResponse">
          <div>
            {{ "common.error44" | translate }}
          </div>
        </ng-container>
      </div>

      <div class="c-dialog__footer">
        <a
          class="btn-yes"
          aria-label="Close"
          (click)="onCloseConfirmDialogue(bonusDetails)"
        >
          {{ "rewards.close" | translate }}
        </a>
      </div>
    </div>
  </section>
</ng-container>
