import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DatePipe } from "@angular/common";
import { Component } from "@angular/core";
import { Subscription } from "rxjs";
import { Store } from "@ngrx/store";

// Components
import { FormValidationComponent } from "src/app/modules/shared/components/form-validation/form-validation.component";

// Constants
import { UserFlowTypes } from "src/app/constants/costants";

// Enums
import { StatusResponse } from "src/app/models/api/status.response";

// Models
import { GamingLockAccountRequest } from "src/app/modules/limits/models/gaming-lock-account/gaming-lock-account-request.model";
import {
  GamingLockAccountCoolingError,
  GamingLockAccountResponse,
} from "src/app/modules/limits/models/gaming-lock-account/gaming-lock-account-response.model";

// Reducers
import { AppState } from "src/app/store/reducers";

// Selectors
import { selectLanguageCode } from "src/app/modules/multi-languages/store/selectors/languages.selectors";

// Services
import { MultiLanguageService } from "src/app/modules/multi-languages/services/multi-language.service";
import { LoginAPIService } from "src/app/modules/auth/store/services/login-api.service";
import { UtilityService } from "src/app/modules/shared/services/utility.service";
import { FormService } from "src/app/modules/dynamic-form/services/form.service";
import { LimitsService } from "src/app/modules/limits/services/limits.service";

@Component({
  selector: "app-self-exclusion",
  templateUrl: "./self-exclusion.component.html",
  styleUrls: ["./self-exclusion.component.scss"],
  providers: [DatePipe],
})
export class SelfExclusionComponent extends FormValidationComponent {
  // Strings
  startDateFormatted: string = "";
  endDateFormatted: string = "";
  languageCode: string = "";
  serverError: string = "";

  // Booleans
  isExclusionPopup: boolean = false;
  isPassword: boolean = false;

  // Date
  startDate: Date;
  endDate: Date;

  // Enums
  UserFlowTypes = UserFlowTypes;

  // Form Groups
  suspendAccountForm: FormGroup;

  // Subscriptions
  responsibleGamingLockAccountSubscription: Subscription;
  subscription: Subscription;

  constructor(
    private multiLanguageService: MultiLanguageService,
    private loginAPIService: LoginAPIService,
    private utilityService: UtilityService,
    private limitsService: LimitsService,
    private formBuilder: FormBuilder,
    private formService: FormService,
    private store: Store<AppState>,
    private datePipe: DatePipe
  ) {
    super();

    this.onLoad();
  }

  // -----------------------------------------------------------------
  // Lifecycle Hooks
  ngOnInit(): void {
    this.languageCode = this.multiLanguageService.getLanguageCode();

    this.subscription = this.store
      .select(selectLanguageCode)
      .subscribe((languageCode: string) => {
        this.languageCode = languageCode;
      });
  }

  // -----------------------------------------------------------------
  // Get Methods
  getExclusionPeriod(): number {
    return this.suspendAccountForm.controls["rgCoolOffPeriodInDays"].value;
  }

  isButtonDisabled(formGroup: FormGroup): boolean {
    if (!formGroup.controls["rgCoolOffPeriodInDays"].value) {
      return formGroup.invalid || formGroup.pending;
    }
  }

  // -----------------------------------------------------------------
  // Set Methods
  onLoad(): void {
    this.suspendAccountForm = this.formBuilder.group({
      rgCoolOffPeriodInDays: ["", [Validators.required]],
      rgClose: ["false"],
      reason: ["Self Exclusion", [Validators.required]],
      password: [""],
    });
  }

  onSetExclusionPeriod(period: number): void {
    if (
      period !== this.suspendAccountForm.controls["rgCoolOffPeriodInDays"].value
    ) {
      this.onCalculateDateRange(period);

      this.suspendAccountForm.controls["rgCoolOffPeriodInDays"].setValue(
        period
      );
    } else {
      this.onClearExclusionPeriod();
    }
  }

  onOpenConfirmationDailogue(): void {
    if (this.suspendAccountForm.controls["rgCoolOffPeriodInDays"].value) {
      this.isExclusionPopup = true;

      this.suspendAccountForm.controls["password"].setValidators(
        Validators.required
      );

      this.suspendAccountForm.updateValueAndValidity();
    }
  }

  onCloseConfirmationDailogue(): void {
    this.onClearExclusionPeriod();

    this.isExclusionPopup = false;

    this.suspendAccountForm.controls["password"].setErrors(null);

    this.suspendAccountForm.controls["password"].clearValidators();

    this.suspendAccountForm.updateValueAndValidity();
  }

  onClearExclusionPeriod(): void {
    this.suspendAccountForm.controls["password"].setValue("");

    this.suspendAccountForm.controls["rgCoolOffPeriodInDays"].setValue("");

    this.suspendAccountForm.controls["password"].markAsUntouched({
      onlySelf: true,
    });

    this.suspendAccountForm.controls["password"].markAsPristine({
      onlySelf: true,
    });

    this.startDate = undefined;

    this.endDate = undefined;
  }

  onCalculateDateRange(period: number): void {
    let days: number = period;

    this.startDateFormatted = this.datePipe.transform(
      new Date(),
      "dd/MM/yyyy HH:mm"
    );

    this.endDate = new Date();

    this.endDate.setDate(this.endDate.getDate() + days);

    this.endDateFormatted = this.datePipe.transform(
      this.endDate,
      "dd/MM/yyyy HH:mm"
    );
  }

  onConfirmSuspendAccount(): void {
    if (this.suspendAccountForm.valid || this.utilityService.isPnpFlow()) {
      this.isExclusionPopup = false;

      let gamingLockAccountRequest: GamingLockAccountRequest =
        this.formService.transformFormToData<GamingLockAccountRequest>(
          this.suspendAccountForm,
          {}
        );

      this.responsibleGamingLockAccountSubscription = this.limitsService
        .onResponsibleGamingLockAccount(gamingLockAccountRequest)
        .subscribe((gamingLockAccountResponse: GamingLockAccountResponse) => {
          if (
            gamingLockAccountResponse &&
            gamingLockAccountResponse.success &&
            gamingLockAccountResponse.success.status === StatusResponse.SUCCESS
          ) {
            this.utilityService.closeAccountComponent();

            this.loginAPIService.onGetLogout();
          } else {
            this.onClearExclusionPeriod();

            this.serverError = (
              gamingLockAccountResponse.errors as GamingLockAccountCoolingError
            ).password;
          }

          this.suspendAccountForm.controls["password"].setErrors(null);

          this.suspendAccountForm.controls["password"].clearValidators();

          this.suspendAccountForm.updateValueAndValidity();
        });
    }

    setTimeout(() => {
      this.serverError = undefined;
    }, 5000);
  }

  // -----------------------------------------------------------------
  // On Destroy
  ngOnDestroy(): void {
    if (this.responsibleGamingLockAccountSubscription)
      this.responsibleGamingLockAccountSubscription.unsubscribe();

    if (this.subscription) this.subscription.unsubscribe();
  }
}
