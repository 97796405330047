/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./default-live-casino.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../game-card/game-card.component.ngfactory";
import * as i3 from "../game-card/game-card.component";
import * as i4 from "../../../multi-languages/services/translation.service";
import * as i5 from "../../../user/services/user-details.service";
import * as i6 from "../../../shared/pipes/currency-format.pipe";
import * as i7 from "../../services/game-play.service";
import * as i8 from "../../../shared/services/mix-panel.service";
import * as i9 from "../../services/jackpot.service";
import * as i10 from "../../../shared/services/utility.service";
import * as i11 from "../../../auth/services/session.service";
import * as i12 from "../../../shared/services/common.service";
import * as i13 from "@ngrx/store";
import * as i14 from "@angular/router";
import * as i15 from "@angular/common";
import * as i16 from "@ngx-translate/core";
import * as i17 from "../../../../../../node_modules/ngx-swiper-wrapper/dist/ngx-swiper-wrapper.ngfactory";
import * as i18 from "ngx-swiper-wrapper";
import * as i19 from "../../../shared/directives/swiper-resize.directive";
import * as i20 from "./default-live-casino.component";
var styles_DefaultLiveCasinoComponent = [i0.styles];
var RenderType_DefaultLiveCasinoComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DefaultLiveCasinoComponent, data: {} });
export { RenderType_DefaultLiveCasinoComponent as RenderType_DefaultLiveCasinoComponent };
function View_DefaultLiveCasinoComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "game-card casino live-casino"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "game-card-inner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-game-card", [], null, null, null, i2.View_GameCardComponent_0, i2.RenderType_GameCardComponent)), i1.ɵdid(3, 4964352, null, 0, i3.GameCardComponent, [i4.TranslationService, i5.UserDetailsService, i6.CurrencyFormatPipe, i7.GamePlayService, i8.MixPanelService, i9.JackpotService, i10.UtilityService, i11.SessionService, i12.CommonService, i13.Store, i14.Router], { game_asset_style: [0, "game_asset_style"], gameGroupName: [1, "gameGroupName"], lobbyName: [2, "lobbyName"], gameData: [3, "gameData"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.gameGroupData.game_asset_style; var currVal_1 = _co.gameGroupData.name; var currVal_2 = _co.callingFromlobby; var currVal_3 = _v.parent.context.$implicit; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
function View_DefaultLiveCasinoComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DefaultLiveCasinoComponent_2)), i1.ɵdid(2, 16384, null, 0, i15.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = (_v.context.$implicit.gameType === "live_casino"); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_DefaultLiveCasinoComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "div", [["class", "game-group__container"]], null, null, null, null, null)), i1.ɵprd(512, null, i15.ɵNgClassImpl, i15.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i15.NgClass, [i15.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "continue-games": 0, "favourite-games": 1 }), (_l()(), i1.ɵeld(4, 0, null, null, 5, "div", [["class", "game-group--title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [["class", "gameGroupName-link"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onNavigateAllGamesPage() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(6, null, [" ", " "])), (_l()(), i1.ɵeld(7, 0, null, null, 2, "span", [["class", "viewall-link"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onNavigateAllGamesPage() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(8, null, [" ", " "])), i1.ɵpid(131072, i16.TranslatePipe, [i16.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(10, 0, null, null, 7, "div", [["class", "swiper__outer hoverspace"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 6, "div", [["class", "swiper__inner swiper__full"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 5, "swiper", [["class", "mySwiper swiper-control"], ["watchSlidesProgress", ""]], null, [[null, "slidePrevTransitionEnd"], [null, "slideNextTransitionEnd"], ["window", "resize"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 14).onResize() !== false);
        ad = (pd_0 && ad);
    } if (("slidePrevTransitionEnd" === en)) {
        var pd_1 = (_co.onSwiperPrevEvent() !== false);
        ad = (pd_1 && ad);
    } if (("slideNextTransitionEnd" === en)) {
        var pd_2 = (_co.onSwiperNextEvent() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i17.View_SwiperComponent_0, i17.RenderType_SwiperComponent)), i1.ɵdid(13, 4374528, null, 0, i18.SwiperComponent, [i1.NgZone, i1.ChangeDetectorRef, i1.PLATFORM_ID, [2, i18.SWIPER_CONFIG]], { config: [0, "config"] }, { S_SLIDEPREVTRANSITIONEND: "slidePrevTransitionEnd", S_SLIDENEXTTRANSITIONEND: "slideNextTransitionEnd" }), i1.ɵdid(14, 16384, null, 0, i19.SwiperResizeDirective, [i18.SwiperComponent], null, null), (_l()(), i1.ɵand(16777216, null, 0, 2, null, View_DefaultLiveCasinoComponent_1)), i1.ɵdid(16, 278528, null, 0, i15.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(0, i15.SlicePipe, [])], function (_ck, _v) { var _co = _v.component; var currVal_0 = "game-group__container"; var currVal_1 = _ck(_v, 3, 0, ((_co.gameGroupData && (_co.gameGroupData.group_type === "automatic")) && (_co.gameGroupData.group_sub_type === "continue_playing")), ((_co.gameGroupData && (_co.gameGroupData.group_type === "automatic")) && (_co.gameGroupData.group_sub_type === "favourite"))); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_4 = _co.swiperLiveCasino; _ck(_v, 13, 0, currVal_4); var currVal_5 = i1.ɵunv(_v, 16, 0, i1.ɵnov(_v, 17).transform(_co.gamesList, 0, _co.swiperLazyConfigurations.indexNumberOfGameTilesToshow)); _ck(_v, 16, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.gameGroupData.name; _ck(_v, 6, 0, currVal_2); var currVal_3 = i1.ɵunv(_v, 8, 0, i1.ɵnov(_v, 9).transform("gamegroup.view_all")); _ck(_v, 8, 0, currVal_3); }); }
export function View_DefaultLiveCasinoComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-default-live-casino", [], null, null, null, View_DefaultLiveCasinoComponent_0, RenderType_DefaultLiveCasinoComponent)), i1.ɵdid(1, 770048, null, 0, i20.DefaultLiveCasinoComponent, [i5.UserDetailsService, i10.UtilityService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DefaultLiveCasinoComponentNgFactory = i1.ɵccf("app-default-live-casino", i20.DefaultLiveCasinoComponent, View_DefaultLiveCasinoComponent_Host_0, { gameGroupData: "gameGroupData", callingFromlobby: "callingFromlobby" }, {}, []);
export { DefaultLiveCasinoComponentNgFactory as DefaultLiveCasinoComponentNgFactory };
