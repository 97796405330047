import { Subscription } from "rxjs";
import { Store } from "@ngrx/store";
import {
  ViewEncapsulation,
  ElementRef,
  Component,
  ViewChild,
} from "@angular/core";

// Models
import { UserData } from "src/app/modules/user/models/user-data.model";

// Reducers
import { AppState } from "src/app/store/reducers";

// Selectors
import { selectAuthUserDataLoaded } from "src/app/modules/user/store/selectors/user.selectors";

// Services
import { GtmService } from "src/app/modules/shared/services/gtm.service";

@Component({
  selector: "app-storyly",
  templateUrl: "./storyly.component.html",
  styleUrls: ["./storyly.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class StorylyComponent {
  // View Child
  @ViewChild("storylyweb", { static: false }) storylyWebDom: ElementRef;

  // Strings
  userCountry: string = "";

  // Subscriptions
  subscription: Subscription;

  constructor(private store: Store<AppState>, private gtmService: GtmService) {}

  // -----------------------------------------------------------------
  // Lifecycle Hooks
  ngAfterViewInit(): void {
    this.subscription = this.store
      .select(selectAuthUserDataLoaded)
      .subscribe(
        (result: {
          userData: UserData;
          isLoaded: boolean;
          isError: boolean;
        }) => {
          if (result.isLoaded) {
            this.userCountry = result.userData.country.toLowerCase();

            this.onInitializeStoryly();
          }
        }
      );
  }

  // -----------------------------------------------------------------
  // Set Methods
  onInitializeStoryly(): void {
    if (this.storylyWebDom) {
      this.storylyWebDom.nativeElement.init({
        token:
          "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhY2NfaWQiOjQzNDAsImFwcF9pZCI6MTUyNjMsImluc19pZCI6MTY3MDF9.bxFQxOAFoPzoxNgcfcFNSmeoSvPD4Mpoos6stDwDnwU",
        props: {
          storyGroupTextVisibility: true,
          storyGroupTextColor: "#fff",
          storyGroupIconBorderColorSeen: ["#F2F7FC", "#F2F7FC"],
          storyGroupIconBorderColorNotSeen: ["#7A90F6", "#60A8FF"],
          storyGroupSize: "78x78",
          storyGroupBorderRadius: 100,
          storyGroupAlign: "left",
          storyGroupMarginHorizontal: 0,
        },
        // Filter Dashboard by user language
        segments: [this.userCountry],
        events: {
          onStoryViewed: (story) => {
            // tracking triggered when a story is viewed
            this.gtmService.onGtmDataLayerEvent({
              event: "storyly_onStoryViewed",
              data: story,
            });
          },
          actionClicked: (story) => {
            // tracking triggered when clicked Swipe Up or Action Button.
            this.gtmService.onGtmDataLayerEvent({
              event: "storyly_actionClicked",
              data: story,
            });
          },
        },
      });
    }
  }

  // -----------------------------------------------------------------
  // On Destroy
  ngOnDestroy(): void {
    if (this.subscription) this.subscription.unsubscribe();
  }
}
