import { FormBuilder, FormGroup } from "@angular/forms";
import { Component } from "@angular/core";
import { Subscription } from "rxjs";
import { Store } from "@ngrx/store";

// Constants
import { UserFlowTypes } from "src/app/constants/costants";

// Enums
import { StatusResponse } from "src/app/models/api/status.response";

// Models
import { UpdateSubscriptionPreferenceResponse } from "src/app/modules/shared/models/preferences/update-subscription-preference-response.model";
import { UpdateSubscriptionPreferenceRequest } from "src/app/modules/shared/models/preferences/update-subscription-preference-request.model";
import { SubscriptionPreferenceResponse } from "src/app/modules/shared/models/preferences/subscription-preference-response.model";
import { LoaderSubscriptions } from "src/app/modules/shared/models/preferences/loader-subscriptions.model";
import { UserData } from "src/app/modules/user/models/user-data.model";

// Reducers
import { AppState } from "src/app/store/reducers";

// Selectors
import { selectLanguageCode } from "src/app/modules/multi-languages/store/selectors/languages.selectors";

// Services
import { MultiLanguageService } from "src/app/modules/multi-languages/services/multi-language.service";
import { TranslationService } from "src/app/modules/multi-languages/services/translation.service";
import { UserDetailsService } from "src/app/modules/user/services/user-details.service";
import { AccountService } from "src/app/modules/account/services/account.service";
import { UtilityService } from "src/app/modules/shared/services/utility.service";
import { FormService } from "src/app/modules/dynamic-form/services/form.service";

@Component({
  selector: "app-preferences",
  templateUrl: "./preferences.component.html",
  styleUrls: ["./preferences.component.scss"],
})
export class PreferencesComponent {
  // Strings
  errorMessageText: string = "";
  mobileNumber: string = "";
  languageCode: string = "";
  emailId: string = "";

  // Form Groups
  subscriptionPreferenceForm: FormGroup;

  // Objects
  loader: LoaderSubscriptions = {
    emailSubscription: { disable: false, sub: false },
    mobileSubcription: { disable: false, sub: false },
  };

  // Enums
  UserFlowTypes = UserFlowTypes;

  // Objects
  profileDetails: UserData;

  // Subscriptions
  updateSubscriptionPreferenceSubscription: Subscription;
  subscriptionPreferenceSubscription: Subscription;
  languageCodeSubscription: Subscription;
  userDataSubscription: Subscription;

  constructor(
    private multiLanguageService: MultiLanguageService,
    private userDetailsService: UserDetailsService,
    private translationService: TranslationService,
    private accountService: AccountService,
    private utilityService: UtilityService,
    private formBuilder: FormBuilder,
    private formService: FormService,
    private store: Store<AppState>
  ) {
    this.subscriptionPreferenceForm = this.formBuilder.group({
      emailSubscribed: [false],
      mobileSubscribed: [false],
    });
  }

  // -----------------------------------------------------------------
  // Lifecycle Hooks
  ngOnInit(): void {
    if (this.utilityService.isPnpFlow()) {
      this.onGetUserProfileData();
    }

    this.onGetSubscriptionPreference("");

    this.languageCode = this.multiLanguageService.getLanguageCode();

    this.languageCodeSubscription = this.store
      .select(selectLanguageCode)
      .subscribe((languageCode: string) => {
        this.languageCode = languageCode;
      });
  }

  // -----------------------------------------------------------------
  // Set Methods
  onGetUserProfileData(): void {
    if (this.userDetailsService.getUserProfileDetails()) {
      this.profileDetails = this.userDetailsService.getUserProfileDetails();

      this.onSetUserDetails(this.profileDetails);
    } else {
      this.userDataSubscription = this.userDetailsService
        .onGetUserData()
        .subscribe((data: UserData) => {
          this.profileDetails = data;

          this.onSetUserDetails(this.profileDetails);
        });
    }
  }

  onSetUserDetails(profileData: UserData): void {
    if (profileData) {
      this.emailId = profileData.email;

      this.mobileNumber = `+${profileData.cellPhoneAreaCode}${profileData.cellPhone}`;
    }
  }

  onUpdateSubscriptionPreference(callingId: string): void {
    this.onTogglePreferenceLoader(callingId, true);

    let updateSubscriptionPreferenceRequest: UpdateSubscriptionPreferenceRequest =
      this.formService.transformFormToData<UpdateSubscriptionPreferenceRequest>(
        this.subscriptionPreferenceForm,
        {}
      );

    this.updateSubscriptionPreferenceSubscription = this.accountService
      .onUpdateSubscriptionPreference(updateSubscriptionPreferenceRequest)
      .subscribe(
        (
          updateSubscriptionPreferenceResponse: UpdateSubscriptionPreferenceResponse
        ) => {
          if (
            updateSubscriptionPreferenceResponse &&
            updateSubscriptionPreferenceResponse.status ===
              StatusResponse.SUCCESS
          ) {
            this.onGetSubscriptionPreference(callingId);
          } else {
            if (callingId === "emailSubscription") {
              this.onUpdateSubscriptionForm(
                !updateSubscriptionPreferenceResponse.emailSubscribed,
                updateSubscriptionPreferenceResponse.mobileSubscribed,
                callingId
              );
            } else {
              this.onUpdateSubscriptionForm(
                updateSubscriptionPreferenceResponse.emailSubscribed,
                !updateSubscriptionPreferenceResponse.mobileSubscribed,
                callingId
              );
            }

            this.errorMessageText = this.translationService.get(
              "preference.error_handle_msg"
            );

            setTimeout(() => {
              this.errorMessageText = undefined;
            }, 5000);
          }
        }
      );
  }

  onGetSubscriptionPreference(callingId?: string): void {
    this.onTogglePreferenceLoader(callingId, true);

    this.subscriptionPreferenceSubscription = this.accountService
      .onSubscriptionPreference()
      .subscribe(
        (subscriptionPreferenceResponse: SubscriptionPreferenceResponse) => {
          if (
            subscriptionPreferenceResponse &&
            subscriptionPreferenceResponse.status == StatusResponse.SUCCESS
          ) {
            this.onUpdateSubscriptionForm(
              subscriptionPreferenceResponse.emailSubscribed,
              subscriptionPreferenceResponse.mobileSubscribed,
              callingId
            );
          } else {
            this.errorMessageText = this.translationService.get(
              "preference.error_handle_msg"
            );

            setTimeout(() => {
              this.errorMessageText = undefined;
            }, 5000);
          }
        }
      );
  }

  onUpdateSubscriptionForm(
    emailStatus: boolean,
    mobileStatus: boolean,
    callingId?: string
  ): void {
    this.subscriptionPreferenceForm.controls["emailSubscribed"].setValue(
      emailStatus
    );

    this.subscriptionPreferenceForm.controls["mobileSubscribed"].setValue(
      mobileStatus
    );

    if (callingId) {
      this.onTogglePreferenceLoader(callingId, false);
    }
  }

  onTogglePreferenceLoader(callingId: string, isEnable: boolean): void {
    if (isEnable && callingId) {
      this.loader[callingId].sub = true;

      this.loader[callingId].disable = true;
    } else if (callingId) {
      this.loader[callingId].sub = false;

      this.loader[callingId].disable = false;
    }
  }

  // -----------------------------------------------------------------
  // On Destroy
  ngOnDestroy(): void {
    if (this.updateSubscriptionPreferenceSubscription)
      this.updateSubscriptionPreferenceSubscription.unsubscribe();

    if (this.subscriptionPreferenceSubscription)
      this.subscriptionPreferenceSubscription.unsubscribe();

    if (this.languageCodeSubscription)
      this.languageCodeSubscription.unsubscribe();

    if (this.userDataSubscription) this.userDataSubscription.unsubscribe();
  }
}
