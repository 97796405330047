<ng-container *ngIf="rewardSize === 'basic'">
  <div
    class="rewardBonusCard"
    [class.hostedCashier]="callingFrom === 'hostedCashier'"
    *ngIf="bonusDetails"
    (click)="(callingFrom === 'all-rewards' || callingFrom === 'pnp-cashier') ? 
    onOpenDepositView(bonusDetails) : onSelectBonus(bonusDetails)"
  >
    <div class="rewardBonusCard__bg">
      <img
        src="/assets/img/rewards/bonus-img/shotz-rewards.png"
        alt="bonus-card-bg"
      />
    </div>

    <div class="rewardBonusCard__container">
      <div class="rewardBonusCard__icon">
        <ng-container
          *ngIf="(bonusDetails?.amountType === 'PCT' || bonusDetails?.amountType === 'ABS') &&
           !isRealUser && bonusDetails?.bonusCode"
        >
          <img
            *ngIf="bonusDetails?.bonusCode.toLowerCase().startsWith('welcomeoffer_casino')"
            src="/assets/img/rewards/bonus-img/welcome-bonus-deposit-restrict.png"
            class="rewardBonusCardImg"
            alt="welcome-bonus-deposit-restrict"
          />

          <img
            *ngIf="bonusDetails?.bonusCode.toLowerCase().startsWith('welcomeoffer_live')"
            src="/assets/img/rewards/bonus-img/welcome-bonus-release-restrict.png"
            class="rewardBonusCardImg"
            alt="welcome-bonus-release-restrict"
          />

          <img
            *ngIf="!bonusDetails?.bonusCode.toLowerCase().startsWith('welcomeoffer_casino') && 
                   !bonusDetails?.bonusCode.toLowerCase().startsWith('welcomeoffer_live')"
            src="/assets/img/rewards/bonus-img/welcome-bonus-release-restrict.png"
            class="rewardBonusCardImg"
            alt="welcome-bonus-release-restrict"
          />
        </ng-container>

        <ng-container
          *ngIf="(bonusDetails?.amountType === 'PCT' || bonusDetails?.amountType === 'ABS' ) && isRealUser"
        >
          <img
            src="/assets/img/rewards/bonus-img/wager-bonus.png"
            class="rewardBonusCardImg"
            alt="wager-bonus"
          />
        </ng-container>

        <div
          class="countdown-timer"
          *ngIf="bonusDetails?.campaignEndDate || bonusDetails?.targetValidityDate"
        >
          <app-countdown-timer
            [expiryDate]="bonusDetails?.targetValidityDate ? (bonusDetails?.targetValidityDate * 1000) : bonusDetails?.campaignEndDate"
          ></app-countdown-timer>
        </div>
      </div>
      <div class="rewardBonusCard__details">
        <div class="rewardBonusCard__text-inside">
          <div class="rewardBonusCard--title">
            <ng-container *ngIf="bonusDetails?.bonusCode && !isRealUser">
              <ng-container
                *ngIf="bonusDetails?.bonusCode.toLowerCase().startsWith('welcomeoffer_casino')"
                >{{'rewards.welcome_bonus' | translate}}</ng-container
              >
              <ng-container
                *ngIf="bonusDetails?.bonusCode.toLowerCase().startsWith('welcomeoffer_live')"
                >{{'rewards.live_casino_offer' | translate}}</ng-container
              >

              <ng-container
                *ngIf="!bonusDetails?.bonusCode.toLowerCase().startsWith('welcomeoffer_casino') && 
                       !bonusDetails?.bonusCode.toLowerCase().startsWith('welcomeoffer_live')"
                >{{'rewards.live_casino_offer' | translate}}</ng-container
              >
            </ng-container>

            <ng-container *ngIf="isRealUser"
              >{{'rewards.wager_get_paid'| translate}}</ng-container
            >
          </div>

          <div class="rewardBonusCard--desc">
            {{'rewards.wager_deposit_issue_multiplier' | translate
            :({'issueMultiplier': bonusDetails?.issueMultiplier })}}<br />
            <ng-container
              *ngIf="bonusDetails?.currencyTier && bonusDetails?.amountType === 'PCT'"
            >
              {{'rewards.get_pct_upto_maxoffer'| translate:({
              'percentageValue':(bonusDetails?.currencyTier[currencyCode][0].percentageValue),
              'maxOfferValue':(((bonusDetails?.currencyTier[currencyCode][0].maxOfferValue)/100)
              | currencyformat:currencySymbol:false) })}}
            </ng-container>

            <ng-container
              *ngIf="bonusDetails?.currencyTier && bonusDetails?.amountType === 'ABS'"
            >
              {{'rewards.get_maxoffer_cash'| translate :({
              'maxOfferValue':(((bonusDetails?.currencyTier[currencyCode][0].maxOfferValue)/100)
              | currencyformat:currencySymbol:false) })}}
            </ng-container>
          </div>
        </div>
        <button
          aria-label="Claim offer"
          class="btn btn-primary btn-round btn-xs"
          *ngIf="callingFrom === 'all-rewards' || callingFrom === 'pnp-cashier'"
        >
          {{ 'rewards.claim_offer' | translate }}
        </button>
        <div
          class="payment-bonus-swich"
          *ngIf="callingFrom === 'hostedCashier'"
        >
          <div
            class="toggleSwitch m-r-8"
            [class.active]="selectedBonus && selectedBonus?.bonusCode === bonusDetails?.bonusCode"
          ></div>
          <span class="bonus-status">
            <ng-container
              *ngIf="!(selectedBonus && selectedBonus?.bonusCode === bonusDetails?.bonusCode)"
            >
              {{'rewards.add_bonus' | translate}}
            </ng-container>
            <ng-container
              *ngIf="(selectedBonus && selectedBonus?.bonusCode === bonusDetails.bonusCode)"
            >
              {{'rewards.bonus_added' | translate}}
            </ng-container>
          </span>
        </div>

        <button aria-label="Terms" class="btn btn-info">
          <i class="icon-info" (click)="onOpenRewardTCPopUp($event)"></i>
        </button>
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="rewardSize === 'shorter'">
  <div class="pnp-short-bonus" (click)="onSelectBonus(bonusDetails)">
    <div class="bonus-card--info">
      {{'rewards.wager_deposit_issue_multiplier' | translate
      :({'issueMultiplier': bonusDetails?.issueMultiplier})}}<br />
      <ng-container
        *ngIf="bonusDetails?.currencyTier && bonusDetails?.amountType === 'PCT'"
      >
        {{'rewards.get_pct_upto_maxoffer'| translate:({
        'percentageValue':(bonusDetails?.currencyTier[currencyCode][0].percentageValue),
        'maxOfferValue':(((bonusDetails?.currencyTier[currencyCode][0].maxOfferValue)
        / 100) | currencyformat:currencySymbol:false) })}}
      </ng-container>
      <ng-container
        *ngIf="bonusDetails?.currencyTier && bonusDetails?.amountType === 'ABS'"
      >
        {{'rewards.get_maxoffer_cash'| translate :({
        'maxOfferValue':(((bonusDetails?.currencyTier[currencyCode][0].maxOfferValue)/100)
        | currencyformat:currencySymbol:false) })}}
      </ng-container>
    </div>
    <div class="choose-bonuse">
      <ng-container
        *ngIf="!(selectedBonus && selectedBonus?.bonusCode === bonusDetails?.bonusCode)"
      >
        <i class="icon-checkbox"></i>{{'rewards.yes_add_bonus' | translate}}
      </ng-container>
      <ng-container
        *ngIf="(selectedBonus && selectedBonus?.bonusCode === bonusDetails?.bonusCode)"
      >
        <i class="icon-checkbox-checked checked"></i>{{'rewards.bonus_added' |
        translate}}
      </ng-container>
    </div>
    <div
      class="bonus-card--expiry"
      *ngIf="(bonusDetails?.campaignEndDate || bonusDetails?.targetValidityDate)"
    >
      <app-countdown-timer
        [expiryDate]="bonusDetails?.targetValidityDate ? (bonusDetails?.targetValidityDate * 1000) : bonusDetails?.campaignEndDate"
      >
      </app-countdown-timer>
    </div>
    <button aria-label="Terms" class="bonus-card--terms">
      <i class="icon-info" (click)="onOpenRewardTCPopUp($event)"></i>
    </button>
  </div>
</ng-container>
