/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./storyly.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./storyly.component";
import * as i3 from "@ngrx/store";
import * as i4 from "../../../shared/services/gtm.service";
var styles_StorylyComponent = [i0.styles];
var RenderType_StorylyComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_StorylyComponent, data: {} });
export { RenderType_StorylyComponent as RenderType_StorylyComponent };
export function View_StorylyComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { storylyWebDom: 0 }), (_l()(), i1.ɵeld(1, 0, [[1, 0], ["storylyweb", 1]], null, 0, "storyly-web", [], null, null, null, null, null))], null, null); }
export function View_StorylyComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-storyly", [], null, null, null, View_StorylyComponent_0, RenderType_StorylyComponent)), i1.ɵdid(1, 4374528, null, 0, i2.StorylyComponent, [i3.Store, i4.GtmService], null, null)], null, null); }
var StorylyComponentNgFactory = i1.ɵccf("app-storyly", i2.StorylyComponent, View_StorylyComponent_Host_0, {}, {}, []);
export { StorylyComponentNgFactory as StorylyComponentNgFactory };
