<!DOCTYPE html>
<html>
  <head></head>
  <body></body>
  <script>
    (function () {
      const queryParams = JSON.parse(
        '{"' +
          decodeURI(
            window.location.search
              .substring(1)
              .replace(/&/g, '","')
              .replace(/=/g, '":"')
          ) +
          '"}'
      );
      /**queryParams['transactionType']
       * possible values we get deposit & withdraw
       */
      if (isInsideIframe()) {
        let postMessageData = {
          message: queryParams["transactionType"] + "-transactionStatus",
        };
        window.parent.postMessage(postMessageData, "*");
      } else {
        let value = queryParams["transactionType"] + "-transactionStatus";
        window.location.href = window.location.origin + "?redirect=" + value;
      }
    })();

    function isInsideIframe() {
      try {
        return window.self !== window.top;
      } catch (e) {
        return true;
      }
    }
  </script>
</html>
