<div
  class="overlay-backdrop atTagFilter"
  (click)="onCloseGamesToaster()"
  *ngIf="isGamesToasterOpen"
></div>

<div
  class="games-tag__bar"
  id="gFooter"
  [ngClass]="{'gameplayFooterHover':isGamePlayFooterHover, 'gameplayHover':isGamesToasterOpen}"
  (mouseenter)="onHoverBottomRibbon()"
  (mouseleave)="onMouseLeaveBottomRibbon()"
>
  <div class="games-tag__bar__inner">
    <div
      class="games-tag__container"
      [ngClass]="isLoggedIn?'isLoggedIn':'isNotLoggedIn'"
    >
      <div
        class="swiper__outer clearfix float-left"
        [class.nousedcc]="isNoCCCardsAvialable"
        [ngClass]="zimplerMarketsList.indexOf(languageCode) >= 0? 'isPNP' : 'isClassic'"
      >
        <div class="swiper__inner atTagButtonsSwiper">
          <swiper
            class="mySwiper swiper-control clearfix"
            [config]="swiperTagButton"
            watchSlidesProgress
          >
            <button
              aria-label="Last played"
              class="btn btn-xs btn-tag btn-round"
              *ngIf="isLoggedIn"
              (click)="isTouchScreen ? onFilterGamesByTagName('last-played') :''"
              (mouseenter)="!isTouchScreen ? onFilterGamesByTagName('last-played'):''"
              [class.active]="activeTag === 'last-played'"
            >
              {{'tag-management.last_played' | translate}}
            </button>
            <button
              aria-label="Tags"
              class="btn btn-xs btn-tag btn-round"
              [class.active]="activeTag === tag"
              (click)="isTouchScreen ? onFilterGamesByTagName(tag) :''"
              (mouseenter)="!isTouchScreen ? onFilterGamesByTagName(tag): ''"
              *ngFor="let tag of allAvaialbleTagsList"
            >
              {{tag}}
            </button>
          </swiper>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="games-tag__toaster"
  [ngClass]="isGamesToasterOpen ? 'isOpen' : 'isClose'"
  (mouseleave)="onCloseGamesToaster()"
>
  <div
    class="swiper__outer"
    [ngClass]="!isLoading && activeTagGamesList && activeTagGamesList.length>0 ? 'show' : 'hide'"
  >
    <div class="swiper__inner atTagFilterSwiper">
      <swiper
        class="mySwiper swiper-control"
        [config]="swiperTagFilter"
        (slidePrevTransitionEnd)="onSwiperPrevEvent()"
        (slideNextTransitionEnd)="onSwiperNextEvent()"
        watchSlidesProgress
      >
        <div
          class="game-card casino game-tag small"
          *ngFor="let game of activeTagGamesList | slice:0:swiperLazyConfigurations.indexNumberOfGameTilesToshow;let i=index"
        >
          <app-game-card
            [gameData]="game"
            [game_asset_style]="'default-casino'"
            (callBackAction)="onCloseGamesToaster()"
            [callingFrom]="'game-window-tag'"
            [activeTagName]="activeTag"
          >
          </app-game-card>
        </div>
      </swiper>
    </div>
  </div>

  <ng-container *ngIf="isLoading">
    <div class="loading-games-msg loading-msg fade-in">
      {{'tag-management.game_loading_waiting' | translate}}
    </div>
  </ng-container>
  <ng-container
    *ngIf="!isLoading && activeTagGamesList && activeTagGamesList.length === 0"
  >
    <div class="no-game-msg loading-msg fade-in">
      {{'tag-management.no_games_found' | translate}}
    </div>
  </ng-container>
</div>
