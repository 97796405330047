import {
  AfterViewInit,
  SimpleChanges,
  ElementRef,
  Component,
  ViewChild,
  OnChanges,
  Input,
} from "@angular/core";

// Pipes
import { CurrencyFormatPipe } from "src/app/modules/shared/pipes/currency-format.pipe";

@Component({
  selector: "app-jackpot-counter",
  templateUrl: "./jackpot-counter.component.html",
  styleUrls: ["./jackpot-counter.component.scss"],
})
export class JackpotCounterComponent implements AfterViewInit, OnChanges {
  // View Childs
  @ViewChild("animatedDigit", { static: false }) animatedDigit: ElementRef;

  // Inputs
  @Input() currencySymbol: string = "";
  @Input() duration: number = 0;
  @Input() digit: number = 0;
  @Input() steps: number = 0;

  constructor(private currencyFormatPipe: CurrencyFormatPipe) {}

  // -----------------------------------------------------------------
  // Lifecycle Hooks
  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes["digit"] &&
      changes["digit"].previousValue !== changes["digit"].currentValue
    ) {
      this.onAnimateCounter();
    }
  }

  ngAfterViewInit(): void {
    if (this.digit) {
      this.onAnimateCounter();
    }
  }

  // -----------------------------------------------------------------
  // Get Methods
  getConvertedNumberToCurrency(jackpotAmount: number): string {
    return this.currencyFormatPipe.transform(
      jackpotAmount.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1,"),
      this.currencySymbol,
      false
    );
  }

  // -----------------------------------------------------------------
  // Set Methods
  onAnimateCounter(): void {
    if (!this.duration) {
      this.duration = 1000;
    }

    if (typeof this.digit === "number") {
      this.onCounterFunc(this.digit, this.duration, this.animatedDigit);
    }
  }

  onCounterFunc(
    endValue: number,
    durationMs: number,
    element: ElementRef
  ): void {
    if (!this.steps) {
      this.steps = 12;
    }

    const stepCount: number = Math.abs(durationMs / this.steps);
    const valueIncrement: number = (endValue + 1 - 0) / stepCount;
    const sinValueIncrement: number = Math.PI / stepCount;

    let currentValue: number = 0;
    let currentSinValue: number = 0;

    const self = this;

    function step(): void {
      currentSinValue += sinValueIncrement;
      currentValue += valueIncrement * Math.sin(currentSinValue) ** 2 * 2;

      if (element)
        element.nativeElement.textContent = self.getConvertedNumberToCurrency(
          Math.abs(Math.floor(currentValue))
        );

      if (currentSinValue < Math.PI) {
        window.requestAnimationFrame(step);
      }
    }

    step();
  }
}
