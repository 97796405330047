/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./promo-race-counter.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../shared/components/countdown-timer/countdown-timer.component.ngfactory";
import * as i3 from "../../../shared/components/countdown-timer/countdown-timer.component";
import * as i4 from "../../services/cashback-promo.service";
import * as i5 from "../../../multi-languages/services/translation.service";
import * as i6 from "@ngx-translate/core";
import * as i7 from "@angular/common";
import * as i8 from "./promo-race-counter.component";
import * as i9 from "../../../user/services/user-details.service";
import * as i10 from "../../../shared/pipes/currency-format.pipe";
import * as i11 from "../../../shared/services/emitter.service";
import * as i12 from "@ngrx/store";
var styles_PromoRaceCounterComponent = [i0.styles];
var RenderType_PromoRaceCounterComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PromoRaceCounterComponent, data: {} });
export { RenderType_PromoRaceCounterComponent as RenderType_PromoRaceCounterComponent };
function View_PromoRaceCounterComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, [[1, 0], ["promoRaceCounter", 1]], null, 9, "div", [["class", "cb-countdown"], ["id", "promoRaceCounter"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["alt", "CashBack"], ["class", "cb-countdown--img"], ["src", "./assets/img/rewards/bonus-img/cashback.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 7, "span", [["class", "cb-countdown--timer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "app-countdown-timer", [], null, null, null, i2.View_CountdownTimerComponent_0, i2.RenderType_CountdownTimerComponent)), i1.ɵdid(4, 704512, null, 0, i3.CountdownTimerComponent, [i4.CashbackPromoService, i5.TranslationService], { expiryDate: [0, "expiryDate"], callingFrom: [1, "callingFrom"], promoRaceId: [2, "promoRaceId"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 2, "span", [["class", "cb-countdown--timer-txt"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), i1.ɵpid(131072, i6.TranslatePipe, [i6.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(8, 0, null, null, 1, "button", [["aria-label", "Info"], ["class", "btn btn-info"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, "i", [["class", "icon-info"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onOpenRewardTCPopUp() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.userOptinDuration; var currVal_1 = "promoCounter"; var currVal_2 = _co.campaignDetails.promoRaceId; _ck(_v, 4, 0, currVal_0, currVal_1, currVal_2); }, function (_ck, _v) { var currVal_3 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform("rewards.cashback")); _ck(_v, 6, 0, currVal_3); }); }
export function View_PromoRaceCounterComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { element: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PromoRaceCounterComponent_1)), i1.ɵdid(2, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isOptnCounter; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_PromoRaceCounterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-promo-race-counter", [], null, [[null, "touchstart"], ["window", "orientationchange"], ["window", "resize"]], function (_v, en, $event) { var ad = true; if (("touchstart" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onStart($event) !== false);
        ad = (pd_0 && ad);
    } if (("window:orientationchange" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).onRotate() !== false);
        ad = (pd_1 && ad);
    } if (("window:resize" === en)) {
        var pd_2 = (i1.ɵnov(_v, 1).onResize() !== false);
        ad = (pd_2 && ad);
    } return ad; }, View_PromoRaceCounterComponent_0, RenderType_PromoRaceCounterComponent)), i1.ɵdid(1, 245760, null, 0, i8.PromoRaceCounterComponent, [i4.CashbackPromoService, i9.UserDetailsService, i10.CurrencyFormatPipe, i5.TranslationService, i11.EmitterService, i12.Store, i1.Renderer2], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PromoRaceCounterComponentNgFactory = i1.ɵccf("app-promo-race-counter", i8.PromoRaceCounterComponent, View_PromoRaceCounterComponent_Host_0, {}, {}, []);
export { PromoRaceCounterComponentNgFactory as PromoRaceCounterComponentNgFactory };
