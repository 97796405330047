import { combineLatest, Observable, of, Subscription } from "rxjs";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import {
  SimpleChange,
  HostListener,
  Component,
  OnChanges,
  Input,
} from "@angular/core";

// Libraries
import * as _ from "underscore";

// Pipes
import { GamesFilterPipe } from "src/app/modules/shared/pipes/games-filter.pipe";

// Models
import { LobbyGameGroup } from "src/app/modules/game-groups/models/lobby/lobby-game-group.model";
import { LobbyPregmatic } from "src/app/modules/game-groups/models/lobby/lobby.model";
import { GameGroup } from "src/app/modules/game-groups/models/game-group.model";
import { GamePregmatic } from "src/app/modules/game-groups/models/game.model";

// Reducers
import { GamesState } from "src/app/modules/game-groups/store/reducers/games.reducer";
import { AppState } from "src/app/store/reducers";

// Selectors
import { selectLanguageCode } from "src/app/modules/multi-languages/store/selectors/languages.selectors";
import {
  selectAllLobbyPregmatic,
  selectAllGamesIsLoaded,
  selectLastPlayedState,
  selectAllGames,
} from "src/app/modules/game-groups/store/selectors/games.selectors";
import {
  selectAuthLoginIsLoggedOut,
  selectAuthLoginIsLoggedIn,
} from "src/app/modules/auth/store/selectors/auth.selectors";

// Services
import { MultiLanguageService } from "src/app/modules/multi-languages/services/multi-language.service";
import { TranslationService } from "src/app/modules/multi-languages/services/translation.service";
import { UtilityService } from "src/app/modules/shared/services/utility.service";
import { SessionService } from "src/app/modules/auth/services/session.service";

@Component({
  selector: "app-game-groups-display",
  templateUrl: "./game-groups-display.component.html",
  styleUrls: ["./game-groups-display.component.scss"],
})
export class GameGoupsDisplayComponent implements OnChanges {
  // Inputs
  @Input() lobby: string = "";

  // Numbers
  gameGroupSize: number = 3;

  // Strings
  languageCode: string = "";

  // Booleans
  isGamesLoading: boolean = false;
  isGamesLoaded: boolean = false;
  isLoggedIn: boolean = false;
  isLoading: boolean = true;

  // Enums
  windowType: "device" | "mobile" = "device";

  // Arrays
  filteredGameGroupsWithGamesList: LobbyGameGroup[] = [];
  lobbyGroupDataWithGamesList: LobbyGameGroup[] = [];
  listGameGroupLobbyList: LobbyPregmatic[] = [];
  allFavoriteGamesList: GamePregmatic[] = [];
  gameGroupsList: LobbyGameGroup[] = [];
  lastPlayedGamesList: number[] = [];
  gamesList: GamePregmatic[] = [];

  // Others
  activeGameGroupLobby: LobbyPregmatic;

  // Subscriptions
  subscriptions: Subscription[] = [];

  constructor(
    private multiLanguageService: MultiLanguageService,
    private translationService: TranslationService,
    private gamesFilterPipe: GamesFilterPipe,
    private sessionService: SessionService,
    public utilityService: UtilityService,
    private store: Store<AppState>,
    private router: Router
  ) {}

  // -----------------------------------------------------------------
  // Lifecycle Hooks
  ngOnInit(): void {
    this.getWindowType();

    this.languageCode = this.multiLanguageService.getLanguageCode();

    this.isLoggedIn = this.sessionService.getIsUserLoggedIn();

    this.subscriptions = [
      this.store
        .select(selectLanguageCode)
        .subscribe((languageCode: string) => {
          this.languageCode = languageCode;
        }),
      this.store
        .select(selectAuthLoginIsLoggedIn)
        .subscribe((isLoggedIn: boolean) => {
          this.isLoggedIn = isLoggedIn;

          this.onGetLobbyGroupsAndGames(false);
        }),
      this.store
        .select(selectAuthLoginIsLoggedOut)
        .subscribe((isLoggedOut: boolean) => {
          if (isLoggedOut) {
            this.isLoggedIn = false;

            this.onGetLobbyGroupsAndGames(false);
          }
        }),
      this.store
        .select(selectAllGamesIsLoaded)
        .subscribe((gamesLoaded: GamesState) => {
          this.isGamesLoading = gamesLoaded.isLoading;

          this.isGamesLoaded = gamesLoaded.isLoaded;
        }),
    ];
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
    if (
      changes["lobby"] &&
      changes["lobby"].previousValue !== changes["lobby"].currentValue
    ) {
      this.gameGroupsList = undefined;

      this.gamesList = undefined;

      this.lobbyGroupDataWithGamesList = undefined;

      this.lobby = changes["lobby"].currentValue;

      this.onGetLobbyGroupsAndGames(false);
    }
  }

  // -----------------------------------------------------------------
  // Host Listeners
  @HostListener("window:resize") onResize(): void {
    this.getWindowType();
  }

  @HostListener("window:scroll") onWindowScroll(): void {
    if (
      this.lobbyGroupDataWithGamesList &&
      this.lobbyGroupDataWithGamesList.length > 0 &&
      this.filteredGameGroupsWithGamesList &&
      this.filteredGameGroupsWithGamesList.length <
        this.lobbyGroupDataWithGamesList.length
    ) {
      this.onScroll();
    }
  }

  // -----------------------------------------------------------------
  // Window Type
  getWindowType(): void {
    let clientWidth: number = document.body.clientWidth;

    if (clientWidth <= 767) {
      this.windowType = "mobile";
    } else {
      this.windowType = "device";
    }
  }

  // -----------------------------------------------------------------
  // Get Methods
  getProcessGameGroupGames(
    lobbyGameGroupList: LobbyGameGroup[],
    games: GamePregmatic[]
  ): LobbyGameGroup[] {
    if (lobbyGameGroupList && games) {
      _.each(
        lobbyGameGroupList,
        (lobbyGameGroup: LobbyGameGroup, indexLobby: number) => {
          if (lobbyGameGroup.group_type === "manual") {
            _.each(games, (game: GamePregmatic) => {
              let gameGroupList: GameGroup[] =
                game.gameGroupList as GameGroup[];

              if (game.gameGroupList && gameGroupList.length > 0) {
                _.each(
                  gameGroupList,
                  (gameGroup: GameGroup, indexGameGroup: number) => {
                    if (gameGroup.id === lobbyGameGroup.id) {
                      if (lobbyGameGroupList[indexLobby].games) {
                        let clonedGame: GamePregmatic = _.clone(game);

                        clonedGame.gameGroupList =
                          game.gameGroupList[indexGameGroup];

                        lobbyGameGroupList[indexLobby].games.push(clonedGame);
                      } else {
                        let clonedGame: GamePregmatic = _.clone(game);

                        clonedGame.gameGroupList =
                          game.gameGroupList[indexGameGroup];

                        lobbyGameGroupList[indexLobby].games = [];

                        lobbyGameGroupList[indexLobby].games.push(clonedGame);
                      }
                    }
                  }
                );
              }
            });
          } else if (
            lobbyGameGroup.group_type === "automatic" &&
            lobbyGameGroup.group_sub_type === "continue_playing"
          ) {
            if (
              this.isLoggedIn &&
              this.lastPlayedGamesList &&
              this.lastPlayedGamesList.length > 0
            ) {
              _.each(this.lastPlayedGamesList, (typeId: number) => {
                let game: GamePregmatic = _.findWhere(games, {
                  beGameTypeId: typeId,
                });

                if (game && lobbyGameGroupList[indexLobby].games) {
                  lobbyGameGroupList[indexLobby].games.push(game);
                } else if (game) {
                  lobbyGameGroupList[indexLobby].games = [];

                  lobbyGameGroupList[indexLobby].games.push(game);
                }
              });

              lobbyGameGroupList[indexLobby].games =
                this.gamesFilterPipe.transform(
                  lobbyGameGroupList[indexLobby].games,
                  {
                    typeOfGames: "non-live-game",
                  }
                );
            } else {
              lobbyGameGroupList[indexLobby].games = [];
            }
          } else if (
            lobbyGameGroup.group_type === "automatic" &&
            lobbyGameGroup.group_sub_type === "favourite"
          ) {
            if (
              this.isLoggedIn &&
              this.allFavoriteGamesList &&
              this.allFavoriteGamesList.length > 0
            ) {
              _.each(
                this.allFavoriteGamesList,
                (favoriteGame: GamePregmatic) => {
                  let game: GamePregmatic = _.findWhere(games, {
                    beGameTypeId: favoriteGame.gameTypeId,
                  });

                  if (game && lobbyGameGroupList[indexLobby].games) {
                    lobbyGameGroupList[indexLobby].games.push(game);
                  } else if (game) {
                    lobbyGameGroupList[indexLobby].games = [];
                    lobbyGameGroupList[indexLobby].games.push(game);
                  }
                }
              );
            } else {
              lobbyGameGroupList[indexLobby].games = [];
            }
          }
        }
      );
    }

    return lobbyGameGroupList;
  }

  // -----------------------------------------------------------------
  // Set Methods
  onGetLobbyGroupsAndGames(isForce?: boolean): void {
    this.isLoading = true;

    let lastPlayedGamesApi: Observable<number[]>;

    if (this.isLoggedIn) {
      lastPlayedGamesApi = this.store.select(selectLastPlayedState);
    }

    combineLatest([
      this.store.select(selectAllLobbyPregmatic),
      this.store.select(selectAllGames),
      lastPlayedGamesApi ? lastPlayedGamesApi : of(null),
    ]).subscribe(
      ([lobbyGameGroupsResponse, gamesResponse, lastPlayedResponse]) => {
        let lobbyGameGroups: LobbyPregmatic[] = lobbyGameGroupsResponse;

        let games: GamePregmatic[] = gamesResponse;

        lobbyGameGroups = JSON.parse(JSON.stringify(lobbyGameGroups));

        if (lastPlayedResponse) {
          let lastPlayed: number[] = lastPlayedResponse;

          this.lastPlayedGamesList = _.clone(lastPlayed);
        }

        if (lobbyGameGroups && lobbyGameGroups.length > 0) {
          this.gameGroupsList =
            this.utilityService.getFilterLobbyListByLobbyName(
              _.clone(lobbyGameGroups),
              this.lobby
            );

          this.onUpdateMetaTagBasedOnLobby(lobbyGameGroups, this.lobby);
        }

        if (
          !_.isEmpty(this.gameGroupsList) &&
          this.lobby &&
          games &&
          games.length > 0
        ) {
          this.gamesList = games;

          this.lobbyGroupDataWithGamesList = this.getProcessGameGroupGames(
            this.gameGroupsList,
            this.gamesList
          );

          this.onInitializeLazyLoading();
        } else {
          this.lobbyGroupDataWithGamesList = [];

          this.filteredGameGroupsWithGamesList = [];
        }
        this.isLoading = false;
      }
    );
  }

  onNavigateToAllGames(providerName: string): void {
    this.router.navigate([
      `${this.languageCode}/${this.translationService.get(
        `url.${this.lobby}`
      )}/${providerName}`,
    ]);
  }

  onUpdateMetaTagBasedOnLobby(
    lobbyGameGroup: LobbyPregmatic[],
    activeLobby: string
  ): void {
    this.listGameGroupLobbyList = lobbyGameGroup.filter(
      (lobbyPregmatic: LobbyPregmatic) => {
        return lobbyPregmatic.name.toLowerCase() === activeLobby.toLowerCase();
      }
    );

    if (this.listGameGroupLobbyList && this.listGameGroupLobbyList.length) {
      this.activeGameGroupLobby = this.listGameGroupLobbyList[0];
    }
  }

  /*
    Below Logic is used to lazy load game group's under a selected lobby
    Flow will be like this we load only three game groups first on lading casino page
    & based on user scroll
  
    We calculate window scroll height & inner height If this both exceeds
    Scroll height-footer height we next 3 game group on to Dom, In this way we
    achive lazy loading.
  */
  onInitializeLazyLoading(): void {
    this.gameGroupSize = 3;

    if (
      this.lobbyGroupDataWithGamesList &&
      this.lobbyGroupDataWithGamesList.length > 0
    ) {
      this.filteredGameGroupsWithGamesList =
        this.lobbyGroupDataWithGamesList.slice(0, this.gameGroupSize);
    }
  }

  onScroll(): void {
    let metaContentHeight: number = 0;

    let footerHeight: number = this.utilityService.getFooterHeight();

    const metaContentDom: HTMLCollectionOf<Element> =
      document.getElementsByClassName("meta-content");

    if (metaContentDom && metaContentDom.length > 0) {
      metaContentHeight = (metaContentDom[0] as HTMLElement).offsetHeight;
    }

    if (
      window.scrollY + window.innerHeight >=
      document.body.scrollHeight - (footerHeight + metaContentHeight + 250)
    ) {
      this.gameGroupSize = this.gameGroupSize + 3;

      this.filteredGameGroupsWithGamesList =
        this.lobbyGroupDataWithGamesList.slice(0, this.gameGroupSize);
    }
  }

  // -----------------------------------------------------------------
  // On Destroy
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) =>
      subscription.unsubscribe()
    );
  }
}
