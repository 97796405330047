import { catchError, map } from "rxjs/operators";
import { throwError } from "rxjs";
// API Interactors
import { PaymentInteractors } from "src/app/models/interactors/payment.interactor";
// Libraries
import * as _ from "underscore";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class PaymentService {
    constructor(httpClient) {
        this.httpClient = httpClient;
        this.paymentInteractor = new PaymentInteractors(this.httpClient);
    }
    // -----------------------------------------------------------------
    // Get Methods - Observables Pending Withdrawals
    onPendingWithdrawals(pendingWithdrawalsRequest) {
        let merchantId = pendingWithdrawalsRequest.merchantId;
        let userId = pendingWithdrawalsRequest.userId;
        delete pendingWithdrawalsRequest.merchantId;
        delete pendingWithdrawalsRequest.userId;
        return this.paymentInteractor
            .get(`/api/user/transaction/${merchantId}/${userId}`, pendingWithdrawalsRequest)
            .pipe(map((pendingWithdrawalsResponse) => {
            return pendingWithdrawalsResponse;
        }), catchError(() => {
            return throwError(`Unable to get pending withdrawals`);
        }));
    }
    // -----------------------------------------------------------------
    // Get Methods - Observables User used PIQ Accounts
    onGetUserUsedPIQAccounts(userPIQAccountRequest) {
        if (!userPIQAccountRequest ||
            _.isEmpty(userPIQAccountRequest) ||
            !userPIQAccountRequest.merchantId ||
            !userPIQAccountRequest.userId ||
            !userPIQAccountRequest.sessionId) {
            return throwError("RequiredDetails Not available");
        }
        let merchantId = userPIQAccountRequest.merchantId;
        let userId = userPIQAccountRequest.userId;
        delete userPIQAccountRequest.merchantId;
        delete userPIQAccountRequest.userId;
        return this.paymentInteractor
            .get(`/api/user/account/${merchantId}/${userId}`, userPIQAccountRequest)
            .pipe(map((userPIQAccountResponse) => {
            return userPIQAccountResponse;
        }), catchError((error) => {
            return throwError(error);
        }));
    }
    // -----------------------------------------------------------------
    // Get Methods - Observables User PIQ Methods
    onGetUserPIQMethods(userPaymentMethodsRequest) {
        if (!userPaymentMethodsRequest ||
            _.isEmpty(userPaymentMethodsRequest) ||
            !userPaymentMethodsRequest.merchantId ||
            !userPaymentMethodsRequest.userId ||
            !userPaymentMethodsRequest.sessionId) {
            return throwError("RequiredDetails Not available");
        }
        let merchantId = userPaymentMethodsRequest.merchantId;
        let userId = userPaymentMethodsRequest.userId;
        delete userPaymentMethodsRequest.merchantId;
        delete userPaymentMethodsRequest.userId;
        return this.paymentInteractor
            .get(`/api/user/payment/method/${merchantId}/${userId}`, userPaymentMethodsRequest)
            .pipe(map((userPaymentMethodsResponse) => {
            return userPaymentMethodsResponse;
        }), catchError((error) => {
            return throwError(error);
        }));
    }
    // -----------------------------------------------------------------
    // Get Methods - Observables PIQ Transaction Status
    onGetPIQTransactionStatus(piqTransactionStatusRequest) {
        let merchantId = piqTransactionStatusRequest.merchantId;
        let userId = piqTransactionStatusRequest.userId;
        let transactionId = piqTransactionStatusRequest.transactionId;
        delete piqTransactionStatusRequest.merchantId;
        delete piqTransactionStatusRequest.userId;
        delete piqTransactionStatusRequest.transactionId;
        return this.paymentInteractor
            .get(`/api/user/transaction/${merchantId}/${userId}/${transactionId}/status`, piqTransactionStatusRequest)
            .pipe(map((piqTransactionStatusResponse) => {
            return piqTransactionStatusResponse;
        }), catchError(() => {
            return throwError("something went wrong in fetch piq transaction status");
        }));
    }
    // -----------------------------------------------------------------
    // Get Methods - Observables Transaction History
    onCancelTransaction(cancelTransactionRequest) {
        let merchantId = cancelTransactionRequest.merchantId;
        let userId = cancelTransactionRequest.userId;
        let transactionId = cancelTransactionRequest.transactionId;
        delete cancelTransactionRequest.merchantId;
        delete cancelTransactionRequest.userId;
        delete cancelTransactionRequest.transactionId;
        return this.paymentInteractor
            .delete(`/api/user/transaction/${merchantId}/${userId}/${transactionId}`, cancelTransactionRequest)
            .pipe(map((cancelTransactionResponse) => {
            return cancelTransactionResponse;
        }), catchError(() => {
            return throwError(`Unable to cancel transaction`);
        }));
    }
    // -----------------------------------------------------------------
    // Get Methods - Observables Make Payment
    onMakePayment(processPaymentRequest) {
        let provider = processPaymentRequest.provider;
        let paymentMethod = processPaymentRequest.paymentMethod;
        let methodType = processPaymentRequest.methodType;
        delete processPaymentRequest.provider;
        delete processPaymentRequest.paymentMethod;
        delete processPaymentRequest.methodType;
        return this.paymentInteractor
            .post(`/api/${provider}/${paymentMethod}/${methodType}`, processPaymentRequest)
            .pipe(map((processPaymentResponse) => {
            return processPaymentResponse;
        }), catchError(() => {
            return throwError(`payment Reqiest failed for provider-{{paymentRequestObj.providerType}} & method-{{ paymentRequestObject.paymentMethod}}`);
        }));
    }
    // -----------------------------------------------------------------
    // Get Methods - Observables Delete Payment Method
    onDeletePaymentMethod(deletePaymentMethodRequest) {
        let merchantId = deletePaymentMethodRequest.merchantId;
        let userId = deletePaymentMethodRequest.userId;
        let accountId = deletePaymentMethodRequest.accountId;
        delete deletePaymentMethodRequest.merchantId;
        delete deletePaymentMethodRequest.userId;
        delete deletePaymentMethodRequest.accountId;
        return this.paymentInteractor
            .delete(`/api/user/account/${merchantId}/${userId}/${accountId}`, deletePaymentMethodRequest)
            .pipe(map((deletePaymentMethodResponse) => {
            return deletePaymentMethodResponse;
        }), catchError(() => {
            return throwError(`Unable to delete the payment Method`);
        }));
    }
}
PaymentService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PaymentService_Factory() { return new PaymentService(i0.ɵɵinject(i1.HttpClient)); }, token: PaymentService, providedIn: "root" });
