import { createFeatureSelector, createSelector } from "@ngrx/store";
// Reducers
import { AuthStateFeatureKey, } from "src/app/modules/auth/store/reducers/auth.reducer";
// Select the Main State
const selectAuthReducerState = createFeatureSelector(AuthStateFeatureKey);
const ɵ0 = (state) => {
    return {
        loggedIn: state.loggedIn,
        isLoaded: state.isLoaded,
        isLoading: state.isLoading,
    };
};
// Login
export const selectAuthLoggedIn = createSelector(selectAuthReducerState, ɵ0);
const ɵ1 = (state) => {
    return {
        payNPlayLoginResponse: state.payNPlayLoggedIn,
        isLoaded: state.isLoaded,
        isLoading: state.isLoading,
    };
};
// Pay N Play Login
export const selectAuthPayNPlayLoggedIn = createSelector(selectAuthReducerState, ɵ1);
const ɵ2 = (state) => {
    return {
        loggedIn: state.logout,
        isLoaded: state.isLoadedLogout,
    };
};
// Logout
export const selectAuthLogout = createSelector(selectAuthReducerState, ɵ2);
const ɵ3 = (state) => {
    return {
        isLoaded: state.isLoaded,
        isLoading: state.isLoading
    };
};
export const selectAuthLoaded = createSelector(selectAuthReducerState, ɵ3);
const ɵ4 = (state) => state.isLoading;
export const selectAuthLoading = createSelector(selectAuthReducerState, ɵ4);
const ɵ5 = (state) => state.isLoggedIn;
// Login Is Logged In
export const selectAuthLoginIsLoggedIn = createSelector(selectAuthReducerState, ɵ5);
const ɵ6 = (state) => state.isLoggedOut;
export const selectAuthLoginIsLoggedOut = createSelector(selectAuthReducerState, ɵ6);
const ɵ7 = (state) => {
    return {
        isLoading: state.isLoadingLoginStatus,
        isLoaded: state.isLoadedLoginStatus,
        loginStatus: state.loginStatus,
    };
};
// Login Status
export const selectAuthLoginStatus = createSelector(selectAuthReducerState, ɵ7);
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7 };
