import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { NgModule } from "@angular/core";

// Components
import { UpdateProfileComponent } from "src/app/modules/user/components/update-profile/update-profile.component";

// Effects
import { ProfileEffects } from "src/app/modules/user/store/effects/profile.effects";
import { UserEffects } from "src/app/modules/user/store/effects/user.effects";

// Modules
import { SharedModule } from "src/app/modules/shared/shared.module";

// Reducers
import * as fromProfileReducer from "src/app/modules/user/store/reducers/profile.reducer";
import * as fromUserReducer from "src/app/modules/user/store/reducers/user.reducer";

@NgModule({
  declarations: [UpdateProfileComponent],
  imports: [
    ReactiveFormsModule,
    CommonModule,
    SharedModule,
    FormsModule,
    EffectsModule.forFeature([ProfileEffects, UserEffects]),
    StoreModule.forFeature(
      fromProfileReducer.ProfileStateFeatureKey,
      fromProfileReducer.reducer
    ),
    StoreModule.forFeature(
      fromUserReducer.UserStateFeatureKey,
      fromUserReducer.reducer
    ),
  ],
  exports: [UpdateProfileComponent],
})
export class UserModule {}
