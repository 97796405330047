import { Component, HostListener } from "@angular/core";
import { Subscription } from "rxjs";
import { Store } from "@ngrx/store";
import {
  UrlSegmentGroup,
  PRIMARY_OUTLET,
  NavigationEnd,
  RouterEvent,
  UrlSegment,
  UrlTree,
  Router,
} from "@angular/router";

// Actions
import { userRequested } from "src/app/modules/user/store/actions/user.actions";

// Constants
import { UserFlowTypes } from "src/app/constants/costants";

// Models
import { MenuConfigurationType } from "src/app/models/configurations/menu/menu-configuration-type.model";
import { NavigationRoute } from "src/app/modules/shared/models/navigation/navigation.model";
import { ActiveTab } from "src/app/modules/shared/models/active-tab.model";

// Reducers
import { AppState } from "src/app/store/reducers";

// Selectors
import { selectLanguageCode } from "src/app/modules/multi-languages/store/selectors/languages.selectors";
import { selectAuthUserDataLoaded } from "src/app/modules/user/store/selectors/user.selectors";
import {
  selectAuthLoginIsLoggedOut,
  selectAuthLoginIsLoggedIn,
} from "src/app/modules/auth/store/selectors/auth.selectors";

// Services
import { MultiLanguageService } from "src/app/modules/multi-languages/services/multi-language.service";
import { TranslationService } from "src/app/modules/multi-languages/services/translation.service";
import { RegistrationService } from "src/app/modules/registration/services/registration.service";
import { GamePlayService } from "src/app/modules/game-groups/services/game-play.service";
import { UserDetailsService } from "src/app/modules/user/services/user-details.service";
import { LiveChatService } from "src/app/modules/chat/services/live-chat.service";
import { RewardsService } from "src/app/modules/rewards/services/reward.service";
import { UtilityService } from "src/app/modules/shared/services/utility.service";
import { CommonService } from "src/app/modules/shared/services/common.service";
import { SessionService } from "src/app/modules/auth/services/session.service";
import { ProfileService } from "src/app/modules/user/services/profile.service";

@Component({
  selector: "app-left-navigation",
  templateUrl: "./left-navigation.component.html",
  styleUrls: ["./left-navigation.component.scss"],
})
export class LeftNavigationComponent {
  // Numbers
  levelUpNotificationCount: number = 0;
  userTotalBalance: number = 0;

  // Strings
  userJurisdiction: string = "";
  activeLeftMenu: string = "";
  currencySymbol: string = "";
  languageCode: string = "";
  countryCode: string = "";
  activePage: string = "";
  username: string = "";

  // Booleans
  isHungarianStyle: boolean = false;
  isBalanceLoading: boolean = true;
  isLoggedIn: boolean = false;
  isLoading: boolean = false;

  // Enums
  UserFlowTypes = UserFlowTypes;

  // To Discover
  menuConfigurationType: MenuConfigurationType;

  // Subscriptions
  subscriptions: Subscription[] = [];

  constructor(
    private multiLanguageService: MultiLanguageService,
    private registrationService: RegistrationService,
    private userDetailsService: UserDetailsService,
    private translationService: TranslationService,
    private liveChatService: LiveChatService,
    private gamePlayService: GamePlayService,
    private rewardsService: RewardsService,
    private utilityService: UtilityService,
    private profileService: ProfileService,
    private sessionService: SessionService,
    private commonService: CommonService,
    private store: Store<AppState>,
    private router: Router
  ) {}

  // -----------------------------------------------------------------
  // Lifecycle Hooks
  ngOnInit(): void {
    if (this.commonService.getCountryCode()) {
      this.countryCode = this.commonService.getCountryCode();
    }

    if (this.userDetailsService.getUserTotalBalance()) {
      this.userTotalBalance = this.userDetailsService.getUserTotalBalance();

      this.isBalanceLoading = false;
    }

    this.onGetLobbyMenuList();

    this.onSetGameActivePage();

    this.isLoggedIn = this.sessionService.getIsUserLoggedIn();

    this.languageCode = this.multiLanguageService.getLanguageCode();

    if (this.languageCode === "hu-hu") {
      this.isHungarianStyle = true;
    }

    this.userJurisdiction = this.profileService.getUserJurisdiction();

    this.subscriptions = [
      this.commonService.updateCountryCode$.subscribe((countryCode: string) => {
        this.countryCode = countryCode;
      }),
      /*
        Condition to hide banner where ever it not required
        comes here...
      */
      this.router.events.subscribe((event: RouterEvent) => {
        if (event instanceof NavigationEnd) {
          const tree: UrlTree = this.router.parseUrl(event.url);

          const urlSegmentGroup: UrlSegmentGroup =
            tree.root.children[PRIMARY_OUTLET];

          const urlSegment: UrlSegment[] = urlSegmentGroup
            ? urlSegmentGroup.segments
            : [];

          if (urlSegment && urlSegment[1] && urlSegment[1].path) {
            this.activePage = this.multiLanguageService.getActiveLobby(
              urlSegment[1].path
            );
          } else {
            this.activePage = "";
          }
        }
      }),
      this.store
        .select(selectLanguageCode)
        .subscribe((languageCode: string) => {
          this.languageCode = languageCode;

          if (this.languageCode === "hu-hu") {
            this.isHungarianStyle = true;
          }
        }),
      this.store
        .select(selectAuthLoginIsLoggedIn)
        .subscribe((isLoggedIn: boolean) => {
          this.isLoggedIn = isLoggedIn;
        }),
      this.store
        .select(selectAuthLoginIsLoggedOut)
        .subscribe((isLoggedOut: boolean) => {
          if (isLoggedOut) {
            this.isLoggedIn = false;

            this.userTotalBalance = 0;

            this.currencySymbol = undefined;

            this.isBalanceLoading = true;
          }
        }),
      this.store
        .select(selectAuthUserDataLoaded)
        .subscribe(({ userData, isLoaded }) => {
          if (isLoaded) {
            if (userData) {
              this.username = userData.firstName;
            }
          } else {
            if (this.isLoggedIn) {
              this.store.dispatch(userRequested());
            }
          }
        }),
      /*
        Here we are using BehaviorSubject on initialization also v'll get default value..
        we differentiate subject & BehaviorSubject with "Sb" at end of Event Name.
      */
      this.userDetailsService.currencySymbolBehaviourSubject$.subscribe(
        (currencySymbol: string) => {
          this.currencySymbol = currencySymbol;
        }
      ),
      this.userDetailsService.userBalanceUpdatedSubject$.subscribe(
        (balanceUpdateMessage: string) => {
          if (balanceUpdateMessage == "UserBalanceUpdated") {
            this.userTotalBalance =
              this.userDetailsService.getUserTotalBalance();

            this.isBalanceLoading = false;
          }
        }
      ),
      this.commonService.activeLeftMenuSubject$.subscribe(
        (activeLeftMenu: string) => {
          this.activeLeftMenu = activeLeftMenu;
        }
      ),
      this.rewardsService.updateLevelUpNotificationSubject$.subscribe(
        (levelUpNotificationCount: number) => {
          this.levelUpNotificationCount = levelUpNotificationCount;
        }
      ),
      this.rewardsService.isClearLevelUpNotificationSubject$.subscribe(
        (isClearLevelUpNotification: boolean) => {
          if (isClearLevelUpNotification) {
            this.levelUpNotificationCount = 0;
          }
        }
      ),
      this.profileService.userJurisdictionSubject$.subscribe(
        (userJurisdiction: string) => {
          this.userJurisdiction = userJurisdiction;
        }
      ),
    ];
  }

  // -----------------------------------------------------------------
  // Host Listeners
  @HostListener("window:resize") onResize(): void {
    this.onSetGameActivePage();
  }

  // -----------------------------------------------------------------
  // Set Methods
  onSetGameActivePage(): void {
    const pathURL: string = this.utilityService
      .getDecodedCurrentPath()
      .split("/")[2];

    if (pathURL === this.translationService.get("url.game")) {
      this.activePage = this.multiLanguageService.getActiveLobby(pathURL);
    }
  }

  onOpenAccountMenuList(): void {
    const activeTab: ActiveTab = {
      tabName: "menuOptions",
      showBackButton: true,
    };

    this.commonService.onBroadcastActiveAcountView(activeTab);
  }

  onInitiateDeposit(): void {
    this.utilityService.initiateDeposit(false);
  }

  /*
    All the left menu navigation all handled in below
    callback function
  */
  onNavigateTo(path?: string): void {
    if (this.commonService.getActiveAccountMenu()) {
      const activeTab: ActiveTab = {
        tabName: "",
        showBackButton: true,
      };

      this.commonService.onBroadcastActiveAcountView(activeTab);
    }

    this.utilityService.updateActiveLeftMenu();

    if (path === "livespins") {
      if (this.sessionService.getIsUserLoggedIn()) {
        this.router.navigate([`${this.languageCode}/livespins`]);
      } else {
        let navigationData: NavigationRoute = {
          type: "url",
          data: {
            navigateTo: "livespins",
            path: path,
          },
        };

        this.commonService.onSetNavigateAfterLogin(navigationData);

        const activeTab: ActiveTab = {
          tabName: "login",
          showBackButton: true,
        };

        this.commonService.onBroadcastActiveAcountView(activeTab);
      }
    } else if (path === "casino" && this.activePage === "game") {
      this.gamePlayService.onBroadCastGameWindowMinimized(true);

      this.gamePlayService.onClearRegistrationTimer();

      this.router.navigate([`${this.languageCode}/casino`]);
    } else if (path === "rewards") {
      this.rewardsService.onBroadcastLevelUpNotifications(true);

      const activeTab: ActiveTab = {
        tabName: path,
        showBackButton: true,
      };

      this.commonService.onBroadcastActiveAcountView(activeTab);
    } else if (path === "login") {
      this.utilityService.initiateLogin();
    } else if (path) {
      this.router.navigate([
        `${this.languageCode}/${this.translationService.get(`url.${path}`)}`,
      ]);
    } else {
      if (
        !this.sessionService.getIsUserLoggedIn() &&
        !this.utilityService.isPnpFlow()
      ) {
        this.router.navigate([this.languageCode]);
      } else {
        this.commonService.onBroadcastNavigationData({ lobby: "casino" });

        this.router.navigate([`${this.languageCode}/casino`]);
      }

      this.gamePlayService.onClearRegistrationTimer();
    }

    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  onOpenRegistrationPopup(): void {
    this.registrationService.onOpenRegistration();
  }

  /*
    Below function helps us to get left menu navigation options
    If we want to add any new navigation link into left menu simply
    we have to first configure under /configurations/menulist.config/caf.json
  */
  onGetLobbyMenuList(): void {
    this.menuConfigurationType =
      this.utilityService.getBrandMenuConfigurationType("leftMenuConfig");
  }

  onInitializeLiveChat(): void {
    this.liveChatService.onInitializeChat();
  }

  // -----------------------------------------------------------------
  // On Destroy
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) =>
      subscription.unsubscribe()
    );
  }
}
