import { Subject } from "rxjs";
import { OnDestroy } from "@angular/core";
// API Interactors
import { ApiInteractors } from "src/app/models/interactors/api.interactor";
// Configurations
import { launchGameURlBasedOnSubProvidersPrefixConfigurations, launchGameURLBasedOnMarketConfigurations, } from "src/app/configurations/main.configurations";
// Environments
import { environment } from "src/environments/environment";
// Libraries
import * as _ from "underscore";
import * as $ from "jquery";
// Selectors
import { selectLanguageCode } from "src/app/modules/multi-languages/store/selectors/languages.selectors";
import { selectAuthLoginIsLoggedOut, selectAuthLoginIsLoggedIn, } from "src/app/modules/auth/store/selectors/auth.selectors";
// Utilities
import { purpleTheme } from "src/app/modules/multi-languages/utilities/theme.utilities";
import * as i0 from "@angular/core";
import * as i1 from "../../registration/services/registration.service";
import * as i2 from "../../multi-languages/services/translation.service";
import * as i3 from "../../shared/services/utility.service";
import * as i4 from "../../auth/services/session.service";
import * as i5 from "../../shared/services/common.service";
import * as i6 from "@angular/common/http";
import * as i7 from "@ngrx/store";
import * as i8 from "@angular/router";
export class GamePlayService {
    constructor(registrationService, translationService, utilityService, sessionService, commonService, httpClient, store, router) {
        this.registrationService = registrationService;
        this.translationService = translationService;
        this.utilityService = utilityService;
        this.sessionService = sessionService;
        this.commonService = commonService;
        this.httpClient = httpClient;
        this.store = store;
        this.router = router;
        // Numbers
        this.registrationPopUpTimePeriodInSeconds = 90;
        // Strings
        this.languageCode = environment.defaultLanguage;
        this.gameCalledFrom = "";
        this.gameVendorCode = "";
        // Booleans
        this.isGamePlayWindowLaunched = false;
        this.isEventListenerAdded = false;
        this.isFavouriteGame = false;
        this.isGameMinimized = false;
        this.isGameLoading = false;
        this.isLoggedIn = false;
        // --------------------------------------------------------
        // Subject - Is Game Window Launched
        this.gameLaunchedSubject = new Subject();
        this.gameLaunchedSubject$ = this.gameLaunchedSubject.asObservable();
        // --------------------------------------------------------
        // Subject - is Game Window Minimized
        this.isGameWindowMinimizedSubject = new Subject();
        this.isGameWindowMinimizedSubject$ = this.isGameWindowMinimizedSubject.asObservable();
        // --------------------------------------------------------
        // Subject - Update Game Favourite Status
        this.updateIsFavouriteGameSubject = new Subject();
        this.updateIsFavouriteGameSubject$ = this.updateIsFavouriteGameSubject.asObservable();
        // --------------------------------------------------------
        // Subject - Reloaunch Game
        this.isRelaunchGameSubject = new Subject();
        this.isRelaunchGameSubject$ = this.isRelaunchGameSubject.asObservable();
        // --------------------------------------------------------
        // Subscriptions
        this.subscriptions = [];
        this.apiInteractor = new ApiInteractors(this.httpClient);
        this.isLoggedIn = this.sessionService.getIsUserLoggedIn();
        this.subscriptions = [
            this.store
                .select(selectAuthLoginIsLoggedIn)
                .subscribe((isLoggedIn) => (this.isLoggedIn = isLoggedIn)),
            this.store
                .select(selectAuthLoginIsLoggedOut)
                .subscribe((isLoggedOut) => {
                if (isLoggedOut) {
                    this.isLoggedIn = false;
                }
            }),
            this.store
                .select(selectLanguageCode)
                .subscribe((languageCode) => {
                this.languageCode = languageCode;
            }),
            this.commonService.isGameWindowRegilyPopUpTimerUpdateSubject$.subscribe(() => {
                if (!this.isLoggedIn && !this.utilityService.isPnpFlow()) {
                    this.onUpdateRegilyTimer();
                }
            }),
        ];
    }
    // -------------------------------------------------------------------//
    // Get Methods
    getDeviceType() {
        if (document.body.clientWidth >= 1024) {
            return "desktop";
        }
        else {
            return "mobile";
        }
    }
    getCurrentGamePregmatic() {
        return this.gamePregmatic;
    }
    getIsGameWindowMinimized() {
        return this.isGameMinimized;
    }
    getIsFavouriteGame() {
        return this.isFavouriteGame;
    }
    getGameCalledFrom() {
        return this.gameCalledFrom;
    }
    getPlayNGoGameDetails(response) {
        if (response && response.url.includes("playngonetwork")) {
            let responseUrl = response.url;
            responseUrl = responseUrl
                .replace("lobby", "origin")
                .concat("&embedmode=iframe");
            if (!responseUrl.includes("origin")) {
                responseUrl = responseUrl.concat(`&origin=${window.location.origin}`);
            }
            response.url = responseUrl;
            return response;
        }
        else {
            return response;
        }
    }
    getDeviceWidthHeight() {
        let clientWidth = document.body.clientWidth;
        let footerNaviagtionHeight = 0;
        let height = 0;
        let width = 0;
        if (document.getElementById("bottomNavigation")) {
            footerNaviagtionHeight =
                document.getElementById("bottomNavigation").offsetHeight;
        }
        if (clientWidth <= 1024 &&
            window.matchMedia("(orientation: portrait)").matches) {
            width = window.innerWidth;
            height = window.innerHeight - footerNaviagtionHeight;
        }
        else if (clientWidth <= 1024 &&
            window.matchMedia("(orientation: landscape)").matches &&
            this.isLoggedIn) {
            width = window.innerWidth - footerNaviagtionHeight;
            height = window.innerHeight;
        }
        else {
            width = $("#gameIframeWrapper").outerWidth();
            height = $("#gameIframeWrapper").outerHeight();
        }
        return { width, height };
    }
    getGameVendorCode() {
        return this.gameVendorCode;
    }
    getGameLanguageURL(responseURL) {
        if (this.gameVendorCode) {
            let gameURL = responseURL;
            let setLanguageParam;
            let languageCodeURL = this.getGameLaunchURLLocale();
            if (this.gameVendorCode !== "playngo" &&
                responseURL &&
                !responseURL.includes("playngonetwork")) {
                gameURL = decodeURIComponent(responseURL);
            }
            gameURL = new URL(gameURL);
            if (gameURL.search && gameURL.search.includes("language")) {
                setLanguageParam = gameURL.searchParams.set("language", languageCodeURL);
            }
            else if (gameURL.search && gameURL.search.includes("langIso")) {
                setLanguageParam = gameURL.searchParams.set("langIso", languageCodeURL);
            }
            else if (gameURL.search && gameURL.search.includes("lang")) {
                setLanguageParam = gameURL.searchParams.set("lang", languageCodeURL);
                if (this.gameVendorCode === "pushgaming" &&
                    gameURL.search.includes("country")) {
                    if (this.languageCode === "pt-br") {
                        setLanguageParam = gameURL.searchParams.set("country", "BR");
                    }
                    else {
                        setLanguageParam = gameURL.searchParams.set("country", "ES");
                    }
                }
            }
            else if (this.gameVendorCode.toLowerCase().startsWith("mgs_") &&
                gameURL.search &&
                gameURL.search.includes("ul")) {
                setLanguageParam = gameURL.searchParams.set("ul", languageCodeURL);
            }
            gameURL.search = gameURL.searchParams.toString();
            if (this.gameVendorCode === "playngo" &&
                responseURL &&
                responseURL.includes("playngonetwork")) {
                gameURL = gameURL.toString();
            }
            else {
                gameURL = decodeURIComponent(gameURL.toString());
            }
            return gameURL;
        }
    }
    getGameLaunchURLLocale() {
        let launchGameLocaleURL = "";
        if (this.languageCode &&
            launchGameURLBasedOnMarketConfigurations &&
            launchGameURLBasedOnMarketConfigurations.hasOwnProperty(this.languageCode) &&
            launchGameURLBasedOnMarketConfigurations[this.languageCode][this.gameVendorCode]) {
            launchGameLocaleURL =
                launchGameURLBasedOnMarketConfigurations[this.languageCode][this.gameVendorCode];
        }
        else if (this.gameVendorCode.toLowerCase().startsWith("hub88")) {
            launchGameLocaleURL =
                launchGameURlBasedOnSubProvidersPrefixConfigurations[this.languageCode]
                    .hub88;
        }
        else if (this.gameVendorCode.toLowerCase().startsWith("relax")) {
            launchGameLocaleURL =
                launchGameURlBasedOnSubProvidersPrefixConfigurations[this.languageCode]
                    .relax;
        }
        else if (this.gameVendorCode.toLowerCase().startsWith("mgs")) {
            launchGameLocaleURL =
                launchGameURlBasedOnSubProvidersPrefixConfigurations[this.languageCode]
                    .mgs;
        }
        return launchGameLocaleURL;
    }
    // -----------------------------------------------------------------
    // Set Methods
    onBroadCastGameLaunch(gamePregmatic) {
        this.onSetCurrentGamePregmatic(gamePregmatic);
        this.gameLaunchedSubject.next(gamePregmatic);
    }
    onSetCurrentGamePregmatic(gamePregmatic) {
        this.gamePregmatic = gamePregmatic;
    }
    onBroadCastGameWindowMinimized(isGameWindowMinimized) {
        this.onSetIsGameWindowMinimized(isGameWindowMinimized);
        this.isGameWindowMinimizedSubject.next(isGameWindowMinimized);
    }
    onSetIsGameWindowMinimized(isGameMinimized) {
        this.isGameMinimized = isGameMinimized;
    }
    onBroadcastIsFavouriteGame(isFavouriteGame) {
        this.onSetIsFavouriteGame(isFavouriteGame);
        this.updateIsFavouriteGameSubject.next(isFavouriteGame);
    }
    onSetIsFavouriteGame(isFavouriteGame) {
        this.isFavouriteGame = isFavouriteGame;
    }
    onSetGameCalledFrom(gameCalledFrom) {
        this.gameCalledFrom = gameCalledFrom;
    }
    onBroadcastIsRelaunchGame(flag) {
        this.isRelaunchGameSubject.next(flag);
    }
    onLoadGamePlay(gamePlay) {
        this.isEventListenerAdded = false;
        this.gamePlay = gamePlay;
        $(".game-is-loading").css("display", "flex");
        const urlPath = gamePlay.gameType === "realgame"
            ? `/ajax/launcher/getRealGames`
            : `/ajax/launcher/getFreeGames`;
        this.apiInteractor
            .get(urlPath, {
            gameSymbol: gamePlay.gameId,
        })
            .subscribe((gameResponse) => {
            let gameDetails = gameResponse.gameDetails;
            this.onLoadIFrame(gameDetails);
        });
    }
    // NB: This method requires to be rewritten
    onLoadIFrame(response) {
        /*
          we remove gameplay loading progress indicator here
        */
        $(".game-is-loading").hide();
        $("#gameIframeWrapper").html("");
        let $iframe = "";
        if (response && response.netentDetails) {
            response.netentDetails.language = response.language;
            $("#gameIframeWrapper").append('<div id="netentgame"></div>');
            this.onNetentScript(response.netentDetails, this.getDeviceType());
            if (!this.isLoggedIn && !this.utilityService.isPnpFlow()) {
                this.onSetRegilyRegistrationPopTimer();
            }
        }
        else if (response && response.url) {
            if (this.languageCode &&
                purpleTheme().indexOf(this.languageCode) > -1 &&
                this.gameVendorCode) {
                response.url = this.getGameLanguageURL(response.url);
            }
            response = this.getPlayNGoGameDetails(response);
            $iframe = $(`<iframe title="Game playground" name="gamePlayIframe" class="iframe" id="gamePlayIframe" src="${response.url}"></iframe>`);
            if (!this.isLoggedIn && !this.utilityService.isPnpFlow()) {
                this.onSetRegilyRegistrationPopTimer();
            }
        }
        else if (response === "gamedata-not-found") {
            this.onErrorMessageIFrame(response);
        }
        else {
            this.onErrorMessageIFrame(response.error);
        }
        $("#gameIframeWrapper").append($iframe);
        this.isGameLoading = false;
        setTimeout(() => {
            $("#gamePlayIframe").on("load", () => {
                /*
                  Blueprint vendor's postMessage listeners:
                  CloseGame - On fatal Error redirecting it to Home page
                  requestSize - Sending width and height of the iframe container
                */
                window.parent.addEventListener("message", (e) => {
                    if (e && e.data) {
                        if (e.data.type == "requestSize") {
                            this.onBluePrintPostMessage();
                        }
                    }
                });
                if (response &&
                    response.url.includes("playngonetwork")) {
                    this.onEstablishCrossCommunication(response.url);
                }
            });
            window.onresize = () => {
                if ($("#gamePlayIframe").length > 0 &&
                    response &&
                    response.game_code.includes("hub88bpg")) {
                    this.onBluePrintPostMessage();
                }
            };
        });
    }
    onEstablishCrossCommunication(gameURL) {
        let gameCommunicator = {
            source: undefined,
            targetOrigin: undefined,
            init: (element) => {
                this.onAddWindowEventListenersForGameCommunication();
                gameCommunicator.source = element.contentWindow;
                gameCommunicator.targetOrigin = gameURL.split("?")[0];
            },
            postMessage: (data) => {
                gameCommunicator.source.postMessage(data, gameCommunicator.targetOrigin);
            },
        };
        gameCommunicator.init(document.getElementById("gamePlayIframe"));
        gameCommunicator.postMessage({
            messageType: "addEventListener",
            eventType: "backToLobby",
        });
        gameCommunicator.postMessage({
            messageType: "addEventListener",
            eventType: "reloadGame",
        });
    }
    onAddWindowEventListenersForGameCommunication() {
        if (!this.isEventListenerAdded) {
            this.isEventListenerAdded = true;
            window.addEventListener("message", (messageEvent) => {
                this.onEventListenerProcessMessage(messageEvent);
            });
        }
    }
    onEventListenerProcessMessage(messageEvent) {
        if (messageEvent &&
            messageEvent.data &&
            messageEvent.data.type == "backToLobby") {
            this.router.navigate([`${this.languageCode}/casino`]);
        }
        if (messageEvent &&
            messageEvent.data &&
            messageEvent.data.type == "reloadGame" &&
            this.isGameLoading === false) {
            this.isGameLoading = true;
            this.onLoadGamePlay(this.gamePlay);
        }
    }
    /*
      Blue print providers don't handle client device resolution automatically
      so we have send clients device width & height as window post message when "requestResize" event is
      send by them
    */
    onBluePrintPostMessage() {
        let element = document.getElementById("gamePlayIframe");
        let deviceResolution = this.getDeviceWidthHeight();
        let data = Object.assign({ type: "resize" }, deviceResolution);
        element.contentWindow.postMessage(data, "*");
    }
    /*
      All the Erros which we encounters when we're trying fetch game url or  loading on iframe
      will be handle by below function handler.
    */
    onErrorMessageIFrame(response) {
        let $iframe = "";
        if (response && response.length > 20) {
            $iframe = $(`<iframe title="Game playground"  name="gamePlayIframe" class="iframe" id="gamePlayIframe" src="${response}"></iframe>`);
        }
        else if (response == "session expired") {
            $iframe = `<div class="loading-failed">${response}${this.translationService.get("gameplay.please_login")}</div>`;
        }
        else if (response &&
            (response.problem_loading ||
                response.unable_to_find_game ||
                response.no_response_url)) {
            $iframe = `<div class="loading-failed">${response.problem_loading} ${response.unable_to_find_game} ${response.no_response_url}</div>`;
        }
        else if (response &&
            response.errorCode &&
            response.errorCode === 100431) {
            $iframe = `<div class="loading-failed">${this.translationService.get("gameplay.game_code_missing")}</div>`;
        }
        else if (response === "gamedata-not-found") {
            $iframe = `<div class="loading-failed">${this.translationService.get("gameplay.gamenot_config")}</div>`;
        }
        else {
            $iframe = `<div class="loading-failed">${this.translationService.get("gameplay.gamenot_found")}</div>`;
        }
        $("#gameIframeWrapper").append($iframe);
    }
    onNetentScript(response, device) {
        if (!window["netent"]) {
            let gameServerURL = response.staticServer;
            let netentScript = document.createElement("script");
            netentScript.onload = () => {
                this.onLoadNetentGames(response, device);
            };
            netentScript.setAttribute("src", `${gameServerURL}/gameinclusion/library/gameinclusion.js`);
            document.head.appendChild(netentScript);
        }
        else {
            this.onLoadNetentGames(response, device);
        }
    }
    onLoadNetentGames(response, device) {
        if (device === "mobile") {
            this.onLoadMobileNetentGames(response);
        }
        else {
            this.onLoadDesktopNetentGames(response);
        }
    }
    /*
      Desktop Netent Game Configurations
    */
    onLoadDesktopNetentGames(response) {
        let configurations = {
            gameId: response.gameId,
            staticServer: response.staticServer,
            gameServer: response.gameServer,
            sessionId: response.sessionId,
            casinoBrand: "oneupent",
            targetElement: "netentgame",
            walletMode: response.walletMode,
            language: response.language,
            lobbyURL: environment.siteUrl,
        };
        if (response.liveCasinoHost) {
            configurations.liveCasinoHost = response.liveCasinoHost;
        }
        // Game launch successful.
        let success = (netEntExtend) => {
            netEntExtend.resize(640, 480);
            netEntExtend.addEventListener("gameReady", () => {
                netEntExtend.get("volumeLevel", (volumeLevel) => {
                    netEntExtend.set("volumeLevel", 50);
                });
            });
        };
        // Error handling here.
        let error = (e) => {
            console.log(e);
        };
        window["netent"].launch(configurations, success, error);
    }
    /*
      Mobile Netent Game Configurations
    */
    onLoadMobileNetentGames(response) {
        let configurations = {
            gameId: response.gameId,
            staticServer: response.staticServer,
            gameServer: response.gameServer,
            sessionId: response.sessionId,
            walletMode: response.walletMode,
            lobbyURL: response.lobbyURL,
            keepAliveURL: "",
            keepAliveInterval: 15,
            casinoBrand: response.casinoBrand,
            launchType: "iframe",
            applicationType: response.applicationType,
            targetElement: "netentgame",
            iframeSandbox: "allow-scripts allow-popups allow-popups-to-escape-sandbox allow-top-navigation allow-top-navigation-by-user-activation allow-same-origin allow-forms allow-pointer-lock",
            allowHtmlEmbedFullScreen: true,
            width: "100%",
            height: "100%",
            enforceRatio: false,
            language: response.language,
        };
        if (response.liveCasinoHost) {
            configurations.liveCasinoHost = response.liveCasinoHost;
        }
        let success = (netEntExtend) => {
            // gameReady Listener - callback is used for iFrame Touch games launch
            netEntExtend.addEventListener("gameReady", function () {
                // Game ready handler to setup Operator specific code
            });
        };
        // Error handling here.
        let error = (e) => {
            console.log(e);
        };
        window["netent"].launch(configurations, success, error);
    }
    /*
      Below logic is to clean game window data which we in store game card componet
      on user game launch hit on every new game launch
    */
    onClearGameWindowData() {
        let data = this.getCurrentGamePregmatic();
        if (!_.isEmpty(data)) {
            this.onBroadCastGameLaunch({});
            this.onBroadCastGameWindowMinimized(false);
        }
    }
    /*
      Below functionality will trigger registration popup at different time slots
      at 9mins 18mins 36mins(only three times)
  
      from the time when he start playing demo game without login or registration
      we pop out just to ask user to register & enjoy the real feel.
    */
    onSetRegilyRegistrationPopTimer() {
        this.registrationTimeout = setTimeout(() => {
            if (!this.isLoggedIn &&
                !this.utilityService.isPnpFlow() &&
                this.isGamePlayWindowLaunched) {
                this.registrationService.onOpenRegistration();
            }
        }, this.registrationPopUpTimePeriodInSeconds * 1000);
    }
    onUpdateRegilyTimer() {
        if (this.registrationPopUpTimePeriodInSeconds === 90) {
            this.registrationPopUpTimePeriodInSeconds = 180;
            this.onSetRegilyRegistrationPopTimer();
        }
        else if (this.registrationPopUpTimePeriodInSeconds === 180) {
            this.registrationPopUpTimePeriodInSeconds = 360;
            this.onSetRegilyRegistrationPopTimer();
        }
        else {
            this.onClearRegistrationTimer();
        }
    }
    onClearRegistrationTimer() {
        if (this.registrationTimeout) {
            this.registrationPopUpTimePeriodInSeconds = 90;
            clearInterval(this.registrationTimeout);
        }
    }
    onSetGameVendorCode(gameVendorCode) {
        this.gameVendorCode = gameVendorCode;
    }
    // -----------------------------------------------------------------
    // On Destroy
    ngOnDestroy() {
        if (this.getGameVendorCode()) {
            this.gameVendorCode = undefined;
        }
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
}
GamePlayService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GamePlayService_Factory() { return new GamePlayService(i0.ɵɵinject(i1.RegistrationService), i0.ɵɵinject(i2.TranslationService), i0.ɵɵinject(i3.UtilityService), i0.ɵɵinject(i4.SessionService), i0.ɵɵinject(i5.CommonService), i0.ɵɵinject(i6.HttpClient), i0.ɵɵinject(i7.Store), i0.ɵɵinject(i8.Router)); }, token: GamePlayService, providedIn: "root" });
