import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import {
  ValidationErrors,
  AsyncValidatorFn,
  AbstractControl,
} from "@angular/forms";

// Models
import { ValidationResponse } from "src/app/modules/registration/models/validation/validation-response.model";
import { ValidationRequest } from "src/app/modules/registration/models/validation/validation-request.model";

// Services
import { CommonService } from "src/app/modules/shared/services/common.service";

export class UniqueFieldsValidator {
  static createValidator(
    fieldToValidate: string,
    commonService: CommonService,
    isUniqueValidation: boolean
  ): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors> => {
      const controlValue: string = control.value;

      const regex: RegExp =
        /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,})$/;

      if (!controlValue) {
        return of({ required: true });
      } else if (
        controlValue &&
        fieldToValidate == "txtEmail" &&
        !regex.test(controlValue)
      ) {
        return of({ invalidEmail: true });
      } else if (
        controlValue &&
        fieldToValidate == "txtEmail" &&
        (controlValue.length < 5 || controlValue.length > 50)
      ) {
        return of({ cannotBeLessThan: true });
      } else if (
        controlValue &&
        fieldToValidate == "txtNickname" &&
        controlValue.length < 5 &&
        !regex.test(controlValue)
      ) {
        return of({ mustContainMinimum: true });
      } else if (isUniqueValidation) {
        const validationRequest: ValidationRequest = {
          [fieldToValidate]: controlValue,
        };

        return commonService.onGetValidateUniqueness(validationRequest).pipe(
          map((validationResponse: ValidationResponse) => {
            if (validationResponse && validationResponse.response == 1) {
              return null;
            } else if (
              validationResponse &&
              validationResponse.response == -1
            ) {
              if (fieldToValidate == "txtEmail") {
                return { invalidEmailResponse: true };
              } else {
                return { invalidNicknameResponse: true };
              }
            } else {
              if (fieldToValidate == "txtEmail") {
                return { notUniqueEmail: true };
              } else {
                return { notUniqueNickname: true };
              }
            }
          })
        );
      } else {
        return of(null);
      }
    };
  }
}
