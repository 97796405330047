import { Observable, Subject, Subscription } from "rxjs";
import { Injectable, OnDestroy } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";

// API Interactors
import { ApiInteractors } from "src/app/models/interactors/api.interactor";

// Configurations
import {
  launchGameURlBasedOnSubProvidersPrefixConfigurations,
  launchGameURLBasedOnMarketConfigurations,
} from "src/app/configurations/main.configurations";

// Environments
import { environment } from "src/environments/environment";

// Libraries
import * as _ from "underscore";
import * as $ from "jquery";

// Models
import { CommunicatorMessage } from "src/app/modules/game-groups/models/game-communicator/communicator-message.model";
import { NetEntExtensionMethods } from "src/app/modules/game-groups/models/netent/netent-extension-methods.model";
import { GameCommunicator } from "src/app/modules/game-groups/models/game-communicator/game-communicator.model";
import { DeviceDimensions } from "src/app/modules/game-groups/models/game-communicator/device-dimensions.model";
import { NetEntConfigurations } from "src/app/modules/game-groups/models/netent/netent-configuration.model";
import { NetEntDetails } from "src/app/modules/game-groups/models/netent/netent-details.model";
import { GamePlay } from "src/app/modules/game-groups/models/game-play/game-play.model";
import { GamePregmatic } from "src/app/modules/game-groups/models/game.model";
import {
  GameDetailsResponse,
  GameDetailsError,
  GameDetails,
} from "src/app/modules/game-groups/models/game-details/game-details.model";

// Reducers
import { AppState } from "src/app/store/reducers";

// Selectors
import { selectLanguageCode } from "src/app/modules/multi-languages/store/selectors/languages.selectors";
import {
  selectAuthLoginIsLoggedOut,
  selectAuthLoginIsLoggedIn,
} from "src/app/modules/auth/store/selectors/auth.selectors";

// Services
import { TranslationService } from "src/app/modules/multi-languages/services/translation.service";
import { RegistrationService } from "src/app/modules/registration/services/registration.service";
import { UtilityService } from "src/app/modules/shared/services/utility.service";
import { CommonService } from "src/app/modules/shared/services/common.service";
import { SessionService } from "src/app/modules/auth/services/session.service";

// Utilities
import { purpleTheme } from "src/app/modules/multi-languages/utilities/theme.utilities";

@Injectable({
  providedIn: "root",
})
export class GamePlayService implements OnDestroy {
  // API Interactions
  apiInteractor: ApiInteractors;

  // Numbers
  registrationPopUpTimePeriodInSeconds: number = 90;

  // Strings
  languageCode: string = environment.defaultLanguage;
  gameCalledFrom: string = "";
  gameVendorCode: string = "";

  // Booleans
  isGamePlayWindowLaunched: boolean = false;
  isEventListenerAdded: boolean = false;
  isFavouriteGame: boolean = false;
  isGameMinimized: boolean = false;
  isGameLoading: boolean = false;
  isLoggedIn: boolean = false;

  // Objects
  gamePregmatic: GamePregmatic | GamePlay;
  gamePlay: GamePlay;

  // Timeout
  registrationTimeout: NodeJS.Timer;

  // --------------------------------------------------------
  // Subject - Is Game Window Launched
  private gameLaunchedSubject: Subject<GamePregmatic | GamePlay> = new Subject<
    GamePregmatic | GamePlay
  >();
  public gameLaunchedSubject$: Observable<GamePregmatic | GamePlay> =
    this.gameLaunchedSubject.asObservable();

  // --------------------------------------------------------
  // Subject - is Game Window Minimized
  private isGameWindowMinimizedSubject: Subject<boolean> =
    new Subject<boolean>();
  public isGameWindowMinimizedSubject$: Observable<boolean> =
    this.isGameWindowMinimizedSubject.asObservable();

  // --------------------------------------------------------
  // Subject - Update Game Favourite Status
  updateIsFavouriteGameSubject: Subject<boolean> = new Subject<boolean>();
  updateIsFavouriteGameSubject$: Observable<boolean> =
    this.updateIsFavouriteGameSubject.asObservable();

  // --------------------------------------------------------
  // Subject - Reloaunch Game
  private isRelaunchGameSubject: Subject<boolean> = new Subject<boolean>();
  public isRelaunchGameSubject$: Observable<boolean> =
    this.isRelaunchGameSubject.asObservable();

  // --------------------------------------------------------
  // Subscriptions
  subscriptions: Subscription[] = [];

  constructor(
    private registrationService: RegistrationService,
    private translationService: TranslationService,
    private utilityService: UtilityService,
    private sessionService: SessionService,
    private commonService: CommonService,
    private httpClient: HttpClient,
    private store: Store<AppState>,
    private router: Router
  ) {
    this.apiInteractor = new ApiInteractors(this.httpClient);

    this.isLoggedIn = this.sessionService.getIsUserLoggedIn();

    this.subscriptions = [
      this.store
        .select(selectAuthLoginIsLoggedIn)
        .subscribe((isLoggedIn: boolean) => (this.isLoggedIn = isLoggedIn)),
      this.store
        .select(selectAuthLoginIsLoggedOut)
        .subscribe((isLoggedOut: boolean) => {
          if (isLoggedOut) {
            this.isLoggedIn = false;
          }
        }),
      this.store
        .select(selectLanguageCode)
        .subscribe((languageCode: string) => {
          this.languageCode = languageCode;
        }),
      this.commonService.isGameWindowRegilyPopUpTimerUpdateSubject$.subscribe(
        () => {
          if (!this.isLoggedIn && !this.utilityService.isPnpFlow()) {
            this.onUpdateRegilyTimer();
          }
        }
      ),
    ];
  }

  // -------------------------------------------------------------------//
  // Get Methods
  getDeviceType(): "desktop" | "mobile" {
    if (document.body.clientWidth >= 1024) {
      return "desktop";
    } else {
      return "mobile";
    }
  }

  getCurrentGamePregmatic(): GamePregmatic | GamePlay {
    return this.gamePregmatic;
  }

  getIsGameWindowMinimized(): boolean {
    return this.isGameMinimized;
  }

  getIsFavouriteGame(): boolean {
    return this.isFavouriteGame;
  }

  getGameCalledFrom(): string {
    return this.gameCalledFrom;
  }

  getPlayNGoGameDetails(response: GameDetails): GameDetails {
    if (response && response.url.includes("playngonetwork")) {
      let responseUrl: string = response.url;

      responseUrl = responseUrl
        .replace("lobby", "origin")
        .concat("&embedmode=iframe");

      if (!responseUrl.includes("origin")) {
        responseUrl = responseUrl.concat(`&origin=${window.location.origin}`);
      }

      response.url = responseUrl;

      return response;
    } else {
      return response;
    }
  }

  getDeviceWidthHeight(): DeviceDimensions {
    let clientWidth: number = document.body.clientWidth;

    let footerNaviagtionHeight: number = 0;

    let height: number = 0;

    let width: number = 0;

    if (document.getElementById("bottomNavigation")) {
      footerNaviagtionHeight =
        document.getElementById("bottomNavigation").offsetHeight;
    }

    if (
      clientWidth <= 1024 &&
      window.matchMedia("(orientation: portrait)").matches
    ) {
      width = window.innerWidth;

      height = window.innerHeight - footerNaviagtionHeight;
    } else if (
      clientWidth <= 1024 &&
      window.matchMedia("(orientation: landscape)").matches &&
      this.isLoggedIn
    ) {
      width = window.innerWidth - footerNaviagtionHeight;

      height = window.innerHeight;
    } else {
      width = $("#gameIframeWrapper").outerWidth();

      height = $("#gameIframeWrapper").outerHeight();
    }

    return { width, height };
  }

  getGameVendorCode(): string {
    return this.gameVendorCode;
  }

  getGameLanguageURL(responseURL: string): string {
    if (this.gameVendorCode) {
      let gameURL: string | URL = responseURL;

      let setLanguageParam: URLSearchParams | void;

      let languageCodeURL: string = this.getGameLaunchURLLocale();

      if (
        this.gameVendorCode !== "playngo" &&
        responseURL &&
        !responseURL.includes("playngonetwork")
      ) {
        gameURL = decodeURIComponent(responseURL);
      }

      gameURL = new URL(gameURL);

      if (gameURL.search && gameURL.search.includes("language")) {
        setLanguageParam = gameURL.searchParams.set(
          "language",
          languageCodeURL
        );
      } else if (gameURL.search && gameURL.search.includes("langIso")) {
        setLanguageParam = gameURL.searchParams.set("langIso", languageCodeURL);
      } else if (gameURL.search && gameURL.search.includes("lang")) {
        setLanguageParam = gameURL.searchParams.set("lang", languageCodeURL);

        if (
          this.gameVendorCode === "pushgaming" &&
          gameURL.search.includes("country")
        ) {
          if (this.languageCode === "pt-br") {
            setLanguageParam = gameURL.searchParams.set("country", "BR");
          } else {
            setLanguageParam = gameURL.searchParams.set("country", "ES");
          }
        }
      } else if (
        this.gameVendorCode.toLowerCase().startsWith("mgs_") &&
        gameURL.search &&
        gameURL.search.includes("ul")
      ) {
        setLanguageParam = gameURL.searchParams.set("ul", languageCodeURL);
      }

      gameURL.search = gameURL.searchParams.toString();

      if (
        this.gameVendorCode === "playngo" &&
        responseURL &&
        responseURL.includes("playngonetwork")
      ) {
        gameURL = gameURL.toString();
      } else {
        gameURL = decodeURIComponent(gameURL.toString());
      }

      return gameURL;
    }
  }

  getGameLaunchURLLocale(): string {
    let launchGameLocaleURL: string = "";

    if (
      this.languageCode &&
      launchGameURLBasedOnMarketConfigurations &&
      launchGameURLBasedOnMarketConfigurations.hasOwnProperty(
        this.languageCode
      ) &&
      launchGameURLBasedOnMarketConfigurations[this.languageCode][
        this.gameVendorCode
      ]
    ) {
      launchGameLocaleURL =
        launchGameURLBasedOnMarketConfigurations[this.languageCode][
          this.gameVendorCode
        ];
    } else if (this.gameVendorCode.toLowerCase().startsWith("hub88")) {
      launchGameLocaleURL =
        launchGameURlBasedOnSubProvidersPrefixConfigurations[this.languageCode]
          .hub88;
    } else if (this.gameVendorCode.toLowerCase().startsWith("relax")) {
      launchGameLocaleURL =
        launchGameURlBasedOnSubProvidersPrefixConfigurations[this.languageCode]
          .relax;
    } else if (this.gameVendorCode.toLowerCase().startsWith("mgs")) {
      launchGameLocaleURL =
        launchGameURlBasedOnSubProvidersPrefixConfigurations[this.languageCode]
          .mgs;
    }

    return launchGameLocaleURL;
  }

  // -----------------------------------------------------------------
  // Set Methods
  onBroadCastGameLaunch(gamePregmatic?: GamePregmatic | GamePlay): void {
    this.onSetCurrentGamePregmatic(gamePregmatic);

    this.gameLaunchedSubject.next(gamePregmatic);
  }

  onSetCurrentGamePregmatic(gamePregmatic: GamePregmatic | GamePlay): void {
    this.gamePregmatic = gamePregmatic;
  }

  onBroadCastGameWindowMinimized(isGameWindowMinimized: boolean): void {
    this.onSetIsGameWindowMinimized(isGameWindowMinimized);

    this.isGameWindowMinimizedSubject.next(isGameWindowMinimized);
  }

  onSetIsGameWindowMinimized(isGameMinimized: boolean): void {
    this.isGameMinimized = isGameMinimized;
  }

  onBroadcastIsFavouriteGame(isFavouriteGame: boolean): void {
    this.onSetIsFavouriteGame(isFavouriteGame);

    this.updateIsFavouriteGameSubject.next(isFavouriteGame);
  }

  onSetIsFavouriteGame(isFavouriteGame: boolean): void {
    this.isFavouriteGame = isFavouriteGame;
  }

  onSetGameCalledFrom(gameCalledFrom: string): void {
    this.gameCalledFrom = gameCalledFrom;
  }

  onBroadcastIsRelaunchGame(flag: boolean): void {
    this.isRelaunchGameSubject.next(flag);
  }

  onLoadGamePlay(gamePlay: GamePlay): void {
    this.isEventListenerAdded = false;

    this.gamePlay = gamePlay;

    $(".game-is-loading").css("display", "flex");

    const urlPath: string =
      gamePlay.gameType === "realgame"
        ? `/ajax/launcher/getRealGames`
        : `/ajax/launcher/getFreeGames`;

    this.apiInteractor
      .get<{ gameSymbol: string }, GameDetailsResponse>(urlPath, {
        gameSymbol: gamePlay.gameId,
      })
      .subscribe((gameResponse: GameDetailsResponse) => {
        let gameDetails: GameDetails = gameResponse.gameDetails;

        this.onLoadIFrame(gameDetails);
      });
  }

  // NB: This method requires to be rewritten
  onLoadIFrame(response: GameDetails | string): void {
    /*
      we remove gameplay loading progress indicator here
    */
    $(".game-is-loading").hide();

    $("#gameIframeWrapper").html("");

    let $iframe: string = "";

    if ((response as GameDetails) && (response as GameDetails).netentDetails) {
      (response as GameDetails).netentDetails.language = (
        response as GameDetails
      ).language;

      $("#gameIframeWrapper").append('<div id="netentgame"></div>');

      this.onNetentScript(
        (response as GameDetails).netentDetails,
        this.getDeviceType()
      );

      if (!this.isLoggedIn && !this.utilityService.isPnpFlow()) {
        this.onSetRegilyRegistrationPopTimer();
      }
    } else if ((response as GameDetails) && (response as GameDetails).url) {
      if (
        this.languageCode &&
        purpleTheme().indexOf(this.languageCode) > -1 &&
        this.gameVendorCode
      ) {
        (response as GameDetails).url = this.getGameLanguageURL(
          (response as GameDetails).url
        );
      }

      response = this.getPlayNGoGameDetails(response as GameDetails);

      $iframe = $(
        `<iframe title="Game playground" name="gamePlayIframe" class="iframe" id="gamePlayIframe" src="${
          (response as GameDetails).url
        }"></iframe>`
      );

      if (!this.isLoggedIn && !this.utilityService.isPnpFlow()) {
        this.onSetRegilyRegistrationPopTimer();
      }
    } else if (response === "gamedata-not-found") {
      this.onErrorMessageIFrame(response);
    } else {
      this.onErrorMessageIFrame((response as GameDetails).error);
    }

    $("#gameIframeWrapper").append($iframe);

    this.isGameLoading = false;

    setTimeout(() => {
      $("#gamePlayIframe").on("load", () => {
        /*
          Blueprint vendor's postMessage listeners:
          CloseGame - On fatal Error redirecting it to Home page
          requestSize - Sending width and height of the iframe container
        */
        window.parent.addEventListener("message", (e: MessageEvent) => {
          if (e && e.data) {
            if (e.data.type == "requestSize") {
              this.onBluePrintPostMessage();
            }
          }
        });

        if (
          (response as GameDetails as GameDetails) &&
          (response as GameDetails).url.includes("playngonetwork")
        ) {
          this.onEstablishCrossCommunication((response as GameDetails).url);
        }
      });

      window.onresize = () => {
        if (
          $("#gamePlayIframe").length > 0 &&
          (response as GameDetails) &&
          (response as GameDetails).game_code.includes("hub88bpg")
        ) {
          this.onBluePrintPostMessage();
        }
      };
    });
  }

  onEstablishCrossCommunication(gameURL: string): void {
    let gameCommunicator: GameCommunicator = {
      source: undefined,
      targetOrigin: undefined,
      init: (element: HTMLIFrameElement) => {
        this.onAddWindowEventListenersForGameCommunication();

        gameCommunicator.source = element.contentWindow;

        gameCommunicator.targetOrigin = gameURL.split("?")[0];
      },
      postMessage: (data: CommunicatorMessage) => {
        gameCommunicator.source.postMessage(
          data,
          gameCommunicator.targetOrigin
        );
      },
    };

    gameCommunicator.init(document.getElementById("gamePlayIframe"));

    gameCommunicator.postMessage({
      messageType: "addEventListener",
      eventType: "backToLobby",
    });

    gameCommunicator.postMessage({
      messageType: "addEventListener",
      eventType: "reloadGame",
    });
  }

  onAddWindowEventListenersForGameCommunication(): void {
    if (!this.isEventListenerAdded) {
      this.isEventListenerAdded = true;

      window.addEventListener("message", (messageEvent: MessageEvent) => {
        this.onEventListenerProcessMessage(messageEvent);
      });
    }
  }

  onEventListenerProcessMessage(messageEvent: MessageEvent): void {
    if (
      messageEvent &&
      messageEvent.data &&
      messageEvent.data.type == "backToLobby"
    ) {
      this.router.navigate([`${this.languageCode}/casino`]);
    }

    if (
      messageEvent &&
      messageEvent.data &&
      messageEvent.data.type == "reloadGame" &&
      this.isGameLoading === false
    ) {
      this.isGameLoading = true;

      this.onLoadGamePlay(this.gamePlay);
    }
  }

  /*
    Blue print providers don't handle client device resolution automatically
    so we have send clients device width & height as window post message when "requestResize" event is
    send by them
  */
  onBluePrintPostMessage(): void {
    let element: HTMLIFrameElement = document.getElementById(
      "gamePlayIframe"
    ) as HTMLIFrameElement;

    let deviceResolution: DeviceDimensions = this.getDeviceWidthHeight();

    let data: DeviceDimensions = { type: "resize", ...deviceResolution };

    element.contentWindow.postMessage(data, "*");
  }

  /*
    All the Erros which we encounters when we're trying fetch game url or  loading on iframe
    will be handle by below function handler.
  */
  onErrorMessageIFrame(response: string | GameDetailsError): void {
    let $iframe: string = "";

    if ((response as string) && (response as string).length > 20) {
      $iframe = $(
        `<iframe title="Game playground"  name="gamePlayIframe" class="iframe" id="gamePlayIframe" src="${response}"></iframe>`
      );
    } else if ((response as string) == "session expired") {
      $iframe = `<div class="loading-failed">${response}${this.translationService.get(
        "gameplay.please_login"
      )}</div>`;
    } else if (
      (response as GameDetailsError) &&
      ((response as GameDetailsError).problem_loading ||
        (response as GameDetailsError).unable_to_find_game ||
        (response as GameDetailsError).no_response_url)
    ) {
      $iframe = `<div class="loading-failed">${
        (response as GameDetailsError).problem_loading
      } ${(response as GameDetailsError).unable_to_find_game} ${
        (response as GameDetailsError).no_response_url
      }</div>`;
    } else if (
      (response as GameDetailsError) &&
      (response as GameDetailsError).errorCode &&
      (response as GameDetailsError).errorCode === 100431
    ) {
      $iframe = `<div class="loading-failed">${this.translationService.get(
        "gameplay.game_code_missing"
      )}</div>`;
    } else if ((response as string) === "gamedata-not-found") {
      $iframe = `<div class="loading-failed">${this.translationService.get(
        "gameplay.gamenot_config"
      )}</div>`;
    } else {
      $iframe = `<div class="loading-failed">${this.translationService.get(
        "gameplay.gamenot_found"
      )}</div>`;
    }

    $("#gameIframeWrapper").append($iframe);
  }

  onNetentScript(response: NetEntDetails, device: "desktop" | "mobile"): void {
    if (!window["netent"]) {
      let gameServerURL: string = response.staticServer;

      let netentScript: HTMLScriptElement = document.createElement("script");

      netentScript.onload = () => {
        this.onLoadNetentGames(response, device);
      };

      netentScript.setAttribute(
        "src",
        `${gameServerURL}/gameinclusion/library/gameinclusion.js`
      );

      document.head.appendChild(netentScript);
    } else {
      this.onLoadNetentGames(response, device);
    }
  }

  onLoadNetentGames(
    response: NetEntDetails,
    device: "desktop" | "mobile"
  ): void {
    if (device === "mobile") {
      this.onLoadMobileNetentGames(response);
    } else {
      this.onLoadDesktopNetentGames(response);
    }
  }

  /*
    Desktop Netent Game Configurations
  */
  onLoadDesktopNetentGames(response: NetEntDetails): void {
    let configurations: NetEntConfigurations = {
      gameId: response.gameId,
      staticServer: response.staticServer,
      gameServer: response.gameServer,
      sessionId: response.sessionId,
      casinoBrand: "oneupent",
      targetElement: "netentgame",
      walletMode: response.walletMode,
      language: response.language,
      lobbyURL: environment.siteUrl,
    };

    if (response.liveCasinoHost) {
      configurations.liveCasinoHost = response.liveCasinoHost;
    }

    // Game launch successful.
    let success: (netEntExtend: NetEntExtensionMethods) => void = (
      netEntExtend: NetEntExtensionMethods
    ) => {
      netEntExtend.resize(640, 480);

      netEntExtend.addEventListener("gameReady", () => {
        netEntExtend.get("volumeLevel", (volumeLevel) => {
          netEntExtend.set("volumeLevel", 50);
        });
      });
    };

    // Error handling here.
    let error: (e: Error) => void = (e: Error) => {
      console.log(e);
    };

    window["netent"].launch(configurations, success, error);
  }

  /*
    Mobile Netent Game Configurations
  */
  onLoadMobileNetentGames(response: NetEntDetails): void {
    let configurations: NetEntConfigurations = {
      gameId: response.gameId,
      staticServer: response.staticServer,
      gameServer: response.gameServer,
      sessionId: response.sessionId,
      walletMode: response.walletMode,
      lobbyURL: response.lobbyURL,
      keepAliveURL: "",
      keepAliveInterval: 15,
      casinoBrand: response.casinoBrand,
      launchType: "iframe",
      applicationType: response.applicationType,
      targetElement: "netentgame",
      iframeSandbox:
        "allow-scripts allow-popups allow-popups-to-escape-sandbox allow-top-navigation allow-top-navigation-by-user-activation allow-same-origin allow-forms allow-pointer-lock",
      allowHtmlEmbedFullScreen: true,
      width: "100%",
      height: "100%",
      enforceRatio: false,
      language: response.language,
    };

    if (response.liveCasinoHost) {
      configurations.liveCasinoHost = response.liveCasinoHost;
    }

    let success: (netEntExtend: NetEntExtensionMethods) => void = (
      netEntExtend: NetEntExtensionMethods
    ) => {
      // gameReady Listener - callback is used for iFrame Touch games launch
      netEntExtend.addEventListener("gameReady", function () {
        // Game ready handler to setup Operator specific code
      });
    };

    // Error handling here.
    let error: (e: Error) => void = (e: Error) => {
      console.log(e);
    };

    window["netent"].launch(configurations, success, error);
  }

  /*
    Below logic is to clean game window data which we in store game card componet
    on user game launch hit on every new game launch
  */
  onClearGameWindowData(): void {
    let data: GamePlay | GamePregmatic = this.getCurrentGamePregmatic();

    if (!_.isEmpty(data)) {
      this.onBroadCastGameLaunch({});

      this.onBroadCastGameWindowMinimized(false);
    }
  }

  /*
    Below functionality will trigger registration popup at different time slots
    at 9mins 18mins 36mins(only three times)

    from the time when he start playing demo game without login or registration
    we pop out just to ask user to register & enjoy the real feel.
  */
  onSetRegilyRegistrationPopTimer(): void {
    this.registrationTimeout = setTimeout(() => {
      if (
        !this.isLoggedIn &&
        !this.utilityService.isPnpFlow() &&
        this.isGamePlayWindowLaunched
      ) {
        this.registrationService.onOpenRegistration();
      }
    }, this.registrationPopUpTimePeriodInSeconds * 1000);
  }

  onUpdateRegilyTimer(): void {
    if (this.registrationPopUpTimePeriodInSeconds === 90) {
      this.registrationPopUpTimePeriodInSeconds = 180;

      this.onSetRegilyRegistrationPopTimer();
    } else if (this.registrationPopUpTimePeriodInSeconds === 180) {
      this.registrationPopUpTimePeriodInSeconds = 360;

      this.onSetRegilyRegistrationPopTimer();
    } else {
      this.onClearRegistrationTimer();
    }
  }

  onClearRegistrationTimer(): void {
    if (this.registrationTimeout) {
      this.registrationPopUpTimePeriodInSeconds = 90;

      clearInterval(this.registrationTimeout);
    }
  }

  onSetGameVendorCode(gameVendorCode: string): void {
    this.gameVendorCode = gameVendorCode;
  }

  // -----------------------------------------------------------------
  // On Destroy
  ngOnDestroy(): void {
    if (this.getGameVendorCode()) {
      this.gameVendorCode = undefined;
    }

    this.subscriptions.forEach((subscription: Subscription) =>
      subscription.unsubscribe()
    );
  }
}
