import {
  createFeatureSelector,
  DefaultProjectorFn,
  MemoizedSelector,
  createSelector,
} from "@ngrx/store";

// Enums
import { LanguageCodes } from "src/app/models/configurations/enums/language-codes.enum";

// Reducers
import {
  LanguageStateFeatureKey,
  LanguagesReducerState,
} from "src/app/modules/multi-languages/store/reducers/languages.reducer";

// Select the Main State
const selectLanguagesReducerState =
  createFeatureSelector<LanguagesReducerState>(LanguageStateFeatureKey);

// Select Parts of the state
export const selectLanguage: MemoizedSelector<
  object,
  LanguageCodes,
  DefaultProjectorFn<LanguageCodes>
> = createSelector(
  selectLanguagesReducerState,
  (state: LanguagesReducerState) => state.language
);
export const selectLanguageCode: MemoizedSelector<
  object,
  string,
  DefaultProjectorFn<string>
> = createSelector(
  selectLanguagesReducerState,
  (state: LanguagesReducerState) => state.languageCode
);
