import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { combineLatest, Observable, Subscription } from "rxjs";
import { Store } from "@ngrx/store";
import {
  ViewEncapsulation,
  HostListener,
  EventEmitter,
  Component,
  Output,
} from "@angular/core";

// Configurations
import { userRequested } from "src/app/modules/user/store/actions/user.actions";
import {
  userProfileBalanceRequested,
  profileBalanceRequested,
} from "src/app/modules/user/store/actions/profile.actions";
import {
  quickDepositDefaultValuesConfigurations,
  avialableCountriesConfigurations,
} from "src/app/configurations/main.configurations";

// Enums
import { WorldCurrencyCode } from "src/app/models/configurations/enums/localization/world-currencies.enum";
import { CurrencyPosition } from "src/app/models/configurations/enums/currency-position.enum";
import { StatusResponse } from "src/app/models/api/status.response";

// Environments
import { environment } from "src/environments/environment";

// Libraries
import * as _ from "underscore";
import * as $ from "jquery";

// Models
import { PiqTransactionStatusResponse } from "src/app/modules/account/models/piq-transaction-status/piq-transaction-status-response.model";
import { PiqTransactionStatusRequest } from "src/app/modules/account/models/piq-transaction-status/piq-transaction-status-request.model";
import { UserPaymentMethodsRequest } from "src/app/modules/transactions/models/user-payment-methods/user-payment-methods-request.model";
import { TransactionMethodTypes } from "src/app/modules/transactions/models/pending-withdrawals/pending-withdrawals-request.model";
import { DynamicFormProcessPaymentRequest } from "src/app/modules/account/models/dynamic-form-process-payment-request.model";
import { UserPIQAccount } from "src/app/modules/transactions/models/user-piq-account/user-piq-account-response.model";
import { ProcessPaymentRequest } from "src/app/modules/account/models/process-payments/process-payment-request.model";
import { UserBalanceDetails } from "src/app/modules/shared/models/profiles/user-balance-details.model";
import { UserProfileBalance } from "src/app/modules/shared/models/profiles/user-profile-balance.model";
import {
  UserPaymentMethodsResponse,
  UserPaymentMethod,
} from "src/app/modules/transactions/models/user-payment-methods/user-payment-methods-response.model";
import { CashierAttributes } from "src/app/modules/account/models/cashier/cashier-attributes.model";
import { IFramePayment } from "src/app/modules/account/models/iframe-payment/iframe-payment.model";
import { CashierDetails } from "src/app/modules/account/models/cashier/cashier-details.model";
import { TransactionStatus } from "src/app/modules/account/models/transaction-status.model";
import {
  ProcessPaymentRedirection,
  ProcessPaymentResponse,
} from "src/app/modules/account/models/process-payments/process-payment-response.model";
import { UpdateGTMEvent } from "src/app/modules/account/models/update-gtm-event.model";
import { CurrencyDetails } from "src/app/modules/auth/models/currency-details.model";
import { ProfileBalance } from "src/app/modules/auth/models/profile-balance.model";
import { PiqDetails } from "src/app/modules/account/models/piq-details.model";
import { ActiveTab } from "src/app/modules/shared/models/active-tab.model";
import { UserData } from "src/app/modules/user/models/user-data.model";
import { TokenResponse } from "src/app/models/games/token.response";
import { TokenRequest } from "src/app/models/games/token.request";

// Reducers
import { AppState } from "src/app/store/reducers";

// Selectors
import { selectAuthUserDataLoaded } from "src/app/modules/user/store/selectors/user.selectors";
import {
  selectAuthProfileBalance,
  selectUserProfileBalance,
} from "src/app/modules/user/store/selectors/profile.selectors";
import {
  selectAuthLoginIsLoggedOut,
  selectAuthLoginIsLoggedIn,
} from "src/app/modules/auth/store/selectors/auth.selectors";

// Services
import { UserDetailsService } from "src/app/modules/user/services/user-details.service";
import { PaymentService } from "src/app/modules/transactions/services/payment.service";
import { CashierService } from "src/app/modules/account/services/cashier.service";
import { EmitterService } from "src/app/modules/shared/services/emitter.service";
import { UtilityService } from "src/app/modules/shared/services/utility.service";
import { FormService } from "src/app/modules/dynamic-form/services/form.service";
import { GtmService } from "src/app/modules/shared/services/gtm.service";

// Validators
import { CharactersValidator } from "src/app/modules/validators/validators/characters.validators";

declare let encryptData: any;

@Component({
  selector: "app-quick-deposit",
  templateUrl: "./quick-deposit.component.html",
  styleUrls: ["./quick-deposit.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class QuickDepositComponent {
  // Outputs
  @Output() noUsedMethodsAvailable: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  // Numbers
  transactionStatusIntervalCounter: number = 0;
  defaultMinPaymentAmount: number = 0;
  redirectionTimer: number = 0;

  // Strings
  showScreen: string = "payment";
  currencySymbol: string = "";

  // Booleans
  isDepositProgress: boolean = false;
  isQd3DPopup: boolean = false;
  isLoggedIn: boolean = false;
  isLoading: boolean = false;

  // Enums
  windowType: "device" | "mobile" = "device";
  currencyPosition: CurrencyPosition;
  currencyCode: WorldCurrencyCode;

  // Objects
  redirectionOutput: ProcessPaymentRedirection;
  userBalanceDetails: UserBalanceDetails;
  selectedPaymentMethod: UserPIQAccount;
  transactionStatus: TransactionStatus;
  piqDetails: PiqDetails = {};
  profileDetails: UserData;

  // Forms
  cashierAttributesForm: FormGroup;
  cashierDetailsForm: FormGroup;

  // Intervals
  transactionStatusInterval: NodeJS.Timer;

  // Subscriptions
  transactionStatusSubscription: Subscription;
  makePaymentTokenSubscription: Subscription;
  userPIQMethodsSubscription: Subscription;
  combineLatestSubscription: Subscription;
  makePaymentSubscription: Subscription;
  tokenSubscription: Subscription;

  subscriptions: Subscription[] = [];

  constructor(
    private userDetailsService: UserDetailsService,
    private cashierService: CashierService,
    private utilityService: UtilityService,
    private emitterService: EmitterService,
    private paymentService: PaymentService,
    private formService: FormService,
    private formBuilder: FormBuilder,
    private gtmService: GtmService,
    private store: Store<AppState>
  ) {
    this.cashierDetailsForm = this.formBuilder.group({
      amount: [0, [CharactersValidator.validateMinNumericValue("50", "1000")]],
      cvv: ["", [CharactersValidator.validateExactNumber(3)]],
      accountId: ["", [Validators.required]],
    });

    if (environment.features.testing.quickDeposits) {
      this.cashierDetailsForm.markAsDirty();

      this.isDepositProgress = false;

      this.transactionStatus = {
        status: "Success",
        successAmount: "22",
      };

      this.showScreen = "transaction";
    }

    this.cashierAttributesForm = this.formBuilder.group({
      successUrl: [""],
      failureUrl: [""],
      pendingUrl: [""],
      cancelUrl: [""],
      labelId: [""],
      productId: [""],
      ipAddr: [""],
      bnsCode: [""],
      type: ["QuickDeposit"],
    });
  }

  // -----------------------------------------------------------------
  // Lifecycle Hooks
  ngOnInit(): void {
    this.store.dispatch(userProfileBalanceRequested());

    this.store.dispatch(profileBalanceRequested());

    this.getWindowType();

    this.onUpdateUserDetails();

    this.piqDetails.method = "Deposit";

    if (this.userDetailsService.userCurrencyDetails) {
      this.currencyPosition = this.getCurrencyPosition();
    }

    this.subscriptions = [
      this.store
        .select(selectAuthLoginIsLoggedIn)
        .subscribe((isLoggedIn: boolean) => (this.isLoggedIn = isLoggedIn)),
      this.store
        .select(selectAuthLoginIsLoggedOut)
        .subscribe((isLoggedOut: boolean) => {
          if (isLoggedOut) {
            this.isLoggedIn = false;
          }
        }),
      this.userDetailsService.userBalanceUpdatedSubject$.subscribe(() => {
        if (this.userDetailsService.userCurrencyDetails) {
          this.currencyPosition = this.getCurrencyPosition();
        }
      }),
      this.userDetailsService.currencyCodeBehaviourSubject$.subscribe(
        (currencyCode: WorldCurrencyCode) => {
          this.currencyCode = currencyCode;
        }
      ),
      this.userDetailsService.currencySymbolBehaviourSubject$.subscribe(
        (currencySymbol: string) => {
          this.currencySymbol = currencySymbol;

          if (this.currencySymbol) {
            this.onSetQuickDepositDefaultValue();
          }
        }
      ),
      this.emitterService.isSuccessfulDepositSubject$.subscribe(() => {
        this.onUpdateUserDetails();
      }),
      this.store
        .select(selectAuthUserDataLoaded)
        .subscribe(({ userData, isLoaded }) => {
          if (isLoaded) {
            this.profileDetails = userData;
          } else {
            if (this.isLoggedIn) {
              this.store.dispatch(userRequested());
            }
          }
        }),
    ];
  }

  // -----------------------------------------------------------------
  // Host Listeners
  @HostListener("window:message", ["$event"])
  onMessage(event: MessageEvent): void {
    if (
      event &&
      event.data &&
      event.data.message === "deposit-transactionStatus"
    ) {
      this.onGetUserCurrentTransactionStatus(
        sessionStorage.getItem("_tid"),
        atob(sessionStorage.getItem("_tam"))
      );
    }
  }

  @HostListener("window:resize")
  onResize(): void {
    this.getWindowType();
  }

  // -----------------------------------------------------------------
  // Window Type
  getWindowType(): void {
    const clientWidth: number = document.body.clientWidth;

    if (clientWidth <= 1024) {
      this.windowType = "mobile";
    } else {
      this.windowType = "device";
    }
  }

  // -----------------------------------------------------------------
  // Get Methods
  getCurrencyPosition(): CurrencyPosition {
    let isCountryExist: boolean = false;

    const currencyDetails: CurrencyDetails =
      this.userDetailsService.userCurrencyDetails;

    if (this.userDetailsService.userProfileDetails) {
      currencyDetails.countryCode =
        this.userDetailsService.userProfileDetails.country;

      isCountryExist =
        avialableCountriesConfigurations.indexOf(currencyDetails.countryCode) >=
        0
          ? true
          : false;
    }

    if (
      isCountryExist &&
      currencyDetails &&
      currencyDetails.position === CurrencyPosition.before
    ) {
      return CurrencyPosition.before;
    } else if (
      isCountryExist &&
      currencyDetails &&
      currencyDetails.position === CurrencyPosition.after
    ) {
      return CurrencyPosition.after;
    } else {
      return CurrencyPosition.before;
    }
  }

  getProcessPaymentRequest(): ProcessPaymentRequest {
    let dynamicFormValues: CashierDetails = {};

    const selectPaymentMethods: string = this.selectedPaymentMethod.type;

    dynamicFormValues = this.formService.transformFormToData<CashierDetails>(
      this.cashierDetailsForm,
      {}
    );

    const requestObject: ProcessPaymentRequest = {
      paymentMethod: "deposit",
      methodType: "process",
      merchantId: this.piqDetails.merchantId
        ? this.piqDetails.merchantId
        : environment.paymentIqMID,
      sessionId: this.piqDetails.sessionId,
      userId: this.piqDetails.userId,
      attributes: this.formService.transformFormToData<CashierAttributes>(
        this.cashierAttributesForm,
        {}
      ),
      provider: selectPaymentMethods.toLowerCase(),
      amount: dynamicFormValues.amount,
    };

    if (this.selectedPaymentMethod.service) {
      requestObject.service = this.selectedPaymentMethod.service;
    }

    if (selectPaymentMethods === "CREDITCARD") {
      if (dynamicFormValues.cvv) {
        dynamicFormValues.encCvv = encryptData(dynamicFormValues.cvv);
        delete dynamicFormValues.cvv;
      }
    }

    _.each(dynamicFormValues, (value: string, key: string) => {
      if (value === "") {
        delete dynamicFormValues[key];
      }
    });

    return { ...requestObject, ...dynamicFormValues };
  }

  // -----------------------------------------------------------------
  // Set Methods
  onHoverBottomRibbon(): void {
    this.utilityService.gameplayFooterMouseOver(true);
  }

  onMouseLeaveBottomRibbon(): void {
    this.utilityService.gameplayFooterMouseOver(false);
  }

  onSetQuickDepositDefaultValue(): void {
    this.defaultMinPaymentAmount =
      quickDepositDefaultValuesConfigurations[`default_${this.currencySymbol}`];
  }

  onUpdateUserDetails(): void {
    this.userBalanceDetails = this.userDetailsService.getUserBalanceDetails();

    this.isLoading = true;

    if (
      !_.isEmpty(this.profileDetails) &&
      !_.isEmpty(this.userBalanceDetails)
    ) {
      this.piqDetails.userId = this.profileDetails.playerID;

      this.onGetCashierToken();
    } else {
      let tokenRequest: TokenRequest = {
        token: "cashier",
      };

      const tokenObservable: Observable<TokenResponse> =
        this.cashierService.onGetToken(tokenRequest);

      const profileBalanceObservable: Observable<ProfileBalance> =
        this.store.select(selectAuthProfileBalance);

      const userProfileBalanceObservable: Observable<UserProfileBalance> =
        this.store.select(selectUserProfileBalance);

      this.combineLatestSubscription = combineLatest([
        tokenObservable,
        profileBalanceObservable,
        userProfileBalanceObservable,
      ]).subscribe(
        ([
          tokenResponse,
          profileBalanceResponse,
          userProfileBalanceResponse,
        ]) => {
          let token: TokenResponse = tokenResponse;

          let profileBalance: ProfileBalance = profileBalanceResponse;

          let userProfileBalance: UserProfileBalance =
            userProfileBalanceResponse;

          if (
            profileBalance &&
            profileBalance.profile &&
            profileBalance.profile.playerID &&
            userProfileBalance
          ) {
            this.userBalanceDetails =
              this.userDetailsService.getUserBalanceDetails();

            this.piqDetails.userId = profileBalance.profile.playerID;

            this.onParseToken(token);
          }
        }
      );
    }
  }

  onGetCashierToken(): void {
    let tokenRequest: TokenRequest = {
      token: "cashier",
    };

    this.tokenSubscription = this.cashierService
      .onGetToken(tokenRequest)
      .subscribe((tokenResponse: TokenResponse) => {
        if (
          tokenResponse &&
          tokenResponse.status === StatusResponse.SUCCESS &&
          tokenResponse.token &&
          tokenResponse.token !== ""
        ) {
          this.onParseToken(tokenResponse);
        }
      });
  }

  onParseToken(token: TokenResponse): void {
    if (token.status === "SUCCESS" && token.token && token.token !== "") {
      this.piqDetails.sessionId = token.token;

      this.piqDetails.merchantId = environment.paymentIqMID;

      this.cashierAttributesForm.controls.successUrl.setValue(
        token.cashierCallbackUrl
      );

      this.cashierAttributesForm.controls.failureUrl.setValue(
        token.cashierCallbackUrl
      );
      this.cashierAttributesForm.controls.pendingUrl.setValue(
        token.cashierCallbackUrl
      );

      this.cashierAttributesForm.controls.cancelUrl.setValue(
        token.cashierCallbackUrl
      );

      this.cashierAttributesForm.controls.labelId.setValue(token.labelId);

      this.cashierAttributesForm.controls.productId.setValue(token.productId);

      this.cashierAttributesForm.controls.ipAddr.setValue(token.ipAddr);

      this.onGetUserUsedAccount();
    } else {
      this.selectedPaymentMethod = undefined;
    }
  }

  onGetUserUsedAccount(): void {
    let { merchantId, userId, sessionId, method } = this.piqDetails;

    let userPaymentMethodsRequest: UserPaymentMethodsRequest = {
      merchantId,
      userId,
      sessionId,
      method: TransactionMethodTypes[method],
    };

    this.userPIQMethodsSubscription = this.paymentService
      .onGetUserPIQMethods(userPaymentMethodsRequest)
      .subscribe(
        (userPaymentMethodsResponse: UserPaymentMethodsResponse) => {
          this.isLoading = false;

          /*
            select the first used card method
          */
          if (
            userPaymentMethodsResponse &&
            userPaymentMethodsResponse.success &&
            userPaymentMethodsResponse.methods
          ) {
            const userPaymentMethod: UserPaymentMethod =
              userPaymentMethodsResponse.methods.find(
                (userPaymentMethod: UserPaymentMethod) => {
                  if (userPaymentMethod.providerType === "CREDITCARD") {
                    return true;
                  }
                }
              );

            if (
              userPaymentMethod &&
              userPaymentMethod.accounts &&
              userPaymentMethod.accounts.length > 0
            ) {
              this.emitterService.onBroadcastIsNoCCCardsAvailable(false);

              this.selectedPaymentMethod = userPaymentMethod
                .accounts[0] as UserPIQAccount;

              this.onSetInputSize();

              this.cashierDetailsForm.controls.amount.setValue(
                this.defaultMinPaymentAmount
              );

              if (userPaymentMethod.limit) {
                const minLimit: string = userPaymentMethod.limit.min;

                const maxLimit: string = userPaymentMethod.limit.max;

                if (minLimit && maxLimit) {
                  this.cashierDetailsForm.controls.amount.setValidators([
                    CharactersValidator.validateMinNumericValue(
                      minLimit,
                      maxLimit
                    ),
                  ]);
                }
              }

              this.cashierDetailsForm.controls.accountId.setValue(
                this.selectedPaymentMethod.accountId
              );
            } else {
              this.emitterService.onBroadcastIsNoCCCardsAvailable(true);

              this.noUsedMethodsAvailable.emit(true);

              this.selectedPaymentMethod = undefined;

              this.onOpenMainCashier();
            }
          } else {
            this.noUsedMethodsAvailable.emit(true);

            this.selectedPaymentMethod = undefined;

            this.onOpenMainCashier();
          }
        },
        () => {
          this.isLoading = false;
        }
      );
  }

  onOpenMainCashier(): void {
    if (this.windowType === "mobile") {
      const activeTab: ActiveTab = {
        tabName: "deposit",
        showBackButton: true,
      };

      this.utilityService.openAccountComponent(activeTab);
    }
  }

  onMakeQuickDeposit(): void {
    this.isDepositProgress = true;

    let tokenRequest: TokenRequest = {
      token: "cashier",
    };

    this.makePaymentTokenSubscription = this.cashierService
      .onGetToken(tokenRequest)
      .subscribe(
        (tokenResponse: TokenResponse) => {
          if (
            tokenResponse &&
            tokenResponse.status === "SUCCESS" &&
            tokenResponse.token &&
            tokenResponse.token !== ""
          ) {
            this.piqDetails.sessionId = tokenResponse.token;

            this.piqDetails.merchantId = environment.paymentIqMID;

            this.cashierAttributesForm.controls.successUrl.setValue(
              tokenResponse.cashierCallbackUrl
            );

            this.cashierAttributesForm.controls.failureUrl.setValue(
              tokenResponse.cashierCallbackUrl
            );

            this.cashierAttributesForm.controls.pendingUrl.setValue(
              tokenResponse.cashierCallbackUrl
            );

            this.cashierAttributesForm.controls.cancelUrl.setValue(
              tokenResponse.cashierCallbackUrl
            );

            this.cashierAttributesForm.controls.labelId.setValue(
              tokenResponse.labelId
            );

            this.cashierAttributesForm.controls.productId.setValue(
              tokenResponse.productId
            );

            this.cashierAttributesForm.controls.ipAddr.setValue(
              tokenResponse.ipAddr
            );

            const processPaymentRequest: ProcessPaymentRequest =
              this.getProcessPaymentRequest();

            this.makePaymentSubscription = this.paymentService
              .onMakePayment(processPaymentRequest)
              .subscribe((processPaymentResponse: ProcessPaymentResponse) => {
                const txnId: string = processPaymentResponse.txRefId.substr(
                  processPaymentResponse.txRefId.indexOf("A") + 1
                );

                const txnRefId: string = processPaymentResponse.txRefId;

                sessionStorage.setItem("_userId", this.piqDetails.userId);

                sessionStorage.setItem("_sessionId", this.piqDetails.sessionId);

                sessionStorage.setItem("_tid", txnId);

                sessionStorage.setItem("_trid", btoa(txnRefId));

                sessionStorage.setItem(
                  "_tam",
                  btoa(processPaymentRequest.amount.toString())
                );

                if (processPaymentResponse && processPaymentResponse.success) {
                  if (
                    processPaymentResponse.success &&
                    processPaymentResponse.txState &&
                    processPaymentResponse.txState !== "WAITING_INPUT"
                  ) {
                    this.onGetUserCurrentTransactionStatus(
                      txnId,
                      processPaymentResponse.amount
                    );
                  } else if (
                    processPaymentResponse.success &&
                    processPaymentResponse.txState &&
                    processPaymentResponse.txState === "WAITING_INPUT"
                  ) {
                    this.redirectionOutput =
                      processPaymentResponse.redirectOutput;

                    const contentAndForm: IFramePayment =
                      this.cashierService.getIframe(
                        processPaymentResponse.redirectOutput
                      );

                    const { content, form } = contentAndForm;

                    if (content || form) {
                      this.onOpenQuickDeposit3DPopup();

                      setTimeout(() => {
                        $("#redirectionDiv").css(
                          "height",
                          100 + this.redirectionOutput.height
                        );

                        $("#redirectionDiv").html(content);

                        const $iframe = $("#loaderIframe");

                        $iframe.ready(() => {
                          $iframe.contents().find("body").length > 0
                            ? $iframe.contents().find("body").append(form)
                            : $iframe.append(form);

                          if (
                            !processPaymentResponse.redirectOutput.html &&
                            ($("#loaderIframe")
                              .contents()
                              .find("#proxy_redirect").length ||
                              $("#loaderIframe").find("#proxy_redirect").length)
                          ) {
                            if (
                              $("#loaderIframe")
                                .contents()
                                .find("#proxy_redirect").length > 0
                            ) {
                              $("#loaderIframe")
                                .contents()
                                .find("#proxy_redirect")
                                .submit();
                            } else {
                              $("#loaderIframe")
                                .find("#proxy_redirect")
                                .submit();
                            }
                          }
                        });
                      });
                    }
                  }
                } else {
                  this.onShowTransactionStatus("failure", undefined);
                }
              });
          } else {
            this.onShowTransactionStatus("failure", undefined);
          }
        },
        () => {
          this.onShowTransactionStatus("failure", undefined);
        }
      );
  }

  onGetUserCurrentTransactionStatus(
    transactionId: string,
    amount: string
  ): void {
    const piqTransactionStatusRequest: PiqTransactionStatusRequest = {
      sessionId: this.piqDetails.sessionId,
      userId: this.piqDetails.userId,
      merchantId: environment.paymentIqMID,
      transactionId,
    };

    this.transactionStatusSubscription = this.paymentService
      .onGetPIQTransactionStatus(piqTransactionStatusRequest)
      .subscribe(
        (piqTransactionStatusResponse: PiqTransactionStatusResponse) => {
          if (
            piqTransactionStatusResponse &&
            piqTransactionStatusResponse.statusCode === "SUCCESS" &&
            piqTransactionStatusResponse.txState === "SUCCESSFUL"
          ) {
            this.store.dispatch(userProfileBalanceRequested());

            this.onShowTransactionStatus("Success", amount);
          } else if (
            piqTransactionStatusResponse &&
            piqTransactionStatusResponse.txState === "PROCESSING"
          ) {
            this.transactionStatusInterval = setInterval(() => {
              if (this.transactionStatusIntervalCounter <= 4) {
                this.transactionStatusIntervalCounter++;

                this.onGetUserCurrentTransactionStatus(transactionId, amount);
              } else {
                this.onShowTransactionStatus("failure", undefined);
              }
            }, 2000);
          } else {
            this.onShowTransactionStatus("failure", undefined);
          }
        },
        () => {
          this.onShowTransactionStatus("failure", undefined);
        }
      );
  }

  onShowTransactionStatus(status: string, successAmount?: string): void {
    clearInterval(this.transactionStatusInterval);

    this.transactionStatusIntervalCounter = 0;

    this.onCloseQuickDeposit3DPopup();

    this.transactionStatus = {
      status,
      successAmount,
    };

    this.isDepositProgress = false;

    this.showScreen = "transaction";

    let count: number = 6;

    const timerInstance: NodeJS.Timer = setInterval(() => {
      this.redirectionTimer = --count;

      if (this.redirectionTimer === 0) {
        clearInterval(timerInstance);

        this.showScreen = "payment";

        this.cashierDetailsForm.controls.amount.setValue(
          this.defaultMinPaymentAmount
        );

        this.cashierDetailsForm.controls.cvv.setValue("");
      }
    }, 1000);

    if (status === "Success") {
      this.onUpdateGtmEvent({
        txAmount: successAmount,
        merchantTxId: sessionStorage.getItem("_tid"),
      });

      this.emitterService.onBroadcastSuccessfulDeposit(!!status);
    }

    sessionStorage.removeItem("_userId");

    sessionStorage.removeItem("_sessionId");

    sessionStorage.removeItem("_tid");

    sessionStorage.removeItem("_trid");

    sessionStorage.removeItem("_tam");
  }

  onCancel3DTransaction(): void {
    this.onCloseQuickDeposit3DPopup();

    this.onGetUserCurrentTransactionStatus(
      sessionStorage.getItem("_tid"),
      atob(sessionStorage.getItem("_tam"))
    );
  }

  onOpenQuickDeposit3DPopup(): void {
    this.isQd3DPopup = true;

    this.utilityService.onAddClassToAppBody("overflow-hidden");
  }

  onCloseQuickDeposit3DPopup(): void {
    this.isQd3DPopup = false;

    this.utilityService.onRemoveClassFromAppBody("overflow-hidden");
  }

  onRetry(): void {
    this.showScreen = "payment";
  }

  onSetInputSize(): void {
    if (document.getElementById("qdAmountInput")) {
      let qdAmountInput: HTMLInputElement = document.getElementById(
        "qdAmountInput"
      ) as HTMLInputElement;

      qdAmountInput.size = qdAmountInput.value.length
        ? qdAmountInput.value.length
        : 4;
    }
  }

  onUpdateGtmEvent(responseData: UpdateGTMEvent): void {
    if (this.profileDetails && responseData.merchantTxId) {
      this.gtmService.onGtmDataLayerEvent({
        event: "user_interaction",
        event_type: "deposit",
        currency: this.currencyCode,
        txAmount: responseData.txAmount,
        transactioRefId: responseData.merchantTxId,
        userid: this.profileDetails.playerID,
        event_context: "subsequent",
      });
    }
  }

  // -----------------------------------------------------------------
  // On Destroy
  ngOnDestroy(): void {
    if (this.transactionStatusSubscription)
      this.transactionStatusSubscription.unsubscribe();

    if (this.makePaymentTokenSubscription)
      this.makePaymentTokenSubscription.unsubscribe();

    if (this.userPIQMethodsSubscription)
      this.userPIQMethodsSubscription.unsubscribe();

    if (this.combineLatestSubscription)
      this.combineLatestSubscription.unsubscribe();

    if (this.makePaymentSubscription)
      this.makePaymentSubscription.unsubscribe();

    if (this.tokenSubscription) this.tokenSubscription.unsubscribe();

    this.subscriptions.forEach((subscription: Subscription) =>
      subscription.unsubscribe()
    );
  }
}
