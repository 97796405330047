import { HttpClient } from "@angular/common/http";
import { catchError, map } from "rxjs/operators";
import { Observable, throwError } from "rxjs";
import { Injectable } from "@angular/core";

// Api Interactors
import { ApiInteractors } from "src/app/models/interactors/api.interactor";

// Enums
import { Project } from "src/app/models/environments/project.enum";

// Environments
import { environment } from "src/environments/environment";

// Models
import { CountryBlockResponse } from "src/app/modules/country-block/models/country-block-response.model";

// Utilities
import { supportedMarketsList } from "src/app/modules/multi-languages/utilities/languages.utilities";

@Injectable({
  providedIn: "root",
})
export class CountryBlockService {
  // API Interactions
  apiInteractor: ApiInteractors;

  // Strings
  languageCode: string = environment.defaultLanguage;

  // Arrays
  acceptedLanguagesList: string[] = [];

  constructor(private httpClient: HttpClient) {
    this.apiInteractor = new ApiInteractors(this.httpClient);

    this.acceptedLanguagesList = supportedMarketsList();
  }

  // -----------------------------------------------------------------
  // Get Methods
  onGetCountryBlock(): Observable<CountryBlockResponse> {
    return this.apiInteractor
      .get<null, CountryBlockResponse>(
        `/ajax/country/countryblock`,
        null,
        Project.Shotz
      )
      .pipe(
        map((countryBlockResponse: CountryBlockResponse) => {
          return countryBlockResponse;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
}
