import { BehaviorSubject } from "rxjs";
// Environments
import { environment } from "src/environments/environment";
// Libraries
import { addScriptDynamically, } from "livespins/@livespins";
// Selectors
import { selectLanguageCode } from "src/app/modules/multi-languages/store/selectors/languages.selectors";
import { selectAuthLoginIsLoggedIn } from "src/app/modules/auth/store/selectors/auth.selectors";
import * as i0 from "@angular/core";
import * as i1 from "../../multi-languages/services/multi-language.service";
import * as i2 from "../../auth/services/session.service";
import * as i3 from "@ngrx/store";
export class LivespinsService {
    constructor(multiLanguageService, sessionService, store) {
        this.multiLanguageService = multiLanguageService;
        this.sessionService = sessionService;
        this.store = store;
        // Strings
        this.languageCode = "";
        // Booleans
        this.isInitStarted = false;
        // Subjects
        this.clientSubject = new BehaviorSubject(null);
        this.sdkSubject = new BehaviorSubject(null);
        this.languageCode = this.multiLanguageService.getLanguageCode();
        this.languageCodeSubscription = this.store
            .select(selectLanguageCode)
            .subscribe((languageCode) => {
            this.languageCode = languageCode;
        });
    }
    // ----------------------------------------------------------
    // Get Methods
    get client() {
        return this.clientSubject.value;
    }
    get sdk() {
        return this.sdkSubject.value;
    }
    // ----------------------------------------------------------
    // Set Methods
    onInitialiseLivespinsSDK() {
        // This can be called multiple times, but we only need to initialise the
        // client once.
        this.isInitStarted = true;
        document.addEventListener("LIVESPINS_LOADED", () => {
            console.log("Livespins SDK module ready to be used 💪", window.livespins);
            let tenant = "";
            switch (this.languageCode) {
                case "fi-fi":
                case "en-nz":
                case "en-ca": {
                    tenant = "HIREE";
                    break;
                }
                default: {
                    tenant = "HIR";
                    break;
                }
            }
            let livespinsConfigurations = Object.assign({}, environment.livespins.configurations, { tenant });
            const livespinsSDK = window.livespins.sdk(Object.assign({}, livespinsConfigurations));
            this.sdkSubject.next(livespinsSDK);
            livespinsSDK.Client().then((client) => {
                console.log({ client });
                this.clientSubject.next(client);
            });
        });
        addScriptDynamically("https://assets.livespins.com/sdk/js/livespins.js");
    }
    onInitialise() {
        if (!this.isInitStarted && this.sessionService.getIsUserLoggedIn()) {
            this.onInitialiseLivespinsSDK();
        }
        this.subscription = this.store
            .select(selectAuthLoginIsLoggedIn)
            .subscribe((isLoggedIn) => {
            if (!this.isInitStarted && isLoggedIn) {
                this.onInitialiseLivespinsSDK();
            }
        });
    }
    // -----------------------------------------------------------------
    // On Destroy
    ngOnDestroy() {
        if (this.languageCodeSubscription)
            this.languageCodeSubscription.unsubscribe();
        if (this.subscription)
            this.subscription.unsubscribe();
    }
}
// Others
LivespinsService.langCodeToLivespinsLanguageCode = {
    "en-row": "en",
    "en-ca": "en",
    "en-nz": "en",
    "en-eu": "en",
    "de-de": "de",
    "nb-no": "no",
    "fi-fi": "fi",
    "hu-hu": "hu",
};
LivespinsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LivespinsService_Factory() { return new LivespinsService(i0.ɵɵinject(i1.MultiLanguageService), i0.ɵɵinject(i2.SessionService), i0.ɵɵinject(i3.Store)); }, token: LivespinsService, providedIn: "root" });
