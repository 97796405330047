// Actions
import { languageCodeLoaded } from "src/app/modules/multi-languages/store/actions/languages.actions";
// API Interactors
import { ApiInteractors } from "src/app/models/interactors/api.interactor";
// Configurations
import { localStorageKeys } from "src/app/modules/multi-languages/configurations/localstorage-keys.configurations";
import { userProfileRegisteredLanguageConfigurations, localToCmsLanguageConfigurations, } from "src/app/configurations/main.configurations";
// Environments
import { environment } from "src/environments/environment";
// Selectors
import { selectLanguageCode } from "src/app/modules/multi-languages/store/selectors/languages.selectors";
import { selectAuthProfileBalance } from "src/app/modules/user/store/selectors/profile.selectors";
// Utilities
import { getQueryParams } from "src/app/modules/shared/utilities/query-parameter.utilities";
import * as i0 from "@angular/core";
import * as i1 from "../../multi-languages/services/translation.service";
import * as i2 from "../../user/services/user-details.service";
import * as i3 from "./affiliate.service";
import * as i4 from "../../shared/services/common.service";
import * as i5 from "../../shared/services/gtm.service";
import * as i6 from "@angular/common/http";
import * as i7 from "@ngrx/store";
import * as i8 from "@angular/router";
export class LoginService {
    constructor(translationService, userDetailsService, affiliateService, commonService, gtmService, httpClient, store, router) {
        this.translationService = translationService;
        this.userDetailsService = userDetailsService;
        this.affiliateService = affiliateService;
        this.commonService = commonService;
        this.gtmService = gtmService;
        this.httpClient = httpClient;
        this.store = store;
        this.router = router;
        // Strings
        this.userCountryLanguage = "";
        this.userCountryCode = "";
        this.languageCode = "";
        this.apiInteractor = new ApiInteractors(this.httpClient);
        this.subscription = this.store
            .select(selectLanguageCode)
            .subscribe((languageCode) => {
            this.languageCode = languageCode;
        });
    }
    // -----------------------------------------------------------------
    // Get Methods
    getDecodedCurrentPath() {
        return decodeURIComponent(window.location.pathname);
    }
    // -----------------------------------------------------------------
    // Set Methods
    onLoginSuccessGtmEvents(loginResponse) {
        setTimeout(() => {
            this.userCountryCode = this.commonService.getUserCountry();
            if (this.userCountryCode) {
                this.gtmService.onGtmDataLayerEvent({
                    event: "logged_in",
                    userId: loginResponse.user.id,
                    country: this.userCountryCode,
                    userType: loginResponse.ecrCategory,
                });
            }
        }, 1000);
    }
    onNavigateToUserProfileLanguage() {
        this.userProfileLanguageSubscription = this.store
            .select(selectAuthProfileBalance)
            .subscribe((profileBalance) => {
            if (profileBalance &&
                profileBalance.profile &&
                profileBalance.profile.language) {
                this.userCountryLanguage =
                    userProfileRegisteredLanguageConfigurations[profileBalance.profile.language];
                let userLanguage = this.userCountryLanguage
                    ? this.userCountryLanguage
                    : environment.defaultLanguage;
                const params = getQueryParams();
                if (userLanguage !== this.languageCode) {
                    this.userDetailsService.onSetUserLanguageCode(userLanguage);
                    this.store.dispatch(languageCodeLoaded({
                        language: localToCmsLanguageConfigurations[userLanguage],
                        languageCode: userLanguage,
                    }));
                    this.translationService.onUseLanguage(localToCmsLanguageConfigurations[this.languageCode]);
                    localStorage.setItem(localStorageKeys.languageCode, userLanguage);
                    const navigateAfterLogin = this.commonService.getNavigateAfterLogin();
                    if (navigateAfterLogin &&
                        navigateAfterLogin.type === "url" &&
                        navigateAfterLogin.data.path != "") {
                        this.affiliateService.onNavigateToLandingPage(params, userLanguage, navigateAfterLogin.data.path);
                    }
                    else {
                        this.affiliateService.onNavigateToLandingPage(params, userLanguage);
                    }
                }
                // If the user language is the same as the landing page market
                /*
                  
                  This code was implemented mainly for VIP / Group ID
                  The reas
      
                */
                this.onEmptyNavigationAfterLogin();
                setTimeout(() => {
                    if (params.ignoreRefresh !== "ignoreRefresh") {
                        window.location.reload();
                    }
                });
            }
        });
    }
    onEmptyNavigationAfterLogin() {
        let navigateData = this.commonService.getNavigateAfterLogin();
        if (navigateData &&
            (navigateData.type === "view" ||
                (navigateData.type === "url" &&
                    navigateData.data.navigateTo != "promotions"))) {
            this.commonService.onSetNavigateAfterLogin(undefined);
        }
    }
    onVerifyIsLandingPage() {
        const path = this.getDecodedCurrentPath();
        let params = {};
        if (window.location.search) {
            params = JSON.parse(`{"${decodeURI(window.location.search
                .substring(1)
                .replace(/&/g, '","')
                .replace(/=/g, '":"'))}}`);
        }
        const regFirst = /^[a-z]{2}-[a-z]{2}$/;
        const regSecond = /^[a-z]{2}-[a-z]{3}$/;
        let segmentsList = path.split("/");
        let languageCodeFromURL = this.getDecodedCurrentPath().split("/")[1];
        if (segmentsList &&
            segmentsList.length <= 2 &&
            (regFirst.test(languageCodeFromURL) ||
                regSecond.test(languageCodeFromURL))) {
            if (sessionStorage.getItem("isUserVistedLandingPage")) {
                if (params) {
                    this.router.navigate([path], { queryParams: params });
                }
                else {
                    this.router.navigate([path]);
                }
            }
        }
    }
    // -----------------------------------------------------------------
    // On Destroy
    ngOnDestroy() {
        if (this.userProfileLanguageSubscription)
            this.userProfileLanguageSubscription.unsubscribe();
        if (this.subscription)
            this.subscription.unsubscribe();
    }
}
LoginService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LoginService_Factory() { return new LoginService(i0.ɵɵinject(i1.TranslationService), i0.ɵɵinject(i2.UserDetailsService), i0.ɵɵinject(i3.AffiliateService), i0.ɵɵinject(i4.CommonService), i0.ɵɵinject(i5.GtmService), i0.ɵɵinject(i6.HttpClient), i0.ɵɵinject(i7.Store), i0.ɵɵinject(i8.Router)); }, token: LoginService, providedIn: "root" });
