import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { Store } from "@ngrx/store";

// Configurations
import { mixPanelEventsConfigurations } from "src/app/configurations/main.configurations";

// Models
import { NavigationAfterLogin } from "src/app/modules/shared/models/navigation/navigate-after-login.model";
import { KYCDetailsToUnblock } from "src/app/modules/kyc/models/kyc-details/kyc-details-to-unblock.model";
import { TrackGameEvent } from "src/app/modules/shared/models/mix-panel/track-game-event.model";
import { KycLevelDetails } from "src/app/modules/auth/models/kyc-level-details.model";
import { GamePregmatic } from "src/app/modules/game-groups/models/game.model";
import { QueryParams } from "src/app/modules/shared/models/parameters.model";
import { ActiveTab } from "src/app/modules/shared/models/active-tab.model";
import {
  NavigationRoute,
  NavigationData,
} from "src/app/modules/shared/models/navigation/navigation.model";

// Reducers
import { AppState } from "src/app/store/reducers";

// Selectors
import { selectLanguageCode } from "src/app/modules/multi-languages/store/selectors/languages.selectors";
import { selectAuthLoginIsLoggedIn } from "src/app/modules/auth/store/selectors/auth.selectors";

// Services
import { MultiLanguageService } from "src/app/modules/multi-languages/services/multi-language.service";
import { PayNPlayCashierService } from "src/app/modules/account/services/pay-n-play-cashier.service";
import { TranslationService } from "src/app/modules/multi-languages/services/translation.service";
import { GamePlayService } from "src/app/modules/game-groups/services/game-play.service";
import { MixPanelService } from "src/app/modules/shared/services/mix-panel.service";
import { CashierService } from "src/app/modules/account/services/cashier.service";
import { UtilityService } from "src/app/modules/shared/services/utility.service";
import { CommonService } from "src/app/modules/shared/services/common.service";
import { SessionService } from "src/app/modules/auth/services/session.service";
import { KYCService } from "src/app/modules/kyc/services/kyc.service";

@Injectable({
  providedIn: "root",
})
export class DeepLinksService {
  // Strings
  languageCode: string = "";
  serverError: string = "";

  // Booleans
  isButtonLoader: boolean = false;
  isLoggedIn: boolean = false;
  isPassword: boolean = true;

  // Subscriptions
  userKycLevelOpenAccountsSubscription: Subscription;
  kycSubscription: Subscription;

  subscriptions: Subscription[] = [];

  constructor(
    private payNPlayCashierService: PayNPlayCashierService,
    private multiLanguageService: MultiLanguageService,
    private translationService: TranslationService,
    private gamePlayService: GamePlayService,
    private mixPanelService: MixPanelService,
    private utilityService: UtilityService,
    private sessionService: SessionService,
    private cashierService: CashierService,
    private commonService: CommonService,
    private kycService: KYCService,
    private store: Store<AppState>,
    private router: Router
  ) {
    this.languageCode = this.multiLanguageService.getLanguageCode();

    this.isLoggedIn = this.sessionService.getIsUserLoggedIn();

    this.subscriptions = [
      this.store
        .select(selectLanguageCode)
        .subscribe((languageCode: string) => {
          this.languageCode = languageCode;
        }),
      this.store
        .select(selectAuthLoginIsLoggedIn)
        .subscribe((isLoggedIn: boolean) => {
          this.isLoggedIn = isLoggedIn;
        }),
    ];
  }

  // -----------------------------------------------------------------
  // Set Methods
  /*
    Below complete functioanlity deals with deep links before login
    basically when user try to access few part of product without login
    we useful ask user to force login before we ask for force login
    we store it's related data in service data layer & reuse it after successful
    login so that user lands on exact place where we preferred to be before login
  */
  onNavigateAfterLogin(): void {
    const navigateAfterLogin: NavigationRoute =
      this.commonService.getNavigateAfterLogin();

    if (navigateAfterLogin && navigateAfterLogin.type === "url") {
      this.onNavigateByUrlData(navigateAfterLogin);
    } else if (navigateAfterLogin && navigateAfterLogin.type === "view") {
      this.onNavigateByViewData(navigateAfterLogin);
    } else if (this.utilityService.getDecodedCurrentPath()) {
      this.onNavigationCurrentRoute();
    }
  }

  onNavigateByUrlData(navigateAfterLogin: NavigationRoute): void {
    if (navigateAfterLogin.data.navigateTo === "lobby") {
      this.commonService.onBroadcastNavigationData(navigateAfterLogin.data);

      if (navigateAfterLogin.data.group) {
        this.router.navigate([
          `${this.languageCode}/casino/${navigateAfterLogin.data.group}`,
        ]);
      }
    }

    if (navigateAfterLogin.data.navigateTo === "gameWindow") {
      this.gamePlayService.onSetGameCalledFrom("realgame");

      this.onSendMixPanelEvent(navigateAfterLogin.data);

      this.router.navigate([
        `${this.languageCode}/${this.translationService.get("url.game")}/${
          navigateAfterLogin.data.path
        }`,
      ]);
    }

    if (navigateAfterLogin.data.navigateTo === "livespins") {
      this.router.navigate([
        `${this.languageCode}/${navigateAfterLogin.data.navigateTo}`,
      ]);
    }
  }

  onNavigateByViewData(navigateAfterLogin: NavigationRoute): void {
    if (navigateAfterLogin.path === "confirmidentity") {
      this.onKycLevelDetails(navigateAfterLogin);
    } else {
      if (navigateAfterLogin.path === "rewards") {
        this.utilityService.updateActiveLeftMenu("rewards");
      }

      if (navigateAfterLogin.path === "deposit" && navigateAfterLogin.data) {
        this.cashierService.onSetActiveDepositBonus({
          bonusCode: navigateAfterLogin.data.bonusCode,
        });
      }

      const activeTab: ActiveTab = {
        tabName: navigateAfterLogin.path,
        showBackButton: true,
      };

      this.utilityService.openAccountComponent(activeTab);
    }
  }

  onKycLevelDetails(navigateAfterLogin: NavigationRoute): void {
    this.kycSubscription = this.kycService
      .onGetUserKycLevelDetails()
      .subscribe((kycLevelDetails: KycLevelDetails) => {
        if (!kycLevelDetails.enableKyc) {
          const activeTab: ActiveTab = {
            tabName: "menuOptions",
            showBackButton: true,
          };

          this.commonService.onBroadcastActiveAcountView(activeTab);
        } else {
          const activeTab: ActiveTab = {
            tabName: navigateAfterLogin.path,
            showBackButton: true,
          };

          this.utilityService.openAccountComponent(activeTab);
        }
      });
  }

  onNavigationCurrentRoute(): void {
    const pathName: string = window.location.pathname;

    if (pathName.length <= 7) {
      this.router.navigate([`${this.languageCode}/casino`]);
    } else if (pathName.includes(this.translationService.get("url.game"))) {
      let gamePregmatic: GamePregmatic =
        this.gamePlayService.getCurrentGamePregmatic() as GamePregmatic;

      if (gamePregmatic && gamePregmatic.gameType) {
        gamePregmatic.gameType = "realgame";
      }

      this.gamePlayService.onSetGameCalledFrom("realgame");

      this.gamePlayService.onSetCurrentGamePregmatic(gamePregmatic);

      this.gamePlayService.onBroadcastIsRelaunchGame(true);
    }
  }

  onSendMixPanelEvent(navigationData: NavigationData): void {
    if (Object.keys(navigationData).length > 0) {
      let eventProperties: TrackGameEvent = {
        name: navigationData.path,
        playType: "realgame",
        location: navigationData.gameGroupName,
        category: navigationData.lobbyName,
      };

      if (this.mixPanelService.getVerticalByUrl()) {
        eventProperties.vertical = this.mixPanelService.getVerticalByUrl();
      }

      this.mixPanelService.onTrackMixPanelEvents(
        mixPanelEventsConfigurations.launched_game,
        eventProperties
      );
    }
  }

  onNavigatePromotionQueryParameters(path: string): void {
    let navigationAfterLogin: NavigationAfterLogin = {
      type: "url",
      data: {
        navigateTo: "promotions",
        path: path,
      },
    };

    this.commonService.onSetNavigateAfterLogin(navigationAfterLogin);

    this.router.navigate([
      `${this.languageCode}/${this.translationService.get("url.promotions")}`,
    ]);
  }

  onNavigateGamePlayWindowQP(path: string): void {
    let navigationAfterLogin: NavigationAfterLogin = {
      type: "url",
      data: {
        navigateTo: "gameWindow",
        path: path.split("/")[3],
      },
    };

    this.commonService.onSetNavigateAfterLogin(navigationAfterLogin);

    this.router.navigate([`${this.languageCode}/casino`]);
  }

  onNavigateKycLevelQueryParameters(queryParams: QueryParams): void {
    this.userKycLevelOpenAccountsSubscription = this.kycService
      .onGetUserKycLevelDetails()
      .subscribe((kycDetailsToUnblock: KYCDetailsToUnblock) => {
        if (!kycDetailsToUnblock.enableKyc) {
          const activeTab: ActiveTab = {
            tabName: "menuOptions",
            showBackButton: true,
          };

          this.commonService.onBroadcastActiveAcountView(activeTab);
        } else {
          const activeTab: ActiveTab = {
            tabName: queryParams.tab,
            showBackButton: true,
          };

          this.utilityService.openAccountComponent(activeTab);
        }
      });
  }

  onNavigateLoggedInQP(queryParams: QueryParams): void {
    if (
      queryParams.tab === "deposit" &&
      queryParams.hasOwnProperty("bonusCode")
    ) {
      this.cashierService.onSetActiveDepositBonus({
        bonusCode: queryParams.bonusCode,
      });
    }

    if (queryParams.tab === "deposit") {
      this.utilityService.initiateDeposit(true);
    } else if (queryParams.tab === "withdrawal") {
      this.utilityService.initiateWithdraw();
    } else {
      const activeTab: ActiveTab = {
        tabName: queryParams.tab,
        showBackButton: true,
      };

      this.utilityService.openAccountComponent(activeTab);
    }
  }

  onNavigateToLoggedOutQP(queryParams: QueryParams): void {
    if (queryParams.tab === "deposit") {
      this.onNavigateToDeposit(queryParams);
    } else {
      this.utilityService.initiateLogin();

      let navigationAfterLogin: NavigationAfterLogin = {
        type: "view",
        path: queryParams.tab,
      };

      this.commonService.onSetNavigateAfterLogin(navigationAfterLogin);
    }
  }

  /*
    Below handler will be called from deep with deposit as tab value and
    navigate to deposit based on user market where he is..
  */
  onNavigateToDeposit(queryParams: QueryParams): void {
    if (this.utilityService.isPnpFlow()) {
      this.payNPlayCashierService.onBroadcastInitiateDeposit({
        callingFrom: "deepLink",
      });
    } else {
      const activeTab: ActiveTab = {
        tabName: "login",
        showBackButton: true,
      };

      this.utilityService.openAccountComponent(activeTab);

      let navigationAfterLogin: NavigationAfterLogin = {
        type: "view",
        path: queryParams.tab,
      };

      if (
        queryParams.tab === "deposit" &&
        queryParams.hasOwnProperty("bonusCode")
      ) {
        navigationAfterLogin.data = { bonusCode: queryParams.bonusCode };
      }

      this.commonService.onSetNavigateAfterLogin(navigationAfterLogin);
    }
  }

  onNavigateLobbyGameGroupsQuery(queryParams: QueryParams): void {
    let navigationAfterLogin: NavigationAfterLogin = {
      type: "url",
      data: {
        navigateTo: "lobby",
      },
    };

    navigationAfterLogin.data.lobby = queryParams.lobby;

    if (queryParams.hasOwnProperty("group")) {
      navigationAfterLogin.data.group = queryParams.group;
    }

    if (!this.isLoggedIn) {
      this.utilityService.initiateLogin();

      this.commonService.onSetNavigateAfterLogin(navigationAfterLogin);
    } else {
      this.commonService.onBroadcastNavigationData(navigationAfterLogin.data);

      if (navigationAfterLogin.data.group) {
        this.router.navigate([
          `${this.languageCode}/casino/${queryParams.group}`,
        ]);
      }
    }
  }

  // -----------------------------------------------------------------
  // On Destroy
  ngOnDestroy(): void {
    if (this.userKycLevelOpenAccountsSubscription)
      this.userKycLevelOpenAccountsSubscription.unsubscribe();

    if (this.kycSubscription) this.kycSubscription.unsubscribe();

    this.subscriptions.forEach((subscription: Subscription) =>
      subscription.unsubscribe()
    );
  }
}
