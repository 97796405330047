<ng-container *ngIf="isLiveSpinsEnabled">
  <div class="game-group__container">
    <div class="game-group--title">
      <ng-container
        *ngTemplateOutlet="streamsTemplate"
      ></ng-container>
    </div>

    <div class="swiper__outer hoverspace">
      <div class="swiper__inner swiper__full">
        <swiper
          id="livespins-swiper"
          class="mySwiper swiper-control"
          [config]="livespinsSwiper"
          watchSlidesProgress
        >
          <ng-container
            *ngIf="(liveStream$ | async).length > 0"
          >
            <ng-container
              *ngFor="
                let item of liveStream$ | async;
                trackBy: getLivespinsStreamsTrackBy
              "
            >
              <app-csn-livespins-room
                (click)="onPlay(item)"
                [liveStream]="item"
              ></app-csn-livespins-room>
            </ng-container>
          </ng-container>

          <ng-container
            *ngIf="
              (liveScheduledStream$ | async).length > 0
            "
          >
            <ng-container
              *ngFor="
                let item of liveScheduledStream$ | async
              "
            >
              <app-csn-livespins-schedule-room
                [livespinsScheduleRoom]="item"
              ></app-csn-livespins-schedule-room>
            </ng-container>
          </ng-container>
        </swiper>
      </div>
    </div>
  </div>
</ng-container>

<!-- 
  ------------------------------------------------------
  
  Templates 
  
  ------------------------------------------------------
-->
<!-- Livespins Streams Template -->
<ng-template #streamsTemplate>
  <ng-container *ngIf="hasStreams">
    <span class="livespins-link">
      <img
        src="/assets/img/livespins.svg"
        alt="Livespins"
        title="Livespins"
        width="auto"
        height="18"
      />
    </span>
  </ng-container>
</ng-template>
