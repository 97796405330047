<div
  class="form-group {{config.class}}"
  [formGroup]="formGroup"
  [class.has-label]="config.lableDisplay === true"
  *ngIf="(isUsedAccount && config.visibleOnRepeatPayment) || (!isUsedAccount && config.visibleOnNewPayment)"
>
  <div class="controls">
    <label *ngIf="config.lableDisplay">
      {{config.lableDisplayText | translate}}
    </label>
    <div class="custom-select">
      <select [formControlName]="config.key">
        <option *ngFor="let option of config.options">
          {{ option }}
        </option>
      </select>
    </div>
  </div>
</div>
