/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./button-loader.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../loader/loader.component.ngfactory";
import * as i3 from "../loader/loader.component";
import * as i4 from "@angular/common";
import * as i5 from "./button-loader.component";
var styles_ButtonLoaderComponent = [i0.styles];
var RenderType_ButtonLoaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ButtonLoaderComponent, data: {} });
export { RenderType_ButtonLoaderComponent as RenderType_ButtonLoaderComponent };
function View_ButtonLoaderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [["class", "componets-loader valign-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-loader", [], null, null, null, i2.View_LoaderComponent_0, i2.RenderType_LoaderComponent)), i1.ɵdid(2, 49152, null, 0, i3.LoaderComponent, [], null, null)], null, null); }
function View_ButtonLoaderComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), i1.ɵncd(null, 0), (_l()(), i1.ɵand(0, null, null, 0))], null, null); }
export function View_ButtonLoaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ButtonLoaderComponent_1)), i1.ɵdid(1, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ButtonLoaderComponent_2)), i1.ɵdid(3, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isLoading; _ck(_v, 1, 0, currVal_0); var currVal_1 = !_co.isLoading; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_ButtonLoaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["setLoader", ""]], null, null, null, View_ButtonLoaderComponent_0, RenderType_ButtonLoaderComponent)), i1.ɵdid(1, 573440, null, 0, i5.ButtonLoaderComponent, [], { setLoader: [0, "setLoader"] }, null)], function (_ck, _v) { var currVal_0 = ""; _ck(_v, 1, 0, currVal_0); }, null); }
var ButtonLoaderComponentNgFactory = i1.ɵccf("[setLoader]", i5.ButtonLoaderComponent, View_ButtonLoaderComponent_Host_0, { setLoader: "setLoader" }, {}, ["*"]);
export { ButtonLoaderComponentNgFactory as ButtonLoaderComponentNgFactory };
