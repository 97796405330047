<ng-container
  *ngIf="countryBlockResponse && !countryBlockResponse.isIpBlocked && !isCountryBlockLoading"
>
  <ng-container
    *marketBasedComponentDisplay="{'flowType': UserFlowTypes.ZIMPLER_FLOW,'langCode':languageCode}"
  >
    <ng-container *ngIf="isNewsTickerBannerAvailable">
      <div class="page-body no-padding">
        <app-news-ticker></app-news-ticker>
      </div>
    </ng-container>
  </ng-container>

  <div class="app-container page-body homepage padding">
    <ng-container *ngIf="languageCode">
      <app-custom-banner
        class="clearfix"
        [zoneId]="(zimplerMarketsList.indexOf(languageCode) <= -1) ? activeLobby : 'casino_banner_HIR'"
      ></app-custom-banner>
    </ng-container>

    <ng-container *ngIf="!languageCode">
      <div class="stz-banner clearfix">
        <div class="stz-banner__item">
          <div class="mobile-banner">
            <div class="banner-media skeleton-loader"></div>
          </div>
          <div class="desktop-banner">
            <div class="banner-body">
              <div class="banner-media skeleton-loader"></div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <app-game-lobby
      class="clearfix"
      [callingFrom]="'casinoLobby'"
      (callOnSelectedLobby)="onSelectedLobby($event)"
    ></app-game-lobby>

    <app-game-groups-display
      class="clearfix"
      *ngIf="activeLobby && activeLobby !=='all'"
      [lobby]="activeLobby"
      class="game-group"
    ></app-game-groups-display>

    <app-all-games
      class="clearfix"
      *ngIf="activeLobby && activeLobby ==='all'"
      [activeLobby]="activeLobby"
    ></app-all-games>
  </div>
</ng-container>
