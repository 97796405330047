import { Observable, Subject } from "rxjs";
import { Injectable } from "@angular/core";

// Configurations
import { localStorageKeys } from "src/app/modules/multi-languages/configurations/localstorage-keys.configurations";

// Models
import { LimitsResponse } from "src/app/modules/limits/models/limits-response.model";
import { SocketDetails } from "src/app/modules/auth/models/socket-details.model";
import { SessionLimit } from "src/app/modules/auth/models/session-limit.model";

// Services
import { LimitsService } from "src/app/modules/limits/services/limits.service";

@Injectable({
  providedIn: "root",
})
export class SessionService {
  // Strings
  userCountryLanguage: string = "";
  languageCode: string = "";

  // Objects
  socketDetails: SocketDetails;

  // Intervals
  sessionLimitInterval: NodeJS.Timer;

  private sessionTimeUpdatedSubject: Subject<boolean> = new Subject<boolean>();
  public sessionTimeUpdatedSubject$: Observable<boolean> =
    this.sessionTimeUpdatedSubject.asObservable();

  constructor(private limitsService: LimitsService) {}

  // -----------------------------------------------------------------
  // Get Methods
  getIsUserLoggedIn(): boolean {
    const isUserLoggedIn: boolean = JSON.parse(
      localStorage.getItem(localStorageKeys.stz_user)
    );

    return isUserLoggedIn ? true : false;
  }

  // -----------------------------------------------------------------
  // Set Methods
  onSetSessionVariable(sessionKey: string, sessionLimit: SessionLimit): void {
    sessionStorage.setItem(sessionKey, JSON.stringify(sessionLimit));
  }

  onSessionContinue(): void {
    let session: SessionLimit = JSON.parse(sessionStorage.getItem("session"));

    if (session && session.sessionLimit) {
      this.onSetSessionVariable("session", {
        sessionLimit: session.sessionLimit,
        availableSessionLimit: session.sessionLimit * 60,
      });

      this.onStartLoginSession();
    } else {
      this.limitsService
        .onGetResponsibleGamingLimits("session")
        .subscribe((limitsResponse: LimitsResponse) => {
          if (
            limitsResponse &&
            limitsResponse.limits &&
            limitsResponse.limits.overall
          ) {
            let limits: number = limitsResponse.limits.overall.value;

            this.onSetSessionVariable("session", {
              sessionLimit: limits,
              availableSessionLimit: limits * 60,
            });

            this.onStartLoginSession();
          }
        });
    }
  }

  onBroadcastSessionUpdated(isSessionTimeUpdated: boolean): void {
    this.sessionTimeUpdatedSubject.next(isSessionTimeUpdated);
  }

  onStartLoginSession(): void {
    this.onBroadcastSessionUpdated(true);

    if (!this.sessionLimitInterval) {
      this.sessionLimitInterval = setInterval(() => {
        let session: SessionLimit = JSON.parse(
          sessionStorage.getItem("session")
        );

        if (session) {
          if (session.availableSessionLimit - 1 > 0) {
            session.availableSessionLimit = session.availableSessionLimit - 1;

            sessionStorage.setItem("session", JSON.stringify(session));
          } else {
            clearInterval(this.sessionLimitInterval);

            this.sessionLimitInterval = undefined;

            setTimeout(() => {
              document.getElementById("sessionPopup").style.display = "flex";

              document.body.classList.add("overflow-hidden");
            }, 100);
          }
        } else {
          clearInterval(this.sessionLimitInterval);

          this.sessionLimitInterval = undefined;
        }
      }, 1000);
    }
  }

  /*
    Information related to socket is stored her
    we use this data in socket service ts file
  */
  onSetSocketDetails(socketDetails: SocketDetails): void {
    this.socketDetails = socketDetails;
  }
}
