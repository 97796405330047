/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./csn-livespins-room.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./csn-livespins-room.component";
var styles_CsnLivespinsRoomComponent = [i0.styles];
var RenderType_CsnLivespinsRoomComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CsnLivespinsRoomComponent, data: {} });
export { RenderType_CsnLivespinsRoomComponent as RenderType_CsnLivespinsRoomComponent };
export function View_CsnLivespinsRoomComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "csn-livespins-room", [], [[8, "liveStream", 0], [8, "cta", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.liveStream; var currVal_1 = true; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_CsnLivespinsRoomComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-csn-livespins-room", [], null, null, null, View_CsnLivespinsRoomComponent_0, RenderType_CsnLivespinsRoomComponent)), i1.ɵdid(1, 573440, null, 0, i2.CsnLivespinsRoomComponent, [], null, null)], null, null); }
var CsnLivespinsRoomComponentNgFactory = i1.ɵccf("app-csn-livespins-room", i2.CsnLivespinsRoomComponent, View_CsnLivespinsRoomComponent_Host_0, { liveStream: "liveStream" }, {}, []);
export { CsnLivespinsRoomComponentNgFactory as CsnLivespinsRoomComponentNgFactory };
