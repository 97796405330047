<main class="vp page-body">
  <div class="vp__header padding" [class.vp__header--bg]="isPageHeaderBg">
    <button
      *ngIf="deviceType === 'desktop'"
      aria-label="Go back"
      class="btn btn-circle btn-secondary"
      (click)="onGoBack()"
    >
      <i class="icon-chevron-left"></i>
    </button>

    <div class="vp__header--title">
      {{ providerName }}
    </div>

    <button
      *ngIf="deviceType === 'desktop'"
      aria-label="A-Z"
      class="btn btn-secondary btn-sm btn-round"
      [class.active]="sortedBy === 'a-z'"
      (click)="onSortByGameName()"
    >
      {{ 'gamegroup.A_Z' | translate}}
    </button>

    <button
      *ngIf="deviceType === 'mobile'"
      aria-label="Close"
      class="btn btn-circle btn-secondary"
      (click)="onGoBack()"
    >
      <i class="icon-close"></i>
    </button>
  </div>

  <div class="vp__body padding">
    <section class="vp__game-container clearfix">
      <div
        class="game-card casino"
        *ngFor="let game of filteredGamesList; let i = index"
      >
        <app-game-card
          [gameData]="game"
          [game_asset_style]="'default-casino'"
          [callingFrom]="'studios'"
        >
        </app-game-card>
        <div class="game-card__footer">
          <div class="game-title">{{game.name}}</div>
        </div>
      </div>
      <ng-container *ngIf="isLoading">
        <div
          class="game-card casino"
          *ngFor="let number of [].constructor(15);let i=index"
        >
          <div class="game-card__body">
            <div class="game-card__mask skeleton-loader"></div>
          </div>
          <div class="game-card__footer">
            <div class="game-title skeleton-loader round-skelton"></div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="filteredGamesList && filteredGamesList.length === 0">
        <div class="no-results text-center">
          {{'gamegroup.nogame_found' | translate}}
        </div>
      </ng-container>
    </section>
  </div>
</main>
