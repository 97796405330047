/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./time.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./time.component";
import * as i4 from "@ngrx/store";
var styles_TimeComponent = [i0.styles];
var RenderType_TimeComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_TimeComponent, data: {} });
export { RenderType_TimeComponent as RenderType_TimeComponent };
export function View_TimeComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "current-time"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.formattedDate; _ck(_v, 1, 0, currVal_0); }); }
export function View_TimeComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-time", [], null, null, null, View_TimeComponent_0, RenderType_TimeComponent)), i1.ɵprd(512, null, i2.DatePipe, i2.DatePipe, [i1.LOCALE_ID]), i1.ɵdid(2, 245760, null, 0, i3.TimeComponent, [i4.Store, i2.DatePipe], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var TimeComponentNgFactory = i1.ɵccf("app-time", i3.TimeComponent, View_TimeComponent_Host_0, {}, {}, []);
export { TimeComponentNgFactory as TimeComponentNgFactory };
