/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./all-rewards.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "../cashback-promo-reward/cashback-promo-reward.component.ngfactory";
import * as i4 from "../cashback-promo-reward/cashback-promo-reward.component";
import * as i5 from "../../../account/services/pay-n-play-cashier.service";
import * as i6 from "../../services/cashback-promo.service";
import * as i7 from "../../../user/services/user-details.service";
import * as i8 from "../../../shared/services/utility.service";
import * as i9 from "../free-spin-reward-card/free-spin-reward-card.component.ngfactory";
import * as i10 from "../free-spin-reward-card/free-spin-reward-card.component";
import * as i11 from "../../../multi-languages/services/translation.service";
import * as i12 from "../../../game-groups/services/game-play.service";
import * as i13 from "@ngrx/store";
import * as i14 from "@angular/router";
import * as i15 from "../deposit-reward-card/deposit-reward-card.component.ngfactory";
import * as i16 from "../deposit-reward-card/deposit-reward-card.component";
import * as i17 from "../../../account/services/cashier.service";
import * as i18 from "../unclaimed-free-spin-reward-card/unclaimed-free-spin-reward-card.component.ngfactory";
import * as i19 from "../unclaimed-free-spin-reward-card/unclaimed-free-spin-reward-card.component";
import * as i20 from "../../../chat/services/live-chat.service";
import * as i21 from "../../services/reward.service";
import * as i22 from "../upcoming-reward-card/upcoming-reward-card.component.ngfactory";
import * as i23 from "../upcoming-reward-card/upcoming-reward-card.component";
import * as i24 from "@angular/common";
import * as i25 from "../../../shared/components/loader/loader.component.ngfactory";
import * as i26 from "../../../shared/components/loader/loader.component";
import * as i27 from "../../../navigation/components/side-menu-modal/side-menu-modal.component.ngfactory";
import * as i28 from "../../../navigation/components/side-menu-modal/side-menu-modal.component";
import * as i29 from "../../../multi-languages/services/multi-language.service";
import * as i30 from "./all-rewards.component";
var styles_AllRewardsComponent = [i0.styles];
var RenderType_AllRewardsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AllRewardsComponent, data: {} });
export { RenderType_AllRewardsComponent as RenderType_AllRewardsComponent };
function View_AllRewardsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "p", [["class", "leadtitle m-b-12 text-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("rewards.all_rewards_leadtext")); _ck(_v, 2, 0, currVal_0); }); }
function View_AllRewardsComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-cashback-promo-reward", [], null, [[null, "callOpenRewardTCPopUp"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("callOpenRewardTCPopUp" === en)) {
        var pd_0 = (_co.onOpenRewardTCPopUp($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_CashbackPromoRewardComponent_0, i3.RenderType_CashbackPromoRewardComponent)), i1.ɵdid(2, 770048, null, 0, i4.CashbackPromoRewardComponent, [i5.PayNPlayCashierService, i6.CashbackPromoService, i7.UserDetailsService, i8.UtilityService], { bonusDetails: [0, "bonusDetails"], callingFrom: [1, "callingFrom"], rewardSize: [2, "rewardSize"] }, { callOpenRewardTCPopUp: "callOpenRewardTCPopUp" })], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit; var currVal_1 = "all-rewards"; var currVal_2 = "basic"; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_AllRewardsComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-free-spin-reward-card", [], null, [[null, "callOpenRewardTCPopUp"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("callOpenRewardTCPopUp" === en)) {
        var pd_0 = (_co.onOpenRewardTCPopUp($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i9.View_FreeSpinRewardCardComponent_0, i9.RenderType_FreeSpinRewardCardComponent)), i1.ɵdid(2, 770048, null, 0, i10.FreeSpinRewardCardComponent, [i7.UserDetailsService, i11.TranslationService, i12.GamePlayService, i8.UtilityService, i13.Store, i14.Router], { bonusDetails: [0, "bonusDetails"], callingFrom: [1, "callingFrom"] }, { callOpenRewardTCPopUp: "callOpenRewardTCPopUp" })], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit; var currVal_1 = "all-rewards"; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_AllRewardsComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-deposit-reward-card", [], null, [[null, "callOpenRewardTCPopUp"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("callOpenRewardTCPopUp" === en)) {
        var pd_0 = (_co.onOpenRewardTCPopUp($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i15.View_DepositRewardCardComponent_0, i15.RenderType_DepositRewardCardComponent)), i1.ɵdid(2, 770048, null, 0, i16.DepositRewardCardComponent, [i5.PayNPlayCashierService, i7.UserDetailsService, i8.UtilityService, i17.CashierService, i13.Store], { bonusDetails: [0, "bonusDetails"], callingFrom: [1, "callingFrom"], rewardSize: [2, "rewardSize"] }, { callOpenRewardTCPopUp: "callOpenRewardTCPopUp" })], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit; var currVal_1 = "all-rewards"; var currVal_2 = "basic"; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_AllRewardsComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-unclaimed-free-spin-reward-card", [], null, [[null, "callOnLevelupClaimed"], [null, "callOpenRewardTCPopUp"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("callOnLevelupClaimed" === en)) {
        var pd_0 = (_co.onReloadActiveBonus() !== false);
        ad = (pd_0 && ad);
    } if (("callOpenRewardTCPopUp" === en)) {
        var pd_1 = (_co.onOpenRewardTCPopUp($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i18.View_UnclaimedFreeSpinRewardCardComponent_0, i18.RenderType_UnclaimedFreeSpinRewardCardComponent)), i1.ɵdid(2, 704512, null, 0, i19.UnclaimedFreeSpinRewardCardComponent, [i20.LiveChatService, i21.RewardsService, i8.UtilityService], { bonusDetails: [0, "bonusDetails"] }, { callOpenRewardTCPopUp: "callOpenRewardTCPopUp", callOnLevelupClaimed: "callOnLevelupClaimed" })], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit; _ck(_v, 2, 0, currVal_0); }, null); }
function View_AllRewardsComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-upcoming-reward-card", [], null, null, null, i22.View_UpcomingRewardCardComponent_0, i22.RenderType_UpcomingRewardCardComponent)), i1.ɵdid(2, 573440, null, 0, i23.UpcomingRewardCardComponent, [], { bonusDetails: [0, "bonusDetails"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit; _ck(_v, 2, 0, currVal_0); }, null); }
function View_AllRewardsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 11, null, null, null, null, null, null, null)), i1.ɵdid(2, 16384, null, 0, i24.NgSwitch, [], { ngSwitch: [0, "ngSwitch"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AllRewardsComponent_4)), i1.ɵdid(4, 278528, null, 0, i24.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i24.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AllRewardsComponent_5)), i1.ɵdid(6, 278528, null, 0, i24.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i24.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AllRewardsComponent_6)), i1.ɵdid(8, 278528, null, 0, i24.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i24.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AllRewardsComponent_7)), i1.ɵdid(10, 278528, null, 0, i24.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i24.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AllRewardsComponent_8)), i1.ɵdid(12, 278528, null, 0, i24.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i24.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _v.context.$implicit.bonusTypeFD; _ck(_v, 2, 0, currVal_0); var currVal_1 = "cashback-promotion"; _ck(_v, 4, 0, currVal_1); var currVal_2 = "freeSpin-bonus"; _ck(_v, 6, 0, currVal_2); var currVal_3 = "deposit-bonus"; _ck(_v, 8, 0, currVal_3); var currVal_4 = "unclaimedFreeSpin-bonus"; _ck(_v, 10, 0, currVal_4); var currVal_5 = "upcoming-bonus"; _ck(_v, 12, 0, currVal_5); }, null); }
function View_AllRewardsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "reward-cards__container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AllRewardsComponent_3)), i1.ɵdid(3, 278528, null, 0, i24.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.filteredCampaignDetailsList; _ck(_v, 3, 0, currVal_0); }, null); }
function View_AllRewardsComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "reward-cards__container isloading"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-loader", [], null, null, null, i25.View_LoaderComponent_0, i25.RenderType_LoaderComponent)), i1.ɵdid(3, 49152, null, 0, i26.LoaderComponent, [], null, null), (_l()(), i1.ɵeld(4, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform("rewards.loader_text")); _ck(_v, 5, 0, currVal_0); }); }
function View_AllRewardsComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "reward-cards__container isloading"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("rewards.rewards_not_config")); _ck(_v, 3, 0, currVal_0); }); }
export function View_AllRewardsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "app-side-menu-modal", [], null, null, null, i27.View_SideMenuModalComponent_0, i27.RenderType_SideMenuModalComponent)), i1.ɵdid(1, 770048, null, 0, i28.SideMenuModalComponent, [i29.MultiLanguageService, i8.UtilityService, i13.Store, i14.Router], { sideMenuTitle: [0, "sideMenuTitle"], customClass: [1, "customClass"] }, null), (_l()(), i1.ɵeld(2, 0, null, 1, 8, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AllRewardsComponent_1)), i1.ɵdid(4, 16384, null, 0, i24.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AllRewardsComponent_2)), i1.ɵdid(6, 16384, null, 0, i24.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AllRewardsComponent_9)), i1.ɵdid(8, 16384, null, 0, i24.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AllRewardsComponent_10)), i1.ɵdid(10, 16384, null, 0, i24.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "rewards.rewards"; var currVal_1 = "allRewards"; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = !_co.isLoading; _ck(_v, 4, 0, currVal_2); var currVal_3 = ((!_co.isLoading && _co.filteredCampaignDetailsList) && (_co.filteredCampaignDetailsList.length > 0)); _ck(_v, 6, 0, currVal_3); var currVal_4 = _co.isLoading; _ck(_v, 8, 0, currVal_4); var currVal_5 = ((!_co.isLoading && _co.filteredCampaignDetailsList) && (_co.filteredCampaignDetailsList.length === 0)); _ck(_v, 10, 0, currVal_5); }, null); }
export function View_AllRewardsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-all-rewards", [], null, null, null, View_AllRewardsComponent_0, RenderType_AllRewardsComponent)), i1.ɵdid(1, 245760, null, 0, i30.AllRewardsComponent, [i6.CashbackPromoService, i7.UserDetailsService, i21.RewardsService, i8.UtilityService, i13.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AllRewardsComponentNgFactory = i1.ɵccf("app-all-rewards", i30.AllRewardsComponent, View_AllRewardsComponent_Host_0, {}, {}, []);
export { AllRewardsComponentNgFactory as AllRewardsComponentNgFactory };
