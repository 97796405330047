<div class="game-card__body" *ngIf="gameData"
  (click)="onGameLauncher($event,gameData.name,gameData.hasDemo, isLoggedIn ? 'realgame':'freegame')">
  <div class="game-card__mask">
    <img src="{{gamesUrlPath}}/{{gameData.gameCode}}/{{gameData.gameCode}}{{imageStypeTag}}.jpg{{imgixParams}}"
      alt="{{gameData.name}}" title="{{gameData.name}}" />
  </div>
  <div class="game-card__hover"
    [ngClass]="{'hide': ((callingFrom === 'recent-search' && !isLoggedIn) || (callingFrom === 'game-window-tag' && !isLoggedIn))}">
    <div class="game-card__hover-inside">
      <button aria-label="Play now" class="btn btn-primary btn-play btn-round"
        *ngIf="!(callingFrom === 'recent-search' || callingFrom === 'game-window-tag')" [ngClass]="{
            'btn-xs': (callingFrom === 'recent-search' || callingFrom === 'game-window-tag') || (callingFrom !='poster' && callingFrom !='jackpot-poster'), 
            'btn-sm': callingFrom === 'poster' || callingFrom === 'jackpot-poster-1'}"
        (click)="onGameLauncher($event,gameData.name,gameData.hasDemo, isLoggedIn ? 'realgame':'freegame')">
        {{'general.play_now' | translate}}
      </button>
      <a class="btn-fun" [ngClass]="{
            'btn-xs': (callingFrom === 'recent-search' || callingFrom === 'game-window-tag') || (callingFrom !='poster' && callingFrom !='jackpot-poster-1'), 
            'btn-sm': callingFrom === 'poster' || callingFrom === 'jackpot-poster-1'}"
        *ngIf="gameData['hasDemo'] && isLoggedIn"
        (click)="onGameLauncher($event,gameData.name,gameData.hasDemo,'freegame')">
        {{'general.tryForFun' | translate}} <i class="icon-chevron-right"></i>
      </a>
    </div>
  </div>
  <div class="jackpot-amount" *ngIf="isJackpot && jackpotKeyValue && jackpotKeyValue[gameData.gameCode] > 0">
    <app-jackpot-counter [currencySymbol]="currencySymbol" [digit]="jackpotKeyValue[gameData.gameCode]"
      [duration]="1000"></app-jackpot-counter>
  </div>
</div>