<app-side-menu-modal
  [customClass]="'preference'"
  [sideMenuTitle]="'preference.preferences'"
>
  <!-- modal body -->
  <ng-container sideMenuBody>
    <p class="small-text m-t-8 m-b-24 subscribe-offers--intro-text">
      {{'preference.preferences_text' | translate}}
    </p>
    <div class="alert alert-danger" *ngIf="errorMessageText">
      {{errorMessageText}}
    </div>

    <div class="subscribe-offers" [formGroup]="subscriptionPreferenceForm">
      <div class="subscribe-offers--list">
        <div class="preferences-container">
          <span class="text">
            {{'preference.send_by_email' | translate}}
          </span>
          <span
            for="emailSubscription"
            class="toggleSwitch subscribeToggleSwitch"
            [ngClass]="{
              'active': subscriptionPreferenceForm.controls['emailSubscribed'].value && !(loader.emailSubscription.sub), 
              'preferenceLoader':loader.emailSubscription.sub 
            }"
          >
            <input
              type="checkbox"
              name="emailSubscribed"
              id="emailSubscription"
              formControlName="emailSubscribed"
              [disabled]="loader.emailSubscription.disable"
              (change)="onUpdateSubscriptionPreference('emailSubscription')"
            />
          </span>
        </div>
        <div
          class="form-group clearfix"
          *marketBasedComponentDisplay="{
            'flowType': UserFlowTypes.ZIMPLER_FLOW,
            'langCode':languageCode
          }"
        >
          <div class="controls">
            <span class="icon-prefix"><i class="icon-mail"></i></span>
            <input
              placeholder="{{'preference.enter_email' | translate}}"
              [value]="emailId"
              type="email"
              name="txtEmail"
              autocomplete="off"
              class="preferenceinput"
              disabled
            />
          </div>
        </div>
      </div>

      <div class="clearfix"></div>
      <div class="subscribe-offers--list">
        <div class="preferences-container">
          <span class="text">
            {{'preference.send_by_sms' | translate}}
          </span>
          <span
            for="mobileSubcription"
            class="toggleSwitch subscribeToggleSwitch"
            [ngClass]="{
              'active': subscriptionPreferenceForm.controls['mobileSubscribed'].value && !(loader.mobileSubcription.sub), 
              'preferenceLoader':loader.mobileSubcription.sub
            }"
          >
            <input
              type="checkbox"
              name="mobileSubscribed"
              id="mobileSubcription"
              formControlName="mobileSubscribed"
              [disabled]="loader.mobileSubcription.disable"
              (change)="onUpdateSubscriptionPreference('mobileSubcription')"
            />
          </span>
        </div>
        <div
          class="form-group clearfix"
          *marketBasedComponentDisplay="{
            'flowType': UserFlowTypes.ZIMPLER_FLOW,
            'langCode': languageCode
          }"
        >
          <div class="controls">
            <span class="icon-prefix"><i class="icon-message"></i></span>
            <input
              type="mobile"
              placeholder="{{'preference.enter_phone' | translate}}"
              [value]="mobileNumber"
              name="txtMobile"
              autocomplete="off"
              class="preferenceinput"
              disabled
            />
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</app-side-menu-modal>
