<div class="game-card__body" *ngIf="gameData">
  <div class="showreel-endscreen" *ngIf="isVideoEnded">
    <button
      aria-label="Play"
      class="btn btn-primary btn-sm btn-play btn-round"
      (click)="onGameLaunchHandler(gameData.name,gameData.hasDemo,isLoggedIn ? 'realgame':'freegame')"
    >
      {{'general.play' | translate}}
    </button>

    <button
      aria-label="Try for fun"
      class="btn btn-secondary btn-sm btn-fun btn-round"
      *ngIf="isLoggedIn"
      (click)="onGameLaunchHandler(gameData.name,gameData.hasDemo,'freegame')"
    >
      {{'general.tryForFun' | translate}}
    </button>

    <button
      aria-label="Replay"
      class="btn btn-replay btn-secondary fade-in-up"
      (click)="onPlayAndPausedHandler()"
    >
      <i class="icon-replay"></i>
    </button>
  </div>

  <ng-container *ngIf="gameData.externalAsset && !isVideoEnded">
    <div class="isLoadingVideo" *ngIf="isLoading">
      <app-loader></app-loader>
    </div>

    <div class="playHanderBackdrop" (click)="onPlayAndPausedHandler()">
      <button
        aria-label="Play"
        class="btn btn-play-showreel btn-secondary fade-in"
        *ngIf="this.isPaused && !isLoading"
      >
        <i class="icon-play"></i>
      </button>

      <button
        aria-label="Pause"
        class="btn btn-play-showreel btn-secondary btn-pause fade-in"
        *ngIf="!this.isPaused"
      >
        <i class="icon-pause"></i>
      </button>
    </div>

    <button
      aria-label="Volume"
      *ngIf="gameData.externalAsset"
      class="btn btn-mute btn-secondary"
      (click)="onSetVolume()"
    >
      <i [class]="isMutted?'icon-mute':'icon-unmute'"></i>
    </button>
  </ng-container>
  <div class="game-card__mask" *ngIf="!gameData.externalAsset">
    <p class="msg">
      {{'gamegroup.novideo_configured'| translate }}
    </p>
  </div>
  <div
    *ngIf="gameData.externalAsset"
    class="vimeoplay-container"
    [id]="'vimeoId' + gameData.externalAsset"
  ></div>
</div>
