<form class="search-box" autocomplete="off" [class.isGamePlay]="isGamePlayWindow">
  <span class="prefix-icon m-r-4 search-box--title"><i class="icon-search"></i></span>
  <span class="search-box--subtitle">
    <input class="search-input" type="search" #searchInput id="searchtext"
      placeholder="{{'general.search-placeholder' | translate}}" (input)="onSearchGames($event.target.value)"
      autocomplete="off" />
  </span>
  <span class="suffix-icon cursor-hand search-box--title" *ngIf="(searchText && searchText.length > 0)"
    (click)="onCloseSearchResultsHandler()"><i class="icon-close"></i></span>
</form>

<!--Search form Result Related-->
<div class="search-results fade-in open-resultbox" *ngIf="(searchText && searchText.length > 0)">
  <h5 class="search-results--title text-left"
    *ngIf="(searchText && searchText.length > 0) && !(filteredGamesList && filteredGamesList.length === 0)">
    {{'general.search_results' | translate}}
  </h5>
  <div class="search-results__container searchresults-card" id="searchresults-card"
    (scroll)="onLoadMoreSearchResult($event)">
    <ng-container *ngIf="filteredGamesList && filteredGamesList.length > 0">
      <div class="game-card clearfix"
        [ngClass]="isLiveCasinoPage?'livecasino-card searchResultsGamecard':'casino search-game-card'"
        *ngFor="let game of filteredGamesList; let i = index">
        <app-game-card [gameData]="game" [isJackpot]="false" [game_asset_style]="(game.gameType === 'live_dealer' || game.gameType === 'live_casino') 
                    ? 'default-live-casino':'default-casino'" [callingFrom]="'recent-search'"
          (callBackAction)="onCloseSearchResultsHandler()"></app-game-card>
        <div [ngClass]="isLiveCasinoPage ? 'livecasino-card__footer' : 'game-card__footer'">
          <div class="game-title" [ngClass]="isLiveCasinoPage ? 'livecasino-card--game-name' : 'game-title'"
            [innerHtml]="game.name | searchGameHighlight: searchText">
          </div>
          <div class="livecasino-card--provider-name" *ngIf="isLiveCasinoPage">
            {{game.vendorDisplayName}}
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="filteredGamesList && filteredGamesList.length === 0 && isGamesLoaded && !isGamesLoading">
      <p class="no-data valign-center light-text">
        {{'general.nogames_configured' | translate}}
      </p>
    </ng-container>
  </div>
</div>

<div class="overlay-bg search-overlay" *ngIf="(searchText && searchText.length > 0) && (this.windowType === 'device')"
  (click)="onCloseSearchResultsHandler()"></div>