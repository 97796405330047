<app-side-menu-modal
  [customClass]="'betHistory'"
  [callingFrom]="'betHistory'"
  (callBackButton)="onGoBack()"
  [sideMenuTitle]="titleText"
>
  <!-- modal body -->
  <ng-container sideMenuBody>
    <div
      [class.valign-middle]="betTransactionsList && betTransactionsList.length === 0 && activeStep !== 'step3'"
    >
      <div class="txn-btngroup" *ngIf="activeStep === 'step1'">
        <ng-container *ngTemplateOutlet="buttonGroupsTemplate"></ng-container>
      </div>

      <ng-container *ngTemplateOutlet="filterTemplate"></ng-container>

      <h5
        class="component-inner--subtitle show-only-on-desktop"
        *ngIf="(activeStep === 'step3' && (betTransactionsList && betTransactionsList.length > 0))"
      >
        {{'bettransaction.results' | translate}}
      </h5>

      <table
        class="table-data"
        [class.component-middle]="betTransactionsList && betTransactionsList.length === 0 && activeStep === 'step3'"
      >
        <ng-container
          *ngIf="(activeStep === 'step2' || activeStep === 'step3')"
        >
          <ng-container *ngTemplateOutlet="recordsTemplate"></ng-container>

          <ng-container *ngTemplateOutlet="noRecordsTemplate"></ng-container>

          <ng-container
            *ngTemplateOutlet="skeletonLoaderTemplate"
          ></ng-container>
        </ng-container>
      </table>
    </div>
  </ng-container>
</app-side-menu-modal>

<!-- 
  ------------------------------------------------------
  
  Templates 
  
  ------------------------------------------------------
-->
<!-- Button Groups Template -->
<ng-template #buttonGroupsTemplate>
  <button
    class="btn btn-lg btn-gameHistory btn-round full-width"
    aria-label="24 Hours"
    (click)="onFetchBetHistory('last-24h')"
  >
    {{'general.last_24_hours' | translate}}
  </button>
  <button
    class="btn btn-lg btn-gameHistory btn-round full-width"
    aria-label="7days"
    (click)="onFetchBetHistory('last-7d')"
  >
    {{'general.last_7_days' | translate}}
  </button>
  <button
    *ngIf="false"
    class="btn btn-lg btn-gameHistory btn-round full-width"
    aria-label="30days"
    (click)="onFetchBetHistory('last-30d')"
  >
    {{'general.last_30_days' | translate}}
  </button>
  <button
    class="btn btn-lg btn-gameHistory btn-round full-width"
    aria-label="Select date range"
    (click)="onFetchBetHistory('select-date')"
  >
    {{'general.select_date_range' | translate}}
  </button>
</ng-template>

<!-- Filter Template -->
<ng-template #filterTemplate>
  <form
    class="datefilter-wrap"
    [formGroup]="txnDetailsFrom"
    *ngIf="activeStep === 'step3'"
  >
    <div class="transaction-filter">
      <label class="transaction-filter--label"
        ><i class="icon-calendar"></i> {{'general.date_from' |
        translate}}</label
      >

      <ng-container [ngSwitch]="windowType">
        <ng-container *ngSwitchCase="'device'">
          <input
            type="text"
            name="startDate"
            class="transaction-filter--input"
            formControlName="startDate"
            [(min)]="minDate"
            [(max)]="maxDate"
            mbsc-calendar
            [mbsc-options]="desktopStartDate"
            placeholder="{{'general.ddmmyyyy' | translate}}"
            [readonly]="true"
          />
        </ng-container>
        <ng-container *ngSwitchDefault>
          <input
            type="text"
            name="startDate"
            class="transaction-filter--input"
            formControlName="startDate"
            [(min)]="minDate"
            [(max)]="maxDate"
            mbsc-date
            [mbsc-options]="mobileStartDate"
            placeholder="{{'general.ddmmyyyy' | translate}}"
            [readonly]="true"
          />
        </ng-container>
      </ng-container>
    </div>

    <div class="transaction-filter">
      <label class="transaction-filter--label"
        ><i class="icon-calendar"></i> {{'general.date_to' | translate}}</label
      >

      <ng-container [ngSwitch]="windowType">
        <ng-container *ngSwitchCase="'device'">
          <input
            type="text"
            class="transaction-filter--input"
            name="endDate"
            formControlName="endDate"
            [(min)]="minDate"
            [(max)]="maxDate"
            mbsc-calendar
            [mbsc-options]="desktopEndDate"
            placeholder="{{'general.ddmmyyyy' | translate}}"
          />
        </ng-container>
        <ng-container *ngSwitchDefault>
          <input
            type="text"
            name="endDate"
            class="transaction-filter--input"
            formControlName="endDate"
            [(min)]="minDate"
            [(max)]="maxDate"
            mbsc-date
            [mbsc-options]="mobileEndDate"
            placeholder="{{'general.ddmmyyyy' | translate}}"
          />
        </ng-container>
      </ng-container>
    </div>

    <button
      class="btn btn-md btn-primary full-width medium-text btn-round"
      [setLoader]="isButtonLoader"
      [disabled]="txnDetailsFrom.invalid || txnDetailsFrom.pending || isButtonLoader"
      aria-label="Show game history"
      (click)="onGetBetTransaction()"
    >
      {{'general.show_game_history' | translate}}
    </button>
  </form>
</ng-template>

<!-- Records Template -->
<ng-template #recordsTemplate>
  <ng-container
    *ngIf="( betTransactionsList && betTransactionsList.length > 0)"
  >
    <tr
      class="row-group"
      *ngFor="let data of betTransactionsList; let i = index"
    >
      <td class="txnDetails--row">
        <div class="txnDetails-date">
          {{data.transactionTime | date:'MM/dd/yy'}} <br />
          {{data.transactionTime | date:'HH:mm:ss'}}
        </div>
        <div class="bet-and-win">
          <h5 class="txn-game-name">{{data.gameName}}</h5>
          <span class="txn-result"
            ><b>{{'bettransaction.bet' | translate}} : </b> {{data.txnCurrency}}
            {{data.betAmount | number : '1.2-2'}}</span
          >
          <span class="txn-result"
            ><b>{{'bettransaction.win' | translate}} : </b> {{data.txnCurrency}}
            {{data.winAmount | number : '1.2-2' }}</span
          >
        </div>
      </td>
    </tr>
  </ng-container>
</ng-template>

<!-- No Records Template -->
<ng-template #noRecordsTemplate>
  <!--
    If their are no records..we will display this conatiner
  -->
  <ng-container *ngIf="betTransactionsList && betTransactionsList.length === 0">
    <tr>
      <td colspan="3">
        <p class="no-data valign-center">
          <b>{{'bettransaction.no_data_available' | translate}}</b>
        </p>
      </td>
    </tr>
  </ng-container>
</ng-template>

<!-- Skeleton Loader Template -->
<ng-template #skeletonLoaderTemplate>
  <!--
    loading animation conatiner while fetching records from BE
  -->
  <ng-container *ngIf="isLoading">
    <tr
      class="row-group"
      *ngFor="let items of [].constructor(10); let i = index"
    >
      <td class="txnDetails--row">
        <div
          class="txnDetails-date skeleton-loader history loading round-skelton"
        ></div>
        <div class="bet-and-win">
          <h5 class="skeleton-loader history round-skelton"></h5>
          <span class="txn-result skeleton-loader history loading round-skelton"></span>
          <span class="txn-result skeleton-loader history loading round-skelton"></span>
        </div>
      </td>
    </tr>
  </ng-container>
</ng-template>
