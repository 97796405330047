import { catchError, map } from "rxjs/operators";
import { throwError } from "rxjs";
// Actions
import { profileBalanceRequested } from "src/app/modules/user/store/actions/profile.actions";
import { loggedInStatus } from "src/app/modules/auth/store/actions/auth.actions";
// API Interactors
import { ApiInteractors } from "src/app/models/interactors/api.interactor";
// Configurations
import { localStorageKeys } from "src/app/modules/multi-languages/configurations/localstorage-keys.configurations";
// Enums
import { Project } from "src/app/models/environments/project.enum";
import * as i0 from "@angular/core";
import * as i1 from "../../../rewards/services/cashback-promo.service";
import * as i2 from "../../services/affiliate.service";
import * as i3 from "../../../user/services/profile.service";
import * as i4 from "../../services/session.service";
import * as i5 from "../../../shared/services/socket.service";
import * as i6 from "../../services/login.service";
import * as i7 from "@angular/common/http";
import * as i8 from "@ngrx/store";
export class LoginAPIService {
    constructor(cashbackPromoService, affiliateService, profileService, sessionService, socketService, loginService, httpClient, store) {
        this.cashbackPromoService = cashbackPromoService;
        this.affiliateService = affiliateService;
        this.profileService = profileService;
        this.sessionService = sessionService;
        this.socketService = socketService;
        this.loginService = loginService;
        this.httpClient = httpClient;
        this.store = store;
        this.apiInteractor = new ApiInteractors(this.httpClient);
    }
    // -----------------------------------------------------------------
    // Get Methods
    onLogin(credentials) {
        return this.apiInteractor
            .post(`/ajax/login`, credentials, Project.Shotz)
            .pipe(map((loginResponse) => {
            if (loginResponse && loginResponse.success === true) {
                this.affiliateService.onDeleteAffiliateCookies();
                this.profileService.onSetUserJurisdiction(loginResponse.userJurisdiction);
                localStorage.setItem(localStorageKeys.stz_user, "true");
                this.store.dispatch(profileBalanceRequested());
                this.loginService.onNavigateToUserProfileLanguage();
                this.socketService.onConnectToSockets(loginResponse.pragmaticUrl, loginResponse.pragmaticSessionId, true);
                if (loginResponse.sessionLimit > 0) {
                    this.sessionService.onSetSessionVariable("session", {
                        sessionLimit: loginResponse.sessionLimit,
                        availableSessionLimit: loginResponse.sessionLimit * 60,
                    });
                }
                if (loginResponse.userJurisdiction === "estonia") {
                    this.userLoggedTime = new Date();
                    localStorage.setItem(localStorageKeys.loggedInTime, this.userLoggedTime.toString());
                }
                this.store.dispatch(loggedInStatus({ isLoggedIn: true }));
                if (loginResponse && loginResponse.user) {
                    this.sessionService.onSetSocketDetails(loginResponse.user);
                }
                if (loginResponse.lastLoginTime) {
                    this.cashbackPromoService.onSetUserLastAuthTime(+loginResponse.lastLoginTime);
                }
                this.loginService.onLoginSuccessGtmEvents(loginResponse);
            }
            return loginResponse;
        }));
    }
    onChangePassword(changePassword) {
        return this.apiInteractor
            .post(`/ajax/profile/changePassword`, changePassword, Project.Shotz)
            .pipe(map((changePasswordResult) => {
            return changePasswordResult;
        }), catchError((error) => {
            return throwError(error);
        }));
    }
    onGetLoginStatus() {
        return this.apiInteractor
            .get(`/ajax/login/status`, null, Project.Shotz)
            .pipe(map((loginStatus) => {
            if (loginStatus && loginStatus.status) {
                this.socketService.onConnectToSockets(loginStatus.pragmaticUrl, loginStatus.pragmaticSessionId, true);
                localStorage.setItem(localStorageKeys.stz_user, "true");
                /*
                this double check is need for one different case.
              */
                this.loginService.onVerifyIsLandingPage();
                this.sessionService.onSetSocketDetails({
                    message: "",
                    pragmaticSessionId: loginStatus.pragmaticSessionId,
                    socketUrl: loginStatus.pragmaticUrl,
                    partnerID: loginStatus.partnerId,
                });
            }
            else {
                localStorage.removeItem(localStorageKeys.stz_user);
                sessionStorage.removeItem("session");
            }
            return loginStatus;
        }), catchError((error) => {
            return throwError(error);
        }));
    }
    /*
      Here we will logout user..
      even Logout api fails by removing active user &
      active session from sessionStorage
    */
    onGetLogout() {
        return this.apiInteractor
            .post(`/ajax/login/logout`, null, Project.Shotz)
            .pipe(map((logoutResponse) => {
            return logoutResponse;
        }), catchError((error) => {
            return throwError(error);
        }));
    }
}
LoginAPIService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LoginAPIService_Factory() { return new LoginAPIService(i0.ɵɵinject(i1.CashbackPromoService), i0.ɵɵinject(i2.AffiliateService), i0.ɵɵinject(i3.ProfileService), i0.ɵɵinject(i4.SessionService), i0.ɵɵinject(i5.SocketService), i0.ɵɵinject(i6.LoginService), i0.ɵɵinject(i7.HttpClient), i0.ɵɵinject(i8.Store)); }, token: LoginAPIService, providedIn: "root" });
