/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./unclaimed-free-spin-reward-card.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "../../../shared/components/button-loader/button-loader.component.ngfactory";
import * as i4 from "../../../shared/components/button-loader/button-loader.component";
import * as i5 from "@angular/common";
import * as i6 from "./unclaimed-free-spin-reward-card.component";
import * as i7 from "../../../chat/services/live-chat.service";
import * as i8 from "../../services/reward.service";
import * as i9 from "../../../shared/services/utility.service";
var styles_UnclaimedFreeSpinRewardCardComponent = [i0.styles];
var RenderType_UnclaimedFreeSpinRewardCardComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UnclaimedFreeSpinRewardCardComponent, data: {} });
export { RenderType_UnclaimedFreeSpinRewardCardComponent as RenderType_UnclaimedFreeSpinRewardCardComponent };
function View_UnclaimedFreeSpinRewardCardComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 19, "div", [["class", "rewardBonusCard"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "rewardBonusCard__bg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["src", "/assets/img/rewards/bonus-img/shotz-rewards.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 16, "div", [["class", "rewardBonusCard__container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "rewardBonusCard__icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "img", [["class", "rewardBonusCard--chip no-shadow"], ["src", "/assets/img/rewards/bonus-img/nextlevel-card.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 13, "div", [["class", "rewardBonusCard__details no-justify-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 6, "div", [["class", "rewardBonusCard__text-inside"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["class", "rewardBonusCard--title"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, [" ", " ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(11, 0, null, null, 2, "div", [["class", "rewardBonusCard--desc"]], null, null, null, null, null)), (_l()(), i1.ɵted(12, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(14, 0, null, null, 3, "button", [["aria-label", "Open reward"], ["class", "btn btn-xs btn-primary btn-round m-t-8"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.applyLevelupBonus(_co.bonusDetails.levelId) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_ButtonLoaderComponent_0, i3.RenderType_ButtonLoaderComponent)), i1.ɵdid(15, 573440, null, 0, i4.ButtonLoaderComponent, [], { setLoader: [0, "setLoader"] }, null), (_l()(), i1.ɵted(16, 0, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(18, 0, null, null, 1, "button", [["aria-label", "Terms"], ["class", "btn btn-info"]], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 0, "i", [["class", "icon-info"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onOpenRewardTCPopUp($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_4 = _co.isRequestInProcess; _ck(_v, 15, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 9, 0, i1.ɵnov(_v, 10).transform("rewards.level")); var currVal_1 = _co.bonusDetails.levelId; _ck(_v, 9, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵunv(_v, 12, 0, i1.ɵnov(_v, 13).transform("rewards.unlock_your_reward")); _ck(_v, 12, 0, currVal_2); var currVal_3 = _co.isRequestInProcess; _ck(_v, 14, 0, currVal_3); var currVal_5 = i1.ɵunv(_v, 16, 0, i1.ɵnov(_v, 17).transform("rewards.open_reward")); _ck(_v, 16, 0, currVal_5); }); }
function View_UnclaimedFreeSpinRewardCardComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "div", [["class", "rewardBonusCard reward-failed"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "rewardBonusCard__bg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["src", "/assets/img/rewards/bonus-img/shotz-rewards.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 14, "div", [["class", "rewardBonusCard__container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 13, "div", [["class", "rewardBonusCard__details"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 9, "div", [["class", "rewardBonusCard__text-inside"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "rewardBonusCard--title"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(9, 0, null, null, 5, "div", [["class", "rewardBonusCard--desc"]], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, [" ", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(12, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(13, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(15, 0, null, null, 2, "button", [["aria-label", "Chat now"], ["class", "btn btn-xs btn-primary btn-round m-t-8"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onInitializeChat() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(16, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 7, 0, i1.ɵnov(_v, 8).transform("rewards.something_wrong")); _ck(_v, 7, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 10, 0, i1.ɵnov(_v, 11).transform("rewards.reward_not_loaded")); _ck(_v, 10, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 13, 0, i1.ɵnov(_v, 14).transform("rewards.check_customer_support")); _ck(_v, 13, 0, currVal_2); var currVal_3 = i1.ɵunv(_v, 16, 0, i1.ɵnov(_v, 17).transform("rewards.chat_now")); _ck(_v, 16, 0, currVal_3); }); }
export function View_UnclaimedFreeSpinRewardCardComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_UnclaimedFreeSpinRewardCardComponent_1)), i1.ɵdid(1, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UnclaimedFreeSpinRewardCardComponent_2)), i1.ɵdid(3, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.bonusDetails && !_co.isLevelUpClaimFailed); _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.isLevelUpClaimFailed; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_UnclaimedFreeSpinRewardCardComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-unclaimed-free-spin-reward-card", [], null, null, null, View_UnclaimedFreeSpinRewardCardComponent_0, RenderType_UnclaimedFreeSpinRewardCardComponent)), i1.ɵdid(1, 704512, null, 0, i6.UnclaimedFreeSpinRewardCardComponent, [i7.LiveChatService, i8.RewardsService, i9.UtilityService], null, null)], null, null); }
var UnclaimedFreeSpinRewardCardComponentNgFactory = i1.ɵccf("app-unclaimed-free-spin-reward-card", i6.UnclaimedFreeSpinRewardCardComponent, View_UnclaimedFreeSpinRewardCardComponent_Host_0, { bonusDetails: "bonusDetails" }, { callOpenRewardTCPopUp: "callOpenRewardTCPopUp", callOnLevelupClaimed: "callOnLevelupClaimed" }, []);
export { UnclaimedFreeSpinRewardCardComponentNgFactory as UnclaimedFreeSpinRewardCardComponentNgFactory };
