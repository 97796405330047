<div
  class="game-group__container"
  [ngClass]="{
    'continue-games': (gameGroupData && gameGroupData.group_type === 'automatic'
        && gameGroupData.group_sub_type === 'continue_playing'),
    'favourite-games': (gameGroupData && gameGroupData.group_type === 'automatic'
          && gameGroupData.group_sub_type === 'favourite')
  }"
>
  <div class="game-group--title">
    <span
      class="gameGroupName-link"
      (click)="onNavigateAllGamesPage()"
    >
      {{gameGroupData.name}}
    </span>

    <span
      class="viewall-link"
      (click)="onNavigateAllGamesPage()"
    >
      {{'gamegroup.view_all'| translate }}
    </span>
  </div>

  <div class="swiper__outer hoverspace">
    <div class="swiper__inner swiper__full">
      <swiper
        class="mySwiper swiper-control"
        [config]="swiperLiveCasino"
        (slidePrevTransitionEnd)="onSwiperPrevEvent()"
        (slideNextTransitionEnd)="onSwiperNextEvent()"
        watchSlidesProgress
      >
        <ng-container
          *ngFor="let game of gamesList | slice:0:swiperLazyConfigurations.indexNumberOfGameTilesToshow; let i = index"
        >
          <div
            class="game-card casino live-casino"
            *ngIf="game.gameType === 'live_casino'"
          >
            <div class="game-card-inner">
              <app-game-card
                [gameData]="game"
                [game_asset_style]="gameGroupData.game_asset_style"
                [lobbyName]="callingFromlobby"
                [gameGroupName]="gameGroupData.name"
              >
              </app-game-card>
            </div>
          </div>
        </ng-container>
      </swiper>
    </div>
  </div>
</div>
