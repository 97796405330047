// Models
import { EnvironmentsDetails } from "src/app/models/environments/environments.model";

const isNew: boolean = false;

const bunnyURL: string = "https://cdn.shotz.com";

const MEDIA_URL = isNew ? bunnyURL : "https://hpyhr-ps.imgix.net";

export const environment: EnvironmentsDetails = {
  production: false,
  // Site URL
  siteUrl: "https://stage-shotz.uatsecure.com",
  apiUrl: "https://stage-shotz.uatsecure.com",
  languageApiUrl: "https://stage-shotz.uatsecure.com",
  staticURLApiUrl: "https://stage-shotz.uatsecure.com",
  // Media URL
  mediaUrl: `${MEDIA_URL}`,
  mediaUrlPath: `${MEDIA_URL}/uploads/media/CAF`,
  mediaUrlPathSTZ: `${MEDIA_URL}/uploads/media/STZ`,
  bunnyPath: `${bunnyURL}/uploads/media/STZ`,
  // Game URL
  gamesUrlPath: `${MEDIA_URL}/uploads/games/CAF`,
  // Vimeo
  vimeoUrl: "https://player.vimeo.com/video/",
  // Website Code
  websiteCode: "STZ",
  websiteName: "shotz",
  // Languages
  languagePath: "/languages/STZ/",
  defaultLanguage: "en-row",
  // Payments
  paymentIqUrl: "https://test-api.paymentiq.io/paymentiq",
  paymentIqMID: "100425989",
  paymentIqUID: "shotz",
  // Micro Gaming
  microGamingJackpotsUrl:
    "https://api32.gameassists.co.uk/casino/progressive/public/v1",
  // Live Gaming
  liveGameUrl: "prelive-dga.pragmaticplaylive.net",
  liveGameCasioID: "ppcdk00000004555",
  // Netent
  netentBrand: "oneupent",
  netentGameLiveServerUrl: "https://oneupent-livegame-test.casinomodule.com",
  netentGameStaticUrl: "https://oneupent-static-test.casinomodule.com",
  // Mix Panel Project Token
  mixpanelProjectToken: "80fc93f621b1883c55734d5d188a447b",
  // Default Currency
  defaultCurrencySymbol: "€",
  defaultCurrencyCode: "EUR",
  // Website
  website: "shotz.com",
  // Zimpler Markets
  zimplerMarkets: ["fi-fi"],
  // GTM
  pnpGTM_trackingId: "GTM-NDKGLJ6",
  classicGTM_trackingId: "GTM-5V24DL9",
  // Livespins Configurations
  livespins: {
    configurations: {
      language: "en",
      tenant: "SHOTZ",
      serverConfig: {
        api: "https://happyhour-apigateway-stage.a.hpyhr.cloud/stream",
        ui: "https://stream-livespins-stage.a.hpyhr.cloud",
      },
    },
    shareURL: "/livespins/:sessionId/play",
  },
  // Features
  features: {
    shotzLanguages: false,
    hungarian: true,
    liveChatVip: true,
    termsAndConditions: false,
    showLanguageSelector: true,
    disableLevelUpLoader: true,
    regilyCurrency: {
      huf: false,
    },
    showConsoles: {
      regily: false,
    },
    testing: {
      quickDeposits: false,
      kyc: false,
    },
    liveCasino: {
      livespins: true,
    },
  },
};
