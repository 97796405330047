/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./limits-regulatory.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "./limits-regulatory.component";
import * as i5 from "../../../shared/services/emitter.service";
import * as i6 from "../../../shared/services/utility.service";
import * as i7 from "@ngrx/store";
var styles_LimitsRegulatoryComponent = [i0.styles];
var RenderType_LimitsRegulatoryComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LimitsRegulatoryComponent, data: {} });
export { RenderType_LimitsRegulatoryComponent as RenderType_LimitsRegulatoryComponent };
function View_LimitsRegulatoryComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_LimitsRegulatoryComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "notifyMsg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 10, "div", [["class", "notifyMsg__container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "button", [["aria-label", "Close"], ["class", "notifyMsg--coloseBtn btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onCloseRegulatoryPopup() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "i", [["class", "icon-close"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "h3", [["class", "notifyMsg--title"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(7, 0, null, null, 2, "p", [["class", "notifyMsg--text m-t-8 m-b-16"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LimitsRegulatoryComponent_2)), i1.ɵdid(11, 540672, null, 0, i3.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null)], function (_ck, _v) { var currVal_2 = i1.ɵnov(_v.parent, 2); _ck(_v, 11, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform("LimitsRegulatoryMsg.LimitsRegulatoryMsgTitle")); _ck(_v, 5, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 8, 0, i1.ɵnov(_v, 9).transform("LimitsRegulatoryMsg.LimitsRegulatoryMsgText")); _ck(_v, 8, 0, currVal_1); }); }
function View_LimitsRegulatoryComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "notifyMsg--btn-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "button", [["class", "btn btn-sm btn-secondary btn-round btn-50 m-r-8"]], [[1, "aria-label", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onNavigateToLimits() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵted(3, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(5, 0, null, null, 3, "button", [["class", "btn btn-sm btn-primary btn-round btn-50"]], [[1, "aria-label", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onCloseRegulatoryPopup() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵted(7, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("LimitsRegulatoryMsg.setLmits")); _ck(_v, 1, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("LimitsRegulatoryMsg.setLmits")); _ck(_v, 3, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform("LimitsRegulatoryMsg.later")); _ck(_v, 5, 0, currVal_2); var currVal_3 = i1.ɵunv(_v, 7, 0, i1.ɵnov(_v, 8).transform("LimitsRegulatoryMsg.later")); _ck(_v, 7, 0, currVal_3); }); }
export function View_LimitsRegulatoryComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_LimitsRegulatoryComponent_1)), i1.ɵdid(1, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, [["buttonGroupsTemplate", 2]], null, 0, null, View_LimitsRegulatoryComponent_3))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.isLoggedIn && _co.isOpenLimitsRegulatoryPopup); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_LimitsRegulatoryComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-limits-regulatory", [], null, null, null, View_LimitsRegulatoryComponent_0, RenderType_LimitsRegulatoryComponent)), i1.ɵdid(1, 245760, null, 0, i4.LimitsRegulatoryComponent, [i5.EmitterService, i6.UtilityService, i7.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LimitsRegulatoryComponentNgFactory = i1.ɵccf("app-limits-regulatory", i4.LimitsRegulatoryComponent, View_LimitsRegulatoryComponent_Host_0, {}, {}, []);
export { LimitsRegulatoryComponentNgFactory as LimitsRegulatoryComponentNgFactory };
