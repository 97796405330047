import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { SimpleChange, Component, Input } from "@angular/core";
import { Subscription } from "rxjs";

// Configurations
import { userMinimumRGLimitCheckConfigurations } from "src/app/configurations/main.configurations";

// Components
import { FormValidationComponent } from "src/app/modules/shared/components/form-validation/form-validation.component";

// Enums
import { WorldCurrencyCode } from "src/app/models/configurations/enums/localization/world-currencies.enum";
import { PeriodTags } from "src/app/modules/limits/enums/period-tags.enum";
import { StatusResponse } from "src/app/models/api/status.response";

// Models
import { CancelNetDepositLimitsResponse } from "src/app/modules/limits/models/cancel-net-deposit-limits/cancel-net-deposit-limits-response.model";
import { CancelNetDepositLimitsRequest } from "src/app/modules/limits/models/cancel-net-deposit-limits/cancel-net-deposit-limits-request.model";
import { CancelDepositLimitsResponse } from "src/app/modules/limits/models/cancel-deposit-limts/cancel-depsoit-limits-response.model";
import { SetNetDepositLimitResponse } from "src/app/modules/limits/models/set-net-deposit-limit/set-net-deposit-limit-response.model";
import { CancelDepositLimitsRequest } from "src/app/modules/limits/models/cancel-deposit-limts/cancel-deposit-limits-request.model";
import { NetDepositLimitsResponse } from "src/app/modules/limits/models/net-deposit-limits/net-deposit-limits-response.model";
import { CancelLimitsResponse } from "src/app/modules/limits/models/cancel-limits/cancel-limits-response.model";
import { CancelLimitsRequest } from "src/app/modules/limits/models/cancel-limits/cancel-limits-request.model";
import { RgLimitsResponse } from "src/app/modules/limits/models/rg-limits/rg-limits-response.model";
import { RgLimitsRequest } from "src/app/modules/limits/models/rg-limits/rg-limits-request.model";
import { PeriodSpecificLimits } from "src/app/modules/limits/models/period-specific-limits.model";
import { LimitsResponse } from "src/app/modules/limits/models/limits-response.model";
import {
  LimitIntervals,
  LimitItem,
} from "src/app/modules/limits/models/limits-intervals.model";

// Pipes
import { CurrencyFormatPipe } from "src/app/modules/shared/pipes/currency-format.pipe";

// Services
import { TranslationService } from "src/app/modules/multi-languages/services/translation.service";
import { UserDetailsService } from "src/app/modules/user/services/user-details.service";
import { FormService } from "src/app/modules/dynamic-form/services/form.service";
import { LimitsService } from "src/app/modules/limits/services/limits.service";

// Validators
import { LimitsValidator } from "src/app/modules/validators/validators/limits.validators";

@Component({
  selector: "app-rg-limits",
  templateUrl: "./rg-limits.component.html",
  styleUrls: ["./rg-limits.component.scss"],
})
export class RgLimitsComponent extends FormValidationComponent {
  // Inputs
  @Input() limitType: string = "";

  // Numbers
  userMinimumLimit: number = 0;

  // Strings
  cancelLimitsErrorMessage: string = "";
  successResponseMessage: string = "";
  failedResponseMessage: string = "";
  formattedUserMinimum: string = "";
  currencySymbol: string = "";

  // Booleans
  isSettingLoader: boolean = false;
  isButtonLoader: boolean = false;

  // Enums
  currencyCode: WorldCurrencyCode;
  PeriodTags = PeriodTags;

  // Arrays
  periodTagsList: string[] = ["daily", "weekly", "monthly"];

  // Objects
  periodSpecificLimits: PeriodSpecificLimits = {
    currentLimit: 0,
    remainingLimit: 0,
    pendingLimit: 0,
    pendingTime: undefined,
  };
  currentLimits?: LimitIntervals | LimitsResponse | NetDepositLimitsResponse;

  // Form Groups
  rgLimitsForm: FormGroup;

  // Subscriptions
  getResponsibleGamingLimitsSubscription: Subscription;
  cancelNetDepositLimitsSubscription: Subscription;
  cancelDepositLimitsSubscription: Subscription;
  getNetDepositLimitsSubscription: Subscription;
  cancelLimitsSubscription: Subscription;
  limitsSubscription: Subscription;

  subscriptions: Subscription[] = [];

  constructor(
    private translationService: TranslationService,
    private userDetailsService: UserDetailsService,
    private currencyFormatPipe: CurrencyFormatPipe,
    private limitsService: LimitsService,
    private formService: FormService,
    private formBuilder: FormBuilder
  ) {
    super();

    this.onLoad();
  }

  // -----------------------------------------------------------------
  // Lifecycle Hooks
  ngOnInit(): void {
    this.subscriptions = [
      this.userDetailsService.currencySymbolBehaviourSubject$.subscribe(
        (currencySymbol: string) => {
          this.currencySymbol = currencySymbol;
        }
      ),
      this.userDetailsService.currencyCodeBehaviourSubject$.subscribe(
        (currencyCode: WorldCurrencyCode) => {
          this.currencyCode = currencyCode;
        }
      ),
    ];
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
    if (
      changes["limitType"] &&
      changes["limitType"].previousValue !== changes["limitType"].currentValue
    ) {
      this.limitType = changes["limitType"]
        ? changes["limitType"].currentValue
        : "";

      this.rgLimitsForm.controls["type"].setValue(
        changes["limitType"].currentValue
      );

      /*
        Here we are using Net Deposit limits for type deposit
        loss or wager limits we use onGetLimits
      */
      if (this.limitType === "deposit") {
        this.rgLimitsForm.controls["period"].setValue(PeriodTags.weekly);

        this.onGetNetDepositLimits();
      } else {
        this.rgLimitsForm.controls["period"].setValue(PeriodTags.daily);

        this.onGetLimits();
      }
    }
  }

  // -----------------------------------------------------------------
  // Get Methods
  getRgLimitsRequest(): RgLimitsRequest {
    let rgLimitsRequest: RgLimitsRequest =
      this.formService.transformFormToData<RgLimitsRequest>(
        this.rgLimitsForm,
        {}
      );

    rgLimitsRequest[rgLimitsRequest.period] = rgLimitsRequest.limit;

    delete rgLimitsRequest.limit;

    delete rgLimitsRequest.period;

    return rgLimitsRequest;
  }

  getFormattedLimits(currentLimits: LimitsResponse): LimitsResponse {
    let limitsResponse: LimitsResponse = currentLimits;

    if (this.limitType !== "deposit") {
      if (limitsResponse.pendingLimits) {
        if (limitsResponse.pendingLimits.daily) {
          limitsResponse.daily.pending =
            limitsResponse.pendingLimits.daily.value;

          limitsResponse.daily.remainingTime =
            limitsResponse.pendingLimits.daily.remainingTime;
        }

        if (limitsResponse.pendingLimits.weekly) {
          limitsResponse.weekly.pending =
            limitsResponse.pendingLimits.weekly.value;

          limitsResponse.weekly.remainingTime =
            limitsResponse.pendingLimits.weekly.remainingTime;
        }

        if (limitsResponse.pendingLimits.monthly) {
          limitsResponse.monthly.pending =
            limitsResponse.pendingLimits.monthly.value;

          limitsResponse.monthly.remainingTime =
            limitsResponse.pendingLimits.monthly.remainingTime;
        }
      }
    }

    return limitsResponse;
  }

  getNetLimitsRequest(): RgLimitsRequest {
    let rgLimitsRequest: RgLimitsRequest =
      this.formService.transformFormToData<RgLimitsRequest>(
        this.rgLimitsForm,
        {}
      );

    rgLimitsRequest.currency = this.currencyCode;

    rgLimitsRequest[`${rgLimitsRequest.period}Limit`] = rgLimitsRequest.limit;

    delete rgLimitsRequest.limit;

    delete rgLimitsRequest.period;

    delete rgLimitsRequest.type;

    return rgLimitsRequest;
  }

  // -----------------------------------------------------------------
  // Set Methods
  onLoad(): void {
    let userCurrency: string = this.userDetailsService.getCurrencySymbol();

    this.userMinimumLimit = userMinimumRGLimitCheckConfigurations[userCurrency];

    this.formattedUserMinimum = this.currencyFormatPipe.transform(
      this.userMinimumLimit,
      this.currencySymbol
    );

    this.rgLimitsForm = this.formBuilder.group(
      {
        type: ["", [Validators.required]],
        limit: ["", [Validators.required]],
        period: ["", [Validators.required]],
      },
      {
        validator: LimitsValidator.validateResponsibleGamingLimits(
          this.currentLimits,
          this.userMinimumLimit
        ),
      }
    );
  }

  onClearLimitsFields(): void {
    this.rgLimitsForm.controls["limit"].setValue(null);

    this.rgLimitsForm.controls["limit"].markAsUntouched({ onlySelf: true });

    this.rgLimitsForm.controls["limit"].markAsPristine({ onlySelf: true });

    this.rgLimitsForm.markAsUntouched({ onlySelf: true });

    this.rgLimitsForm.markAsPristine();
  }

  onSetActivePeriod(period: string): void {
    /*
      Condition to check whether any value exists in limits fields or not
      if exist...we clear the form control.
    */
    if (this.rgLimitsForm.controls["period"].value) {
      this.onClearLimitsFields();
    }

    if (this.limitType === "deposit") {
      this.onSetNetDepositPeriodSpecificationLimits(`${period}Limit`);
    } else {
      this.onSetPeriodSpecificLimits(period);
    }

    this.rgLimitsForm.controls["period"].setValue(period);
  }

  onSetPeriodSpecificLimits(period: string): void {
    /*
      Re-initialize to default values for every period
      change...
    */
    this.periodSpecificLimits = {
      currentLimit: 0,
      remainingLimit: 0,
      pendingLimit: 0,
      pendingTime: undefined,
    };

    if (this.rgLimitsForm.controls["period"].value) {
      this.onClearLimitsFields();
    }

    /*
      Here we update period specific changes...
    */
    let limits: LimitItem;

    if (this.currentLimits && this.limitType === "deposit") {
      limits = this.currentLimits[period];
    } else if (
      this.currentLimits &&
      (this.currentLimits as LimitsResponse).limits
    ) {
      limits = (this.currentLimits as LimitsResponse).limits[period];

      if (
        (this.currentLimits as LimitsResponse).pendingLimits &&
        (this.currentLimits as LimitsResponse).pendingLimits[period]
      ) {
        const pendingLimits: LimitItem = (this.currentLimits as LimitsResponse)
          .pendingLimits[period];

        limits.pending = pendingLimits.value;

        limits.remainingTime = pendingLimits.remainingTime;
      }
    }

    if (period && limits) {
      this.periodSpecificLimits = {
        currentLimit: limits.value,
        remainingLimit: limits.remaining,
        pendingLimit: limits.pending,
        pendingTime: limits.remainingTime
          ? new Date(new Date().getTime() + limits.remainingTime * 60 * 1000)
          : undefined,
      };
    }
  }

  onUpdateLimits(): void {
    if (this.rgLimitsForm.valid) {
      this.isButtonLoader = true;

      let rgLimitsRequest: RgLimitsRequest = this.getRgLimitsRequest();

      this.limitsSubscription = this.limitsService
        .onSetResponsibleGamingLimits(rgLimitsRequest)
        .subscribe((rgLimitsResponse: RgLimitsResponse) => {
          this.isButtonLoader = false;

          if (rgLimitsResponse && rgLimitsResponse.success) {
            this.successResponseMessage = this.translationService.get(
              "limits.limit_updated_success"
            );

            this.onClearLimitsFields();

            this.onGetLimits();
          } else {
            this.failedResponseMessage = this.translationService.get(
              "limits.limits_update_falied"
            );

            this.onClearLimitsFields();
          }
        });

      setTimeout(() => {
        this.successResponseMessage = undefined;

        this.failedResponseMessage = undefined;
      }, 5000);
    }
  }

  onGetLimits(): void {
    if (this.limitType) {
      this.getResponsibleGamingLimitsSubscription = this.limitsService
        .onGetResponsibleGamingLimits(this.limitType)
        .subscribe((limitsResponse: LimitsResponse) => {
          if (this.limitType === "deposit" && limitsResponse) {
            this.currentLimits = limitsResponse.limits.deposit;

            this.onSetPeriodSpecificLimits(
              this.rgLimitsForm.controls["period"].value
            );

            this.rgLimitsForm.setValidators(
              LimitsValidator.validateResponsibleGamingLimits(
                this.currentLimits,
                this.userMinimumLimit
              )
            );
          } else if (limitsResponse) {
            this.currentLimits = limitsResponse;

            this.onSetPeriodSpecificLimits(
              this.rgLimitsForm.controls["period"].value
            );

            const formattedLimits: LimitsResponse = this.getFormattedLimits(
              this.currentLimits
            );

            this.rgLimitsForm.setValidators(
              LimitsValidator.validateResponsibleGamingLimits(
                formattedLimits.limits,
                this.userMinimumLimit
              )
            );
          }
        });
    }
  }

  onCancelLimits(): void {
    this.isSettingLoader = true;

    if (this.limitType === "deposit") {
      let cancelDepositLimitsRequest: CancelDepositLimitsRequest = {
        limitTypes: this.rgLimitsForm.controls["period"].value.toUpperCase(),
        isConfirmed: 0,
      };

      this.cancelDepositLimitsSubscription = this.limitsService
        .onCancelDepositLimits(cancelDepositLimitsRequest)
        .subscribe(
          (cancelDepositLimitsResponse: CancelDepositLimitsResponse) => {
            this.isSettingLoader = false;

            if (
              cancelDepositLimitsResponse &&
              cancelDepositLimitsResponse.success.status ===
                StatusResponse.SUCCESS
            ) {
              this.onGetLimits();
            } else {
              this.cancelLimitsErrorMessage = this.translationService.get(
                "general.something_went_wrong"
              );
            }

            setTimeout(() => {
              this.cancelLimitsErrorMessage = undefined;
            }, 5000);
          }
        );
    } else {
      let cancelLimitsRequest: CancelLimitsRequest = {
        limitType: `${this.rgLimitsForm.controls[
          "type"
        ].value.toUpperCase()}LIMIT`,
        isConfirm: 0,
      };

      cancelLimitsRequest[this.rgLimitsForm.controls["period"].value] =
        this.periodSpecificLimits.pendingLimit;

      this.cancelLimitsSubscription = this.limitsService
        .onCancelLimits(cancelLimitsRequest)
        .subscribe((cancelLimitsResponse: CancelLimitsResponse) => {
          this.isSettingLoader = false;

          if (cancelLimitsResponse && cancelLimitsResponse.success) {
            this.onGetLimits();
          } else {
            this.cancelLimitsErrorMessage = this.translationService.get(
              "general.something_went_wrong"
            );
          }

          setTimeout(() => {
            this.cancelLimitsErrorMessage = undefined;
          }, 5000);
        });
    }
  }

  onGetNetDepositLimits(): void {
    if (this.limitType) {
      this.getNetDepositLimitsSubscription = this.limitsService
        .onGetNetDepositLimits()
        .subscribe((netDepositLimitsResponse: NetDepositLimitsResponse) => {
          if (this.limitType === "deposit" && netDepositLimitsResponse) {
            this.currentLimits = netDepositLimitsResponse;

            this.onSetNetDepositPeriodSpecificationLimits(
              `${this.rgLimitsForm.controls["period"].value}Limit`
            );

            this.rgLimitsForm.setValidators(
              LimitsValidator.validateResponsibleGamingLimits(
                this.currentLimits,
                this.userMinimumLimit
              )
            );
          }
        });
    }
  }

  onCancelNetDepositLimits(): void {
    if (this.limitType) {
      let cancelNetDepositLimitsRequest: CancelNetDepositLimitsRequest = {
        confirm: false,
      };

      this.cancelNetDepositLimitsSubscription = this.limitsService
        .onCancelNetDepositLimits(cancelNetDepositLimitsRequest)
        .subscribe(
          (cancelNetDepositLimitsResponse: CancelNetDepositLimitsResponse) => {
            this.isSettingLoader = false;

            if (
              cancelNetDepositLimitsResponse &&
              cancelNetDepositLimitsResponse.status === StatusResponse.SUCCESS
            ) {
              this.onGetNetDepositLimits();
            } else {
              this.cancelLimitsErrorMessage = this.translationService.get(
                "general.something_went_wrong"
              );
            }

            setTimeout(() => {
              this.cancelLimitsErrorMessage = undefined;
            }, 5000);
          }
        );
    }
  }

  onSetNetDepositPeriodSpecificationLimits(period: string): void {
    /*
      Re-initialize to default values for every period
      change...
    */
    this.periodSpecificLimits = {
      currentLimit: 0,
      remainingLimit: 0,
      pendingLimit: 0,
      pendingTime: undefined,
    };

    if (this.rgLimitsForm.controls["period"].value) {
      this.onClearLimitsFields();
    }

    let tempLimits: LimitIntervals | LimitsResponse | NetDepositLimitsResponse;

    let limits: NetDepositLimitsResponse;

    if (this.currentLimits && this.limitType === "deposit") {
      tempLimits = this.currentLimits;

      limits = tempLimits as NetDepositLimitsResponse;

      if (period === "weeklyLimit") {
        this.periodSpecificLimits = {
          currentLimit: limits.weeklyLimit / 100,
          remainingLimit: limits.remainingNetLimitThisWeek / 100,
          pendingLimit: limits.pendingWeeklyLimit / 100,
          pendingTime: limits.remainigWeeklyNetLimitTime
            ? new Date(
                new Date().getTime() +
                  limits.remainigWeeklyNetLimitTime * 60 * 1000
              )
            : undefined,
        };
      } else if (period === "monthlyLimit") {
        this.periodSpecificLimits = {
          currentLimit: limits.monthlyLimit / 100,
          remainingLimit: limits.remainingNetLimitThisMonth / 100,
          pendingLimit: limits.pendingMonthlyLimit / 100,
          pendingTime: limits.remainigMonthlyNetLimitTime
            ? new Date(
                new Date().getTime() +
                  limits.remainigMonthlyNetLimitTime * 60 * 1000
              )
            : undefined,
        };
      }
    }
  }

  onUpdateNetDepositLimits(): void {
    if (this.rgLimitsForm.valid) {
      this.isButtonLoader = true;

      let rgLimitsRequest: RgLimitsRequest = this.getNetLimitsRequest();

      this.cancelLimitsSubscription = this.limitsService
        .onSetNetDepositLimits(rgLimitsRequest)
        .subscribe((data: SetNetDepositLimitResponse) => {
          this.isButtonLoader = false;

          if (
            data &&
            (data.status === StatusResponse.SUCCESS ||
              data.status === StatusResponse.PENDING)
          ) {
            this.successResponseMessage = this.translationService.get(
              "limits.limit_updated_success"
            );

            this.onClearLimitsFields();

            this.onGetNetDepositLimits();
          } else if (data.errorCode === 100296) {
            this.failedResponseMessage = this.translationService.get(
              "limits.error_100296_updateFalied"
            );
          } else {
            this.failedResponseMessage = this.translationService.get(
              "limits.limits_update_falied"
            );

            this.onClearLimitsFields();
          }
        });

      setTimeout(() => {
        this.successResponseMessage = undefined;

        this.failedResponseMessage = undefined;
      }, 5000);
    }
  }

  // -----------------------------------------------------------------
  // On Destroy
  ngOnDestroy(): void {
    if (this.getResponsibleGamingLimitsSubscription)
      this.getResponsibleGamingLimitsSubscription.unsubscribe();

    if (this.cancelNetDepositLimitsSubscription)
      this.cancelNetDepositLimitsSubscription.unsubscribe();

    if (this.cancelDepositLimitsSubscription)
      this.cancelDepositLimitsSubscription.unsubscribe();

    if (this.getNetDepositLimitsSubscription)
      this.getNetDepositLimitsSubscription.unsubscribe();

    if (this.cancelLimitsSubscription)
      this.cancelLimitsSubscription.unsubscribe();

    if (this.limitsSubscription) this.limitsSubscription.unsubscribe();

    this.subscriptions.forEach((subscription: Subscription) =>
      subscription.unsubscribe()
    );
  }
}
