import { BehaviorSubject, Observable, Subject, throwError } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { catchError, map } from "rxjs/operators";
import { Injectable } from "@angular/core";

// API Interactors
import { ApiInteractors } from "src/app/models/interactors/api.interactor";

// Enums
import { WorldCurrencyCode } from "src/app/models/configurations/enums/localization/world-currencies.enum";
import { Project } from "src/app/models/environments/project.enum";

// Environments
import { environment } from "src/environments/environment";

// Libraries
import * as _ from "underscore";

// Models
import { UserProfileBalance } from "src/app/modules/shared/models/profiles/user-profile-balance.model";
import { UserBalanceDetails } from "src/app/modules/shared/models/profiles/user-balance-details.model";
import { CurrencyDetails } from "src/app/modules/shared/models/currency/currency-details.model";
import { UserData } from "src/app/modules/user/models/user-data.model";
import { UserTags } from "src/app/modules/user/models/user-tags.model";

@Injectable({
  providedIn: "root",
})
export class UserDetailsService {
  // API Interactions
  apiInteractor: ApiInteractors;

  // Numbers
  withdrawableBalance: number = 0;
  userTotalBalance: number = 0;
  userTotalBonus: number = 0;

  // Strings
  defaultCurrency: string = WorldCurrencyCode.EUR;
  defaultCurrencySymbol: string = "€";
  userBrowserCountry: string = "";
  userCurrencySymbol: string = "";
  userLanguageCode: string = "";
  userCountryCode: string = "";

  // Date
  lastLoginTime: Date;

  // Enums
  userCurrencyCode: WorldCurrencyCode;

  // Objects
  userBalanceDetails: UserBalanceDetails;
  userCurrencyDetails: CurrencyDetails;
  userProfileDetails: UserData;

  // --------------------------------------------------------
  // Subject and Behaviour Subject
  private userProfileUpdatedSubject: Subject<string> = new Subject<string>();
  userProfileUpdatedSubject$: Observable<string> =
    this.userProfileUpdatedSubject.asObservable();

  // --------------------------------------------------------
  // Subject and Behaviour Subject
  private userBalanceUpdatedSubject: Subject<string> = new Subject<string>();
  userBalanceUpdatedSubject$: Observable<string> =
    this.userBalanceUpdatedSubject.asObservable();

  // --------------------------------------------------------
  // Subject and Behaviour Subject
  private currencySymbolBehaviourSubject: BehaviorSubject<string> =
    new BehaviorSubject<string>(environment.defaultCurrencySymbol);
  currencySymbolBehaviourSubject$: Observable<string> =
    this.currencySymbolBehaviourSubject.asObservable();

  // --------------------------------------------------------
  // Subject and Behaviour Subject
  private currencyCodeBehaviourSubject: BehaviorSubject<WorldCurrencyCode> =
    new BehaviorSubject<WorldCurrencyCode>(
      WorldCurrencyCode[environment.defaultCurrencyCode]
    );
  currencyCodeBehaviourSubject$: Observable<WorldCurrencyCode> =
    this.currencyCodeBehaviourSubject.asObservable();

  constructor(private httpClient: HttpClient) {
    this.apiInteractor = new ApiInteractors(this.httpClient);
  }

  // -----------------------------------------------------------------
  // Get Methods
  getCurrencySymbol(): string {
    return this.userCurrencySymbol
      ? this.userCurrencySymbol
      : this.defaultCurrencySymbol;
  }

  getCurrencyCode(): WorldCurrencyCode {
    return WorldCurrencyCode[
      this.userCurrencyCode ? this.userCurrencyCode : this.defaultCurrency
    ];
  }

  getUserCurrencyDetails(): CurrencyDetails {
    return this.userCurrencyDetails;
  }

  getUserBrowserCountry(): string {
    return this.userBrowserCountry;
  }

  getUserProfileDetails(): UserData {
    return this.userProfileDetails;
  }

  getWithdrawableBalance(): number {
    return this.withdrawableBalance;
  }

  getUserTotalBalance(): number {
    return this.userTotalBalance;
  }

  getUserTotalBonus(): number {
    return this.userTotalBonus;
  }

  getUserBalanceDetails(): UserBalanceDetails {
    return this.userBalanceDetails;
  }

  getLastLoginTime(): Date {
    return this.lastLoginTime;
  }

  getUserCountryCode(): string {
    return this.userCountryCode;
  }

  // -----------------------------------------------------------------
  // Get Observable
  onGetUserTagsList(): Observable<UserTags> {
    return this.apiInteractor
      .get<null, UserTags>(`/ajax/profile/getUserTags`, null, Project.Shotz)
      .pipe(
        map((response: UserTags) => {
          return response;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  onGetUserData(): Observable<UserData> {
    return this.apiInteractor
      .get<null, UserData>(`/ajax/profile/getData`, null, Project.Shotz)
      .pipe(
        map((response: UserData) => {
          return response;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  // -----------------------------------------------------------------
  // Set Methods - Set Currency
  onSetCurrencySymbol(currencySymbol: string): void {
    this.userCurrencySymbol = currencySymbol;

    this.currencySymbolBehaviourSubject.next(currencySymbol);
  }

  onSetUserCurrencyCode(currencyCode: WorldCurrencyCode): void {
    this.userCurrencyCode = currencyCode;

    this.currencyCodeBehaviourSubject.next(currencyCode);
  }

  onSetUserProfileDetails(userProfile: UserData): void {
    this.userProfileDetails = userProfile;

    this.userProfileDetails.firstName = this.userProfileDetails.firstName
      ? this.userProfileDetails.firstName.toLowerCase()
      : "";

    this.userProfileDetails.lastName = this.userProfileDetails.lastName
      ? this.userProfileDetails.lastName.toLowerCase()
      : "";

    this.userProfileDetails.affiliateLinkID = this.userProfileDetails
      .affiliateLinkID
      ? this.userProfileDetails.affiliateLinkID
      : "";

    this.onSetUserCountryCode(userProfile.country);

    this.onSetUserLanguageCode(userProfile.language);

    this.userProfileUpdatedSubject.next("UserProfileUpdated");
  }

  onSetUserCurrencyDetails(currencyDetails: CurrencyDetails): void {
    this.userCurrencyDetails = currencyDetails;
  }

  onSetUserBalanceDetails(userBalancePocketDetails: UserProfileBalance): void {
    this.userTotalBalance =
      userBalancePocketDetails.realCashBalance +
      userBalancePocketDetails.crpBalance +
      userBalancePocketDetails.wrpBalance +
      userBalancePocketDetails.drpBalance;

    this.userTotalBonus =
      userBalancePocketDetails.crpBalance + userBalancePocketDetails.wrpBalance;
    this.withdrawableBalance =
      userBalancePocketDetails.realCashBalance +
      userBalancePocketDetails.drpBalance;

    this.userBalanceDetails = {
      userTotalBalance: this.userTotalBalance,
      userTotalBonus: this.userTotalBalance,
      withdrawableBalance: this.withdrawableBalance,
    };

    this.userBalanceUpdatedSubject.next("UserBalanceUpdated");
  }

  onSetUserLanguageCode(countryCode: string): void {
    this.userLanguageCode = countryCode;
  }

  onSetUserCountryCode(countryCode: string): void {
    this.userCountryCode = countryCode;
  }
}
