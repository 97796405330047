import { Action, createReducer, on } from "@ngrx/store";

// Actions
import { bannerLoaded } from "src/app/modules/banner/store/actions/banners.actions";

// Models
import { Banner } from "src/app/modules/banner/models/banner.model";

// Utilities
import {
  bannerZoneMappersLiveCasinoList,
  bannerZoneMappersCasinoList,
} from "src/app/modules/banner/utilities/banner-zone-mappers.utilities";

export const BannerStateFeatureKey = "banner";

// Reducer States
export interface BannerReducerState {
  bannerList: {
    [key: string]: {
      banners: Banner[];
      isLoaded: boolean;
      isLoading: boolean;
    };
  };
  bannerError: string;
}

export const initialState: BannerReducerState = {
  bannerList: {},
  bannerError: null,
};

// Reducer
const BannersReducer = createReducer(
  initialState,
  // Banners
  on(bannerLoaded, (state: BannerReducerState, { bannerRequest, banners }) => {
    let zoneId: string = bannerRequest.zoneId;

    if (bannerZoneMappersCasinoList.includes(zoneId)) {
      zoneId = "b_casino";
    }

    if (bannerZoneMappersLiveCasinoList.includes(zoneId)) {
      zoneId = "b_all-live";
    }

    return {
      ...state,
      bannerList: {
        ...state.bannerList,
        [`${zoneId}`]: {
          isLoaded: true,
          isLoading: false,
          banners,
        },
      },
      bannerError: null,
    };
  })
);

export function reducer(state: BannerReducerState | undefined, action: Action) {
  return BannersReducer(state, action);
}
