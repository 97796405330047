/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./live-casino.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../news/components/news-ticker/news-ticker.component.ngfactory";
import * as i3 from "../../../news/components/news-ticker/news-ticker.component";
import * as i4 from "../../../news/services/news-ticker.service";
import * as i5 from "@ngrx/store";
import * as i6 from "@angular/common";
import * as i7 from "../../../banner/components/custom-banner/custom-banner.component.ngfactory";
import * as i8 from "../../../banner/components/custom-banner/custom-banner.component";
import * as i9 from "../../../multi-languages/services/multi-language.service";
import * as i10 from "../../../banner/services/custom-banner.service";
import * as i11 from "../../../registration/services/registration.service";
import * as i12 from "../../../multi-languages/services/translation.service";
import * as i13 from "../../../user/services/user-details.service";
import * as i14 from "../../../auth/services/affiliate.service";
import * as i15 from "../../services/game-play.service";
import * as i16 from "../../../auth/services/session.service";
import * as i17 from "../../../shared/services/emitter.service";
import * as i18 from "../../../user/services/profile.service";
import * as i19 from "../../../shared/services/utility.service";
import * as i20 from "../live-casino-group-display/live-casino-group-display.component.ngfactory";
import * as i21 from "../live-casino-group-display/live-casino-group-display.component";
import * as i22 from "../live-casino-play-again/live-casino-play-again.component.ngfactory";
import * as i23 from "../live-casino-play-again/live-casino-play-again.component";
import * as i24 from "../../services/game-groups.service";
import * as i25 from "../../../shared/pipes/games-filter.pipe";
import * as i26 from "../../../shared/services/common.service";
import * as i27 from "@angular/router";
import * as i28 from "@ngx-translate/core";
import * as i29 from "../provider-lobby/provider-lobby.component.ngfactory";
import * as i30 from "../provider-lobby/provider-lobby.component";
import * as i31 from "../../../shared/services/mix-panel.service";
import * as i32 from "../../../shared/directives/market-based-component-display.directive";
import * as i33 from "../game-lobby/game-lobby.component.ngfactory";
import * as i34 from "../game-lobby/game-lobby.component";
import * as i35 from "../provider-filter/provider-filter.component.ngfactory";
import * as i36 from "../provider-filter/provider-filter.component";
import * as i37 from "./live-casino.component";
var styles_LiveCasinoComponent = [i0.styles];
var RenderType_LiveCasinoComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_LiveCasinoComponent, data: {} });
export { RenderType_LiveCasinoComponent as RenderType_LiveCasinoComponent };
function View_LiveCasinoComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "page-body no-padding"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-news-ticker", [], null, null, null, i2.View_NewsTickerComponent_0, i2.RenderType_NewsTickerComponent)), i1.ɵdid(2, 245760, null, 0, i3.NewsTickerComponent, [i4.NewsTickerService, i5.Store], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
function View_LiveCasinoComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LiveCasinoComponent_2)), i1.ɵdid(2, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isNewsTickerBannerAvailable; _ck(_v, 2, 0, currVal_0); }, null); }
function View_LiveCasinoComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-custom-banner", [["class", "clearfix"]], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onResize() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_CustomBannerComponent_0, i7.RenderType_CustomBannerComponent)), i1.ɵdid(2, 770048, null, 0, i8.CustomBannerComponent, [i9.MultiLanguageService, i10.CustomBannerService, i11.RegistrationService, i12.TranslationService, i13.UserDetailsService, i14.AffiliateService, i15.GamePlayService, i16.SessionService, i17.EmitterService, i18.ProfileService, i19.UtilityService, i5.Store], { zoneId: [0, "zoneId"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.zimplerMarketsList.indexOf(_co.languageCode) <= (0 - 1)) ? _co.activeLobbyName : "livecasino_banner_HIR"); _ck(_v, 2, 0, currVal_0); }, null); }
function View_LiveCasinoComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "stz-banner clearfix"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 5, "div", [["class", "stz-banner__item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "mobile-banner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "div", [["class", "banner-media skeleton-loader"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "desktop-banner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "banner-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "div", [["class", "banner-media skeleton-loader"]], null, null, null, null, null))], null, null); }
function View_LiveCasinoComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-live-casino-group-display", [["class", "liveCasino__game-group clearfix"]], null, [["window", "resize"], ["window", "orientationchange"], ["window", "scroll"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onWindowResize() !== false);
        ad = (pd_0 && ad);
    } if (("window:orientationchange" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).onRotate() !== false);
        ad = (pd_1 && ad);
    } if (("window:scroll" === en)) {
        var pd_2 = (i1.ɵnov(_v, 2).onWindowScroll() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i20.View_LiveCasinoGroupDisplayComponent_0, i20.RenderType_LiveCasinoGroupDisplayComponent)), i1.ɵdid(2, 770048, null, 0, i21.LiveCasinoGroupDisplayComponent, [i19.UtilityService, i16.SessionService, i5.Store], { finalLobbyGames: [0, "finalLobbyGames"], lobbyProviderData: [1, "lobbyProviderData"], activeLobbyName: [2, "activeLobbyName"], groupData: [3, "groupData"], games: [4, "games"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.finalLobbyGamesList; var currVal_1 = _co.activeLobbyProviderData; var currVal_2 = _co.activeLobbyName; var currVal_3 = _co.gameGGroupfilterByLobbyList[0]; var currVal_4 = _co.finalGamesList; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
function View_LiveCasinoComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-live-casino-play-again", [["class", "playAgain__wrapper"]], null, null, null, i22.View_LiveCasinoPlayAgainComponent_0, i22.RenderType_LiveCasinoPlayAgainComponent)), i1.ɵdid(2, 245760, null, 0, i23.LiveCasinoPlayAgainComponent, [i12.TranslationService, i24.GameGroupsService, i25.GamesFilterPipe, i15.GamePlayService, i19.UtilityService, i16.SessionService, i26.CommonService, i5.Store, i27.Router], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
function View_LiveCasinoComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "no-results text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "img", [["class", "p-16 no-results__logo"], ["height", "100%"], ["src", "/assets/img/logo.svg"], ["width", "100"]], [[8, "alt", 0], [8, "title", 0]], null, null, null, null)), i1.ɵpid(131072, i28.TranslatePipe, [i28.TranslateService, i1.ChangeDetectorRef]), i1.ɵpid(131072, i28.TranslatePipe, [i28.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(5, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), i1.ɵpid(131072, i28.TranslatePipe, [i28.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("general.site_name")), ""); var currVal_1 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 2, 1, i1.ɵnov(_v, 4).transform("general.site_name")), ""); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform("gamegroup.nogame_found")); _ck(_v, 6, 0, currVal_2); }); }
function View_LiveCasinoComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "game-card livecasino-card noHoverOnSkelton"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "game-card__body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [["class", "game-card__mask skeleton-loader"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "livecasino-card__footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "div", [["class", "livecasino-card--game-name skeleton-loader round-skelton"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "div", [["class", "livecasino-card--provider-name skeleton-loader round-skelton"]], null, null, null, null, null))], null, null); }
function View_LiveCasinoComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "liveCasino__game-group"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LiveCasinoComponent_9)), i1.ɵdid(2, 278528, null, 0, i6.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_0 = i1.ɵEMPTY_ARRAY.constructor(15); _ck(_v, 2, 0, currVal_0); }, null); }
function View_LiveCasinoComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-provider-lobby", [["class", "liveCasino__side-widgets--item"]], null, null, null, i29.View_ProviderLobbyComponent_0, i29.RenderType_ProviderLobbyComponent)), i1.ɵdid(1, 770048, null, 0, i30.ProviderLobbyComponent, [i12.TranslationService, i24.GameGroupsService, i31.MixPanelService, i15.GamePlayService, i19.UtilityService, i16.SessionService, i26.CommonService, i5.Store, i27.Router], { lobbyProviderData: [0, "lobbyProviderData"], games: [1, "games"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.activeLobbyProviderData; var currVal_1 = _co.finalLobbyGamesList; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_LiveCasinoComponent_11(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "liveCasino__seo clearfix"]], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.activeLobbyData.content; _ck(_v, 0, 0, currVal_0); }); }
export function View_LiveCasinoComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 2, null, View_LiveCasinoComponent_1)), i1.ɵdid(1, 16384, null, 0, i32.MarketBasedComponentDisplayDirective, [i1.ViewContainerRef, i1.TemplateRef], { marketBasedComponentDisplay: [0, "marketBasedComponentDisplay"] }, null), i1.ɵpod(2, { "flowType": 0, "langCode": 1 }), (_l()(), i1.ɵeld(3, 0, null, null, 23, "div", [["class", "app-container page-body homepage padding"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LiveCasinoComponent_3)), i1.ɵdid(5, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LiveCasinoComponent_4)), i1.ɵdid(7, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 1, "app-game-lobby", [["class", "clearfix"]], null, [[null, "callOnSelectedLobby"], ["document", "touchend"], ["document", "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("document:touchend" === en)) {
        var pd_0 = (i1.ɵnov(_v, 9).onClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("document:click" === en)) {
        var pd_1 = (i1.ɵnov(_v, 9).onClick($event) !== false);
        ad = (pd_1 && ad);
    } if (("callOnSelectedLobby" === en)) {
        var pd_2 = (_co.onSelectedLobby($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i33.View_GameLobbyComponent_0, i33.RenderType_GameLobbyComponent)), i1.ɵdid(9, 770048, null, 0, i34.GameLobbyComponent, [i24.GameGroupsService, i31.MixPanelService, i27.ActivatedRoute, i19.UtilityService, i16.SessionService, i26.CommonService, i5.Store, i27.Router], { callingFrom: [0, "callingFrom"] }, { callOnSelectedLobby: "callOnSelectedLobby" }), (_l()(), i1.ɵeld(10, 0, null, null, 14, "div", [["class", "liveCasino liveCasino__wrapper clearfix"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 8, "div", [["class", "liveCasino__games-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LiveCasinoComponent_5)), i1.ɵdid(13, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LiveCasinoComponent_6)), i1.ɵdid(15, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LiveCasinoComponent_7)), i1.ɵdid(17, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LiveCasinoComponent_8)), i1.ɵdid(19, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(20, 0, null, null, 4, "div", [["class", "liveCasino__side-widgets"]], null, null, null, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 1, "app-provider-filter", [["class", "liveCasino__side-widgets--item"]], null, [[null, "callOnProvidersChanged"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("callOnProvidersChanged" === en)) {
        var pd_0 = (_co.onProviderListChanged($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i35.View_ProviderFilterComponent_0, i35.RenderType_ProviderFilterComponent)), i1.ɵdid(22, 245760, null, 0, i36.ProviderFilterComponent, [i25.GamesFilterPipe, i31.MixPanelService, i19.UtilityService, i5.Store], null, { callOnProvidersChanged: "callOnProvidersChanged" }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LiveCasinoComponent_10)), i1.ɵdid(24, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LiveCasinoComponent_11)), i1.ɵdid(26, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 2, 0, _co.UserFlowTypes.ZIMPLER_FLOW, _co.languageCode); _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.languageCode; _ck(_v, 5, 0, currVal_1); var currVal_2 = !_co.languageCode; _ck(_v, 7, 0, currVal_2); var currVal_3 = "liveCasinoLobby"; _ck(_v, 9, 0, currVal_3); var currVal_4 = ((_co.finalGamesList && (_co.finalGamesList.length > 0)) && !_co.isLoading); _ck(_v, 13, 0, currVal_4); var currVal_5 = ((((_co.finalGamesList && (_co.finalGamesList.length === 0)) && !_co.isLoading) && (_co.activeLobbyName === "all-live")) && _co.isLastPlayedLiveGamesExist); _ck(_v, 15, 0, currVal_5); var currVal_6 = ((((_co.finalGamesList && (_co.finalGamesList.length === 0)) && !_co.isLoading) && _co.isLoaded) && (((_co.activeLobbyName === "all-live") && !_co.isLastPlayedLiveGamesExist) || (_co.activeLobbyName !== "all-live"))); _ck(_v, 17, 0, currVal_6); var currVal_7 = _co.isLoading; _ck(_v, 19, 0, currVal_7); _ck(_v, 22, 0); var currVal_8 = (_co.finalLobbyGamesList && (_co.finalLobbyGamesList.length > 0)); _ck(_v, 24, 0, currVal_8); var currVal_9 = (_co.activeLobbyData && _co.activeLobbyData.content); _ck(_v, 26, 0, currVal_9); }, null); }
export function View_LiveCasinoComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-live-casino", [], null, [["window", "orientationchange"], ["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:orientationchange" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onRotate() !== false);
        ad = (pd_0 && ad);
    } if (("window:resize" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).onResize() !== false);
        ad = (pd_1 && ad);
    } return ad; }, View_LiveCasinoComponent_0, RenderType_LiveCasinoComponent)), i1.ɵdid(1, 4440064, null, 0, i37.LiveCasinoComponent, [i9.MultiLanguageService, i12.TranslationService, i24.GameGroupsService, i4.NewsTickerService, i25.GamesFilterPipe, i19.UtilityService, i16.SessionService, i26.CommonService, i5.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LiveCasinoComponentNgFactory = i1.ɵccf("app-live-casino", i37.LiveCasinoComponent, View_LiveCasinoComponent_Host_0, {}, {}, []);
export { LiveCasinoComponentNgFactory as LiveCasinoComponentNgFactory };
