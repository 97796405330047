export interface PendingWithdrawalsRequest {
  merchantId: string;
  userId: string;
  sessionId: string;
  // SUCCESSFUL,CANCELLED
  states?: string;
  // CreditcardDeposit
  txType?: string;
  // yyyy-MM-dd HH:mm:ss
  maxDate?: string;
  // yyyy-MM-dd HH:mm:ss
  minDate?: string;
  // User's withdrawal transaction-id.
  transactionId?: string;
  method?: TransactionMethodTypes;
}

export enum TransactionMethodTypes {
  "Deposit" = "Deposit",
  "Withdrawal" = "Withdrawal",
}
