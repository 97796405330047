import { createFeatureSelector, createSelector } from "@ngrx/store";

// Reducers
import {
  UserStateFeatureKey,
  UserReducerState,
} from "src/app/modules/user/store/reducers/user.reducer";

// Select the Main State
const selectUserReducerState =
  createFeatureSelector<UserReducerState>(UserStateFeatureKey);

// User Data
export const selectAuthUserDataLoaded = createSelector(
  selectUserReducerState,
  (state: UserReducerState) => {
    return {
      userData: state.userData,
      isLoaded: state.isLoaded,
      isError: state.isError,
    };
  }
);

export const selectUserData = createSelector(
  selectUserReducerState,
  (state: UserReducerState) => {
    return state.userData;
  }
);
