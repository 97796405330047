export const onAppendNoScript = (trackingId: string): void => {
  let noScriptIFrame: HTMLScriptElement =
    document.querySelector("noscript iframe");

  if (
    noScriptIFrame &&
    noScriptIFrame.src.includes("https://www.googletagmanager.com/ns")
  ) {
    noScriptIFrame.src = `https://www.googletagmanager.com/ns.html?id=${trackingId}`;
  } else {
    let noscript: HTMLElement = document.createElement("noscript");

    let iframe: HTMLIFrameElement = document.createElement("iframe");

    iframe.src = `https://www.googletagmanager.com/ns.html?id=${trackingId}`;

    iframe.height = "0";

    iframe.width = "0";

    iframe.style.display = "none";

    iframe.style.visibility = "hidden";

    noscript.appendChild(iframe);

    document.body.appendChild(noscript);
  }
};

export const onSetGtmTagManager = (
  window: Window,
  document: Document,
  scriptParams: "script",
  library: "dataLayer",
  trackingId: string
): void => {
  if (window !== undefined) {
    window[library] = window[library] || window;

    window[library].push({
      "gtm.start": new Date().getTime(),
      event: "gtm.js",
    });
  }

  let dataLayer = library != "dataLayer" ? `&l=${library}` : "";

  let script: HTMLScriptElement = document.querySelector("script");

  if (script && script.src.includes("https://www.googletagmanager.com/gtm")) {
    script.src = `https://www.googletagmanager.com/gtm.js?id=${trackingId}${dataLayer}`;
  } else {
    let beforeScript: HTMLScriptElement =
      document.getElementsByTagName(scriptParams)[0];

    let afterScript: HTMLScriptElement = document.createElement(scriptParams);

    afterScript.async = true;

    afterScript.src = `https://www.googletagmanager.com/gtm.js?id=${trackingId}${dataLayer}`;

    beforeScript.parentNode.insertBefore(afterScript, beforeScript);
  }
};
