import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";

// Actions
import { languageCodeLoaded } from "src/app/modules/multi-languages/store/actions/languages.actions";

// API Interactors
import { ApiInteractors } from "src/app/models/interactors/api.interactor";

// Configurations
import { localStorageKeys } from "src/app/modules/multi-languages/configurations/localstorage-keys.configurations";
import {
  userProfileRegisteredLanguageConfigurations,
  localToCmsLanguageConfigurations,
} from "src/app/configurations/main.configurations";

// Environments
import { environment } from "src/environments/environment";

// Models
import { NavigationRoute } from "src/app/modules/shared/models/navigation/navigation.model";
import { ProfileBalance } from "src/app/modules/auth/models/profile-balance.model";
import { QueryParams } from "src/app/modules/shared/models/parameters.model";
import { LoggedIn } from "src/app/modules/auth/models/logged-in.model";

// Reducers
import { AppState } from "src/app/store/reducers";

// Selectors
import { selectLanguageCode } from "src/app/modules/multi-languages/store/selectors/languages.selectors";
import { selectAuthProfileBalance } from "src/app/modules/user/store/selectors/profile.selectors";

// Services
import { TranslationService } from "src/app/modules/multi-languages/services/translation.service";
import { UserDetailsService } from "src/app/modules/user/services/user-details.service";
import { AffiliateService } from "src/app/modules/auth/services/affiliate.service";
import { CommonService } from "src/app/modules/shared/services/common.service";
import { GtmService } from "src/app/modules/shared/services/gtm.service";

// Utilities
import { getQueryParams } from "src/app/modules/shared/utilities/query-parameter.utilities";

@Injectable({
  providedIn: "root",
})
export class LoginService {
  // API Interactions
  apiInteractor: ApiInteractors;

  // Strings
  userCountryLanguage: string = "";
  userCountryCode: string = "";
  languageCode: string = "";

  // Date
  userLoggedTime: Date;

  // Subscriptions
  userProfileLanguageSubscription: Subscription;
  subscription: Subscription;

  constructor(
    private translationService: TranslationService,
    private userDetailsService: UserDetailsService,
    private affiliateService: AffiliateService,
    private commonService: CommonService,
    private gtmService: GtmService,
    private httpClient: HttpClient,
    private store: Store<AppState>,
    private router: Router
  ) {
    this.apiInteractor = new ApiInteractors(this.httpClient);

    this.subscription = this.store
      .select(selectLanguageCode)
      .subscribe((languageCode: string) => {
        this.languageCode = languageCode;
      });
  }

  // -----------------------------------------------------------------
  // Get Methods
  getDecodedCurrentPath(): string {
    return decodeURIComponent(window.location.pathname);
  }

  // -----------------------------------------------------------------
  // Set Methods
  onLoginSuccessGtmEvents(loginResponse: LoggedIn): void {
    setTimeout(() => {
      this.userCountryCode = this.commonService.getUserCountry();

      if (this.userCountryCode) {
        this.gtmService.onGtmDataLayerEvent({
          event: "logged_in",
          userId: loginResponse.user.id,
          country: this.userCountryCode,
          userType: loginResponse.ecrCategory,
        });
      }
    }, 1000);
  }

  onNavigateToUserProfileLanguage(): void {
    this.userProfileLanguageSubscription = this.store
      .select(selectAuthProfileBalance)
      .subscribe((profileBalance: ProfileBalance) => {
        if (
          profileBalance &&
          profileBalance.profile &&
          profileBalance.profile.language
        ) {
          this.userCountryLanguage =
            userProfileRegisteredLanguageConfigurations[
              profileBalance.profile.language
            ];

          let userLanguage: string = this.userCountryLanguage
            ? this.userCountryLanguage
            : environment.defaultLanguage;

          const params: QueryParams = getQueryParams();

          if (userLanguage !== this.languageCode) {
            this.userDetailsService.onSetUserLanguageCode(userLanguage);

            this.store.dispatch(
              languageCodeLoaded({
                language: localToCmsLanguageConfigurations[userLanguage],
                languageCode: userLanguage,
              })
            );

            this.translationService.onUseLanguage(
              localToCmsLanguageConfigurations[this.languageCode]
            );

            localStorage.setItem(localStorageKeys.languageCode, userLanguage);

            const navigateAfterLogin: NavigationRoute =
              this.commonService.getNavigateAfterLogin();

            if (
              navigateAfterLogin &&
              navigateAfterLogin.type === "url" &&
              navigateAfterLogin.data.path != ""
            ) {
              this.affiliateService.onNavigateToLandingPage(
                params,
                userLanguage,
                navigateAfterLogin.data.path
              );
            } else {
              this.affiliateService.onNavigateToLandingPage(
                params,
                userLanguage
              );
            }
          }

          // If the user language is the same as the landing page market
          /*
            
            This code was implemented mainly for VIP / Group ID
            The reas

          */
          this.onEmptyNavigationAfterLogin();

          setTimeout(() => {
            if (params.ignoreRefresh !== "ignoreRefresh") {
              window.location.reload();
            }
          });
        }
      });
  }

  onEmptyNavigationAfterLogin() {
    let navigateData: NavigationRoute =
      this.commonService.getNavigateAfterLogin();

    if (
      navigateData &&
      (navigateData.type === "view" ||
        (navigateData.type === "url" &&
          navigateData.data.navigateTo != "promotions"))
    ) {
      this.commonService.onSetNavigateAfterLogin(undefined);
    }
  }

  onVerifyIsLandingPage(): void {
    const path: string = this.getDecodedCurrentPath();

    let params: QueryParams = {};

    if (window.location.search) {
      params = JSON.parse(
        `{"${decodeURI(
          window.location.search
            .substring(1)
            .replace(/&/g, '","')
            .replace(/=/g, '":"')
        )}}`
      );
    }

    const regFirst: RegExp = /^[a-z]{2}-[a-z]{2}$/;

    const regSecond: RegExp = /^[a-z]{2}-[a-z]{3}$/;

    let segmentsList: string[] = path.split("/");

    let languageCodeFromURL: string =
      this.getDecodedCurrentPath().split("/")[1];

    if (
      segmentsList &&
      segmentsList.length <= 2 &&
      (regFirst.test(languageCodeFromURL) ||
        regSecond.test(languageCodeFromURL))
    ) {
      if (sessionStorage.getItem("isUserVistedLandingPage")) {
        if (params) {
          this.router.navigate([path], { queryParams: params });
        } else {
          this.router.navigate([path]);
        }
      }
    }
  }

  // -----------------------------------------------------------------
  // On Destroy
  ngOnDestroy(): void {
    if (this.userProfileLanguageSubscription)
      this.userProfileLanguageSubscription.unsubscribe();

    if (this.subscription) this.subscription.unsubscribe();
  }
}
