import { Subscription } from "rxjs";
import { Store } from "@ngrx/store";
import {
  ViewEncapsulation,
  SimpleChange,
  EventEmitter,
  HostListener,
  ElementRef,
  Component,
  OnDestroy,
  OnChanges,
  ViewChild,
  Output,
  Input,
} from "@angular/core";

// Actions
import { bannerRequested } from "src/app/modules/banner/store/actions/banners.actions";

// Configurations
import { MenuConfigurationType } from "src/app/models/configurations/menu/menu-configuration-type.model";

// Constants
import { UserFlowTypes } from "src/app/constants/costants";

// Environments
import { environment } from "src/environments/environment";

// Libraries
import { SwiperConfigInterface } from "ngx-swiper-wrapper";
import Player from "@vimeo/player";

// Models
import { LobbyByPageNameConfigurations } from "src/app/models/configurations/menu/lobby-by-page-name-configuration.model";
import { BannerRequest } from "src/app/modules/banner/models/banner-request.model";
import { ProfileBalance } from "src/app/modules/auth/models/profile-balance.model";
import { BannerBonus } from "src/app/modules/banner/models/banner-bonus.model";
import { BannerZone } from "src/app/modules/banner/models/banner-zone.model";
import { UserData } from "src/app/modules/user/models/user-data.model";
import { Banner } from "src/app/modules/banner/models/banner.model";

// Reducers
import { AppState } from "src/app/store/reducers";

// Selectors
import { selectLanguageCode } from "src/app/modules/multi-languages/store/selectors/languages.selectors";
import {
  getBanner,
  getBannerList,
} from "src/app/modules/banner/store/selectors/banners.selectors";
import {
  selectAuthLoginIsLoggedOut,
  selectAuthLoginIsLoggedIn,
} from "src/app/modules/auth/store/selectors/auth.selectors";

// Services
import { MultiLanguageService } from "src/app/modules/multi-languages/services/multi-language.service";
import { TranslationService } from "src/app/modules/multi-languages/services/translation.service";
import { RegistrationService } from "src/app/modules/registration/services/registration.service";
import { CustomBannerService } from "src/app/modules/banner/services/custom-banner.service";
import { GamePlayService } from "src/app/modules/game-groups/services/game-play.service";
import { UserDetailsService } from "src/app/modules/user/services/user-details.service";
import { AffiliateService } from "src/app/modules/auth/services/affiliate.service";
import { EmitterService } from "src/app/modules/shared/services/emitter.service";
import { UtilityService } from "src/app/modules/shared/services/utility.service";
import { SessionService } from "src/app/modules/auth/services/session.service";
import { ProfileService } from "src/app/modules/user/services/profile.service";

// Utilities
import { replaceAllUtility } from "src/app/modules/shared/utilities/replace-all.utilities";

@Component({
  selector: "app-custom-banner",
  templateUrl: "./custom-banner.component.html",
  styleUrls: ["./custom-banner.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class CustomBannerComponent implements OnChanges, OnDestroy {
  // View Child
  @ViewChild("banner", { static: false }) cashier: ElementRef;

  // Outputs
  @Output() callOnBannerApiSuccess: EventEmitter<void> =
    new EventEmitter<void>();

  // Inputs
  @Input() callingFrom: string = "";
  @Input() zoneId: string = "";

  // Numbers
  userTotalBalance: number = 0;

  // Strings
  mediaUrl: string = environment.mediaUrl;
  affiliateOfferJsonText: string = "";
  getAffiliateValue: string = "";
  languageCode: string = "";

  // Booleans
  isShowBannerFromAffiliate: boolean = false;
  isLoggedIn: boolean = false;
  isLoading: boolean = false;

  // Arrays
  zimplerMarketsList: string[] = [];
  activeBannersList: Banner[] = [];

  // Enums
  deviceType: "desktop" | "mobile" = "desktop";
  UserFlowTypes = UserFlowTypes;

  // Objects
  swiperBanner: SwiperConfigInterface = {
    slidesPerView: 1,
    slidesPerGroup: 1,
    loop: false,
    resistanceRatio: 0,
    touchRatio: 2,
    autoplay: {
      delay: 30000,
      disableOnInteraction: false,
    },
    pagination: {
      el: ".banner-pagination",
      clickable: true,
    },
    breakpoints: {
      767: {
        pagination: false,
        touchRatio: 2,
      },
    },
  };
  profileDetails: UserData;

  // Subscriptions
  bannerSubscription: Subscription;

  subscriptions: Subscription[] = [];

  constructor(
    private multiLanguageService: MultiLanguageService,
    private customBannerService: CustomBannerService,
    private registrationService: RegistrationService,
    private translationService: TranslationService,
    private userDetailsService: UserDetailsService,
    private affiliateService: AffiliateService,
    private gamePlayService: GamePlayService,
    private sessionService: SessionService,
    private emitterService: EmitterService,
    private profileService: ProfileService,
    private utilityService: UtilityService,
    private store: Store<AppState>
  ) {}

  // -----------------------------------------------------------------
  // Lifecycle Hooks
  ngOnInit(): void {
    this.isLoggedIn = this.sessionService.getIsUserLoggedIn();

    this.zimplerMarketsList = environment.zimplerMarkets;

    this.languageCode = this.multiLanguageService.getLanguageCode();

    if (this.userDetailsService.getUserTotalBalance()) {
      this.userTotalBalance = this.userDetailsService.getUserTotalBalance();
    }

    this.getWindowType();

    this.subscriptions = [
      this.store
        .select(selectAuthLoginIsLoggedIn)
        .subscribe((isLoggedIn: boolean) => (this.isLoggedIn = isLoggedIn)),
      this.store
        .select(selectAuthLoginIsLoggedOut)
        .subscribe((isLoggedOut: boolean) => {
          if (isLoggedOut) {
            this.isLoggedIn = false;

            this.onLoadAffiliateBanner(false);

            this.onBannerCalling();
          }
        }),
      this.store
        .select(selectLanguageCode)
        .subscribe((languageCode: string) => {
          this.languageCode = languageCode;

          this.onLoadAffiliateBanner(false);
        }),
      this.userDetailsService.userBalanceUpdatedSubject$.subscribe(
        (balanceUpdateMessage: string) => {
          if (balanceUpdateMessage == "UserBalanceUpdated") {
            this.userTotalBalance =
              this.userDetailsService.getUserTotalBalance();

            this.utilityService.toggleBannerDepositBtn(this.userTotalBalance);
          }

          if (!this.utilityService.isPnpFlow()) {
            /*
              Instead of calling inside login/logout sucess event, we call in side
              profile update because this reloadbanner depends on usercountry(for login)
              or geocountry(for logout)
            
              Note: This event will be triggered automatically after
              login & logout
            */
            this.onLoadAffiliateBanner(false);

            this.onReloadBanner(true);
          }
        }
      ),
      this.emitterService.isSuccessfulDepositSubject$.subscribe(() => {
        if (!this.utilityService.isPnpFlow()) {
          this.onReloadBanner(true, true);

          this.onReloadAffiliateBanner();
        }
      }),
    ];
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
    if (
      changes["zoneId"] &&
      changes["zoneId"].previousValue != changes["zoneId"].currentValue
    ) {
      this.activeBannersList = undefined;

      this.zoneId = changes["zoneId"].currentValue;

      this.zoneId = `b_${this.zoneId}`;

      this.onReloadBanner();
    }

    if (
      changes["callingFrom"] &&
      changes["callingFrom"].previousValue !=
        changes["callingFrom"].currentValue
    ) {
      this.callingFrom = changes["callingFrom"].currentValue;
    }
  }

  // -----------------------------------------------------------------
  // Host Listeners
  @HostListener("window:resize") onResize(): void {
    this.getWindowType();
  }

  // -----------------------------------------------------------------
  // Window Type
  getWindowType(): void {
    let clientWidth: number = document.body.clientWidth;

    if (clientWidth >= 1023) {
      this.deviceType = "desktop";
    } else {
      this.deviceType = "mobile";
    }

    if (
      (clientWidth >= 500 &&
        window.matchMedia("(orientation: landscape)").matches) ||
      (clientWidth >= 768 &&
        window.matchMedia("(orientation: portrait)").matches)
    ) {
      this.deviceType = "desktop";
    }
  }

  // -----------------------------------------------------------------
  // Set Methods
  onReloadAffiliateBanner(): void {
    if (
      this.userDetailsService.getUserProfileDetails() &&
      this.userDetailsService.getUserProfileDetails().userStatus === "play"
    ) {
      setTimeout(() => {
        this.onLoadAffiliateBanner(true);
      }, 5000);
    }
  }

  onReloadBanner(
    isforce: boolean = false,
    refreshProfileCache?: boolean
  ): void {
    if (this.customBannerService.getIsProfileRefreshRequired()) {
      refreshProfileCache = true;

      isforce = true;

      this.customBannerService.onSetIsProfileRefreshRequired(false);
    }

    /* 
      this if condition is to avoid calling the banner API when the child zones are called. Only parent zones
      are supported for banners. This avoids the banner being refreshed everytime a category is chosen within a lobby 
    */
    this.bannerSubscription = this.store
      .select(getBanner({ zoneId: this.zoneId }))
      .subscribe(
        (list: {
          isLoading: boolean;
          isLoaded: boolean;
          banners: Banner[];
        }) => {
          if (list) {
            if (list.isLoaded && !list.isLoading) {
              if (this.customBannerService.getIsProfileRefreshRequired()) {
                refreshProfileCache = true;

                this.customBannerService.onSetIsProfileRefreshRequired(false);
              }

              this.onShowBanner(list);
            }

            if (!list.isLoaded && list.isLoading) {
              if (this.zoneId && !this.isShowBannerFromAffiliate) {
                const queryRequest: BannerZone = refreshProfileCache
                  ? { zoneId: this.zoneId, refreshProfileCache: true }
                  : { zoneId: this.zoneId };

                this.onGetBannersByZoneId(queryRequest);
              }
            }
          }
        }
      );

    // if (this.zoneId) {
    //   const queryRequest: BannerZone = refreshProfileCache
    //     ? { zoneId: this.zoneId, refreshProfileCache: true }
    //     : { zoneId: this.zoneId };

    //   this.onGetBannersByZoneId(queryRequest, isforce);
    // }
  }

  onLoadAffiliateBanner(isRefreshProfileCache?: boolean): void {
    if (this.isLoggedIn) {
      this.onGetUserProfileData(isRefreshProfileCache);
    } else {
      this.onGetUserAffiliateValue();
    }
  }

  onGetUserProfileData(isRefreshProfileCache?: boolean): void {
    if (
      !isRefreshProfileCache &&
      this.userDetailsService.getUserProfileDetails()
    ) {
      this.getAffiliateValue = undefined;

      this.profileDetails = this.userDetailsService.getUserProfileDetails();

      this.onGetUserAffiliateValue();
    } else {
      this.profileService
        .onGetProfileBalanceCurrency()
        .subscribe((profileBalanceResponse: ProfileBalance) => {
          if (profileBalanceResponse && profileBalanceResponse.profile) {
            this.getAffiliateValue = undefined;

            this.profileDetails = profileBalanceResponse.profile;

            this.onGetUserAffiliateValue();
          } else {
            this.isShowBannerFromAffiliate = false;
          }
        });
    }
  }

  onOpenRegistrationPopup(): void {
    this.registrationService.onOpenRegistration();
  }

  onGetUserAffiliateValue(): void {
    let affilateLanguage: string =
      this.affiliateService.getAffiliateCookies("affiliateLang");

    let affiliateValuesList: string[] = this.translationService
      .get("affiliateids.affiliateValue")
      .split(",");

    if (this.affiliateService.getAffiliateCookies("affId")) {
      this.getAffiliateValue = this.affiliateService
        .getAffiliateCookies("affId")
        .split("_")[0];
    } else if (
      this.profileDetails &&
      this.profileDetails.userStatus === "play" &&
      this.profileDetails.affiliateLinkID
    ) {
      this.getAffiliateValue =
        this.profileDetails.affiliateLinkID.split("_")[0];
    }

    if (
      (this.zimplerMarketsList.indexOf(
        this.multiLanguageService.getLanguageCode()
      ) <= -1 &&
        this.getAffiliateValue &&
        affilateLanguage &&
        affiliateValuesList &&
        affiliateValuesList.indexOf(this.getAffiliateValue) > -1 &&
        affilateLanguage === this.languageCode) ||
      (this.isLoggedIn && this.getAffiliateValue)
    ) {
      this.isShowBannerFromAffiliate = true;

      this.onRenderAffiliteJsonOfferTextAsHtml();
    } else {
      this.isShowBannerFromAffiliate = false;
    }
  }

  onGetBannersByZoneId(bannerRequest: BannerRequest): void {
    this.activeBannersList = undefined;

    this.isLoading = true;

    this.store.dispatch(bannerRequested({ bannerRequest }));
  }

  onShowBanner(list: {
    isLoading: boolean;
    isLoaded: boolean;
    banners: Banner[];
  }) {
    if (list && list.isLoaded && !list.isLoading) {
      this.isLoading = false;

      let bannerList: Banner[] = list.banners;

      if (bannerList && bannerList.length > 0) {
        this.activeBannersList = bannerList;

        this.activeBannersList = this.activeBannersList.map(
          (banner: Banner) => {
            let content: string = replaceAllUtility(banner.content.toString(), {
              "{{site_url}}": environment.siteUrl,
              "{{media_url}}": environment.mediaUrl,
            });

            /*
            adding click event to whole banner container inorder to listen all click
            events within the banner...
          */
            setTimeout(() => {
              this.utilityService.onToggleCMSButtons();

              this.utilityService.toggleBannerDepositBtn(this.userTotalBalance);

              const iframe: Element = document.querySelector(
                `.dynamicBannerContainer-${banner.id} iframe.videoIframe`
              );

              if (iframe) {
                const player: Player = new Player(iframe, {
                  background: 1,
                  muted: 1,
                  loop: 0,
                  title: "promotional-banners",
                });
              }

              if (this.callingFrom === "allgames-page") {
                this.callOnBannerApiSuccess.emit();
              }
            });

            return {
              ...banner,
              content: content,
            };
          }
        );

        this.customBannerService.onBroadCastIsBannerAvialable(true);
      } else {
        this.activeBannersList = [];

        this.customBannerService.onBroadCastIsBannerAvialable(false);
      }
    }
  }

  onJoinBanners(event: Event): void {
    let target: HTMLInputElement = <HTMLInputElement>event.target;

    let routerLink: string = target.getAttribute("routerLink");

    let data: BannerBonus;

    if (routerLink === "deposit" && target.getAttribute("bonusCode")) {
      data = { bonusCode: target.getAttribute("bonusCode") };
    }

    /* 
      We are using this registration routing here because of circular
      dependency
    */
    if (
      routerLink &&
      (routerLink === "registration" || routerLink === "register")
    ) {
      if (!this.isLoggedIn && !this.utilityService.isPnpFlow()) {
        this.registrationService.onOpenRegistration();
      } else {
        this.utilityService.initiateDeposit(true);
      }
    } else if (routerLink) {
      if (
        routerLink &&
        this.multiLanguageService.getActiveLobby(routerLink.split("/")[1]) ===
          "game"
      ) {
        this.gamePlayService.onClearGameWindowData();
      }

      /*
        Below Utility Handler decide routerLink event action on banner
        like registration button , login btn, game play btn deposit etc...
      */
      this.utilityService.onCmsRoutingHandler(routerLink, data);
    }
  }

  onBannerCalling(): void {
    const menuConfigurationType: MenuConfigurationType =
      this.utilityService.getBrandMenuConfigurationType("lobbyByPageName");

    const lobby: string[] = (
      menuConfigurationType as LobbyByPageNameConfigurations
    ).livecasinolobbies;

    if (this.zoneId) {
      const activeLobbyname: string = this.zoneId.replace("b_", "");

      if (
        menuConfigurationType &&
        (menuConfigurationType as LobbyByPageNameConfigurations)
          .livecasinolobbies &&
        lobby.indexOf(activeLobbyname) <= -1
      ) {
        this.onReloadBanner(true);
      }
    }
  }

  onRenderAffiliteJsonOfferTextAsHtml(): void {
    this.affiliateOfferJsonText = this.translationService.get(
      `affiliate.${this.getAffiliateValue}_offer`
    );
  }

  // -----------------------------------------------------------------
  // On Destroy
  ngOnDestroy(): void {
    if (this.bannerSubscription) this.bannerSubscription.unsubscribe();

    this.subscriptions.forEach((subscription: Subscription) =>
      subscription.unsubscribe()
    );
  }
}
