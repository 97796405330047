<app-side-menu-modal
  [customClass]="'activeBonuses'"
  [sideMenuTitle]="'general.bonuses'"
>
  <!-- modal body -->
  <ng-container sideMenuBody>
    <ng-container
      *ngFor="
        let bonus of activeBonusDetailsList;
        let i = index
      "
    >
      <div class="bonus-card">
        <div class="bonus-card__header">
          <span class="bonus-card--user">
            <ng-container *ngIf="!bonus.isFreeSpinBonus">
              <img
                src="/assets/img/rewards/bonus-img/{{
                  bonus.bonusCategory === 'CASHOUT_RESTRICT'
                    ? 'welcome-bonus-deposit-restrict'
                    : 'welcome-bonus-release-restrict'
                }}.png"
                alt="{{ 'general.site_name' | translate }}"
                title="{{
                  'general.site_name' | translate
                }}"
              />
            </ng-container>

            <ng-container *ngIf="bonus.isFreeSpinBonus">
              <img
                src="/assets/img/rewards/bonus-img/freespins-bonus.png"
                alt="{{ 'general.site_name' | translate }}"
                title="{{
                  'general.site_name' | translate
                }}"
              />
            </ng-container>
          </span>

          <div class="bonus-card--description">
            <h5 class="m-b-4">{{ bonus.bonusName }}</h5>

            <p>
              <b>{{ "bonus.received" | translate }}</b>
              {{
                bonus.offeredCRP
                  | currencyformat: currencySymbol
              }}
              {{ "common.on" | translate }}
              {{ bonus.offeredDate | date: "dd/MM/yyyy" }}
              {{ "common.at" | translate }}
              {{ bonus.offeredDate | date: "HH:mm" }}
            </p>

            <p>
              <b>{{ "bonus.Expires" | translate }}</b>
              {{ "common.on" | translate }}
              {{ bonus.expiredDate | date: "dd/MM/yyyy" }}
              {{ "common.at" | translate }}
              {{ bonus.expiredDate | date: "HH:mm" }}
            </p>
          </div>
        </div>

        <div class="bonus-card__body">
          <div class="progress">
            <h3 class="m-b-8">
              {{ "bonus.bonusWin" | translate }}
              {{
                bonus.currentCRP
                  | currencyformat: currencySymbol
              }}
            </h3>

            <div class="progress--bar">
              <span
                class="progress--value fade-in-right"
                [ngStyle]="{
                  width: bonus.wageredPercent + '%'
                }"
              >
              </span>
            </div>

            <span class="progress--percentage"
              >{{ bonus.wageredPercent | number: "1.0-2" }}%
            </span>
          </div>

          <div class="right">
            <button
              class="btn btn-primary btn-round btn-sm"
              aria-label="Forfiet"
              (click)="
                onOpenConfirmDialogue(bonus.ecrBonusId)
              "
            >
              {{ "bonus.frorfeit" | translate }}
            </button>
          </div>
        </div>

        <div class="bonus-card__footer m-t-16">
          <h3 class="wagering">
            {{ "bonus.wagering_left" | translate }}
            {{
              bonus.distanceToRelease
                | currencyformat: currencySymbol
            }}
          </h3>
        </div>
      </div>
    </ng-container>

    <ng-container
      *ngIf="
        activeBonusDetailsList &&
        activeBonusDetailsList.length === 0
      "
    >
      <div class="bonus-card valign-center">
        <p class="no-data valign-center">
          <strong>
            {{ "general.no_bonus_found" | translate }}
          </strong>
        </p>
      </div>
    </ng-container>

    <ng-container *ngIf="isLoading">
      <div class="bonus-card">
        <div class="bonus-card__header">
          <span
            class="bonus-card--user skeleton-loader loading"
          ></span>

          <div class="bonus-card--description">
            <p class="offer-name skeleton-loader"></p>

            <h5 class="skeleton-loader"></h5>
          </div>
        </div>

        <div class="bonus-card__body">
          <div class="progress">
            <h3 class="skeleton-loader"></h3>
            <div
              class="progress--bar skeleton-loader"
            ></div>

            <span
              class="progress--percentage skeleton-loader"
            ></span>
          </div>
          <div class="right">
            <button
              class="btn btn-sm skeleton-loader loading"
              aria-label="Loader"
            ></button>
          </div>
        </div>

        <div class="bonus-card__footer">
          <p class="skeleton-loader"></p>
          <p class="skeleton-loader"></p>
        </div>
      </div>
    </ng-container>
  </ng-container>
</app-side-menu-modal>

<ng-container *ngIf="isBonusForfeit">
  <section class="c-dialog__backdrop atLeftMenu">
    <div class="c-dialog">
      <div class="c-dialog__header">
        <img
          src="/assets/img/logo.svg"
          alt="{{ 'general.site_name' | translate }}"
          width="100%"
          height="100%"
        />
      </div>

      <div class="c-dialog__body">
        <p>{{ "bonus.forfeit_description" | translate }}</p>
      </div>

      <div class="c-dialog__footer">
        <a
          class="btn-yes"
          aria-label="Keep bonus"
          (click)="onCloseConfirmDialogue()"
        >
          {{ "bonus.keep_bonus" | translate }}
        </a>

        <a
          class="btn-no bonusForfeitCancel"
          aria-label="Forfiet bonus"
          (click)="onDropBonus()"
        >
          {{ "bonus.forfeit_bonus" | translate }}
        </a>
      </div>
    </div>
  </section>
</ng-container>
