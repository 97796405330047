<section
  class="popout__outerWrap promotion-page fade-in all-cards clearfix"
>
  <div
    id="pageHeader"
    #pageHeader
    class="popout__header isFixed"
    [ngClass]="{ pageHeaderBg: isPageHeaderBg }"
  >
    <div class="popout__header--title promotions">
      {{ "promotion.promotions" | translate }}
    </div>

    <button
      class="btn btn-circle isMobileShow close-btn closePopoutView"
      aria-label="Close"
      (click)="onBackToHome()"
    >
      <i class="icon-close"></i>
    </button>
  </div>

  <!-- id="popoutOverlay" #popupOver -->
  <div class="padding promotion-page popout__body clearfix">
    <ng-container *ngIf="!isLoading">
      <div
        class="promotion-item clearfix"
        *ngFor="let promotion of promotionList"
      >
        <div class="promotion-card">
          <div
            class="promotion-body"
            [innerHtml]="promotion.snippet"
          ></div>

          <button
            class="btn btn-md btn-primary promotion--cta btn-round"
            aria-label="Read more"
            (click)="onGoToPromotionPage(promotion.url)"
          >
            {{ "promotion.read_more" | translate }}
          </button>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="isLoading">
      <div
        class="promotion-item isLoading clearfix"
        *ngFor="
          let number of [].constructor(6);
          let i = index
        "
      >
        <div class="promotion-card">
          <div class="promotion__img skeleton-loader"></div>
          <div class="promotion__body">
            <h4
              class="promotion--title round-skelton skeleton-loader"
            ></h4>
            <div class="promotion--text">
              <p
                class="skeleton-loader t1 round-skelton"
              ></p>
              <p
                class="skeleton-loader t1 round-skelton"
              ></p>
              <p
                class="skeleton-loader t2 round-skelton"
              ></p>
            </div>

            <button
              class="btn btn-md btn-primary promotion--cta btn-round skeleton-loader"
              aria-label="loader"
            ></button>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container
      *ngIf="promotionList && promotionList.length === 0"
    >
      <div class="no-results text-center">
        {{
          "promotion.nopromotions_configured" | translate
        }}
      </div>
    </ng-container>
  </div>
</section>
