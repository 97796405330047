import { Observable, Subject, Subscription, throwError } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { catchError, map } from "rxjs/operators";
import { Injectable } from "@angular/core";

// API Interactors
import { ApiInteractors } from "src/app/models/interactors/api.interactor";

// Configurations
import { localStorageKeys } from "src/app/modules/multi-languages/configurations/localstorage-keys.configurations";

// Enums
import { StatusResponse } from "src/app/models/api/status.response";

// Models
import { CashierMakeWalletWithdrwalResponse } from "src/app/modules/account/models/cashier-make-wallet-withdrawal/cashier-make-wallet-withdrawal-response.model";
import { CashierMakeWalletWithdrwalRequest } from "src/app/modules/account/models/cashier-make-wallet-withdrawal/cashier-make-wallet-withdrawal-request.model";
import { PayNPlayTransactionStatusResponse } from "src/app/modules/account/models/pay-n-play-transaction-status/pay-n-play-transaction-status-response.model";
import { PayNPlayTransactionStatusRequest } from "src/app/modules/account/models/pay-n-play-transaction-status/pay-n-play-transaction-status-request.model";
import { PayNPlayFromTransactionResponse } from "src/app/modules/account/models/pay-n-play-from-transaction/pay-n-play-from-transaction-response.model";
import { PayNPlayFromTransactionRequest } from "src/app/modules/account/models/pay-n-play-from-transaction/pay-n-play-from-transaction-request.model";
import { QuickRegisterZimplerResponse } from "src/app/modules/account/models/quick-register-zimpler/quick-register-zimpler-response.model";
import { QuickRegisterZimplerRequest } from "src/app/modules/account/models/quick-register-zimpler/quick-register-zimpler-request.model";
import { ZimplerPayAndPlayResponse } from "src/app/modules/account/models/zimpler-pay-and-play/zimpler-pay-and-play-response.model";
import { ZimplerPayAndPlayRequest } from "src/app/modules/account/models/zimpler-pay-and-play/zimpler-pay-and-play-request.model";
import { PnPMinMaxLimitsResponse } from "src/app/modules/account/models/pnp-min-max-limits/pnp-min-max-limits-response.model";
import { RevertWithdrawalResponse } from "src/app/modules/account/models/revert-withdrawal/revert-withdrawal-response.model";
import { PnPMinMaxLimitsRequest } from "src/app/modules/account/models/pnp-min-max-limits/pnp-min-max-limits-request.model";
import { RevertWithdrawalRequest } from "src/app/modules/account/models/revert-withdrawal/revert-withdrawal-request.model";
import { PendingCashoutsResponse } from "src/app/modules/account/models/pending-cashouts/pending-cashouts-response.model";
import { PendingCashoutsRequest } from "src/app/modules/account/models/pending-cashouts/pending-cashouts-request.model";
import { PayNPlayLoginResponse } from "src/app/modules/account/models/pay-n-play-login/pay-n-play-login-response.model";
import { PayNPlayLoginRequest } from "src/app/modules/account/models/pay-n-play-login/pay-n-play-login-request.model";
import { CashierDetailsRequest } from "src/app/modules/account/models/cashier-details/cashier-details-request.model";
import { PnPMinMaxLimits } from "src/app/modules/account/models/pnp-min-max-limits/pnp-min-max-limits.model";
import { InitiateDeposit } from "src/app/modules/account/models/initiate-deposit/initiate-deposit.model";
import {
  CashierDetailsResponse,
  CashoutMethod,
} from "src/app/modules/account/models/cashier-details/cashier-details-response.model";
import { ProfileBalance } from "src/app/modules/auth/models/profile-balance.model";

// Services
import { CashbackPromoService } from "src/app/modules/rewards/services/cashback-promo.service";
import { SocketService } from "src/app/modules/shared/services/socket.service";
import { ProfileService } from "src/app/modules/user/services/profile.service";
import { GtmService } from "src/app/modules/shared/services/gtm.service";

@Injectable({
  providedIn: "root",
})
export class PayNPlayCashierService {
  // API Interactions
  apiInteractor: ApiInteractors;

  // Strings
  userTractionFetchingRequired: string = "";

  // Date
  userLoggedTime: Date;

  // Objects
  depositLimits: PnPMinMaxLimits = {
    minLimit: 0,
    maxLimit: 0,
  };
  cashoutLimits: PnPMinMaxLimits = {
    minLimit: 0,
    maxLimit: 0,
  };

  // --------------------------------------------------------
  // Subject - Initialize Txn
  private initiateTxnSubject: Subject<string> = new Subject<string>();
  public initiateTxnSubject$: Observable<string> =
    this.initiateTxnSubject.asObservable();

  // --------------------------------------------------------
  // Subject - Deposit
  private initiateDepositSubject: Subject<InitiateDeposit> =
    new Subject<InitiateDeposit>();
  public initiateDepositSubject$: Observable<InitiateDeposit> =
    this.initiateDepositSubject.asObservable();

  // --------------------------------------------------------
  // Subject - Withdraw
  private initiateWithdrawSubject: Subject<void> = new Subject<void>();
  public initiateWithdrawSubject$: Observable<void> =
    this.initiateWithdrawSubject.asObservable();

  // --------------------------------------------------------
  // Subject - Process User Transaction
  private processUserTransactionSubject: Subject<string> =
    new Subject<string>();
  public processUserTransactionSubject$: Observable<string> =
    this.processUserTransactionSubject.asObservable();

  // --------------------------------------------------------
  // Subject - Zimpler Process Completed
  private zimplerProcessCompletedSubject: Subject<void> = new Subject<void>();
  public zimplerProcessCompletedSubject$: Observable<void> =
    this.zimplerProcessCompletedSubject.asObservable();

  // Subscriptions
  subscription: Subscription;

  constructor(
    private cashbackPromoService: CashbackPromoService,
    private profileService: ProfileService,
    private socketService: SocketService,
    private gtmService: GtmService,
    private httpClient: HttpClient
  ) {
    this.apiInteractor = new ApiInteractors(this.httpClient);
  }

  // -----------------------------------------------------------------
  // Get Methods
  getUserLoggedTime(): Date {
    return this.userLoggedTime;
  }

  // -----------------------------------------------------------------
  // Get Observables
  onGetPNPMinMaxTxnLimits(
    pnpMinMaxLimitsRequest: PnPMinMaxLimitsRequest
  ): Observable<PnPMinMaxLimits> {
    return this.apiInteractor
      .get<PnPMinMaxLimitsRequest, PnPMinMaxLimitsResponse>(
        `/ajax/PayAndPlayTrustly/getPNPMinMaxTxnLimits`,
        pnpMinMaxLimitsRequest
      )
      .pipe(
        map((response: PnPMinMaxLimitsResponse) => {
          if (
            response.status === StatusResponse.SUCCESS &&
            response.minMaxTxnLimitsData &&
            response.minMaxTxnLimitsData[0]
          ) {
            this.depositLimits = {
              minLimit: response.minMaxTxnLimitsData[0].minTxnLimit,
              maxLimit: response.minMaxTxnLimitsData[0].maxTxnLimit,
            };
          }

          return this.depositLimits;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  onGetPendingCashouts(
    pendingCashoutsRequest: PendingCashoutsRequest
  ): Observable<PendingCashoutsResponse[]> {
    return this.apiInteractor
      .get<PendingCashoutsRequest, PendingCashoutsResponse[]>(
        `/ajax/cashier/getPendingCashouts`,
        pendingCashoutsRequest
      )
      .pipe(
        map((response: PendingCashoutsResponse[]) => {
          return response;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  onGetWithdrawalReversal(
    revertWithdrawalRequest: RevertWithdrawalRequest
  ): Observable<RevertWithdrawalResponse> {
    return this.apiInteractor
      .post<RevertWithdrawalRequest, RevertWithdrawalResponse>(
        `/ajax/Cashier/revertWithdrawal`,
        revertWithdrawalRequest
      )
      .pipe(
        map((response: RevertWithdrawalResponse) => {
          return response;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  onGetCashoutTxnLimits(
    cashierDetailsRequest: CashierDetailsRequest
  ): Observable<PnPMinMaxLimits> {
    return this.apiInteractor
      .get<CashierDetailsRequest, CashierDetailsResponse>(
        `/ajax/cashier/getCashierDetails`,
        cashierDetailsRequest
      )
      .pipe(
        map((response: CashierDetailsResponse) => {
          if (
            response &&
            response.cashierData &&
            response.cashierData.cashoutMethods
          ) {
            let cashoutMethod: CashoutMethod =
              response.cashierData.cashoutMethods.ZIMPLER_DIRECT;

            this.cashoutLimits = {
              minLimit: +cashoutMethod.limits.minTxnLimit,
              maxLimit: +cashoutMethod.limits.maxTxnLimit,
            };
          }

          return this.cashoutLimits;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  onZimplerPayAndPlay(
    zimplerPayAndPlayRequest: ZimplerPayAndPlayRequest
  ): Observable<ZimplerPayAndPlayResponse> {
    return this.apiInteractor
      .post<ZimplerPayAndPlayRequest, ZimplerPayAndPlayResponse>(
        `/ajax/zimpler/zimplerPayAndPlayInit`,
        zimplerPayAndPlayRequest
      )
      .pipe(
        map((response: ZimplerPayAndPlayResponse) => {
          return response;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  onGetPNPUserFromTransaction(
    payNPlayFromTransactionRequest: PayNPlayFromTransactionRequest
  ): Observable<PayNPlayFromTransactionResponse> {
    return this.apiInteractor
      .get<PayNPlayFromTransactionRequest, PayNPlayFromTransactionResponse>(
        `/ajax/payAndPlayTrustly/getPNPUserFromTransaction`,
        payNPlayFromTransactionRequest
      )
      .pipe(
        map(
          (
            payNPlayFromTransactionResponse: PayNPlayFromTransactionResponse
          ) => {
            return payNPlayFromTransactionResponse;
          }
        ),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  onGetQuickRegisterZimpler(
    quickRegisterZimplerRequest: QuickRegisterZimplerRequest
  ): Observable<QuickRegisterZimplerResponse> {
    return this.apiInteractor
      .post<QuickRegisterZimplerRequest, QuickRegisterZimplerResponse>(
        `/ajax/zimpler/quickRegisterZimpler`,
        quickRegisterZimplerRequest
      )
      .pipe(
        map((quickRegisterZimplerResponse: QuickRegisterZimplerResponse) => {
          return quickRegisterZimplerResponse;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  onGetZimplerWithdrawal(
    cashierMakeWalletWithdrwalRequest: CashierMakeWalletWithdrwalRequest
  ): Observable<CashierMakeWalletWithdrwalResponse> {
    return this.apiInteractor
      .post<
        CashierMakeWalletWithdrwalRequest,
        CashierMakeWalletWithdrwalResponse
      >(`/ajax/cashier/makeWalletWithdrawal`, cashierMakeWalletWithdrwalRequest)
      .pipe(
        map(
          (
            cashierMakeWalletWithdrwalResponse: CashierMakeWalletWithdrwalResponse
          ) => {
            return cashierMakeWalletWithdrwalResponse;
          }
        ),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  onPayNPlayLogin(
    payNPlayLoginRequest: PayNPlayLoginRequest
  ): Observable<PayNPlayLoginResponse> {
    return this.apiInteractor
      .get<PayNPlayLoginRequest, PayNPlayLoginResponse>(
        `/ajax/PayAndPlayTrustly/getPNPThirdPartyLogin`,
        payNPlayLoginRequest
      )
      .pipe(
        map((payNPlayLoginResponse: PayNPlayLoginResponse) => {
          if (
            payNPlayLoginResponse &&
            payNPlayLoginResponse.status === StatusResponse.SUCCESS
          ) {
            this.socketService.onConnectToSockets(
              payNPlayLoginResponse.pragmaticUrl,
              payNPlayLoginResponse.pragmaticSessionId,
              true
            );

            this.userLoggedTime = new Date();

            localStorage.setItem(
              localStorageKeys.loggedInTime,
              this.userLoggedTime.toString()
            );

            this.onPushGtmLogin(payNPlayLoginResponse);

            localStorage.setItem(localStorageKeys.stz_user, "true");

            if (payNPlayLoginResponse.lastAuthTime) {
              this.cashbackPromoService.onSetUserLastAuthTime(
                +payNPlayLoginResponse.lastAuthTime
              );
            }
          }

          return payNPlayLoginResponse;
        })
      );
  }

  onGetUserPayNPlayTransactionStatus(
    payNPlayTransactionStatusRequest: PayNPlayTransactionStatusRequest
  ): Observable<PayNPlayTransactionStatusResponse> {
    return this.apiInteractor
      .post<
        PayNPlayTransactionStatusRequest,
        PayNPlayTransactionStatusResponse
      >(`/ajax/cashier/gettransationStatus`, payNPlayTransactionStatusRequest)
      .pipe(
        map(
          (
            payNPlayTransactionStatusResponse: PayNPlayTransactionStatusResponse
          ) => {
            return payNPlayTransactionStatusResponse;
          }
        ),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  // -----------------------------------------------------------------
  // Set Methods
  onBroadcastInitiateLogin(txnType: string): void {
    this.initiateTxnSubject.next(txnType);
  }

  onBroadcastInitiateDeposit(initiateDeposit: InitiateDeposit): void {
    this.initiateDepositSubject.next(initiateDeposit);
  }

  onBroadcastInitiateWithdraw(): void {
    this.initiateWithdrawSubject.next();
  }

  onBroadcastProcessUserTransaction(type: string): void {
    this.processUserTransactionSubject.next(type);
  }

  onBroadcastZimplerProcessCompleted(): void {
    this.zimplerProcessCompletedSubject.next();
  }

  onSetUserLoggedTime(userLoggedTime: Date): void {
    this.userLoggedTime = userLoggedTime;
  }

  onPushGtmLogin(payNPlayLoginResponse: PayNPlayLoginResponse): void {
    this.subscription = this.profileService
      .onGetProfileBalanceCurrency()
      .subscribe((profileBalance: ProfileBalance) => {
        if (profileBalance) {
          if (profileBalance.profile) {
            payNPlayLoginResponse.countryCode = profileBalance.profile.country;
          }

          this.gtmService.onTrackLoginGTMEvent(
            "login-success",
            payNPlayLoginResponse
          );
        }
      });
  }

  // -----------------------------------------------------------------
  // On Destroy
  ngOnDestroy(): void {
    if (this.subscription) this.subscription.unsubscribe();
  }
}
