import { createAction, props } from "@ngrx/store";

// Models
import { BannerRequest } from "src/app/modules/banner/models/banner-request.model";
import { Banner } from "src/app/modules/banner/models/banner.model";

export enum BannerActionTypes {
  // Banner
  BannerRequested = "[Banners API] Banners Requested",
  BannerLoaded = "[Banners API] Banners Loaded",
  BannerError = "[Banners API] Banners Error",
}

// Banners
export const bannerRequested = createAction(
  BannerActionTypes.BannerRequested,
  props<{ bannerRequest: BannerRequest }>()
);
export const bannerLoaded = createAction(
  BannerActionTypes.BannerLoaded,
  props<{ bannerRequest: BannerRequest; banners: Banner[] }>()
);
export const bannerError = createAction(BannerActionTypes.BannerError);
