import { Component } from "@angular/core";
import { Subscription } from "rxjs";
import { Store } from "@ngrx/store";

// Enums
import { StatusResponse } from "src/app/models/api/status.response";

// Libraries
import * as _ from "underscore";

// Models
import { ClaimLevelUpBonusResponse } from "src/app/modules/rewards/models/claim-level/claim-level-up-bonus-response.model";
import { ClaimLevelUpBonusRequest } from "src/app/modules/rewards/models/claim-level/claim-level-up-bonus-request.model";
import { UserCurrentLevelUpDetails } from "src/app/modules/rewards/models/user-current-level-up-details.model";
import { ActiveBonusDetails } from "src/app/modules/rewards/models/active-bonus-details.model";
import { ActiveBonus } from "src/app/modules/rewards/models/active-bonus.model";
import { GamePregmatic } from "src/app/modules/game-groups/models/game.model";

// Reducers
import { AppState } from "src/app/store/reducers";

// Selectors
import { selectLanguageCode } from "src/app/modules/multi-languages/store/selectors/languages.selectors";

// Services
import { MultiLanguageService } from "src/app/modules/multi-languages/services/multi-language.service";
import { GameGroupsService } from "src/app/modules/game-groups/services/game-groups.service";
import { UserDetailsService } from "src/app/modules/user/services/user-details.service";
import { LiveChatService } from "src/app/modules/chat/services/live-chat.service";
import { RewardsService } from "src/app/modules/rewards/services/reward.service";
import { UtilityService } from "src/app/modules/shared/services/utility.service";

@Component({
  selector: "app-reward-congrats",
  templateUrl: "./reward-congrats.component.html",
  styleUrls: ["./reward-congrats.component.scss"],
})
export class RewardCongratsComponent {
  // Strings
  levelUpClaimErrorMessage: string = "";
  currencySymbol: string = "";
  languageCode: string = "";

  // Booleans
  isLevelUpClaimed: boolean = false;
  isButtonDisable: boolean = false;
  isError: boolean = false;
  isCoin: boolean = false;

  // Objects
  userCurrentLevelUpDetails: UserCurrentLevelUpDetails;

  // Subscriptions
  activeBonusDetailsSubscription: Subscription;
  claimLevelUpBonusSubscription: Subscription;
  gamesSubscription: Subscription;

  subscriptions: Subscription[] = [];

  constructor(
    private multiLanguageService: MultiLanguageService,
    private userDetailsService: UserDetailsService,
    private gameGroupsService: GameGroupsService,
    private liveChatService: LiveChatService,
    private rewardsService: RewardsService,
    private utilityService: UtilityService,
    private store: Store<AppState>
  ) {}

  // -----------------------------------------------------------------
  // Lifecycle Hooks
  ngOnInit(): void {
    let userCurrentLevelUpDetails: UserCurrentLevelUpDetails =
      this.rewardsService.getUserCurrentLevelUpDetails();

    if (
      userCurrentLevelUpDetails &&
      Object.keys(userCurrentLevelUpDetails).length > 0
    ) {
      this.userCurrentLevelUpDetails = userCurrentLevelUpDetails;

      this.userCurrentLevelUpDetails.freeSpinCount =
        userCurrentLevelUpDetails.spinCount
          ? userCurrentLevelUpDetails.spinCount
          : 0;

      this.userCurrentLevelUpDetails.freeSpinValue =
        userCurrentLevelUpDetails.spinValue
          ? userCurrentLevelUpDetails.spinValue
          : 0;
    }

    this.languageCode = this.multiLanguageService.getLanguageCode();

    this.subscriptions = [
      this.store
        .select(selectLanguageCode)
        .subscribe((languageCode: string) => {
          this.languageCode = languageCode;
        }),
      this.userDetailsService.currencySymbolBehaviourSubject$.subscribe(
        (currencySymbol: string) => {
          this.currencySymbol = currencySymbol;
        }
      ),
    ];
  }

  // -----------------------------------------------------------------
  // Set Methods
  onGetClaimLevelUpBonus(levelId: number): void {
    this.isButtonDisable = true;

    this.isLevelUpClaimed = false;

    this.onStartSpinner();

    let claimLevelUpBonusRequest: ClaimLevelUpBonusRequest = {
      levelId,
    };

    this.claimLevelUpBonusSubscription = this.rewardsService
      .onGetClaimLevelUpBonus(claimLevelUpBonusRequest)
      .subscribe((claimLevelUpBonusResponse: ClaimLevelUpBonusResponse) => {
        if (
          claimLevelUpBonusResponse &&
          claimLevelUpBonusResponse.status === StatusResponse.SUCCESS
        ) {
          if (this.userCurrentLevelUpDetails.dataFrom === "socket") {
            this.onAppendGameData();
          } else if (this.userCurrentLevelUpDetails.dataFrom === "rewards") {
            this.onGetActiveBonusData(claimLevelUpBonusResponse.ecrBonusId);
          }

          this.rewardsService.onBroadcastLevelUpDetails(undefined);
        } else {
          this.onShowErrorScreen(claimLevelUpBonusResponse);
        }
      });
  }

  onShowErrorScreen(
    claimLevelUpBonusResponse?: ClaimLevelUpBonusResponse
  ): void {
    this.isError = true;

    this.isButtonDisable = false;

    if (claimLevelUpBonusResponse && claimLevelUpBonusResponse.errors) {
      this.levelUpClaimErrorMessage =
        claimLevelUpBonusResponse.errors.errorDescription;
    } else {
      this.levelUpClaimErrorMessage =
        "Something went wrong,please customer support";
    }
  }

  onAppendGameData(): void {
    this.gamesSubscription = this.gameGroupsService
      .onGetGamePregmaticList()
      .subscribe((gamesList: GamePregmatic[]) => {
        if (gamesList && gamesList.length > 0) {
          let filteredGame: GamePregmatic;

          if (this.userCurrentLevelUpDetails.dataFrom === "socket") {
            filteredGame = gamesList.find(
              (game: GamePregmatic) =>
                game.pristineGameId === this.userCurrentLevelUpDetails.gameId
            );
          } else {
            filteredGame = gamesList.find(
              (game: GamePregmatic) =>
                game.gameCode === this.userCurrentLevelUpDetails.gameId
            );
          }

          if (filteredGame && Object.keys(filteredGame).length > 0) {
            this.userCurrentLevelUpDetails.gameData = filteredGame;

            setTimeout(() => {
              this.isCoin = false;

              this.isLevelUpClaimed = true;

              this.isButtonDisable = false;
            });
          } else {
            this.onShowErrorScreen();
          }
        }
      });
  }

  onStartSpinner(): void {
    this.isCoin = true;
  }

  onOpenRewardTCPopUp(event: MouseEvent): void {
    if (event) {
      event.stopPropagation();
    }

    this.utilityService.toggleRewardTCPopUp(true);
  }

  onGetActiveBonusData(ecrBonusId: string): void {
    if (ecrBonusId) {
      this.activeBonusDetailsSubscription = this.rewardsService
        .onGetActiveBonusDetails()
        .subscribe((activeBonusDetailsResponse: ActiveBonusDetails[]) => {
          if (
            activeBonusDetailsResponse &&
            activeBonusDetailsResponse.length > 0
          ) {
            const filteredActiveBonusList: ActiveBonusDetails[] =
              activeBonusDetailsResponse.filter(
                (activeBonusDetails: ActiveBonusDetails) => {
                  return activeBonusDetails.ecrBonusId == +ecrBonusId;
                }
              );
            if (
              filteredActiveBonusList &&
              Object.keys(filteredActiveBonusList).length > 0
            ) {
              let activeBonusDetails: ActiveBonusDetails =
                filteredActiveBonusList[0];

              if (
                activeBonusDetails &&
                activeBonusDetails.freeSpinGameIds &&
                activeBonusDetails.freeSpinGameIds.length > 0
              ) {
                let activeBonus: ActiveBonus = {
                  ecrId: activeBonusDetails.ecrExternalId,
                  newLevelId: 6,
                  bonusId: activeBonusDetails.bonusID,
                  ecrBonusId: 0,
                  gameId: activeBonusDetails.freeSpinGameIds[0],
                  freeSpinCount: activeBonusDetails.freeSpinCount,
                  freeSpinValue: activeBonusDetails.freeSpinValue,
                  messageName: "LEVELUP_LEVEL_UPGRADE",
                  messageType: "LEVELUP",
                  freespinExpiryDate: activeBonusDetails.freespinExpiryDate,
                  offeredDate: activeBonusDetails.offeredDate,
                };

                this.userCurrentLevelUpDetails = {
                  ...this.userCurrentLevelUpDetails,
                  ...activeBonus,
                };

                this.onAppendGameData();
              } else {
                this.onShowErrorScreen();
              }
            }
          } else {
            this.onShowErrorScreen();
          }
        });
    }
  }

  onCloseComponent(navigateTo?: string): void {
    this.utilityService.closeAccountComponent(navigateTo);
  }

  onInitializeLiveChat(): void {
    this.liveChatService.onInitializeChat();

    this.onCloseComponent();
  }

  // -----------------------------------------------------------------
  // On Destroy
  ngOnDestroy(): void {
    if (this.activeBonusDetailsSubscription)
      this.activeBonusDetailsSubscription.unsubscribe();

    if (this.claimLevelUpBonusSubscription)
      this.claimLevelUpBonusSubscription.unsubscribe();

    if (this.gamesSubscription) this.gamesSubscription.unsubscribe();

    this.subscriptions.forEach((subscription: Subscription) =>
      subscription.unsubscribe()
    );
  }
}
