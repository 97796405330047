<div class="pnp-qd">
  <div class="pnp-qd__container">
    <form [formGroup]="zimplerForm">
      <div class="pnp-qd__btn-wrapper">
        <div class="pnp-qd--btn">
          <input
            type="radio"
            id="quickAmount-50"
            name="amount"
            class="btn-radio"
            formControlName="amount"
            value="50"
            (click)="onSetTransactionAmount($event)"
          />

          <label class="btn" for="quickAmount-50">
            50 {{currencySymbol}}
          </label>
        </div>

        <div class="pnp-qd--btn">
          <input
            type="radio"
            id="quickAmount-150"
            name="amount"
            class="btn-radio"
            formControlName="amount"
            value="150"
            (click)="onSetTransactionAmount($event)"
            checked
          />

          <label class="btn" for="quickAmount-150">
            150 {{currencySymbol}}
          </label>
        </div>

        <div class="pnp-qd--btn">
          <input
            type="radio"
            id="quickAmount-250"
            name="amount"
            class="btn-radio"
            formControlName="amount"
            value="250"
            (click)="onSetTransactionAmount($event)"
          />

          <label class="btn" for="quickAmount-250">
            250 {{currencySymbol}}
          </label>
        </div>
      </div>

      <div class="pnp-qd--input">
        <div class="controls">
          <div
            class="quickpick-inputwrap"
            [ngClass]="isFocus?'isFocus':''"
            [class.has-error]="
              zimplerForm.controls['amount'].errors
                && 
              zimplerForm.controls['amount'].touched
            "
          >
            <input
              type="tel"
              id="qucikPickInputValue"
              name="amount"
              class="number-only qucik-input"
              [class.error-input]="getShouldShowErrors('amount',zimplerForm)"
              formControlName="amount"
              maxlength="8"
              (focus)="isFocus = true"
              (blur)="isFocus = false"
              (input)="onUncheckQuickPicks()"
              placeholder="{{'zimpler.Orenteramount' | translate}}"
            />

            <span class="currency-symbol" [class.isVisible]="isVisible">€</span>
          </div>

          <ng-container
            *ngTemplateOutlet="quickPickAmountErrorTemplate"
          ></ng-container>

          <!-- <p
            class="cashier-error-msg"
            *ngIf="getShouldShowErrors('amount',zimplerForm)"
          >
            {{getErrorMessage('amount',zimplerForm)}}
          </p> -->
        </div>
      </div>

      <button
        class="btn btn-primary pnp-qd--deposit-btn"
        [disabled]="zimplerForm.invalid || zimplerForm.pending"
        (click)="onZimplerPayAndPlayInitialize()"
      >
        {{'zimpler.depositAndPlay' | translate}}

        <ng-container *ngIf="!isLoggedIn">
          <em class="freespin--text">
            {{'welcomeOffer.freeSpinValue' | translate}}
            {{'welcomeOffer.freeSpinText' | translate}}
          </em>
        </ng-container>
      </button>
    </form>

    <div class="pnp-qd__paymethod-logos">
      <span>
        <img src="/assets/img/pnp/nordea.svg" alt="Nordea" />
      </span>
      <span>
        <img src="/assets/img/pnp/op-pohjola.svg" alt="OP pohjola" />
      </span>

      <span>
        <img src="/assets/img/pnp/Muut-pankit.svg" alt="Muut pankit" />
      </span>
    </div>
  </div>
</div>

<!-- 
  ------------------------------------------------------
  
  Error Templates 
  
  ------------------------------------------------------
-->
<ng-template #quickPickAmountErrorTemplate>
  <p
    class="cashier-error-msg"
    *ngIf="
        zimplerForm.get('amount').errors
          && 
        zimplerForm.get('amount').touched
    "
  >
    <ng-container>
      <div [hidden]="!zimplerForm.get('amount').errors.required">
        {{ "errors.error1" | translate }}
      </div>
      <div [hidden]="!zimplerForm.controls['amount'].errors.minimumAmount">
        {{methodCalled}} {{ "errors.error10" | translate }}
        {{errorMinimumAmountNumber}}
      </div>
      <div [hidden]="!zimplerForm.controls['amount'].errors.maximumAmount">
        {{methodCalled}} {{ "errors.error11" | translate }}
        {{errorMaximumAmountNumber}}
      </div>
      <div [hidden]="!zimplerForm.controls['amount'].errors.onlyNumbers">
        {{ "errors.error12" | translate }}
      </div>

      <div [hidden]="!zimplerForm.controls['amount'].errors.notEnoughBalance">
        {{ "errors.error67" | translate: ({ 'withdrawalAmount':
        zimplerForm.controls['amount'].errors.withdrawableBalance, 'minValue':
        zimplerForm.controls['amount'].errors.minValue }) }}
      </div>
      <div
        [hidden]="!zimplerForm.controls['amount'].errors.exceedMaximumAmount"
      >
        {{ "errors.error68" | translate: ({ 'withdrawalAmount':
        zimplerForm.controls['amount'].errors.withdrawalAmount,
        'maxValue': zimplerForm.controls['amount'].errors.maxValue }) }}
      </div>
      <div [hidden]="!zimplerForm.controls['amount'].errors.balanceAmount">
        {{ "errors.error69" | translate: ({ 'withdrawalAmount':
        zimplerForm.controls['amount'].errors.withdrawalAmount }) }}
      </div>
    </ng-container>
  </p>
</ng-template>
