/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./show-reel.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../vimeo-player/vimeo-player.component.ngfactory";
import * as i3 from "../vimeo-player/vimeo-player.component";
import * as i4 from "@ngx-translate/core";
import * as i5 from "../../../../../../node_modules/ngx-swiper-wrapper/dist/ngx-swiper-wrapper.ngfactory";
import * as i6 from "ngx-swiper-wrapper";
import * as i7 from "../../../shared/directives/swiper-resize.directive";
import * as i8 from "@angular/common";
import * as i9 from "./show-reel.component";
import * as i10 from "../../../multi-languages/services/translation.service";
import * as i11 from "../../services/game-play.service";
import * as i12 from "../../../shared/services/mix-panel.service";
import * as i13 from "../../../shared/services/utility.service";
import * as i14 from "../../../auth/services/session.service";
import * as i15 from "../../../auth/services/login.service";
import * as i16 from "@ngrx/store";
import * as i17 from "@angular/router";
var styles_ShowReelComponent = [i0.styles];
var RenderType_ShowReelComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ShowReelComponent, data: {} });
export { RenderType_ShowReelComponent as RenderType_ShowReelComponent };
function View_ShowReelComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "game-card showreel"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-vimeo-player", [], null, [[null, "gameLaunch"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("gameLaunch" === en)) {
        var pd_0 = (_co.onTriggerGameLaunch($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_VimeoPlayerComponent_0, i2.RenderType_VimeoPlayerComponent)), i1.ɵdid(2, 573440, null, 0, i3.VimeoPlayerComponent, [], { isLoggedIn: [0, "isLoggedIn"], gameData: [1, "gameData"] }, { gameLaunch: "gameLaunch" }), (_l()(), i1.ɵeld(3, 0, null, null, 5, "div", [["class", "game-card__footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "game-title showreel-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, [" ", " "])), (_l()(), i1.ɵeld(6, 0, null, null, 2, "button", [["aria-label", "Play now"], ["class", "btn btn-primary btn-round btn-sm game-cta"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onGameLaunch(_v.context.$implicit.name, _v.context.$implicit.hasDemo) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(7, null, [" ", " "])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isLoggedIn; var currVal_1 = _v.context.$implicit; _ck(_v, 2, 0, currVal_0, currVal_1); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit.name; _ck(_v, 5, 0, currVal_2); var currVal_3 = i1.ɵunv(_v, 7, 0, i1.ɵnov(_v, 8).transform("general.play_now")); _ck(_v, 7, 0, currVal_3); }); }
export function View_ShowReelComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [["class", "game-group__container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "game-group--title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["class", "gameGroupName-link"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onNavigateAllGamesPage() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(3, null, [" ", " "])), (_l()(), i1.ɵeld(4, 0, null, null, 2, "span", [["class", "viewall-link"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onNavigateAllGamesPage() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(5, null, [" ", " "])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(7, 0, null, null, 7, "div", [["class", "swiper__outer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 6, "div", [["class", "swiper__inner swiper__full"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 5, "swiper", [["class", "mySwiper showreel swiper-control"], ["watchSlidesProgress", ""]], null, [[null, "slidePrevTransitionEnd"], [null, "slideNextTransitionEnd"], ["window", "resize"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 11).onResize() !== false);
        ad = (pd_0 && ad);
    } if (("slidePrevTransitionEnd" === en)) {
        var pd_1 = (_co.onSwiperPrevEvent() !== false);
        ad = (pd_1 && ad);
    } if (("slideNextTransitionEnd" === en)) {
        var pd_2 = (_co.onSwiperNextEvent() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i5.View_SwiperComponent_0, i5.RenderType_SwiperComponent)), i1.ɵdid(10, 4374528, null, 0, i6.SwiperComponent, [i1.NgZone, i1.ChangeDetectorRef, i1.PLATFORM_ID, [2, i6.SWIPER_CONFIG]], { config: [0, "config"] }, { S_SLIDEPREVTRANSITIONEND: "slidePrevTransitionEnd", S_SLIDENEXTTRANSITIONEND: "slideNextTransitionEnd" }), i1.ɵdid(11, 16384, null, 0, i7.SwiperResizeDirective, [i6.SwiperComponent], null, null), (_l()(), i1.ɵand(16777216, null, 0, 2, null, View_ShowReelComponent_1)), i1.ɵdid(13, 278528, null, 0, i8.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(0, i8.SlicePipe, [])], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.swiperVidoeShow; _ck(_v, 10, 0, currVal_2); var currVal_3 = i1.ɵunv(_v, 13, 0, i1.ɵnov(_v, 14).transform(_co.gamesList, 0, _co.swiperLazyConfigurations.indexNumberOfGameTilesToshow)); _ck(_v, 13, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.gameGroupData.name; _ck(_v, 3, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform("gamegroup.view_all")); _ck(_v, 5, 0, currVal_1); }); }
export function View_ShowReelComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-show-reel", [], null, [[null, "keydown"]], function (_v, en, $event) { var ad = true; if (("keydown" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onKeydown($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_ShowReelComponent_0, RenderType_ShowReelComponent)), i1.ɵdid(1, 770048, null, 0, i9.ShowReelComponent, [i10.TranslationService, i11.GamePlayService, i12.MixPanelService, i13.UtilityService, i14.SessionService, i15.LoginService, i16.Store, i17.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ShowReelComponentNgFactory = i1.ɵccf("app-show-reel", i9.ShowReelComponent, View_ShowReelComponent_Host_0, { gameGroupData: "gameGroupData", callingFromlobby: "callingFromlobby" }, {}, []);
export { ShowReelComponentNgFactory as ShowReelComponentNgFactory };
