<section class="liveCasino__side-widgets--list">
  <div
    class="livecasino livecasino__provider-lobby"
    *ngIf="games && games.length>0"
  >
    <div class="widget--title livecasino__provider-lobby--title">
      {{'livecasino.lobbies_by_providers' | translate}}
    </div>

    <div class="livecasino__provider-lobby__inner">
      <div class="livecasino__provider-lobby__list" *ngFor="let game of games">
        <div
          class="livecasino__provider-lobby--img"
          (click)="onGameLaunch(game.name, game.vendorDisplayName)"
        >
          <img
            src="{{gamesUrlPath}}/{{game.gameCode}}/{{game.gameCode}}_live-lobby-provider.jpg{{imgixParams}}"
            alt="{{game.name}}"
            title="{{game.name}}"
          />
        </div>
        <div
          class="livecasino__provider-lobby--table-open"
          *ngIf="providerLevelGamesList && providerLevelGamesList[game.vendorCode]"
        >
          <span class="live-pulse"></span
          >{{providerLevelGamesList[game.vendorCode]['live-games'].length}}
          {{'livecasino.live_open_tables' | translate}}
        </div>
      </div>
    </div>
  </div>

  <!-- skeleton-loader -->

  <div class="livecasino livecasino__provider-lobby" *ngIf="isLoading">
    <div
      class="widget--title livecasino__provider-lobby--title skeleton-loader round-skelton"
    ></div>

    <div
      class="livecasino__provider-lobby__list"
      *ngFor="let number of  [].constructor(3)"
    >
      <div class="livecasino__provider-lobby--img skeleton-loader"></div>
      <div class="livecasino__provider-lobby--table-open">
        <span class="live-pulse skeleton-loader"></span
        ><span class="liveTable-count skeleton-loader round-skelton"></span>
      </div>
    </div>
  </div>
</section>
