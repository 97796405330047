import { Component, OnDestroy, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";

// Models
import {
  PolicyUserDetailsUpdateResponse,
  PolicyConfigsUserDetails,
  PolicyConfigs,
} from "src/app/modules/shared/models/policy/policy.model";

// Services
import { MultiLanguageService } from "src/app/modules/multi-languages/services/multi-language.service";
import { UtilityService } from "src/app/modules/shared/services/utility.service";
import { PolicyService } from "src/app/modules/shared/services/policy.service";
import { SessionService } from "src/app/modules/auth/services/session.service";

@Component({
  selector: "app-terms-and-conditions",
  templateUrl: "./terms-and-conditions.component.html",
  styleUrls: ["./terms-and-conditions.component.scss"],
})
export class TermsAndConditionsComponent implements OnInit, OnDestroy {
  // Constant
  readonly termsAndConditionsPolicyType: string = "TERMS_AND_CONDITIONS";

  // Strings
  languageCode: string = "";

  // Booleans {
  isTermsAndConditionsPopup: boolean = false;
  isLoggedIn: boolean = false;

  // Objects
  termsAndConditionsUserPolicy: PolicyConfigsUserDetails;
  currentTermsAndConditions: PolicyConfigs;

  // Subscriptions
  updateUserPolicyDetailsSubscription: Subscription;

  subscriptions: Subscription[] = [];

  constructor(
    private multiLanguageService: MultiLanguageService,
    private translateService: TranslateService,
    private utilityService: UtilityService,
    private sessionService: SessionService,
    private policyService: PolicyService,
    private router: Router
  ) {}

  // -----------------------------------------------------------------
  // Lifecycle Hooks
  ngOnInit(): void {
    this.languageCode = this.multiLanguageService.getLanguageCode();

    this.isLoggedIn = this.sessionService.getIsUserLoggedIn();

    if (this.isLoggedIn) {
      this.isTermsAndConditionsPopup = true;
      //this.onGetCurrentPolicy();
    }
  }

  // -----------------------------------------------------------------
  // Set Methods
  // Get Methods
  onGetCurrentPolicy(): void {
    this.subscriptions.push(
      this.policyService
        .onGetPolicies(this.termsAndConditionsPolicyType)
        .subscribe((terms: PolicyConfigs) => {
          if (terms) {
            this.currentTermsAndConditions = terms;
            this.onGetUserPolicyDetails();
          }
        })
    );
  }

  onGetUserPolicyDetails(): void {
    this.subscriptions.push(
      this.policyService
        .onGetUserPolicyDetails(this.termsAndConditionsPolicyType)
        .subscribe((terms: PolicyConfigsUserDetails) => {
          console.log("get user T&C", terms);
          if (terms != null) {
            if (
              this.currentTermsAndConditions.policyVersionId !=
              terms.policyVersionId
            ) {
              this.isTermsAndConditionsPopup = true;
            }
          } else {
            this.isTermsAndConditionsPopup = true;
          }
        })
    );
  }

  // Not being used but just in case for the future...
  /*onGetUserPolicyDetailsHistory(): void {
    this.subscriptions.push(
      this.policyService
        .OnGetUserPolicyDetailsHistory(this.termsAndConditionsPolicyType)
        .subscribe((terms: PolicyConfigsUserDetails[]) => {
          console.log("get user T&C", terms);
        })
    );
  }*/

  // Set Methods
  onTermsAndConditionsBackdropRemove(): void {
    this.isTermsAndConditionsPopup = false;

    this.utilityService.onRemoveClassFromAppBody("overflow-hidden");
  }

  onAcceptTermsAndConditions(): void {
    const policy: PolicyConfigsUserDetails = {
      policyType: this.currentTermsAndConditions.policyType,
      policyVersionId: this.currentTermsAndConditions.policyVersionId,
      policyAccepted: true,
    };

    this.updateUserPolicyDetailsSubscription = this.policyService
      .onUpdateUserPolicyDetails(policy)
      .subscribe(
        (policyUserDetailsUpdateResponse: PolicyUserDetailsUpdateResponse) => {
          if (policyUserDetailsUpdateResponse.status == "SUCCESS") {
            this.onTermsAndConditionsBackdropRemove();

            let termsAndConditionsUrl = this.translateService.instant(
              "url.terms_conditions"
            );

            this.router.navigate([
              `${this.languageCode}/${termsAndConditionsUrl}`,
            ]);
          } else {
            console.log(policyUserDetailsUpdateResponse);
          }
        }
      );
  }

  // -----------------------------------------------------------------
  // On Destroy
  ngOnDestroy(): void {
    if (this.updateUserPolicyDetailsSubscription)
      this.updateUserPolicyDetailsSubscription.unsubscribe();

    this.subscriptions.forEach((subscription: Subscription) =>
      subscription.unsubscribe()
    );
  }
}
