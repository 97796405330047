// Actions
import { logoutRequested } from "src/app/modules/auth/store/actions/auth.actions";
// Libraries
import * as socketIo from "socket.io-client";
import * as i0 from "@angular/core";
import * as i1 from "../../user/services/user-details.service";
import * as i2 from "./emitter.service";
import * as i3 from "../../rewards/services/reward.service";
import * as i4 from "@ngrx/store";
export class SocketService {
    constructor(userDetailsService, emitterService, rewardsService, store) {
        this.userDetailsService = userDetailsService;
        this.emitterService = emitterService;
        this.rewardsService = rewardsService;
        this.store = store;
        // Strings
        this.pragmaticSessionId = "";
        this.pragmaticUrl = "";
    }
    onConnectToSockets(pragmaticUrl, pragmaticSessionId, force) {
        if (pragmaticSessionId) {
            this.pragmaticSessionId = pragmaticSessionId;
            this.pragmaticUrl = pragmaticUrl;
            /*
              Comment below condition to run local code in IE until issue resolved
            */
            if (!this.socket || (this.socket && !this.socket.connected) || force) {
                this.socket = socketIo(pragmaticUrl);
                this.socket.on("connect", () => {
                    this.socket.emit("setAuthCode", pragmaticSessionId);
                    this.socket.on("message", (socketMessage) => {
                        console.log("socketttttttttttttttttttttttttttttttt", socketMessage);
                        /*
                          To keep session active when user playing games
                        */
                        if (socketMessage &&
                            socketMessage.messageName &&
                            socketMessage.messageName.includes("PushRealBalance")) {
                            /*
                              Commenting below line because mutliple status Api calls are hitting
                              when user playing games
                            */
                            setTimeout(() => {
                                const socketBalanceData = {
                                    realCashBalance: Number(socketMessage.cashBalance),
                                    crpBalance: socketMessage.crpBalance / 100,
                                    wrpBalance: socketMessage.wrpBalance / 100,
                                    drpBalance: socketMessage.drpBalance / 100,
                                    rrpBalance: socketMessage.rrpBalance / 100,
                                };
                                this.userDetailsService.onSetUserBalanceDetails(socketBalanceData);
                            });
                        }
                        else if (socketMessage &&
                            socketMessage.messageName &&
                            socketMessage.messageName.includes("LEVELUP_LEVEL_UPGRADE")) {
                            socketMessage.dataFrom = "socket";
                            this.rewardsService.onBroadcastLevelUpDetails(socketMessage);
                        }
                        else if (socketMessage &&
                            socketMessage.messageName &&
                            socketMessage.messageName.includes("LEVELUP_POINTS_UPDATE")) {
                            this.rewardsService.onBroadcastLevelUpPointDetails(socketMessage);
                        }
                        else if (socketMessage &&
                            socketMessage.messageName.includes("BET_FROM_BONUS_EXCEEDED_LIMIT")) {
                            this.emitterService.onBroadcastSocketMessage(socketMessage);
                        }
                        else if (socketMessage &&
                            socketMessage.messageName &&
                            socketMessage.messageName.includes("PushLogoutEvent")) {
                            this.store.dispatch(logoutRequested({}));
                        }
                    });
                });
            }
        }
    }
}
SocketService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SocketService_Factory() { return new SocketService(i0.ɵɵinject(i1.UserDetailsService), i0.ɵɵinject(i2.EmitterService), i0.ɵɵinject(i3.RewardsService), i0.ɵɵinject(i4.Store)); }, token: SocketService, providedIn: "root" });
