import { Observable, of } from "rxjs";
import {
  ValidationErrors,
  AsyncValidatorFn,
  AbstractControl,
  FormGroup,
} from "@angular/forms";

export class PasswordValidator {
  static validatePassword(minChar: number, maxChar: number): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors> => {
      const password: string = control.value;

      if (!password) {
        // let messagePartOne: string = getTranslation("errors.error1");

        return of({ required: true });
      } else if (password && password.length < minChar) {
        // let messagePartTwentyFour: string = getTranslation("errors.error24");

        return of({ passwordNotStrong: true });
      } else if (password && password.length > maxChar) {
        // let messagePartFiftySeven: string = getTranslation("errors.error57");

        // let messagePartFiftyEight: string = getTranslation("errors.error58");

        return of({ passwordCannotBeStronger: true });
      } else {
        return of(null);
      }
    };
  }

  static validateConfirmPassword() {
    return (formGroup: FormGroup): void => {
      const { password, confirmPassword } = formGroup.controls;

      if (!password.value) {
        // let messagePartOne: string = getTranslation("errors.error1");

        password.setErrors({ required: true });
      } else if (!confirmPassword.value) {
        // let messagePartOne: string = getTranslation("errors.error1");

        confirmPassword.setErrors({ required: true });
      } else if (
        confirmPassword.value &&
        password.value &&
        password.value !== confirmPassword.value
      ) {
        //  let messagePartTwo: string = getTranslation("errors.error2");

        confirmPassword.setErrors({ passwordDoNotMatch: true });
      } else {
        confirmPassword.setErrors(null);
      }
    };
  }
}
