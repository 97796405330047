import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "searchGameHighlight",
})
export class GameSearchHighlight implements PipeTransform {
  transform(gameName: string, searchText: string) {
    if (!searchText) return gameName;

    const regex = new RegExp(searchText, "gi");
    const match = gameName.match(regex);

    if (!match) {
      return gameName;
    }

    return gameName.replace(regex, `<strong>${match[0]}</strong>`);
  }
}
