/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./csn-livespins-iframe.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../shared/pipes/safe.pipe";
import * as i3 from "@angular/platform-browser";
import * as i4 from "./csn-livespins-iframe.component";
var styles_CsnLivespinsIFrameComponent = [i0.styles];
var RenderType_CsnLivespinsIFrameComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CsnLivespinsIFrameComponent, data: {} });
export { RenderType_CsnLivespinsIFrameComponent as RenderType_CsnLivespinsIFrameComponent };
export function View_CsnLivespinsIFrameComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.SafePipe, [i3.DomSanitizer]), (_l()(), i1.ɵeld(1, 0, null, null, 1, "iframe", [["allow", "clipboard-read;clipboard-write;autoplay;fullscreen;"], ["border", "0"], ["frameborder", "0"], ["height", "100%"], ["marginheight", "0"], ["scrolling", "auto"], ["width", "100%"]], [[8, "src", 5]], null, null, null, null)), i1.ɵppd(2, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v, 0), _co.iframe)); _ck(_v, 1, 0, currVal_0); }); }
export function View_CsnLivespinsIFrameComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-csn-livespins-iframe", [], null, null, null, View_CsnLivespinsIFrameComponent_0, RenderType_CsnLivespinsIFrameComponent)), i1.ɵdid(1, 573440, null, 0, i4.CsnLivespinsIFrameComponent, [], null, null)], null, null); }
var CsnLivespinsIFrameComponentNgFactory = i1.ɵccf("app-csn-livespins-iframe", i4.CsnLivespinsIFrameComponent, View_CsnLivespinsIFrameComponent_Host_0, { iframeURL: "iframeURL" }, {}, []);
export { CsnLivespinsIFrameComponentNgFactory as CsnLivespinsIFrameComponentNgFactory };
