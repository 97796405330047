import { FormGroup } from "@angular/forms";
import {
  ViewEncapsulation,
  EventEmitter,
  Component,
  Output,
} from "@angular/core";

// Components
import { FormValidationComponent } from "src/app/modules/shared/components/form-validation/form-validation.component";

// Models
import { UserPaymentMethod } from "src/app/modules/transactions/models/user-payment-methods/user-payment-methods-response.model";
import { DepositMethodInputField } from "src/app/models/configurations/deposit-method-configuration.model";

@Component({
  selector: "app-form-button",
  templateUrl: "./form-button.component.html",
  styleUrls: ["./form-button.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class FormButtonComponent extends FormValidationComponent {
  // Outputs
  @Output() buttonClicked: EventEmitter<string> = new EventEmitter<string>();

  // Strings
  callingFrom: string = "";

  // Booleans
  isUsedAccount: boolean = false;

  // Objects
  selectedMethodData: UserPaymentMethod;
  config: DepositMethodInputField;

  // Form Groups
  formGroup: FormGroup;

  constructor() {
    super();
  }

  // -----------------------------------------------------------------
  // Set Methods
  onButtonClick(message: string): void {
    this.buttonClicked.emit(message);
  }
}
