import { Observable, Subscription, throwError } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { catchError, map } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";

// API Interactors
import { ApiInteractors } from "src/app/models/interactors/api.interactor";

// Enums
import { StatusResponse } from "src/app/models/api/status.response";
import { Project } from "src/app/models/environments/project.enum";

// Models
import { UploadDocumentsResponse } from "src/app/modules/kyc/models/upload-documents/upload-documents-response.model";
import { UploadDocumentsRequest } from "src/app/modules/kyc/models/upload-documents/upload-documents-request.model";
import { KYCUserDetailsResponse } from "src/app/modules/kyc/models/kyc-details/kyc-user-details-response.model";
import { KYCDetailsToUnblock } from "src/app/modules/kyc/models/kyc-details/kyc-details-to-unblock.model";
import { SendDocumentResponse } from "src/app/modules/kyc/models/send-document-response.model";
import { SendDocumentRequest } from "src/app/modules/kyc/models/send-document-request.model";

// Reducers
import { AppState } from "src/app/store/reducers";

// Selectors
import { selectAuthLoginIsLoggedOut } from "src/app/modules/auth/store/selectors/auth.selectors";

@Injectable({
  providedIn: "root",
})
export class KYCService {
  // API Interactions
  apiInteractor: ApiInteractors;

  // Others
  kycDetailsToUnblock: KYCDetailsToUnblock = {
    address: false,
    identity: false,
    paymentInstrument: false,
    sourceOfIncome: false,
    enableKyc: false,
  };

  // Subscriptions
  subscriptions: Subscription[] = [];

  constructor(private httpClient: HttpClient, private store: Store<AppState>) {
    this.apiInteractor = new ApiInteractors(this.httpClient);

    this.subscriptions = [
      this.store
        .select(selectAuthLoginIsLoggedOut)
        .subscribe((isLoggedOut: boolean) => {
          if (isLoggedOut) {
            this.kycDetailsToUnblock = {
              address: false,
              identity: false,
              paymentInstrument: false,
              sourceOfIncome: false,
              enableKyc: false,
            };
          }
        }),
    ];
  }

  // -----------------------------------------------------------------
  // Get Methods - Observable
  onUploadDocuments(
    uploadDocumentsRequest: UploadDocumentsRequest
  ): Observable<UploadDocumentsResponse> {
    let formData: FormData = new FormData();

    let type: string = uploadDocumentsRequest.type;

    delete uploadDocumentsRequest.type;

    formData.append("file", uploadDocumentsRequest.file);

    const url: string = `/ajax/account/Documents/upload?file=${type}&accountId=`;

    return this.apiInteractor
      .upload<FormData, UploadDocumentsResponse>(url, formData)
      .pipe(
        map((uploadDocumentsResponse: UploadDocumentsResponse) => {
          return uploadDocumentsResponse;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  onSendDocuments(
    sendDocumentRequest?: SendDocumentRequest
  ): Observable<SendDocumentResponse> {
    return this.onGetSendDocuments(sendDocumentRequest).pipe(
      map((uploadDocumentsResponse: SendDocumentResponse) => {
        return uploadDocumentsResponse;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  onGetSendDocuments(
    sendDocumentRequest?: SendDocumentRequest
  ): Observable<SendDocumentResponse> {
    let url: string = `/ajax/account/Documents/send`;

    if (!sendDocumentRequest) {
      return this.apiInteractor.post<null, SendDocumentResponse>(
        url,
        null,
        Project.Shotz
      );
    } else {
      return this.apiInteractor.post<SendDocumentRequest, SendDocumentResponse>(
        url,
        sendDocumentRequest,
        Project.Shotz
      );
    }
  }

  onGetUserKycLevelDetails(): Observable<KYCDetailsToUnblock> {
    return this.apiInteractor
      .get<null, KYCUserDetailsResponse>(
        `/ajax/profile/getUserKYCDetails`,
        null,
        Project.Shotz
      )
      .pipe(
        map((kycUserDetailsResponse: KYCUserDetailsResponse) => {
          if (
            kycUserDetailsResponse &&
            kycUserDetailsResponse.status === StatusResponse.SUCCESS
          ) {
            let requiredDocumentsList: string[] =
              kycUserDetailsResponse.requiredDocs;

            if (requiredDocumentsList && requiredDocumentsList.length > 0) {
              requiredDocumentsList.filter((documentName: string) => {
                if (kycUserDetailsResponse.kycDetails[documentName]) {
                  this.kycDetailsToUnblock[documentName] = "vrfn_new";

                  this.kycDetailsToUnblock.enableKyc = true;
                }
              });
            }
          }

          return this.kycDetailsToUnblock;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  // -----------------------------------------------------------------
  // On Destroy
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) =>
      subscription.unsubscribe()
    );
  }
}
