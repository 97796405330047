import { Subscription } from "rxjs";
import { Store } from "@ngrx/store";
import {
  HostListener,
  ElementRef,
  Component,
  Renderer2,
  ViewChild,
} from "@angular/core";

// Configurations
import { localStorageKeys } from "src/app/modules/multi-languages/configurations/localstorage-keys.configurations";

// Reducers
import { AppState } from "src/app/store/reducers";

// Selectors
import { selectAuthLoginIsLoggedIn } from "src/app/modules/auth/store/selectors/auth.selectors";

// Services
import { PayNPlayCashierService } from "src/app/modules/account/services/pay-n-play-cashier.service";

@Component({
  selector: "app-session-timer",
  templateUrl: "./session-timer.component.html",
  styleUrls: ["./session-timer.component.scss"],
})
export class SessionTimerComponent {
  // View Childs
  @ViewChild("userSessionTime", { static: false }) el: ElementRef;

  // Strings
  counter: string = "00hr 00m 00s";

  // Booleans
  isLoggedIn: boolean = false;

  // Date
  userLoggedTime: Date = new Date();

  // Timeouts
  timeouts: NodeJS.Timer;
  intervals: NodeJS.Timer;

  // Enums
  windowType: "device" | "mobile" = "device";

  // Subscriptions
  subscriptions: Subscription[] = [];

  constructor(
    private payNPlayCashierService: PayNPlayCashierService,
    private store: Store<AppState>,
    private renderer: Renderer2
  ) {}

  // -----------------------------------------------------------------
  // Lifecycle Hooks
  ngOnInit(): void {
    this.getWindowType();

    this.timeouts = setTimeout(() => {
      this.onGetUserLoginTime();

      this.onStartLoginSession();
    }, 1000);

    this.subscriptions = [
      this.store
        .select(selectAuthLoginIsLoggedIn)
        .subscribe((isLoggedIn: boolean) => {
          this.isLoggedIn = isLoggedIn;

          if (this.isLoggedIn) {
            this.userLoggedTime =
              this.payNPlayCashierService.getUserLoggedTime();
          }
        }),
    ];
  }

  // -----------------------------------------------------------------
  // Host Listeners
  @HostListener("touchstart", ["$event"])
  onStart(event: MouseEvent): void {
    if (event) {
      this.onInitiateDragDrop();
    }
  }

  @HostListener("window:orientationchange")
  onRotate(): void {
    this.onSetSessionTimerScreenOrientation();

    this.getWindowType();
  }

  @HostListener("window:resize")
  onResize(): void {
    this.getWindowType();
  }

  // -----------------------------------------------------------------
  // Window Type
  getWindowType(): void {
    let clientWidth: number = document.body.clientWidth;

    if (clientWidth >= 1024) {
      this.windowType = "device";
    } else {
      this.windowType = "mobile";
    }
  }

  // -----------------------------------------------------------------
  // Get Methods
  getFormattedAmount<T>(value: T, unit: string): string {
    let slicedAmount: string = `00${value}`.slice(-2);

    return `${slicedAmount}${unit}`;
  }

  // -----------------------------------------------------------------
  // Set Methods
  onSetSessionTimerScreenOrientation(): void {
    if (window.matchMedia("(orientation: landscape)").matches) {
      this.renderer.setStyle(this.el.nativeElement, "left", `${16}px`);

      this.renderer.setStyle(this.el.nativeElement, "bottom", `${64}px`);

      this.renderer.setStyle(this.el.nativeElement, "top", "unset");
    } else {
      this.renderer.setStyle(this.el.nativeElement, "top", `${16}px`);

      this.renderer.setStyle(this.el.nativeElement, "left", `${64}px`);
    }
  }

  onGetUserLoginTime(): void {
    if (this.payNPlayCashierService.getUserLoggedTime()) {
      this.userLoggedTime = this.payNPlayCashierService.getUserLoggedTime();
    } else if (localStorage.getItem(localStorageKeys.loggedInTime)) {
      this.userLoggedTime = new Date(
        Date.parse(localStorage.getItem(localStorageKeys.loggedInTime))
      );
    }
  }

  onStartLoginSession(): void {
    let userLoggedInTime: Date = this.userLoggedTime;

    this.intervals = setInterval(() => {
      if (userLoggedInTime) {
        let totalMilliSecondAfterDiff: number =
          new Date().getTime() - userLoggedInTime.getTime();

        this.onDisplayUserLoginTime(totalMilliSecondAfterDiff);
      }
    }, 100);
  }

  onInitiateDragDrop(): void {
    if (this.windowType === "mobile") {
      let userSessionElement: HTMLElement =
        document.getElementById("userSessionTimer");

      let timerHeight: number = userSessionElement.clientHeight;

      let timerWidth: number = userSessionElement.clientWidth;

      if (userSessionElement) {
        userSessionElement.addEventListener(
          "touchmove",
          (touchEvent: TouchEvent) => {
            touchEvent.preventDefault();

            let innerHeight: number = window.innerHeight;

            let innerWidth: number = window.innerWidth;

            let touchLocation: Touch = touchEvent.targetTouches[0];

            if (
              touchLocation.clientX > 0 &&
              touchLocation.clientX < innerWidth - timerWidth
            ) {
              userSessionElement.style.left = touchLocation.clientX + "px";
            }

            if (
              touchLocation.clientY > 0 &&
              touchLocation.clientY < innerHeight - timerHeight
            ) {
              userSessionElement.style.top = touchLocation.clientY + "px";
            }
          },
          false
        );
      }
    }
  }

  onDisplayUserLoginTime(totalMilliSecondAfterDiff: number): void {
    let hours: number = 0;

    let minutes: number = 0;

    let seconds: string = "";

    hours = Math.floor((totalMilliSecondAfterDiff / (1000 * 60 * 60)) % 24);

    minutes = Math.floor((totalMilliSecondAfterDiff / (1000 * 60)) % 60);

    seconds = ((totalMilliSecondAfterDiff / 1000) % 60).toFixed(0);

    let formattedHours: string = this.getFormattedAmount<number>(hours, "hr");

    let formattedMinutes: string = this.getFormattedAmount<number>(
      minutes,
      "m"
    );

    let formattedSeconds: string = this.getFormattedAmount<string>(
      seconds,
      "s"
    );

    this.counter = `${formattedHours} ${formattedMinutes} ${formattedSeconds}`;
  }

  // -----------------------------------------------------------------
  // On Destroy
  ngOnDestroy(): void {
    if (this.intervals) clearInterval(this.intervals);

    if (this.timeouts) clearTimeout(this.timeouts);

    this.subscriptions.forEach((subscription: Subscription) =>
      subscription.unsubscribe()
    );
  }
}
