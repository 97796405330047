import { createAction, props } from "@ngrx/store";

// Models
import { PayNPlayLoginResponse } from "src/app/modules/account/models/pay-n-play-login/pay-n-play-login-response.model";
import { PayNPlayLoginRequest } from "src/app/modules/account/models/pay-n-play-login/pay-n-play-login-request.model";
import { LogoutResponse } from "src/app/modules/auth/models/logout/logout-response.model";
import { LoginCredentials } from "src/app/modules/auth/models/login-credentials.model";
import { LoginStatus } from "src/app/modules/auth/models/login-status.model";
import { LoggedIn } from "src/app/modules/auth/models/logged-in.model";

export enum AuthActionTypes {
  // Login
  LoginRequested = "[Auth API] Login Requested",
  LoginRequestedRefresh = "[Auth API] Login Requested Refresh",
  LoginLoaded = "[Auth API] Login Loaded",
  LoginError = "[Auth API] Login Error",

  // Pay N Play Login
  PayNPlayLoginRequested = "[Auth API] Pay N Play Login Requested",
  PayNPlayLoginRequestedRefresh = "[Auth API] Pay N Play Login Requested Refresh",
  PayNPlayLoginLoaded = "[Auth API] Pay N Play Login Loaded",
  PayNPlayLoginError = "[Auth API] Pay N Play Login Error",

  // Login Status
  LoggedInStatus = "[Auth API] Login In Status",

  // Login Status
  LoginStatusRequested = "[Auth API] Login Status Requested",
  LoginStatusRequestedRefresh = "[Auth API] Login Status Requested Refresh",
  LoginStatusLoaded = "[Auth API] Login Status Loaded",
  LoginStatusError = "[Auth API] Login Status Error",

  // Logout
  LogoutRequested = "[Auth API] Logout Requested",
  LogoutRequestedRefresh = "[Auth API] Logout Requested Refresh",
  LogoutLoaded = "[Auth API] Logout Loaded",
  LogoutError = "[Auth API] Logout Error",
}

// Login
export const loginRequested = createAction(
  AuthActionTypes.LoginRequested,
  props<{ credentials: LoginCredentials }>()
);
export const loginRequestedRefresh = createAction(
  AuthActionTypes.LoginRequestedRefresh,
  props<{ credentials: LoginCredentials }>()
);
export const loginLoaded = createAction(
  AuthActionTypes.LoginLoaded,
  props<{ loggedIn: LoggedIn }>()
);
export const loginError = createAction(AuthActionTypes.LoginError);

// Pay N Play Login
export const payNPlayLoginRequested = createAction(
  AuthActionTypes.PayNPlayLoginRequested,
  props<{ payNPlayLoginRequest: PayNPlayLoginRequest }>()
);
export const payNPlayLoginRequestedRefresh = createAction(
  AuthActionTypes.PayNPlayLoginRequestedRefresh,
  props<{ payNPlayLoginRequest: PayNPlayLoginRequest }>()
);
export const payNPlayLoginLoaded = createAction(
  AuthActionTypes.PayNPlayLoginLoaded,
  props<{ payNPlayLoggedIn: PayNPlayLoginResponse }>()
);
export const payNPlayLoginError = createAction(
  AuthActionTypes.PayNPlayLoginError
);

// Login In Status
export const loggedInStatus = createAction(
  AuthActionTypes.LoggedInStatus,
  props<{ isLoggedIn: boolean }>()
);

// Login Status
export const loginStatusRequested = createAction(
  AuthActionTypes.LoginStatusRequested
);
export const loginStatusRequestedRefresh = createAction(
  AuthActionTypes.LoginStatusRequestedRefresh,
  props<{ credentials: LoginCredentials }>()
);
export const loginStatusLoaded = createAction(
  AuthActionTypes.LoginStatusLoaded,
  props<{ loginStatus: LoginStatus }>()
);
export const loginStatusError = createAction(AuthActionTypes.LoginStatusError);

// Logout
export const logoutRequested = createAction(
  AuthActionTypes.LogoutRequested,
  props<{ logoutResponse?: LogoutResponse }>()
);
export const logoutRequestedRefresh = createAction(
  AuthActionTypes.LogoutRequestedRefresh
);
export const logoutLoaded = createAction(
  AuthActionTypes.LogoutLoaded,
  props<{ logoutResponse?: LogoutResponse }>()
);
export const logoutError = createAction(AuthActionTypes.LogoutError);
