import { Observable, of } from "rxjs";
import {
  ValidationErrors,
  AsyncValidatorFn,
  AbstractControl,
  ValidatorFn,
  FormGroup,
} from "@angular/forms";

// Configurations
import { nameRegex } from "src/app/modules/shared/configurations/regex.configurations";

export class CharactersValidator {
  static validateName(minChar: number, maxChar: number): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors> => {
      const controlValue: string = control.value;

      let regex: RegExp = /_/;

      if (!controlValue) {
        // let messagePartOne: string = getTranslation("errors.error1");

        return of({ required: true });
      } else if (controlValue && controlValue.length < minChar) {
        // This field must contain a minimum of
        // let messagePartThree: string = getTranslation("errors.error3");

        // letters
        // let messagePartFour: string = getTranslation("errors.error4");

        // `${messagePartThree}${minChar}${messagePartFour}

        return of({ minimumLetters: true });
      } else if (controlValue && controlValue.length > maxChar) {
        // This field cannot be more than
        // let messagePartEight: string = getTranslation("errors.error8");

        // letters
        // let messagePartFour: string = getTranslation("errors.error4");

        // `${messagePartEight}${maxChar}${messagePartFour}`

        return of({ cannotExceedCharacters: true });
      } else if (!nameRegex.test(controlValue) || regex.test(controlValue)) {
        // This field must contain only letters
        // let messagePartFive: string = getTranslation("errors.error5");

        return of({ mustContainOnlyLetters: true });
      } else {
        return of(null);
      }
    };
  }

  static validateMinMaxCharacters(
    minChar: number,
    maxChar: number
  ): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors> => {
      const controlValue: string = control.value;

      if (!controlValue) {
        // let messagePartOne: string = getTranslation("errors.error1");

        return of({ required: true });
      } else if (controlValue && controlValue.length < minChar) {
        // This field must contain a minimum of
        // let messagePartThree: string = getTranslation("errors.error3");

        // letters
        // let messagePartFour: string = getTranslation("errors.error4");

        // `${messagePartThree}${minChar}${messagePartFour}

        return of({ minimumLetters: true });
      } else if (controlValue && controlValue.length > maxChar) {
        // This field cannot be more than
        // let messagePartEight: string = getTranslation("errors.error8");

        // letters
        // let messagePartFour: string = getTranslation("errors.error4");

        // `${messagePartEight}${maxChar}${messagePartFour}`

        return of({ cannotExceedCharacters: true });
      } else {
        return of(null);
      }
    };
  }

  static validateAlphaNumeric(
    minChar: number,
    maxChar: number
  ): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors> => {
      const controlValue: string = control.value;

      const regex: RegExp = /^[a-z0-9]+$/i;

      if (!controlValue) {
        // let messagePartOne: string = getTranslation("errors.error1");

        return of({ required: true });
      } else if (!regex.test(controlValue)) {
        // Invalid Input
        // let messagePartSix: string = getTranslation("errors.error6");

        return of({ invalidInput: true });
      } else if (controlValue && controlValue.length < minChar) {
        // This field must contain a minimum of
        // let messagePartThree: string = getTranslation("errors.error3");

        // letters
        // let messagePartFour: string = getTranslation("errors.error4");

        //  `${messagePartThree}${minChar}${messagePartFour}`

        return of({ minimumLetters: true });
      } else if (controlValue && maxChar && controlValue.length > maxChar) {
        // This field cannot exceed
        // let messagePartSeven: string = getTranslation("errors.error7");

        // letters
        // let messagePartFour: string = getTranslation("errors.error4");

        // `${messagePartSeven}${maxChar}${messagePartFour}`

        return of({ exceedCharacters: true });
      } else {
        return of(null);
      }
    };
  }

  static validateMinNumericValueFunctional(
    minValue: string,
    maxValue: string,
    callingFrom?: string,
    withdrawableBalance?: number
  ): ValidatorFn {
    return (formGroup: FormGroup): ValidationErrors => {
      // const controlValue: string = control.value;
      const { amount } = formGroup.controls;

      const controlValue: string = amount.value;

      let computedValue: number = +controlValue;

      let minValueNumeric: number = Number(minValue);

      let maxValueNumeric: number = Number(maxValue);

      let regex: RegExp; // = /^[0-9]*(\.\d{0,2})?/;

      if (!computedValue) {
        //  let messagePartOne: string = getTranslation("errors.error1");

        amount.setErrors({ required: true });

        return;
      } else if (controlValue) {
        if (callingFrom === "withdraw") {
          regex = /^[0-9]*(\.\d{0,2})?/;

          computedValue = Number(controlValue);
        } else if (callingFrom === "deposit") {
          regex = /^[0-9]*$/;
        } else {
          regex = /^[0-9]*(\.\d{0,2})?/;

          computedValue = Number(controlValue);
        }

        if (!regex.test(controlValue)) {
          // This field should contain only numbers

          // let messagePartTwelve: string = getTranslation("errors.error12");

          amount.setErrors({ onlyNumbers: true });

          return;
        } else if (!controlValue) {
          // This field should contain only numbers
          // let messagePartTwelve: string = getTranslation("errors.error12");

          amount.setErrors({ onlyNumbers: true });

          return;
        } else if (
          computedValue &&
          callingFrom === "withdraw" &&
          minValueNumeric &&
          computedValue > withdrawableBalance &&
          computedValue < minValueNumeric
        ) {
          // Oops, you cannot make a withdrawal as your balance is {{withdrawalAmount}}, and the minimum amount to withdraw is {{minValue}}.
          /*
          let messagePartSixtySeven: string = getTranslation("errors.error67", {
            withdrawalAmount: withdrawableBalance,
            minValue: minValueNumeric,
          });
          */

          amount.setErrors({
            notEnoughBalance: true,
            withdrawalAmount: withdrawableBalance,
            minValue: minValueNumeric,
          });

          return;
        } else if (
          computedValue &&
          minValueNumeric &&
          computedValue < minValueNumeric
        ) {
          // Minimum
          // let messagePartTen: string = getTranslation("errors.error10");

          // `${messagePartTen}${minValueNumeric}`

          amount.setErrors({
            minimumAmount: true,
          });

          return;
        } else if (
          controlValue &&
          callingFrom === "withdraw" &&
          maxValueNumeric &&
          computedValue > withdrawableBalance &&
          computedValue > maxValueNumeric
        ) {
          // Oops, you cannot make a withdrawal as your balance is {{withdrawalAmount}}, and the maximum amount to withdraw is {{maxValue}}.
          /*
          let messagePartSixtyEight: string = getTranslation("errors.error68", {
            withdrawalAmount: withdrawableBalance,
            maxValue: maxValueNumeric,
          });
          */

          amount.setErrors({
            exceedMaximumAmount: true,
            withdrawalAmount: withdrawableBalance,
            maxValue: maxValueNumeric,
          });

          return;
        } else if (
          controlValue &&
          callingFrom === "withdraw" &&
          minValueNumeric &&
          maxValueNumeric &&
          computedValue > withdrawableBalance &&
          computedValue >= minValueNumeric &&
          computedValue <= maxValueNumeric
        ) {
          // Oops, you cannot make a withdrawal as your balance is {{withdrawalAmount}}.
          /*
          let messagePartSixtyNine: string = getTranslation("errors.error69", {
            withdrawalAmount: withdrawableBalance,
          });
          */

          amount.setErrors({
            balanceAmount: true,
            withdrawalAmount: withdrawableBalance,
          });

          return;
        } else if (
          controlValue &&
          maxValueNumeric &&
          computedValue > maxValueNumeric
        ) {
          // Maximum
          // let messagePartEleven: string = getTranslation("errors.error11");

          // `${messagePartEleven}${maxValueNumeric}`

          amount.setErrors({
            maximumAmount: true,
          });

          return;
        }
      } else {
        return;
      }
    };
  }

  static validateMinNumericValue(
    minValue: string,
    maxValue: string,
    callingFrom?: string,
    withdrawableBalance?: number
  ): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors> => {
      const controlValue: string = control.value;

      let computedValue: number = +controlValue;

      let minValueNumeric: number = Number(minValue);

      let maxValueNumeric: number = Number(maxValue);

      let regex: RegExp; // = /^[0-9]*(\.\d{0,2})?/;

      if (!computedValue) {
        //  let messagePartOne: string = getTranslation("errors.error1");

        return of({ required: true });
      } else if (controlValue) {
        if (callingFrom === "withdraw") {
          regex = /^[0-9]*(\.\d{0,2})?/;

          computedValue = Number(controlValue);
        } else if (callingFrom === "deposit") {
          regex = /^[0-9]*$/;
        } else {
          regex = /^[0-9]*(\.\d{0,2})?/;

          computedValue = Number(controlValue);
        }

        if (!regex.test(controlValue)) {
          // This field should contain only numbers

          // let messagePartTwelve: string = getTranslation("errors.error12");

          return of({ onlyNumbers: true });
        } else if (!controlValue) {
          // This field should contain only numbers
          // let messagePartTwelve: string = getTranslation("errors.error12");

          return of({ onlyNumbers: true });
        } else if (
          computedValue &&
          callingFrom === "withdraw" &&
          minValueNumeric &&
          computedValue > withdrawableBalance &&
          computedValue < minValueNumeric
        ) {
          // Oops, you cannot make a withdrawal as your balance is {{withdrawalAmount}}, and the minimum amount to withdraw is {{minValue}}.
          /*
          let messagePartSixtySeven: string = getTranslation("errors.error67", {
            withdrawalAmount: withdrawableBalance,
            minValue: minValueNumeric,
          });
          */

          return of({
            notEnoughBalance: true,
            withdrawalAmount: withdrawableBalance,
            minValue: minValueNumeric,
          });
        } else if (
          computedValue &&
          minValueNumeric &&
          computedValue < minValueNumeric
        ) {
          // Minimum
          // let messagePartTen: string = getTranslation("errors.error10");

          // `${messagePartTen}${minValueNumeric}`

          return of({ minimumAmount: true });
        } else if (
          controlValue &&
          callingFrom === "withdraw" &&
          maxValueNumeric &&
          computedValue > withdrawableBalance &&
          computedValue > maxValueNumeric
        ) {
          // Oops, you cannot make a withdrawal as your balance is {{withdrawalAmount}}, and the maximum amount to withdraw is {{maxValue}}.
          /*
          let messagePartSixtyEight: string = getTranslation("errors.error68", {
            withdrawalAmount: withdrawableBalance,
            maxValue: maxValueNumeric,
          });
          */

          return of({
            exceedMaximumAmount: true,
            withdrawalAmount: withdrawableBalance,
            maxValue: maxValueNumeric,
          });
        } else if (
          controlValue &&
          callingFrom === "withdraw" &&
          minValueNumeric &&
          maxValueNumeric &&
          computedValue > withdrawableBalance &&
          computedValue >= minValueNumeric &&
          computedValue <= maxValueNumeric
        ) {
          // Oops, you cannot make a withdrawal as your balance is {{withdrawalAmount}}.
          /*
          let messagePartSixtyNine: string = getTranslation("errors.error69", {
            withdrawalAmount: withdrawableBalance,
          });
          */

          return of({
            balanceAmount: true,
            withdrawalAmount: withdrawableBalance,
          });
        } else if (
          controlValue &&
          maxValueNumeric &&
          computedValue > maxValueNumeric
        ) {
          // Maximum
          // let messagePartEleven: string = getTranslation("errors.error11");

          // `${messagePartEleven}${maxValueNumeric}`

          return of({ maximumAmount: true });
        }
      } else {
        return of(null);
      }
    };
  }

  static validateExactNumber(
    initialValue: number,
    isWhiteSpacesExcluded?: boolean
  ): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors> => {
      let controlValue: string =
        typeof control.value == "string" && control.value
          ? control.value.trim()
          : control.value;

      if (controlValue && isWhiteSpacesExcluded) {
        controlValue = controlValue.replace(/ /g, "");
      }

      const length: number = controlValue && controlValue.toString().length;

      let computedValue: number = Number(initialValue);

      let regex: RegExp = /^[0-9]*$/;

      if (!controlValue) {
        //  let messagePartOne: string = getTranslation("errors.error1");

        return of({ required: true });
      } else if (controlValue && !regex.test(controlValue)) {
        // This field con contain only numbers
        //  let messagePartNine: string = getTranslation("errors.error9");

        return of({ onlyNumbers: true });
      } else if (controlValue && computedValue && length !== computedValue) {
        // Should contain
        // let messagePartSixtyFive: string = getTranslation("errors.error65");

        // numbers
        // let messagePartTwentyOne: string = getTranslation("errors.error21");

        //  `${messagePartSixtyFive}${computedValue}${messagePartTwentyOne}`

        return of({ shouldContainNumbers: true });
      } else {
        // return of(null);

        return null;
      }
    };
  }

  static validatePhoneNumber(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors> => {
      const controlValue: string = control.value;

      const minDigit: number = 5;

      const maxDigit: number = 10;

      const regex: RegExp = /^[0-9]*$/;

      if (!controlValue) {
        //  let messagePartOne: string = getTranslation("errors.error1");

        return of({ required: true });
      } else if (controlValue && !regex.test(controlValue)) {
        // Phone Number can contain only numbers
        // let messagePartEighteen: string = getTranslation("errors.error18");

        return of({ onlyNumbers: true });
      } else if (controlValue && controlValue.length < minDigit) {
        // Phone Number must contain a minimum of
        // let messagePartNineteen: string = getTranslation("errors.error19");

        // numbers
        // let messagePartTweentyOne: string = getTranslation("errors.error21");

        // `${messagePartNineteen}${minDigit}${messagePartTweentyOne}`

        return of({ minimumNumbers: true });
      } else if (controlValue && controlValue.length > maxDigit) {
        // Phone Number cannot be longer than
        // let messagePartTweenty: string = getTranslation("errors.error20");

        // numbers
        // let messagePartTweentyOne: string = getTranslation("errors.error21");

        // `${messagePartTweenty}${maxDigit}${messagePartTweentyOne}`

        return of({ maximumNumbers: true });
      } else {
        return of(null);
      }
    };
  }
}
