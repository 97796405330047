import { Action, createReducer, on } from "@ngrx/store";

// Actions
import { logoutLoaded } from "src/app/modules/auth/store/actions/auth.actions";
import {
  userProfileBalanceLoaded,
  userProfileBalanceError,
  profileBalanceLoaded,
  profileBalanceError,
} from "src/app/modules/user/store/actions/profile.actions";

// Models
import { UserProfileBalance } from "src/app/modules/shared/models/profiles/user-profile-balance.model";
import { ProfileBalance } from "src/app/modules/auth/models/profile-balance.model";
import { UserData } from "src/app/modules/user/models/user-data.model";

export const ProfileStateFeatureKey = "profile";

// Reducer States
export interface ProfileReducerState {
  // Profile Balance
  profileBalance: ProfileBalance;
  isLoaded: boolean;
  isLoading: boolean;
  profileBalanceError: string;

  // User Profile Balance
  userProfileBalance: UserProfileBalance;
  userProfileBalanceIsLoaded: boolean;
  userProfileBalanceIsLoading: boolean;
  userProfileBalanceError: string;

  // User Data
  userData: UserData;
  userDataIsLoaded: boolean;
  userDataIsLoading: boolean;
  userDataError: string;
}

export const initialState: ProfileReducerState = {
  // Profile Balance
  profileBalance: null,
  isLoaded: false,
  isLoading: false,
  profileBalanceError: null,

  // User Profile Balance
  userProfileBalance: null,
  userProfileBalanceIsLoaded: false,
  userProfileBalanceIsLoading: false,
  userProfileBalanceError: null,

  // User Data
  userData: null,
  userDataIsLoaded: false,
  userDataIsLoading: false,
  userDataError: null,
};

// Reducer
const ProfileReducer = createReducer(
  initialState,
  // Profile Balance
  on(profileBalanceLoaded, (state: ProfileReducerState, { profileBalance }) => {
    return {
      ...state,
      profileBalance,
      isLoading: false,
      isLoaded: true,
      profileBalanceError: null,
      userData: profileBalance.profile,
    };
  }),
  on(profileBalanceError, (state: ProfileReducerState) => {
    return {
      ...state,
      profileBalance: null,
      isLoading: false,
      isLoaded: false,
      profileBalanceError: "Error occurred whilst retrieving Profile Balance",
    };
  }),
  // User Profile Balance
  on(
    userProfileBalanceLoaded,
    (state: ProfileReducerState, { userProfileBalance }) => {
      return {
        ...state,
        userProfileBalance,
        userProfileBalanceIsLoading: false,
        userProfileBalanceIsLoaded: true,
        userProfileBalanceError: null,
      };
    }
  ),
  on(userProfileBalanceError, (state: ProfileReducerState) => {
    return {
      ...state,
      userProfileBalance: null,
      userProfileBalanceIsLoading: false,
      userProfileBalanceIsLoaded: false,
      userProfileBalanceError:
        "Error occurred whilst retrieving User Profile Balance",
    };
  }),
  // Logout
  on(logoutLoaded, (state: ProfileReducerState) => {
    return {
      ...state,
      profileBalance: null,
      isLoading: false,
      isLoaded: false,
      profileBalanceError: null,
      userData: null,

      userProfileBalance: null,
      userProfileBalanceIsLoading: false,
      userProfileBalanceIsLoaded: false,
      userProfileBalanceError: null,
    };
  })
);

export function reducer(
  state: ProfileReducerState | undefined,
  action: Action
) {
  return ProfileReducer(state, action);
}
