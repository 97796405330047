<app-side-menu-modal
  [customClass]="'kyc'"
  [sideMenuTitle]="'confirmidentity.page_title'"
>
  <!-- modal body -->
  <ng-container sideMenuBody>
    <p class="text-center kyc-page-text-color">
      {{ "confirmidentity.description" | translate }}
    </p>

    <p class="text-center kyc-page-text-color m-b-16">
      <span class="upload-infomsg">
        <i>{{
          "confirmidentity.upload_note" | translate
        }}</i>
      </span>
    </p>

    <accordion
      class="accordion"
      [showArrows]="true"
      [closeOthers]="true"
      [expandAll]="false"
    >
      <!--My Documents Start-->
      <accordion-group>
        <accordion-heading>
          {{ "confirmidentity.my_documents" | translate }}
        </accordion-heading>

        <div class="accordion-content">
          <div
            class="kyc__card"
            [class.inprogress]="
              accountDocumentStatus?.identityStatus ===
              'vrfn_init'
            "
            [class.in-accepted]="
              accountDocumentStatus?.identityStatus ===
              'vrfn_verified'
            "
            [class.in-rejected]="
              accountDocumentStatus?.identityStatus ===
              'vrfn_failed'
            "
          >
            <ng-container
              *ngTemplateOutlet="
                proofOfIdentityVerifyInitTemplate
              "
            ></ng-container>
          </div>

          <div
            class="kyc__card"
            [class.inprogress]="
              accountDocumentStatus?.addressStatus ===
              'vrfn_init'
            "
            [class.in-accepted]="
              accountDocumentStatus?.addressStatus ===
              'vrfn_verified'
            "
            [class.in-rejected]="
              accountDocumentStatus?.addressStatus ===
              'vrfn_failed'
            "
          >
            <ng-container
              *ngTemplateOutlet="
                proofOfAddressVerifyInitTemplate
              "
            ></ng-container>
          </div>

          <div
            class="kyc__card"
            [class.inprogress]="
              accountDocumentStatus?.cafStatus ===
              'vrfn_init'
            "
            [class.in-accepted]="
              accountDocumentStatus?.cafStatus ===
              'vrfn_verified'
            "
            [class.in-rejected]="
              accountDocumentStatus?.cafStatus ===
              'vrfn_failed'
            "
          >
            <ng-container
              *ngTemplateOutlet="
                proofOfPaymentVerifyInitTemplate
              "
            ></ng-container>
          </div>

          <div
            class="kyc__card"
            [class.inprogress]="
              accountDocumentStatus?.sourceOfIncomeStatus ===
              'vrfn_init'
            "
            [class.in-accepted]="
              accountDocumentStatus?.sourceOfIncomeStatus ===
              'vrfn_verified'
            "
            [class.in-rejected]="
              accountDocumentStatus?.sourceOfIncomeStatus ===
              'vrfn_failed'
            "
          >
            <ng-container
              *ngTemplateOutlet="
                sourceOfFundsVerifyInitTemplate
              "
            ></ng-container>
          </div>
        </div>
      </accordion-group>

      <hr class="separator m-b-16 clearfix" />

      <!--Proof of ID  Start-->
      <accordion-group>
        <accordion-heading>
          {{ "confirmidentity.proof_id" | translate }}
        </accordion-heading>

        <div
          class="accordion-content"
          *ngIf="kycLevelDetails?.identity === 'vrfn_new'"
        >
          <app-proof-of-identity
            (uploadIdenityProofFiles)="
              onUploadDocument($event)
            "
            [uploadResponseData]="
              updateStatusData['nationalid']
            "
            [isButtonDisabled]="isButtonDisabled"
          ></app-proof-of-identity>
        </div>
      </accordion-group>

      <!-- Proof of address Start-->
      <accordion-group>
        <accordion-heading>
          {{ "confirmidentity.proof_address" | translate }}
        </accordion-heading>

        <div
          class="accordion-content"
          *ngIf="kycLevelDetails?.address === 'vrfn_new'"
        >
          <app-proof-of-address
            (uploadAddressProofFiles)="
              onUploadDocument($event)
            "
            [uploadResponseData]="
              updateStatusData.utilitybill
            "
            [isButtonDisabled]="isButtonDisabled"
          ></app-proof-of-address>
        </div>
      </accordion-group>

      <!-- Proof of funds start-->
      <accordion-group>
        <accordion-heading>
          {{ "confirmidentity.proof_payment" | translate }}
        </accordion-heading>

        <div
          class="accordion-content"
          *ngIf="
            kycLevelDetails?.paymentInstrument ===
            'vrfn_new'
          "
        >
          <app-proof-of-payment
            (uploadProofOfPaymentFiles)="
              onUploadDocument($event)
            "
            [uploadResponseData]="
              updateStatusData['proof-of-payment']
            "
            [isButtonDisabled]="isButtonDisabled"
          ></app-proof-of-payment>
        </div>
      </accordion-group>

      <!-- Source of fundes Start -->
      <accordion-group
        [disabled]="
          kycLevelDetails?.sourceOfIncome !== 'vrfn_new'
        "
      >
        <accordion-heading>
          {{ "confirmidentity.source_funds" | translate }}
        </accordion-heading>

        <div
          class="accordion-content"
          *ngIf="
            kycLevelDetails?.sourceOfIncome === 'vrfn_new'
          "
        >
          <app-source-of-fund
            (uploadProofOfPaymentFiles)="
              onUploadDocument($event)
            "
            [uploadResponseData]="
              updateStatusData['source-of-fund']
            "
            [isButtonDisabled]="isButtonDisabled"
          ></app-source-of-fund>
        </div>
      </accordion-group>
    </accordion>
  </ng-container>
</app-side-menu-modal>

<!-- 
  ------------------------------------------------------
  
  Templates 
  
  ------------------------------------------------------
-->
<!--

  Identity Status Verify Initialization
  identityStatus === 'vrfn_init'

-->
<ng-template #proofOfIdentityVerifyInitTemplate>
  <h5 class="sub-title">
    {{ "confirmidentity.image_proof_id" | translate }}
  </h5>

  <div class="status-row">
    <p
      [class.inprogress-group]="
        accountDocumentStatus?.identityStatus ===
        'vrfn_init'
      "
      [class.in-accepted-group]="
        accountDocumentStatus?.identityStatus ===
        'vrfn_verified'
      "
      [class.in-rejected-group]="
        accountDocumentStatus?.identityStatus ===
        'vrfn_failed'
      "
    >
      <span class="status-info"
        >{{ "confirmidentity.status" | translate }} :
      </span>

      <ng-container
        [ngSwitch]="accountDocumentStatus?.identityStatus"
      >
        <ng-container *ngSwitchCase="'vrfn_new'">
          <strong>
            {{
              "confirmidentity.not_yet_uploaded" | translate
            }}
          </strong>
        </ng-container>

        <ng-container *ngSwitchCase="'vrfn_init'">
          <strong class="in-progress">
            {{ "confirmidentity.in_progress" | translate }}
          </strong>
        </ng-container>

        <ng-container *ngSwitchCase="'vrfn_verified'">
          <strong class="success">
            {{ "confirmidentity.verified" | translate }}
          </strong>
        </ng-container>

        <ng-container *ngSwitchCase="'vrfn_failed'">
          <strong class="reject">
            {{ "confirmidentity.rejected" | translate }}
          </strong>
        </ng-container>

        <ng-container *ngSwitchDefault>
          <strong>
            {{
              "confirmidentity.not_yet_uploaded" | translate
            }}
          </strong>
        </ng-container>
      </ng-container>

      <ng-container
        [ngSwitch]="accountDocumentStatus?.identityStatus"
      >
        <ng-container *ngSwitchCase="'vrfn_init'">
          <div class="wknd-circle-initial">
            <div class="wknd-ellipsis"></div>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="'vrfn_verified'">
          <div class="wknd-circle-success">
            <div class="wknd-ok"></div>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="'vrfn_failed'">
          <div class="wknd-circle-failure">
            <div class="wknd-cancel"></div>
          </div>
        </ng-container>
      </ng-container>
    </p>
  </div>
</ng-template>

<!--

  Address Status Verify Initialization
  addressStatus === 'vrfn_init'

-->
<ng-template #proofOfAddressVerifyInitTemplate>
  <h5 class="sub-title">
    {{ "confirmidentity.proof_address" | translate }}
  </h5>

  <div class="status-row">
    <p
      [class.inprogress-group]="
        accountDocumentStatus?.addressStatus === 'vrfn_init'
      "
      [class.in-accepted-group]="
        accountDocumentStatus?.addressStatus ===
        'vrfn_verified'
      "
      [class.in-rejected-group]="
        accountDocumentStatus?.addressStatus ===
        'vrfn_failed'
      "
    >
      <span class="status-info">
        {{ "confirmidentity.status" | translate }} :
      </span>

      <ng-container
        [ngSwitch]="accountDocumentStatus?.addressStatus"
      >
        <ng-container *ngSwitchCase="'vrfn_new'">
          <strong>
            {{
              "confirmidentity.not_yet_uploaded" | translate
            }}
          </strong>
        </ng-container>

        <ng-container *ngSwitchCase="'vrfn_init'">
          <strong class="in-progress">
            {{ "confirmidentity.in_progress" | translate }}
          </strong>
        </ng-container>

        <ng-container *ngSwitchCase="'vrfn_verified'">
          <strong class="success">
            {{ "confirmidentity.verified" | translate }}
          </strong>
        </ng-container>

        <ng-container *ngSwitchCase="'vrfn_failed'">
          <strong class="reject">
            {{ "confirmidentity.rejected" | translate }}
          </strong>
        </ng-container>

        <ng-container *ngSwitchDefault>
          <strong>
            {{
              "confirmidentity.not_yet_uploaded" | translate
            }}
          </strong>
        </ng-container>
      </ng-container>

      <ng-container
        [ngSwitch]="accountDocumentStatus?.addressStatus"
      >
        <ng-container *ngSwitchCase="'vrfn_init'">
          <div class="wknd-circle-initial">
            <div class="wknd-ellipsis"></div>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="'vrfn_verified'">
          <div class="wknd-circle-success">
            <div class="wknd-ok"></div>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="'vrfn_failed'">
          <div class="wknd-circle-failure">
            <div class="wknd-cancel"></div>
          </div>
        </ng-container>
      </ng-container>
    </p>
  </div>
</ng-template>

<!--

  Proof of Payment Verify Initialization
  accountDocumentStatus?.cafStatus === 'vrfn_init'

-->
<ng-template #proofOfPaymentVerifyInitTemplate>
  <h5 class="sub-title">
    {{ "confirmidentity.proof_payment" | translate }}
  </h5>

  <div class="status-row">
    <p
      [class.inprogress-group]="
        accountDocumentStatus?.cafStatus === 'vrfn_init'
      "
      [class.in-accepted-group]="
        accountDocumentStatus?.cafStatus === 'vrfn_verified'
      "
      [class.in-rejected-group]="
        accountDocumentStatus?.cafStatus === 'vrfn_failed'
      "
    >
      <span class="status-info"
        >{{ "confirmidentity.status" | translate }} :
      </span>

      <ng-container
        [ngSwitch]="accountDocumentStatus?.cafStatus"
      >
        <ng-container *ngSwitchCase="'vrfn_new'">
          <strong>
            {{
              "confirmidentity.not_yet_uploaded" | translate
            }}
          </strong>
        </ng-container>

        <ng-container *ngSwitchCase="'vrfn_init'">
          <strong class="in-progress">
            {{ "confirmidentity.in_progress" | translate }}
          </strong>
        </ng-container>

        <ng-container *ngSwitchCase="'vrfn_verified'">
          <strong class="success">
            {{ "confirmidentity.verified" | translate }}
          </strong>
        </ng-container>

        <ng-container *ngSwitchCase="'vrfn_failed'">
          <strong class="reject">
            {{ "confirmidentity.rejected" | translate }}
          </strong>
        </ng-container>

        <ng-container *ngSwitchDefault>
          <strong>
            {{
              "confirmidentity.not_yet_uploaded" | translate
            }}
          </strong>
        </ng-container>
      </ng-container>

      <ng-container
        [ngSwitch]="accountDocumentStatus?.cafStatus"
      >
        <ng-container *ngSwitchCase="'vrfn_init'">
          <div class="wknd-circle-initial">
            <div class="wknd-ellipsis"></div>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="'vrfn_verified'">
          <div class="wknd-circle-success">
            <div class="wknd-ok"></div>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="'vrfn_failed'">
          <div class="wknd-circle-failure">
            <div class="wknd-cancel"></div>
          </div>
        </ng-container>
      </ng-container>
    </p>
  </div>
</ng-template>

<!--

  Source of Funds Verify Initialization
  accountDocumentStatus?.sourceOfIncomeStatus === 'vrfn_init'

-->
<ng-template #sourceOfFundsVerifyInitTemplate>
  <h5 class="sub-title">
    {{ "confirmidentity.source_funds" | translate }}
  </h5>

  <div class="status-row">
    <p
      [class.inprogress-group]="
        accountDocumentStatus?.sourceOfIncomeStatus ===
        'vrfn_init'
      "
      [class.in-accepted-group]="
        accountDocumentStatus?.sourceOfIncomeStatus ===
        'vrfn_verified'
      "
      [class.in-rejected-group]="
        accountDocumentStatus?.sourceOfIncomeStatus ===
        'vrfn_failed'
      "
    >
      <span class="status-info"
        >{{ "confirmidentity.status" | translate }} :
      </span>

      <ng-container
        [ngSwitch]="
          accountDocumentStatus?.sourceOfIncomeStatus
        "
      >
        <ng-container *ngSwitchCase="'vrfn_new'">
          <strong>
            {{
              "confirmidentity.not_yet_uploaded" | translate
            }}
          </strong>
        </ng-container>

        <ng-container *ngSwitchCase="'vrfn_init'">
          <strong class="in-progress">
            {{ "confirmidentity.in_progress" | translate }}
          </strong>
        </ng-container>

        <ng-container *ngSwitchCase="'vrfn_verified'">
          <strong class="success">
            {{ "confirmidentity.verified" | translate }}
          </strong>
        </ng-container>

        <ng-container *ngSwitchCase="'vrfn_failed'">
          <strong class="reject">
            {{ "confirmidentity.rejected" | translate }}
          </strong>
        </ng-container>

        <ng-container *ngSwitchDefault>
          <strong>
            {{
              "confirmidentity.not_yet_uploaded" | translate
            }}
          </strong>
        </ng-container>
      </ng-container>

      <ng-container
        [ngSwitch]="
          accountDocumentStatus?.sourceOfIncomeStatus
        "
      >
        <ng-container *ngSwitchCase="'vrfn_init'">
          <div class="wknd-circle-initial">
            <div class="wknd-ellipsis"></div>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="'vrfn_verified'">
          <div class="wknd-circle-success">
            <div class="wknd-ok"></div>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="'vrfn_failed'">
          <div class="wknd-circle-failure">
            <div class="wknd-cancel"></div>
          </div>
        </ng-container>
      </ng-container>
    </p>
  </div>
</ng-template>
