/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./livespins-live-casino.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../csn-livespins-iframe/csn-livespins-iframe.component.ngfactory";
import * as i3 from "../csn-livespins-iframe/csn-livespins-iframe.component";
import * as i4 from "@angular/common";
import * as i5 from "../../../shared/components/loader/loader.component.ngfactory";
import * as i6 from "../../../shared/components/loader/loader.component";
import * as i7 from "./livespins-live-casino.component";
import * as i8 from "../../../multi-languages/services/multi-language.service";
import * as i9 from "../../../multi-languages/services/translation.service";
import * as i10 from "../../services/livespins.service";
import * as i11 from "@angular/router";
import * as i12 from "../../../auth/services/session.service";
import * as i13 from "../../../shared/services/common.service";
import * as i14 from "@angular/common/http";
import * as i15 from "@ngrx/store";
var styles_LivespinsLiveCasinoComponent = [i0.styles];
var RenderType_LivespinsLiveCasinoComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_LivespinsLiveCasinoComponent, data: {} });
export { RenderType_LivespinsLiveCasinoComponent as RenderType_LivespinsLiveCasinoComponent };
function View_LivespinsLiveCasinoComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_LivespinsLiveCasinoComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_LivespinsLiveCasinoComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_LivespinsLiveCasinoComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-csn-livespins-iframe", [], null, null, null, i2.View_CsnLivespinsIFrameComponent_0, i2.RenderType_CsnLivespinsIFrameComponent)), i1.ɵdid(2, 573440, null, 0, i3.CsnLivespinsIFrameComponent, [], { iframeURL: [0, "iframeURL"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.iframeURL; _ck(_v, 2, 0, currVal_0); }, null); }
function View_LivespinsLiveCasinoComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "livespins-iframe"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [["class", "livespins-page-background"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LivespinsLiveCasinoComponent_6)), i1.ɵdid(4, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.iframeURL; _ck(_v, 4, 0, currVal_0); }, null); }
function View_LivespinsLiveCasinoComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_LivespinsLiveCasinoComponent_5)), i1.ɵdid(1, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (!_co.noGameUrlError && !_co.isLoading); _ck(_v, 1, 0, currVal_0); }, null); }
function View_LivespinsLiveCasinoComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "app-loader"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-loader", [], null, null, null, i5.View_LoaderComponent_0, i5.RenderType_LoaderComponent)), i1.ɵdid(3, 49152, null, 0, i6.LoaderComponent, [], null, null)], null, null); }
function View_LivespinsLiveCasinoComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_LivespinsLiveCasinoComponent_8)), i1.ɵdid(1, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.isLoading && !_co.noGameUrlError); _ck(_v, 1, 0, currVal_0); }, null); }
function View_LivespinsLiveCasinoComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.noGameUrlError; _ck(_v, 2, 0, currVal_0); }); }
function View_LivespinsLiveCasinoComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_LivespinsLiveCasinoComponent_10)), i1.ɵdid(1, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.noGameUrlError; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_LivespinsLiveCasinoComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "livespins-page"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LivespinsLiveCasinoComponent_1)), i1.ɵdid(2, 540672, null, 0, i4.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LivespinsLiveCasinoComponent_2)), i1.ɵdid(4, 540672, null, 0, i4.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LivespinsLiveCasinoComponent_3)), i1.ɵdid(6, 540672, null, 0, i4.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null), (_l()(), i1.ɵand(0, [["iframeTemplate", 2]], null, 0, null, View_LivespinsLiveCasinoComponent_4)), (_l()(), i1.ɵand(0, [["loadingTemplate", 2]], null, 0, null, View_LivespinsLiveCasinoComponent_7)), (_l()(), i1.ɵand(0, [["errorTemplate", 2]], null, 0, null, View_LivespinsLiveCasinoComponent_9))], function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 8); _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 7); _ck(_v, 4, 0, currVal_1); var currVal_2 = i1.ɵnov(_v, 9); _ck(_v, 6, 0, currVal_2); }, null); }
export function View_LivespinsLiveCasinoComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-livespins-live-casino", [], null, null, null, View_LivespinsLiveCasinoComponent_0, RenderType_LivespinsLiveCasinoComponent)), i1.ɵdid(1, 245760, null, 0, i7.LivespinsLiveCasinoComponent, [i8.MultiLanguageService, i9.TranslationService, i10.LivespinsService, i11.ActivatedRoute, i12.SessionService, i13.CommonService, i14.HttpClient, i15.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LivespinsLiveCasinoComponentNgFactory = i1.ɵccf("app-livespins-live-casino", i7.LivespinsLiveCasinoComponent, View_LivespinsLiveCasinoComponent_Host_0, {}, {}, []);
export { LivespinsLiveCasinoComponentNgFactory as LivespinsLiveCasinoComponentNgFactory };
