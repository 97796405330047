import { catchError, map } from "rxjs/operators";
import { throwError } from "rxjs";
// API Interactors
import { ApiInteractors } from "src/app/models/interactors/api.interactor";
// Enums
import { Project } from "src/app/models/environments/project.enum";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class AccountService {
    constructor(httpClient) {
        this.httpClient = httpClient;
        this.apiInteractor = new ApiInteractors(this.httpClient);
    }
    // -----------------------------------------------------------------
    // Get Methods - Observable
    onSubscriptionPreference() {
        return this.apiInteractor
            .post(`/ajax/profile/getSubscriptionPreferenes`, null, Project.Shotz)
            .pipe(map((subscriptionPreferenceResponse) => {
            return subscriptionPreferenceResponse;
        }), catchError((error) => {
            return throwError(error);
        }));
    }
    onUpdateSubscriptionPreference(updateSubscriptionPreferenceRequest) {
        return this.apiInteractor
            .post(`/ajax/profile/updateSubscriptionPreferenes`, updateSubscriptionPreferenceRequest, Project.Shotz)
            .pipe(map((updateSubscriptionPreferenceResponse) => {
            return updateSubscriptionPreferenceResponse;
        }), catchError((error) => {
            return throwError(error);
        }));
    }
    onDropBonus(dropBonusRequest) {
        return this.apiInteractor
            .get(`/ajax/bonus/drop`, dropBonusRequest, Project.Shotz)
            .pipe(map((dropBonusResponse) => {
            return dropBonusResponse;
        }), catchError((error) => {
            return throwError(error);
        }));
    }
    onGetAccountVerficationStatus() {
        return this.apiInteractor
            .get(`/ajax/profile/getAccountVerificationStatus`, null, Project.Shotz)
            .pipe(map((kycStatusResponse) => {
            return kycStatusResponse;
        }), catchError((error) => {
            return throwError(error);
        }));
    }
}
AccountService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AccountService_Factory() { return new AccountService(i0.ɵɵinject(i1.HttpClient)); }, token: AccountService, providedIn: "root" });
