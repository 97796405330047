<div class="game-group__container">
  <div class="game-group--title">
    <span
      class="gameGroupName-link"
      (click)="onNavigateAllGamesPage()"
    >
      {{gameGroupData.name}}
    </span>
    <span
      class="viewall-link"
      (click)="onNavigateAllGamesPage()"
    >
      {{'gamegroup.view_all'| translate }}
    </span>
  </div>
  <div class="swiper__outer">
    <div class="swiper__inner swiper__full">
      <swiper
        class="mySwiper showreel swiper-control"
        [config]="swiperVidoeShow"
        (slidePrevTransitionEnd)="onSwiperPrevEvent()"
        (slideNextTransitionEnd)="onSwiperNextEvent()"
        watchSlidesProgress
      >
        <div
          class="game-card showreel"
          *ngFor="let game of gamesList | slice:0:swiperLazyConfigurations.indexNumberOfGameTilesToshow; let i = index"
        >
          <app-vimeo-player
            [gameData]="game"
            [isLoggedIn]="isLoggedIn"
            (gameLaunch)="onTriggerGameLaunch($event)"
          ></app-vimeo-player>

          <div class="game-card__footer">
            <div class="game-title showreel-title">
              {{game.name}}
            </div>
            <button
              aria-label="Play now"
              class="btn btn-primary btn-round btn-sm game-cta"
              (click)="onGameLaunch(game.name,game.hasDemo)"
            >
              {{'general.play_now' | translate}}
            </button>
          </div>
        </div>
      </swiper>
    </div>
  </div>
</div>
