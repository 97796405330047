import { createReducer, on } from "@ngrx/store";
// Actions
import { logoutLoaded } from "src/app/modules/auth/store/actions/auth.actions";
import { userLoaded, userError, } from "src/app/modules/user/store/actions/user.actions";
export const UserStateFeatureKey = "user";
export const initialState = {
    // User Data
    userData: null,
    isLoaded: false,
    isError: false,
    isLoading: false,
    userDataError: null,
};
const ɵ0 = (state, { userData }) => {
    return Object.assign({}, state, { userData, isLoading: false, isLoaded: true, isError: false, userDataError: null });
}, ɵ1 = (state) => {
    return Object.assign({}, state, { userData: null, isLoading: false, isLoaded: false, isError: true, userDataError: "Error occurred whilst retrieving User" });
}, ɵ2 = (state) => {
    return Object.assign({}, state, { userData: null, isLoading: false, isLoaded: false, userDataError: null });
};
// Reducer
const UserReducer = createReducer(initialState, 
// User
on(userLoaded, ɵ0), on(userError, ɵ1), 
// Logout
on(logoutLoaded, ɵ2));
export function reducer(state, action) {
    return UserReducer(state, action);
}
export { ɵ0, ɵ1, ɵ2 };
