// Enums
import { WorldCurrencyCode } from "src/app/models/configurations/enums/localization/world-currencies.enum";
import { StatusResponse } from "src/app/models/api/status.response";

// Models
import { GamePregmatic } from "src/app/modules/game-groups/models/game.model";

export interface EligibleBonusItem {
  bonusCode?: string;
  criteriaType?: EligibleBonusCriteriaType;
  validityDays?: number;
  validityTillDate?: number;
  refCurrency?: WorldCurrencyCode;
  isLinkedBonus?: number;
  linkedBonusId?: number;
  channelId?: string;
  subChannelId?: string;
  productId?: EligibleBonusProductType;
  campaignStartDate?: number;
  campaignEndDate?: number;
  playerTargetType?: string;
  playerTargetSubTypes?: string;
  amountType?: EligibleBonusAmountType;
  currency?: WorldCurrencyCode;
  // Valid values: 0 and 1.
  // If the value is 0, it means the bonus is inactive. If the value is 1, it means the bonus is active. Inactive bonus (value as 0) is not applicable for this API.
  status?: number;
  bonusName?: string;
  bonusGroupId?: number;
  preOfferId?: number;
  maxOfferPerUser?: number;
  maxBonusAmount?: string;
  maxBonuses?: number;
  priority?: number;
  segmentStatus?: boolean;
  segmentRuleList?: EligibleBonusSegmentRule[];
  currencyTier?: CurrencyTier;
  affiliateId?: string | null;
  issueMeasure?: string;
  issueMultiplier?: number;
  spinType?: string | null;
  absIssueCriteria?: AbsIssueCriteria;
  vendorId?: string | null;
  gameIds?: string[];
  depositOptions?: string[];
  bonusID?: number;
  bonusCategory?: EligibleBonusCategory;
  active?: number;
  offerCurrency?: WorldCurrencyCode;

  // Added as common fields
  depositRequired?: boolean;
  bonusTypeFD?: string;
  freeSpinGameIds?: string[];
  gameData?: GamePregmatic;
  levelId?: number;
  freespinExpiryDate?: string;
  freeSpinCount?: number;
  freeSpinValue?: number;
  targetValidityDate?: string;
  playerOptinTime?: number;
}

export interface EligibleBonus {
  ecrExternalId: string;
  status: StatusResponse;
  errorCode: string;
  errorDescription: string;
  eligibleBonusList: EligibleBonusItem[];
}

export enum EligibleBonusCriteriaType {
  SIGNUP = "SIGNUP",
  LOGIN = "LOGIN",
  DEPOSIT = "DEPOSIT",
  SPECIAL = "SPECIAL",
  ALL = "ALL",
}

export enum EligibleBonusPlayerTargetType {
  ALL = "ALL",
  Segment = "Segment",
  "Segment Campaigns" = "Segment Campaigns",
  CSV = "CSV",
  Status = "Status",
  Optimove = "Optimove",
}

export enum EligibleBonusProductType {
  CASINO = "CASINO",
  BINGO = "BINGO",
  SPORTS_BOOK = "SPORTS_BOOK",
  FANTASY = "FANTASY",
}

export enum EligibleBonusAmountType {
  // ABS stands for Absolute
  ABS = "ABS",
  // PCT stands Percentage
  PCT = "PCT",
}

export enum EligibleBonusSegmentRule {
  CONTACT_VERIFICATION = "CONTACT_VERIFICATION",
  RISKPOINTS_GREATER_THAN_0 = "RISKPOINTS_GREATER_THAN_0",
}

export enum EligibleBonusCategory {
  CASHOUT_RESTRICT = "CASHOUT_RESTRICT",
  RELEASE_RESTRICT = "RELEASE_RESTRICT",
}

export interface AbsIssueCriteria {
  [currencyCode: string]: number;
}

export interface CurrencyTier {
  [currencyCode: string]: CurrencyTierItem[];
}

export interface CurrencyTierItem {
  currency: WorldCurrencyCode;
  minDepositValue: number;
  maxDepositValue: number;
  minOfferValue: number;
  maxOfferValue: number;
  percentageValue: number;
  bonusOfferValueOnIssue: number;
  issueMultiplierForOffer: number;
  freespinCountOnIssue: number;
  freeSpinValue: number;
  freespinCountOrPct: number;
}
