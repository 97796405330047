import { Dictionary } from "@ngrx/entity";
import {
  createFeatureSelector,
  DefaultProjectorFn,
  MemoizedSelector,
  createSelector,
} from "@ngrx/store";

// Models
import { LobbyPregmatic } from "src/app/modules/game-groups/models/lobby/lobby.model";
import { GamePregmatic } from "src/app/modules/game-groups/models/game.model";

// Reducers
import {
  adapterLobbyPregmatic,
  GamesStateFeatureKey,
  LobbyPregmaticState,
  GamesReducerState,
  adapterGames,
  GamesState,
} from "src/app/modules/game-groups/store/reducers/games.reducer";

// Select the Main State
const selectGamesReducerState =
  createFeatureSelector<GamesReducerState>(GamesStateFeatureKey);

// Select Parts of the state
const selectGamesState = createSelector(
  selectGamesReducerState,
  (state: GamesReducerState) => state.gamesList
);

const selectLobbyPregmaticState = createSelector(
  selectGamesReducerState,
  (state: GamesReducerState) => state.lobbyPregmaticList
);

export const selectLastPlayedState = createSelector(
  selectGamesReducerState,
  (state: GamesReducerState) => state.lastPlayedList
);

// Games Adapter
const adapterGamesSelection = adapterGames.getSelectors();

// Retrieve All Games - Array Format
export const selectAllGames: MemoizedSelector<
  object,
  GamePregmatic[],
  DefaultProjectorFn<GamePregmatic[]>
> = createSelector(selectGamesState, adapterGamesSelection.selectAll);

// Retrieve All Games - Dictionary Format
export const selectGamesEntities: MemoizedSelector<
  object,
  Dictionary<GamePregmatic>,
  DefaultProjectorFn<Dictionary<GamePregmatic>>
> = createSelector(selectGamesState, adapterGamesSelection.selectEntities);

// Retrieve
export const selectAllGamesIsLoaded: MemoizedSelector<
  object,
  GamesState,
  DefaultProjectorFn<GamesState>
> = createSelector(selectGamesState, (state: GamesState) => state);

// Lobby Pregmatic Adapter
const adapterLobbyPregmaticSelection = adapterLobbyPregmatic.getSelectors();

// Retrieve All Lobby Pregmatic - Array Format
export const selectAllLobbyPregmatic: MemoizedSelector<
  object,
  LobbyPregmatic[],
  DefaultProjectorFn<LobbyPregmatic[]>
> = createSelector(
  selectLobbyPregmaticState,
  adapterLobbyPregmaticSelection.selectAll
);

// Retrieve All Lobby Pregmatic - Dictionary Format
export const selectLobbyPregmaticEntities: MemoizedSelector<
  object,
  Dictionary<LobbyPregmatic>,
  DefaultProjectorFn<Dictionary<LobbyPregmatic>>
> = createSelector(
  selectLobbyPregmaticState,
  adapterLobbyPregmaticSelection.selectEntities
);

// Retrieve
export const selectAllLobbyPregmaticIsLoaded: MemoizedSelector<
  object,
  boolean,
  DefaultProjectorFn<boolean>
> = createSelector(
  selectLobbyPregmaticState,
  (state: LobbyPregmaticState) => state.isLoaded
);

export const selectAllGamesLoadingState: MemoizedSelector<
  object,
  {
    isLoaded: boolean;
    isLoading: boolean;
  },
  DefaultProjectorFn<{
    isLoaded: boolean;
    isLoading: boolean;
  }>
> = createSelector(selectGamesState, (state: GamesState) => {
  return {
    isLoaded: state.isLoaded,
    isLoading: state.isLoading,
  };
});
