<ng-container *ngIf="selectedPaymentMethod">
  <section
    class="qd"
    (mouseenter)="onHoverBottomRibbon()"
    (mouseleave)="onMouseLeaveBottomRibbon()"
  >
    <div class="qd-inner">
      <ng-container *ngIf="showScreen === 'payment' && !isDepositProgress">
        <div class="qd-step qd-screen1 fade-in">
          <form [formGroup]="cashierDetailsForm" novalidate class="qd-form">
            <span class="cc-img">
              <img
                src="/assets/img/qd/{{selectedPaymentMethod.cardType.toLowerCase()}}.png"
                alt="{{selectedPaymentMethod.cardType}}"
                alt="{{selectedPaymentMethod.cardType}}"
              />

              <span class="card-number">
                {{selectedPaymentMethod.maskedAccount.slice(-6)}}
              </span>
            </span>

            <span class="controls m-l-16 qdAmountInput-wrap qd-input-group">
              <input
                type="tel"
                id="qdAmountInput"
                name="amount"
                class="input amount qdAmountInput"
                formControlName="amount"
                maxlength="8"
                size="4"
                appInputCurrencyPlacement
                autofocus="autofocus"
                autocomplete="off"
                (input)="onSetInputSize()"
                (focusout)="onSetInputSize()"
              />
            </span>

            <span class="controls m-l-8 cvv">
              <input
                type="tel"
                id="cvv"
                name="cvv"
                class="input cvv"
                maxlength="3"
                formControlName="cvv"
                autocomplete="off"
                placeholder="CCV"
              />
            </span>

            <button
              aria-label="Deposit"
              class="btn btn-primary btn-sm m-l-8 btn-round"
              [disabled]="cashierDetailsForm.invalid || cashierDetailsForm.pending"
              (click)="onMakeQuickDeposit()"
            >
              {{'tag-management.deposit' | translate}}
            </button>
          </form>
        </div>
      </ng-container>

      <ng-container *ngIf="showScreen === 'transaction' && !isDepositProgress">
        <ng-container
          *ngIf="transactionStatus && transactionStatus.status === 'Success'"
        >
          <div class="qd-step qd-screen2 qd-status qd-success fade-in">
            {{'quick_deposit.youhave_added' | translate}}

            <strong class="p-l-4 p-r-4">
              {{transactionStatus.successAmount |
              currencyformat:currencySymbol}}
            </strong>
            {{'quick_deposit.your_account' | translate}}
          </div>
        </ng-container>

        <ng-container
          *ngIf="transactionStatus && transactionStatus.status === 'failure'"
        >
          <div class="qd-step qd-screen3 qd-status qd-failure fade-in">
            <strong>{{'quick_deposit.card_unauthorized' | translate}}</strong>

            <button
              class="btn btn-primary btn-sm m-l-12 btn-round"
              aria-label="Try again"
              (click)="onRetry()"
            >
              {{'quick_deposit.try_again' | translate}}
            </button>
          </div>
        </ng-container>
      </ng-container>

      <ng-container
        *ngIf="isDepositProgress || (!selectedPaymentMethod && isLoading)"
      >
        <div class="qd-loader fade-in">
          <app-loader class="clearfix qd-step load-space m-auto"></app-loader>
        </div>
      </ng-container>
    </div>
  </section>
</ng-container>

<section
  id="qd-3ddeposit-popup"
  class="QD-3d__modal QD-3d__modal--backdrop"
  [style.display]="isQd3DPopup ? 'block' : 'none'"
>
  <div class="QD-3d__modal__wrapper">
    <div class="QD-3d__modal__container">
      <button
        class="btn modal-popup--close-btn QD-3d__modal--close-btn"
        aria-label="Close"
        (click)="onCancel3DTransaction()"
      >
        <i class="icon-close"></i>
      </button>

      <div id="redirectionDiv" class="QD-3d__modal__body">
        <app-loader></app-loader>
      </div>
    </div>
  </div>
</section>
