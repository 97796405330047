import { catchError, map } from "rxjs/operators";
import { throwError } from "rxjs";
// Api Interactors
import { ApiInteractors } from "src/app/models/interactors/api.interactor";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class PolicyService {
    constructor(httpClient) {
        this.httpClient = httpClient;
        this.apiInteractor = new ApiInteractors(this.httpClient);
    }
    // -----------------------------------------------------------------
    // Get Methods
    onGetPolicies(policyType) {
        return this.apiInteractor
            .get(`/ajax/policy/getPolicies`)
            .pipe(map((response) => {
            if (response &&
                response.policyConfigs &&
                response.policyConfigs.length > 0) {
                let policies = response.policyConfigs.filter((policy) => policy.policyType === policyType);
                if (policies.length > 0) {
                    return policies[0];
                }
                return null;
            }
            return null;
        }), catchError((error) => {
            return throwError(error);
        }));
    }
    onGetUserPolicyDetails(policyType) {
        return this.apiInteractor
            .get(`/ajax/policy/getUserPolicyDetails`)
            .pipe(map((policyUserDetailsResponse) => {
            if (policyUserDetailsResponse &&
                policyUserDetailsResponse.policies &&
                policyUserDetailsResponse.policies.length > 0) {
                let policiesList = policyUserDetailsResponse.policies.filter((policy) => policy.policyType === policyType);
                if (policiesList.length > 0) {
                    return policiesList[0];
                }
                return null;
            }
            return null;
        }), catchError((error) => {
            return throwError(error);
        }));
    }
    /*OnGetUserPolicyDetailsHistory(
      policyType: string
    ): Observable<PolicyConfigsUserDetails[]> {
      return this.apiInteractor
        .get<null, PolicyUserDetailsResponse>(
          `/ajax/policy/getUserPolicyDetailsHistory`
        )
        .pipe(
          map((response: PolicyUserDetailsResponse) => {
            return response.policyConfigs.filter(
              (policy) => policy.policyType === policyType
            );
          }),
          catchError((error) => {
            return throwError(error);
          })
        );
    }*/
    onUpdateUserPolicyDetails(updatedPolicy) {
        const payload = {
            policies: JSON.stringify([updatedPolicy]),
        };
        return this.apiInteractor
            .post(`/ajax/policy/updateUserPolicyDetails`, payload)
            .pipe(map((policyUserDetailsUpdateResponse) => {
            return policyUserDetailsUpdateResponse;
        }), catchError((error) => {
            return throwError(error);
        }));
    }
}
PolicyService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PolicyService_Factory() { return new PolicyService(i0.ɵɵinject(i1.HttpClient)); }, token: PolicyService, providedIn: "root" });
