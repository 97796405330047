import { BehaviorSubject, Subject, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
// API Interactors
import { ApiInteractors } from "src/app/models/interactors/api.interactor";
// Enums
import { WorldCurrencyCode } from "src/app/models/configurations/enums/localization/world-currencies.enum";
import { Project } from "src/app/models/environments/project.enum";
// Environments
import { environment } from "src/environments/environment";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class UserDetailsService {
    constructor(httpClient) {
        this.httpClient = httpClient;
        // Numbers
        this.withdrawableBalance = 0;
        this.userTotalBalance = 0;
        this.userTotalBonus = 0;
        // Strings
        this.defaultCurrency = WorldCurrencyCode.EUR;
        this.defaultCurrencySymbol = "€";
        this.userBrowserCountry = "";
        this.userCurrencySymbol = "";
        this.userLanguageCode = "";
        this.userCountryCode = "";
        // --------------------------------------------------------
        // Subject and Behaviour Subject
        this.userProfileUpdatedSubject = new Subject();
        this.userProfileUpdatedSubject$ = this.userProfileUpdatedSubject.asObservable();
        // --------------------------------------------------------
        // Subject and Behaviour Subject
        this.userBalanceUpdatedSubject = new Subject();
        this.userBalanceUpdatedSubject$ = this.userBalanceUpdatedSubject.asObservable();
        // --------------------------------------------------------
        // Subject and Behaviour Subject
        this.currencySymbolBehaviourSubject = new BehaviorSubject(environment.defaultCurrencySymbol);
        this.currencySymbolBehaviourSubject$ = this.currencySymbolBehaviourSubject.asObservable();
        // --------------------------------------------------------
        // Subject and Behaviour Subject
        this.currencyCodeBehaviourSubject = new BehaviorSubject(WorldCurrencyCode[environment.defaultCurrencyCode]);
        this.currencyCodeBehaviourSubject$ = this.currencyCodeBehaviourSubject.asObservable();
        this.apiInteractor = new ApiInteractors(this.httpClient);
    }
    // -----------------------------------------------------------------
    // Get Methods
    getCurrencySymbol() {
        return this.userCurrencySymbol
            ? this.userCurrencySymbol
            : this.defaultCurrencySymbol;
    }
    getCurrencyCode() {
        return WorldCurrencyCode[this.userCurrencyCode ? this.userCurrencyCode : this.defaultCurrency];
    }
    getUserCurrencyDetails() {
        return this.userCurrencyDetails;
    }
    getUserBrowserCountry() {
        return this.userBrowserCountry;
    }
    getUserProfileDetails() {
        return this.userProfileDetails;
    }
    getWithdrawableBalance() {
        return this.withdrawableBalance;
    }
    getUserTotalBalance() {
        return this.userTotalBalance;
    }
    getUserTotalBonus() {
        return this.userTotalBonus;
    }
    getUserBalanceDetails() {
        return this.userBalanceDetails;
    }
    getLastLoginTime() {
        return this.lastLoginTime;
    }
    getUserCountryCode() {
        return this.userCountryCode;
    }
    // -----------------------------------------------------------------
    // Get Observable
    onGetUserTagsList() {
        return this.apiInteractor
            .get(`/ajax/profile/getUserTags`, null, Project.Shotz)
            .pipe(map((response) => {
            return response;
        }), catchError((error) => {
            return throwError(error);
        }));
    }
    onGetUserData() {
        return this.apiInteractor
            .get(`/ajax/profile/getData`, null, Project.Shotz)
            .pipe(map((response) => {
            return response;
        }), catchError((error) => {
            return throwError(error);
        }));
    }
    // -----------------------------------------------------------------
    // Set Methods - Set Currency
    onSetCurrencySymbol(currencySymbol) {
        this.userCurrencySymbol = currencySymbol;
        this.currencySymbolBehaviourSubject.next(currencySymbol);
    }
    onSetUserCurrencyCode(currencyCode) {
        this.userCurrencyCode = currencyCode;
        this.currencyCodeBehaviourSubject.next(currencyCode);
    }
    onSetUserProfileDetails(userProfile) {
        this.userProfileDetails = userProfile;
        this.userProfileDetails.firstName = this.userProfileDetails.firstName
            ? this.userProfileDetails.firstName.toLowerCase()
            : "";
        this.userProfileDetails.lastName = this.userProfileDetails.lastName
            ? this.userProfileDetails.lastName.toLowerCase()
            : "";
        this.userProfileDetails.affiliateLinkID = this.userProfileDetails
            .affiliateLinkID
            ? this.userProfileDetails.affiliateLinkID
            : "";
        this.onSetUserCountryCode(userProfile.country);
        this.onSetUserLanguageCode(userProfile.language);
        this.userProfileUpdatedSubject.next("UserProfileUpdated");
    }
    onSetUserCurrencyDetails(currencyDetails) {
        this.userCurrencyDetails = currencyDetails;
    }
    onSetUserBalanceDetails(userBalancePocketDetails) {
        this.userTotalBalance =
            userBalancePocketDetails.realCashBalance +
                userBalancePocketDetails.crpBalance +
                userBalancePocketDetails.wrpBalance +
                userBalancePocketDetails.drpBalance;
        this.userTotalBonus =
            userBalancePocketDetails.crpBalance + userBalancePocketDetails.wrpBalance;
        this.withdrawableBalance =
            userBalancePocketDetails.realCashBalance +
                userBalancePocketDetails.drpBalance;
        this.userBalanceDetails = {
            userTotalBalance: this.userTotalBalance,
            userTotalBonus: this.userTotalBalance,
            withdrawableBalance: this.withdrawableBalance,
        };
        this.userBalanceUpdatedSubject.next("UserBalanceUpdated");
    }
    onSetUserLanguageCode(countryCode) {
        this.userLanguageCode = countryCode;
    }
    onSetUserCountryCode(countryCode) {
        this.userCountryCode = countryCode;
    }
}
UserDetailsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserDetailsService_Factory() { return new UserDetailsService(i0.ɵɵinject(i1.HttpClient)); }, token: UserDetailsService, providedIn: "root" });
