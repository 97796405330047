// Configurations
import { mixPanelEventsConfigurations } from "src/app/configurations/main.configurations";
// Selectors
import { selectLanguageCode } from "src/app/modules/multi-languages/store/selectors/languages.selectors";
import { selectAuthLoginIsLoggedIn } from "src/app/modules/auth/store/selectors/auth.selectors";
import * as i0 from "@angular/core";
import * as i1 from "../../account/services/pay-n-play-cashier.service";
import * as i2 from "../../multi-languages/services/multi-language.service";
import * as i3 from "../../multi-languages/services/translation.service";
import * as i4 from "../../game-groups/services/game-play.service";
import * as i5 from "./mix-panel.service";
import * as i6 from "./utility.service";
import * as i7 from "../../auth/services/session.service";
import * as i8 from "../../account/services/cashier.service";
import * as i9 from "./common.service";
import * as i10 from "../../kyc/services/kyc.service";
import * as i11 from "@ngrx/store";
import * as i12 from "@angular/router";
export class DeepLinksService {
    constructor(payNPlayCashierService, multiLanguageService, translationService, gamePlayService, mixPanelService, utilityService, sessionService, cashierService, commonService, kycService, store, router) {
        this.payNPlayCashierService = payNPlayCashierService;
        this.multiLanguageService = multiLanguageService;
        this.translationService = translationService;
        this.gamePlayService = gamePlayService;
        this.mixPanelService = mixPanelService;
        this.utilityService = utilityService;
        this.sessionService = sessionService;
        this.cashierService = cashierService;
        this.commonService = commonService;
        this.kycService = kycService;
        this.store = store;
        this.router = router;
        // Strings
        this.languageCode = "";
        this.serverError = "";
        // Booleans
        this.isButtonLoader = false;
        this.isLoggedIn = false;
        this.isPassword = true;
        this.subscriptions = [];
        this.languageCode = this.multiLanguageService.getLanguageCode();
        this.isLoggedIn = this.sessionService.getIsUserLoggedIn();
        this.subscriptions = [
            this.store
                .select(selectLanguageCode)
                .subscribe((languageCode) => {
                this.languageCode = languageCode;
            }),
            this.store
                .select(selectAuthLoginIsLoggedIn)
                .subscribe((isLoggedIn) => {
                this.isLoggedIn = isLoggedIn;
            }),
        ];
    }
    // -----------------------------------------------------------------
    // Set Methods
    /*
      Below complete functioanlity deals with deep links before login
      basically when user try to access few part of product without login
      we useful ask user to force login before we ask for force login
      we store it's related data in service data layer & reuse it after successful
      login so that user lands on exact place where we preferred to be before login
    */
    onNavigateAfterLogin() {
        const navigateAfterLogin = this.commonService.getNavigateAfterLogin();
        if (navigateAfterLogin && navigateAfterLogin.type === "url") {
            this.onNavigateByUrlData(navigateAfterLogin);
        }
        else if (navigateAfterLogin && navigateAfterLogin.type === "view") {
            this.onNavigateByViewData(navigateAfterLogin);
        }
        else if (this.utilityService.getDecodedCurrentPath()) {
            this.onNavigationCurrentRoute();
        }
    }
    onNavigateByUrlData(navigateAfterLogin) {
        if (navigateAfterLogin.data.navigateTo === "lobby") {
            this.commonService.onBroadcastNavigationData(navigateAfterLogin.data);
            if (navigateAfterLogin.data.group) {
                this.router.navigate([
                    `${this.languageCode}/casino/${navigateAfterLogin.data.group}`,
                ]);
            }
        }
        if (navigateAfterLogin.data.navigateTo === "gameWindow") {
            this.gamePlayService.onSetGameCalledFrom("realgame");
            this.onSendMixPanelEvent(navigateAfterLogin.data);
            this.router.navigate([
                `${this.languageCode}/${this.translationService.get("url.game")}/${navigateAfterLogin.data.path}`,
            ]);
        }
        if (navigateAfterLogin.data.navigateTo === "livespins") {
            this.router.navigate([
                `${this.languageCode}/${navigateAfterLogin.data.navigateTo}`,
            ]);
        }
    }
    onNavigateByViewData(navigateAfterLogin) {
        if (navigateAfterLogin.path === "confirmidentity") {
            this.onKycLevelDetails(navigateAfterLogin);
        }
        else {
            if (navigateAfterLogin.path === "rewards") {
                this.utilityService.updateActiveLeftMenu("rewards");
            }
            if (navigateAfterLogin.path === "deposit" && navigateAfterLogin.data) {
                this.cashierService.onSetActiveDepositBonus({
                    bonusCode: navigateAfterLogin.data.bonusCode,
                });
            }
            const activeTab = {
                tabName: navigateAfterLogin.path,
                showBackButton: true,
            };
            this.utilityService.openAccountComponent(activeTab);
        }
    }
    onKycLevelDetails(navigateAfterLogin) {
        this.kycSubscription = this.kycService
            .onGetUserKycLevelDetails()
            .subscribe((kycLevelDetails) => {
            if (!kycLevelDetails.enableKyc) {
                const activeTab = {
                    tabName: "menuOptions",
                    showBackButton: true,
                };
                this.commonService.onBroadcastActiveAcountView(activeTab);
            }
            else {
                const activeTab = {
                    tabName: navigateAfterLogin.path,
                    showBackButton: true,
                };
                this.utilityService.openAccountComponent(activeTab);
            }
        });
    }
    onNavigationCurrentRoute() {
        const pathName = window.location.pathname;
        if (pathName.length <= 7) {
            this.router.navigate([`${this.languageCode}/casino`]);
        }
        else if (pathName.includes(this.translationService.get("url.game"))) {
            let gamePregmatic = this.gamePlayService.getCurrentGamePregmatic();
            if (gamePregmatic && gamePregmatic.gameType) {
                gamePregmatic.gameType = "realgame";
            }
            this.gamePlayService.onSetGameCalledFrom("realgame");
            this.gamePlayService.onSetCurrentGamePregmatic(gamePregmatic);
            this.gamePlayService.onBroadcastIsRelaunchGame(true);
        }
    }
    onSendMixPanelEvent(navigationData) {
        if (Object.keys(navigationData).length > 0) {
            let eventProperties = {
                name: navigationData.path,
                playType: "realgame",
                location: navigationData.gameGroupName,
                category: navigationData.lobbyName,
            };
            if (this.mixPanelService.getVerticalByUrl()) {
                eventProperties.vertical = this.mixPanelService.getVerticalByUrl();
            }
            this.mixPanelService.onTrackMixPanelEvents(mixPanelEventsConfigurations.launched_game, eventProperties);
        }
    }
    onNavigatePromotionQueryParameters(path) {
        let navigationAfterLogin = {
            type: "url",
            data: {
                navigateTo: "promotions",
                path: path,
            },
        };
        this.commonService.onSetNavigateAfterLogin(navigationAfterLogin);
        this.router.navigate([
            `${this.languageCode}/${this.translationService.get("url.promotions")}`,
        ]);
    }
    onNavigateGamePlayWindowQP(path) {
        let navigationAfterLogin = {
            type: "url",
            data: {
                navigateTo: "gameWindow",
                path: path.split("/")[3],
            },
        };
        this.commonService.onSetNavigateAfterLogin(navigationAfterLogin);
        this.router.navigate([`${this.languageCode}/casino`]);
    }
    onNavigateKycLevelQueryParameters(queryParams) {
        this.userKycLevelOpenAccountsSubscription = this.kycService
            .onGetUserKycLevelDetails()
            .subscribe((kycDetailsToUnblock) => {
            if (!kycDetailsToUnblock.enableKyc) {
                const activeTab = {
                    tabName: "menuOptions",
                    showBackButton: true,
                };
                this.commonService.onBroadcastActiveAcountView(activeTab);
            }
            else {
                const activeTab = {
                    tabName: queryParams.tab,
                    showBackButton: true,
                };
                this.utilityService.openAccountComponent(activeTab);
            }
        });
    }
    onNavigateLoggedInQP(queryParams) {
        if (queryParams.tab === "deposit" &&
            queryParams.hasOwnProperty("bonusCode")) {
            this.cashierService.onSetActiveDepositBonus({
                bonusCode: queryParams.bonusCode,
            });
        }
        if (queryParams.tab === "deposit") {
            this.utilityService.initiateDeposit(true);
        }
        else if (queryParams.tab === "withdrawal") {
            this.utilityService.initiateWithdraw();
        }
        else {
            const activeTab = {
                tabName: queryParams.tab,
                showBackButton: true,
            };
            this.utilityService.openAccountComponent(activeTab);
        }
    }
    onNavigateToLoggedOutQP(queryParams) {
        if (queryParams.tab === "deposit") {
            this.onNavigateToDeposit(queryParams);
        }
        else {
            this.utilityService.initiateLogin();
            let navigationAfterLogin = {
                type: "view",
                path: queryParams.tab,
            };
            this.commonService.onSetNavigateAfterLogin(navigationAfterLogin);
        }
    }
    /*
      Below handler will be called from deep with deposit as tab value and
      navigate to deposit based on user market where he is..
    */
    onNavigateToDeposit(queryParams) {
        if (this.utilityService.isPnpFlow()) {
            this.payNPlayCashierService.onBroadcastInitiateDeposit({
                callingFrom: "deepLink",
            });
        }
        else {
            const activeTab = {
                tabName: "login",
                showBackButton: true,
            };
            this.utilityService.openAccountComponent(activeTab);
            let navigationAfterLogin = {
                type: "view",
                path: queryParams.tab,
            };
            if (queryParams.tab === "deposit" &&
                queryParams.hasOwnProperty("bonusCode")) {
                navigationAfterLogin.data = { bonusCode: queryParams.bonusCode };
            }
            this.commonService.onSetNavigateAfterLogin(navigationAfterLogin);
        }
    }
    onNavigateLobbyGameGroupsQuery(queryParams) {
        let navigationAfterLogin = {
            type: "url",
            data: {
                navigateTo: "lobby",
            },
        };
        navigationAfterLogin.data.lobby = queryParams.lobby;
        if (queryParams.hasOwnProperty("group")) {
            navigationAfterLogin.data.group = queryParams.group;
        }
        if (!this.isLoggedIn) {
            this.utilityService.initiateLogin();
            this.commonService.onSetNavigateAfterLogin(navigationAfterLogin);
        }
        else {
            this.commonService.onBroadcastNavigationData(navigationAfterLogin.data);
            if (navigationAfterLogin.data.group) {
                this.router.navigate([
                    `${this.languageCode}/casino/${queryParams.group}`,
                ]);
            }
        }
    }
    // -----------------------------------------------------------------
    // On Destroy
    ngOnDestroy() {
        if (this.userKycLevelOpenAccountsSubscription)
            this.userKycLevelOpenAccountsSubscription.unsubscribe();
        if (this.kycSubscription)
            this.kycSubscription.unsubscribe();
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
}
DeepLinksService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DeepLinksService_Factory() { return new DeepLinksService(i0.ɵɵinject(i1.PayNPlayCashierService), i0.ɵɵinject(i2.MultiLanguageService), i0.ɵɵinject(i3.TranslationService), i0.ɵɵinject(i4.GamePlayService), i0.ɵɵinject(i5.MixPanelService), i0.ɵɵinject(i6.UtilityService), i0.ɵɵinject(i7.SessionService), i0.ɵɵinject(i8.CashierService), i0.ɵɵinject(i9.CommonService), i0.ɵɵinject(i10.KYCService), i0.ɵɵinject(i11.Store), i0.ɵɵinject(i12.Router)); }, token: DeepLinksService, providedIn: "root" });
