<ng-container *ngIf="bonusDetails">
  <ng-container *ngIf="bonusDetails?.bonusCategory === 'CASHOUT_RESTRICT'">
    <app-cashout-reward
      [bonusDetails]="bonusDetails"
      [rewardSize]="rewardSize"
      [callingFrom]="callingFrom"
      [isRealUser]="isRealUser"
      [selectedBonus]="selectedBonus"
      [currencyCode]="currencyCode"
      (callOpenRewardTCPopUp)="onOpenRewardTCPopUp($event)"
      (callOnBonusSelected)="onSelectBonus($event)"
      (callOnOpenDeposit)="onOpenDepositView($event)"
    ></app-cashout-reward>
  </ng-container>

  <ng-container *ngIf="bonusDetails?.bonusCategory === 'RELEASE_RESTRICT'">
    <app-release-restriction
      [bonusDetails]="bonusDetails"
      [rewardSize]="rewardSize"
      [callingFrom]="callingFrom"
      [isRealUser]="isRealUser"
      [selectedBonus]="selectedBonus"
      [currencyCode]="currencyCode"
      (callOpenRewardTCPopUp)="onOpenRewardTCPopUp($event)"
      (callOnBonusSelected)="onSelectBonus($event)"
      (callOnOpenDeposit)="onOpenDepositView($event)"
    ></app-release-restriction>
  </ng-container>
</ng-container>
