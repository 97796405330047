import { of, throwError } from "rxjs";
import { catchError, map, share, tap } from "rxjs/operators";
// API Interactors
import { ApiInteractors } from "src/app/models/interactors/api.interactor";
// Enums
import { Project } from "src/app/models/environments/project.enum";
// Libraries
import * as _ from "underscore";
// Selectors
import { selectAuthLoginIsLoggedOut } from "src/app/modules/auth/store/selectors/auth.selectors";
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
import * as i2 from "@angular/common/http";
export class PromotionsService {
    constructor(store, httpClient) {
        this.store = store;
        this.httpClient = httpClient;
        // Arrays
        this.promotionList = [];
        this.apiInteractor = new ApiInteractors(this.httpClient);
        this.subscription = this.store
            .select(selectAuthLoginIsLoggedOut)
            .subscribe((isLoggedOut) => {
            if (isLoggedOut) {
                this.promotionList = undefined;
            }
        });
    }
    // -----------------------------------------------------------------
    // Get Methods
    onGetPromotionList(isForce = false) {
        if (!_.isEmpty(this.promotionList) && !isForce) {
            return of(this.promotionList);
        }
        else {
            return this.apiInteractor
                .get(`/ajax/promotion/getPromotions`, null, Project.Highroller)
                .pipe(tap((promotionList) => {
                this.promotionList = promotionList;
            }), map((response) => {
                return response;
            }), catchError((error) => {
                return throwError(error);
            }), share());
        }
    }
    // -----------------------------------------------------------------
    // On Destroy
    ngOnDestroy() {
        if (this.subscription)
            this.subscription.unsubscribe();
    }
}
PromotionsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PromotionsService_Factory() { return new PromotionsService(i0.ɵɵinject(i1.Store), i0.ɵɵinject(i2.HttpClient)); }, token: PromotionsService, providedIn: "root" });
