<app-side-menu-modal
  [customClass]="'allRewards'"
  [sideMenuTitle]="'rewards.rewards'"
>
  <!-- modal body -->
  <ng-container sideMenuBody>
    <ng-container *ngIf="!isLoading">
      <p class="leadtitle m-b-12 text-center">
        {{ "rewards.all_rewards_leadtext" | translate }}
      </p>
    </ng-container>

    <ng-container
      *ngIf="
        !isLoading &&
        filteredCampaignDetailsList &&
        filteredCampaignDetailsList.length > 0
      "
    >
      <div class="reward-cards__container">
        <ng-container
          *ngFor="let bonus of filteredCampaignDetailsList"
        >
          <ng-container [ngSwitch]="bonus.bonusTypeFD">
            <ng-container
              *ngSwitchCase="'cashback-promotion'"
            >
              <app-cashback-promo-reward
                [bonusDetails]="bonus"
                [rewardSize]="'basic'"
                [callingFrom]="'all-rewards'"
                (callOpenRewardTCPopUp)="
                  onOpenRewardTCPopUp($event)
                "
              ></app-cashback-promo-reward>
            </ng-container>

            <ng-container *ngSwitchCase="'freeSpin-bonus'">
              <app-free-spin-reward-card
                [callingFrom]="'all-rewards'"
                [bonusDetails]="bonus"
                (callOpenRewardTCPopUp)="
                  onOpenRewardTCPopUp($event)
                "
              ></app-free-spin-reward-card>
            </ng-container>

            <ng-container *ngSwitchCase="'deposit-bonus'">
              <app-deposit-reward-card
                [callingFrom]="'all-rewards'"
                [rewardSize]="'basic'"
                [bonusDetails]="bonus"
                (callOpenRewardTCPopUp)="
                  onOpenRewardTCPopUp($event)
                "
              ></app-deposit-reward-card>
            </ng-container>

            <ng-container
              *ngSwitchCase="'unclaimedFreeSpin-bonus'"
            >
              <app-unclaimed-free-spin-reward-card
                [bonusDetails]="bonus"
                (callOnLevelupClaimed)="
                  onReloadActiveBonus()
                "
                (callOpenRewardTCPopUp)="
                  onOpenRewardTCPopUp($event)
                "
              ></app-unclaimed-free-spin-reward-card>
            </ng-container>

            <ng-container *ngSwitchCase="'upcoming-bonus'">
              <app-upcoming-reward-card
                [bonusDetails]="bonus"
              >
              </app-upcoming-reward-card>
            </ng-container>
          </ng-container>
        </ng-container>
      </div>
    </ng-container>

    <ng-container *ngIf="isLoading">
      <div class="reward-cards__container isloading">
        <app-loader></app-loader>

        <p>{{ "rewards.loader_text" | translate }}</p>
      </div>
    </ng-container>

    <ng-container
      *ngIf="
        !isLoading &&
        filteredCampaignDetailsList &&
        filteredCampaignDetailsList.length === 0
      "
    >
      <div class="reward-cards__container isloading">
        <p>
          {{ "rewards.rewards_not_config" | translate }}
        </p>
      </div>
    </ng-container>
  </ng-container>
</app-side-menu-modal>
