import { Component, ViewEncapsulation } from "@angular/core";
import { Subscription } from "rxjs";
import { Store } from "@ngrx/store";

// Environments
import { environment } from "src/environments/environment";

// Libraries
import * as $ from "jquery";

// Reducers
import { AppState } from "src/app/store/reducers";

// Selectors
import {
  selectAuthLoginIsLoggedOut,
  selectAuthLoginIsLoggedIn,
} from "src/app/modules/auth/store/selectors/auth.selectors";

// Services
import { NewsTickerService } from "src/app/modules/news/services/news-ticker.service";
import { BannerRequest } from "src/app/modules/banner/models/banner-request.model";
import { Banner } from "src/app/modules/banner/models/banner.model";

@Component({
  selector: "app-news-ticker",
  templateUrl: "./news-ticker.component.html",
  styleUrls: ["./news-ticker.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class NewsTickerComponent {
  // Strings
  zoneId: string = "stickers";
  bannerContent: string = "";

  // Booleans
  isLoggedIn: boolean = false;
  isLoading: boolean = false;

  // Subscriptions
  bannerSubscription: Subscription;

  subscriptions: Subscription[] = [];

  constructor(
    private newsTickerService: NewsTickerService,
    private store: Store<AppState>
  ) {}

  // -----------------------------------------------------------------
  // Lifecycle Hooks
  ngOnInit(): void {
    this.onGetBanners(this.zoneId, true);

    this.subscriptions = [
      this.store
        .select(selectAuthLoginIsLoggedIn)
        .subscribe((isLoggedIn: boolean) => {
          this.isLoggedIn = isLoggedIn;

          if (this.isLoggedIn) {
            this.onGetBanners(this.zoneId, true);
          }
        }),
      this.store
        .select(selectAuthLoginIsLoggedOut)
        .subscribe((isLoggedOut: boolean) => {
          if (isLoggedOut) {
            this.onGetBanners(this.zoneId, true);
          }
        }),
    ];
  }

  // -----------------------------------------------------------------
  // Set Methods
  onGetBanners(zoneId: string, isforce?: boolean): void {
    let bannerRequest: BannerRequest = {
      zoneId: zoneId,
    };

    this.isLoading = true;

    this.bannerSubscription = this.newsTickerService
      .onGetStickerBanners(bannerRequest, isforce)
      .subscribe((bannersList: Banner[]) => {
        this.isLoading = false;

        if (bannersList && bannersList.length > 0) {
          this.newsTickerService.onBroadcastNewIsBannerAvialable(true);

          this.bannerContent = bannersList[0].content.replace(
            new RegExp("{{site_url}}", "g"),
            environment.siteUrl
          );

          setTimeout(() => {
            if (document.getElementById("news-ticker")) {
              document.getElementById("news-ticker").innerHTML =
                this.bannerContent;

              this.onCreateTicket();
            }
          });
        } else {
          this.newsTickerService.onBroadcastNewIsBannerAvialable(false);
        }
      });
  }

  onCreateTicket(): void {
    let newsTickerList: number = $(".ticker-list p").length;

    for (let i: number = 0; i < newsTickerList; i++) {
      $(".ticker-list").clone().prependTo(".newsTicker--list");
    }
  }

  // -----------------------------------------------------------------
  // On Destroy
  ngOnDestroy(): void {
    if (this.bannerSubscription) this.bannerSubscription.unsubscribe();

    this.subscriptions.forEach((subscription: Subscription) =>
      subscription.unsubscribe()
    );
  }
}
