<app-side-menu-modal
  [customClass]="'changePwd'"
  [sideMenuTitle]="'general.my_Account'"
>
  <!-- modal body -->
  <ng-container sideMenuBody>
    <form
      name="profileUpdateForm"
      class="profile-update"
      [formGroup]="profileUpdateForm"
    >
      <div class="profile-input-row">
        <div class="form-group col-50 light-text-input">
          <div class="controls">
            <label for="firstName"
              >{{ "updateprofile.firstname" | translate }}</label
            >

            <input
              type="text"
              id="firstName"
              name="firstName"
              formControlName="firstName"
              autocomplete="off"
              readonly
            />
          </div>
        </div>

        <div class="form-group col-50 light-text-input">
          <div class="controls">
            <label for="lastName"
              >{{ "updateprofile.lastname" | translate }}</label
            >

            <input
              type="text"
              id="lastName"
              name="lastName"
              formControlName="lastName"
              autocomplete="off"
              readonly
            />
          </div>
        </div>
      </div>

      <div class="profile-input-row clearfix">
        <div class="form-group full-width light-text-input">
          <div class="controls">
            <label for="streetAddress"
              >{{ "updateprofile.streetaddres" | translate }}</label
            >

            <input
              type="text"
              id="streetAddress"
              name="streetAddress"
              formControlName="address1"
              autocomplete="off"
              readonly
            />
          </div>
        </div>
      </div>

      <div class="profile-input-row">
        <div class="form-group col-50 light-text-input">
          <div class="controls">
            <label for="city">{{ "updateprofile.city" | translate }}</label>

            <input
              type="text"
              id="city"
              name="city"
              formControlName="city"
              autocomplete="off"
              readonly
              />
          </div>
        </div>

        <div class="form-group col-50 light-text-input">
          <div class="controls">
            <label for="zipCode"
              >{{ "updateprofile.zipcode" | translate }}</label
            >

            <input
              type="text"
              id="zipCode"
              name="zipCode"
              formControlName="zip"
              autocomplete="off"
              readonly
            />
          </div>
        </div>
      </div>

      <div class="profile-input-row">
        <div class="form-group col-50 light-text-input">
          <div class="controls">
            <label for="country"
              >{{ "updateprofile.country" | translate }}</label
            >

            <input
              type="text"
              id="country"
              name="country"
              formControlName="country"
              autocomplete="off"
              readonly
            />
          </div>
        </div>

        <div class="form-group col-50 light-text-input">
          <div class="controls">
            <label for="phoneNumber"
              >{{ "updateprofile.phonenumber" | translate }}</label
            >

            <input
              type="text"
              id="phoneNumber"
              name="phoneNumber"
              formControlName="phone"
              autocomplete="off"
              readonly
            />
          </div>
        </div>
      </div>

      <div class="profile-input-row">
        <div class="form-group col-50 light-text-input">
          <div class="controls">
            <label for="email">{{ "updateprofile.email" | translate }}</label>

            <input
              type="email"
              id="email"
              name="email"
              formControlName="email"
              autocomplete="off"
              readonly
            />
          </div>
        </div>
      </div>
    </form>

    <p class="update-text">{{ "updateprofile.update_text" | translate }}</p>

    <div
      class="btn btn-primary btn-md full-width m-t-16 btn-round"
      (click)="onInitializeLiveChat()"
    >
      {{ "updateprofile.contact_support" | translate }}
    </div>
  </ng-container>
</app-side-menu-modal>
