<ng-container *ngIf="!isQuickDeposit">
  <ng-container
    *ngTemplateOutlet="quickDepositTemplate"
  ></ng-container>
</ng-container>

<!-- 
  ------------------------------------------------------
  
  Templates 
  
  ------------------------------------------------------
-->
<!-- Quick Deposit Template -->
<ng-template #quickDepositTemplate>
  <nav
    id="bottomNavigation"
    class="bottom-sticky-nav fade-in"
    [class.atGamePlay]="isGamePlay"
    [ngClass]="isLoggedIn ? 'isLoggedIn' : 'isNotLoggedIn'"
  >
    <div class="bottom-sticky-nav__inner">
      <ng-container *ngIf="!isLoggedIn">
        <ng-container
          *ngTemplateOutlet="minimizeGameTemplate"
        ></ng-container>

        <ng-container
          *ngTemplateOutlet="loginTemplate"
        ></ng-container>

        <ng-container
          *ngTemplateOutlet="openRegistrationTemplate"
        ></ng-container>

        <ng-container
          *ngTemplateOutlet="openDepositTemplate"
        ></ng-container>

        <ng-container
          *ngTemplateOutlet="openMenuListTemplate"
        ></ng-container>
      </ng-container>

      <ng-container *ngIf="isLoggedIn">
        <div
          class="bottom-sticky-nav__left p-l-16"
          [ngClass]="isGamePlay ? 'atGamePlay' : 'atHome'"
        >
          <ng-container *ngIf="!isGamePlay">
            <button
              aria-label="Deposit"
              class="btn-deposit fade-in-up full-width"
              (click)="onOpenDeposit()"
            >
              <i
                class="icon-circle btn-primary icon-plus m-r-8"
              ></i>

              <ng-container *ngIf="!isBalanceLoading">
                <span class="text">
                  {{
                    userTotalBalance
                      | currencyformat: currencySymbol
                  }}
                </span>
              </ng-container>

              <ng-container *ngIf="isBalanceLoading">
                <span class="text skeleton-loader"></span>
              </ng-container>
            </button>
          </ng-container>

          <ng-container *ngIf="isGamePlay">
            <button
              id="openQuickDeposit"
              class="btn btn-primary btn-md btn-round fade-in-up full-width isPortrait"
              aria-label="Deposit"
              (click)="onOpenQuickDeposit()"
            >
              {{ "general.deposit" | translate }}
            </button>

            <button
              id="openQuickDeposit"
              class="btn btn-deposit btn-round isLandscape"
              aria-label="Quick Deposit"
              (click)="onOpenQuickDeposit()"
            >
              <i
                class="icon-circle btn-primary icon-plus"
              ></i>

              <span class="img">
                <img src="/assets/img/cc.png" alt="visa" />
              </span>
            </button>
          </ng-container>
        </div>

        <div
          class="bottom-sticky-nav__right"
          [ngClass]="isGamePlay ? 'atGamePlay' : 'atHome'"
        >
          <ng-container *ngIf="isGamePlay && isLoggedIn">
            <button
              class="btn-sticky fade-in-up icon-btn"
              aria-label="Home"
              (click)="onMakeMinimizedGame()"
            >
              <i class="icon-home"></i>
            </button>
          </ng-container>

          <button
            class="btn-sticky btn-menu btn-burger-menu icon-btn"
            aria-label="burger-menu button"
            (click)="onOpenAccountMenuList()"
          >
            <i class="icon-menu"></i>
          </button>
        </div>

        <ng-container
          *ngTemplateOutlet="rewardsIndicatorTemplate"
        ></ng-container>
      </ng-container>
    </div>
  </nav>
</ng-template>

<!-- Minimize Game Template -->
<ng-template #minimizeGameTemplate>
  <ng-container *ngIf="activePage === 'game'">
    <div
      class="btn-sticky float-left btn-bottom-nav"
      [class.devider]="activePage === 'game'"
    >
      <button
        class="fade-in-up btn-sticky icon-btn"
        aria-label="Home"
        (click)="onMakeMinimizedGame()"
      >
        <i class="icon-home"></i>
      </button>
    </div>
  </ng-container>
</ng-template>

<!-- Login Template -->
<ng-template #loginTemplate>
  <button
    class="btn btn-sm btn-login-game btn-round btn-bottom-nav devider"
    aria-label="Login"
    (click)="onOpenLogin()"
  >
    {{ "general.login" | translate }}
  </button>
</ng-template>

<!-- Open Registration Template -->
<ng-template #openRegistrationTemplate>
  <ng-container *ngIf="!isLoggedIn">
    <button
      class="btn btn-round btn-sm btn-bottom-nav devider"
      *marketBasedComponentDisplay="{
        flowType: UserFlowTypes.NORMAL_FLOW,
        langCode: languageCode
      }"
      aria-label="Join now"
      (click)="onOpenRegistrationPopup()"
    >
      {{ "general.joinnow" | translate }}
    </button>
  </ng-container>
</ng-template>

<!-- Open Deposit Template -->
<ng-template #openDepositTemplate>
  <button
    class="btn btn-round btn-sm btn-bottom-nav devider"
    *marketBasedComponentDisplay="{
      flowType: UserFlowTypes.ZIMPLER_FLOW,
      langCode: languageCode
    }"
    aria-label="Join now"
    (click)="onOpenDeposit()"
  >
    {{ "general.deposit" | translate }}
  </button>
</ng-template>

<!-- Open Menu List Template -->
<ng-template #openMenuListTemplate>
  <button
    class="btn-sticky btn-menu btn-burger-menu btn-bottom-nav devider icon-btn"
    aria-label="Menu"
    (click)="onOpenAccountMenuList()"
  >
    <i class="icon-menu"></i>
  </button>
</ng-template>

<!-- Rewards Indicator Template -->
<ng-template #rewardsIndicatorTemplate>
  <ng-container *ngIf="isLoggedIn">
    <div class="levels">
      <app-rewards-indicator></app-rewards-indicator>
    </div>
  </ng-container>
</ng-template>

<!-- 
  ------------------------------------------------------
  
  Deposit: Templates 
  
  ------------------------------------------------------
-->
<ng-template #noDepositTemplate>
  
</ng-template>
