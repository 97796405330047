import { Injectable } from "@angular/core";

// Configurations
import { localStorageKeys } from "src/app/modules/multi-languages/configurations/localstorage-keys.configurations";
import { mixPanelEventsConfigurations } from "src/app/configurations/main.configurations";

// Environments
import { environment } from "src/environments/environment";

// Libraries
import mixpanel from "mixpanel-browser";

// Models
import { GameDataTrackGameLaunch } from "src/app/modules/shared/models/mix-panel/game-data-track-game-launch.model";
import { TrackGameEvent } from "src/app/modules/shared/models/mix-panel/track-game-event.model";
import { GamePregmatic } from "src/app/modules/game-groups/models/game.model";

@Injectable({
  providedIn: "root",
})
export class MixPanelService {
  constructor() {
    this.onInitiateMixPanel();
  }

  // -----------------------------------------------------------------
  // Get Methods
  getWindowType(): "desktop" | "mobile" {
    let clientWidth: number = document.body.clientWidth;

    if (clientWidth >= 1024) {
      return "desktop";
    } else {
      return "mobile";
    }
  }

  getVerticalByGameData(
    gameLanuchData: GameDataTrackGameLaunch
  ): "Live-casino" | "Casino" {
    let liveGameTypes: string[] = ["live_dealer", "live_casino"];

    return liveGameTypes.indexOf(
      (gameLanuchData.gameData as GamePregmatic).gameType
    ) > -1
      ? "Live-casino"
      : "Casino";
  }

  getVerticalByUrl(): string {
    let locationPath: string = window.location.pathname;

    let verticalValues: string = "";

    if (locationPath.includes("/casino")) {
      verticalValues = "Casino";
    } else if (locationPath.includes("/live-casino")) {
      verticalValues = "Live-casino";
    } else if (locationPath.includes("/studio")) {
      verticalValues = "Studio";
    }

    return verticalValues;
  }

  // -----------------------------------------------------------------
  // Set Methods
  onInitiateMixPanel(): void {
    mixpanel.init(environment.mixpanelProjectToken, {
      debug: true,
    });
  }

  onTrackMixPanelEvents(
    eventName: string,
    eventProperties: TrackGameEvent
  ): void {
    eventProperties.platfromType = this.getWindowType();

    if (localStorage.getItem(localStorageKeys.stz_user)) {
      mixpanel.track(eventName, eventProperties);
    }
  }

  onTrackGameLaunchEvent(gameLanuchData: GameDataTrackGameLaunch): void {
    let eventProperties: TrackGameEvent = {
      name: gameLanuchData.gameData.name,
      playType: gameLanuchData.gameType,
      vertical: this.getVerticalByGameData(gameLanuchData),
      category: "no category",
    };

    if (gameLanuchData.groupName) {
      eventProperties.location = gameLanuchData.groupName;
    }

    if (gameLanuchData.lobbyName) {
      eventProperties.category = gameLanuchData.lobbyName;
    }

    if (gameLanuchData.callingFrom === "game-window-tag") {
      eventProperties.location = "In-Game";
      eventProperties.Tag = gameLanuchData.activeTag;
    }

    if (gameLanuchData.callingFrom === "recent-search") {
      eventProperties.location = "Search";
    }

    if (gameLanuchData.callingFrom === "studios") {
      eventProperties.location = "Game Studios";
    }

    this.onTrackMixPanelEvents(
      mixPanelEventsConfigurations.launched_game,
      eventProperties
    );
  }
}
