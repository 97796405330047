<div
  class="game-group__container"
  [ngClass]="{'continue-games':(gameGroupData && gameGroupData.group_type === 'automatic'
          && gameGroupData.group_sub_type === 'continue_playing'),
          'favourite-games':(gameGroupData && gameGroupData.group_type === 'automatic'
          && gameGroupData.group_sub_type === 'favourite')}"
>
  <div class="game-group--title">
    <span
      class="gameGroupName-link"
      (click)="onNavigateAllGamesPage()"
    >
      {{ gameGroupData.name }}
    </span>
    <span
      class="viewall-link"
      (click)="onNavigateAllGamesPage()"
    >
      {{ 'gamegroup.view_all' | translate }}
    </span>
  </div>

  <div class="swiper__outer hoverspace posterHover">
    <div class="swiper__inner swiper__full">
      <swiper
        class="mySwiper swiper-control"
        [config]="swiperPoster"
        (slidePrevTransitionEnd)="onSwiperPrevEvent()"
        (slideNextTransitionEnd)="onSwiperNextEvent()"
        watchSlidesProgress
      >
        <div
          class="game-card poster x2 livecasino-card"
          *ngFor="let game of gamesList | slice:0:swiperLazyConfigurations.indexNumberOfGameTilesToshow; let i = index"
        >
          <div class="game-card-inner">
            <div class="livecasino-card__inner">
              <app-game-card
                [gameData]="game"
                [gameGroupName]="gameGroupData.name"
                [game_asset_style]="gameGroupData.game_asset_style"
                [lobbyName]="callingFromlobby"
                [callingFrom]="'poster-live-casino'"
              ></app-game-card>

              <div class="livecasino-card__vendor-logo">
                <img
                  src="{{mediaUrlPath}}/poster-vendor/{{game.vendorCode}}.png{{imgixParams}}"
                  alt="{{game.vendorDisplayName}}"
                  title="{{game.vendorDisplayName}}"
                />
              </div>
            </div>

            <div class="game-card__footer">
              <div
                class="game-title livecasino-card--game-title truncate-text"
              >
                {{game.name}}
              </div>
            </div>
          </div>
        </div>
      </swiper>
    </div>
  </div>
</div>
