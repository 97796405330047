import { Component, HostListener } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Subscription } from "rxjs";

// Components
import { SharedTransactionComponent } from "src/app/modules/transactions/components/shared-transaction/shared-transaction.component";

// Models
import { BetTransactionsResponse } from "src/app/modules/transactions/models/bet-transactions-response.model";
import { BetTransactionsRequest } from "src/app/modules/transactions/models/bet-transactions-request.model";

// Services
import { TranslationService } from "src/app/modules/multi-languages/services/translation.service";
import { TransactionsService } from "src/app/modules/transactions/services/transactions.service";
import { UtilityService } from "src/app/modules/shared/services/utility.service";

@Component({
  selector: "app-bet-history",
  templateUrl: "./bet-history.component.html",
  styleUrls: ["./bet-history.component.scss"],
})
export class BetHistoryComponent extends SharedTransactionComponent {
  // Strings
  titleText: string = "Game History";
  activeStep: string = "step1";
  period: string = "";

  // Booleans
  isButtonLoader: boolean = false;
  isLoading: boolean = false;

  // Enums
  windowType: "device" | "mobile" = "device";

  // Arrays
  betTransactionsList: BetTransactionsResponse[] = [];

  // Objects
  betTransactionsRequest: BetTransactionsRequest = {
    size: 1000,
    index: 0,
    interval: {
      start: new Date().setHours(0, 0, 0, 0),
      end: new Date().getTime(),
    },
    type: "casino",
    productId: ["CASINO"],
  };

  // Subscriptions
  subscription: Subscription;

  constructor(
    private transactionsService: TransactionsService,
    private translationService: TranslationService,
    public utilityService: UtilityService,
    public formBuilder: FormBuilder
  ) {
    super(utilityService, formBuilder);
  }

  // -----------------------------------------------------------------
  // Lifecycle Hooks
  ngOnInit(): void {
    this.titleText = this.translationService.get("bettransaction.game_history");

    this.getWindowType();
  }

  // -----------------------------------------------------------------
  // Host Listeners
  @HostListener("window:resize")
  onResize(): void {
    this.getWindowType();
  }

  // -----------------------------------------------------------------
  // Window Type
  getWindowType(): void {
    let clientWidth: number = document.body.clientWidth;

    if (clientWidth <= 1024) {
      this.windowType = "mobile";
    } else {
      this.windowType = "device";
    }
  }

  // -----------------------------------------------------------------
  // Get Methods
  /*
    Below function created a request object which we need to pass
    backend based on applied filter last 24h last 7d or selected
    date.
  */
  getBetTransactionsRequest(): BetTransactionsRequest {
    let currentDate: Date = new Date();

    if (this.period === "last-24h") {
      this.betTransactionsRequest.interval = {
        start: currentDate.setHours(currentDate.getHours() - 24),
        end: new Date().getTime(),
      };
    } else if (this.period === "last-7d") {
      this.betTransactionsRequest.interval = {
        start: new Date().setDate(currentDate.getDate() - 7),
        end: new Date().getTime(),
      };
    } else if (this.period === "last-30d") {
      this.betTransactionsRequest.interval = {
        start: new Date().setDate(currentDate.getDate() - 30),
        end: new Date().getTime(),
      };
    } else if (this.period === "select-date") {
      this.betTransactionsRequest.interval = {
        start: this.txnDetailsFrom.get("startDate").value,
        end: this.txnDetailsFrom.get("endDate").value.setHours(23, 59, 59, 999),
      };
    }

    this.betTransactionsRequest.interval.start = Math.floor(
      this.betTransactionsRequest.interval.start / 1000
    );

    this.betTransactionsRequest.interval.end = Math.floor(
      this.betTransactionsRequest.interval.end / 1000
    );

    return this.betTransactionsRequest;
  }

  // -----------------------------------------------------------------
  // Set Methods
  onFetchBetHistory(period: string): void {
    this.period = period;

    if (this.period === "last-24h") {
      this.titleText = this.translationService.get(
        "bettransaction.last_24_hours"
      );

      this.onGetBetTransaction();

      this.activeStep = "step2";
    } else if (this.period === "last-7d") {
      this.titleText = this.translationService.get(
        "bettransaction.last_7_days"
      );

      this.onGetBetTransaction();

      this.activeStep = "step2";
    } else if (this.period === "last-30d") {
      this.titleText = this.translationService.get(
        "bettransaction.last_30_days"
      );

      this.onGetBetTransaction();

      this.activeStep = "step2";
    } else if (this.period === "select-date") {
      this.titleText = this.translationService.get("bettransaction.date_range");

      this.activeStep = "step3";
    }
  }

  onGoBack(): void {
    this.titleText = this.translationService.get(
      "bettransaction.last_24_hours"
    );

    if (this.activeStep === "step3") {
      this.onCloseComponent();
    } else if (this.activeStep === "step1") {
      this.utilityService.closeAccountComponent("menuOptions");
    } else if (this.activeStep === "step2") {
      this.activeStep = "step1";

      this.titleText = this.translationService.get(
        "bettransaction.game_history"
      );
    } else {
      this.activeStep = "step1";
    }

    this.betTransactionsList = undefined;
  }

  onGetBetTransaction(): void {
    this.isButtonLoader = true;

    this.betTransactionsList = undefined;

    this.isLoading = true;

    let betTransactionsRequest: BetTransactionsRequest =
      this.getBetTransactionsRequest();

    this.subscription = this.transactionsService
      .onGetBetTransactions(betTransactionsRequest)
      .subscribe((betTransactionsList: BetTransactionsResponse[]) => {
        this.isButtonLoader = false;

        this.isLoading = false;

        if (betTransactionsList && betTransactionsList.length > 0) {
          this.betTransactionsList = betTransactionsList;
        } else {
          this.betTransactionsList = [];
        }
      });
  }

  onCloseComponent(navigateTo?: string): void {
    this.titleText = this.translationService.get("bettransaction.game_history");

    this.activeStep = "step1";

    this.betTransactionsList = undefined;

    this.utilityService.closeAccountComponent(navigateTo);
  }

  // -----------------------------------------------------------------
  // On Destroy
  ngOnDestroy(): void {
    this.titleText = this.translationService.get("bettransaction.game_history");

    this.activeStep = "step1";

    this.betTransactionsList = undefined;

    if (this.subscription) this.subscription.unsubscribe();
  }
}
