import { Observable, Subscription } from "rxjs";
import { Router } from "@angular/router";
import { map } from "rxjs/operators";
import { Store } from "@ngrx/store";
import {
  AfterViewInit,
  HostListener,
  ElementRef,
  Component,
  ViewChild,
} from "@angular/core";

// Configurations
import { marketLocaleCurrencyMappingConfigurations } from "src/app/configurations/main.configurations";

// Environments
import { environment } from "src/environments/environment";

// Libraries
import * as _ from "underscore";

// Models
import { TotalJackpotFeed } from "src/app/modules/game-groups/models/jackpot/total-jackpot-feed.model";
import { QueryParams } from "src/app/modules/shared/models/parameters.model";

// Pipes
import { CurrencyFormatPipe } from "src/app/modules/shared/pipes/currency-format.pipe";

// Reducers
import { AppState } from "src/app/store/reducers";

// Selectors
import { selectLanguageCode } from "src/app/modules/multi-languages/store/selectors/languages.selectors";
import {
  selectAuthLoginIsLoggedOut,
  selectAuthLoginIsLoggedIn,
} from "src/app/modules/auth/store/selectors/auth.selectors";

// Services
import { MultiLanguageService } from "src/app/modules/multi-languages/services/multi-language.service";
import { TranslationService } from "src/app/modules/multi-languages/services/translation.service";
import { RegistrationService } from "src/app/modules/registration/services/registration.service";
import { UserDetailsService } from "src/app/modules/user/services/user-details.service";
import { AffiliateService } from "src/app/modules/auth/services/affiliate.service";
import { UtilityService } from "src/app/modules/shared/services/utility.service";
import { SessionService } from "src/app/modules/auth/services/session.service";
import { MainService } from "src/app/modules/shared/services/main.service";

// Utilities
import { supportedMarketsList } from "src/app/modules/multi-languages/utilities/languages.utilities";

@Component({
  selector: "app-landing-page",
  templateUrl: "./landing-page.component.html",
  styleUrls: ["./landing-page.component.scss"],
})
export class LandingPageComponent implements AfterViewInit {
  // View Child
  @ViewChild("observerElementHeight", { static: false })
  observerElementHeight: ElementRef;

  // Numbers
  affixElementPosition?: number = undefined;
  footerHeight?: number = undefined;
  visibilityLimit: number = 0;

  // Strings
  mediaUrlPathHir: string = environment.mediaUrlPathSTZ;
  mediaUrlPath: string = environment.mediaUrlPath;
  rewardSpecificationDeviceType: string = "";
  apiUrlPath: string = environment.apiUrl;
  affiliateOfferJsonText: string = "";
  jackpotTotalAmount: string = "";
  userLanguageCode: string = "";
  userAffiliateId: string = "";
  bonusRewardsBg: string = "";
  currencySymbol: string = "";
  languageCode: string = "";
  imgixParams: string = "";

  // Booleans
  isScrollCheckEnabled: boolean = false;
  isHungarianStyle: boolean = false;
  isJackpotImage: boolean = false;
  isJapanRegion: boolean = false;
  isAffiliate: boolean = false;
  isLoggedIn: boolean = false;
  isScrolled: boolean = false;
  isFixedBar: boolean = true;

  // Enums
  deviceType: "desktop" | "mobile" = "desktop";

  // Subscriptions
  subscriptions: Subscription[] = [];

  constructor(
    private multiLanguageService: MultiLanguageService,
    private registrationService: RegistrationService,
    private userDetailsService: UserDetailsService,
    private translationService: TranslationService,
    private currencyFormatPipe: CurrencyFormatPipe,
    private affiliateService: AffiliateService,
    private utilityService: UtilityService,
    private sessionService: SessionService,
    private mainService: MainService,
    private store: Store<AppState>,
    private router: Router
  ) {
    this.onLoad();
  }

  // -----------------------------------------------------------------
  // Lifecycle Hooks
  ngOnInit(): void {
    this.getWindowType();

    this.onScrollEnabled();

    this.isLoggedIn = this.sessionService.getIsUserLoggedIn();

    this.imgixParams = this.utilityService.getImgixParams();

    if (this.languageCode === "hu-hu") {
      this.isHungarianStyle = true;
    }
  }

  ngAfterViewInit(): void {
    this.affixElementPosition =
      this.observerElementHeight.nativeElement.offsetTop - window.innerHeight;

    this.onUpdateSEOContent();

    this.onSetAffiliateCookie();
  }

  // -----------------------------------------------------------------
  // Host Listeners
  @HostListener("window:resize") onResize(): void {
    this.onScrollEnabled();

    this.getWindowType();
  }

  @HostListener("window:scroll") onScroll(): void {
    if (this.isScrollCheckEnabled && window.scrollY >= 250) {
      this.isScrolled = true;
    } else {
      this.isScrolled = false;
    }

    this.onLoadOnScroll();
  }

  // -----------------------------------------------------------------
  // Window Type
  getWindowType(): void {
    this.rewardSpecificationDeviceType = undefined;

    let clientWidth: number = document.body.clientWidth;

    if (clientWidth >= 1023) {
      this.deviceType = "desktop";
    } else {
      this.deviceType = "mobile";
    }

    if (
      (clientWidth >= 481 &&
        clientWidth < 1023 &&
        window.matchMedia("(orientation: landscape)").matches) ||
      (clientWidth >= 768 &&
        clientWidth < 1023 &&
        window.matchMedia("(orientation: portrait)").matches)
    ) {
      this.rewardSpecificationDeviceType = "mobile_landscape";
    }

    this.onSetCountryRegionBackground();
  }

  // -----------------------------------------------------------------
  // Get Methods
  getJackpotsTotalAmount(): Observable<TotalJackpotFeed> {
    return this.mainService
      .onGetMicroGamingTotalJackpotsAmount(
        this.userDetailsService.getCurrencyCode()
      )
      .pipe(
        map((jackpotFeed: TotalJackpotFeed) => {
          return jackpotFeed;
        })
      );
  }

  // -----------------------------------------------------------------
  // Set Methods
  onLoad(): void {
    /* 
      NB: leave language code here inside contructor since
      user will recall language code whenever clicking on 
      the brand image
    */
    this.languageCode = this.multiLanguageService.getLanguageCode();

    this.subscriptions = [
      this.store
        .select(selectAuthLoginIsLoggedIn)
        .subscribe((isLoggedIn: boolean) => (this.isLoggedIn = isLoggedIn)),
      this.store
        .select(selectAuthLoginIsLoggedOut)
        .subscribe((isLoggedOut: boolean) => {
          if (isLoggedOut) {
            this.isLoggedIn = false;
          }
        }),
      this.userDetailsService.currencyCodeBehaviourSubject$.subscribe(() => {
        this.onGetJackpotTotal();
      }),
      this.store
        .select(selectLanguageCode)
        .subscribe((languageCode: string) => {
          this.languageCode = languageCode;

          this.onUpdateSEOContent();

          this.onSetCountryRegionBackground();

          this.onSetAffiliateCookie();

          if (this.languageCode === "hu-hu") {
            this.isHungarianStyle = true;
          }
        }),
      this.userDetailsService.currencySymbolBehaviourSubject$.subscribe(
        (currencySymbol: string) => {
          this.currencySymbol = currencySymbol;
        }
      ),
    ];
  }

  onUpdateSEOContent(): void {
    this.utilityService.setSEO({
      metaTitle: this.translationService.get("SEO.landingPage_metaTitle"),
      metaDescription: this.translationService.get(
        "SEO.landingPage_metaDescription"
      ),
      metaTags: this.translationService.get("SEO.landingPage_metaTag"),
      title: this.translationService.get("SEO.landingPage_title"),
    });

    this.multiLanguageService.onSetCanonicalURL("landingpage");
  }

  onGetJackpotTotal(): void {
    this.getJackpotsTotalAmount().subscribe((jackpotfeed: TotalJackpotFeed) => {
      if (
        jackpotfeed.currencyIsoCode ==
        marketLocaleCurrencyMappingConfigurations[this.languageCode]
          .currencyCode
      ) {
        if (jackpotfeed && jackpotfeed.jackpotsTotal) {
          this.jackpotTotalAmount = this.currencyFormatPipe.transform(
            jackpotfeed.jackpotsTotal
              .toFixed(2)
              .replace(/(\d)(?=(\d{3})+\.)/g, "$1,"),
            this.currencySymbol,
            false
          );
        }
      }
    });
  }

  onOpenRegistrationPopup(): void {
    if (this.utilityService.isPnpFlow()) {
      this.utilityService.initiateDeposit(true);
    } else {
      this.registrationService.onOpenRegistration();
    }
  }

  onScrollEnabled(): void {
    let clientWidth: number = document.body.clientWidth;

    if (clientWidth >= 1024) {
      this.isScrollCheckEnabled = true;
    } else {
      this.isScrollCheckEnabled = false;
    }
  }

  onLoadOnScroll(): void {
    const footerContainerDom: HTMLCollectionOf<Element> =
      document.getElementsByClassName("footer-conatiner");

    if (this.footerHeight === undefined) {
      this.footerHeight = footerContainerDom[0].clientHeight + 150;
    }

    if (
      window.scrollY + window.outerHeight >=
        document.body.scrollHeight - this.footerHeight &&
      this.visibilityLimit <= 5
    ) {
      this.visibilityLimit = this.visibilityLimit + 1;
    }

    this.affixElementPosition =
      this.observerElementHeight.nativeElement.offsetTop - window.innerHeight;

    const windowScroll: number = window.pageYOffset;

    if (windowScroll >= this.affixElementPosition) {
      this.isFixedBar = false;
    } else {
      this.isFixedBar = true;
    }
  }

  onSetCountryRegionBackground(): void {
    let rewardURL: string = this.rewardSpecificationDeviceType
      ? "desktop"
      : this.deviceType;

    this.bonusRewardsBg = `${this.apiUrlPath}/uploads/media/CAF/LP/HRL/h-rewards-${rewardURL}_${this.languageCode}.png`;
  }

  onSetAffiliateCookie(): void {
    const queryParams: QueryParams = this.utilityService.getQueryParams();

    let userAffilateValue: string = queryParams.affid;

    if (!this.isLoggedIn && userAffilateValue) {
      let affiliateValuesList: string[] = this.translationService
        .get("affiliateids.affiliateValue")
        .split(",");

      if (
        affiliateValuesList &&
        affiliateValuesList.indexOf(userAffilateValue.split("_")[0]) > -1
      ) {
        let languageCodeFromURL: string = this.utilityService
          .getDecodedCurrentPath()
          .split("/")[1];
        if (
          languageCodeFromURL &&
          _.contains(supportedMarketsList(), languageCodeFromURL)
        ) {
          this.userLanguageCode = languageCodeFromURL;
        }

        this.affiliateService.onSetAffiliateCookie(
          userAffilateValue,
          this.userLanguageCode
        );
      }
    }

    this.onGetUserAffiliateValue();
  }

  onGetUserAffiliateValue(): void {
    let affilateLanguage: string =
      this.affiliateService.getAffiliateCookies("affiliateLang");

    let affiliateValuesList: string[] = this.translationService
      .get("affiliateids.affiliateValue")
      .split(",");

    if (this.affiliateService.getAffiliateCookies("affId")) {
      this.userAffiliateId = this.affiliateService
        .getAffiliateCookies("affId")
        .split("_")[0];
    }

    if (
      this.userAffiliateId &&
      affilateLanguage &&
      affiliateValuesList &&
      affiliateValuesList.indexOf(this.userAffiliateId) > -1 &&
      affilateLanguage === this.languageCode
    ) {
      this.isAffiliate = true;

      this.onRenderAffiliteJsonOfferTextAsHtml();
    } else {
      this.isAffiliate = false;
    }
  }

  onRenderAffiliteJsonOfferTextAsHtml(): void {
    this.affiliateOfferJsonText = this.translationService.get(
      `affiliate.${this.userAffiliateId}_offer`
    );
  }

  onGoToAllProvidersPage(): void {
    this.router.navigate([
      `${this.languageCode}/casino/${this.translationService.get(
        "url.allProvider-page"
      )}`,
    ]);
  }

  // -----------------------------------------------------------------
  // On Destroy
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) =>
      subscription.unsubscribe()
    );
  }
}
