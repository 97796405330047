/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./reward-bonus-terms-conditions.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../shared/components/loader/loader.component.ngfactory";
import * as i3 from "../../../shared/components/loader/loader.component";
import * as i4 from "@angular/common";
import * as i5 from "./reward-bonus-terms-conditions.component";
import * as i6 from "../../../static-pages/services/static-page.service";
var styles_RewardBonusTermsConditionsComponent = [i0.styles];
var RenderType_RewardBonusTermsConditionsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RewardBonusTermsConditionsComponent, data: {} });
export { RenderType_RewardBonusTermsConditionsComponent as RenderType_RewardBonusTermsConditionsComponent };
function View_RewardBonusTermsConditionsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-loader", [], null, null, null, i2.View_LoaderComponent_0, i2.RenderType_LoaderComponent)), i1.ɵdid(2, 49152, null, 0, i3.LoaderComponent, [], null, null)], null, null); }
function View_RewardBonusTermsConditionsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Content not configured please check with customer support!"]))], null, null); }
export function View_RewardBonusTermsConditionsComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { element: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RewardBonusTermsConditionsComponent_1)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RewardBonusTermsConditionsComponent_2)), i1.ɵdid(4, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, [[1, 0], ["dynamicContent", 1]], null, 0, "div", [], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isLoading; _ck(_v, 2, 0, currVal_0); var currVal_1 = (!_co.isPageAvailable && !_co.isLoading); _ck(_v, 4, 0, currVal_1); }, null); }
export function View_RewardBonusTermsConditionsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-reward-bonus-terms-conditions", [], null, null, null, View_RewardBonusTermsConditionsComponent_0, RenderType_RewardBonusTermsConditionsComponent)), i1.ɵdid(1, 704512, null, 0, i5.RewardBonusTermsConditionsComponent, [i6.StaticPageService], null, null)], null, null); }
var RewardBonusTermsConditionsComponentNgFactory = i1.ɵccf("app-reward-bonus-terms-conditions", i5.RewardBonusTermsConditionsComponent, View_RewardBonusTermsConditionsComponent_Host_0, { urlpath: "urlpath" }, {}, []);
export { RewardBonusTermsConditionsComponentNgFactory as RewardBonusTermsConditionsComponentNgFactory };
