import { throwError, of } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { Subject } from "rxjs/Subject";
// API Interactors
import { ApiInteractors } from "src/app/models/interactors/api.interactor";
import { Project } from "src/app/models/environments/project.enum";
// Libraries
import * as _ from "underscore";
import { EligibleBonusAmountType, EligibleBonusCategory, } from "src/app/modules/rewards/models/eligible-bonus.model";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class RewardsService {
    constructor(httpClient) {
        this.httpClient = httpClient;
        // Numbers
        this.levelBeforeLevelUpgradedNumber = 0;
        this.userCurrentLevelId = 0;
        // --------------------------------------------------------
        // Subject - User Level Up Details
        this.userLevelUpDetailsSubject = new Subject();
        this.userLevelUpDetailsSubject$ = this.userLevelUpDetailsSubject.asObservable();
        // --------------------------------------------------------
        // Subject - Update Level Up Notification
        this.updateLevelUpNotificationSubject = new Subject();
        this.updateLevelUpNotificationSubject$ = this.updateLevelUpNotificationSubject.asObservable();
        // --------------------------------------------------------
        // Subject - is Clear Level Up Notification
        this.isClearLevelUpNotificationSubject = new Subject();
        this.isClearLevelUpNotificationSubject$ = this.isClearLevelUpNotificationSubject.asObservable();
        // --------------------------------------------------------
        // Subject - User Level Up Point Details
        this.userLevelUpPointDetailsSubject = new Subject();
        this.userLevelUpPointDetailsSubject$ = this.userLevelUpPointDetailsSubject.asObservable();
        this.apiInteractor = new ApiInteractors(this.httpClient);
    }
    // -----------------------------------------------------------------
    // Get Methods
    getNotificationCount(currentLevelNumber) {
        if (this.levelBeforeLevelUpgradedNumber && currentLevelNumber) {
            return currentLevelNumber - this.levelBeforeLevelUpgradedNumber;
        }
        else {
            return 0;
        }
    }
    getUserCurrentLevelUpDetails() {
        return this.userCurrentLevelUpDetails;
    }
    getUserCurrentLevelId() {
        return this.userCurrentLevelId;
    }
    /*
      Here we process our eligible bonus based on
      1.bonuscode available or not
      2.user currencyTier avilable or not &
      3. also we disable cashout bonus of amountType PCT for pnp flow
    */
    getProcessEligibleBonus(eligibleBonusItemList, currencyCode, isPnpFlow) {
        let depositBonusDetailsList = _.filter(eligibleBonusItemList, {
            criteriaType: "DEPOSIT",
        });
        let filteredBonusList = this.getFilterEligibleBonusBasedOnFlow(depositBonusDetailsList, currencyCode, isPnpFlow);
        return _.sortBy(filteredBonusList, (eligibleBonusItem) => {
            return eligibleBonusItem.campaignStartDate;
        }).reverse();
    }
    getFilterEligibleBonusBasedOnFlow(filteredBonus, currencyCode, isPnpFlow) {
        let finalFilteredBonusList = _.filter(filteredBonus, (eligibleBonusItem) => {
            if (eligibleBonusItem.playerTargetType !== "LEVELUP" &&
                eligibleBonusItem.currencyTier &&
                eligibleBonusItem.currencyTier[currencyCode] &&
                eligibleBonusItem.currencyTier[currencyCode].length > 0 &&
                eligibleBonusItem.bonusCode &&
                (eligibleBonusItem.bonusCategory ===
                    EligibleBonusCategory.RELEASE_RESTRICT ||
                    !isPnpFlow ||
                    (isPnpFlow &&
                        eligibleBonusItem.bonusCategory ===
                            EligibleBonusCategory.CASHOUT_RESTRICT &&
                        eligibleBonusItem.amountType === EligibleBonusAmountType.ABS))) {
                eligibleBonusItem.bonusTypeFD = "deposit-bonus";
                return eligibleBonusItem;
            }
        });
        return finalFilteredBonusList;
    }
    // -----------------------------------------------------------------
    // Get Observables - Get User Level Up Details
    onGetUserLevelUpDetails() {
        return this.apiInteractor
            .get(`/ajax/loyality/getUserLevelupDetails`, null, Project.Shotz)
            .pipe(map((userLevelUpDetails) => {
            if (userLevelUpDetails && userLevelUpDetails.levelId) {
                this.onSetUserCurrentLevelId(userLevelUpDetails.levelId);
            }
            if (userLevelUpDetails) {
                this.onSetUserLevelUpPointDetails({
                    currentSpinCount: userLevelUpDetails.currentSpinCount,
                    spinsNeededForNextLevel: userLevelUpDetails.spinsNeededForNextLevel,
                    levelId: userLevelUpDetails.levelId,
                });
            }
            return userLevelUpDetails;
        }), catchError(() => {
            return of(undefined);
        }));
    }
    // -----------------------------------------------------------------
    // Get Observables - Get User Level Up Details
    onGetClaimLevelUpBonus(request) {
        return this.apiInteractor
            .post(`/ajax/loyality/claimLevelupBonus`, request, Project.Shotz)
            .pipe(map((claimLevelUpBonusResponse) => {
            return claimLevelUpBonusResponse;
        }), catchError(() => {
            return of(undefined);
        }));
    }
    // -----------------------------------------------------------------
    // Get Observables - Get User Level Up Details
    onGetUnclaimedLevels() {
        return this.apiInteractor
            .get(`/ajax/loyality/getUnclaimedLevels`, null, Project.Shotz)
            .pipe(map((unclaimedLevelsResponse) => {
            return unclaimedLevelsResponse;
        }), catchError(() => {
            return of(undefined);
        }));
    }
    // -----------------------------------------------------------------
    // Get Observables - Get Active Bonus Details
    onGetActiveBonusDetails() {
        return this.apiInteractor
            .get(`/ajax/Bonus/activeBonusDetails`, null, Project.Shotz)
            .pipe(map((activeBonusDetailsResponse) => {
            return activeBonusDetailsResponse;
        }), catchError((error) => {
            return throwError(error);
        }));
    }
    // -----------------------------------------------------------------
    // Get Observables - Get Eligible Bonus
    onGetEligibleBonus(eligibleRequest) {
        return this.apiInteractor
            .get(`/ajax/bonus/getEligibleBonuses`, eligibleRequest, Project.Shotz)
            .pipe(map((eligibleBonus) => {
            return eligibleBonus;
        }), catchError((error) => {
            return throwError(error);
        }));
    }
    // -----------------------------------------------------------------
    // Set Methods - on Set Level Before Level Upgraded
    onSetLevelBeforeLevelUpgraded(levelBeforeLevelUpgradedNumber) {
        this.levelBeforeLevelUpgradedNumber = levelBeforeLevelUpgradedNumber;
    }
    // -----------------------------------------------------------------
    // Set Methods - on Broad Level Up Details
    onBroadcastLevelUpDetails(userCurrentLevelUpDetails) {
        this.userCurrentLevelUpDetails = userCurrentLevelUpDetails;
        this.onSetUserCurrentLevelId(userCurrentLevelUpDetails.newLevelId);
        this.userLevelUpDetailsSubject.next(userCurrentLevelUpDetails);
    }
    // -----------------------------------------------------------------
    // Set Methods - on Set Level Up Details
    onSetLevelUpDetails(userCurrentLevelUpDetails) {
        this.userCurrentLevelUpDetails = userCurrentLevelUpDetails;
    }
    // -----------------------------------------------------------------
    // Set Methods - on Broadcast Level Up Notifications
    onBroadcastLevelUpNotifications(isClearLevelUpNotification) {
        this.isClearLevelUpNotificationSubject.next(isClearLevelUpNotification);
    }
    // -----------------------------------------------------------------
    // Set Methods - on Broadcast Level Up Point Details
    onBroadcastLevelUpPointDetails(userCurrentLevelUpPointDetails) {
        this.userCurrentLevelUpPointDetails = userCurrentLevelUpPointDetails;
        this.onSetUserCurrentLevelId(userCurrentLevelUpPointDetails.levelId);
        this.userLevelUpPointDetailsSubject.next(userCurrentLevelUpPointDetails);
    }
    // -----------------------------------------------------------------
    // Set Methods - on Set Level Up Point Details
    onSetUserLevelUpPointDetails(userCurrentLevelUpPointDetails) {
        this.userCurrentLevelUpPointDetails = userCurrentLevelUpPointDetails;
    }
    // -----------------------------------------------------------------
    // Set Methods - on Set User Current Level Id
    onSetUserCurrentLevelId(userCurrentLevelId) {
        this.userCurrentLevelId = userCurrentLevelId;
    }
}
RewardsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RewardsService_Factory() { return new RewardsService(i0.ɵɵinject(i1.HttpClient)); }, token: RewardsService, providedIn: "root" });
