// Configurations
import { localStorageKeys } from "src/app/modules/multi-languages/configurations/localstorage-keys.configurations";
import { mixPanelEventsConfigurations } from "src/app/configurations/main.configurations";
// Environments
import { environment } from "src/environments/environment";
// Libraries
import mixpanel from "mixpanel-browser";
import * as i0 from "@angular/core";
export class MixPanelService {
    constructor() {
        this.onInitiateMixPanel();
    }
    // -----------------------------------------------------------------
    // Get Methods
    getWindowType() {
        let clientWidth = document.body.clientWidth;
        if (clientWidth >= 1024) {
            return "desktop";
        }
        else {
            return "mobile";
        }
    }
    getVerticalByGameData(gameLanuchData) {
        let liveGameTypes = ["live_dealer", "live_casino"];
        return liveGameTypes.indexOf(gameLanuchData.gameData.gameType) > -1
            ? "Live-casino"
            : "Casino";
    }
    getVerticalByUrl() {
        let locationPath = window.location.pathname;
        let verticalValues = "";
        if (locationPath.includes("/casino")) {
            verticalValues = "Casino";
        }
        else if (locationPath.includes("/live-casino")) {
            verticalValues = "Live-casino";
        }
        else if (locationPath.includes("/studio")) {
            verticalValues = "Studio";
        }
        return verticalValues;
    }
    // -----------------------------------------------------------------
    // Set Methods
    onInitiateMixPanel() {
        mixpanel.init(environment.mixpanelProjectToken, {
            debug: true,
        });
    }
    onTrackMixPanelEvents(eventName, eventProperties) {
        eventProperties.platfromType = this.getWindowType();
        if (localStorage.getItem(localStorageKeys.stz_user)) {
            mixpanel.track(eventName, eventProperties);
        }
    }
    onTrackGameLaunchEvent(gameLanuchData) {
        let eventProperties = {
            name: gameLanuchData.gameData.name,
            playType: gameLanuchData.gameType,
            vertical: this.getVerticalByGameData(gameLanuchData),
            category: "no category",
        };
        if (gameLanuchData.groupName) {
            eventProperties.location = gameLanuchData.groupName;
        }
        if (gameLanuchData.lobbyName) {
            eventProperties.category = gameLanuchData.lobbyName;
        }
        if (gameLanuchData.callingFrom === "game-window-tag") {
            eventProperties.location = "In-Game";
            eventProperties.Tag = gameLanuchData.activeTag;
        }
        if (gameLanuchData.callingFrom === "recent-search") {
            eventProperties.location = "Search";
        }
        if (gameLanuchData.callingFrom === "studios") {
            eventProperties.location = "Game Studios";
        }
        this.onTrackMixPanelEvents(mixPanelEventsConfigurations.launched_game, eventProperties);
    }
}
MixPanelService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MixPanelService_Factory() { return new MixPanelService(); }, token: MixPanelService, providedIn: "root" });
