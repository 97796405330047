/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./game-lobby.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../storyly/storyly.component.ngfactory";
import * as i3 from "../storyly/storyly.component";
import * as i4 from "@ngrx/store";
import * as i5 from "../../../shared/services/gtm.service";
import * as i6 from "@angular/common";
import * as i7 from "@ngx-translate/core";
import * as i8 from "../recent-search-games/recent-search-games.component.ngfactory";
import * as i9 from "../recent-search-games/recent-search-games.component";
import * as i10 from "../../../multi-languages/services/translation.service";
import * as i11 from "../../../shared/pipes/games-filter.pipe";
import * as i12 from "../../../shared/services/mix-panel.service";
import * as i13 from "../../../auth/services/session.service";
import * as i14 from "../../../shared/services/common.service";
import * as i15 from "@angular/router";
import * as i16 from "../../../../../../node_modules/ngx-swiper-wrapper/dist/ngx-swiper-wrapper.ngfactory";
import * as i17 from "ngx-swiper-wrapper";
import * as i18 from "../../../shared/directives/swiper-resize.directive";
import * as i19 from "./game-lobby.component";
import * as i20 from "../../services/game-groups.service";
import * as i21 from "../../../shared/services/utility.service";
var styles_GameLobbyComponent = [i0.styles];
var RenderType_GameLobbyComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_GameLobbyComponent, data: {} });
export { RenderType_GameLobbyComponent as RenderType_GameLobbyComponent };
function View_GameLobbyComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-storyly", [], null, null, null, i2.View_StorylyComponent_0, i2.RenderType_StorylyComponent)), i1.ɵdid(1, 4374528, null, 0, i3.StorylyComponent, [i4.Store, i5.GtmService], null, null)], null, null); }
function View_GameLobbyComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "gameLobbyNav__list btn-lobbyNav"]], [[2, "active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onNavigateToLobby(((_v.parent.context.$implicit == null) ? null : _v.parent.context.$implicit.filterKey), "list") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i6.ɵNgClassImpl, i6.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i6.NgClass, [i6.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵted(3, null, [" ", " "])), i1.ɵpid(131072, i7.TranslatePipe, [i7.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_1 = "gameLobbyNav__list btn-lobbyNav"; var currVal_2 = ((_co.callingFrom === "liveCasinoLobby") ? "liveCasinoNav" : "casinoNav"); _ck(_v, 2, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (((_v.parent.context.$implicit == null) ? null : _v.parent.context.$implicit.filterKey) === _co.activeLobby); _ck(_v, 0, 0, currVal_0); var currVal_3 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform(_v.parent.context.$implicit.linkText)); _ck(_v, 3, 0, currVal_3); }); }
function View_GameLobbyComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GameLobbyComponent_3)), i1.ɵdid(3, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_1 = (!((_v.context.$implicit == null) ? null : _v.context.$implicit.onlyAfterLogin) || ((((_v.context.$implicit == null) ? null : _v.context.$implicit.onlyAfterLogin) === _co.isLoggedIn) && _co.isLastPlayedLiveGamesExist)); _ck(_v, 3, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.menuConfigurationType.onlyAfterLogin; _ck(_v, 1, 0, currVal_0); }); }
function View_GameLobbyComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", " "])), i1.ɵpid(131072, i7.TranslatePipe, [i7.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("general.search-placeholder")); _ck(_v, 1, 0, currVal_0); }); }
function View_GameLobbyComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", " "])), i1.ɵpid(131072, i7.TranslatePipe, [i7.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("livecasino.search-placeholder")); _ck(_v, 1, 0, currVal_0); }); }
function View_GameLobbyComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "searchlobby-at-mobile"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "search-box"]], [[2, "liveCasinoSearch", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onOpenSearchResultsAtMobile() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["class", "prefix-icon m-r-4 search-box--title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "i", [["class", "icon-search"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 4, "span", [["class", "search-box--subtitle casino"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GameLobbyComponent_5)), i1.ɵdid(6, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GameLobbyComponent_6)), i1.ɵdid(8, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.callingFrom != "liveCasinoLobby"); _ck(_v, 6, 0, currVal_1); var currVal_2 = (_co.callingFrom === "liveCasinoLobby"); _ck(_v, 8, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.callingFrom === "liveCasinoLobby"); _ck(_v, 1, 0, currVal_0); }); }
function View_GameLobbyComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-recent-search-games", [], null, [[null, "closeSearchResults"], ["window", "resize"], ["window", "orientationchange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onResize() !== false);
        ad = (pd_0 && ad);
    } if (("window:orientationchange" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).onRotate() !== false);
        ad = (pd_1 && ad);
    } if (("closeSearchResults" === en)) {
        var pd_2 = (_co.onCloseSearchResultsAtMobile() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i8.View_RecentSearchGamesComponent_0, i8.RenderType_RecentSearchGamesComponent)), i1.ɵdid(1, 4964352, null, 0, i9.RecentSearchGamesComponent, [i10.TranslationService, i11.GamesFilterPipe, i12.MixPanelService, i13.SessionService, i14.CommonService, i4.Store, i15.Router], { callingFrom: [0, "callingFrom"] }, { closeSearchResults: "closeSearchResults" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.callingFrom; _ck(_v, 1, 0, currVal_0); }, null); }
function View_GameLobbyComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "searchAtMobile padding"]], [[2, "liveCasinoSearch", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "searchAtMobile__header flex-a-j-center clearfix"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "searchAtMobile__header--title"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" ", " "])), i1.ɵpid(131072, i7.TranslatePipe, [i7.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(5, 0, null, null, 1, "button", [["aria-label", "Close"], ["class", "btn btn-circle btn-secondary searchAtMobile--close-btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onCloseSearchResultsAtMobile() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "i", [["class", "icon-close"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "searchAtMobile__body clearfix"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GameLobbyComponent_8)), i1.ɵdid(9, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.isSearchRequired; _ck(_v, 9, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.callingFrom === "liveCasinoLobby"); _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("general.search")); _ck(_v, 3, 0, currVal_1); }); }
export function View_GameLobbyComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "storyly-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GameLobbyComponent_1)), i1.ɵdid(2, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 14, "section", [["class", "gameLobbyNav"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 8, "div", [["class", "gameLobbyNav__list-wrapper swiper__outer clearfix float-left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 7, "div", [["class", "swiper__inner swiper__full atGameLobbySwiper"]], null, null, null, null, null)), i1.ɵprd(512, null, i6.ɵNgClassImpl, i6.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(7, 278528, null, 0, i6.NgClass, [i6.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 4, "swiper", [["class", "mySwiper swiper-control clearfix"], ["watchSlidesProgress", ""]], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 10).onResize() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i16.View_SwiperComponent_0, i16.RenderType_SwiperComponent)), i1.ɵdid(9, 4374528, null, 0, i17.SwiperComponent, [i1.NgZone, i1.ChangeDetectorRef, i1.PLATFORM_ID, [2, i17.SWIPER_CONFIG]], { config: [0, "config"] }, null), i1.ɵdid(10, 16384, null, 0, i18.SwiperResizeDirective, [i17.SwiperComponent], null, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_GameLobbyComponent_2)), i1.ɵdid(12, 278528, null, 0, i6.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(13, 0, null, null, 2, "div", [["class", "gameLobbyNav__search-wrapper searchAtlobbynav"], ["id", "searchAtlobbynav"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onOpenSearchResultsAtDesktop() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 1, "app-recent-search-games", [], null, [["window", "resize"], ["window", "orientationchange"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 15).onResize() !== false);
        ad = (pd_0 && ad);
    } if (("window:orientationchange" === en)) {
        var pd_1 = (i1.ɵnov(_v, 15).onRotate() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i8.View_RecentSearchGamesComponent_0, i8.RenderType_RecentSearchGamesComponent)), i1.ɵdid(15, 4964352, null, 0, i9.RecentSearchGamesComponent, [i10.TranslationService, i11.GamesFilterPipe, i12.MixPanelService, i13.SessionService, i14.CommonService, i4.Store, i15.Router], { callingFrom: [0, "callingFrom"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GameLobbyComponent_4)), i1.ɵdid(17, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GameLobbyComponent_7)), i1.ɵdid(19, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isLoggedIn; _ck(_v, 2, 0, currVal_0); var currVal_1 = "swiper__inner swiper__full atGameLobbySwiper"; var currVal_2 = ((_co.callingFrom === "liveCasinoLobby") ? "liveCasinoNav" : "casinoNav"); _ck(_v, 7, 0, currVal_1, currVal_2); var currVal_3 = _co.swiperGameLobby; _ck(_v, 9, 0, currVal_3); var currVal_4 = _co.menuConfigurationType; _ck(_v, 12, 0, currVal_4); var currVal_5 = _co.callingFrom; _ck(_v, 15, 0, currVal_5); var currVal_6 = _co.isSearchRequired; _ck(_v, 17, 0, currVal_6); var currVal_7 = _co.isShowSearchResult; _ck(_v, 19, 0, currVal_7); }, null); }
export function View_GameLobbyComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-game-lobby", [], null, [["document", "touchend"], ["document", "click"]], function (_v, en, $event) { var ad = true; if (("document:touchend" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("document:click" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).onClick($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, View_GameLobbyComponent_0, RenderType_GameLobbyComponent)), i1.ɵdid(1, 770048, null, 0, i19.GameLobbyComponent, [i20.GameGroupsService, i12.MixPanelService, i15.ActivatedRoute, i21.UtilityService, i13.SessionService, i14.CommonService, i4.Store, i15.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GameLobbyComponentNgFactory = i1.ɵccf("app-game-lobby", i19.GameLobbyComponent, View_GameLobbyComponent_Host_0, { callingFrom: "callingFrom" }, { callOnSelectedLobby: "callOnSelectedLobby" }, []);
export { GameLobbyComponentNgFactory as GameLobbyComponentNgFactory };
