import { createFeatureSelector, createSelector, } from "@ngrx/store";
// Reducers
import { BannerStateFeatureKey, } from "src/app/modules/banner/store/reducers/banners.reducer";
// Utilities
import { bannerZoneMappersLiveCasinoList, bannerZoneMappersCasinoList, } from "src/app/modules/banner/utilities/banner-zone-mappers.utilities";
// Select the Main State
const selectBannerReducerState = createFeatureSelector(BannerStateFeatureKey);
const ɵ0 = (state) => state.bannerList;
// Get Banners List
export const getBannerList = createSelector(selectBannerReducerState, ɵ0);
// Get A Single Banner
export const getBanner = (props) => createSelector(selectBannerReducerState, (state) => {
    let zoneId = props.zoneId;
    if (bannerZoneMappersCasinoList.includes(props.zoneId)) {
        zoneId = "b_casino";
    }
    if (bannerZoneMappersLiveCasinoList.includes(props.zoneId)) {
        zoneId = "b_all-live";
    }
    if (state && state.bannerList.hasOwnProperty(zoneId)) {
        const { banners, isLoaded, isLoading } = state.bannerList[zoneId];
        return {
            isLoading,
            isLoaded,
            banners,
        };
    }
    else {
        return {
            isLoading: true,
            isLoaded: false,
            banners: [],
        };
    }
});
export { ɵ0 };
