import { Subject } from "rxjs";
import * as i0 from "@angular/core";
export class EmitterService {
    constructor() {
        // Booleans
        this.isCCCardsAvailableStatus = false;
        // --------------------------------------------------------
        // Subject - Socket Message Subject
        this.socketMessageSubject = new Subject();
        this.socketMessageSubject$ = this.socketMessageSubject.asObservable();
        // --------------------------------------------------------
        // Subject - successful Deposit Subject
        this.isSuccessfulDepositSubject = new Subject();
        this.isSuccessfulDepositSubject$ = this.isSuccessfulDepositSubject.asObservable();
        // --------------------------------------------------------
        // Subject - Is Open Reward Terms And Conditions
        this.isOpenRewardTermsConditionsSubject = new Subject();
        this.isOpenRewardTermsConditionsSubject$ = this.isOpenRewardTermsConditionsSubject.asObservable();
        // --------------------------------------------------------
        // Subject - Is No CC Cards Available
        this.isNoCCCardsAvailableSubject = new Subject();
        this.isNoCCCardsAvailableSubject$ = this.isNoCCCardsAvailableSubject.asObservable();
        // --------------------------------------------------------
        // Subject - Is Promotional Video Enabled
        this.isPromotionalVideoEnabledSubject = new Subject();
        this.isPromotionalVideoEnabledSubject$ = this.isPromotionalVideoEnabledSubject.asObservable();
        // --------------------------------------------------------
        // Subject - Is Open Limits Regulatory Popup
        this.isOpenLimitsRegulatoryPopupSubject = new Subject();
        this.isOpenLimitsRegulatoryPopupSubject$ = this.isOpenLimitsRegulatoryPopupSubject.asObservable();
    }
    // -----------------------------------------------------------------
    // Get Methods
    getCCCardsAvailableStatus() {
        return this.isCCCardsAvailableStatus;
    }
    // -----------------------------------------------------------------
    // Set Methods
    onBroadcastSocketMessage(socketMessage) {
        this.socketMessageSubject.next(socketMessage);
    }
    onBroadcastSuccessfulDeposit(isSuccessful) {
        this.isSuccessfulDepositSubject.next(isSuccessful);
    }
    onBroadcastRewardTermsAndConditions(isOpenRewardTermsConditions) {
        this.isOpenRewardTermsConditionsSubject.next(isOpenRewardTermsConditions);
    }
    onBroadcastIsNoCCCardsAvailable(isNoCCCardsAvailable) {
        this.isNoCCCardsAvailableSubject.next(isNoCCCardsAvailable);
    }
    onBroadcastIsPromotionalVideoEnabled(isPromotionalVideoEnabled) {
        this.isPromotionalVideoEnabledSubject.next(isPromotionalVideoEnabled);
    }
    onBroadcastLimitsRegulatoryPopup(isOpenLimitsRegulatoryPopup) {
        this.isOpenLimitsRegulatoryPopupSubject.next(isOpenLimitsRegulatoryPopup);
    }
}
EmitterService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function EmitterService_Factory() { return new EmitterService(); }, token: EmitterService, providedIn: "root" });
