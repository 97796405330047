/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./provider-lobby.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "./provider-lobby.component";
import * as i5 from "../../../multi-languages/services/translation.service";
import * as i6 from "../../services/game-groups.service";
import * as i7 from "../../../shared/services/mix-panel.service";
import * as i8 from "../../services/game-play.service";
import * as i9 from "../../../shared/services/utility.service";
import * as i10 from "../../../auth/services/session.service";
import * as i11 from "../../../shared/services/common.service";
import * as i12 from "@ngrx/store";
import * as i13 from "@angular/router";
var styles_ProviderLobbyComponent = [i0.styles];
var RenderType_ProviderLobbyComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProviderLobbyComponent, data: {} });
export { RenderType_ProviderLobbyComponent as RenderType_ProviderLobbyComponent };
function View_ProviderLobbyComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "livecasino__provider-lobby--table-open"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "span", [["class", "live-pulse"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", " ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.providerLevelGamesList[_v.parent.context.$implicit.vendorCode]["live-games"].length; var currVal_1 = i1.ɵunv(_v, 2, 1, i1.ɵnov(_v, 3).transform("livecasino.live_open_tables")); _ck(_v, 2, 0, currVal_0, currVal_1); }); }
function View_ProviderLobbyComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "livecasino__provider-lobby__list"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "livecasino__provider-lobby--img"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onGameLaunch(_v.context.$implicit.name, _v.context.$implicit.vendorDisplayName) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [], [[8, "src", 4], [8, "alt", 0], [8, "title", 0]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProviderLobbyComponent_3)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = (_co.providerLevelGamesList && _co.providerLevelGamesList[_v.context.$implicit.vendorCode]); _ck(_v, 4, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(4, "", _co.gamesUrlPath, "/", _v.context.$implicit.gameCode, "/", _v.context.$implicit.gameCode, "_live-lobby-provider.jpg", _co.imgixParams, ""); var currVal_1 = i1.ɵinlineInterpolate(1, "", _v.context.$implicit.name, ""); var currVal_2 = i1.ɵinlineInterpolate(1, "", _v.context.$implicit.name, ""); _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }); }
function View_ProviderLobbyComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "livecasino livecasino__provider-lobby"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "widget--title livecasino__provider-lobby--title"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "livecasino__provider-lobby__inner"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProviderLobbyComponent_2)), i1.ɵdid(6, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.games; _ck(_v, 6, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("livecasino.lobbies_by_providers")); _ck(_v, 2, 0, currVal_0); }); }
function View_ProviderLobbyComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "livecasino__provider-lobby__list"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "livecasino__provider-lobby--img skeleton-loader"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "livecasino__provider-lobby--table-open"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "span", [["class", "live-pulse skeleton-loader"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "span", [["class", "liveTable-count skeleton-loader round-skelton"]], null, null, null, null, null))], null, null); }
function View_ProviderLobbyComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "livecasino livecasino__provider-lobby"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "widget--title livecasino__provider-lobby--title skeleton-loader round-skelton"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProviderLobbyComponent_5)), i1.ɵdid(3, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_0 = i1.ɵEMPTY_ARRAY.constructor(3); _ck(_v, 3, 0, currVal_0); }, null); }
export function View_ProviderLobbyComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "section", [["class", "liveCasino__side-widgets--list"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProviderLobbyComponent_1)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProviderLobbyComponent_4)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.games && (_co.games.length > 0)); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.isLoading; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_ProviderLobbyComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-provider-lobby", [], null, null, null, View_ProviderLobbyComponent_0, RenderType_ProviderLobbyComponent)), i1.ɵdid(1, 770048, null, 0, i4.ProviderLobbyComponent, [i5.TranslationService, i6.GameGroupsService, i7.MixPanelService, i8.GamePlayService, i9.UtilityService, i10.SessionService, i11.CommonService, i12.Store, i13.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProviderLobbyComponentNgFactory = i1.ɵccf("app-provider-lobby", i4.ProviderLobbyComponent, View_ProviderLobbyComponent_Host_0, { lobbyProviderData: "lobbyProviderData", games: "games" }, {}, []);
export { ProviderLobbyComponentNgFactory as ProviderLobbyComponentNgFactory };
