import { HttpClient } from "@angular/common/http";
import { catchError, map } from "rxjs/operators";
import { Observable, throwError } from "rxjs";
import { Injectable } from "@angular/core";

// API Interactors
import { ApiInteractors } from "src/app/models/interactors/api.interactor";

// Enums
import { Project } from "src/app/models/environments/project.enum";

// Models
import { UpdateSubscriptionPreferenceResponse } from "src/app/modules/shared/models/preferences/update-subscription-preference-response.model";
import { UpdateSubscriptionPreferenceRequest } from "src/app/modules/shared/models/preferences/update-subscription-preference-request.model";
import { SubscriptionPreferenceResponse } from "src/app/modules/shared/models/preferences/subscription-preference-response.model";
import { DropBonusResponse } from "src/app/modules/rewards/models/drop-bonus-response.model";
import { DropBonusRequest } from "src/app/modules/rewards/models/drop-bonus-request.model";
import { KYCStatusResponse } from "src/app/modules/kyc/models/kyc-status.model";

@Injectable({
  providedIn: "root",
})
export class AccountService {
  // API Interactions
  apiInteractor: ApiInteractors;

  constructor(private httpClient: HttpClient) {
    this.apiInteractor = new ApiInteractors(this.httpClient);
  }

  // -----------------------------------------------------------------
  // Get Methods - Observable
  onSubscriptionPreference(): Observable<SubscriptionPreferenceResponse> {
    return this.apiInteractor
      .post<null, SubscriptionPreferenceResponse>(
        `/ajax/profile/getSubscriptionPreferenes`,
        null,
        Project.Shotz
      )
      .pipe(
        map(
          (subscriptionPreferenceResponse: SubscriptionPreferenceResponse) => {
            return subscriptionPreferenceResponse;
          }
        ),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  onUpdateSubscriptionPreference(
    updateSubscriptionPreferenceRequest: UpdateSubscriptionPreferenceRequest
  ): Observable<UpdateSubscriptionPreferenceResponse> {
    return this.apiInteractor
      .post<
        UpdateSubscriptionPreferenceRequest,
        UpdateSubscriptionPreferenceResponse
      >(
        `/ajax/profile/updateSubscriptionPreferenes`,
        updateSubscriptionPreferenceRequest,
        Project.Shotz
      )
      .pipe(
        map(
          (
            updateSubscriptionPreferenceResponse: UpdateSubscriptionPreferenceResponse
          ) => {
            return updateSubscriptionPreferenceResponse;
          }
        ),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  onDropBonus(
    dropBonusRequest: DropBonusRequest
  ): Observable<DropBonusResponse> {
    return this.apiInteractor
      .get<DropBonusRequest, DropBonusResponse>(
        `/ajax/bonus/drop`,
        dropBonusRequest,
        Project.Shotz
      )
      .pipe(
        map((dropBonusResponse: DropBonusResponse) => {
          return dropBonusResponse;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  onGetAccountVerficationStatus(): Observable<KYCStatusResponse> {
    return this.apiInteractor
      .get<null, KYCStatusResponse>(
        `/ajax/profile/getAccountVerificationStatus`,
        null,
        Project.Shotz
      )
      .pipe(
        map((kycStatusResponse: KYCStatusResponse) => {
          return kycStatusResponse;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
}
