import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

// Components
import { DynamicFormComponent } from "src/app/modules/dynamic-form/components/dynamic-form/dynamic-form.component";
import { FormSelectComponent } from "src/app/modules/dynamic-form/components/form-select/form-select.component";
import { FormButtonComponent } from "src/app/modules/dynamic-form/components/form-button/form-button.component";
import { FormInputComponent } from "src/app/modules/dynamic-form/components/form-input/form-input.component";
import { FormLabelComponent } from "src/app/modules/dynamic-form/components/form-label/form-label.component";
import { FormRadioComponent } from "src/app/modules/dynamic-form/components/form-radio/form-radio.component";

// Directives
import { DynamicFieldDirective } from "src/app/modules/dynamic-form/directives/dynamic-field.directive";

// Modules
import { SharedModule } from "src/app/modules/shared/shared.module";

@NgModule({
  declarations: [
    DynamicFieldDirective,
    DynamicFormComponent,
    FormSelectComponent,
    FormButtonComponent,
    FormInputComponent,
    FormLabelComponent,
    FormRadioComponent,
  ],
  entryComponents: [
    FormButtonComponent,
    FormSelectComponent,
    FormInputComponent,
    FormLabelComponent,
    FormRadioComponent,
  ],
  imports: [CommonModule, ReactiveFormsModule, FormsModule, SharedModule],
  exports: [DynamicFormComponent],
})
export class DynamicFormModule {}
