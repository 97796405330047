import { Action, createReducer, on } from "@ngrx/store";

// Actions
import { logoutLoaded } from "src/app/modules/auth/store/actions/auth.actions";

import {
  userLoaded,
  userError,
} from "src/app/modules/user/store/actions/user.actions";

// Models
import { UserData } from "src/app/modules/user/models/user-data.model";

export const UserStateFeatureKey = "user";

// Reducer States
export interface UserReducerState {
  // User Data
  userData: UserData;
  isLoaded: boolean;
  isError: boolean;
  isLoading: boolean;
  userDataError: string;
}

export const initialState: UserReducerState = {
  // User Data
  userData: null,
  isLoaded: false,
  isError: false,
  isLoading: false,
  userDataError: null,
};

// Reducer
const UserReducer = createReducer(
  initialState,
  // User
  on(userLoaded, (state: UserReducerState, { userData }) => {
    return {
      ...state,
      userData,
      isLoading: false,
      isLoaded: true,
      isError: false,
      userDataError: null,
    };
  }),
  on(userError, (state: UserReducerState) => {
    return {
      ...state,
      userData: null,
      isLoading: false,
      isLoaded: false,
      isError: true,
      userDataError: "Error occurred whilst retrieving User",
    };
  }),
  // Logout
  on(logoutLoaded, (state: UserReducerState) => {
    return {
      ...state,
      userData: null,
      isLoading: false,
      isLoaded: false,
      userDataError: null,
    };
  })
);

export function reducer(state: UserReducerState | undefined, action: Action) {
  return UserReducer(state, action);
}
