import { catchError, map } from "rxjs/operators";
import { throwError } from "rxjs";
// Api Interactors
import { ApiInteractors } from "src/app/models/interactors/api.interactor";
// Enums
import { Project } from "src/app/models/environments/project.enum";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class ForgotPasswordAPIService {
    constructor(httpClient) {
        this.httpClient = httpClient;
        this.apiInteractor = new ApiInteractors(this.httpClient);
    }
    onSendResetPasswordLink(forgotPassword) {
        return this.apiInteractor
            .post(`/ajax/resetPassword`, forgotPassword, Project.Shotz)
            .pipe(map((resetPassword) => {
            return resetPassword;
        }), catchError((error) => {
            return throwError(error);
        }));
    }
}
ForgotPasswordAPIService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ForgotPasswordAPIService_Factory() { return new ForgotPasswordAPIService(i0.ɵɵinject(i1.HttpClient)); }, token: ForgotPasswordAPIService, providedIn: "root" });
