/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./payment-bonus.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../rewards/components/cashback-promo-reward/cashback-promo-reward.component.ngfactory";
import * as i3 from "../../../rewards/components/cashback-promo-reward/cashback-promo-reward.component";
import * as i4 from "../../services/pay-n-play-cashier.service";
import * as i5 from "../../../rewards/services/cashback-promo.service";
import * as i6 from "../../../user/services/user-details.service";
import * as i7 from "../../../shared/services/utility.service";
import * as i8 from "@angular/common";
import * as i9 from "../../../rewards/components/deposit-reward-card/deposit-reward-card.component.ngfactory";
import * as i10 from "../../../rewards/components/deposit-reward-card/deposit-reward-card.component";
import * as i11 from "../../services/cashier.service";
import * as i12 from "@ngrx/store";
import * as i13 from "../../../../../../node_modules/ngx-swiper-wrapper/dist/ngx-swiper-wrapper.ngfactory";
import * as i14 from "ngx-swiper-wrapper";
import * as i15 from "../../../shared/directives/swiper-resize.directive";
import * as i16 from "@ngx-translate/core";
import * as i17 from "../../../shared/components/loader/loader.component.ngfactory";
import * as i18 from "../../../shared/components/loader/loader.component";
import * as i19 from "../../../shared/directives/market-based-component-display.directive";
import * as i20 from "./payment-bonus.component";
import * as i21 from "../../../multi-languages/services/multi-language.service";
import * as i22 from "../../../rewards/services/reward.service";
var styles_PaymentBonusComponent = [i0.styles];
var RenderType_PaymentBonusComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_PaymentBonusComponent, data: {} });
export { RenderType_PaymentBonusComponent as RenderType_PaymentBonusComponent };
function View_PaymentBonusComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "payment-bonus__list"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-cashback-promo-reward", [], null, [[null, "callOnBonusSelected"], [null, "callOpenRewardTCPopUp"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("callOnBonusSelected" === en)) {
        var pd_0 = (_co.onGetPromoCashback() !== false);
        ad = (pd_0 && ad);
    } if (("callOpenRewardTCPopUp" === en)) {
        var pd_1 = (_co.onOpenRewardTCPopUp($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_CashbackPromoRewardComponent_0, i2.RenderType_CashbackPromoRewardComponent)), i1.ɵdid(2, 770048, null, 0, i3.CashbackPromoRewardComponent, [i4.PayNPlayCashierService, i5.CashbackPromoService, i6.UserDetailsService, i7.UtilityService], { bonusDetails: [0, "bonusDetails"], callingFrom: [1, "callingFrom"], rewardSize: [2, "rewardSize"], selectedBonus: [3, "selectedBonus"] }, { callOpenRewardTCPopUp: "callOpenRewardTCPopUp", callOnBonusSelected: "callOnBonusSelected" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit; var currVal_1 = "payment-bonus"; var currVal_2 = _co.rewardSize; var currVal_3 = _co.selectedBonus; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
function View_PaymentBonusComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PaymentBonusComponent_3)), i1.ɵdid(2, 278528, null, 0, i8.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.campaignDetailsList; _ck(_v, 2, 0, currVal_0); }, null); }
function View_PaymentBonusComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "payment-bonus__list"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-deposit-reward-card", [], null, [[null, "callOnBonusSelected"], [null, "callOpenRewardTCPopUp"], [null, "callOnNavigateToDeposit"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("callOnBonusSelected" === en)) {
        var pd_0 = (_co.onSelectBonus($event) !== false);
        ad = (pd_0 && ad);
    } if (("callOpenRewardTCPopUp" === en)) {
        var pd_1 = (_co.onOpenRewardTCPopUp($event) !== false);
        ad = (pd_1 && ad);
    } if (("callOnNavigateToDeposit" === en)) {
        var pd_2 = (_co.onNavigateToDeposit() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i9.View_DepositRewardCardComponent_0, i9.RenderType_DepositRewardCardComponent)), i1.ɵdid(2, 770048, null, 0, i10.DepositRewardCardComponent, [i4.PayNPlayCashierService, i6.UserDetailsService, i7.UtilityService, i11.CashierService, i12.Store], { selectedBonus: [0, "selectedBonus"], bonusDetails: [1, "bonusDetails"], callingFrom: [2, "callingFrom"], rewardSize: [3, "rewardSize"] }, { callOpenRewardTCPopUp: "callOpenRewardTCPopUp", callOnNavigateToDeposit: "callOnNavigateToDeposit", callOnBonusSelected: "callOnBonusSelected" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.selectedBonus; var currVal_1 = _v.context.$implicit; var currVal_2 = _co.callingFrom; var currVal_3 = _co.rewardSize; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
function View_PaymentBonusComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "payment-bonus swiper__outer clearfix"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "swiper", [["class", "mySwiper swiperPaymentBonus clearfix swiper-control"], ["watchSlidesProgress", ""]], null, [[null, "indexChange"], ["window", "resize"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onResize() !== false);
        ad = (pd_0 && ad);
    } if (("indexChange" === en)) {
        var pd_1 = ((_co.selectedBonusIndex = $event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i13.View_SwiperComponent_0, i13.RenderType_SwiperComponent)), i1.ɵdid(2, 4374528, null, 0, i14.SwiperComponent, [i1.NgZone, i1.ChangeDetectorRef, i1.PLATFORM_ID, [2, i14.SWIPER_CONFIG]], { index: [0, "index"], config: [1, "config"] }, { indexChange: "indexChange" }), i1.ɵdid(3, 16384, null, 0, i15.SwiperResizeDirective, [i14.SwiperComponent], null, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_PaymentBonusComponent_2)), i1.ɵdid(5, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_PaymentBonusComponent_4)), i1.ɵdid(7, 278528, null, 0, i8.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 0, "div", [["class", "paybonus-pagination swipper-pagination clearfix"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.selectedBonusIndex; var currVal_1 = _co.swiperPaymentBonus; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _co.campaignDetailsList; _ck(_v, 5, 0, currVal_2); var currVal_3 = _co.depositBonusDetailsList; _ck(_v, 7, 0, currVal_3); }, null); }
function View_PaymentBonusComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "h3", [["class", "pnp-bonuses--title"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵpid(131072, i16.TranslatePipe, [i16.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("rewards.add_bonus_popup_title")); _ck(_v, 1, 0, currVal_0); }); }
function View_PaymentBonusComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "payment-bonus__list"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-cashback-promo-reward", [], null, [[null, "callOnBonusSelected"], [null, "callOnNavigateToDeposit"], [null, "callOpenRewardTCPopUp"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("callOnBonusSelected" === en)) {
        var pd_0 = (_co.onGetPromoCashback() !== false);
        ad = (pd_0 && ad);
    } if (("callOnNavigateToDeposit" === en)) {
        var pd_1 = (_co.onNavigateToDeposit() !== false);
        ad = (pd_1 && ad);
    } if (("callOpenRewardTCPopUp" === en)) {
        var pd_2 = (_co.onOpenRewardTCPopUp($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i2.View_CashbackPromoRewardComponent_0, i2.RenderType_CashbackPromoRewardComponent)), i1.ɵdid(2, 770048, null, 0, i3.CashbackPromoRewardComponent, [i4.PayNPlayCashierService, i5.CashbackPromoService, i6.UserDetailsService, i7.UtilityService], { bonusDetails: [0, "bonusDetails"], callingFrom: [1, "callingFrom"], rewardSize: [2, "rewardSize"], selectedBonus: [3, "selectedBonus"] }, { callOpenRewardTCPopUp: "callOpenRewardTCPopUp", callOnNavigateToDeposit: "callOnNavigateToDeposit", callOnBonusSelected: "callOnBonusSelected" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit; var currVal_1 = _co.callingFrom; var currVal_2 = _co.rewardSize; var currVal_3 = _co.selectedBonus; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
function View_PaymentBonusComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PaymentBonusComponent_9)), i1.ɵdid(2, 278528, null, 0, i8.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.campaignDetailsList; _ck(_v, 2, 0, currVal_0); }, null); }
function View_PaymentBonusComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "isPNPBonuses"]], [[2, "m-b-16", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-deposit-reward-card", [], null, [[null, "callOnBonusSelected"], [null, "callOpenRewardTCPopUp"], [null, "callOnNavigateToDeposit"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("callOnBonusSelected" === en)) {
        var pd_0 = (_co.onSelectBonus($event) !== false);
        ad = (pd_0 && ad);
    } if (("callOpenRewardTCPopUp" === en)) {
        var pd_1 = (_co.onOpenRewardTCPopUp($event) !== false);
        ad = (pd_1 && ad);
    } if (("callOnNavigateToDeposit" === en)) {
        var pd_2 = (_co.onNavigateToDeposit() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i9.View_DepositRewardCardComponent_0, i9.RenderType_DepositRewardCardComponent)), i1.ɵdid(2, 770048, null, 0, i10.DepositRewardCardComponent, [i4.PayNPlayCashierService, i6.UserDetailsService, i7.UtilityService, i11.CashierService, i12.Store], { selectedBonus: [0, "selectedBonus"], bonusDetails: [1, "bonusDetails"], callingFrom: [2, "callingFrom"], rewardSize: [3, "rewardSize"] }, { callOpenRewardTCPopUp: "callOpenRewardTCPopUp", callOnNavigateToDeposit: "callOnNavigateToDeposit", callOnBonusSelected: "callOnBonusSelected" })], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.selectedBonus; var currVal_2 = _v.context.$implicit; var currVal_3 = _co.callingFrom; var currVal_4 = _co.rewardSize; _ck(_v, 2, 0, currVal_1, currVal_2, currVal_3, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.rewardSize === "basic"); _ck(_v, 0, 0, currVal_0); }); }
function View_PaymentBonusComponent_11(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "pnp-bonuses--cancel-link"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onNavigateToDeposit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵpid(131072, i16.TranslatePipe, [i16.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("rewards.no_thanks_continue_deposit")); _ck(_v, 1, 0, currVal_0); }); }
function View_PaymentBonusComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PaymentBonusComponent_7)), i1.ɵdid(2, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PaymentBonusComponent_8)), i1.ɵdid(4, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PaymentBonusComponent_10)), i1.ɵdid(6, 278528, null, 0, i8.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PaymentBonusComponent_11)), i1.ɵdid(8, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.rewardSize === "basic"); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.campaignDetailsList; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.depositBonusDetailsList; _ck(_v, 6, 0, currVal_2); var currVal_3 = (_co.rewardSize === "basic"); _ck(_v, 8, 0, currVal_3); }, null); }
function View_PaymentBonusComponent_12(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "p", [["class", "pnp-bonuses--loader valign-center"]], [[2, "j1", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-loader", [], null, null, null, i17.View_LoaderComponent_0, i17.RenderType_LoaderComponent)), i1.ɵdid(2, 49152, null, 0, i18.LoaderComponent, [], null, null), (_l()(), i1.ɵeld(3, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", " "])), i1.ɵpid(131072, i16.TranslatePipe, [i16.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.rewardSize === "shorter"); _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform("general.loading")); _ck(_v, 4, 0, currVal_1); }); }
function View_PaymentBonusComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PaymentBonusComponent_6)), i1.ɵdid(2, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PaymentBonusComponent_12)), i1.ɵdid(4, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isLoading; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.isLoading; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_PaymentBonusComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 2, null, View_PaymentBonusComponent_1)), i1.ɵdid(1, 16384, null, 0, i19.MarketBasedComponentDisplayDirective, [i1.ViewContainerRef, i1.TemplateRef], { marketBasedComponentDisplay: [0, "marketBasedComponentDisplay"] }, null), i1.ɵpod(2, { "flowType": 0, "langCode": 1 }), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_PaymentBonusComponent_5)), i1.ɵdid(4, 16384, null, 0, i19.MarketBasedComponentDisplayDirective, [i1.ViewContainerRef, i1.TemplateRef], { marketBasedComponentDisplay: [0, "marketBasedComponentDisplay"] }, null), i1.ɵpod(5, { "flowType": 0, "langCode": 1 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 2, 0, _co.UserFlowTypes.NORMAL_FLOW, _co.languageCode); _ck(_v, 1, 0, currVal_0); var currVal_1 = _ck(_v, 5, 0, _co.UserFlowTypes.ZIMPLER_FLOW, _co.languageCode); _ck(_v, 4, 0, currVal_1); }, null); }
export function View_PaymentBonusComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-payment-bonus", [], null, null, null, View_PaymentBonusComponent_0, RenderType_PaymentBonusComponent)), i1.ɵdid(1, 638976, null, 0, i20.PaymentBonusComponent, [i5.CashbackPromoService, i21.MultiLanguageService, i6.UserDetailsService, i22.RewardsService, i7.UtilityService, i11.CashierService, i12.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PaymentBonusComponentNgFactory = i1.ɵccf("app-payment-bonus", i20.PaymentBonusComponent, View_PaymentBonusComponent_Host_0, { clearSelectedBonus: "clearSelectedBonus", activeBonusData: "activeBonusData", callingFrom: "callingFrom", rewardSize: "rewardSize" }, { callOnNavigateToDeposit: "callOnNavigateToDeposit", callOnSelectBonus: "callOnSelectBonus" }, []);
export { PaymentBonusComponentNgFactory as PaymentBonusComponentNgFactory };
