<div class="livespins-page">
  <ng-container
    *ngTemplateOutlet="loadingTemplate"
  ></ng-container>

  <ng-container
    *ngTemplateOutlet="iframeTemplate"
  ></ng-container>

  <ng-container
    *ngTemplateOutlet="errorTemplate"
  ></ng-container>
</div>

<!-- 
  ------------------------------------------------------
  
  Templates 
  
  ------------------------------------------------------
-->
<!-- iFrame Template -->
<ng-template #iframeTemplate>
  <ng-container *ngIf="!noGameUrlError && !isLoading">
    <div class="livespins-iframe">
      <div class="livespins-page-background"></div>

      <ng-container *ngIf="iframeURL">
        <app-csn-livespins-iframe
          [iframeURL]="iframeURL"
        ></app-csn-livespins-iframe>
      </ng-container>
    </div>
  </ng-container>
</ng-template>

<!-- Loading Template -->
<ng-template #loadingTemplate>
  <ng-container *ngIf="isLoading && !noGameUrlError">
    <div class="app-loader">
      <app-loader></app-loader>
    </div>
  </ng-container>
</ng-template>

<!-- Error Template -->
<ng-template #errorTemplate>
  <ng-container *ngIf="noGameUrlError">
    <div>{{ noGameUrlError }}</div>
  </ng-container>
</ng-template>
