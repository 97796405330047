<div
  class="{{config.class}}"
  [ngClass]="{
    'has-error': getShouldShowErrors(config.key, formGroup), 
    'show':(isUsedAccount && config.visibleOnRepeatPayment) || (!isUsedAccount && config.visibleOnNewPayment),
    'hide':(isUsedAccount && !config.visibleOnRepeatPayment) || (!isUsedAccount && !config.visibleOnNewPayment)
  }"
>
  {{config.name}}
</div>
