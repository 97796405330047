<app-side-menu-modal
  [customClass]="'loginModal'"
  [showBackBtn]="false"
  [sideMenuTitle]="'logincomponent.login_to_your_account'"
>
  <!-- modal body -->
  <ng-container sideMenuBody>
    <form [formGroup]="loginForm" novalidate (keyup.enter)="onLogin()">
      <div class="alert alert-danger fade-in-up clearfix" *ngIf="serverError">
        {{serverError}}
      </div>

      <div
        class="form-group clearfix"
        [ngClass]="{
          'has-error': loginForm.invalid && loginForm.touched && loginForm.controls['txtEmail'].errors, 
          'has-success': loginForm.valid && loginForm.touched && !loginForm.controls['txtEmail'].errors
        }"
      >
        <div class="controls">
          <label>{{'logincomponent.your_email' | translate}}</label>
          <input
            type="email"
            id="txtEmail"
            name="txtEmail"
            formControlName="txtEmail"
            autocomplete="off"
            placeholder="{{'logincomponent.your_email' | translate}}"
          />

          <ng-container *ngTemplateOutlet="emailErrorTemplate"></ng-container>
        </div>
      </div>

      <div
        class="form-group clearfix password-input"
        [ngClass]="{
          'has-error': loginForm.invalid && loginForm.touched && loginForm.controls['txtPassword'].errors, 
          'has-success': loginForm.valid && loginForm.touched && !loginForm.controls['txtPassword'].errors
        }"
      >
        <div class="controls">
          <label>{{'logincomponent.password' | translate}}</label>

          <input
            [type]="isPassword ? 'password' : 'text'"
            id="txtPassword"
            name="txtPassword"
            class="isPassword"
            formControlName="txtPassword"
            autocomplete="off"
            placeholder="{{'logincomponent.password' | translate}}"
            input-trimdir
          />
          <span class="icon-suffix" (click)="isPassword = !isPassword">
            <i [className]="!isPassword ? 'icon-eye-hide' : 'icon-eye'"></i>
          </span>

          <ng-container
            *ngTemplateOutlet="passwordErrorTemplate"
          ></ng-container>
        </div>
      </div>

      <button
        type="button"
        id="loginButton1"
        class="btn btn-secondary btn-round btn-md full-width m-t-24 m-b-24 form-button"
        [setLoader]="isButtonLoader"
        [disabled]="loginForm.invalid || loginForm.pending || isButtonLoader"
        aria-label="Login in"
        (click)="onLogin()"
      >
        {{'logincomponent.log_in' | translate}}
      </button>

      <ng-container *ngTemplateOutlet="forgotPasswordTemplate"></ng-container>
    </form>
  </ng-container>
</app-side-menu-modal>

<!-- 
  ------------------------------------------------------
  
  Error Templates 
  
  ------------------------------------------------------
-->
<ng-template #emailErrorTemplate>
  <div
    class="error-msg fade-in-down"
    *ngIf="
      loginForm.controls['txtEmail'].errors
        && 
      loginForm.controls['txtEmail'].touched
    "
  >
    <ng-container>
      <!-- {{ loginForm.controls['txtEmail'].errors | json }} -->
      <div [hidden]="!loginForm.controls['txtEmail'].errors.required">
        {{ "errors.error1" | translate }}
      </div>
      <div [hidden]="!loginForm.controls['txtEmail'].errors.invalidEmail">
        {{ "errors.error22" | translate }}
      </div>
      <div [hidden]="!loginForm.controls['txtEmail'].errors.cannotBeLessThan">
        {{ "errors.error61" | translate }}
      </div>
      <div [hidden]="!loginForm.controls['txtEmail'].errors.mustContainMinimum">
        {{ "errors.error23" | translate }}
      </div>
      <div
        [hidden]="!loginForm.controls['txtEmail'].errors?.invalidEmailResponse"
      >
        {{ "errors.error28" | translate }}
      </div>
      <div
        [hidden]="!loginForm.controls['txtEmail'].errors?.invalidNicknameResponse"
      >
        {{ "errors.error25" | translate }}
      </div>
      <div [hidden]="!loginForm.controls['txtEmail'].errors?.notUniqueEmail">
        {{ "errors.error29" | translate }}
      </div>
      <div [hidden]="!loginForm.controls['txtEmail'].errors?.notUniqueNickname">
        {{ "errors.error30" | translate }}
      </div>
    </ng-container>
  </div>
</ng-template>

<ng-template #passwordErrorTemplate>
  <div
    class="error-msg fade-in-down"
    *ngIf="
      loginForm.controls['txtPassword'].errors
        && 
      loginForm.controls['txtPassword'].touched
    "
  >
    <ng-container>
      <div [hidden]="!loginForm.controls['txtPassword'].errors.required">
        {{ "errors.error1" | translate }}
      </div>
    </ng-container>
  </div>
</ng-template>

<!-- 
  ------------------------------------------------------
  
  Templates 
  
  ------------------------------------------------------
-->
<!-- Forgot Password and Signup Links Template -->
<ng-template #forgotPasswordTemplate>
  <p
    class="link-text text-center cursor-hand forgot-password-link"
    (click)="onGoToForgotPage()"
  >
    {{'logincomponent.forgot_your_pwd' | translate}}
  </p>

  <p class="link-text text-center m-t-8">
    {{'logincomponent.dont_have_account' | translate}}

    <strong
      class="text-underline cursor-hand signup-link"
      (click)="onOpenRegistrationPopup()"
      >{{'logincomponent.signup' | translate}}</strong
    >
  </p>
</ng-template>
