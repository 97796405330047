<div class="newsTicker__wrap">
  <ng-container *ngIf="!isLoading && bannerContent">
    <div
      id="news-ticker"
      class="newsTicker--list fade-in"
    ></div>
  </ng-container>

  <ng-container *ngIf="isLoading">
    <div class="newsTicker--list fade-in">
      <div class="ticker-list">
        <p class="loading">
          {{ "general.loading_text" | translate }}
        </p>
      </div>
    </div>
  </ng-container>
</div>
