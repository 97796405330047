<!--
  Container to display provider list,which we got from back office
  If their are no providers configured in back office,we will display 
  an empty componenton on dom..
-->
<div
  class="game-group__container"
  *ngIf="!isLoading && providerList && providerList.length>0"
>
  <div class="game-group--title">
    <span
      class="gameGroupName-link"
      (click)="onNavigateAllGamesPage()"
    >
      {{gameGroupData.name}}
    </span>
    <span
      class="viewall-link"
      (click)="onNavigateAllGamesPage()"
    >
      {{'gamegroup.view_all'| translate }}
    </span>
  </div>

  <div class="swiper__outer hoverspace">
    <div class="swiper__inner swiper__full">
      <swiper
        class="mySwiper swiper-control"
        [config]="swiperProviders"
        (slidePrevTransitionEnd)="onSwiperPrevEvent()"
        (slideNextTransitionEnd)="onSwiperNextEvent()"
        watchSlidesProgress
      >
        <div
          class="game-card casino providers__list cursor-hand"
          *ngFor="let provider of providerList | slice:0:swiperLazyConfigurations.indexNumberOfGameTilesToshow"
          (click)="onNavigateToStudio(provider.vendorDisplayName)"
        >
          <div class="providers__wrap">
            <img
              src="{{mediaUrlPath}}/game-provider/{{provider.vendorCode}}.png{{imgixParams}}"
              alt="{{provider.vendorDisplayName}}"
              title="{{provider.vendorDisplayName}}"
            />
          </div>
        </div>
      </swiper>
    </div>
  </div>
</div>
