<div class="rewardBonusCard upcoming no-shadow" *ngIf="bonusDetails">
  <div class="rewardBonusCard__bg">
    <img src="/assets/img/rewards/bonus-img/unlock-reward.svg" />
  </div>
  <div class="rewardBonusCard__container">
    <div class="rewardBonusCard__icon">
      <img
        src="/assets/img/rewards/unlock-reward-icon.png"
        class="rewardBonusCard--chip no-shadow"
      />
    </div>

    <div class="rewardBonusCard__details no-justify-content">
      <div class="rewardBonusCard__text-inside">
        <div class="rewardBonusCard--title upcoming">
          {{'rewards.level' | translate}} {{bonusDetails.levelId + 1}}
        </div>
        <div class="rewardBonusCard--desc upcoming">
          {{'rewards.level_up' | translate}}<br />
          
          {{'rewards.to_unlock_reward' | translate}}
        </div>
      </div>
    </div>
  </div>
</div>
