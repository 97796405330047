<app-side-menu-modal
  [customClass]="'changePwd'"
  [sideMenuTitle]="'transaction.transactions'"
>
  <!-- modal body -->
  <ng-container sideMenuBody>
    <ng-container *ngTemplateOutlet="filterTemplate"></ng-container>

    <div
      [class.component-middle]="transactionHistoryList && transactionHistoryList.length === 0"
    >
      <table class="table-data m-t-44">
        <ng-container *ngTemplateOutlet="recordsTemplate"></ng-container>

        <ng-container *ngTemplateOutlet="noRecordsTemplate"></ng-container>

        <ng-container *ngTemplateOutlet="skeletonLoaderTemplate"></ng-container>
      </table>
    </div>
  </ng-container>
</app-side-menu-modal>

<!-- 
  ------------------------------------------------------
  
  Templates 
  
  ------------------------------------------------------
-->
<!-- Filter Template -->
<ng-template #filterTemplate>
  <form class="datefilter-wrap" [formGroup]="txnDetailsFrom">
    <div class="transaction-filter">
      <label class="transaction-filter--label"
        ><i class="icon-calendar"></i> {{'general.date_from' |
        translate}}</label
      >

      <ng-container [ngSwitch]="windowType">
        <ng-container *ngSwitchCase="'device'">
          <input
            type="text"
            name="startDate"
            class="transaction-filter--input"
            formControlName="startDate"
            [(min)]="minDate"
            [(max)]="maxDate"
            mbsc-calendar
            [mbsc-options]="desktopStartDate"
            placeholder="{{'general.ddmmyyyy' | translate}}"
            [readonly]="true"
          />
        </ng-container>
        <ng-container *ngSwitchDefault>
          <input
            type="text"
            name="startDate"
            class="transaction-filter--input"
            formControlName="startDate"
            [(min)]="minDate"
            [(max)]="maxDate"
            mbsc-date
            [mbsc-options]="mobileStartDate"
            placeholder="{{'general.ddmmyyyy' | translate}}"
            [readonly]="true"
          />
        </ng-container>
      </ng-container>
    </div>
    <div class="transaction-filter">
      <label class="transaction-filter--label"
        ><i class="icon-calendar"></i> {{'general.date_to' | translate}}</label
      >

      <ng-container [ngSwitch]="windowType">
        <ng-container *ngSwitchCase="'device'">
          <input
            class="transaction-filter--input"
            type="text"
            name="endDate"
            formControlName="endDate"
            [(min)]="minDate"
            [(max)]="maxDate"
            mbsc-calendar
            [mbsc-options]="desktopEndDate"
            placeholder="{{'general.ddmmyyyy' | translate}}"
          />
        </ng-container>
        <ng-container *ngSwitchDefault>
          <input
            class="transaction-filter--input"
            type="text"
            name="endDate"
            formControlName="endDate"
            [(min)]="minDate"
            [(max)]="maxDate"
            mbsc-date
            [mbsc-options]="mobileEndDate"
            placeholder="{{'general.ddmmyyyy' | translate}}"
          />
        </ng-container>
      </ng-container>
    </div>

    <div class="select-dropdown">
      <select
        formControlName="transactionTypes"
        (change)="onTransactionTypeChange()"
      >
        <ng-container *ngFor="let type of transactionTypesList; let i=index">
          <option [value]="type.transactionType"
            >{{type.transactionText}}</option
          >
        </ng-container>
      </select>
    </div>

    <button
      class="btn btn-md btn-primary full-width medium-text btn-round"
      [setLoader]="isButtonLoader"
      [disabled]="txnDetailsFrom.invalid || txnDetailsFrom.pending || isButtonLoader"
      aria-label="Show transactions"
      (click)="onFilterTransactionHistory()"
    >
      {{'transaction.show_transactions' | translate}}
    </button>
  </form>
</ng-template>

<!-- Records Template -->
<ng-template #recordsTemplate>
  <ng-container
    *ngIf="transactionHistoryList && transactionHistoryList.length > 0"
  >
    <tr
      class="row-group"
      *ngFor="let data of transactionHistoryList; let i = index"
    >
      <ng-container
        [ngSwitch]="txnDetailsFrom.controls['transactionTypes'].value"
      >
        <ng-container *ngSwitchCase="'deposit'">
          <ng-container
            *ngTemplateOutlet="depositRecordTemplate; context: { data: data}"
          ></ng-container>
        </ng-container>

        <ng-container *ngSwitchCase="'withdraw'">
          <ng-container
            *ngTemplateOutlet="withdrawalRecordTemplate;  context: { data: data}"
          ></ng-container>
        </ng-container>
      </ng-container>
    </tr>
  </ng-container>
</ng-template>

<!-- Record: Deposit Template -->
<ng-template #depositRecordTemplate let-data="data">
  <td class="txnDetails--row">
    <div class="txnDetails-date">
      {{(data.createdTime * 1000) | date:'dd/MM/yy'}} <br />
      {{(data.createdTime * 1000) | date:'HH:mm:ss'}}
    </div>

    <div class="bet-and-win">
      <h5 class="txn-id">{{data.txnId}}</h5>

      <div class="txn-dr-cr-status txn-dr-cr-status--row txn-name">
        <span class="col-txn-status">
          <ng-container [ngSwitch]="data.txnStatus">
            <ng-container *ngSwitchCase="'txn_confirmed_success'">
              {{'transactionhistory.successDespoit' | translate}}
            </ng-container>
            <ng-container *ngSwitchCase="'txn_confirmed_failure'">
              {{'transactionhistory.failedDeposit' | translate}}
            </ng-container>
            <ng-container *ngSwitchDefault>
              {{data.txnStatus}}
            </ng-container>
          </ng-container>
        </span>

        <span class="col-txn-amt"
          >{{data.initialAmount | currencyformat:currencySymbol}}</span
        >
      </div>
      <p class="txn-dr-cr-status txn-name">
        {{'transactionhistory.'+ data.option | translate}}
      </p>
    </div>
  </td>
</ng-template>

<!-- Record: Withdrawal Template -->
<ng-template #withdrawalRecordTemplate let-data="data">
  <td class="txnDetails--row">
    <div class="txnDetails-date">
      {{(data.createdTime * 1000) | date:'dd/MM/yy'}} <br />
      {{(data.createdTime * 1000) | date:'HH:mm:ss'}}
    </div>
    <div class="bet-and-win">
      <h5 class="txn-id">{{data.txnId}}</h5>
      <div class="txn-dr-cr-status txn-dr-cr-status--row txn-name">
        <span class="col-txn-status">
          <ng-container [ngSwitch]="data.txnStatus">
            <ng-container *ngSwitchCase="'co_success'">
              {{'transactionhistory.successWithdrawal' | translate}}
            </ng-container>
            <ng-container *ngSwitchCase="'co_reversed'">
              {{'transactionhistory.cancelledWithrawals' | translate}}
            </ng-container>
            <ng-container *ngSwitchCase="'co_initiated'">
              {{'transactionhistory.pendingWithdrawal' | translate}}
            </ng-container>
            <ng-container *ngSwitchCase="'co_init_failed'">
              {{'transactionhistory.failedWithdrawal' | translate}}
            </ng-container>
            <ng-container *ngSwitchDefault>
              {{data.txnStatus}}
            </ng-container>
          </ng-container>
        </span>

        <span class="col-txn-amt"
          >{{data.initialAmount | currencyformat:currencySymbol}}</span
        >
      </div>

      <p class="txn-dr-cr-status txn-name">
        {{'transactionhistory.'+ data.preferredOption | translate}}
      </p>
    </div>
  </td>
</ng-template>

<!-- No Records Template -->
<ng-template #noRecordsTemplate>
  <!--
    If their are no records..we will display this conatiner
  -->
  <ng-container
    *ngIf="transactionHistoryList && transactionHistoryList.length === 0"
  >
    <tr>
      <td colspan="3">
        <p class="no-data valign-center">
          <b>{{'transactionhistory.no_data_available' | translate}}</b>
        </p>
      </td>
    </tr>
  </ng-container>
</ng-template>

<!-- Skeleton Loader Template -->
<ng-template #skeletonLoaderTemplate>
  <!--
    loading animation conatiner while fetching records from BE
  -->
  <ng-container *ngIf="isLoading">
    <tr
      class="row-group"
      *ngFor="let items of [].constructor(4); let i = index"
    >
      <td class="txnDetails--row">
        <div
          class="txnDetails-date skeleton-loader history loading round-skelton"
        ></div>
        <div class="bet-and-win">
          <h5 class="skeleton-loader history round-skelton"></h5>
          <p class="skeleton-loader history loading round-skelton"></p>
        </div>
      </td>
    </tr>
  </ng-container>
</ng-template>
