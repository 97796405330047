/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./header.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@ngx-translate/core";
import * as i4 from "../../../game-groups/components/recent-search-games/recent-search-games.component.ngfactory";
import * as i5 from "../../../game-groups/components/recent-search-games/recent-search-games.component";
import * as i6 from "../../../multi-languages/services/translation.service";
import * as i7 from "../../pipes/games-filter.pipe";
import * as i8 from "../../services/mix-panel.service";
import * as i9 from "../../../auth/services/session.service";
import * as i10 from "../../services/common.service";
import * as i11 from "@ngrx/store";
import * as i12 from "@angular/router";
import * as i13 from "./header.component";
import * as i14 from "../../../banner/services/custom-banner.service";
import * as i15 from "../../../news/services/news-ticker.service";
import * as i16 from "../../../auth/services/affiliate.service";
import * as i17 from "../../services/utility.service";
import * as i18 from "../../../auth/services/login.service";
var styles_HeaderComponent = [i0.styles];
var RenderType_HeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HeaderComponent, data: {} });
export { RenderType_HeaderComponent as RenderType_HeaderComponent };
function View_HeaderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "header-mobile"], ["id", "mobileHeader"]], [[2, "isLP", null], [2, "isPNP", null]], null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "header-mobile__logo-row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 3, "div", [["class", "logo-m"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onNavigateToHome() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "img", [["height", "100%"], ["src", "/assets/img/logo.svg"], ["width", "100%"]], [[8, "alt", 0], [8, "title", 0]], null, null, null, null)), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_2 = "header-mobile"; var currVal_3 = (_co.isNewsTickerBannerAvailable ? "newsTickerAvailable" : "newsTickerNotAvailable"); _ck(_v, 2, 0, currVal_2, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isLandingPage; var currVal_1 = (_co.zimplerMarketsList.indexOf(_co.languageCode) >= 0); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_4 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform("general.site_name")), ""); var currVal_5 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 5, 1, i1.ɵnov(_v, 7).transform("general.site_name")), ""); _ck(_v, 5, 0, currVal_4, currVal_5); }); }
function View_HeaderComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "stickyHeader padding"], ["id", "stickyHeader"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "stickyHeader--inner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-recent-search-games", [], null, [["window", "resize"], ["window", "orientationchange"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onResize() !== false);
        ad = (pd_0 && ad);
    } if (("window:orientationchange" === en)) {
        var pd_1 = (i1.ɵnov(_v, 3).onRotate() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i4.View_RecentSearchGamesComponent_0, i4.RenderType_RecentSearchGamesComponent)), i1.ɵdid(3, 4964352, null, 0, i5.RecentSearchGamesComponent, [i6.TranslationService, i7.GamesFilterPipe, i8.MixPanelService, i9.SessionService, i10.CommonService, i11.Store, i12.Router], { callingFrom: [0, "callingFrom"] }, null)], function (_ck, _v) { var currVal_0 = "header"; _ck(_v, 3, 0, currVal_0); }, null); }
function View_HeaderComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "overlay-bg custom-overlay"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onCloseSearchResults() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, null); }
export function View_HeaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_2)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_3)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.windowType === "mobile") && !_co.isHidden); _ck(_v, 1, 0, currVal_0); var currVal_1 = (((_co.windowType === "desktop") && _co.isSearchRequired) && !_co.isHidden); _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.isShowSearchResult; _ck(_v, 5, 0, currVal_2); }, null); }
export function View_HeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-header", [], null, [["window", "resize"], ["window", "orientationchange"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onResize() !== false);
        ad = (pd_0 && ad);
    } if (("window:orientationchange" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).onRotate() !== false);
        ad = (pd_1 && ad);
    } return ad; }, View_HeaderComponent_0, RenderType_HeaderComponent)), i1.ɵdid(1, 245760, null, 0, i13.HeaderComponent, [i14.CustomBannerService, i15.NewsTickerService, i16.AffiliateService, i12.ActivatedRoute, i17.UtilityService, i9.SessionService, i10.CommonService, i18.LoginService, i11.Store, i12.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HeaderComponentNgFactory = i1.ɵccf("app-header", i13.HeaderComponent, View_HeaderComponent_Host_0, {}, {}, []);
export { HeaderComponentNgFactory as HeaderComponentNgFactory };
