<ng-container *ngIf="proofOfPaymentData">
  <ng-container
    *ngTemplateOutlet="kycInProgressTemplate"
  ></ng-container>
</ng-container>

<div class="kyc__card">
  <p class="upload-infomsg">
    {{ "confirmidentity.payment_doc-desc" | translate }}
  </p>

  <ng-container
    *ngTemplateOutlet="sizeExceededErrorTemplate"
  ></ng-container>

  <ng-container
    *ngTemplateOutlet="uploadedResponseTemplate"
  ></ng-container>

  <div
    class="upload-btn-wrapper full-width show-mobile-tablet-ipad"
  >
    <input
      type="file"
      id="proof-of-pym-camera"
      #proofOfPymCamera
      accept="image/jpg, image/jpeg,image/png,.doc,.pdf"
      capture="camera"
      (change)="onUpload($event)"
      (click)="onClearField()"
    />

    <label
      for="proof-of-pym-camera"
      class="btn btn-kyc btn-md full-width btn-round"
    >
      <i class="icon-camera"></i>
      {{ "confirmidentity.take_photo" | translate }}
    </label>
  </div>

  <div class="upload-btn-wrapper full-width">
    <input
      type="file"
      id="proof-of-pym"
      #proofOfPym
      accept="image/jpg, image/jpeg,image/png,.doc,.pdf"
      (change)="onUpload($event)"
      (click)="onClearField()"
    />

    <label
      for="proof-of-pym"
      class="btn btn-kyc btn-md full-width btn-round"
    >
      <i class="icon-doc"></i>
      {{ "confirmidentity.upload_image" | translate }}
    </label>
  </div>
</div>

<!-- 
  ------------------------------------------------------
  
  Templates 
  
  ------------------------------------------------------
-->
<!-- KYC In Progress Template -->
<ng-template #kycInProgressTemplate>
  <div class="kyc__card">
    <h5 class="sub-title">
      {{ "confirmidentity.image_proof_id" | translate }}
    </h5>

    <div
      class="upload-content-text kyc-uploaded-file m-t-16"
    >
      <span class="icon">
        <i class="icon-doc"></i>
      </span>

      <ng-container *ngIf="proofOfPaymentData">
        <span class="upload-finename"
          >{{ proofOfPaymentData.fileName }}
        </span>
      </ng-container>

      <span class="closeicon" (click)="onClearField()">
        <i class="icon-close"></i>
      </span>
    </div>

    <button
      class="btn btn-primary btn-md full-width m-t-16 fade-in btn-round"
      [setLoader]="isButtonDisabled"
      [disabled]="isButtonDisabled"
      aria-label="Submit"
      (click)="onSubmitProofOfPayment()"
    >
      {{ "confirmidentity.submit_txt" | translate }}
    </button>
  </div>
</ng-template>

<!-- Uploaded Response Template -->
<ng-template #uploadedResponseTemplate>
  <ng-container *ngIf="uploadResponseData">
    <ng-container [ngSwitch]="uploadResponseData.status">
      <ng-container *ngSwitchCase="'error'">
        <div class="m-t-16 alert alert-danger">
          {{ uploadResponseData.message }}
        </div>
      </ng-container>

      <ng-container *ngSwitchCase="'success'">
        <div class="m-t-16 alert alert-success">
          {{ uploadResponseData.message }}
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-template>

<!-- Size Exceeded Error Template -->
<ng-template #sizeExceededErrorTemplate>
  <ng-container *ngIf="sizeExceeded">
    <div class="m-t-16 alert alert-danger">
      {{ sizeExceeded }}
    </div>
  </ng-container>
</ng-template>
