export const localStorageKeys: {
  loggedInTime: string;
  languageCode: string;
  isLoggedIn: string;
  stz_user: string;
} = {
  loggedInTime: "loggedInTime",
  languageCode: "langCode",
  isLoggedIn: "isLoggedIn",
  stz_user: "stz_user",
};
