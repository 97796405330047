import { createFeatureSelector, createSelector, } from "@ngrx/store";
// Reducers
import { LanguageStateFeatureKey, } from "src/app/modules/multi-languages/store/reducers/languages.reducer";
// Select the Main State
const selectLanguagesReducerState = createFeatureSelector(LanguageStateFeatureKey);
const ɵ0 = (state) => state.language;
// Select Parts of the state
export const selectLanguage = createSelector(selectLanguagesReducerState, ɵ0);
const ɵ1 = (state) => state.languageCode;
export const selectLanguageCode = createSelector(selectLanguagesReducerState, ɵ1);
export { ɵ0, ɵ1 };
