/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./session-timer.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./session-timer.component";
import * as i3 from "../../../account/services/pay-n-play-cashier.service";
import * as i4 from "@ngrx/store";
var styles_SessionTimerComponent = [i0.styles];
var RenderType_SessionTimerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SessionTimerComponent, data: {} });
export { RenderType_SessionTimerComponent as RenderType_SessionTimerComponent };
export function View_SessionTimerComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { el: 0 }), (_l()(), i1.ɵeld(1, 0, [[1, 0], ["userSessionTime", 1]], null, 3, "div", [["class", "userSessionTimer"], ["id", "userSessionTimer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "span", [["class", "userSessionTimer_inner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "i", [["class", "icon-timer"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.counter; _ck(_v, 4, 0, currVal_0); }); }
export function View_SessionTimerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-session-timer", [], null, [[null, "touchstart"], ["window", "orientationchange"], ["window", "resize"]], function (_v, en, $event) { var ad = true; if (("touchstart" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onStart($event) !== false);
        ad = (pd_0 && ad);
    } if (("window:orientationchange" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).onRotate() !== false);
        ad = (pd_1 && ad);
    } if (("window:resize" === en)) {
        var pd_2 = (i1.ɵnov(_v, 1).onResize() !== false);
        ad = (pd_2 && ad);
    } return ad; }, View_SessionTimerComponent_0, RenderType_SessionTimerComponent)), i1.ɵdid(1, 245760, null, 0, i2.SessionTimerComponent, [i3.PayNPlayCashierService, i4.Store, i1.Renderer2], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SessionTimerComponentNgFactory = i1.ɵccf("app-session-timer", i2.SessionTimerComponent, View_SessionTimerComponent_Host_0, {}, {}, []);
export { SessionTimerComponentNgFactory as SessionTimerComponentNgFactory };
