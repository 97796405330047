<ng-container *ngIf="rewardSize === 'basic'">
  <div
    class="rewardBonusCard"
    [class.hostedCashier]="callingFrom === 'hostedCashier'"
    *ngIf="bonusDetails"
    (click)="(callingFrom === 'all-rewards' || callingFrom === 'pnp-cashier') ? onOpenDepositView(bonusDetails) : onSelectBonus(bonusDetails)"
  >
    <div class="rewardBonusCard__bg">
      <img
        src="/assets/img/rewards/bonus-img/shotz-rewards.png"
        alt="bonus card bg"
      />
    </div>
    <div class="rewardBonusCard__container">
      <div class="rewardBonusCard__icon">
        <ng-container
          *ngIf="bonusDetails?.amountType === 'PCT' && !isRealUser && bonusDetails?.bonusCode"
        >
          <img
            *ngIf="bonusDetails?.bonusCode.toLowerCase().startsWith('welcomeoffer_casino')"
            src="/assets/img/rewards/bonus-img/welcome-bonus-deposit-restrict.png"
            class="rewardBonusCardImg"
            alt="welcome-bonus-deposit-restrict"
          />
          <img
            *ngIf="bonusDetails?.bonusCode.toLowerCase().startsWith('welcomeoffer_live')"
            src="/assets/img/rewards/bonus-img/welcome-bonus-release-restrict.png"
            class="rewardBonusCardImg"
            alt="welcome-bonus-release-restrict"
          />
          <img
            *ngIf="!bonusDetails?.bonusCode.toLowerCase().startsWith('welcomeoffer_casino') && 
                    !bonusDetails?.bonusCode.toLowerCase().startsWith('welcomeoffer_live')"
            src="/assets/img/rewards/bonus-img/welcome-bonus-deposit-restrict.png"
            class="rewardBonusCardImg"
            alt="welcome-bonus-release-restrict"
          />
        </ng-container>
        <ng-container *ngIf="bonusDetails?.amountType === 'PCT' && isRealUser">
          <img
            src="/assets/img/rewards/bonus-img/reload-bonus.png"
            class="rewardBonusCardImg"
            alt="reload-bonus"
          />
        </ng-container>
        <ng-container *ngIf="bonusDetails?.amountType === 'ABS'">
          <img
            src="/assets/img/rewards/bonus-img/deposit-bonus.png"
            class="rewardBonusCardImg"
            alt="deposit-bonus"
          />
        </ng-container>
        <div
          class="countdown-timer"
          *ngIf="bonusDetails?.campaignEndDate || bonusDetails?.targetValidityDate"
        >
          <app-countdown-timer
            [expiryDate]="bonusDetails?.targetValidityDate ? (bonusDetails?.targetValidityDate * 1000) : bonusDetails?.campaignEndDate"
          ></app-countdown-timer>
        </div>
      </div>
      <div class="rewardBonusCard__details">
        <ng-container *ngIf="bonusDetails?.currencyTier">
          <ng-container *ngIf="bonusDetails?.amountType === 'PCT'">
            <div class="rewardBonusCard__text-inside">
              <div class="rewardBonusCard--title">
                <ng-container *ngIf="!isRealUser && bonusDetails?.bonusCode">
                  <ng-container
                    *ngIf="bonusDetails?.bonusCode.toLowerCase().startsWith('welcomeoffer_casino')"
                  >
                    {{'rewards.welcome_bonus' | translate}}</ng-container
                  >

                  <ng-container
                    *ngIf="bonusDetails?.bonusCode.toLowerCase().startsWith('welcomeoffer_live')"
                  >
                    {{'rewards.live_casino_offer' | translate}}</ng-container
                  >

                  <ng-container
                    *ngIf="!bonusDetails?.bonusCode.toLowerCase().startsWith('welcomeoffer_casino') && 
                           !bonusDetails?.bonusCode.toLowerCase().startsWith('welcomeoffer_live')"
                  >
                    {{'rewards.welcome_bonus' | translate}}</ng-container
                  >
                </ng-container>

                <ng-container *ngIf="isRealUser"
                  >{{'rewards.reload_bonus'| translate}}</ng-container
                >
              </div>

              <div class="rewardBonusCard--desc">
                {{'rewards.pertge_upto_maxoffer'| translate:({
                'percentageValue':(bonusDetails?.currencyTier[currencyCode][0].percentageValue),
                'maxOfferValue':(((bonusDetails?.currencyTier[currencyCode][0].maxOfferValue)
                / 100) | currencyformat:currencySymbol:false) })}}
                <br />

                <ng-container
                  *ngIf="bonusDetails.currencyTier[currencyCode][0].freespinCountOrPct && isRealUser"
                >
                  {{'rewards.free_spin_cnt' | translate: ({'freespinCountOrPct':
                  (bonusDetails?.currencyTier[currencyCode][0].freespinCountOrPct)}
                  )}}
                </ng-container>

                <ng-container *ngIf="!isRealUser">
                  {{'rewards.200_free_spin' | translate}}
                </ng-container>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="bonusDetails?.amountType === 'ABS'">
            <div class="rewardBonusCard__text-inside">
              <!-- For only japanese lang we are displaying game name  -->
              <div class="rewardBonusCard--title">
                {{'rewards.dep_some_get_more' | translate:({'game':
                bonusDetails?.gameData ? bonusDetails?.gameData.name :
                'rewards.dep_some_get_more_txt' })}}
              </div>

              <div class="rewardBonusCard--desc">
                {{'rewards.deposit_mindep_or_more' | translate:({
                'minDepositValue':
                (((bonusDetails?.currencyTier[currencyCode][0].minDepositValue)
                / 100) | currencyformat:currencySymbol:false)}) }}
                <br />

                <ng-container
                  *ngIf="!bonusDetails?.currencyTier[currencyCode][0].freespinCountOrPct"
                >
                  {{'rewards.get_maxoffer_bonus_money' | translate:
                  ({'maxOfferValue':(((bonusDetails?.currencyTier[currencyCode][0].maxOfferValue)
                  / 100) | currencyformat:currencySymbol:false)})}}
                </ng-container>

                <ng-container
                  *ngIf="bonusDetails?.currencyTier[currencyCode][0].freespinCountOrPct"
                >
                  {{'rewards.get_spincnt_freespins_value'| translate:( {
                  'freespinCount':(bonusDetails?.currencyTier[currencyCode][0].freespinCountOrPct),
                  'freeSpinValue':
                  (((bonusDetails?.currencyTier[currencyCode][0].freeSpinValue)/100)
                  | currencyformat:currencySymbol:false) }) }}
                </ng-container>
              </div>
            </div>
          </ng-container>

          <button
            aria-label="Claim offer"
            class="btn btn-primary btn-round btn-xs"
            *ngIf="callingFrom === 'all-rewards' || callingFrom === 'pnp-cashier'"
          >
            {{ 'rewards.claim_offer' | translate }}
          </button>
          <div
            class="payment-bonus-swich"
            *ngIf="callingFrom === 'hostedCashier'"
          >
            <div
              class="toggleSwitch m-r-8"
              [class.active]="selectedBonus && selectedBonus?.bonusCode === bonusDetails?.bonusCode"
            ></div>
            <span class="bonus-status">
              <ng-container
                *ngIf="!(selectedBonus && selectedBonus?.bonusCode === bonusDetails?.bonusCode)"
              >
                {{'rewards.add_bonus' | translate}}
              </ng-container>
              <ng-container
                *ngIf="(selectedBonus && selectedBonus?.bonusCode === bonusDetails?.bonusCode)"
              >
                {{'rewards.bonus_added' | translate}}
              </ng-container>
            </span>
          </div>
        </ng-container>

        <button aria-label="Terms" class="btn btn-info">
          <i class="icon-info" (click)="onOpenRewardTCPopUp($event)"></i>
        </button>
      </div>

      <div
        class="game-img"
        *ngIf="(bonusDetails?.currencyTier[currencyCode][0].freespinCountOrPct && 
                bonusDetails?.gameIds && bonusDetails?.gameIds.length > 0) && isRealUser"
      >
        <img
          [src]="gameUrlPath + '/' + bonusDetails?.gameIds[0] + '/' + bonusDetails?.gameIds[0] + '_casino.jpg'"
          alt="{{bonusDetails?.gameData ? bonusDetails?.gameData.name : 'game-img'}}"
        />
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="rewardSize === 'shorter'">
  <div
    class="pnp-short-bonus"
    *ngIf="bonusDetails"
    (click)="onSelectBonus(bonusDetails)"
  >
    <ng-container *ngIf="bonusDetails?.currencyTier">
      <div class="bonus-card--info">
        <ng-container *ngIf="bonusDetails?.amountType === 'PCT'">
          {{'rewards.pertge_upto_maxoffer'| translate:({
          'percentageValue':(bonusDetails?.currencyTier[currencyCode][0].percentageValue),
          'maxOfferValue':(((bonusDetails?.currencyTier[currencyCode][0].maxOfferValue)/100)
          | currencyformat:currencySymbol:false) })}}

          <br />

          <ng-container
            *ngIf="bonusDetails?.currencyTier[currencyCode][0]?.freespinCountOrPct && isRealUser"
          >
            {{'rewards.free_spin_cnt' | translate:
            ({'freespinCountOrPct':(bonusDetails?.currencyTier[currencyCode][0]?.freespinCountOrPct)})}}
          </ng-container>

          <ng-container *ngIf="!isRealUser">
            {{'rewards.200_free_spin' | translate}}
          </ng-container>
        </ng-container>

        <ng-container *ngIf="bonusDetails?.amountType === 'ABS'">
          {{'rewards.deposit_mindep_or_more' | translate:({'minDepositValue':
          (((bonusDetails?.currencyTier[currencyCode][0].minDepositValue)/100) |
          currencyformat:currencySymbol:false)}) }}
          <br />

          <ng-container
            *ngIf="!bonusDetails?.currencyTier[currencyCode][0].freespinCountOrPct"
          >
            {{'rewards.get_maxoffer_bonus_money' | translate:
            ({'maxOfferValue': (((bonusDetails?.currencyTier[currencyCode][0]?.maxOfferValue) / 100)
            | currencyformat:currencySymbol:false)})}}
          </ng-container>

          <ng-container
            *ngIf="bonusDetails?.currencyTier[currencyCode][0].freespinCountOrPct"
          >
            {{'rewards.get_spincnt_freespins_value'| translate:( {
            'freespinCount':(bonusDetails?.currencyTier[currencyCode][0]?.freespinCountOrPct),
            'freeSpinValue':
            (((bonusDetails?.currencyTier[currencyCode][0]?.freeSpinValue)/100)
            | currencyformat:currencySymbol:false) }) }}
          </ng-container>
        </ng-container>
      </div>

      <div class="choose-bonuse">
        <ng-container
          *ngIf="!(selectedBonus && selectedBonus?.bonusCode === bonusDetails?.bonusCode)"
        >
          <i class="icon-checkbox"></i>{{'rewards.yes_add_bonus' | translate}}
        </ng-container>
        <ng-container
          *ngIf="(selectedBonus && selectedBonus?.bonusCode === bonusDetails?.bonusCode)"
        >
          <i class="icon-checkbox-checked checked"></i>{{'rewards.bonus_added' |
          translate}}
        </ng-container>
      </div>

      <div
        class="bonus-card--expiry"
        *ngIf="(bonusDetails?.campaignEndDate || bonusDetails?.targetValidityDate) && (callingFrom === 'pnp-cashier' && rewardSize === 'shorter')"
      >
        <app-countdown-timer
          [expiryDate]="bonusDetails?.targetValidityDate ? (bonusDetails?.targetValidityDate * 1000) : bonusDetails?.campaignEndDate"
        >
        </app-countdown-timer>
      </div>
      <button aria-label="Terms" class="bonus-card--terms">
        <i class="icon-info" (click)="onOpenRewardTCPopUp($event)"></i>
      </button>
    </ng-container>
  </div>
</ng-container>
