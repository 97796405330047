import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { Store } from "@ngrx/store";
import {
  ViewEncapsulation,
  HostListener,
  ElementRef,
  Component,
  ViewChild,
} from "@angular/core";

// Configurations
import { localStorageKeys } from "src/app/modules/multi-languages/configurations/localstorage-keys.configurations";
import {
  excludeLanguageOptionsConfigurations,
  localToCmsLanguageConfigurations,
} from "src/app/configurations/main.configurations";

// Environments
import { environment } from "src/environments/environment";

// Models
import { CountryBlockResponse } from "src/app/modules/country-block/models/country-block-response.model";
import { LocalDisplayNameList } from "src/app/modules/static-pages/models/local-display-name.model";
import { StaticPageRequest } from "src/app/modules/static-pages/models/static-page-request.model";
import { ParamList } from "src/app/modules/static-pages/models/parameter-list.model";
import { Footer } from "src/app/modules/shared/models/footer/footer.model";

// Reducers
import { AppState } from "src/app/store/reducers";

// Selectors
import { selectCountryBlock } from "src/app/modules/country-block/store/selectors/country-block.selectors";
import { selectLanguageCode } from "src/app/modules/multi-languages/store/selectors/languages.selectors";
import {
  selectAuthLoginIsLoggedOut,
  selectAuthLoginIsLoggedIn,
} from "src/app/modules/auth/store/selectors/auth.selectors";

// Services
import { MultiLanguageService } from "src/app/modules/multi-languages/services/multi-language.service";
import { TranslationService } from "src/app/modules/multi-languages/services/translation.service";
import { StaticPageService } from "src/app/modules/static-pages/services/static-page.service";
import { LiveChatService } from "src/app/modules/chat/services/live-chat.service";
import { UtilityService } from "src/app/modules/shared/services/utility.service";
import { SessionService } from "src/app/modules/auth/services/session.service";

// Utilities
import { supportedMarketsList } from "src/app/modules/multi-languages/utilities/languages.utilities";
import { replaceAllUtility } from "src/app/modules/shared/utilities/replace-all.utilities";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class FooterComponent {
  // View Childs
  @ViewChild("footerConatiner", { static: false }) footerConatiner: ElementRef;

  // Strings
  staticContent: string = "";
  languageCode: string = "";
  countryCode: string = "";

  // Booleans
  isShowLanguageSelectorEnabled: boolean = false;
  isLanguageOpen: boolean = false;
  isLoggedIn: boolean = false;

  // Arrays
  availableLanguagesList: string[] = [];
  excludedLangaugesList: string[] = [];
  acceptedLanguagesList: string[] = [];

  // Others
  countryBlockResponse: CountryBlockResponse;
  localeDisplayName: LocalDisplayNameList = {
    "en-row": "English",
    "nb-no": "Norwegian",
  };

  // Subscriptions
  footerSubscription: Subscription;

  subscriptions: Subscription[] = [];

  constructor(
    private multiLanguageService: MultiLanguageService,
    private translationService: TranslationService,
    private staticPageService: StaticPageService,
    private liveChatService: LiveChatService,
    private utilityService: UtilityService,
    private sessionService: SessionService,
    private store: Store<AppState>,
    private router: Router
  ) {
    this.onLoad();
  }

  // -----------------------------------------------------------------
  // Lifecycle Hooks
  ngOnInit(): void {
    if (environment.features.hungarian) {
      this.localeDisplayName = {
        ...this.localeDisplayName,
        "hu-hu": "Hungarian",
      };
    }

    this.excludedLangaugesList = excludeLanguageOptionsConfigurations;

    this.onFilterPreferredLanguages();

    this.isLoggedIn = this.sessionService.getIsUserLoggedIn();

    this.languageCode = this.multiLanguageService.getLanguageCode();

    this.subscriptions = [
      this.store
        .select(selectAuthLoginIsLoggedIn)
        .subscribe((isLoggedIn: boolean) => {
          this.isLoggedIn = isLoggedIn;
        }),
      this.store
        .select(selectAuthLoginIsLoggedOut)
        .subscribe((isLoggedOut: boolean) => {
          if (isLoggedOut) {
            this.isLoggedIn = false;
          }
        }),
      this.store
        .select(selectLanguageCode)
        .subscribe((languageCode: string) => {
          if (this.languageCode !== languageCode) {
            this.onLoadFooterContent(true);
          }
          this.languageCode = languageCode;
        }),
      this.store
        .select(selectCountryBlock)
        .subscribe((countryBlockResponse: CountryBlockResponse) => {
          this.countryBlockResponse = countryBlockResponse;

          if (this.countryBlockResponse) {
            this.onLoadFooterContent(true);
          }
        }),
    ];

    this.availableLanguagesList = this.translationService.getLanguages();

    this.onLoadFooterContent();
  }

  // -----------------------------------------------------------------
  // Host Listeners
  @HostListener("document:click", ["$event"])
  @HostListener("document:touchend", ["$event"])
  onClick(event: Event): void {
    if (
      (<HTMLInputElement>event.target).classList.contains(
        "overlay-backdrop--footer"
      )
    ) {
      this.isLanguageOpen = false;
    }
  }

  @HostListener("window:popstate", ["$event"])
  onBrowserBackBtnClose(event: Event): void {
    let languageFromUrl: string = window.location.pathname.split("/")[1];

    if (event.type === "popstate" && languageFromUrl !== this.languageCode) {
      this.onChangeLanguageHandler(languageFromUrl);
    }
  }

  // -----------------------------------------------------------------
  // Set Methods
  onLoad(): void {
    this.isShowLanguageSelectorEnabled = environment.features.showLanguageSelector;

    if (!environment.features.shotzLanguages) {
      this.localeDisplayName = {
        ...this.localeDisplayName,
        "de-eu": "Austria",
        "en-ca": "Canada",
        "fi-fi": "Finland",
        "en-nz": "New Zealand",
        "ja-jp": "Japanese",
        "en-ie": "Ireland",
      };
    }
  }

  /*
    Here we remove Netherland & german option for locale drowdrop
    as per client requirment,but user can use it from url directly
    just we don't display in locale downdrop.
  */
  onFilterPreferredLanguages(): void {
    this.acceptedLanguagesList = supportedMarketsList();

    this.excludedLangaugesList.forEach((languageCode: string) => {
      if (this.acceptedLanguagesList.indexOf(languageCode) > -1) {
        this.acceptedLanguagesList.splice(
          this.acceptedLanguagesList.indexOf(languageCode),
          1
        );
      }
    });
  }

  onLoadFooterContent(isForce?: boolean): void {
    let urlPath: string = "footer";

    let staticRequest: StaticPageRequest = {
      urlPath,
      isForce,
    };

    this.footerSubscription = this.staticPageService
      .onGetStaticContent<StaticPageRequest, Footer>(staticRequest)
      .subscribe((footerResponse: Footer) => {
        if (
          footerResponse &&
          Object.keys(footerResponse).length > 0 &&
          !footerResponse.errorCode &&
          footerResponse.content
        ) {
          this.staticContent = replaceAllUtility(footerResponse.content, {
            "{{site_url}}": environment.siteUrl,
            "{{media_url}}": environment.mediaUrl,
          });
        }
      });
  }

  onDynamicFooterContent(event: MouseEvent): void {
    const routerLink: string = (event.target as HTMLDivElement).getAttribute(
      "routerLink"
    );

    if (routerLink && routerLink.length > 0) {
      this.utilityService.onCmsRoutingHandler(routerLink);
    }
  }

  onChangeLanguageHandler(languageCode: string): void {
    this.languageCode = languageCode;

    this.translationService.onUseLanguage(
      localToCmsLanguageConfigurations[this.languageCode]
    );

    localStorage.setItem(localStorageKeys.languageCode, languageCode);

    this.onReloadPage();
  }

  onReloadPage(): void {
    const params: ParamList = this.utilityService.getQueryParams();

    this.onNavigateToLandingPage(params);

    setTimeout((_) => {
      window.location.reload();
    });
  }

  onNavigateToLandingPage(params: ParamList = {}): void {
    if (
      this.sessionService.getIsUserLoggedIn() ||
      this.languageCode === "fi-fi"
    ) {
      this.router.navigate([`${this.languageCode}/casino`], {
        queryParams: params,
      });
    } else {
      this.router.navigate([this.languageCode], { queryParams: params });
    }
  }

  onInitializeLiveChat(): void {
    this.liveChatService.onInitializeChat();
  }

  // -----------------------------------------------------------------
  // On Destroy
  ngOnDestroy(): void {
    if (this.footerSubscription) this.footerSubscription.unsubscribe();

    this.subscriptions.forEach((subscription: Subscription) =>
      subscription.unsubscribe()
    );
  }
}
