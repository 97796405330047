<div
  id="promoRaceCounter"
  class="cb-countdown"
  #promoRaceCounter
  *ngIf="isOptnCounter"
>
  <img
    src="./assets/img/rewards/bonus-img/cashback.png"
    class="cb-countdown--img"
    alt="CashBack"
  />
  <span class="cb-countdown--timer">
    <app-countdown-timer
      [expiryDate]="userOptinDuration"
      [callingFrom]="'promoCounter'"
      [promoRaceId]="campaignDetails.promoRaceId"
    ></app-countdown-timer>
    <span class="cb-countdown--timer-txt"
      >{{'rewards.cashback' | translate}}</span
    >
    <button aria-label="Info" class="btn btn-info">
      <i class="icon-info" (click)="onOpenRewardTCPopUp()"></i>
    </button>
  </span>
</div>
