import { Subject, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { Store } from "@ngrx/store";
import {
  HostListener,
  ElementRef,
  Component,
  Renderer2,
  ViewChild,
} from "@angular/core";

// Enums
import { StatusResponse } from "src/app/models/api/status.response";

// Models
import { PlayerPromoProgressRequest } from "src/app/modules/rewards/models/player-promo-progress/player-promo-progress-request.model";
import {
  PlayerPromoProgressResponse,
  PlayerPromoProgressItem,
} from "src/app/modules/rewards/models/player-promo-progress/player-promo-progress-response.model";
import {
  CampaignDetails,
  CampaignType,
  UserCampaign,
} from "src/app/modules/rewards/models/user-campaign.model";

// Pipes
import { CurrencyFormatPipe } from "src/app/modules/shared/pipes/currency-format.pipe";

// Reducers
import { AppState } from "src/app/store/reducers";

// Selectors
import { selectAuthLoginIsLoggedOut } from "src/app/modules/auth/store/selectors/auth.selectors";

// Services
import { TranslationService } from "src/app/modules/multi-languages/services/translation.service";
import { CashbackPromoService } from "src/app/modules/rewards/services/cashback-promo.service";
import { UserDetailsService } from "src/app/modules/user/services/user-details.service";
import { EmitterService } from "src/app/modules/shared/services/emitter.service";

@Component({
  selector: "app-promo-race-counter",
  templateUrl: "./promo-race-counter.component.html",
  styleUrls: ["./promo-race-counter.component.scss"],
})
export class PromoRaceCounterComponent {
  // View Child
  @ViewChild("promoRaceCounter", { static: false }) element: ElementRef;

  // Numbers
  userOptinDuration: number = 0;

  // Strings
  userEligibleOffer: string = "";
  currencySymbol: string = "";

  // Booleans
  isOptnCounter: boolean = false;

  // Enums
  windowType: "device" | "mobile" = "device";

  // Arrays
  campaignDetailsList: CampaignDetails[] = [];

  // Objects
  campaignDetails: CampaignDetails;

  // Subjects
  destroy$: Subject<boolean> = new Subject<boolean>();

  // Subscriptions
  playerPromoProgressSubscription: Subscription;
  userCampaignsSubscription: Subscription;

  subscriptions: Subscription[] = [];

  constructor(
    private cashbackPromoService: CashbackPromoService,
    private userDetailsService: UserDetailsService,
    private currencyFormatPipe: CurrencyFormatPipe,
    private translationService: TranslationService,
    private emitterService: EmitterService,
    private store: Store<AppState>,
    private render: Renderer2
  ) {}

  // -----------------------------------------------------------------
  // Lifecycle Hooks
  ngOnInit(): void {
    this.onGetUserCampaignDetails();

    this.subscriptions = [
      this.cashbackPromoService.promoCashbackOptnSuccessSubject$
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          this.onGetUserCampaignDetails();
        }),
      this.cashbackPromoService.stopCashbackPromoSubject$
        .pipe(takeUntil(this.destroy$))
        .subscribe((promoRaceId: string) => {
          this.isOptnCounter = false;

          this.onCashbackOfferAmount(promoRaceId);
        }),
      this.userDetailsService.currencySymbolBehaviourSubject$
        .pipe(takeUntil(this.destroy$))
        .subscribe((currencySymbol: string) => {
          this.currencySymbol = currencySymbol;
        }),
      this.store
        .select(selectAuthLoginIsLoggedOut)
        .subscribe((isLoggedOut: boolean) => {
          if (isLoggedOut) {
            this.isOptnCounter = false;
          }
        }),
      this.emitterService.isSuccessfulDepositSubject$
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          this.onGetUserCampaignDetails();
        }),
    ];
  }

  // -----------------------------------------------------------------
  // Host Listeners
  @HostListener("touchstart", ["$event"])
  onStart(event: MouseEvent): void {
    if (event) {
      this.onInitializeDragNDrop();
    }
  }

  @HostListener("window:orientationchange")
  onRotate(): void {
    if (this.element && this.element.nativeElement) {
      this.onSetPromoRaceCounterOrientation();
    }

    this.getWindowType();
  }

  @HostListener("window:resize")
  onResize(): void {
    this.getWindowType();
  }

  // -----------------------------------------------------------------
  // Window Type
  getWindowType(): void {
    let clientWidth: number = document.body.clientWidth;

    if (clientWidth >= 1024) {
      this.windowType = "device";
    } else {
      this.windowType = "mobile";
    }
  }

  // -----------------------------------------------------------------
  // Set Methods
  onInitializeDragNDrop(): void {
    if (this.windowType === "mobile") {
      let promoRaceCounter: HTMLElement =
        document.getElementById("promoRaceCounter");

      let timerWidth: number = promoRaceCounter.clientWidth;

      let timerHeight: number = promoRaceCounter.clientHeight;

      if (promoRaceCounter) {
        promoRaceCounter.addEventListener(
          "touchmove",
          (touchEvent: TouchEvent) => {
            touchEvent.preventDefault();

            let innerWidth: number = window.innerWidth;

            let innerHeight: number = window.innerHeight;

            let touchLocation: Touch = touchEvent.targetTouches[0];

            if (
              touchLocation.clientX > 0 &&
              touchLocation.clientX < innerWidth - timerWidth
            ) {
              promoRaceCounter.style.left = `${touchLocation.clientX}px`;
            }

            if (
              touchLocation.clientY > 0 &&
              touchLocation.clientY < innerHeight - timerHeight
            ) {
              promoRaceCounter.style.top = `${touchLocation.clientY}px`;
            }
          },
          false
        );
      }
    }
  }

  onSetPromoRaceCounterOrientation(): void {
    if (window.matchMedia("(orientation: landscape)").matches) {
      this.render.setStyle(this.element.nativeElement, "left", `${16}px`);

      this.render.setStyle(this.element.nativeElement, "bottom", `${64}px`);

      this.render.setStyle(this.element.nativeElement, "top", "unset");
    } else {
      this.render.setStyle(this.element.nativeElement, "top", `${16}px`);

      this.render.setStyle(this.element.nativeElement, "left", `${64}px`);
    }
  }

  onCashbackOfferAmount(campaignActivityId: string): void {
    let playerPromoProgressRequest: PlayerPromoProgressRequest = {
      campaignActivityId: campaignActivityId,
    };

    this.playerPromoProgressSubscription = this.cashbackPromoService
      .onGetPlayerPromoProgress(playerPromoProgressRequest)
      .pipe(takeUntil(this.destroy$))
      .subscribe((playerPromoProgressResponse: PlayerPromoProgressResponse) => {
        if (
          playerPromoProgressResponse &&
          playerPromoProgressResponse.promoProgressList &&
          playerPromoProgressResponse.promoProgressList.length > 0
        ) {
          let playerPromoProgressItem: PlayerPromoProgressItem =
            playerPromoProgressResponse.promoProgressList[0];

          let awardValue: string = "";

          let awardType: string = "";

          if (
            playerPromoProgressItem &&
            playerPromoProgressItem.eligibleAward
          ) {
            this.userEligibleOffer = playerPromoProgressItem.eligibleAward;

            awardValue = parseFloat(
              this.userEligibleOffer.replace(/[^0-9.]/g, "")
            ).toFixed(2);

            if (this.userEligibleOffer.indexOf("Bonus") > -1) {
              awardType = this.translationService.get("rewards.bonus");
            } else if (this.userEligibleOffer.indexOf("Cash") > -1) {
              awardType = this.translationService.get("rewards.cash");
            }

            if (!isNaN(+awardValue) && awardType) {
              this.userEligibleOffer = `${this.currencyFormatPipe.transform(
                awardValue,
                this.currencySymbol
              )} ${awardType}`;
            }

            this.cashbackPromoService.onBroadcastCashbackOfferAmount(
              this.userEligibleOffer
            );
          }
        }
      });
  }

  onGetUserCampaignDetails(): void {
    this.userCampaignsSubscription = this.cashbackPromoService
      .onGetUserCampaigns()
      .pipe(takeUntil(this.destroy$))
      .subscribe((userCampaign: UserCampaign) => {
        if (
          userCampaign &&
          userCampaign.campaignDetails &&
          userCampaign.status === StatusResponse.SUCCESS
        ) {
          this.campaignDetailsList = userCampaign.campaignDetails;

          this.campaignDetailsList = this.campaignDetailsList.filter(
            (campaignDetailsItem: CampaignDetails) => {
              let userLastAuthTime: Date;

              let promoStartTime: Date = new Date(
                campaignDetailsItem.campaignStartDate
              );

              let promoOfferExpiryDate: Date = new Date(
                campaignDetailsItem.campaignEndDate
              );

              let currentTime: Date = new Date();

              if (this.cashbackPromoService.getUserLastAuthTime()) {
                userLastAuthTime = new Date(
                  this.cashbackPromoService.getUserLastAuthTime()
                );
              }

              if (
                campaignDetailsItem &&
                campaignDetailsItem.campaignType ===
                  CampaignType.CASHBACK_PROMO &&
                ((campaignDetailsItem.playerOptinTime &&
                  !campaignDetailsItem.depositRequired) ||
                  !campaignDetailsItem.optInRequired ||
                  (campaignDetailsItem.depositRequired &&
                    campaignDetailsItem.playerOptinTime &&
                    campaignDetailsItem.minDepositDone)) &&
                currentTime >= promoStartTime &&
                currentTime < promoOfferExpiryDate
              ) {
                if (
                  !campaignDetailsItem.optInRequired &&
                  userLastAuthTime &&
                  userLastAuthTime < promoStartTime
                ) {
                  this.cashbackPromoService.onBroadcastCashbackStartedPromoPopup(
                    campaignDetailsItem
                  );

                  this.cashbackPromoService.onSetUserLastAuthTime(undefined);
                }

                return campaignDetailsItem;
              }
            }
          );

          if (this.campaignDetailsList && this.campaignDetailsList.length > 0) {
            this.campaignDetails = this.campaignDetailsList[0];

            this.onSetPromoRaceCounter(this.campaignDetails);
          } else {
            this.isOptnCounter = false;
          }
        } else {
          this.isOptnCounter = false;
        }
      });
  }

  onSetPromoRaceCounter(campaignDetails: CampaignDetails): void {
    if (campaignDetails && campaignDetails.campaignEndDate) {
      let currentDate: Date = new Date();

      let futureDate: Date = new Date(campaignDetails.campaignEndDate);

      if (currentDate < futureDate) {
        this.cashbackPromoService.onSetCampaignDetails(campaignDetails);

        this.userOptinDuration = campaignDetails.campaignEndDate;

        this.isOptnCounter = true;
      } else {
        this.isOptnCounter = false;
      }
    }
  }

  onOpenRewardTCPopUp(): void {
    let campaignDetails: CampaignDetails =
      this.cashbackPromoService.getCampaignDetails();

    if (campaignDetails) {
      this.cashbackPromoService.onBroadcastCashbackStartedPromoPopup(
        campaignDetails
      );
    }
  }

  // -----------------------------------------------------------------
  // On Destroy
  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();

    if (this.playerPromoProgressSubscription)
      this.playerPromoProgressSubscription.unsubscribe();

    if (this.userCampaignsSubscription)
      this.userCampaignsSubscription.unsubscribe();

    this.subscriptions.forEach((subscription: Subscription) =>
      subscription.unsubscribe()
    );
  }
}
