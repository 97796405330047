import { Subject, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { Component } from "@angular/core";
import { Store } from "@ngrx/store";

// Models
import { ActiveTab } from "src/app/modules/shared/models/active-tab.model";

// Reducers
import { AppState } from "src/app/store/reducers";

// Selectors
import {
  selectAuthLoginIsLoggedOut,
  selectAuthLoginIsLoggedIn,
} from "src/app/modules/auth/store/selectors/auth.selectors";

// Services
import { EmitterService } from "src/app/modules/shared/services/emitter.service";
import { UtilityService } from "src/app/modules/shared/services/utility.service";

@Component({
  selector: "app-limits-regulatory",
  templateUrl: "./limits-regulatory.component.html",
  styleUrls: ["./limits-regulatory.component.scss"],
})
export class LimitsRegulatoryComponent {
  // Booleans
  isOpenLimitsRegulatoryPopup: boolean = false;
  isLoggedIn: boolean = false;

  // Subjects
  destroy$: Subject<boolean> = new Subject<boolean>();

  // Subscriptions
  subscriptions: Subscription[] = [];

  constructor(
    private emitterService: EmitterService,
    private utilityService: UtilityService,
    private store: Store<AppState>
  ) {}

  // -----------------------------------------------------------------
  // Lifecycle Hooks
  ngOnInit(): void {
    this.subscriptions = [
      this.store
        .select(selectAuthLoginIsLoggedIn)
        .subscribe((isLoggedIn: boolean) => {
          this.isLoggedIn = isLoggedIn;
        }),
      this.store
        .select(selectAuthLoginIsLoggedOut)
        .subscribe((isLoggedOut: boolean) => {
          if (isLoggedOut) {
            this.isLoggedIn = false;
          }
        }),
      this.emitterService.isOpenLimitsRegulatoryPopupSubject$
        .pipe(takeUntil(this.destroy$))
        .subscribe((isOpenLimitsRegulatoryPopup: boolean) => {
          this.isOpenLimitsRegulatoryPopup = isOpenLimitsRegulatoryPopup;
        }),
    ];
  }

  // -----------------------------------------------------------------
  // Set Methods
  onNavigateToLimits(): void {
    const activeTab: ActiveTab = {
      tabName: "limits",
      showBackButton: true,
    };

    this.utilityService.openAccountComponent(activeTab);

    this.onCloseRegulatoryPopup();
  }

  onCloseRegulatoryPopup(): void {
    this.isOpenLimitsRegulatoryPopup = false;
  }

  // -----------------------------------------------------------------
  // On Destroy
  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();

    this.subscriptions.forEach((subscription: Subscription) =>
      subscription.unsubscribe()
    );
  }
}
