import { WorldCurrencyCode } from "src/app/models/configurations/enums/localization/world-currencies.enum";
import { WorldCountryCode } from "src/app/models/configurations/enums/localization/world-countries.enum";
import { StatusResponse } from "src/app/models/api/status.response";

export interface ZimplerPayAndPlayResponse {
  amount: number;
  bonusCode?: string;
  channelId: string;
  countryCode: WorldCountryCode;
  currencyCode: WorldCurrencyCode;
  depositFee: number;
  failureURL?: string;
  ip: string;
  language: ZimplerLanguage;
  orderId: string;
  redirectURL?: string;
  successURL?: string;
  txnId: number;
  txnStatus: string;
  txnType: string;
  uuid: string;
  siteDisplayName: string;
  site: string;
  processorName: string;
  status: StatusResponse;
  errorCode: string | null;
  errorDescription: string | null;
}

export enum ZimplerLanguage {
  "en_CA" = "en_CA",
  "en_NZ" = "en_NZ",
  "fi_FI" = "fi_FI",
  "no_NO" = "no_NO",
  "de_AT" = "de_AT",
  "en_US" = "en_US",
  "en_IE" = "en_IE",
  "hu_HU" = "hu_HU",
}
