/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./live-casino-play-again.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "./live-casino-play-again.component";
import * as i5 from "../../../multi-languages/services/translation.service";
import * as i6 from "../../services/game-groups.service";
import * as i7 from "../../../shared/pipes/games-filter.pipe";
import * as i8 from "../../services/game-play.service";
import * as i9 from "../../../shared/services/utility.service";
import * as i10 from "../../../auth/services/session.service";
import * as i11 from "../../../shared/services/common.service";
import * as i12 from "@ngrx/store";
import * as i13 from "@angular/router";
var styles_LiveCasinoPlayAgainComponent = [i0.styles];
var RenderType_LiveCasinoPlayAgainComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LiveCasinoPlayAgainComponent, data: {} });
export { RenderType_LiveCasinoPlayAgainComponent as RenderType_LiveCasinoPlayAgainComponent };
function View_LiveCasinoPlayAgainComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "lastPlayed--viewall-link"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onViewAllHandler() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("livecasino.view_all")); _ck(_v, 1, 0, currVal_0); }); }
function View_LiveCasinoPlayAgainComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "lastPlayed__card"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onGameLaunch(_v.context.$implicit.name) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "lastPlayed__card--img"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [], [[8, "src", 4], [8, "alt", 0], [8, "title", 0]], null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "lastPlayed__card--details"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "lastPlayed__card--gamename"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "lastPlayed__card--gameprovider"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(4, "", _co.gamesUrlPath, "/", _v.context.$implicit.gameCode, "/", _v.context.$implicit.gameCode, "_default_live_casino.jpg", _co.imgixParams, ""); var currVal_1 = i1.ɵinlineInterpolate(1, "", _v.context.$implicit.name, ""); var currVal_2 = i1.ɵinlineInterpolate(1, "", _v.context.$implicit.name, ""); _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = _v.context.$implicit.name; _ck(_v, 5, 0, currVal_3); var currVal_4 = _v.context.$implicit.vendorDisplayName; _ck(_v, 7, 0, currVal_4); }); }
function View_LiveCasinoPlayAgainComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "lastPlayed lastPlayed__container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "h4", [["class", "lastPlayed--title"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LiveCasinoPlayAgainComponent_2)), i1.ɵdid(5, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 3, "div", [["class", "lastPlayed__cards-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_LiveCasinoPlayAgainComponent_3)), i1.ɵdid(8, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(0, i3.SlicePipe, [])], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.lastPlayedLiveGamesList.length >= 4); _ck(_v, 5, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 8, 0, i1.ɵnov(_v, 9).transform(_co.lastPlayedLiveGamesList, 0, 4)); _ck(_v, 8, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("livecasino.play_again")); _ck(_v, 2, 0, currVal_0); }); }
export function View_LiveCasinoPlayAgainComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_LiveCasinoPlayAgainComponent_1)), i1.ɵdid(1, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.lastPlayedLiveGamesList && (_co.lastPlayedLiveGamesList.length > 0)); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_LiveCasinoPlayAgainComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-live-casino-play-again", [], null, null, null, View_LiveCasinoPlayAgainComponent_0, RenderType_LiveCasinoPlayAgainComponent)), i1.ɵdid(1, 245760, null, 0, i4.LiveCasinoPlayAgainComponent, [i5.TranslationService, i6.GameGroupsService, i7.GamesFilterPipe, i8.GamePlayService, i9.UtilityService, i10.SessionService, i11.CommonService, i12.Store, i13.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LiveCasinoPlayAgainComponentNgFactory = i1.ɵccf("app-live-casino-play-again", i4.LiveCasinoPlayAgainComponent, View_LiveCasinoPlayAgainComponent_Host_0, {}, {}, []);
export { LiveCasinoPlayAgainComponentNgFactory as LiveCasinoPlayAgainComponentNgFactory };
