import { Observable, throwError, of } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { map, catchError } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { Subject } from "rxjs/Subject";

// API Interactors
import { ApiInteractors } from "src/app/models/interactors/api.interactor";

// Enums
import { WorldCurrencyCode } from "src/app/models/configurations/enums/localization/world-currencies.enum";
import { Project } from "src/app/models/environments/project.enum";

// Libraries
import * as _ from "underscore";

// Models
import { ClaimLevelUpBonusResponse } from "src/app/modules/rewards/models/claim-level/claim-level-up-bonus-response.model";
import { UserCurrentLevelUpPointDetails } from "src/app/modules/rewards/models/user-current-level-up-point-details.model";
import { ClaimLevelUpBonusRequest } from "src/app/modules/rewards/models/claim-level/claim-level-up-bonus-request.model";
import { UserCurrentLevelUpDetails } from "src/app/modules/rewards/models/user-current-level-up-details.model";
import { UserLevelUpDetails } from "src/app/modules/rewards/models/user-level-up-details.model";
import { ActiveBonusDetails } from "src/app/modules/rewards/models/active-bonus-details.model";
import { UnclaimedLevels } from "src/app/modules/rewards/models/unclaimed-levels.model";
import { EligibleRequest } from "src/app/modules/rewards/models/eligible-request.model";
import {
  EligibleBonusAmountType,
  EligibleBonusCategory,
  EligibleBonusItem,
  EligibleBonus,
} from "src/app/modules/rewards/models/eligible-bonus.model";

@Injectable({
  providedIn: "root",
})
export class RewardsService {
  // API Interactions
  apiInteractor: ApiInteractors;

  // Numbers
  levelBeforeLevelUpgradedNumber: number = 0;
  userCurrentLevelId: number = 0;

  // Objects
  userCurrentLevelUpPointDetails: UserCurrentLevelUpPointDetails;
  userCurrentLevelUpDetails: UserCurrentLevelUpDetails;

  // --------------------------------------------------------
  // Subject - User Level Up Details
  private userLevelUpDetailsSubject: Subject<UserCurrentLevelUpDetails> =
    new Subject<UserCurrentLevelUpDetails>();
  public userLevelUpDetailsSubject$: Observable<UserCurrentLevelUpDetails> =
    this.userLevelUpDetailsSubject.asObservable();

  // --------------------------------------------------------
  // Subject - Update Level Up Notification
  private updateLevelUpNotificationSubject: Subject<number> =
    new Subject<number>();
  public updateLevelUpNotificationSubject$: Observable<number> =
    this.updateLevelUpNotificationSubject.asObservable();

  // --------------------------------------------------------
  // Subject - is Clear Level Up Notification
  private isClearLevelUpNotificationSubject: Subject<boolean> =
    new Subject<boolean>();
  public isClearLevelUpNotificationSubject$: Observable<boolean> =
    this.isClearLevelUpNotificationSubject.asObservable();

  // --------------------------------------------------------
  // Subject - User Level Up Point Details
  private userLevelUpPointDetailsSubject: Subject<UserCurrentLevelUpPointDetails> =
    new Subject<UserCurrentLevelUpPointDetails>();
  public userLevelUpPointDetailsSubject$: Observable<UserCurrentLevelUpPointDetails> =
    this.userLevelUpPointDetailsSubject.asObservable();

  constructor(private httpClient: HttpClient) {
    this.apiInteractor = new ApiInteractors(this.httpClient);
  }

  // -----------------------------------------------------------------
  // Get Methods
  getNotificationCount(currentLevelNumber: number): number {
    if (this.levelBeforeLevelUpgradedNumber && currentLevelNumber) {
      return currentLevelNumber - this.levelBeforeLevelUpgradedNumber;
    } else {
      return 0;
    }
  }

  getUserCurrentLevelUpDetails(): UserCurrentLevelUpDetails {
    return this.userCurrentLevelUpDetails;
  }

  getUserCurrentLevelId(): number {
    return this.userCurrentLevelId;
  }

  /*
    Here we process our eligible bonus based on
    1.bonuscode available or not
    2.user currencyTier avilable or not &
    3. also we disable cashout bonus of amountType PCT for pnp flow
  */
  getProcessEligibleBonus(
    eligibleBonusItemList: EligibleBonusItem[],
    currencyCode: WorldCurrencyCode,
    isPnpFlow: boolean
  ): EligibleBonusItem[] {
    let depositBonusDetailsList: EligibleBonusItem[] = _.filter(
      eligibleBonusItemList,
      {
        criteriaType: "DEPOSIT",
      }
    );

    let filteredBonusList: EligibleBonusItem[] =
      this.getFilterEligibleBonusBasedOnFlow(
        depositBonusDetailsList,
        currencyCode,
        isPnpFlow
      );

    return _.sortBy(
      filteredBonusList,
      (eligibleBonusItem: EligibleBonusItem) => {
        return eligibleBonusItem.campaignStartDate;
      }
    ).reverse();
  }

  getFilterEligibleBonusBasedOnFlow(
    filteredBonus: EligibleBonusItem[],
    currencyCode: WorldCurrencyCode,
    isPnpFlow: boolean
  ): EligibleBonusItem[] {
    let finalFilteredBonusList: EligibleBonusItem[] = _.filter(
      filteredBonus,
      (eligibleBonusItem: EligibleBonusItem) => {
        if (
          eligibleBonusItem.playerTargetType !== "LEVELUP" &&
          eligibleBonusItem.currencyTier &&
          eligibleBonusItem.currencyTier[currencyCode] &&
          eligibleBonusItem.currencyTier[currencyCode].length > 0 &&
          eligibleBonusItem.bonusCode &&
          (eligibleBonusItem.bonusCategory ===
            EligibleBonusCategory.RELEASE_RESTRICT ||
            !isPnpFlow ||
            (isPnpFlow &&
              eligibleBonusItem.bonusCategory ===
                EligibleBonusCategory.CASHOUT_RESTRICT &&
              eligibleBonusItem.amountType === EligibleBonusAmountType.ABS))
        ) {
          eligibleBonusItem.bonusTypeFD = "deposit-bonus";

          return eligibleBonusItem;
        }
      }
    );

    return finalFilteredBonusList;
  }

  // -----------------------------------------------------------------
  // Get Observables - Get User Level Up Details
  onGetUserLevelUpDetails(): Observable<UserLevelUpDetails> {
    return this.apiInteractor
      .get<null, UserLevelUpDetails>(
        `/ajax/loyality/getUserLevelupDetails`,
        null,
        Project.Shotz
      )
      .pipe(
        map((userLevelUpDetails: UserLevelUpDetails) => {
          if (userLevelUpDetails && userLevelUpDetails.levelId) {
            this.onSetUserCurrentLevelId(userLevelUpDetails.levelId);
          }

          if (userLevelUpDetails) {
            this.onSetUserLevelUpPointDetails({
              currentSpinCount: userLevelUpDetails.currentSpinCount,
              spinsNeededForNextLevel:
                userLevelUpDetails.spinsNeededForNextLevel,
              levelId: userLevelUpDetails.levelId,
            });
          }

          return userLevelUpDetails;
        }),
        catchError(() => {
          return of(undefined);
        })
      );
  }

  // -----------------------------------------------------------------
  // Get Observables - Get User Level Up Details
  onGetClaimLevelUpBonus(
    request: ClaimLevelUpBonusRequest
  ): Observable<ClaimLevelUpBonusResponse> {
    return this.apiInteractor
      .post<ClaimLevelUpBonusRequest, ClaimLevelUpBonusResponse>(
        `/ajax/loyality/claimLevelupBonus`,
        request,
        Project.Shotz
      )
      .pipe(
        map((claimLevelUpBonusResponse: ClaimLevelUpBonusResponse) => {
          return claimLevelUpBonusResponse;
        }),
        catchError(() => {
          return of(undefined);
        })
      );
  }

  // -----------------------------------------------------------------
  // Get Observables - Get User Level Up Details
  onGetUnclaimedLevels(): Observable<UnclaimedLevels> {
    return this.apiInteractor
      .get<null, UnclaimedLevels>(
        `/ajax/loyality/getUnclaimedLevels`,
        null,
        Project.Shotz
      )
      .pipe(
        map((unclaimedLevelsResponse: UnclaimedLevels) => {
          return unclaimedLevelsResponse;
        }),
        catchError(() => {
          return of(undefined);
        })
      );
  }

  // -----------------------------------------------------------------
  // Get Observables - Get Active Bonus Details
  onGetActiveBonusDetails(): Observable<ActiveBonusDetails[]> {
    return this.apiInteractor
      .get<null, ActiveBonusDetails[]>(
        `/ajax/Bonus/activeBonusDetails`,
        null,
        Project.Shotz
      )
      .pipe(
        map((activeBonusDetailsResponse: ActiveBonusDetails[]) => {
          return activeBonusDetailsResponse;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  // -----------------------------------------------------------------
  // Get Observables - Get Eligible Bonus
  onGetEligibleBonus(
    eligibleRequest?: EligibleRequest
  ): Observable<EligibleBonus> {
    return this.apiInteractor
      .get<EligibleRequest, EligibleBonus>(
        `/ajax/bonus/getEligibleBonuses`,
        eligibleRequest,
        Project.Shotz
      )
      .pipe(
        map((eligibleBonus: EligibleBonus) => {
          return eligibleBonus;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  // -----------------------------------------------------------------
  // Set Methods - on Set Level Before Level Upgraded
  onSetLevelBeforeLevelUpgraded(levelBeforeLevelUpgradedNumber: number): void {
    this.levelBeforeLevelUpgradedNumber = levelBeforeLevelUpgradedNumber;
  }

  // -----------------------------------------------------------------
  // Set Methods - on Broad Level Up Details
  onBroadcastLevelUpDetails(
    userCurrentLevelUpDetails: UserCurrentLevelUpDetails
  ): void {
    this.userCurrentLevelUpDetails = userCurrentLevelUpDetails;

    this.onSetUserCurrentLevelId(userCurrentLevelUpDetails.newLevelId);

    this.userLevelUpDetailsSubject.next(userCurrentLevelUpDetails);
  }

  // -----------------------------------------------------------------
  // Set Methods - on Set Level Up Details
  onSetLevelUpDetails(
    userCurrentLevelUpDetails: UserCurrentLevelUpDetails
  ): void {
    this.userCurrentLevelUpDetails = userCurrentLevelUpDetails;
  }

  // -----------------------------------------------------------------
  // Set Methods - on Broadcast Level Up Notifications
  onBroadcastLevelUpNotifications(isClearLevelUpNotification: boolean): void {
    this.isClearLevelUpNotificationSubject.next(isClearLevelUpNotification);
  }

  // -----------------------------------------------------------------
  // Set Methods - on Broadcast Level Up Point Details
  onBroadcastLevelUpPointDetails(
    userCurrentLevelUpPointDetails: UserCurrentLevelUpPointDetails
  ): void {
    this.userCurrentLevelUpPointDetails = userCurrentLevelUpPointDetails;

    this.onSetUserCurrentLevelId(userCurrentLevelUpPointDetails.levelId);

    this.userLevelUpPointDetailsSubject.next(userCurrentLevelUpPointDetails);
  }

  // -----------------------------------------------------------------
  // Set Methods - on Set Level Up Point Details
  onSetUserLevelUpPointDetails(
    userCurrentLevelUpPointDetails: UserCurrentLevelUpPointDetails
  ): void {
    this.userCurrentLevelUpPointDetails = userCurrentLevelUpPointDetails;
  }

  // -----------------------------------------------------------------
  // Set Methods - on Set User Current Level Id
  onSetUserCurrentLevelId(userCurrentLevelId: number): void {
    this.userCurrentLevelId = userCurrentLevelId;
  }
}
