import { catchError, map, share } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { Injectable } from "@angular/core";

// API Interactors
import { ApiInteractors } from "src/app/models/interactors/api.interactor";

// Enums
import { Project } from "src/app/models/environments/project.enum";

// Libraries
import * as _ from "underscore";

// Models
import { CancelNetDepositLimitsResponse } from "src/app/modules/limits/models/cancel-net-deposit-limits/cancel-net-deposit-limits-response.model";
import { CancelNetDepositLimitsRequest } from "src/app/modules/limits/models/cancel-net-deposit-limits/cancel-net-deposit-limits-request.model";
import { CancelDepositLimitsResponse } from "src/app/modules/limits/models/cancel-deposit-limts/cancel-depsoit-limits-response.model";
import { SetNetDepositLimitResponse } from "src/app/modules/limits/models/set-net-deposit-limit/set-net-deposit-limit-response.model";
import { CancelDepositLimitsRequest } from "src/app/modules/limits/models/cancel-deposit-limts/cancel-deposit-limits-request.model";
import { GamingLockAccountResponse } from "src/app/modules/limits/models/gaming-lock-account/gaming-lock-account-response.model";
import { GamingLockAccountRequest } from "src/app/modules/limits/models/gaming-lock-account/gaming-lock-account-request.model";
import { NetDepositLimitsResponse } from "src/app/modules/limits/models/net-deposit-limits/net-deposit-limits-response.model";
import { CancelLimitsResponse } from "src/app/modules/limits/models/cancel-limits/cancel-limits-response.model";
import { CancelLimitsRequest } from "src/app/modules/limits/models/cancel-limits/cancel-limits-request.model";
import { RgLimitsResponse } from "src/app/modules/limits/models/rg-limits/rg-limits-response.model";
import { RgLimitsRequest } from "src/app/modules/limits/models/rg-limits/rg-limits-request.model";
import { LimitsResponse } from "src/app/modules/limits/models/limits-response.model";

@Injectable({
  providedIn: "root",
})
export class LimitsService {
  // API Interactions
  apiInteractor: ApiInteractors;

  constructor(private httpClient: HttpClient) {
    this.apiInteractor = new ApiInteractors(this.httpClient);
  }

  // -----------------------------------------------------------------
  // Get Methods - Observables Limits
  onCancelDepositLimits(
    cancelDepositLimitsRequest: CancelDepositLimitsRequest
  ): Observable<CancelDepositLimitsResponse> {
    return this.apiInteractor
      .post<CancelDepositLimitsRequest, CancelDepositLimitsResponse>(
        `/ajax/ResponsibleGaming/confirmDepositLimits`,
        cancelDepositLimitsRequest
      )
      .pipe(
        map((response: CancelDepositLimitsResponse) => {
          return response;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  // -----------------------------------------------------------------
  // Get Methods - Observables Cancel Limits
  onCancelLimits(
    cancelLimitsRequest: CancelLimitsRequest
  ): Observable<CancelLimitsResponse> {
    return this.apiInteractor
      .post<CancelLimitsRequest, CancelLimitsResponse>(
        `/ajax/ResponsibleGaming/confirmLimits`,
        cancelLimitsRequest,
        Project.Shotz
      )
      .pipe(
        map((response: CancelLimitsResponse) => {
          return response;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  // -----------------------------------------------------------------
  // Get Methods - Observables Net Deposit Limits
  onGetNetDepositLimits(): Observable<NetDepositLimitsResponse> {
    return this.apiInteractor
      .get<null, NetDepositLimitsResponse>(
        `/ajax/ResponsibleGaming/getNetDepositLimits`,
        null,
        Project.Shotz
      )
      .pipe(
        map((response: NetDepositLimitsResponse) => {
          return response;
        }),
        catchError((error) => {
          return throwError(error);
        }),
        share()
      );
  }

  // -----------------------------------------------------------------
  // Get Methods - Observables Cancel Net Deposit Limits
  onCancelNetDepositLimits(
    cancelNetDepositLimitsRequest: CancelNetDepositLimitsRequest
  ): Observable<CancelNetDepositLimitsResponse> {
    return this.apiInteractor
      .post<CancelNetDepositLimitsRequest, CancelNetDepositLimitsResponse>(
        `/ajax/ResponsibleGaming/confirmNetDepositLimit`,
        cancelNetDepositLimitsRequest,
        Project.Shotz
      )
      .pipe(
        map((response: CancelNetDepositLimitsResponse) => {
          return response;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  // -----------------------------------------------------------------
  // Get Methods - Observables Set Net Deposit Limits
  onSetNetDepositLimits(
    rgLimitsRequest: RgLimitsRequest
  ): Observable<SetNetDepositLimitResponse> {
    return this.apiInteractor
      .post<RgLimitsRequest, SetNetDepositLimitResponse>(
        `/ajax/ResponsibleGaming/setNetDepositLimit`,
        rgLimitsRequest,
        Project.Shotz
      )
      .pipe(
        map((response: SetNetDepositLimitResponse) => {
          return response;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  // -----------------------------------------------------------------
  // Get Methods - Observables Responsible Gaming
  onSetResponsibleGamingLimits(
    rgLimitsRequest: RgLimitsRequest
  ): Observable<RgLimitsResponse> {
    return this.apiInteractor
      .post<RgLimitsRequest, RgLimitsResponse>(
        `/ajax/ResponsibleGaming/setLimits`,
        rgLimitsRequest,
        Project.Shotz
      )
      .pipe(
        map((response: RgLimitsResponse) => {
          return response;
        }),
        catchError((error) => {
          return throwError(error);
        }),
        share()
      );
  }

  // -----------------------------------------------------------------
  // Get Methods - Observable Responsible Gaming Limits
  onGetResponsibleGamingLimits(type: string): Observable<LimitsResponse> {
    let endpointURL: string = "";

    if (type === "deposit") {
      endpointURL = `/ajax/balance/getDepositLimit`;
    } else if (type === "loss") {
      endpointURL = `/ajax/balance/getLossLimit`;
    } else if (type === "wager") {
      endpointURL = `/ajax/balance/getWagerLimit`;
    } else if (type === "session") {
      endpointURL = `/ajax/balance/getSessionLimit`;
    }

    return this.apiInteractor
      .get<null, LimitsResponse>(endpointURL, null, Project.Shotz)
      .pipe(
        map((response: LimitsResponse) => {
          return response;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  // -----------------------------------------------------------------
  // Get Methods - Observable Responsible Gaming Limits
  onResponsibleGamingLockAccount(
    request: GamingLockAccountRequest
  ): Observable<GamingLockAccountResponse> {
    return this.apiInteractor
      .post<GamingLockAccountRequest, GamingLockAccountResponse>(
        `/ajax/ResponsibleGaming/lockAccount`,
        request,
        Project.Shotz
      )
      .pipe(
        map((response: GamingLockAccountResponse) => {
          return response;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
}
