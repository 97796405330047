import { createEntityAdapter, EntityState } from "@ngrx/entity";
import { Action, createReducer, on } from "@ngrx/store";

// Actions
import {
  lastPlayedGamesRequested,
  lastPlayedGamesLoaded,
  lastPlayedGamesError,
  gamesRequested,
  lobbyRequested,
  gamesLoaded,
  lobbyLoaded,
  gamesError,
  lobbyError,
} from "src/app/modules/game-groups/store/actions/games.actions";

// Models
import { LobbyPregmatic } from "src/app/modules/game-groups/models/lobby/lobby.model";
import { GamePregmatic } from "src/app/modules/game-groups/models/game.model";

export const GamesStateFeatureKey = "games-collection";

export interface GamesState extends EntityState<GamePregmatic> {
  isLoaded: boolean;
  isLoading: boolean;
}

export interface LobbyPregmaticState extends EntityState<LobbyPregmatic> {
  isLoaded: boolean;
}

// Reducer States
export interface GamesReducerState {
  gamesList: GamesState;
  gameError: string;
  lobbyPregmaticList: LobbyPregmaticState;
  lobbyPregmaticError: string;
  lastPlayedList: number[];
  lastPlayedError: string;
}

// Adapter for Games
export const adapterGames = createEntityAdapter<GamePregmatic>({
  selectId: (game: GamePregmatic) => `${game.gameCode}`,
});

const overviewGamesInitialState: GamesState = adapterGames.getInitialState({
  isLoaded: false,
  isLoading: true,
});

// Adapter for Lobby Pregmatic
export const adapterLobbyPregmatic = createEntityAdapter<LobbyPregmatic>({
  selectId: (lobbyPregmatic: LobbyPregmatic) =>
    `${lobbyPregmatic.id}-${lobbyPregmatic.name}`,
});

const overviewLobbyPregmaticInitialState: LobbyPregmaticState =
  adapterLobbyPregmatic.getInitialState({
    isLoaded: false,
  });

export const initialState: GamesReducerState = {
  gamesList: overviewGamesInitialState,
  gameError: null,
  lobbyPregmaticList: overviewLobbyPregmaticInitialState,
  lobbyPregmaticError: null,
  lastPlayedList: [],
  lastPlayedError: null,
};

// Reducer
const GamesReducer = createReducer(
  initialState,
  // Games
  on(gamesRequested, (state: GamesReducerState) => {
    return {
      ...state,
      gamesList: adapterGames.removeAll({
        ...state.gamesList,
        isLoading: true,
        isLoaded: false,
      }),
      gameError: null,
    };
  }),
  on(gamesLoaded, (state: GamesReducerState, { gamesList }) => {
    return {
      ...state,
      gamesList: adapterGames.upsertMany(gamesList, {
        ...state.gamesList,
        isLoading: false,
        isLoaded: true,
      }),
      gameError: null,
    };
  }),
  on(gamesError, (state: GamesReducerState) => {
    return {
      ...state,
      gameError: "Error occurred whilst retrieving Games List",
    };
  }),
  // Lobby Pregmatic
  on(lobbyRequested, (state: GamesReducerState) => {
    return {
      ...state,
      lobbyPregmaticList: adapterLobbyPregmatic.removeAll(
        state.lobbyPregmaticList
      ),
      lobbyPregmaticError: null,
    };
  }),
  on(lobbyLoaded, (state: GamesReducerState, { lobbyPregmaticList }) => {
    return {
      ...state,
      lobbyPregmaticList: adapterLobbyPregmatic.upsertMany(
        lobbyPregmaticList,
        state.lobbyPregmaticList
      ),
      lobbyPregmaticError: null,
    };
  }),
  on(lobbyError, (state: GamesReducerState) => {
    return {
      ...state,
      lobbyPregmaticError:
        "Error occurred whilst retrieving Lobby Pregmatic List",
    };
  }),
  // Last Played
  on(lastPlayedGamesRequested, (state: GamesReducerState) => {
    return {
      ...state,
      lastPlayedError: null,
    };
  }),
  on(lastPlayedGamesLoaded, (state: GamesReducerState, { lastPlayedList }) => {
    return {
      ...state,
      lastPlayedList,
      lastPlayedError: null,
    };
  }),
  on(lastPlayedGamesError, (state: GamesReducerState) => {
    return {
      ...state,
      lastPlayedError: "Error occurred whilst retrieving Last Played List",
    };
  })
);

export function reducer(state: GamesReducerState | undefined, action: Action) {
  return GamesReducer(state, action);
}
