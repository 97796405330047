import * as i0 from "@angular/core";
export class GtmService {
    constructor() { }
    // -----------------------------------------------------------------
    // Get Methods
    getDepositFailure(transactionData) {
        let payload = {
            event: "customPage",
            pageName: "/deposits/failure",
            transactionId: transactionData.txnId,
            amount: transactionData.amount,
        };
        if (transactionData.errorCode) {
            payload.errorCode = transactionData.errorCode;
            payload.errorDescription = transactionData.errorDescription;
        }
        if (transactionData.externalEcrId) {
            payload.externalEcrId = transactionData.externalEcrId;
        }
        return payload;
    }
    getDepositSuccessBassedTxnStatus(transactionData, isFirstDeposit) {
        let payload = {
            event: "customPage",
            amount: transactionData.amount,
            transactionId: transactionData.txnId,
        };
        if (transactionData.externalEcrId) {
            payload.externalEcrId = transactionData.externalEcrId;
        }
        switch (transactionData.txnStatus) {
            case "CASHIER_INIT_DEPOSIT_SUCCESS":
            case "CASHIER_TRANSFER_SUCCESS":
            case "DEPOSIT_SUCCESS":
                {
                    payload.pageName = "/deposits/success";
                    payload.depositType = isFirstDeposit ? "first" : "subsequent";
                }
                break;
            case "CASHIER_INIT_DEPOSIT_FAILED":
            case "CATEGORY_LIMITS_EXCEEDED":
            case "DEPOSIT_NOT_ALLOWED":
            case "KYC_VERIFIED":
                {
                    payload.pageName = "/deposits/failure";
                    if (transactionData.errorCode && transactionData.errorDescription) {
                        payload.errorCode = transactionData.errorCode;
                        payload.errorDescription = transactionData.errorDescription;
                    }
                }
                break;
        }
        return payload;
    }
    // -----------------------------------------------------------------
    // Set Methods
    onGtmDataLayerEvent(event) {
        let dataLayer = (window["dataLayer"] = window["dataLayer"] || []);
        dataLayer.push(event);
    }
    onSendRegilyAnalyticsEvent(hitType, action, label) {
        const gaEvent = {
            event: "user_interaction",
            event_type: "register",
            event_context: `${action} ${label}`,
        };
        this.onGtmDataLayerEvent(gaEvent);
    }
    onRegistrationGTMEvent(eventType, payload) {
        let eventpayload;
        switch (eventType) {
            case "register":
                {
                    eventpayload = {
                        event: "user_interaction",
                        event_type: "register",
                        event_context: payload.event_context,
                    };
                    if (payload.event_context === "finish") {
                        eventpayload.userid = payload.ecrId;
                    }
                }
                break;
            case "registration-flow":
                {
                    eventpayload = {
                        event: "user_interaction",
                        event_type: "register",
                        event_context: payload.action,
                        eventLabel: payload.label,
                    };
                }
                break;
        }
        this.onGtmDataLayerEvent(eventpayload);
    }
    onRouteChangeGtmEvent(event) {
        let pathname = event.url;
        let router_name = "";
        let gtmRouterEvent;
        if (pathname && pathname.length > 7 && pathname.includes("en-row")) {
            router_name = pathname.substring(7);
        }
        else if (pathname &&
            pathname.length > 6 &&
            !pathname.includes("en-row")) {
            router_name = pathname.substring(6);
        }
        else {
            router_name = "/landing-page";
        }
        gtmRouterEvent = {
            event: "user_interaction",
            event_type: "page",
            route_name: router_name,
        };
        if (router_name) {
            this.onGtmDataLayerEvent(gtmRouterEvent);
        }
    }
    /*
      we basically push three events here
      1.open deposit
      2.success depost &
      3.failure deposit
    
      Zimpler GTM event related functionality goes here...
    */
    onTrackLoginGTMEvent(type, data) {
        if (type === "open") {
            let gtmTrackOpen = {
                event: "customPage",
                pageName: "/open-login",
            };
            this.onGtmDataLayerEvent(gtmTrackOpen);
        }
        if (type === "login-success") {
            let gtmTrackLogin = {
                event: "logged-In",
                externalEcrId: data.ecrExternalId,
                country: data.countryCode,
                userType: data.ecrCategory,
            };
            this.onGtmDataLayerEvent(gtmTrackLogin);
        }
    }
    onTrackDepositGTMEvent(depositType, transactionData, isFirstDeposit = false) {
        let gtmEventPlayload;
        if (depositType === "open") {
            gtmEventPlayload = {
                event: "customPage",
                pageName: "/deposits/open",
            };
        }
        if (depositType === "FAILURE" && transactionData) {
            gtmEventPlayload = this.getDepositFailure(transactionData);
        }
        if (depositType === "SUCCESS" && transactionData) {
            gtmEventPlayload = this.getDepositSuccessBassedTxnStatus(transactionData, isFirstDeposit);
        }
        this.onGtmDataLayerEvent(gtmEventPlayload);
    }
    onUserCashierJourneyEvents(callingFrom, cashierPayload) {
        let eventPayLoad = {
            event: callingFrom === "navigate" ? "user_interaction" : "custom_event",
            event_category: "paymentiq",
        };
        switch (callingFrom) {
            case "cashierInitLoad":
                {
                    eventPayLoad.event_labelId = "casinofriday";
                    eventPayLoad.event_action = "initload";
                    eventPayLoad.event_label = "click";
                    eventPayLoad.partnerID = "CASINO";
                    if (cashierPayload.bonusCode) {
                        eventPayLoad.bounusCode = cashierPayload.bonusCode;
                    }
                }
                break;
            case "isLoading":
                {
                    eventPayLoad.event_action = "is_loading";
                    eventPayLoad.event_label = "click";
                }
                break;
            case "doneLoading":
                {
                    eventPayLoad.event_action = "done_loading";
                    eventPayLoad.event_label = "click";
                }
                break;
            case "failure":
                {
                    eventPayLoad.payment_method = cashierPayload.method_name;
                    eventPayLoad.event_label = cashierPayload.statusCode;
                    eventPayLoad.txRefId = cashierPayload.txRefId;
                    eventPayLoad.amount = cashierPayload.amount;
                    eventPayLoad.userId = cashierPayload.userId;
                    eventPayLoad.event_action = "error";
                }
                break;
            case "paymentMethodSelect":
                {
                    eventPayLoad.payment_provider = cashierPayload.providerType;
                    eventPayLoad.event_action = "payment_method";
                    eventPayLoad.userId = cashierPayload.userId;
                    if (cashierPayload.txType) {
                        //Ecopayz, Credit Card, Btc, etc..
                        eventPayLoad.event_label = cashierPayload.txType;
                    }
                }
                break;
            case "onLoadError":
                {
                    eventPayLoad.event_label = cashierPayload.status;
                    eventPayLoad.userId = cashierPayload.userId;
                    eventPayLoad.event_action = "on_LoadError";
                }
                break;
            case "navigate":
                {
                    eventPayLoad.payment_method = cashierPayload.method_name;
                    eventPayLoad.route_name = cashierPayload.path;
                    eventPayLoad.event_type = "page";
                }
                break;
            case "transactionInit":
                {
                    eventPayLoad.payment_method = cashierPayload.method_name;
                    eventPayLoad.event_action = "transaction initiated";
                    eventPayLoad.txRefId = cashierPayload.txRefId;
                    eventPayLoad.amount = cashierPayload.amount;
                    eventPayLoad.userId = cashierPayload.userId;
                }
                break;
        }
        this.onGtmDataLayerEvent(eventPayLoad);
    }
}
GtmService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GtmService_Factory() { return new GtmService(); }, token: GtmService, providedIn: "root" });
