import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, switchMap, map } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { of } from "rxjs";

// Actions
import {
  countryBlockRequested,
  countryBlockLoaded,
  countryBlockError,
} from "src/app/modules/country-block/store/actions/country-block.actions";

// Models
import { CountryBlockResponse } from "src/app/modules/country-block/models/country-block-response.model";

// Services
import { CountryBlockService } from "src/app/modules/country-block/services/country-block.service";

@Injectable()
export class CountryBlockEffects {
  constructor(
    private countryBlockService: CountryBlockService,
    private actions$: Actions
  ) {}

  countryBlockRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(countryBlockRequested),
      switchMap(() => {
        return this.countryBlockService.onGetCountryBlock().pipe(
          map((countryBlockResponse: CountryBlockResponse) =>
            countryBlockLoaded({ countryBlockResponse })
          ),
          catchError(() => of(countryBlockError()))
        );
      })
    )
  );
}
