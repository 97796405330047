import { Injectable } from "@angular/core";

// Models
import { GtmDepositFailurePayload } from "src/app/modules/shared/models/gtm/gtm-deposit-failure-payload.model";
import { GtmDepositSuccessStatus } from "src/app/modules/shared/models/gtm/gtm-deposit-success-status.model";
import { GtmRegistrationPayload } from "src/app/modules/shared/models/gtm/gtm-registration-payload.model";
import { GtmDepositTransaction } from "src/app/modules/shared/models/gtm/gtm-deposit-transaction.model";
import { GtmRegistrationEvent } from "src/app/modules/shared/models/gtm/gtm-registration-event.model";
import { GtmCashierPayload } from "src/app/modules/shared/models/gtm/gtm-cashier-payload.model";
import { GtmRouterPayload } from "src/app/modules/shared/models/gtm/gtm-router-payload.model";
import { GtmEventPayload } from "src/app/modules/shared/models/gtm/gtm-event-payload.model";
import { GtmQuickDeposit } from "src/app/modules/shared/models/gtm/gtm-quick-deposit.model";
import { GtmRouterEvent } from "src/app/modules/shared/models/gtm/gtm-router-event.model";
import { GtmTrackLogin } from "src/app/modules/shared/models/gtm/gtm-track-login.model";
import { GtmTrackOpen } from "src/app/modules/shared/models/gtm/gtm-track-open.model";
import { GtmStorylyEvent } from "src/app/modules/shared/models/gtm/gtm-storyly.model";
import { GtmLogin } from "src/app/modules/shared/models/gtm/gtm-login.model";

@Injectable({
  providedIn: "root",
})
export class GtmService {
  constructor() {}

  // -----------------------------------------------------------------
  // Get Methods
  getDepositFailure(
    transactionData: GtmDepositTransaction
  ): GtmDepositFailurePayload {
    let payload: GtmDepositFailurePayload = {
      event: "customPage",
      pageName: "/deposits/failure",
      transactionId: transactionData.txnId,
      amount: transactionData.amount,
    };

    if (transactionData.errorCode) {
      payload.errorCode = transactionData.errorCode;
      payload.errorDescription = transactionData.errorDescription;
    }

    if (transactionData.externalEcrId) {
      payload.externalEcrId = transactionData.externalEcrId;
    }

    return payload;
  }

  getDepositSuccessBassedTxnStatus(
    transactionData: GtmDepositTransaction,
    isFirstDeposit: boolean
  ): GtmDepositSuccessStatus {
    let payload: GtmDepositSuccessStatus = {
      event: "customPage",
      amount: transactionData.amount,
      transactionId: transactionData.txnId,
    };

    if (transactionData.externalEcrId) {
      payload.externalEcrId = transactionData.externalEcrId;
    }

    switch (transactionData.txnStatus) {
      case "CASHIER_INIT_DEPOSIT_SUCCESS":
      case "CASHIER_TRANSFER_SUCCESS":
      case "DEPOSIT_SUCCESS":
        {
          payload.pageName = "/deposits/success";

          payload.depositType = isFirstDeposit ? "first" : "subsequent";
        }
        break;

      case "CASHIER_INIT_DEPOSIT_FAILED":
      case "CATEGORY_LIMITS_EXCEEDED":
      case "DEPOSIT_NOT_ALLOWED":
      case "KYC_VERIFIED":
        {
          payload.pageName = "/deposits/failure";

          if (transactionData.errorCode && transactionData.errorDescription) {
            payload.errorCode = transactionData.errorCode;
            payload.errorDescription = transactionData.errorDescription;
          }
        }
        break;
    }

    return payload;
  }

  // -----------------------------------------------------------------
  // Set Methods
  onGtmDataLayerEvent(
    event:
      | GtmDepositFailurePayload
      | GtmDepositSuccessStatus
      | GtmRegistrationEvent
      | GtmEventPayload
      | GtmQuickDeposit
      | GtmRouterEvent
      | GtmTrackLogin
      | GtmTrackOpen
      | GtmLogin
      | GtmStorylyEvent
  ): void {
    let dataLayer = (window["dataLayer"] = window["dataLayer"] || []);

    dataLayer.push(event);
  }

  onSendRegilyAnalyticsEvent(
    hitType: string,
    action: string,
    label: string
  ): void {
    const gaEvent: GtmRegistrationEvent = {
      event: "user_interaction",
      event_type: "register",
      event_context: `${action} ${label}`,
    };

    this.onGtmDataLayerEvent(gaEvent);
  }

  onRegistrationGTMEvent(
    eventType: string,
    payload: GtmRegistrationPayload
  ): void {
    let eventpayload: GtmRegistrationEvent;

    switch (eventType) {
      case "register":
        {
          eventpayload = {
            event: "user_interaction",
            event_type: "register",
            event_context: payload.event_context,
          };

          if (payload.event_context === "finish") {
            eventpayload.userid = payload.ecrId;
          }
        }
        break;

      case "registration-flow":
        {
          eventpayload = {
            event: "user_interaction",
            event_type: "register",
            event_context: payload.action,
            eventLabel: payload.label,
          };
        }
        break;
    }

    this.onGtmDataLayerEvent(eventpayload);
  }

  onRouteChangeGtmEvent(event: GtmRouterPayload): void {
    let pathname: string = event.url;
    let router_name: string = "";

    let gtmRouterEvent: GtmRouterEvent;

    if (pathname && pathname.length > 7 && pathname.includes("en-row")) {
      router_name = pathname.substring(7);
    } else if (
      pathname &&
      pathname.length > 6 &&
      !pathname.includes("en-row")
    ) {
      router_name = pathname.substring(6);
    } else {
      router_name = "/landing-page";
    }

    gtmRouterEvent = {
      event: "user_interaction",
      event_type: "page",
      route_name: router_name,
    };

    if (router_name) {
      this.onGtmDataLayerEvent(gtmRouterEvent);
    }
  }

  /* 
    we basically push three events here
    1.open deposit
    2.success depost &
    3.failure deposit
  
    Zimpler GTM event related functionality goes here... 
  */
  onTrackLoginGTMEvent(
    type: string,
    data?: {
      ecrExternalId?: string;
      countryCode?: string;
      ecrCategory?: string;
    }
  ): void {
    if (type === "open") {
      let gtmTrackOpen: GtmTrackOpen = {
        event: "customPage",
        pageName: "/open-login",
      };

      this.onGtmDataLayerEvent(gtmTrackOpen);
    }

    if (type === "login-success") {
      let gtmTrackLogin: GtmTrackLogin = {
        event: "logged-In",
        externalEcrId: data.ecrExternalId,
        country: data.countryCode,
        userType: data.ecrCategory,
      };

      this.onGtmDataLayerEvent(gtmTrackLogin);
    }
  }

  onTrackDepositGTMEvent(
    depositType: string,
    transactionData?: GtmDepositTransaction,
    isFirstDeposit: boolean = false
  ): void {
    let gtmEventPlayload: GtmDepositFailurePayload | GtmDepositSuccessStatus;

    if (depositType === "open") {
      gtmEventPlayload = {
        event: "customPage",
        pageName: "/deposits/open",
      };
    }

    if (depositType === "FAILURE" && transactionData) {
      gtmEventPlayload = this.getDepositFailure(transactionData);
    }

    if (depositType === "SUCCESS" && transactionData) {
      gtmEventPlayload = this.getDepositSuccessBassedTxnStatus(
        transactionData,
        isFirstDeposit
      );
    }

    this.onGtmDataLayerEvent(gtmEventPlayload);
  }

  onUserCashierJourneyEvents(
    callingFrom: string,
    cashierPayload?: GtmCashierPayload
  ): void {
    let eventPayLoad: GtmEventPayload = {
      event: callingFrom === "navigate" ? "user_interaction" : "custom_event",
      event_category: "paymentiq",
    };

    switch (callingFrom) {
      case "cashierInitLoad":
        {
          eventPayLoad.event_labelId = "casinofriday";
          eventPayLoad.event_action = "initload";
          eventPayLoad.event_label = "click";
          eventPayLoad.partnerID = "CASINO";

          if (cashierPayload.bonusCode) {
            eventPayLoad.bounusCode = cashierPayload.bonusCode;
          }
        }
        break;

      case "isLoading":
        {
          eventPayLoad.event_action = "is_loading";
          eventPayLoad.event_label = "click";
        }
        break;

      case "doneLoading":
        {
          eventPayLoad.event_action = "done_loading";
          eventPayLoad.event_label = "click";
        }
        break;

      case "failure":
        {
          eventPayLoad.payment_method = cashierPayload.method_name;
          eventPayLoad.event_label = cashierPayload.statusCode;
          eventPayLoad.txRefId = cashierPayload.txRefId;
          eventPayLoad.amount = cashierPayload.amount;
          eventPayLoad.userId = cashierPayload.userId;
          eventPayLoad.event_action = "error";
        }
        break;

      case "paymentMethodSelect":
        {
          eventPayLoad.payment_provider = cashierPayload.providerType;
          eventPayLoad.event_action = "payment_method";
          eventPayLoad.userId = cashierPayload.userId;

          if (cashierPayload.txType) {
            //Ecopayz, Credit Card, Btc, etc..
            eventPayLoad.event_label = cashierPayload.txType;
          }
        }
        break;

      case "onLoadError":
        {
          eventPayLoad.event_label = cashierPayload.status;
          eventPayLoad.userId = cashierPayload.userId;
          eventPayLoad.event_action = "on_LoadError";
        }
        break;

      case "navigate":
        {
          eventPayLoad.payment_method = cashierPayload.method_name;
          eventPayLoad.route_name = cashierPayload.path;
          eventPayLoad.event_type = "page";
        }
        break;

      case "transactionInit":
        {
          eventPayLoad.payment_method = cashierPayload.method_name;
          eventPayLoad.event_action = "transaction initiated";
          eventPayLoad.txRefId = cashierPayload.txRefId;
          eventPayLoad.amount = cashierPayload.amount;
          eventPayLoad.userId = cashierPayload.userId;
        }
        break;
    }

    this.onGtmDataLayerEvent(eventPayLoad);
  }
}
