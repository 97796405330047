/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./game-provider.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "../../../../../../node_modules/ngx-swiper-wrapper/dist/ngx-swiper-wrapper.ngfactory";
import * as i4 from "ngx-swiper-wrapper";
import * as i5 from "../../../shared/directives/swiper-resize.directive";
import * as i6 from "@angular/common";
import * as i7 from "./game-provider.component";
import * as i8 from "../../../shared/services/utility.service";
import * as i9 from "@ngrx/store";
import * as i10 from "@angular/router";
var styles_GameProviderComponent = [i0.styles];
var RenderType_GameProviderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GameProviderComponent, data: {} });
export { RenderType_GameProviderComponent as RenderType_GameProviderComponent };
function View_GameProviderComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "game-card casino providers__list cursor-hand"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onNavigateToStudio(_v.context.$implicit.vendorDisplayName) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "providers__wrap"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [], [[8, "src", 4], [8, "alt", 0], [8, "title", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(3, "", _co.mediaUrlPath, "/game-provider/", _v.context.$implicit.vendorCode, ".png", _co.imgixParams, ""); var currVal_1 = i1.ɵinlineInterpolate(1, "", _v.context.$implicit.vendorDisplayName, ""); var currVal_2 = i1.ɵinlineInterpolate(1, "", _v.context.$implicit.vendorDisplayName, ""); _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }); }
function View_GameProviderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [["class", "game-group__container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "game-group--title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["class", "gameGroupName-link"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onNavigateAllGamesPage() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(3, null, [" ", " "])), (_l()(), i1.ɵeld(4, 0, null, null, 2, "span", [["class", "viewall-link"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onNavigateAllGamesPage() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(5, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(7, 0, null, null, 7, "div", [["class", "swiper__outer hoverspace"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 6, "div", [["class", "swiper__inner swiper__full"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 5, "swiper", [["class", "mySwiper swiper-control"], ["watchSlidesProgress", ""]], null, [[null, "slidePrevTransitionEnd"], [null, "slideNextTransitionEnd"], ["window", "resize"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 11).onResize() !== false);
        ad = (pd_0 && ad);
    } if (("slidePrevTransitionEnd" === en)) {
        var pd_1 = (_co.onSwiperPrevEvent() !== false);
        ad = (pd_1 && ad);
    } if (("slideNextTransitionEnd" === en)) {
        var pd_2 = (_co.onSwiperNextEvent() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i3.View_SwiperComponent_0, i3.RenderType_SwiperComponent)), i1.ɵdid(10, 4374528, null, 0, i4.SwiperComponent, [i1.NgZone, i1.ChangeDetectorRef, i1.PLATFORM_ID, [2, i4.SWIPER_CONFIG]], { config: [0, "config"] }, { S_SLIDEPREVTRANSITIONEND: "slidePrevTransitionEnd", S_SLIDENEXTTRANSITIONEND: "slideNextTransitionEnd" }), i1.ɵdid(11, 16384, null, 0, i5.SwiperResizeDirective, [i4.SwiperComponent], null, null), (_l()(), i1.ɵand(16777216, null, 0, 2, null, View_GameProviderComponent_2)), i1.ɵdid(13, 278528, null, 0, i6.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(0, i6.SlicePipe, [])], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.swiperProviders; _ck(_v, 10, 0, currVal_2); var currVal_3 = i1.ɵunv(_v, 13, 0, i1.ɵnov(_v, 14).transform(_co.providerList, 0, _co.swiperLazyConfigurations.indexNumberOfGameTilesToshow)); _ck(_v, 13, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.gameGroupData.name; _ck(_v, 3, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform("gamegroup.view_all")); _ck(_v, 5, 0, currVal_1); }); }
export function View_GameProviderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_GameProviderComponent_1)), i1.ɵdid(1, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((!_co.isLoading && _co.providerList) && (_co.providerList.length > 0)); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_GameProviderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-game-provider", [], null, null, null, View_GameProviderComponent_0, RenderType_GameProviderComponent)), i1.ɵdid(1, 770048, null, 0, i7.GameProviderComponent, [i8.UtilityService, i9.Store, i10.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GameProviderComponentNgFactory = i1.ɵccf("app-game-provider", i7.GameProviderComponent, View_GameProviderComponent_Host_0, { gameGroupData: "gameGroupData", callingFromlobby: "callingFromlobby" }, {}, []);
export { GameProviderComponentNgFactory as GameProviderComponentNgFactory };
