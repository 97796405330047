/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./vimeo-player.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "../../../shared/components/loader/loader.component.ngfactory";
import * as i5 from "../../../shared/components/loader/loader.component";
import * as i6 from "./vimeo-player.component";
var styles_VimeoPlayerComponent = [i0.styles];
var RenderType_VimeoPlayerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_VimeoPlayerComponent, data: {} });
export { RenderType_VimeoPlayerComponent as RenderType_VimeoPlayerComponent };
function View_VimeoPlayerComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["aria-label", "Try for fun"], ["class", "btn btn-secondary btn-sm btn-fun btn-round"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onGameLaunchHandler(_co.gameData.name, _co.gameData.hasDemo, "freegame") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("general.tryForFun")); _ck(_v, 1, 0, currVal_0); }); }
function View_VimeoPlayerComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "showreel-endscreen"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "button", [["aria-label", "Play"], ["class", "btn btn-primary btn-sm btn-play btn-round"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onGameLaunchHandler(_co.gameData.name, _co.gameData.hasDemo, (_co.isLoggedIn ? "realgame" : "freegame")) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VimeoPlayerComponent_3)), i1.ɵdid(5, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 1, "button", [["aria-label", "Replay"], ["class", "btn btn-replay btn-secondary fade-in-up"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onPlayAndPausedHandler() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "i", [["class", "icon-replay"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.isLoggedIn; _ck(_v, 5, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("general.play")); _ck(_v, 2, 0, currVal_0); }); }
function View_VimeoPlayerComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "isLoadingVideo"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-loader", [], null, null, null, i4.View_LoaderComponent_0, i4.RenderType_LoaderComponent)), i1.ɵdid(2, 49152, null, 0, i5.LoaderComponent, [], null, null)], null, null); }
function View_VimeoPlayerComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["aria-label", "Play"], ["class", "btn btn-play-showreel btn-secondary fade-in"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "icon-play"]], null, null, null, null, null))], null, null); }
function View_VimeoPlayerComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["aria-label", "Pause"], ["class", "btn btn-play-showreel btn-secondary btn-pause fade-in"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "icon-pause"]], null, null, null, null, null))], null, null); }
function View_VimeoPlayerComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["aria-label", "Volume"], ["class", "btn btn-mute btn-secondary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onSetVolume() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [], [[8, "className", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.isMutted ? "icon-mute" : "icon-unmute"); _ck(_v, 1, 0, currVal_0); }); }
function View_VimeoPlayerComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VimeoPlayerComponent_5)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "playHanderBackdrop"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onPlayAndPausedHandler() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VimeoPlayerComponent_6)), i1.ɵdid(5, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VimeoPlayerComponent_7)), i1.ɵdid(7, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VimeoPlayerComponent_8)), i1.ɵdid(9, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isLoading; _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.isPaused && !_co.isLoading); _ck(_v, 5, 0, currVal_1); var currVal_2 = !_co.isPaused; _ck(_v, 7, 0, currVal_2); var currVal_3 = _co.gameData.externalAsset; _ck(_v, 9, 0, currVal_3); }, null); }
function View_VimeoPlayerComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "game-card__mask"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "p", [["class", "msg"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("gamegroup.novideo_configured")); _ck(_v, 2, 0, currVal_0); }); }
function View_VimeoPlayerComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "vimeoplay-container"]], [[8, "id", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ("vimeoId" + _co.gameData.externalAsset); _ck(_v, 0, 0, currVal_0); }); }
function View_VimeoPlayerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "game-card__body"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VimeoPlayerComponent_2)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VimeoPlayerComponent_4)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VimeoPlayerComponent_9)), i1.ɵdid(6, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VimeoPlayerComponent_10)), i1.ɵdid(8, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isVideoEnded; _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.gameData.externalAsset && !_co.isVideoEnded); _ck(_v, 4, 0, currVal_1); var currVal_2 = !_co.gameData.externalAsset; _ck(_v, 6, 0, currVal_2); var currVal_3 = _co.gameData.externalAsset; _ck(_v, 8, 0, currVal_3); }, null); }
export function View_VimeoPlayerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_VimeoPlayerComponent_1)), i1.ɵdid(1, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.gameData; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_VimeoPlayerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-vimeo-player", [], null, null, null, View_VimeoPlayerComponent_0, RenderType_VimeoPlayerComponent)), i1.ɵdid(1, 573440, null, 0, i6.VimeoPlayerComponent, [], null, null)], null, null); }
var VimeoPlayerComponentNgFactory = i1.ɵccf("app-vimeo-player", i6.VimeoPlayerComponent, View_VimeoPlayerComponent_Host_0, { isLoggedIn: "isLoggedIn", gameData: "gameData" }, { gameLaunch: "gameLaunch" }, []);
export { VimeoPlayerComponentNgFactory as VimeoPlayerComponentNgFactory };
