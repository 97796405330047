import { Component } from "@angular/core";
import { Subscription } from "rxjs";

// Services
import { ProfileService } from "src/app/modules/user/services/profile.service";

@Component({
  selector: "app-limits",
  templateUrl: "./limits.component.html",
  styleUrls: ["./limits.component.scss"],
})
export class LimitsComponent {
  // Strings
  userJurisdiction: string = "";

  // Subscriptions
  subscriptions: Subscription[] = [];

  constructor(private profileService: ProfileService) {}

  // -----------------------------------------------------------------
  // Lifecycle Hooks
  ngOnInit(): void {
    this.userJurisdiction = this.profileService.getUserJurisdiction();

    this.subscriptions = [
      this.profileService.userJurisdictionSubject$.subscribe(
        (userJurisdiction: string) => {
          this.userJurisdiction = userJurisdiction;
        }
      ),
    ];
  }

  // -----------------------------------------------------------------
  // On Destroy
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) =>
      subscription.unsubscribe()
    );
  }
}
