import {
  SimpleChange,
  EventEmitter,
  ElementRef,
  Component,
  OnChanges,
  ViewChild,
  Output,
  Input,
} from "@angular/core";

// Configurations
import { maxSupportedFileSizeInBytesConfigurations } from "src/app/configurations/main.configurations";

// Models
import { ResidenceProofData } from "src/app/modules/kyc/models/residence-proof-data.model";
import { UploadResponseData } from "src/app/modules/kyc/models/upload-response-data.model";
import { UploadEvent } from "src/app/modules/kyc/models/upload-event.model";

// Services
import { TranslationService } from "src/app/modules/multi-languages/services/translation.service";

@Component({
  selector: "app-proof-of-identity",
  templateUrl: "./proof-of-identity.component.html",
  styleUrls: ["./proof-of-identity.component.scss"],
})
export class ProofOfIdentityComponent implements OnChanges {
  // View Child
  @ViewChild("identityProofCamera", { static: false })
  identityProofCamera: ElementRef;
  @ViewChild("identityProof", { static: false }) identityProof: ElementRef;

  // Outputs
  @Output() uploadIdenityProofFiles: EventEmitter<ResidenceProofData> =
    new EventEmitter<ResidenceProofData>();

  // Inputs
  @Input() uploadResponseData: UploadResponseData;
  @Input() isButtonDisabled: boolean = false;

  // Strings
  sizeExceeded: string = "";

  // Objects
  identityProofData: ResidenceProofData;

  constructor(private translationService: TranslationService) {}

  // -----------------------------------------------------------------
  // Lifecycle Hooks
  ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
    if (
      changes["uploadResponseData"] &&
      changes["uploadResponseData"].previousValue !==
        changes["uploadResponseData"].currentValue
    ) {
      this.uploadResponseData = changes["uploadResponseData"].currentValue;

      setTimeout(() => {
        this.uploadResponseData = undefined;
      }, 5000);

      this.onClearField();
    }

    if (
      changes["isButtonDisabled"] &&
      changes["isButtonDisabled"].previousValue !==
        changes["isButtonDisabled"].currentValue
    ) {
      this.isButtonDisabled = changes["isButtonDisabled"].currentValue;
    }
  }

  // -----------------------------------------------------------------
  // Set Methods
  onUpload(event: UploadEvent): void {
    this.sizeExceeded = undefined;

    const target: HTMLInputElement = event.target as HTMLInputElement;

    if (target.files[0].size >= maxSupportedFileSizeInBytesConfigurations) {
      this.sizeExceeded = this.translationService.get(
        "confirmidentity.file_size_alert"
      );

      this.onClearField();
    } else {
      this.identityProofData = {
        event: event,
        fileName: target.files[0].name,
        type: "nationalid",
      };
    }
  }

  onClearField(): void {
    this.identityProofData = undefined;

    if (this.identityProofCamera && this.identityProof) {
      this.identityProofCamera.nativeElement.value = "";

      this.identityProof.nativeElement.value = "";
    }

    if (this.sizeExceeded) {
      setTimeout(() => {
        this.sizeExceeded = undefined;
      }, 5000);
    }
  }

  onSubmitIdentityProof(): void {
    if (this.identityProofData) {
      this.uploadIdenityProofFiles.emit(this.identityProofData);
    }
  }
}
