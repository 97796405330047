import { createFeatureSelector, createSelector, } from "@ngrx/store";
// Reducers
import { adapterLobbyPregmatic, GamesStateFeatureKey, adapterGames, } from "src/app/modules/game-groups/store/reducers/games.reducer";
// Select the Main State
const selectGamesReducerState = createFeatureSelector(GamesStateFeatureKey);
const ɵ0 = (state) => state.gamesList;
// Select Parts of the state
const selectGamesState = createSelector(selectGamesReducerState, ɵ0);
const ɵ1 = (state) => state.lobbyPregmaticList;
const selectLobbyPregmaticState = createSelector(selectGamesReducerState, ɵ1);
const ɵ2 = (state) => state.lastPlayedList;
export const selectLastPlayedState = createSelector(selectGamesReducerState, ɵ2);
// Games Adapter
const adapterGamesSelection = adapterGames.getSelectors();
// Retrieve All Games - Array Format
export const selectAllGames = createSelector(selectGamesState, adapterGamesSelection.selectAll);
// Retrieve All Games - Dictionary Format
export const selectGamesEntities = createSelector(selectGamesState, adapterGamesSelection.selectEntities);
const ɵ3 = (state) => state;
// Retrieve
export const selectAllGamesIsLoaded = createSelector(selectGamesState, ɵ3);
// Lobby Pregmatic Adapter
const adapterLobbyPregmaticSelection = adapterLobbyPregmatic.getSelectors();
// Retrieve All Lobby Pregmatic - Array Format
export const selectAllLobbyPregmatic = createSelector(selectLobbyPregmaticState, adapterLobbyPregmaticSelection.selectAll);
// Retrieve All Lobby Pregmatic - Dictionary Format
export const selectLobbyPregmaticEntities = createSelector(selectLobbyPregmaticState, adapterLobbyPregmaticSelection.selectEntities);
const ɵ4 = (state) => state.isLoaded;
// Retrieve
export const selectAllLobbyPregmaticIsLoaded = createSelector(selectLobbyPregmaticState, ɵ4);
const ɵ5 = (state) => {
    return {
        isLoaded: state.isLoaded,
        isLoading: state.isLoading,
    };
};
export const selectAllGamesLoadingState = createSelector(selectGamesState, ɵ5);
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5 };
