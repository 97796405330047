import { catchError, map } from "rxjs/operators";
import { throwError } from "rxjs";
// Api Interactors
import { ApiInteractors } from "src/app/models/interactors/api.interactor";
// Enums
import { Project } from "src/app/models/environments/project.enum";
// Environments
import { environment } from "src/environments/environment";
// Utilities
import { supportedMarketsList } from "src/app/modules/multi-languages/utilities/languages.utilities";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class CountryBlockService {
    constructor(httpClient) {
        this.httpClient = httpClient;
        // Strings
        this.languageCode = environment.defaultLanguage;
        // Arrays
        this.acceptedLanguagesList = [];
        this.apiInteractor = new ApiInteractors(this.httpClient);
        this.acceptedLanguagesList = supportedMarketsList();
    }
    // -----------------------------------------------------------------
    // Get Methods
    onGetCountryBlock() {
        return this.apiInteractor
            .get(`/ajax/country/countryblock`, null, Project.Shotz)
            .pipe(map((countryBlockResponse) => {
            return countryBlockResponse;
        }), catchError((error) => {
            return throwError(error);
        }));
    }
}
CountryBlockService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CountryBlockService_Factory() { return new CountryBlockService(i0.ɵɵinject(i1.HttpClient)); }, token: CountryBlockService, providedIn: "root" });
