import { Subject, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
// API Interactors
import { ApiInteractors } from "src/app/models/interactors/api.interactor";
// Enums
import { Project } from "src/app/models/environments/project.enum";
import * as i0 from "@angular/core";
import * as i1 from "./user-details.service";
import * as i2 from "@angular/common/http";
export class ProfileService {
    constructor(userDetailsService, httpClient) {
        this.userDetailsService = userDetailsService;
        this.httpClient = httpClient;
        // Strings
        this.userRegisteredLanguage = "";
        this.userJurisdiction = "";
        this.userCountryCode = "";
        this.languageCode = "";
        // --------------------------------------------------------
        // Subject and Behaviour Subject
        this.userJurisdictionSubject = new Subject();
        this.userJurisdictionSubject$ = this.userJurisdictionSubject.asObservable();
        this.apiInteractor = new ApiInteractors(this.httpClient);
    }
    // -----------------------------------------------------------------
    // Get Methods
    getUserJurisdiction() {
        return this.userJurisdiction;
    }
    // -----------------------------------------------------------------
    // Get Observables
    onGetProfileBalanceCurrency() {
        return this.apiInteractor
            .get(`/ajax/profile/getProfileBalanceCurrency`, null, Project.Shotz)
            .pipe(map((profileBalanceCurrency) => {
            if (profileBalanceCurrency) {
                this.onSetUserJurisdiction(profileBalanceCurrency.profile.jurisdiction);
                this.userDetailsService.onSetUserCurrencyCode(profileBalanceCurrency.currency.code);
                this.userDetailsService.onSetCurrencySymbol(profileBalanceCurrency.currency.symbol);
                this.userDetailsService.onSetUserCurrencyDetails(profileBalanceCurrency.currency);
                this.userDetailsService.onSetUserProfileDetails(profileBalanceCurrency.profile);
                sessionStorage.setItem("_player", btoa(profileBalanceCurrency.profile.playerID));
            }
            return profileBalanceCurrency;
        }), catchError((error) => {
            return throwError(error);
        }));
    }
    onGetUserBalanceByPockets() {
        return this.apiInteractor
            .get(`/ajax/profile/getBalanceByPocket`, null, Project.Shotz)
            .pipe(map((userProfileBalance) => {
            if (userProfileBalance) {
                this.userDetailsService.onSetUserBalanceDetails(userProfileBalance);
            }
            return userProfileBalance;
        }), catchError((error) => {
            return throwError(error);
        }));
    }
    // -----------------------------------------------------------------
    // Set Methods
    onSetUserJurisdiction(jurisdiction) {
        this.userJurisdiction = jurisdiction;
        this.userJurisdictionSubject.next(jurisdiction);
    }
}
ProfileService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ProfileService_Factory() { return new ProfileService(i0.ɵɵinject(i1.UserDetailsService), i0.ɵɵinject(i2.HttpClient)); }, token: ProfileService, providedIn: "root" });
