<ng-container *ngIf="filteredGamesList && filteredGamesList.length>0">
  <ng-container *ngFor="let game of filteredGamesList;let i=index">
    <div class="game-card livecasino-card">
      <app-game-card
        [gameData]="game"
        [isJackpot]="false"
        [gameGroupName]="groupData.name"
        [lobbyName]="activeLobbyName"
        [game_asset_style]="'default-live-casino'"
      >
      </app-game-card>

      <div class="livecasino-card__footer">
        <div class="livecasino-card--game-name">{{game.name}}</div>
        <div class="livecasino-card--provider-name">
          {{game.vendorDisplayName}}
        </div>
      </div>
    </div>
    <ng-container
      *ngIf="((games.length>=playAgainCutOffValue && i === playAgainCutOffValue - 1) || 
        (games.length<playAgainCutOffValue && games.length === i+1)) && activeLobbyName === 'all-live' && isLoggedIn"
    >
      <app-live-casino-play-again
        class="playAgain__wrapper"
      ></app-live-casino-play-again>
    </ng-container>
    <ng-container
      *ngIf="isShowProviderLobby && ((games.length>=providerLobbyCutOffValue && i === providerLobbyCutOffValue - 1) || 
        (games.length < providerLobbyCutOffValue && games.length === i + 1))"
    >
      <app-provider-lobby
        class="liveCasino__side-widgets--item liveCasinoWidgetInMobile"
        [games]="finalLobbyGames"
        *ngIf="finalLobbyGames && finalLobbyGames.length > 0"
        [lobbyProviderData]="lobbyProviderData"
      ></app-provider-lobby>
    </ng-container>
  </ng-container>
</ng-container>
