/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./cookie-message.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "./cookie-message.component";
import * as i5 from "../../../multi-languages/services/translation.service";
import * as i6 from "@ngrx/store";
import * as i7 from "@angular/router";
var styles_CookieMessageComponent = [i0.styles];
var RenderType_CookieMessageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CookieMessageComponent, data: {} });
export { RenderType_CookieMessageComponent as RenderType_CookieMessageComponent };
function View_CookieMessageComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 11, "div", [["class", "notifyMsg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 10, "div", [["class", "notifyMsg__container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "p", [["class", "notifyMsg--text m-b-16"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(6, 0, null, null, 6, "div", [["class", "notifyMsg--btn-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 2, "button", [["aria-label", "Cookie Policy"], ["class", "btn btn-sm btn-secondary btn-round btn-50 m-r-8 cookie-policy-button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onOpenCookiePolicy() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(8, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(10, 0, null, null, 2, "button", [["aria-label", "I Understand"], ["class", "btn btn-sm btn-primary btn-round btn-50"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onAcceptedCookiePolicy() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(11, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform("cookieMsg.cookieMsgText")); _ck(_v, 4, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 8, 0, i1.ɵnov(_v, 9).transform("cookieMsg.cookiePolicy")); _ck(_v, 8, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 11, 0, i1.ɵnov(_v, 12).transform("cookieMsg.iUnderstand")); _ck(_v, 11, 0, currVal_2); }); }
export function View_CookieMessageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_CookieMessageComponent_1)), i1.ɵdid(1, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isCookieMessagePopup; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_CookieMessageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-cookie-message", [], null, null, null, View_CookieMessageComponent_0, RenderType_CookieMessageComponent)), i1.ɵdid(1, 114688, null, 0, i4.CookieMessageComponent, [i5.TranslationService, i6.Store, i7.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CookieMessageComponentNgFactory = i1.ɵccf("app-cookie-message", i4.CookieMessageComponent, View_CookieMessageComponent_Host_0, {}, {}, []);
export { CookieMessageComponentNgFactory as CookieMessageComponentNgFactory };
