import { catchError, map } from "rxjs/operators";
import { throwError } from "rxjs";
// API Interactors
import { ApiInteractors } from "src/app/models/interactors/api.interactor";
// Enums
import { Project } from "src/app/models/environments/project.enum";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class TransactionsService {
    constructor(httpClient) {
        this.httpClient = httpClient;
        this.apiInteractor = new ApiInteractors(this.httpClient);
    }
    // -----------------------------------------------------------------
    // Get Methods - Observables Transaction History
    onGetTransactionHistory(cashierTransactionsRequest) {
        let request = {
            criteria: JSON.stringify(cashierTransactionsRequest),
        };
        return this.apiInteractor
            .post(`/ajax/Report/getTransactionHistory`, request, Project.Shotz)
            .pipe(map((transactionHistoryResponse) => {
            return transactionHistoryResponse;
        }), catchError((error) => {
            return throwError(error);
        }));
    }
    onGetCashierTransactionHistory(cashierTransactionsRequest) {
        let request = {
            criteria: JSON.stringify(cashierTransactionsRequest),
        };
        return this.apiInteractor
            .post(`/ajax/CashierTransactionReport/withdrawalDeposit`, request, Project.Shotz)
            .pipe(map((withdrawalDepositResponse) => {
            return withdrawalDepositResponse;
        }), catchError((error) => {
            return throwError(error);
        }));
    }
    onGetBetTransactions(betTransactionsRequest) {
        let request = {
            criteria: JSON.stringify(betTransactionsRequest),
        };
        return this.apiInteractor
            .post(`/ajax/Report/getTransactionHistory`, request, Project.Shotz)
            .pipe(map((betTransactionsResponse) => {
            return betTransactionsResponse;
        }), catchError((error) => {
            return throwError(error);
        }));
    }
}
TransactionsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TransactionsService_Factory() { return new TransactionsService(i0.ɵɵinject(i1.HttpClient)); }, token: TransactionsService, providedIn: "root" });
