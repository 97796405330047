import { createReducer, on } from "@ngrx/store";
// Actions
import { logoutLoaded } from "src/app/modules/auth/store/actions/auth.actions";
import { userProfileBalanceLoaded, userProfileBalanceError, profileBalanceLoaded, profileBalanceError, } from "src/app/modules/user/store/actions/profile.actions";
export const ProfileStateFeatureKey = "profile";
export const initialState = {
    // Profile Balance
    profileBalance: null,
    isLoaded: false,
    isLoading: false,
    profileBalanceError: null,
    // User Profile Balance
    userProfileBalance: null,
    userProfileBalanceIsLoaded: false,
    userProfileBalanceIsLoading: false,
    userProfileBalanceError: null,
    // User Data
    userData: null,
    userDataIsLoaded: false,
    userDataIsLoading: false,
    userDataError: null,
};
const ɵ0 = (state, { profileBalance }) => {
    return Object.assign({}, state, { profileBalance, isLoading: false, isLoaded: true, profileBalanceError: null, userData: profileBalance.profile });
}, ɵ1 = (state) => {
    return Object.assign({}, state, { profileBalance: null, isLoading: false, isLoaded: false, profileBalanceError: "Error occurred whilst retrieving Profile Balance" });
}, ɵ2 = (state, { userProfileBalance }) => {
    return Object.assign({}, state, { userProfileBalance, userProfileBalanceIsLoading: false, userProfileBalanceIsLoaded: true, userProfileBalanceError: null });
}, ɵ3 = (state) => {
    return Object.assign({}, state, { userProfileBalance: null, userProfileBalanceIsLoading: false, userProfileBalanceIsLoaded: false, userProfileBalanceError: "Error occurred whilst retrieving User Profile Balance" });
}, ɵ4 = (state) => {
    return Object.assign({}, state, { profileBalance: null, isLoading: false, isLoaded: false, profileBalanceError: null, userData: null, userProfileBalance: null, userProfileBalanceIsLoading: false, userProfileBalanceIsLoaded: false, userProfileBalanceError: null });
};
// Reducer
const ProfileReducer = createReducer(initialState, 
// Profile Balance
on(profileBalanceLoaded, ɵ0), on(profileBalanceError, ɵ1), 
// User Profile Balance
on(userProfileBalanceLoaded, ɵ2), on(userProfileBalanceError, ɵ3), 
// Logout
on(logoutLoaded, ɵ4));
export function reducer(state, action) {
    return ProfileReducer(state, action);
}
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4 };
