import {
  ComponentFactoryResolver,
  ComponentFactory,
  ViewContainerRef,
  Directive,
  OnInit,
} from "@angular/core";

// Components
import { PayNPlayCashierComponent } from "src/app/modules/account/components/pay-n-play-cashier/pay-n-play-cashier.component";

@Directive({
  selector: "[playnPlayCashierDir]",
})
export class PlayNPlayCashierDirective implements OnInit {
  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private viewContainerRef: ViewContainerRef
  ) {}

  // -----------------------------------------------------------------
  // Lifecycle Hooks
  ngOnInit(): void {
    this.onAddDynamicComponent();
  }

  // -----------------------------------------------------------------
  // Set Methods
  onAddDynamicComponent(): void {
    const componentFactory: ComponentFactory<PayNPlayCashierComponent> =
      this.componentFactoryResolver.resolveComponentFactory(
        PayNPlayCashierComponent
      );

    const containerRef: ViewContainerRef = this.viewContainerRef;

    containerRef.clear();

    containerRef.createComponent(componentFactory);
  }
}
