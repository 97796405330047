<app-side-menu-modal
  [customClass]="'hostedCashier'"
  [sideMenuTitle]="sideMenuTitleText"
  [isShowBackButton]="isShowBackButton"
>
  <!-- modal body -->
  <ng-container sideMenuBody>
    <ng-container *ngIf="isCashierIFrameLoaded">
      <app-payment-bonus
        *ngIf="cashierType === 'deposit' && !isRedirectNewProviderWindow"
        [activeBonusData]="selectedBonus"
        (callOnSelectBonus)="onSelectedBonus($event)"
        [callingFrom]="'hostedCashier'"
        [rewardSize]="'basic'"
        [clearSelectedBonus]="isClearSelectedBonus"
      ></app-payment-bonus>
    </ng-container>

    <div class="piq-loader" *ngIf="isLoading">
      <app-loader></app-loader>
      <p class="m-t-12 m-b-12 text-center">
        {{'cashier.fetchingPymentMethods' | translate}}
      </p>
    </div>

    <p
      *ngIf="cashierType === 'withdrawal' && isCashierIFrameLoaded"
      class="text-center forefiet-msg-text"
    >
      <strong
        >{{'cashier.withdraw_forefeit_bonus' |
        translate}}</strong
      >
    </p>

    <div id="cashier"></div> </ng-container
></app-side-menu-modal>
