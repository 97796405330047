<div class="rewardBonusCard" *ngIf="bonusDetails && !isLevelUpClaimFailed">
  <div class="rewardBonusCard__bg">
    <img src="/assets/img/rewards/bonus-img/shotz-rewards.png" />
  </div>

  <div class="rewardBonusCard__container">
    <div class="rewardBonusCard__icon">
      <img
        src="/assets/img/rewards/bonus-img/nextlevel-card.png"
        class="rewardBonusCard--chip no-shadow"
      />
    </div>

    <div class="rewardBonusCard__details no-justify-content">
      <div class="rewardBonusCard__text-inside">
        <div class="rewardBonusCard--title">
          {{'rewards.level' | translate}} {{bonusDetails.levelId}}
        </div>

        <div class="rewardBonusCard--desc">
          {{'rewards.unlock_your_reward' | translate}}
        </div>
      </div>

      <button
        aria-label="Open reward"
        class="btn btn-xs btn-primary btn-round m-t-8"
        [setLoader]="isRequestInProcess"
        [disabled]="isRequestInProcess"
        (click)="applyLevelupBonus(bonusDetails.levelId)"
      >
        {{'rewards.open_reward' | translate}}
      </button>

      <button aria-label="Terms" class="btn btn-info">
        <i class="icon-info" (click)="onOpenRewardTCPopUp($event)"></i>
      </button>
    </div>
  </div>
</div>

<div class="rewardBonusCard reward-failed" *ngIf="isLevelUpClaimFailed">
  <div class="rewardBonusCard__bg">
    <img src="/assets/img/rewards/bonus-img/shotz-rewards.png" />
  </div>
  <div class="rewardBonusCard__container">
    <div class="rewardBonusCard__details">
      <div class="rewardBonusCard__text-inside">
        <div class="rewardBonusCard--title">
          {{'rewards.something_wrong' | translate}}
        </div>
        <div class="rewardBonusCard--desc">
          {{'rewards.reward_not_loaded' | translate}}<br />
          
          {{'rewards.check_customer_support' | translate}}
        </div>
      </div>
      <button
        class="btn btn-xs btn-primary btn-round m-t-8"
        aria-label="Chat now"
        (click)="onInitializeChat()"
      >
        {{'rewards.chat_now' | translate}}
      </button>
    </div>
  </div>
</div>
