<main
  class="vp page-body"
  *ngIf="viewType ==='games' || viewType === 'providers'"
>
  <div class="vp__header padding" [class.vp__header--bg]="isPageHeaderBg">
    <button
      *ngIf="deviceType === 'desktop'"
      aria-label="Go back"
      class="btn btn-circle btn-secondary"
      (click)="onGoBack(false)"
    >
      <i class="icon-chevron-left"></i>
    </button>

    <div class="vp__header--title" *ngIf="!isNameLoading; else showloader">
      {{ pageTitle }}
    </div>

    <ng-template #showloader>
      <div
        class="vp__header--title skeleton-loader m-l-16 m-r-16 p-t-8 p-b-8 btn-round"
      ></div>
    </ng-template>

    <button
      *ngIf="deviceType === 'desktop'"
      aria-label="A-Z"
      class="btn btn-secondary btn-sm btn-round"
      [class.active]="sortedBy === 'a-z'"
      (click)="onSortByGameName(viewType)"
    >
      {{ 'gamegroup.A_Z' | translate}}
    </button>

    <button
      *ngIf="deviceType === 'mobile'"
      aria-label="Close"
      class="btn btn-circle btn-secondary"
      (click)="onGoBack(true)"
    >
      <i class="icon-close"></i>
    </button>
  </div>

  <div class="vp__body padding">
    <section
      class="stz-banner clearfix"
      *ngIf="gameGroupDetails && gameGroupDetails.content && isShowBanner"
    >
      <div class="stz-banner__item">
        <div
          class="{{windowType}}-banner banner-type-1"
          [innerHtml]="gameGroupDetails.content | safeHtml"
          (click)="onJoinBanners($event)"
        ></div>
      </div>
    </section>

    <section class="vp__game-container clearfix">
      <ng-container
        *ngIf="((gameGroupDetails && isGameAvailable && filteredGamesList && filteredGamesList.length > 0) 
                || (providerList && providerList.length > 0))"
      >
        <!--
        Below are all the assest which we mainly use in the system
        they are:
        -   0.default
        -   1.default-casino
        -   3.casino-jackpots
        -   4.poster
        -   5.default-live-casino & poster-live-casino
        -   6.quicllinks
        -   7.showcase
        -   8.video showreel
        
        We differentiate/defined game card design & style based on assest type by adding dynamic
        class &
        
        we have three conatiners below 
        -   first container load all the group games marked as
            default, default-casino, poster, casino-jackpot.

        -   second container to load live games assests like default-casino & default-live-casino
            games
        
        -   thrid container will load video showreel games
            
        -   fouth container will load quicllinks
            2nd 3rd 4th container are need as this assest style will be completely different
            & can't accommodate in 1st conatiner it self.
        -->
        <ng-container
          *ngIf="gameGroupDetails.game_asset_style !== 'video-showreel'
                && gameGroupDetails.game_asset_style !== 'quicklink'
                && gameGroupDetails.game_asset_style !== 'default-live-casino'
                && gameGroupDetails.game_asset_style !== 'poster-live-casino'"
        >
          <div
            class="game-card"
            [ngClass]="{
              'poster': (gameGroupDetails.game_asset_style === 'poster' || gameGroupDetails.game_asset_style === 'poster-jackpot'),
              'casino': (gameGroupDetails.game_asset_style !== 'poster' && gameGroupDetails.game_asset_style !== 'poster-jackpot'),
              'x2':gameGroupDetails.game_asset_style === 'poster-jackpot'
            }"
            *ngFor="let game of filteredGamesList; let i = index"
          >
            <app-game-card
              [gameData]="game"
              [callingFrom]="'viewall'"
              [game_asset_style]="gameGroupDetails.game_asset_style"
              [lobbyName]="activeLobby"
              [gameGroupName]="gameGroupDetails.name"
              [isJackpot]="gameGroupDetails.game_asset_style === 'poster-jackpot'? true: false"
            >
            </app-game-card>

            <div class="game-card__footer">
              <div class="game-title">{{game.name}}</div>
            </div>
          </div>
        </ng-container>
        <!--
            Game group whose assests are mapped as default-live-casino or poster
            live casino will be loaded under this conatiner
            
            Right now we are not using this after separate live casino page came into play
            but we may use it leter so please don't delete it
        -->
        <ng-container
          *ngIf="gameGroupDetails.game_asset_style === 'default-live-casino' || gameGroupDetails.game_asset_style  === 'poster-live-casino'"
        >
          <ng-container *ngFor="let game of filteredGamesList; let i = index">
            <div
              class="game-card live-casino"
              [ngClass]="{
                'poster x2 livecasino-card': (gameGroupDetails.game_asset_style === 'poster-live-casino'),
                'casino': (gameGroupDetails.game_asset_style === 'default-live-casino')}"
              *ngIf="game.gameType === 'live_casino'"
            >
              <ng-container
                *ngIf="gameGroupDetails.game_asset_style === 'poster-live-casino'"
              >
                <div class="livecasino-card__inner atViewall">
                  <app-game-card
                    [gameData]="game"
                    [callingFrom]="'viewall'"
                    [lobbyName]="activeLobby"
                    [gameGroupName]="gameGroupDetails.name"
                    [game_asset_style]="gameGroupDetails.game_asset_style"
                    [isJackpot]="false"
                  >
                  </app-game-card>

                  <div class="livecasino-card__vendor-logo">
                    <img
                      src="{{mediaUrlPath}}/poster-vendor/{{game.vendorCode}}.png"
                      alt="{{game.vendorDisplayName}}"
                      title="{{game.vendorDisplayName}}"
                    />
                  </div>
                </div>
                <div class="game-card__footer">
                  <div
                    class="game-title livecasino-card--game-title truncate-text"
                  >
                    {{game.name}}
                  </div>
                </div>
              </ng-container>
              <ng-container
                *ngIf="gameGroupDetails.game_asset_style === 'default-live-casino'"
              >
                <app-game-card
                  [gameData]="game"
                  [callingFrom]="'viewall'"
                  [lobbyName]="activeLobby"
                  [gameGroupName]="gameGroupDetails.name"
                  [game_asset_style]="gameGroupDetails.game_asset_style"
                  [isJackpot]="false"
                >
                </app-game-card>
              </ng-container>
            </div>
          </ng-container>
        </ng-container>
        <!--
            Game group which are marked as 'video-showreel' will be rendered here,
            As the design for this game group will be completely different compare to other
            assest so we load it in separate container
        -->
        <ng-container
          *ngIf="gameGroupDetails.game_asset_style  === 'video-showreel'"
        >
          <div
            class="game-card showreel"
            *ngFor="let game of filteredGamesList; let i = index"
          >
            <div class="hoverme">
              <app-vimeo-player
                [gameData]="game"
                [isLoggedIn]="isLoggedIn"
                (gameLaunch)="onTriggerGameLaunch($event)"
              ></app-vimeo-player>
            </div>
            <div class="game-card__footer">
              <div class="game-title showreel-title">{{game.name}}</div>
              <button
                aria-label="Play Now"
                class="btn btn-primary btn-sm game-cta btn-round"
                (click)="onGameLaunch(game.name,game.hasDemo)"
              >
                {{'general.play_now' | translate}}
              </button>
            </div>
          </div>
        </ng-container>
        <!--
            Game group which are marked as 'quicklink' will be rendered here,
            Here also the design for this game group will be completely different compare to other
            assest so we load it in separate container
        -->
        <ng-container *ngIf="gameGroupDetails.game_asset_style === 'quicklink'">
          <div
            class="game-card game-provider cursor-hand"
            (click)="onShowGamesInProviders(provider.vendorDisplayName)"
            *ngFor="let provider of providerList"
          >
            <span class="game-card__mask">
              <img
                src="{{mediaUrlPath}}/game-provider/{{provider.vendorCode}}.png"
                alt="{{provider.vendorDisplayName }}"
                title="{{provider.vendorDisplayName}}"
              />
            </span>
          </div>
        </ng-container>
      </ng-container>
      <!--
            Container which display when there are no game configured on CMS Basically as we are coming from 
            Viewall option this container will never render but we add it as default fall backend if 
            something goes wrong filtering data or if user directly landed here with invalid group name
      -->
      <ng-container *ngIf="isGameAvailable === false">
        <div class="no-results text-center">
          {{'gamegroup.nogame_found' | translate}}
        </div>
      </ng-container>
      <!--
          Loading selecton comes here which display while fetching of data from serve is in progress
      -->
      <ng-container *ngIf="isLoading">
        <ng-container
          *ngIf="(this.activeGroupName != 'showreels' && this.activeGroupName != 'game studios') || isNameLoading"
        >
          <div
            class="game-card casino noHoverOnSkelton"
            *ngFor="let number of [].constructor(15); let i = index"
          >
            <div class="game-card__body">
              <div class="game-card__mask skeleton-loader"></div>
            </div>
            <div class="game-card__footer">
              <div class="game-title skeleton-loader round-skelton"></div>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="this.activeGroupName === 'showreels' ">
          <div
            class="game-card showreel noHoverOnSkelton"
            *ngFor="let number of [].constructor(12); let i = index"
          >
            <div class="game-card__body">
              <div class="game-card__mask skeleton-loader"></div>
            </div>
            <div class="game-card__footer">
              <button
                aria-label="Loader"
                class="game-cta skeleton-loader round-skelton"
              ></button>
              <div
                class="game-title showreel-title skeleton-loader round-skelton"
              ></div>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="this.activeGroupName === 'game studios'">
          <div
            class="game-card game-provider noHoverOnSkelton skeleton-loader"
            *ngFor="let number of [].constructor(14); let i = index"
          >
            <span class="game-card__mask"> </span>
          </div>
        </ng-container>
      </ng-container>
    </section>

    <section
      class="SEO-content clearfix"
      [innerHtml]="gameGroupDetails.content | safeHtml"
      *ngIf="gameGroupDetails && gameGroupDetails.content"
    ></section>
  </div>
</main>
