import { map, catchError, pairwise, filter } from "rxjs/operators";
import { RoutesRecognized } from "@angular/router";
import { BehaviorSubject, throwError, Subject, of, } from "rxjs";
// Actions
import { userProfileBalanceRequested, profileBalanceRequested, } from "src/app/modules/user/store/actions/profile.actions";
// API Interactors
import { ApiInteractors } from "src/app/models/interactors/api.interactor";
// Configurations
import { marketLocaleCurrencyMappingConfigurations } from "src/app/configurations/main.configurations";
// Enums
import { WorldCurrencyCode } from "src/app/models/configurations/enums/localization/world-currencies.enum";
import { Project } from "src/app/models/environments/project.enum";
// Environments
import { environment } from "src/environments/environment";
// Libraries
import * as _ from "underscore";
// Selectors
import { selectLanguageCode } from "src/app/modules/multi-languages/store/selectors/languages.selectors";
import { selectAuthLoginIsLoggedIn, } from "src/app/modules/auth/store/selectors/auth.selectors";
// Utilities
import { supportedMarketsList } from "src/app/modules/multi-languages/utilities/languages.utilities";
import * as i0 from "@angular/core";
import * as i1 from "../../multi-languages/services/translation.service";
import * as i2 from "../../user/services/user-details.service";
import * as i3 from "@angular/common";
import * as i4 from "../../user/services/profile.service";
import * as i5 from "./main.service";
import * as i6 from "@angular/common/http";
import * as i7 from "@ngrx/store";
import * as i8 from "@angular/router";
export class CommonService {
    constructor(translationService, userDetailService, document, profileService, mainService, httpClient, store, router) {
        this.translationService = translationService;
        this.userDetailService = userDetailService;
        this.document = document;
        this.profileService = profileService;
        this.mainService = mainService;
        this.httpClient = httpClient;
        this.store = store;
        this.router = router;
        // Strings
        this.previousComponentURL = "";
        this.activeAccountTab = "";
        this.activeLobbyName = "";
        this.languageCode = "";
        this.previousURL = "";
        // Booleans
        this.isKycBlocked = true;
        // Others
        this.navigateAfterLogin = {};
        // --------------------------------------------------------
        // Subject - Active Account View
        this.activeAccountViewSubject = new Subject();
        this.activeAccountViewSubject$ = this.activeAccountViewSubject.asObservable();
        // --------------------------------------------------------
        // Subject - Active Left Menu
        this.activeLeftMenuSubject = new Subject();
        this.activeLeftMenuSubject$ = this.activeLeftMenuSubject.asObservable();
        // --------------------------------------------------------
        // Subject - Navigation Data
        this.navigationDataSubject = new Subject();
        this.navigationDataSubject$ = this.navigationDataSubject.asObservable();
        // --------------------------------------------------------
        // Subject - Is Game Window Regily Pop Up Timer Update
        this.isGameWindowRegilyPopUpTimerUpdateSubject = new Subject();
        this.isGameWindowRegilyPopUpTimerUpdateSubject$ = this.isGameWindowRegilyPopUpTimerUpdateSubject.asObservable();
        this.subscriptions = [];
        this.quickDepositToggle = new Subject();
        this.quickDepositToggle$ = this.quickDepositToggle.asObservable();
        this.pendingWithdrawCancel = new Subject();
        this.pendingWithdrawCancel$ = this.pendingWithdrawCancel.asObservable();
        this.updateCountryCode = new Subject();
        this.updateCountryCode$ = this.updateCountryCode.asObservable();
        this.gameplayFooterHover = new BehaviorSubject(false);
        this.gameplayFooterHover$ = this.gameplayFooterHover.asObservable();
        this.isGamesToasterOpen = new BehaviorSubject(false);
        this.isGamesToasterOpen$ = this.isGamesToasterOpen.asObservable();
        this.closeGame = new BehaviorSubject(false);
        this.closeGame$ = this.closeGame.asObservable();
        this.isLiveCasinoPage = new BehaviorSubject(false);
        this.isLiveCasinoPage$ = this.isLiveCasinoPage.asObservable();
        this.apiInteractor = new ApiInteractors(this.httpClient);
        this.subscriptions = [
            this.store
                .select(selectLanguageCode)
                .subscribe((languageCode) => {
                this.languageCode = languageCode;
            }),
            this.store
                .select(selectAuthLoginIsLoggedIn)
                .subscribe((isLoggedIn) => {
                if (isLoggedIn) {
                    this.store.dispatch(profileBalanceRequested());
                    this.store.dispatch(userProfileBalanceRequested());
                }
            }),
            /*
              Game window back navigation handling related code
            */
            this.router.events
                .pipe(filter((evt) => evt instanceof RoutesRecognized), pairwise())
                .subscribe((events) => {
                let urlAfterRedirect = decodeURIComponent(events[0].urlAfterRedirects);
                let temp = this.getDecodedCurrentPath().split("/");
                let translatedString = this.translationService
                    .get("url.game")
                    .toLowerCase();
                if (temp.length > 2 && temp[2] != translatedString) {
                    this.previousComponentURL = urlAfterRedirect;
                    if (temp[2] != "studio") {
                        this.previousURL = this.previousComponentURL;
                    }
                }
                else if (temp.length == 2) {
                    this.previousComponentURL = undefined;
                    this.previousURL = undefined;
                }
            }),
        ];
    }
    // -----------------------------------------------------------------
    // Get Methods
    getActiveLobby() {
        return this.activeLobbyName;
    }
    getNavigateAfterLogin() {
        return this.navigateAfterLogin;
    }
    getActiveAccountMenu() {
        return this.activeAccountTab;
    }
    // -----------------------------------------------------------------
    // Get Observables
    onGetValidateUniqueness(fieldToValidate) {
        let url = "";
        _.each(fieldToValidate, (_, key) => {
            url =
                key == "txtNickname"
                    ? "/ajax/registration/isUniqueNickname"
                    : "/ajax/registration/isUniqueEmail";
        });
        return this.apiInteractor
            .post(url, fieldToValidate, Project.Shotz)
            .pipe(map((validationResponse) => {
            return validationResponse;
        }));
    }
    // -----------------------------------------------------------------
    // Set Methods
    onBroadcastActiveAcountView(activeTab) {
        this.activeAccountViewSubject.next(activeTab);
    }
    onBroadcastActiveLeftMenu(tabName) {
        this.activeLeftMenuSubject.next(tabName);
    }
    onSetActiveLobby(activeLobbyName) {
        this.activeLobbyName = activeLobbyName;
    }
    onBroadcastNavigationData(navigationData) {
        if (navigationData && navigationData.lobby) {
            this.activeLobbyName = navigationData.lobby;
        }
        this.navigationDataSubject.next(navigationData);
    }
    onSetNavigateAfterLogin(navigateAfterLogin) {
        this.navigateAfterLogin = navigateAfterLogin;
    }
    onBroadcastIsGameWindowRegilyPopUpTimerUpdate() {
        this.isGameWindowRegilyPopUpTimerUpdateSubject.next(true);
    }
    // -----------------------------------------------------------------
    // To Discover
    /*
      ApI calls...
    */
    doProfileUpdate(requestObj) {
        return this.mainService.onProfileUpdate(requestObj).pipe(map((response) => {
            if (response) {
                this.userDetailService.onSetUserProfileDetails(response);
            }
            return response;
        }), catchError((error) => {
            return throwError(error);
        }));
    }
    getProfileCountryDetails() {
        if (!_.isEmpty(this.profileCountryDetails)) {
            return of(this.profileCountryDetails);
        }
        else {
            return this.mainService.onGetProfileCountryDetails().pipe(map((response) => {
                this.profileCountryDetails = response;
                return response;
            }), catchError((error) => {
                return throwError(error);
            }));
        }
    }
    onForceGetUserProfileDetails() {
        return this.mainService.apiInteractor
            .get(`/ajax/profile/getBalance`, null, Project.Shotz)
            .pipe(map((balanceDetails) => {
            if (balanceDetails) {
                this.userDetailService.onSetUserBalanceDetails(balanceDetails);
            }
            return balanceDetails;
        }), catchError((error) => {
            return throwError(error);
        }));
    }
    broadcastQuickDepositToggle(flag) {
        this.quickDepositToggle.next(flag);
    }
    broadcastPendingWithdrawCancel(flag) {
        this.pendingWithdrawCancel.next(flag);
    }
    getZendeskToken() {
        return this.mainService.onGetZendeskToken().pipe(map((response) => {
            return response;
        }), catchError((error) => {
            return throwError(error);
        }));
    }
    broadcastUpdateCountryCode(countryCode) {
        this.setCountryCode(countryCode);
        this.updateCountryCode.next(countryCode);
    }
    setCountryCode(countryCode) {
        this.countryCode = countryCode;
    }
    getCountryCode() {
        return this.countryCode;
    }
    setCurrencyByLocality() {
        let languageCodeFromURL = this.getDecodedCurrentPath().split("/")[1];
        if (languageCodeFromURL &&
            languageCodeFromURL !== this.languageCode &&
            _.contains(supportedMarketsList(), languageCodeFromURL)) {
            this.languageCode = languageCodeFromURL;
        }
        if (this.languageCode &&
            marketLocaleCurrencyMappingConfigurations &&
            marketLocaleCurrencyMappingConfigurations.hasOwnProperty(this.languageCode)) {
            let marketLocaleCurrencyMappingConfigClone = Object.assign({}, marketLocaleCurrencyMappingConfigurations);
            this.userDetailService.onSetCurrencySymbol(marketLocaleCurrencyMappingConfigClone[this.languageCode]["currencySymbol"]);
            this.userDetailService.onSetUserCurrencyCode(marketLocaleCurrencyMappingConfigClone[this.languageCode]["currencyCode"]);
        }
        else {
            this.userDetailService.onSetCurrencySymbol(environment.defaultCurrencySymbol);
            this.userDetailService.onSetUserCurrencyCode(WorldCurrencyCode[environment.defaultCurrencyCode]);
        }
    }
    getZendeskRubikoDetails(userName) {
        return this.mainService.getZendeskRubikoDetails(userName).pipe(map((response) => {
            return response;
        }), catchError((error) => {
            return throwError(error);
        }));
    }
    getDecodedCurrentPath() {
        return decodeURIComponent(window.location.pathname);
    }
    broadCastIsLiveCasinoPage(flag) {
        this.isLiveCasinoPage.next(flag);
    }
    updateUserCredentials(updateUserCredentialsRequest) {
        return this.mainService.updateUserCredentails(updateUserCredentialsRequest);
    }
    getUserCountry() {
        if (this.userDetailService.getUserCountryCode()) {
            return this.userDetailService.getUserCountryCode();
        }
        else {
            this.profileService.onGetProfileBalanceCurrency().subscribe((data) => {
                return data["profile"]["country"];
            });
        }
    }
    ngOnDestroy() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
}
CommonService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CommonService_Factory() { return new CommonService(i0.ɵɵinject(i1.TranslationService), i0.ɵɵinject(i2.UserDetailsService), i0.ɵɵinject(i3.DOCUMENT), i0.ɵɵinject(i4.ProfileService), i0.ɵɵinject(i5.MainService), i0.ɵɵinject(i6.HttpClient), i0.ɵɵinject(i7.Store), i0.ɵɵinject(i8.Router)); }, token: CommonService, providedIn: "root" });
