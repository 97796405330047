import { throwError, Subject, Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { map, catchError } from "rxjs/operators";
import { Injectable } from "@angular/core";

// API Interactors
import { ApiInteractors } from "src/app/models/interactors/api.interactor";

// Enums
import { WorldCurrencyCode } from "src/app/models/configurations/enums/localization/world-currencies.enum";
import { Project } from "src/app/models/environments/project.enum";

// Libraries
import * as _ from "underscore";
import * as $ from "jquery";

// Models
import { UserPaymentMethod } from "src/app/modules/transactions/models/user-payment-methods/user-payment-methods-response.model";
import { IFramePayment } from "src/app/modules/account/models/iframe-payment/iframe-payment.model";
import { BonusDescription } from "src/app/modules/account/models/bonus-description.model";
import {
  ProcessPaymentRedirection,
  ProcessPaymentParameter,
} from "src/app/modules/account/models/process-payments/process-payment-response.model";
import { DepositBonus } from "src/app/modules/account/models/deposit-bonus.model";
import { PaymentBonus } from "src/app/modules/account/models/payment-bonus.model";
import { TokenResponse } from "src/app/models/games/token.response";
import { TokenRequest } from "src/app/models/games/token.request";
import {
  EligibleBonusItem,
  CurrencyTierItem,
} from "src/app/modules/rewards/models/eligible-bonus.model";

@Injectable({
  providedIn: "root",
})
export class CashierService {
  // API Interactions
  apiInteractor: ApiInteractors;

  // Objects
  activeDepositBonus: EligibleBonusItem = {
    bonusCode: "",
  };

  // --------------------------------------------------------
  // Subject and Behaviour Subject
  private transactionSuccessStatusSubject: Subject<string> =
    new Subject<string>();
  public transactionSuccessStatusSubject$: Observable<string> =
    this.transactionSuccessStatusSubject.asObservable();

  constructor(private httpClient: HttpClient) {
    this.apiInteractor = new ApiInteractors(this.httpClient);
  }

  // -----------------------------------------------------------------
  // Get Methods
  getActiveDepositBonus(): DepositBonus {
    return this.activeDepositBonus;
  }

  getIframe(redirectOutput: ProcessPaymentRedirection): IFramePayment {
    let $content;

    let $form;

    if (!redirectOutput.html && _.isEmpty(redirectOutput.parameters)) {
      if (redirectOutput.container !== "window") {
        $content = $(
          `<${redirectOutput.container} name="cciFrame" id="loaderIframe" src="${redirectOutput.url}" frameborder="0">
           </${redirectOutput.container}>`
        );
      } else {
        window.location.href = redirectOutput.url;
      }
    } else {
      $content = $(
        `<${redirectOutput.container} name="cciFrame" id="loaderIframe" src="" frameborder="0">
        </${redirectOutput.container}>`
      );

      if (redirectOutput.html) {
        $content = redirectOutput.html;
      } else {
        $form =
          '<form id="proxy_redirect" method="' +
          redirectOutput["method"] +
          '" action="' +
          redirectOutput["url"] +
          '" class="hide" target="_self">';
        _.forEach(redirectOutput["parameters"], function (value, key) {
          if (value != null) {
            $form =
              $form +
              ('<input type="hidden" name="' +
                key +
                '" value="' +
                value +
                '" />');
          }
        });

        $form = $form + "</form>";

        if (!redirectOutput["container"].includes("window")) {
          $form = $($form);
        }
      }
    }

    let iFramePayment: IFramePayment = {
      content: $content,
      form: $form,
    };

    return iFramePayment;
  }

  getIframe2(
    processPaymentRedirection: ProcessPaymentRedirection
  ): IFramePayment {
    let $content: string = "";

    let $form: string = "";

    if (
      !processPaymentRedirection.html &&
      _.isEmpty(processPaymentRedirection.parameters)
    ) {
      if (processPaymentRedirection.container !== "window") {
        $content = $(
          `<${processPaymentRedirection.container}' name="cciFrame" id="loaderIframe" src="${processPaymentRedirection.url}" frameborder="0">
           </${processPaymentRedirection.container}>`
        );
      } else {
        window.location.href = processPaymentRedirection.url;
      }
    } else {
      $content = $(
        `<${processPaymentRedirection.container} name="cciFrame" id="loaderIframe" src="" frameborder="0">
        </${processPaymentRedirection.container}>`
      );

      if (processPaymentRedirection.html) {
        $content = processPaymentRedirection.html;
      } else {
        $form = `<form id="proxy_redirect" method="${processPaymentRedirection.method}" action="${processPaymentRedirection.url}" class="hide" target="_self">`;

        processPaymentRedirection.parameters.forEach(
          (processPaymentParameter: ProcessPaymentParameter) => {
            if (processPaymentParameter.value != null) {
              $form = `${$form}
                <input type="hidden" name="${processPaymentParameter.key}" value="${processPaymentParameter.value}" />`;
            }
          }
        );

        $form = `${$form}</form>`;

        if (!processPaymentRedirection.container.includes("window")) {
          $form = $($form);
        }
      }
    }

    let iFramePayment: IFramePayment = {
      content: $content,
      form: $form,
    };

    return iFramePayment;
  }

  getPaymentMethodsBasedOnBonus(
    selectedBonus: EligibleBonusItem,
    piqMethodsList: UserPaymentMethod[]
  ): PaymentBonus {
    const filteredMethods: PaymentBonus = {
      piqMethods: [],
      nonApplicableBonus: [],
    };

    if (_.isEmpty(selectedBonus) || selectedBonus.depositOptions[0] === "ANY") {
      filteredMethods.piqMethods = piqMethodsList;

      return filteredMethods;
    }

    if (!_.isEmpty(selectedBonus) && selectedBonus.depositOptions) {
      piqMethodsList.forEach((method: UserPaymentMethod, index: number) => {
        if (
          selectedBonus.depositOptions.indexOf(method.providerType) > -1 ||
          selectedBonus.depositOptions.indexOf(
            `${method.providerType}_HOSTED`
          ) > -1
        ) {
          filteredMethods.piqMethods.push(piqMethodsList[index]);
        } else {
          filteredMethods.nonApplicableBonus.push(piqMethodsList[index]);
        }
      });

      return filteredMethods;
    }
  }

  getBonusDescription(
    selectedBonus: EligibleBonusItem,
    inputAmount: number,
    currencyCode: WorldCurrencyCode,
    selectedPaymentMethod: string
  ): BonusDescription | string {
    /*
      Checking whther this bonus is eligible for this selected payment method or not
      Here we add _HOSTED at end of payment selected Method while comparing..
      because we get PIQ methods as _hosted at end
      to differentiate from PS payment methods.
    */
    if (
      selectedBonus.depositOptions.indexOf("ANY") ||
      selectedBonus.depositOptions.indexOf(`${selectedPaymentMethod}_HOSTED`)
    ) {
      /*
        Fetching cuurencyCode bases currencytier
        & sending message
      */
      if (selectedBonus && currencyCode && selectedBonus.currencyTier) {
        const bonusCurrencyTier: CurrencyTierItem =
          selectedBonus.currencyTier[currencyCode][0];

        if (bonusCurrencyTier) {
          const minDepositValue: number =
            bonusCurrencyTier.minDepositValue / 100;

          const maxDepositValue: number =
            bonusCurrencyTier.maxDepositValue / 100;

          const maxOfferValue: number = bonusCurrencyTier.maxOfferValue / 100;

          if (
            selectedBonus.amountType === "ABS" &&
            inputAmount >= minDepositValue
          ) {
            const bonusDescription: BonusDescription = {
              offerType: selectedBonus.amountType,
              maxOfferValue,
              amountAfterBonusApplied:
                Number(maxOfferValue) + Number(inputAmount),
            };

            return bonusDescription;
          } else if (
            selectedBonus.amountType === "PCT" &&
            inputAmount >= minDepositValue
          ) {
            const percentageValue: number = bonusCurrencyTier.percentageValue;

            let offeredValue: number = (inputAmount * percentageValue) / 100;

            if (offeredValue > maxOfferValue) {
              offeredValue = maxOfferValue;
            }

            const bonusDescription: BonusDescription = {
              offerType: selectedBonus.amountType,
              maxOfferValue: offeredValue,
              amountAfterBonusApplied:
                Number(offeredValue) + Number(inputAmount),
            };

            return bonusDescription;
          } else if (inputAmount <= maxDepositValue) {
            return "remove-bonus";
          } else {
            return {};
          }
        }
      }
    } else {
      return "";
    }
  }

  // -----------------------------------------------------------------
  // Observables
  onGetToken(tokenRequest: TokenRequest): Observable<TokenResponse> {
    return this.apiInteractor
      .get<TokenRequest, TokenResponse>(
        `/ajax/token/getToken`,
        tokenRequest,
        Project.Shotz
      )
      .pipe(
        map((tokenResponse: TokenResponse) => {
          return tokenResponse;
        }),
        catchError((error) => {
          if (!tokenRequest || _.isEmpty(tokenRequest) || !tokenRequest.token) {
            return throwError("RequiredDetails Not available");
          }

          return throwError(error);
        })
      );
  }

  // -----------------------------------------------------------------
  // Set Methods - Transaction Success Status
  onSetCastTransactionSuccessStatus(typeOfTransaction: string): void {
    this.transactionSuccessStatusSubject.next(typeOfTransaction);
  }

  // -----------------------------------------------------------------
  // Set Methods - Active Deposit Bonus
  onSetActiveDepositBonus(activeDepositBonus: EligibleBonusItem): void {
    this.activeDepositBonus = activeDepositBonus;
  }
}
