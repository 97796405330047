import { HttpClient } from "@angular/common/http";
import { catchError, map } from "rxjs/operators";
import { Observable, throwError } from "rxjs";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";

// Actions
import { profileBalanceRequested } from "src/app/modules/user/store/actions/profile.actions";
import { loggedInStatus } from "src/app/modules/auth/store/actions/auth.actions";

// API Interactors
import { ApiInteractors } from "src/app/models/interactors/api.interactor";

// Configurations
import { localStorageKeys } from "src/app/modules/multi-languages/configurations/localstorage-keys.configurations";

// Enums
import { Project } from "src/app/models/environments/project.enum";

// Models
import { ChangePasswordResult } from "src/app/modules/auth/models/change-password-result.model";
import { LoginCredentials } from "src/app/modules/registration/models/login-credential.model";
import { LogoutResponse } from "src/app/modules/auth/models/logout/logout-response.model";
import { ChangePassword } from "src/app/modules/auth/models/change-password.model";
import { LoginStatus } from "src/app/modules/auth/models/login-status.model";
import { LoggedIn } from "src/app/modules/auth/models/logged-in.model";

// Reducers
import { AppState } from "src/app/store/reducers";

// Services
import { CashbackPromoService } from "src/app/modules/rewards/services/cashback-promo.service";
import { AffiliateService } from "src/app/modules/auth/services/affiliate.service";
import { SocketService } from "src/app/modules/shared/services/socket.service";
import { SessionService } from "src/app/modules/auth/services/session.service";
import { ProfileService } from "src/app/modules/user/services/profile.service";
import { LoginService } from "src/app/modules/auth/services/login.service";

@Injectable({
  providedIn: "root",
})
export class LoginAPIService {
  // API Interactions
  apiInteractor: ApiInteractors;

  // Date
  userLoggedTime: Date;

  constructor(
    private cashbackPromoService: CashbackPromoService,
    private affiliateService: AffiliateService,
    private profileService: ProfileService,
    private sessionService: SessionService,
    private socketService: SocketService,
    private loginService: LoginService,
    private httpClient: HttpClient,
    private store: Store<AppState>
  ) {
    this.apiInteractor = new ApiInteractors(this.httpClient);
  }

  // -----------------------------------------------------------------
  // Get Methods
  onLogin(credentials: LoginCredentials): Observable<LoggedIn> {
    return this.apiInteractor
      .post<LoginCredentials, LoggedIn>(
        `/ajax/login`,
        credentials,
        Project.Shotz
      )
      .pipe(
        map((loginResponse: LoggedIn) => {
          if (loginResponse && loginResponse.success === true) {
            this.affiliateService.onDeleteAffiliateCookies();

            this.profileService.onSetUserJurisdiction(
              loginResponse.userJurisdiction
            );

            localStorage.setItem(localStorageKeys.stz_user, "true");

            this.store.dispatch(profileBalanceRequested());

            this.loginService.onNavigateToUserProfileLanguage();

            this.socketService.onConnectToSockets(
              loginResponse.pragmaticUrl,
              loginResponse.pragmaticSessionId,
              true
            );

            if (loginResponse.sessionLimit > 0) {
              this.sessionService.onSetSessionVariable("session", {
                sessionLimit: loginResponse.sessionLimit,
                availableSessionLimit: loginResponse.sessionLimit * 60,
              });
            }

            if (loginResponse.userJurisdiction === "estonia") {
              this.userLoggedTime = new Date();

              localStorage.setItem(
                localStorageKeys.loggedInTime,
                this.userLoggedTime.toString()
              );
            }

            this.store.dispatch(loggedInStatus({ isLoggedIn: true }));

            if (loginResponse && loginResponse.user) {
              this.sessionService.onSetSocketDetails(loginResponse.user);
            }

            if (loginResponse.lastLoginTime) {
              this.cashbackPromoService.onSetUserLastAuthTime(
                +loginResponse.lastLoginTime
              );
            }

            this.loginService.onLoginSuccessGtmEvents(loginResponse);
          }

          return loginResponse;
        })
      );
  }

  onChangePassword(
    changePassword: ChangePassword
  ): Observable<ChangePasswordResult> {
    return this.apiInteractor
      .post<ChangePassword, ChangePasswordResult>(
        `/ajax/profile/changePassword`,
        changePassword,
        Project.Shotz
      )
      .pipe(
        map((changePasswordResult: ChangePasswordResult) => {
          return changePasswordResult;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  onGetLoginStatus(): Observable<LoginStatus> {
    return this.apiInteractor
      .get<null, LoginStatus>(`/ajax/login/status`, null, Project.Shotz)
      .pipe(
        map((loginStatus: LoginStatus) => {
          if (loginStatus && loginStatus.status) {
            this.socketService.onConnectToSockets(
              loginStatus.pragmaticUrl,
              loginStatus.pragmaticSessionId,
              true
            );

            localStorage.setItem(localStorageKeys.stz_user, "true");

            /* 
            this double check is need for one different case.
          */
            this.loginService.onVerifyIsLandingPage();

            this.sessionService.onSetSocketDetails({
              message: "",
              pragmaticSessionId: loginStatus.pragmaticSessionId,
              socketUrl: loginStatus.pragmaticUrl,
              partnerID: loginStatus.partnerId,
            });
          } else {
            localStorage.removeItem(localStorageKeys.stz_user);

            sessionStorage.removeItem("session");
          }

          return loginStatus;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  /*
    Here we will logout user..
    even Logout api fails by removing active user &
    active session from sessionStorage
  */
  onGetLogout(): Observable<LogoutResponse> {
    return this.apiInteractor
      .post<null, LogoutResponse>(`/ajax/login/logout`, null, Project.Shotz)
      .pipe(
        map((logoutResponse: LogoutResponse) => {
          return logoutResponse;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
}
