import { createAction, props } from "@ngrx/store";

// Enums
import { LanguageCodes } from "src/app/models/configurations/enums/language-codes.enum";

// Models
import { CountryBlockResponse } from "src/app/modules/country-block/models/country-block-response.model";

export enum CountryBlockActionTypes {
  // Languages
  CountryBlockRequested = "[Country Block API] Country Block Requested",
  CountryBlockRequestedRefresh = "[Country Block API] Country Block Requested Refresh",
  CountryBlockLoaded = "[Country Block API] Country Block Loaded",
  CountryBlockError = "[Country Block API] Country Block Error",
}

// Country Block
export const countryBlockRequested = createAction(
  CountryBlockActionTypes.CountryBlockRequested
);
export const countryBlockRequestedRefresh = createAction(
  CountryBlockActionTypes.CountryBlockRequestedRefresh
);
export const countryBlockLoaded = createAction(
  CountryBlockActionTypes.CountryBlockLoaded,
  props<{ countryBlockResponse: CountryBlockResponse }>()
);
export const countryBlockError = createAction(
  CountryBlockActionTypes.CountryBlockError
);
