import { throwError } from "rxjs";
import {
  SimpleChange,
  EventEmitter,
  Component,
  OnChanges,
  Output,
  Input,
} from "@angular/core";

// Libraries
import Player from "@vimeo/player";

// Models
import { VimeoConfigurations } from "src/app/modules/game-groups/models/vimeo/vimeo-configurations.model";
import { GameLaunch } from "src/app/modules/game-groups/models/game-launch/game-launch.model";
import { GamePregmatic } from "src/app/modules/game-groups/models/game.model";

@Component({
  selector: "app-vimeo-player",
  templateUrl: "./vimeo-player.component.html",
  styleUrls: ["./vimeo-player.component.scss"],
})
export class VimeoPlayerComponent implements OnChanges {
  // Outputs
  @Output() gameLaunch: EventEmitter<GameLaunch> =
    new EventEmitter<GameLaunch>();

  // Inputs
  @Input() isLoggedIn: boolean = false;
  @Input() gameData: GamePregmatic;

  // Booleans
  isVideoEnded: boolean = false;
  isLoading: boolean = false;
  isMutted: boolean = true;
  isPaused: boolean = true;

  // Objects
  playerInstance: Player;

  constructor() {}

  // -----------------------------------------------------------------
  // Lifecycle Hooks
  ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
    if (
      changes["gameData"] &&
      changes["gameData"].previousValue !== changes["gameData"].currentValue
    ) {
      this.gameData = changes["gameData"].currentValue;

      setTimeout(() => {
        this.onInitiateVimeoPlayer(this.gameData.externalAsset);
      });
    }

    if (
      changes["isLoggedIn"] &&
      changes["isLoggedIn"].previousValue !== changes["isLoggedIn"].currentValue
    ) {
      this.isLoggedIn = changes["isLoggedIn"].currentValue;
    }
  }

  // -----------------------------------------------------------------
  // Set Methods
  onInitiateVimeoPlayer(vimeoId: string): void {
    /*
      @property id/url
        Idof vedio or url of vedio need to pass here to create a vedio iframe
        ex: 'https://player.vimeo.in/video/76979871'(url)
        ex:  76979871(ID)
    
      @property speed:true/false
        This flag is need when ur planning to increase play back spped of video
        by using player.setPlaybackRate(value)
        Here value can be b/w 0.5 to 2...
    
      @property loop:true/false
        when this flag is set ture video autoplay again after it ends.
    
      @property speed: true/false
        Set speed flag true to programatically handle speed of the vedio..
    
      @property autopause:true/false
        Pause this video automatically when another one plays.
    */
    const options: VimeoConfigurations = {
      id: vimeoId,
      autopause: true,
      width: "100%",
      muted: true,
      loop: false,
      responsive: true,
      speed: true,
      controls: false,
      title: this.gameData.name,
    };

    this.playerInstance = new Player(`vimeoId${vimeoId}`, options);

    if (this.playerInstance) {
      this.playerInstance.on("play", () => {
        //Do something here if requested...after play btn clicked
      });
    }

    this.playerInstance.on("ended", () => {
      this.isVideoEnded = true;
    });

    this.playerInstance.on("pause", () => {
      this.isPaused = true;
    });
  }

  onSetVolume(): void {
    this.isMutted = !this.isMutted;

    if (this.isMutted) {
      this.playerInstance.setVolume(0);
    } else {
      this.playerInstance.setVolume(1);
    }
  }

  onPlayAndPausedHandler(): void {
    if (this.isVideoEnded) {
      this.isVideoEnded = false;
    }
    if (this.isPaused) {
      this.isLoading = true;
    }

    this.playerInstance.getPaused().then((paused: boolean) => {
      if (paused) {
        this.playerInstance
          .play()
          .then(() => {
            this.isPaused = false;
            this.isLoading = false;
          })
          .catch((error) => {
            this.isLoading = false;

            return throwError(error);
          });
      } else {
        this.playerInstance
          .pause()
          .then(() => {
            this.isPaused = false;
          })
          .catch((error) => {
            return throwError(error);
          });
      }
    });
  }

  onGameLaunchHandler(
    name: string,
    hasDemo: boolean,
    callingFrom: string
  ): void {
    this.gameLaunch.emit({
      name: name,
      hasDemo: hasDemo,
      callingFrom: callingFrom,
    });
  }
}
