/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./news-ticker.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "./news-ticker.component";
import * as i5 from "../../services/news-ticker.service";
import * as i6 from "@ngrx/store";
var styles_NewsTickerComponent = [i0.styles];
var RenderType_NewsTickerComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_NewsTickerComponent, data: {} });
export { RenderType_NewsTickerComponent as RenderType_NewsTickerComponent };
function View_NewsTickerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "newsTicker--list fade-in"], ["id", "news-ticker"]], null, null, null, null, null))], null, null); }
function View_NewsTickerComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "newsTicker--list fade-in"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [["class", "ticker-list"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "p", [["class", "loading"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform("general.loading_text")); _ck(_v, 4, 0, currVal_0); }); }
export function View_NewsTickerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "newsTicker__wrap"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NewsTickerComponent_1)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NewsTickerComponent_2)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (!_co.isLoading && _co.bannerContent); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.isLoading; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_NewsTickerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-news-ticker", [], null, null, null, View_NewsTickerComponent_0, RenderType_NewsTickerComponent)), i1.ɵdid(1, 245760, null, 0, i4.NewsTickerComponent, [i5.NewsTickerService, i6.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NewsTickerComponentNgFactory = i1.ɵccf("app-news-ticker", i4.NewsTickerComponent, View_NewsTickerComponent_Host_0, {}, {}, []);
export { NewsTickerComponentNgFactory as NewsTickerComponentNgFactory };
