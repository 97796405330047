<div
  class="all-games filteredGames-container"
  (scroll)="onLoadMoreGames()"
  *ngIf="providerList && providerList.length"
>
  <ng-container *ngIf="searchBy !== 'games-in-provider'">
    <div class="clearfix" id="providerPosiOnBanner">
      <div class="btn-group atBelowBanner text-center" id="btnPosiOnBanner">
        <button
          aria-label="By provider"
          class="btn btn-lobbyNav btn-sm btn-round"
          (click)="onDisplayByFilterType('providers')"
          [class.active]="searchBy ==='providers'"
        >
          {{ 'gamegroup.By_Provider' | translate}}
        </button>
        <button
          aria-label="A-Z"
          class="btn btn-lobbyNav btn-sm btn-round"
          (click)="onDisplayByFilterType('a-z')"
          [class.active]="searchBy ==='a-z'"
        >
          {{ 'gamegroup.A_Z' | translate }}
        </button>
      </div>

      <ng-container *ngIf="providerList && providerList.length > 0">
        <!--Provider related section comes here-->
        <ng-container *ngIf="searchBy === 'providers'">
          <section class="vp__game-container clearfix">
            <div
              [id]="utilityService.convertGameNameToUrl(provider.vendorDisplayName)"
              class="game-card game-provider cursor-hand"
              *ngFor="let provider of providerList"
            >
              <span
                class="game-card__mask"
                (click)="onNavigateToStudio(provider.vendorDisplayName)"
              >
                <img
                  src="{{mediaUrlPath}}/game-provider/{{provider.vendorCode}}.png{{imgixParams}}"
                  alt="{{provider.vendorDisplayName}}"
                  title="{{provider.vendorDisplayName}}"
                />
              </span>
            </div>
          </section>
        </ng-container>
        <!-- games related section comes here-->
        <ng-container
          *ngIf="(searchBy === 'release-date') || (searchBy === 'a-z')"
        >
          <section class="vp__game-container clearfix">
            <div class="game-card casino" *ngFor="let game of filteredGamesList">
              <app-game-card
                [gameData]="game"
                [isJackpot]="false"
                [game_asset_style]="'default-casino'"
                [lobbyName]="activeLobby"
                [callingFrom]="'all-games'"
              ></app-game-card>
              <div class="game-card__footer">
                <div class="game-title">{{game.name}}</div>
              </div>
            </div>
          </section>
        </ng-container>
      </ng-container>
    </div>
  </ng-container>

  <ng-container *ngIf="searchBy === 'games-in-provider'">
    <main class="vp page-body">
      <div class="vp__header padding" [class.vp__header--bg]="isPageHeaderBackground">
        <button
          *ngIf="windowType === 'device'"
          aria-label="Go back"
          class="btn btn-circle btn-secondary"
          (click)="onGobackToProviders()"
        >
          <i class="icon-chevron-left"></i>
        </button>

        <div class="vp__header--title">
          {{filteredGamesList[0].vendorDisplayName }}
        </div>

        <button
          *ngIf="windowType === 'device'"
          aria-label="A-Z"
          class="btn btn-secondary btn-sm btn-round"
          (click)="onProviderLevelGameSort()"
          [class.active]="isProviderAtoZEnabled"
        >
          {{ 'gamegroup.A_Z' | translate}}
        </button>

        <button
          *ngIf="windowType === 'mobile'"
          aria-label="Close"
          class="btn btn-circle btn-secondary"
          (click)="onGobackToProviders()"
        >
          <i class="icon-close"></i>
        </button>
      </div>

      <div class="vp__body padding">
        <section class="vp__game-container clearfix">
          <div
            class="game-card casino"
            *ngFor="let game of filteredGamesList; let i=index"
          >
            <app-game-card
              [gameData]="game"
              [isJackpot]="false"
              [callingFrom]="'all-games'"
              [lobbyName]="activeLobby"
              [game_asset_style]="'default-casino'"
            ></app-game-card>
            <div class="game-card__footer">
              <div class="game-title">{{game.name}}</div>
            </div>
          </div>
        </section>
      </div>
    </main>
  </ng-container>
</div>

<ng-container *ngIf="providerList && providerList.length === 0">
  <div class="no-results text-center">
    {{ 'gamegroup.gamenot_configured' | translate}}
  </div>
</ng-container>

<ng-container *ngIf="isLoading">
  <div class="no-results text-center">
    <p><app-loader></app-loader></p>
    <p>{{ 'general.loading' | translate}}</p>
  </div>
</ng-container>
