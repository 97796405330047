/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./countdown-timer.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./countdown-timer.component";
import * as i3 from "../../../rewards/services/cashback-promo.service";
import * as i4 from "../../../multi-languages/services/translation.service";
var styles_CountdownTimerComponent = [i0.styles];
var RenderType_CountdownTimerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CountdownTimerComponent, data: {} });
export { RenderType_CountdownTimerComponent as RenderType_CountdownTimerComponent };
export function View_CountdownTimerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.countTimer; _ck(_v, 1, 0, currVal_0); }); }
export function View_CountdownTimerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-countdown-timer", [], null, null, null, View_CountdownTimerComponent_0, RenderType_CountdownTimerComponent)), i1.ɵdid(1, 704512, null, 0, i2.CountdownTimerComponent, [i3.CashbackPromoService, i4.TranslationService], null, null)], null, null); }
var CountdownTimerComponentNgFactory = i1.ɵccf("app-countdown-timer", i2.CountdownTimerComponent, View_CountdownTimerComponent_Host_0, { expiryDate: "expiryDate", callingFrom: "callingFrom", promoRaceId: "promoRaceId" }, {}, []);
export { CountdownTimerComponentNgFactory as CountdownTimerComponentNgFactory };
