<div
  class="gameBgColor"
  *ngIf="!isGameMinimized && this.windowType === 'device'"
>
  <img
    class="gameBgImg"
    alt="{{this.activeGameName}}"
    src="{{gamesUrlPath}}/{{activeGameDataGamePregmatic?.gameCode}}/{{activeGameDataGamePregmatic?.gameCode}}_casino.jpg{{imgixParams}}"
  />
</div>

<div class="page-body game-page">
  <!-----realplay-popup top--------->
  <div
    class="realplay-popup normal-mode fade-in clearfix"
    *ngIf="isLoggedIn  && gameCalledFrom === 'freegame' && !isGameMinimized"
  >
    <div class="realplay-popup__inner">
      {{'gameplay.you_are_playing_free' | translate}}
      <button
        aria-label="PLay with real money"
        class="btn btn-sm btn-primary btn-round"
        (click)="onPlayRealGame()"
      >
        {{'gameplay.play_realmoney' | translate}}
      </button>
    </div>
  </div>
  <!-----realplay-popup top--------->

  <div
    class="game-canvas"
    [ngStyle]="{'width': windowHeight * ((aspectRatio == 2) ? 1.33 : 1.78)+'px'}"
    [ngClass]="{'isPopout fade-in-up': isGameMinimized, 'isNotLoggedIn': !isLoggedIn, 'qd-active':isQuickDepositOpen}"
    [class.screenHeight]="activeGameDataGamePregmatic && activeGameDataGamePregmatic?.gdContent && !isGameMinimized && windowType === 'device'"
  >
    <div
      class="game-height"
      [ngClass]="[aspectRatio == 2 ? 'aspectRatio-4by3': 'aspectRatio-16by9']"
    >
      <div class="game-height__holder" id="makeFullscreenFrame">
        <div class="game-controls" *ngIf="!isGameMinimized">
          <button
            aria-label="Close"
            id="closeGame"
            class="btn btn-circle btn-gameControls"
            (click)="onCloseGame()"
          >
            <i class="icon-close"></i>
          </button>
          <button
            aria-label="Minimize game window"
            class="btn btn-circle btn-gameControls hideInFullScreen"
            (click)="onMakeMinimizedGame()"
          >
            <i class="icon-chevron-down"></i>
          </button>

          <button
            aria-label="Fullscreen game window"
            class="btn btn-circle btn-gameControls fullscreen hideInFullScreen"
            (click)="onMakeFullScreen()"
          >
            <i class="icon-fullscreen"></i>
          </button>
          <button
            aria-label="Fullscreen exit game window"
            class="btn btn-circle btn-gameControls fullscreen showInFullScreen"
            (click)="onExitFullscreen()"
          >
            <i class="icon-fullscreen-exit"></i>
          </button>
        </div>

        <div
          class="isMinimized"
          *ngIf="isGameMinimized"
          (click)="onMakeMaximizeGame()"
        >
          <i class="icon-play"></i>
        </div>
        <div class="game-is-loading">
          <app-loader></app-loader>
        </div>
        <div id="gameIframeWrapper" class="gameIframeWrapper"></div>

        <!-----realplay-popup Fullscreen--------->
        <div
          class="realplay-popup atFullscreen fade-in clearfix"
          *ngIf="isLoggedIn  && gameCalledFrom === 'freegame' && !isGameMinimized && this.windowType === 'device'"
        >
          <div class="realplay-popup__inner">
            {{'gameplay.you_are_playing_free' | translate}}
            <button
              aria-label="Play with realmoney"
              class="btn btn-sm btn-primary btn-round"
              (click)="onPlayRealGame()"
            >
              {{'gameplay.play_realmoney' | translate}}
            </button>
          </div>
        </div>
        <!-----realplay-popup top--------->
      </div>

      <button
        aria-label="Close"
        class="desktop btn-circle btn-secondary btn-close"
        *ngIf="isGameMinimized"
        (click)="onClosePopout()"
      >
        <i class="icon-close"></i>
      </button>

      <div class="mobile-popout">
        <div class="text" (click)="onMakeMaximizeGame()">
          <h5>{{'gameplay.continue_playing' | translate}}</h5>
          <p class="game-name">{{this.activeGameName}}</p>
        </div>
        <button
          aria-label="Close"
          class="btn-close-mobile"
          (click)="onClosePopout()"
        >
          <i class="icon-close"></i>
        </button>
      </div>
    </div>

    <div
      class="meta-content game-meta"
      *ngIf="activeGameDataGamePregmatic && activeGameDataGamePregmatic?.gdContent && !isGameMinimized"
    >
      <div [innerHtml]="activeGameDataGamePregmatic.gdContent"></div>
    </div>
  </div>

  <div
    class="gameplay-fixedfooter"
    [ngClass]="this.windowType === 'device' && !isGameMinimized ? 'show' : 'hide'"
  >
    <div class="tags-bottom">
      <app-tag-game
        [gameSpecificTag]="activeGameDataGamePregmatic && activeGameDataGamePregmatic.tagList"
      >
      </app-tag-game>
    </div>
  </div>
  <div class="mob_IOS_hide_menu"></div>
</div>

<section
  class="c-dialog__backdrop"
  *ngIf="isLoggedIn && isMaxBetPopup"
  id="maxBetPopup"
>
  <div class="c-dialog">
    <div class="c-dialog__header">
      <img
        src="/assets/img/logo.svg"
        alt="{{'general.site_name' | translate}}"
        width="100%"
        height="100%"
      />
    </div>
    <div class="c-dialog__body">
      <p>{{maxBetWarningMessage}}</p>
    </div>
    <div class="c-dialog__footer">
      <a
        aria-label="Max bet"
        class="btn-yes"
        (click)="onCloseMaxBetWarningPopup()"
        >{{'gameplay.max_bet_cta' | translate}}</a
      >
    </div>
  </div>
</section>

<div
  class="leftnav__footer user-time atGameWindow"
  *ngIf="!isGameMinimized && this.windowType === 'device'"
  [ngClass]="{'gameplayFooterHover':isGamePlayFooterHover,'gameplayHover':isGamesToasterOpen}"
  (mouseenter)="onHoverBottomRibbon()"
  (mouseleave)="onMouseLeaveBottomRibbon()"
>
  <app-time></app-time>
  <span
    class="menu-icon nav-icon"
    (click)="onOpenAccountMenuList()"
    *ngIf="isLoggedIn"
    ><i class="icon-menu"></i
  ></span>
  <span class="user-chat nav-icon" (click)="onInitLiveChat()" *ngIf="!isLoggedIn"
    ><i class="icon-chat"></i
  ></span>
</div>
