import { enableProdMode } from "@angular/core";
// Environments
import { environment } from "src/environments/environment";
import { defineCustomElements } from "livespins.stencil/loader";
import * as __NgCli_bootstrap_1 from "./app/app.module.ngfactory";
import * as __NgCli_bootstrap_2 from "@angular/platform-browser";
if (environment.production) {
    enableProdMode();
}
__NgCli_bootstrap_2.platformBrowser()
    .bootstrapModuleFactory(__NgCli_bootstrap_1.AppModuleNgFactory)
    .catch((err) => console.error(err));
defineCustomElements(window);
