import { Subscription } from "rxjs";
import {
  EventEmitter,
  SimpleChange,
  Component,
  OnChanges,
  Output,
  Input,
} from "@angular/core";

// Enums
import { WorldCurrencyCode } from "src/app/models/configurations/enums/localization/world-currencies.enum";

// Environments
import { environment } from "src/environments/environment";

// Libraries
import _ from "underscore";

// Models
import { CampaignItem } from "src/app/modules/rewards/models/campaign-items.model";

// Services
import { UserDetailsService } from "src/app/modules/user/services/user-details.service";

@Component({
  selector: "app-cashout-reward",
  templateUrl: "./cashout-reward.component.html",
  styleUrls: ["./cashout-reward.component.scss"],
})
export class CashoutRewardComponent implements OnChanges {
  // Outputs
  @Output() callOpenRewardTCPopUp: EventEmitter<MouseEvent> =
    new EventEmitter<MouseEvent>();
  @Output() callOnBonusSelected: EventEmitter<CampaignItem> =
    new EventEmitter<CampaignItem>();
  @Output() callOnOpenDeposit: EventEmitter<CampaignItem> =
    new EventEmitter<CampaignItem>();

  // Inputs
  @Input() currencyCode: WorldCurrencyCode;
  @Input() isRealUser: boolean = false;
  @Input() selectedBonus: CampaignItem;
  @Input() bonusDetails: CampaignItem;
  @Input() callingFrom: string = "";
  @Input() rewardSize: string = "";

  // Strings
  gameUrlPath: string = environment.gamesUrlPath;
  apiUrlPath: string = environment.apiUrl;
  currencySymbol: string = "";

  // Subscriptions
  subscriptions: Subscription[] = [];

  constructor(private userDetailsService: UserDetailsService) {}

  // -----------------------------------------------------------------
  // Lifecycle Hooks
  ngOnInit(): void {
    this.subscriptions = [
      this.userDetailsService.currencySymbolBehaviourSubject$.subscribe(
        (currencySymbol: string) => {
          this.currencySymbol = currencySymbol;
        }
      ),
    ];
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
    if (
      changes["bonusDetails"] &&
      changes["bonusDetails"].previousValue !==
        changes["bonusDetails"].currentValue
    ) {
      this.bonusDetails = changes["bonusDetails"].currentValue;
    }

    if (
      changes["callingFrom"] &&
      changes["callingFrom"].previousValue !==
        changes["callingFrom"].currentValue
    ) {
      this.callingFrom = changes["callingFrom"].currentValue;
    }

    if (
      changes["rewardSize"] &&
      changes["rewardSize"].previousValue !== changes["rewardSize"].currentValue
    ) {
      this.rewardSize = changes["rewardSize"].currentValue;
    }

    if (
      changes["isRealUser"] &&
      changes["isRealUser"].previousValue !== changes["isRealUser"].currentValue
    ) {
      this.isRealUser = changes["isRealUser"].currentValue;
    }

    if (
      changes["currencyCode"] &&
      changes["currencyCode"].previousValue !==
        changes["currencyCode"].currentValue
    ) {
      this.currencyCode = changes["currencyCode"].currentValue;
    }

    if (
      changes["selectedBonus"] &&
      changes["selectedBonus"].previousValue !==
        changes["selectedBonus"].currentValue
    ) {
      let selectedBonus: CampaignItem = changes["selectedBonus"].currentValue;

      if (this.selectedBonus && this.selectedBonus.bonusCode) {
        this.selectedBonus = selectedBonus;
      }
    }
  }

  // -----------------------------------------------------------------
  // Set Methods
  onSelectBonus(bonusDetails: CampaignItem): void {
    this.callOnBonusSelected.emit(bonusDetails);
  }

  onOpenDepositView(bonusDetails: CampaignItem): void {
    this.callOnOpenDeposit.emit(bonusDetails);
  }

  onOpenRewardTCPopUp(event: MouseEvent): void {
    this.callOpenRewardTCPopUp.emit(event);
  }

  // -----------------------------------------------------------------
  // On Destroy
  ngOnDestory(): void {
    this.subscriptions.forEach((subscription: Subscription) =>
      subscription.unsubscribe()
    );
  }
}
