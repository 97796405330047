<footer class="footer padding page-body footer-conatiner #footerConatiner">
  <ng-container *ngIf="isLanguageOpen">
    <div class="overlay-backdrop--footer"></div>
  </ng-container>

  <ng-container
    *ngIf="excludedLangaugesList.indexOf(languageCode) <= -1 && !isLoggedIn && isShowLanguageSelectorEnabled"
  >
    <div class="language--wrapper">
      <div class="language--container" [class.isLangOpen]="isLanguageOpen">
        <div
          class="language--active"
          (click)="isLanguageOpen = !isLanguageOpen"
        >
          <a href="javascript:void(0)">
            <img
              src="./assets/img/flag/{{ this.languageCode }}.png"
              alt="flag"
            />
            <span class="">{{ localeDisplayName[this.languageCode] }}</span>
          </a>
        </div>

        <ng-container *ngIf="isLanguageOpen">
          <div
            class="language--dropdown"
            [ngClass]="{
              'isOpen fade-in-up': true,
              isClose: false
            }"
          >
            <a
              href="javascript:void(0)"
              *ngFor="let language of acceptedLanguagesList"
              [class.hide]="languageCode === language"
              (click)="onChangeLanguageHandler(language)"
            >
              <img
                src="./assets/img/flag/{{ language }}.png"
                alt="{{ localeDisplayName[language] }}"
              />

              <span>
                {{ localeDisplayName[language] }}
              </span>
            </a>
          </div>
        </ng-container>
      </div>

      <div class="chat-icon">
        <i class="icon icon-chat" (click)="onInitializeLiveChat()"></i>
      </div>
    </div>
  </ng-container>

  <!-- Dynamically footer coming from CMS...-->
  <div
    id="dynamicFooterContent"
    (click)="onDynamicFooterContent($event)"
    [innerHtml]="staticContent | safeHtml"
  ></div>
</footer>
