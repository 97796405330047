import { BehaviorSubject } from "rxjs";
// Configurations
import { menuConfigurations } from "src/app/configurations/menu.configurations";
import { localToCmsLanguageConfigurations, accountViewConfigurations, bannerCTAConfigurations, } from "src/app/configurations/main.configurations";
// Enums
import { WorldCurrencyCode } from "src/app/models/configurations/enums/localization/world-currencies.enum";
// Environments
import { environment } from "src/environments/environment";
// Libraries
import * as _ from "underscore";
// Selectors
import { selectLanguageCode } from "src/app/modules/multi-languages/store/selectors/languages.selectors";
import { selectAuthLoginIsLoggedOut, selectAuthLoginIsLoggedIn, } from "src/app/modules/auth/store/selectors/auth.selectors";
import * as i0 from "@angular/core";
import * as i1 from "../../account/services/pay-n-play-cashier.service";
import * as i2 from "../../multi-languages/services/multi-language.service";
import * as i3 from "../../user/services/user-details.service";
import * as i4 from "../../multi-languages/services/translation.service";
import * as i5 from "./emitter.service";
import * as i6 from "../../account/services/cashier.service";
import * as i7 from "../../auth/services/session.service";
import * as i8 from "./common.service";
import * as i9 from "@ngrx/store";
import * as i10 from "@angular/platform-browser";
import * as i11 from "@angular/router";
export class UtilityService {
    constructor(payNPlayCashierService, multiLanguageService, userDetailsService, translationService, emitterService, cashierService, sessionService, commonService, store, metaService, router) {
        this.payNPlayCashierService = payNPlayCashierService;
        this.multiLanguageService = multiLanguageService;
        this.userDetailsService = userDetailsService;
        this.translationService = translationService;
        this.emitterService = emitterService;
        this.cashierService = cashierService;
        this.sessionService = sessionService;
        this.commonService = commonService;
        this.store = store;
        this.metaService = metaService;
        this.router = router;
        // Numbers
        this.userTotalBalance = 0;
        // Strings
        this.currencyCode = WorldCurrencyCode.EUR;
        this.activeLeftMenuOption = "";
        this.previousContent = "";
        this.languageCode = "";
        // Booleans
        this.isLoggedIn = false;
        // Subject and BehaviorSubject
        // Subscriptions
        this.subscriptions = [];
        this.gameplayFooterHover = new BehaviorSubject(false);
        this.gameplayFooterHover$ = this.gameplayFooterHover.asObservable();
        this.isGamesToasterOpen = new BehaviorSubject(false);
        this.isGamesToasterOpen$ = this.isGamesToasterOpen.asObservable();
        this.closeGame = new BehaviorSubject(false);
        this.closeGame$ = this.closeGame.asObservable();
        this.onLoad();
    }
    // -----------------------------------------------------------------
    // Get Methods
    getQueryParams() {
        if (window.location.search) {
            return JSON.parse('{"' +
                decodeURI(window.location.search
                    .substring(1)
                    .replace(/&/g, '","')
                    .replace(/=/g, '":"')) +
                '"}');
        }
        else {
            return {};
        }
    }
    getBrandMenuConfigurationType(key) {
        const menu = menuConfigurations();
        return _.clone(menu[key]);
    }
    getFilterLobbyListByLobbyName(lobbyPregmaticList, lobbyName) {
        let filterredLobbyPregmaticList = lobbyPregmaticList.filter((lobbyPregmatic) => {
            return lobbyPregmatic.name.toLowerCase() === lobbyName.toLowerCase();
        });
        return filterredLobbyPregmaticList.length > 0
            ? this.getSortedLobbyGamesGroupByCountry(filterredLobbyPregmaticList[0].gameGroupList)
            : [];
    }
    getFilterLobbyByLobbyName(lobbyPregmaticList, lobbyName) {
        let filterredLobbyPregmaticList = [];
        if (lobbyPregmaticList && lobbyPregmaticList.length > 0) {
            filterredLobbyPregmaticList = lobbyPregmaticList.filter((lobbyPregmatic) => {
                return lobbyPregmatic.name.toLowerCase() === lobbyName.toLowerCase();
            });
        }
        return filterredLobbyPregmaticList.length > 0
            ? filterredLobbyPregmaticList[0]
            : null;
    }
    getSortedUniqueProviderList(gamesList) {
        /*
          This condition " game['gameGroupList'] ? game['gameGroupList']['provider_order'] : 0" is not needed
          here...because gameGroupList is already filter to only single object & it exist all time...
          But for double check we have added it.
        */
        if (gamesList && gamesList.length > 0) {
            let providerList = _.map(gamesList, (gamePregmatic) => {
                return {
                    vendorCode: gamePregmatic.vendorCode,
                    vendorDisplayName: gamePregmatic.vendorDisplayName,
                    vendorName: gamePregmatic.vendorName,
                    provider_order: gamePregmatic.gameGroupList
                        ? gamePregmatic.gameGroupList.provider_order
                        : 0,
                };
            });
            if (providerList) {
                let uniqueProviderList = _.uniq(providerList, false, (provider) => {
                    return provider.vendorCode;
                });
                uniqueProviderList = _.sortBy(uniqueProviderList, "provider_order").reverse();
                return uniqueProviderList;
            }
            else {
                return [];
            }
        }
    }
    getProviderList(gamesList) {
        let uniqueProviderList = [];
        if (gamesList && gamesList.length > 0) {
            let gameProvidersList = _.map(gamesList, (gamePregmatic) => {
                return {
                    vendorCode: gamePregmatic.vendorCode,
                    vendorDisplayName: gamePregmatic.vendorDisplayName,
                    vendorName: gamePregmatic.vendorName,
                    provider_order: gamePregmatic.gameGroupList
                        ? gamePregmatic.gameGroupList.provider_order
                        : 0,
                    isChecked: true,
                };
            });
            if (gameProvidersList) {
                uniqueProviderList = _.uniq(gameProvidersList, false, (gameProvider) => {
                    return gameProvider.vendorCode;
                });
            }
            else {
                uniqueProviderList = [];
            }
        }
        return uniqueProviderList;
    }
    getGamesListByProviderName(gamesList, providerName) {
        if (gamesList) {
            return gamesList.filter((gamePregmatic) => gamePregmatic.vendorCode == providerName);
        }
        else {
            return [];
        }
    }
    getSortedGamesByGameName(gamesList, key) {
        let sortedGamesList = _.sortBy(gamesList, key);
        return sortedGamesList;
    }
    getSortedGamesByReleaseDate(gamesList, key) {
        let sortedGamesList = _.sortBy(gamesList, (gamePregmatic) => {
            return gamePregmatic[key];
        });
        return sortedGamesList;
    }
    getSortedLobbyGamesGroupByCountry(lobbyGamesGroupList) {
        let sortedLobbyGameGroupList = _.sortBy(lobbyGamesGroupList, (lobbyGameGroup) => {
            return lobbyGameGroup.game_group_order;
        });
        return sortedLobbyGameGroupList.reverse();
    }
    getSortedGamesListByCountry(gamesList) {
        let sortedGamesList = _.sortBy(gamesList, (gamePregmatic) => {
            return gamePregmatic.gameGroupList
                ? gamePregmatic.gameGroupList.game_order
                : 0;
        });
        return sortedGamesList.reverse();
    }
    // -----------------------------------------------------------------
    // Set Methods
    onLoad() {
        this.isLoggedIn = this.sessionService.getIsUserLoggedIn();
        this.subscriptions = [
            this.store
                .select(selectLanguageCode)
                .subscribe((languageCode) => {
                this.languageCode = languageCode;
            }),
            this.store
                .select(selectAuthLoginIsLoggedIn)
                .subscribe((isLoggedIn) => (this.isLoggedIn = isLoggedIn)),
            this.store
                .select(selectAuthLoginIsLoggedOut)
                .subscribe((isLoggedOut) => {
                if (isLoggedOut) {
                    this.isLoggedIn = false;
                }
            }),
            this.userDetailsService.currencyCodeBehaviourSubject$.subscribe((currencyCode) => {
                this.currencyCode = currencyCode;
            }),
        ];
    }
    onAddClassToAppBody(className) {
        const documentBody = document.body;
        if (documentBody && !documentBody.classList.contains(className)) {
            documentBody.classList.add(className);
        }
    }
    onRemoveClassFromAppBody(className) {
        const documentBody = document.body;
        if (documentBody && documentBody.classList.contains(className)) {
            documentBody.classList.remove(className);
        }
    }
    /*
      All the navigation related to cms banner will be handler here Itself
      irrespective of where it loads on website...
    */
    onCmsRoutingHandler(routerPath, data) {
        const accountRoutingConfig = accountViewConfigurations;
        if (routerPath && routerPath === "login") {
            if (!this.isLoggedIn) {
                this.initiateLogin();
            }
        }
        else if (routerPath &&
            accountRoutingConfig &&
            accountRoutingConfig.indexOf(routerPath) > -1) {
            if (this.isLoggedIn) {
                if (routerPath === "deposit") {
                    this.initiateDeposit(true);
                }
                else if (routerPath === "withdrawal") {
                    this.initiateWithdraw();
                }
                else {
                    const activeTab = {
                        tabName: routerPath,
                        showBackButton: true,
                    };
                    this.openAccountComponent(activeTab);
                }
                if (data && data.bonusCode) {
                    this.cashierService.onSetActiveDepositBonus({
                        bonusCode: data.bonusCode,
                    });
                }
            }
            else if (!this.isLoggedIn && this.isPnpFlow()) {
                if (routerPath === "deposit") {
                    this.payNPlayCashierService.onBroadcastInitiateDeposit({
                        callingFrom: "deepLink",
                    });
                }
                else if (routerPath === "withdrawal") {
                    this.payNPlayCashierService.onBroadcastInitiateWithdraw();
                }
            }
            else {
                this.initiateLogin();
                this.commonService.onSetNavigateAfterLogin(data
                    ? { type: "view", path: routerPath, data: data }
                    : { type: "view", path: routerPath });
            }
        }
        else if (routerPath &&
            this.multiLanguageService.getActiveLobby(routerPath.split("/")[1]) ===
                "game") {
            setTimeout(() => {
                if (this.isLoggedIn) {
                    this.router.navigate([`${this.languageCode}/${routerPath}`]);
                }
                else {
                    this.commonService.onSetNavigateAfterLogin({
                        type: "url",
                        data: { navigateTo: "gameWindow", path: routerPath.split("/")[2] },
                    });
                    this.initiateLogin();
                }
            });
        }
        else if (routerPath) {
            this.router.navigate([`${this.languageCode}/${routerPath}`]);
        }
        else {
            this.router.navigate([`${this.languageCode}/casino`]);
        }
        window.scrollTo({ top: 0, behavior: "smooth" });
    }
    onToggleCMSButtons() {
        const loggedIn = document.querySelectorAll(".logged-in-user");
        const loggedOut = document.querySelectorAll(".logged-out-user");
        if (this.isLoggedIn) {
            loggedIn.forEach((element) => element.classList.remove("hide"));
            loggedOut.forEach((element) => element.classList.add("hide"));
        }
        else {
            loggedIn.forEach((element) => element.classList.add("hide"));
            loggedOut.forEach((element) => element.classList.remove("hide"));
        }
    }
    onNavigateAllGamesPage(providerPregmatic, providerName) {
        if (providerPregmatic &&
            this.languageCode &&
            providerPregmatic.multilingualUrlGameGroup &&
            providerPregmatic.multilingualUrlGameGroup[localToCmsLanguageConfigurations[this.languageCode]]) {
            window.scrollTo(0, 0);
            let multiLingualUrlGameGroupName = providerPregmatic.multilingualUrlGameGroup[localToCmsLanguageConfigurations[this.languageCode]];
            let allGamesPathURL = multiLingualUrlGameGroupName
                .toLowerCase()
                .trim();
            if (providerName) {
                this.router.navigate([
                    `${this.languageCode}/${this.translationService.get(`url.casino`)}/${allGamesPathURL}/${this.convertGameNameToUrl(providerName)}`,
                ]);
            }
            else {
                this.router.navigate([`${this.languageCode}/${allGamesPathURL}`]);
            }
        }
    }
    // -------------------------------------------------------
    // To Discover
    openAccountComponent(viewName) {
        if (viewName) {
            this.commonService.onBroadcastActiveAcountView(viewName);
        }
        else {
            this.closeAccountComponent();
        }
    }
    closeAccountComponent(navigateTo) {
        this.removeUrlQueryParams();
        if (navigateTo === "menuOptions") {
            const activeTab = {
                tabName: "menuOptions",
                showBackButton: true,
            };
            this.commonService.onBroadcastActiveAcountView(activeTab);
        }
        else {
            const activeTab = {
                tabName: "",
                showBackButton: true,
            };
            this.commonService.onBroadcastActiveAcountView(activeTab);
        }
    }
    getSessionTimeText(minutes) {
        let sessionTimeText = "";
        if (minutes <= 60) {
            sessionTimeText = `${minutes} ${this.translationService.get("limits.minutes")}`;
        }
        else {
            let hours = Math.floor(minutes / 60);
            let mins = minutes % 60;
            if (hours && mins && mins !== 0) {
                mins = mins < 10 ? "0" + mins : mins;
                sessionTimeText = `${hours} ${this.translationService.get("limits.hours")} ${mins} ${this.translationService.get("limits.minutes")}`;
            }
            else {
                sessionTimeText = `${hours} ${this.translationService.get("limits.hours")}`;
            }
        }
        return sessionTimeText;
    }
    addMetaTag(metaTag, metaDescription) {
        if (metaDescription && metaDescription !== this.previousContent) {
            this.removeMetaTag();
            this.previousContent = metaDescription;
            this.metaService.addTag({
                name: "description",
                content: metaDescription,
            });
        }
    }
    removeMetaTag() {
        this.metaService.removeTag("name='description'");
    }
    scrollAddClass() {
        const doc = document.documentElement;
        if (doc) {
            const top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
            return top > 25 ? true : false;
        }
        else {
            return false;
        }
    }
    convertGameNameToUrl(gameName) {
        return gameName
            .replace(/[\W_]+/g, " ")
            .trim()
            .toLowerCase()
            .split(" ")
            .join("-");
    }
    convertSpecialCharactersWithSpace(gameName) {
        return gameName
            .toUpperCase()
            .replace(/[\W_]+/g, " ")
            .trim();
    }
    capitalizeFirstLetter(providerName) {
        return (providerName.charAt(0).toUpperCase() +
            providerName
                .slice(1)
                .replace(/[\W_]+/g, " ")
                .trim());
    }
    backNavigationURlHandler() {
        if (!this.commonService.previousComponentURL) {
            this.router.navigate([`${this.languageCode}/casino`]);
        }
        else {
            this.router.navigate([this.commonService.previousComponentURL]);
        }
    }
    scrollToFragment(location) {
        const element = document.querySelector(`#${location}`);
        if (element) {
            setTimeout(() => {
                element.scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                    inline: "nearest",
                });
            }, 1000);
        }
    }
    updateActiveLeftMenu(name) {
        let segment = "";
        if (["rewards"].indexOf(name) > -1) {
            segment = name;
        }
        else if (!this.commonService.getActiveAccountMenu()) {
            let urlSegments = this.getDecodedCurrentPath().split("/");
            if (urlSegments && urlSegments.length >= 3) {
                segment = this.multiLanguageService.getActiveLobby(urlSegments[2]);
            }
        }
        else if (this.commonService.getActiveAccountMenu() === "rewards") {
            segment = "rewards";
        }
        this.commonService.onBroadcastActiveLeftMenu(segment);
    }
    /**open terms & conditions page in new tab */
    toggleRewardTCPopUp(flag) {
        if (flag) {
            document.body.classList.add("overflow-hidden");
        }
        this.emitterService.onBroadcastRewardTermsAndConditions(flag);
    }
    fullScreenInit(targetElement) {
        let fullscreenPromise;
        if (targetElement) {
            if (targetElement["requestFullscreen"]) {
                fullscreenPromise = targetElement["requestFullscreen"]();
            }
            else if (targetElement["webkitRequestFullscreen"]) {
                fullscreenPromise = targetElement["webkitRequestFullscreen"]();
            }
            else if (targetElement["mozRequestFullScreen"]) {
                fullscreenPromise = targetElement["mozRequestFullScreen"]();
            }
            else if (targetElement["msRequestFullscreen"]) {
                fullscreenPromise = targetElement["msRequestFullscreen"]();
            }
        }
        if (fullscreenPromise) {
            fullscreenPromise
                .catch(() => { })
                .then(() => {
                fullscreenPromise.done = true;
            });
        }
    }
    exitFullScreen() {
        let exitPromise;
        if (document && document["exitFullscreen"]) {
            exitPromise = document["exitFullscreen"]();
        }
        else if (document && document["mozCancelFullScreen"]) {
            exitPromise = document["mozCancelFullScreen"]();
        }
        else if (document && document["webkitExitFullscreen"]) {
            exitPromise = document["webkitExitFullscreen"]();
        }
        else if (document && document["msExitFullscreen"]) {
            exitPromise = document["msExitFullscreen"]();
        }
        if (exitPromise) {
            exitPromise
                .catch(() => { })
                .then(() => {
                exitPromise.done = true;
            });
        }
    }
    /*
      Code to remove query params from url if we have any on closing of component
      because we have chance of opening account component from query params,
      which we need to remove before close.
    */
    removeUrlQueryParams() {
        if (window.location.search.length > 0) {
            this.router.navigate([window.location.pathname]);
        }
    }
    gameplayFooterMouseOver(val) {
        this.gameplayFooterHover.next(val);
    }
    GamesToasterOpen(val) {
        this.isGamesToasterOpen.next(val);
    }
    getFooterHeight() {
        const footerContainerDom = document.getElementsByClassName("footer-conatiner");
        if (footerContainerDom && footerContainerDom.length > 0) {
            return footerContainerDom[0].offsetHeight;
        }
        else {
            return 0;
        }
    }
    setSEO(contents, isGame = false) {
        if (contents.metaTitle || contents.metatitle) {
            document
                .querySelector("meta[name=title]")
                .setAttribute("content", contents.metaTitle ? contents.metaTitle : contents.metatitle);
            document
                .querySelector('meta[property="og:title"]')
                .setAttribute("content", contents.metaTitle ? contents.metaTitle : contents.metatitle);
        }
        else {
            document
                .querySelector("meta[name=title]")
                .setAttribute("content", "highroller");
            document
                .querySelector('meta[property="og:title"]')
                .setAttribute("content", "Highroller.com – Roll with us.");
        }
        if (contents.metaDescription || contents.metadescription) {
            document
                .querySelector("meta[name=description]")
                .setAttribute("content", contents.metaDescription
                ? contents.metaDescription
                : contents.metadescription);
            document
                .querySelector('meta[property="og:description"]')
                .setAttribute("content", contents.metaDescription
                ? contents.metaDescription
                : contents.metadescription);
        }
        else {
            document
                .querySelector("meta[name=description]")
                .setAttribute("content", "No hassle, no lag, no delays - just a smooth ride to exclusivity. HighRoller Casino - #Roll with us");
            document
                .querySelector('meta[property="og:description"]')
                .setAttribute("content", "100% No-Sticky Bonus up to €500");
        }
        if (contents.metaTags || contents.metatags) {
            if (document.querySelectorAll("meta[name=keywords]").length > 0) {
                document
                    .querySelector("meta[name=keywords]")
                    .setAttribute("content", contents.metaTags ? contents.metaTags : contents.metatags);
            }
            else {
                let meta = document.createElement("meta");
                meta.name = "keywords";
                meta.content = contents.metaTags;
                document.getElementsByTagName("head")[0].appendChild(meta);
            }
        }
        else if (document.querySelectorAll("meta[name=keywords]").length > 0) {
            document.querySelector("meta[name=keywords]").setAttribute("content", "");
        }
        else {
            let meta = document.createElement("meta");
            meta.name = "keywords";
            meta.content = "";
            document.getElementsByTagName("head")[0].appendChild(meta);
        }
        if (contents.title) {
            document.querySelector("title").textContent = contents.title;
        }
        else {
            document.querySelector("title").textContent = "highroller";
        }
        if (isGame && contents.name) {
            document.querySelector("title").textContent = contents.name;
        }
    }
    getDecodedCurrentPath() {
        return decodeURIComponent(window.location.pathname);
    }
    /*
      In hosted cashier we have a property called channel
      where we have to pass what kind of device types it is, Below logic
      detect & pass it.
    */
    getDeviceType() {
        const userDeviceInfo = navigator.userAgent;
        if (/android/i.test(userDeviceInfo)) {
            return "Android";
        }
        else if (/iPad|iPhone|iPod/.test(userDeviceInfo)) {
            return "iOS";
        }
        else if (/Windows/.test(userDeviceInfo)) {
            return "windows";
        }
        else if (/ Mac/.test(userDeviceInfo)) {
            return "Mac";
        }
        else if (/Linux/.test(userDeviceInfo)) {
            return "Linux";
        }
        else {
            return "unknown";
        }
    }
    getImgixParams(opts) {
        // https://docs.imgix.com/apis/rendering
        const defaults = {
            auto: "format,compress",
        };
        const options = opts ? Object.assign({}, defaults, opts) : defaults;
        const params = `?${Object.keys(options)
            .map((k) => `${k}=${options[k]}`)
            .join("&")}`;
        return params;
    }
    getUserCountryCode() {
        return this.userDetailsService.getUserCountryCode()
            ? this.userDetailsService.getUserCountryCode()
            : this.commonService.getCountryCode();
    }
    initiateDeposit(showBackButton) {
        if (this.isPnpFlow()) {
            this.payNPlayCashierService.onBroadcastInitiateDeposit({
                callingFrom: "leftMenu",
            });
        }
        else {
            const activeTab = {
                tabName: "deposit",
                showBackButton,
            };
            this.commonService.onBroadcastActiveAcountView(activeTab);
        }
    }
    initiateLogin() {
        if (this.isPnpFlow()) {
            this.payNPlayCashierService.onBroadcastInitiateLogin("LOGIN");
        }
        else {
            const activeTab = {
                tabName: "login",
                showBackButton: true,
            };
            this.commonService.onBroadcastActiveAcountView(activeTab);
        }
    }
    initiateWithdraw() {
        if (this.isPnpFlow()) {
            this.payNPlayCashierService.onBroadcastInitiateWithdraw();
        }
        else {
            const activeTab = {
                tabName: "withdrawal",
                showBackButton: true,
            };
            this.commonService.onBroadcastActiveAcountView(activeTab);
        }
    }
    isPnpFlow() {
        return environment.zimplerMarkets.indexOf(this.multiLanguageService.getLanguageCode()) > -1
            ? true
            : false;
    }
    /*
      Functionality to hide & show "Despoit & Game play" (If they configured on banner)
      Based on banner CTA Config vs user total balance
    */
    toggleBannerDepositBtn(userTotalBalance) {
        const isDepositBtnAvailable = document.querySelectorAll(".show-banner-cta-deposit");
        const isGamePlayBtnAvailable = document.querySelectorAll(".show-banner-cta-play");
        if (userTotalBalance) {
            this.userTotalBalance = userTotalBalance;
        }
        if (isDepositBtnAvailable.length > 0 && isGamePlayBtnAvailable.length > 0) {
            if (this.isLoggedIn) {
                if (this.currencyCode &&
                    this.userTotalBalance < bannerCTAConfigurations[this.currencyCode]) {
                    this.removeClassFromDomElements(isDepositBtnAvailable, "hide");
                    this.addHideClassToDomElements(isGamePlayBtnAvailable, "hide");
                }
                else {
                    this.addHideClassToDomElements(isDepositBtnAvailable, "hide");
                    this.removeClassFromDomElements(isGamePlayBtnAvailable, "hide");
                }
            }
            else {
                this.addHideClassToDomElements(isDepositBtnAvailable, "hide");
                this.removeClassFromDomElements(isGamePlayBtnAvailable, "hide");
            }
        }
    }
    addHideClassToDomElements(domElements, className) {
        domElements.forEach((val) => val.classList.add(className));
    }
    removeClassFromDomElements(domElements, className) {
        domElements.forEach((val) => val.classList.remove(className));
    }
    getTimeFormat(expiryDate) {
        let translated_days = this.translationService.get("rewards.days");
        let translated_day = this.translationService.get("rewards.day");
        let date_future = new Date(expiryDate);
        let date_now = new Date();
        let seconds = 0;
        let minutes = 0;
        let hours = 0;
        let days = 0;
        let durationTime = "";
        if (date_future > date_now) {
            seconds = Math.floor((date_future.getTime() - date_now.getTime()) / 1000);
            minutes = Math.floor(seconds / 60);
            hours = Math.floor(minutes / 60);
            days = Math.floor(hours / 24);
            hours = hours - days * 24;
            minutes = minutes - days * 24 * 60 - hours * 60;
            seconds = seconds - days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60;
            if (days > 30) {
                durationTime = `>30 ${translated_days}`;
            }
            else if (days > 0) {
                /*
                  Logic to round up days if left time is 2 days 23h 10mins 1 sec/23 days 0h 0mins 1sec is 3 days
                  (2 days plus X we always display as 3 days)
        
                  Below 24h will have a timer in that format: 23h 59m 20s
                */
                if (days > 0 && (hours || minutes || seconds)) {
                    days = days + 1;
                }
                durationTime = `${days} ${days === 1 ? translated_day : translated_days}`;
            }
            else if (hours > 0) {
                durationTime = `${hours}${this.translationService.get("common.hours")}${minutes}${this.translationService.get("common.minutes")}`;
            }
            else if (minutes > 0) {
                durationTime = `${minutes}${this.translationService.get("common.minutes")}${seconds}${this.translationService.get("common.seconds")}`;
            }
            else if (seconds > 0) {
                durationTime = `${seconds}${this.translationService.get("common.seconds")}`;
            }
            else {
                durationTime = `${seconds}${this.translationService.get("common.seconds")}`;
            }
        }
        return durationTime;
    }
    ngOnDestroy() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
}
UtilityService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UtilityService_Factory() { return new UtilityService(i0.ɵɵinject(i1.PayNPlayCashierService), i0.ɵɵinject(i2.MultiLanguageService), i0.ɵɵinject(i3.UserDetailsService), i0.ɵɵinject(i4.TranslationService), i0.ɵɵinject(i5.EmitterService), i0.ɵɵinject(i6.CashierService), i0.ɵɵinject(i7.SessionService), i0.ɵɵinject(i8.CommonService), i0.ɵɵinject(i9.Store), i0.ɵɵinject(i10.Meta), i0.ɵɵinject(i11.Router)); }, token: UtilityService, providedIn: "root" });
