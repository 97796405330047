import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

// API Interactors
import { ApiInteractors } from "src/app/models/interactors/api.interactor";

// Enums
import { WorldCurrencyCode } from "src/app/models/configurations/enums/localization/world-currencies.enum";

// Environments
import { environment } from "src/environments/environment";

// Libraries
import * as _ from "underscore";

// Models
import { UpdateUserCredentialsResponse } from "src/app/modules/shared/models/update-user-credentials-response.model";
import { UpdateUserCredentialsRequest } from "src/app/modules/shared/models/update-user-credentials-request.model";
import { TotalJackpotFeed } from "src/app/modules/game-groups/models/jackpot/total-jackpot-feed.model";
import { LastPlayedGames } from "src/app/modules/game-groups/models/games/last-played-games.model";
import { JackpotFeed } from "src/app/modules/game-groups/models/jackpot/jackpot-feed.model";
import { ProfileBalance } from "src/app/modules/auth/models/profile-balance.model";
import { Project } from "src/app/models/environments/project.enum";

@Injectable()
export class MainService {
  // API Interactions
  apiInteractor: ApiInteractors;

  constructor(private httpClient: HttpClient) {
    this.apiInteractor = new ApiInteractors(this.httpClient);
  }

  // -----------------------------------------------------------------
  // Get Methods - Observables Micro Gaming
  onGetMicroGamingJackpotFeed(
    currencyCode: WorldCurrencyCode
  ): Observable<JackpotFeed[]> {
    return this.apiInteractor.getJackpot<JackpotFeed[]>(
      `${environment.microGamingJackpotsUrl}/counters?currencyIsoCode=${currencyCode}`
    );
  }

  onGetMicroGamingTotalJackpotsAmount(
    currencyCode: WorldCurrencyCode
  ): Observable<TotalJackpotFeed> {
    return this.apiInteractor.getJackpot<TotalJackpotFeed>(
      `${environment.microGamingJackpotsUrl}/jackpots/total?currencyIsoCode=${currencyCode}`
    );
  }

  // -----------------------------------------------------------------
  // Get Methods - Observables Get User Data
  onProfileUpdate(request): Observable<any> {
    return this.apiInteractor.post<any, any>(`/ajax/profile/update`, request);
  }

  onGetProfileCountryDetails(): Observable<any> {
    return this.apiInteractor.get<null, any>(
      `/ajax/profile/getcountryDetails`,
      null,
      Project.Shotz
    );
  }

  // -----------------------------------------------------------------
  // Get Methods - Observables Account

  // -----------------------------------------------------------------
  // Get Methods - Observables Zendesk Token
  onGetZendeskToken(): Observable<any> {
    return this.apiInteractor.get<null, any>(`/ajax/profile/getZendeskToken`);
  }

  // -----------------------------------------------------------------
  // Get Methods - Promises
  onGetPromiseLastedPlayedGames(): Promise<LastPlayedGames> {
    return this.apiInteractor
      .get<null, LastPlayedGames>(
        `/ajax/profile/getLastPlayedGames`,
        null,
        Project.Shotz
      )
      .toPromise<LastPlayedGames>();
  }

  updateUserCredentails(
    updateUserCredentialsRequest: UpdateUserCredentialsRequest
  ): Observable<UpdateUserCredentialsResponse> {
    return this.apiInteractor.post<
      UpdateUserCredentialsRequest,
      UpdateUserCredentialsResponse
    >(
      `/ajax/profile/updateUserCredentials`,
      updateUserCredentialsRequest,
      Project.Shotz
    );
  }

  getZendeskRubikoDetails(userName: string): Observable<any> {
    return this.httpClient.get(
      `https://zdapi.rubiko.tech/tracking?user=${encodeURIComponent(
        userName
      )}&page=${encodeURIComponent(window.location.href)}`
    );
  }
}
