import { NgModule } from "@angular/core";

// Components
import { RegistrationComponent } from "src/app/modules/registration/components/registration/registration.component";

// Modules
import { SharedModule } from "src/app/modules/shared/shared.module";

@NgModule({
  declarations: [RegistrationComponent],
  imports: [SharedModule],
  exports: [RegistrationComponent],
})
export class RegistrationModule {}
