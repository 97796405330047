import { Component, Input, SimpleChanges } from "@angular/core";

// Libraries
import { LiveScheduledStream } from "livespins/@livespins";

@Component({
  selector: "app-csn-livespins-schedule-room",
  templateUrl: "./csn-livespins-schedule-room.component.html",
  styleUrls: ["./csn-livespins-schedule-room.component.scss"],
})
export class CsnLivespinsScheduleRoomComponent {
  // Inputs
  @Input() livespinsScheduleRoom?: LiveScheduledStream;

  // -----------------------------------------------------------------
  // Lifecycle Hooks
  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes["livespinsScheduleRoom"].currentValue &&
      changes["livespinsScheduleRoom"].previousValue !=
        changes["livespinsScheduleRoom"].currentValue
    ) {
      this.livespinsScheduleRoom =
        changes["livespinsScheduleRoom"].currentValue;
    }
  }
}
