import { Component } from "@angular/core";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";
import {
  FormBuilder,
  FormControl,
  Validators,
  FormGroup,
} from "@angular/forms";

// Actions
import { loginRequested } from "src/app/modules/auth/store/actions/auth.actions";

// Components
import { FormValidationComponent } from "src/app/modules/shared/components/form-validation/form-validation.component";

// Models
import { LoginCredentials } from "src/app/modules/auth/models/login-credentials.model";
import { ActiveTab } from "src/app/modules/shared/models/active-tab.model";
import { LoggedIn } from "src/app/modules/auth/models/logged-in.model";

// Reducers
import { AppState } from "src/app/store/reducers";

// Selectors
import { selectLanguageCode } from "src/app/modules/multi-languages/store/selectors/languages.selectors";
import { selectAuthLoggedIn } from "src/app/modules/auth/store/selectors/auth.selectors";

// Services
import { MultiLanguageService } from "src/app/modules/multi-languages/services/multi-language.service";
import { TranslationService } from "src/app/modules/multi-languages/services/translation.service";
import { RegistrationService } from "src/app/modules/registration/services/registration.service";
import { DeepLinksService } from "src/app/modules/shared/services/deep-links.service";
import { UtilityService } from "src/app/modules/shared/services/utility.service";
import { CommonService } from "src/app/modules/shared/services/common.service";

// Validators
import { UniqueFieldsValidator } from "src/app/modules/validators/validators/unique.validators";

@Component({
  selector: "app-login-form",
  templateUrl: "./login-form.component.html",
  styleUrls: ["./login-form.component.scss"],
})
export class LoginFormComponent extends FormValidationComponent {
  // Strings
  languageCode: string = "";
  serverError: string = "";

  // Booleans
  isButtonLoader: boolean = false;
  isPassword: boolean = true;

  // Forms
  loginForm: FormGroup;

  // Subscriptions
  loginSubscription: Subscription;

  subscriptions: Subscription[] = [];

  constructor(
    private multiLanguageService: MultiLanguageService,
    private registrationService: RegistrationService,
    private translationService: TranslationService,
    private deepLinksService: DeepLinksService,
    private utilityService: UtilityService,
    private commonService: CommonService,
    private formBuilder: FormBuilder,
    private store: Store<AppState>
  ) {
    super();

    // Forms Initialization
    this.loginForm = this.formBuilder.group({
      txtEmail: new FormControl(
        "",
        [],
        [
          UniqueFieldsValidator.createValidator(
            "txtEmail",
            this.commonService,
            false
          ),
        ]
      ),
      txtPassword: ["", [Validators.required]],
    });
  }

  // -----------------------------------------------------------------
  // Lifecycle Hooks
  ngOnInit(): void {
    this.languageCode = this.multiLanguageService.getLanguageCode();

    this.subscriptions = [
      this.store
        .select(selectLanguageCode)
        .subscribe((languageCode: string) => {
          this.languageCode = languageCode;
        }),
      this.store
        .select(selectAuthLoggedIn)
        .subscribe((request: { loggedIn: LoggedIn; isLoaded: boolean }) => {
          if (request.isLoaded) {
            this.isButtonLoader = false;

            if (request.loggedIn && request.loggedIn.success === true) {
              const activeTab: ActiveTab = {
                tabName: "",
                showBackButton: true,
              };
              
              this.commonService.onBroadcastActiveAcountView(activeTab);

              this.deepLinksService.onNavigateAfterLogin();
            } else {
              this.onLoginFailure(request.loggedIn);
            }
          }
        }),
    ];
  }

  // -----------------------------------------------------------------
  // Set Methods
  onLogin(): void {
    this.isButtonLoader = true;

    this.serverError = undefined;

    if (this.loginForm.valid) {
      const credentials: LoginCredentials = {
        emailId: this.loginForm.controls["txtEmail"].value.trim(),
        password: this.loginForm.controls["txtPassword"].value,
      };

      this.store.dispatch(loginRequested({ credentials }));

      this.loginSubscription = this.store
        .select(selectAuthLoggedIn)
        .subscribe(
          (result: {
            loggedIn: LoggedIn;
            isLoaded: boolean;
            isLoading: boolean;
          }) => {
            if (result.isLoaded) {
              this.isButtonLoader = false;

              if (result.loggedIn && result.loggedIn.success) {
                const activeTab: ActiveTab = {
                  tabName: "",
                  showBackButton: true,
                };
                
                this.commonService.onBroadcastActiveAcountView(activeTab);

                this.deepLinksService.onNavigateAfterLogin();
              } else {
                this.onLoginFailure(result.loggedIn);
              }
            }
          }
        );
    }
  }

  onLoginFailure(loginData: LoggedIn): void {
    this.serverError = this.translationService.get("common.error44");

    if (loginData && loginData.errors) {
      if (loginData.errors.accountLocked) {
        let messase: string = loginData.errors.accountLocked;

        /*
          Below logic/work around is required to remove crossbrowser issue in 
          safari, firefox & windows
        */
        let accountLockedDate: Date = new Date(
          Date.parse(
            messase
              .substr(messase.indexOf(":") + 1)
              .trim()
              .replace(" ", "T") + "+02:00"
          )
        );

        const errorMessage: string = this.translationService.get(
          "logincomponent.error_rg_cool_off"
        );

        this.serverError = `${errorMessage}${accountLockedDate}`;

        return;
      }

      let errorCode: string = "";

      if (loginData.errors.errorCode) {
        errorCode = `${loginData.errors.errorCode}`;
      } else if (loginData.errors.internalErrorCode) {
        errorCode = `${loginData.errors.internalErrorCode}`;
      }

      if (errorCode) {
        this.onDisplayLoginErrorMessage(errorCode);
      }
    }
  }

  onDisplayLoginErrorMessage(errorCode: string): void {
    switch (errorCode) {
      case "100152": {
        this.serverError = this.translationService.get(
          "logincomponent.error_100152_loginAccountdisabled"
        );
        break;
      }
      case "100157": {
        this.serverError = this.translationService.get(
          "logincomponent.error_100157_rg_closed"
        );
        break;
      }
      case "100173": {
        this.serverError = this.translationService.get(
          "logincomponent.error_100173_rg_cool_off"
        );
        break;
      }
      case "100174": {
        this.serverError = this.translationService.get(
          "logincomponent.error_100174_login_attempts_exceeded"
        );
        break;
      }
      case "100161": {
        this.serverError = this.translationService.get(
          "logincomponent.error_100161_wrong_password"
        );
        break;
      }
      case "100166": {
        this.serverError = this.translationService.get(
          "logincomponent.error_100166_wrong_emailid"
        );
        break;
      }
      default: {
        this.serverError = this.translationService.get("common.error44");
      }
    }
  }

  onCloseComponent(): void {
    if (this.commonService.getNavigateAfterLogin()) {
      this.commonService.onSetNavigateAfterLogin(undefined);
    }

    this.utilityService.closeAccountComponent("");
  }

  onGoToForgotPage(): void {
    this.onCloseComponent();

    const activeTab: ActiveTab = {
      tabName: "forgot-password",
      showBackButton: true,
    };

    this.commonService.onBroadcastActiveAcountView(activeTab);
  }

  onOpenRegistrationPopup(): void {
    this.onCloseComponent();

    this.registrationService.onOpenRegistration();
  }

  // -----------------------------------------------------------------
  // On Destroy
  ngOnDestroy(): void {
    if (this.loginSubscription) this.loginSubscription.unsubscribe();

    this.subscriptions.forEach((subscription: Subscription) =>
      subscription.unsubscribe()
    );
  }
}
