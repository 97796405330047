import {
  createFeatureSelector,
  DefaultProjectorFn,
  MemoizedSelector,
  createSelector,
} from "@ngrx/store";

// Models
import { CountryBlockResponse } from "src/app/modules/country-block/models/country-block-response.model";

// Reducers
import {
  CountryBlockStateFeatureKey,
  CountryBlockReducerState,
} from "src/app/modules/country-block/store/reducers/country-block.reducer";

// Select the Main State
const selectCountryBlockReducerState =
  createFeatureSelector<CountryBlockReducerState>(CountryBlockStateFeatureKey);

// Select Parts of the state
export const selectCountryBlock: MemoizedSelector<
  object,
  CountryBlockResponse,
  DefaultProjectorFn<CountryBlockResponse>
> = createSelector(
  selectCountryBlockReducerState,
  (state: CountryBlockReducerState) => state.countryBlockResponse
);
