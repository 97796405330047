import { Subject, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { Store } from "@ngrx/store";
import {
  ViewEncapsulation,
  EventEmitter,
  SimpleChange,
  Component,
  OnInit,
  Output,
  Input,
} from "@angular/core";

// Actions
import { userRequested } from "src/app/modules/user/store/actions/user.actions";

// Constrants
import { UserFlowTypes } from "src/app/constants/costants";

// Enums
import { WorldCurrencyCode } from "src/app/models/configurations/enums/localization/world-currencies.enum";
import { StatusResponse } from "src/app/models/api/status.response";

// Environments
import { environment } from "src/environments/environment";

// Libraries
import { SwiperConfigInterface } from "ngx-swiper-wrapper";
import * as _ from "underscore";

// Models
import { EligibleRequest } from "src/app/modules/rewards/models/eligible-request.model";
import { GamePregmatic } from "src/app/modules/game-groups/models/game.model";
import { UserData } from "src/app/modules/user/models/user-data.model";
import {
  EligibleBonusItem,
  EligibleBonus,
} from "src/app/modules/rewards/models/eligible-bonus.model";
import {
  CampaignDetails,
  UserCampaign,
} from "src/app/modules/rewards/models/user-campaign.model";

// Reducers
import { AppState } from "src/app/store/reducers";

// Selectors
import { selectLanguageCode } from "src/app/modules/multi-languages/store/selectors/languages.selectors";
import { selectAuthUserDataLoaded } from "src/app/modules/user/store/selectors/user.selectors";
import { selectAllGames } from "src/app/modules/game-groups/store/selectors/games.selectors";
import {
  selectAuthLoginIsLoggedOut,
  selectAuthLoginIsLoggedIn,
} from "src/app/modules/auth/store/selectors/auth.selectors";

// Services
import { MultiLanguageService } from "src/app/modules/multi-languages/services/multi-language.service";
import { CashbackPromoService } from "src/app/modules/rewards/services/cashback-promo.service";
import { UserDetailsService } from "src/app/modules/user/services/user-details.service";
import { CashierService } from "src/app/modules/account/services/cashier.service";
import { RewardsService } from "src/app/modules/rewards/services/reward.service";
import { UtilityService } from "src/app/modules/shared/services/utility.service";

@Component({
  selector: "app-payment-bonus",
  templateUrl: "./payment-bonus.component.html",
  styleUrls: ["./payment-bonus.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class PaymentBonusComponent implements OnInit {
  // Outputs
  @Output() callOnNavigateToDeposit: EventEmitter<void> =
    new EventEmitter<void>();
  @Output() callOnSelectBonus: EventEmitter<EligibleBonusItem> =
    new EventEmitter<EligibleBonusItem>();

  // Inputs
  @Input() clearSelectedBonus: boolean = false;
  @Input() activeBonusData: EligibleBonusItem;
  @Input() callingFrom: string = "";
  @Input() rewardSize: string = "";

  // Numbers
  selectedBonusIndex: number = 0;

  // Strings
  selectedBonusCode: string = "";
  userAffialiteId: string = "";
  currencySymbol: string = "";
  languageCode: string = "";

  // Booleans
  isAffiliateBonus: boolean = false;
  isRealUser: boolean = false;
  isLoggedIn: boolean = false;
  isLoading: boolean = false;

  // Arrays
  zimplerMarketsList: string[] = environment.zimplerMarkets;
  depositBonusDetailsList: EligibleBonusItem[] = [];
  campaignDetailsList: CampaignDetails[] = [];
  gamesList: GamePregmatic[] = [];

  // Enums
  currencyCode: WorldCurrencyCode;
  UserFlowTypes = UserFlowTypes;

  // Objects
  selectedBonus: EligibleBonusItem;
  profileDetails: UserData;

  // Swiper Configurations
  swiperPaymentBonus: SwiperConfigInterface = {
    slidesPerView: 1,
    slidesPerGroup: 1,
    spaceBetween: 8,
    resistanceRatio: 0,
    touchRatio: 2,
    pagination: {
      el: ".paybonus-pagination",
      clickable: true,
    },
  };

  // Subjects
  destroy$: Subject<boolean> = new Subject<boolean>();

  // Subscriptions
  userCampaignsSubscription: Subscription;
  rewardsSubscription: Subscription;
  gamesSubscription: Subscription;

  subscriptions: Subscription[] = [];

  constructor(
    private cashbackPromoService: CashbackPromoService,
    private multiLanguageService: MultiLanguageService,
    private userDetailsService: UserDetailsService,
    private rewardsService: RewardsService,
    private utilityService: UtilityService,
    private cashierService: CashierService,
    private store: Store<AppState>
  ) {}

  // -----------------------------------------------------------------
  // Lifecycle Hooks
  ngOnInit(): void {
    this.onGetUserAffiliateId();

    this.onGetPromoCashback();

    this.onGetEligibleBonusDetails();

    this.languageCode = this.multiLanguageService.getLanguageCode();

    if (this.profileDetails) {
      this.isRealUser =
        this.profileDetails.userStatus === "real" ? true : false;
    }

    this.subscriptions = [
      this.store
        .select(selectAuthLoginIsLoggedIn)
        .subscribe((isLoggedIn: boolean) => {
          this.isLoggedIn = isLoggedIn;
        }),
      this.store
        .select(selectAuthLoginIsLoggedOut)
        .subscribe((isLoggedOut: boolean) => {
          if (isLoggedOut) {
            this.isLoggedIn = false;
          }
        }),
      this.store
        .select(selectLanguageCode)
        .subscribe((languageCode: string) => {
          this.languageCode = languageCode;
        }),
      this.userDetailsService.currencyCodeBehaviourSubject$.subscribe(
        (currencyCode: WorldCurrencyCode) => {
          this.currencyCode = currencyCode;
        }
      ),
      this.userDetailsService.currencySymbolBehaviourSubject$.subscribe(
        (currencySymbol: string) => {
          this.currencySymbol = currencySymbol;
        }
      ),
      this.store
        .select(selectAuthUserDataLoaded)
        .subscribe(({ userData, isLoaded }) => {
          if (isLoaded) {
            this.profileDetails = userData;

            this.isRealUser =
              this.profileDetails.userStatus === "real" ? true : false;
          } else {
            if (this.isLoggedIn) {
              this.store.dispatch(userRequested());
            }
          }
        }),
    ];
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
    if (
      changes["clearSelectedBonus"] &&
      changes["clearSelectedBonus"].previousValue !==
        changes["clearSelectedBonus"].currentValue
    ) {
      if (changes["clearSelectedBonus"].currentValue) {
        this.onRemoveBonus();
      }
    }

    if (
      changes["activeBonusData"] &&
      changes["activeBonusData"].previousValue !==
        changes["activeBonusData"].currentValue
    ) {
      this.selectedBonus = changes["activeBonusData"].currentValue;

      if (this.selectedBonus && this.selectedBonus.bonusCode) {
        this.selectedBonusCode = this.selectedBonus.bonusCode;
      }
    }

    if (
      changes["callingFrom"] &&
      changes["callingFrom"].previousValue !==
        changes["callingFrom"].currentValue
    ) {
      this.callingFrom = changes["callingFrom"].currentValue;
    }

    if (
      changes["rewardSize"] &&
      changes["rewardSize"].previousValue !== changes["rewardSize"].currentValue
    ) {
      this.rewardSize = changes["rewardSize"].currentValue;
    }
  }

  // -----------------------------------------------------------------
  // Set Methods
  onSelectBonus(eligibleBonusItem: EligibleBonusItem): void {
    if (
      this.selectedBonus &&
      this.selectedBonus.bonusCode === eligibleBonusItem.bonusCode
    ) {
      this.onRemoveBonus();
    } else if (eligibleBonusItem && eligibleBonusItem.bonusCode) {
      this.selectedBonus = eligibleBonusItem;

      this.callOnSelectBonus.emit(eligibleBonusItem);
    }
  }

  onSetSelectedBonusIndex(bonusCode: string): void {
    this.selectedBonusIndex = _.findIndex(this.depositBonusDetailsList, {
      bonusCode: bonusCode,
    });
  }

  onNavigateToDeposit(): void {
    this.callOnNavigateToDeposit.emit();
  }

  /*
    Functionality to check Is user having as welcome bonus, If yes we activate
    that bonus by default to users
  */
  onWelcomBonus(): void {
    if (this.depositBonusDetailsList) {
      let eligibleBonusItem: EligibleBonusItem;

      /* 
        Here we are checking Is user having affiliate welcome bonus,
        If yes we activate affilate bonus by default to users
      */
      eligibleBonusItem = _.find(
        this.depositBonusDetailsList,
        (eligibleBonusItem: EligibleBonusItem, index: number) => {
          if (
            eligibleBonusItem.bonusName &&
            eligibleBonusItem.bonusName
              .toLowerCase()
              .startsWith("welcome offer") &&
            this.userAffialiteId &&
            eligibleBonusItem.affiliateId &&
            (eligibleBonusItem.affiliateId.includes(this.userAffialiteId) ||
              eligibleBonusItem.affiliateId.includes(
                this.userAffialiteId.split("_")[0]
              ))
          ) {
            this.selectedBonusIndex = index;

            this.isAffiliateBonus = true;

            return true;
          }
        }
      );

      if (!this.isAffiliateBonus) {
        eligibleBonusItem = _.find(
          this.depositBonusDetailsList,
          (eligibleBonusItem: EligibleBonusItem, index: number) => {
            if (
              eligibleBonusItem.bonusName &&
              eligibleBonusItem.bonusName
                .toLowerCase()
                .startsWith("welcome offer")
            ) {
              this.selectedBonusIndex = index;

              return true;
            }
          }
        );
      }

      if (eligibleBonusItem) {
        this.onSelectBonus(eligibleBonusItem);
      }
    }
  }

  onRemoveBonus(): void {
    this.selectedBonus = undefined;

    this.callOnSelectBonus.emit({});
  }

  onOpenRewardTCPopUp(event: MouseEvent): void {
    if (event) {
      event.stopPropagation();
    }

    this.utilityService.toggleRewardTCPopUp(true);
  }

  onGetEligibleBonusDetails(): void {
    this.isLoading = true;

    let eligibleRequest: EligibleRequest = { criteriaType: "DEPOSIT" };

    this.rewardsSubscription = this.rewardsService
      .onGetEligibleBonus(eligibleRequest)
      .subscribe((eligibleBonus: EligibleBonus) => {
        if (eligibleBonus && eligibleBonus.status === StatusResponse.SUCCESS) {
          this.isLoading = false;

          let eligibleBonusItemList: EligibleBonusItem[] =
            eligibleBonus.eligibleBonusList;

          if (eligibleBonusItemList) {
            this.onProcessEligibleBonusDetails(eligibleBonusItemList);

            this.onGetAllGames();
          }
        } else if (
          this.zimplerMarketsList.indexOf(
            this.multiLanguageService.getLanguageCode()
          ) > -1
        ) {
          this.onNavigateToDeposit();
        }
      });
  }

  onProcessEligibleBonusDetails(
    eligibleBonusItemList: EligibleBonusItem[]
  ): void {
    this.depositBonusDetailsList = this.rewardsService.getProcessEligibleBonus(
      eligibleBonusItemList,
      this.currencyCode,
      this.utilityService.isPnpFlow()
    );

    if (this.callingFrom !== "hostedCashier") {
      this.onGetSortedBySelectedBonus();
    } else {
      this.onSetSelectedBonusIndex(this.selectedBonusCode);
    }

    /*
      Here we filter welcome bonus only user not navigated from banner/promotions/rewards
      with bonus code first prority goes to bonus code confiured on above then after
      welcome bonus related stuff
    */
    if (!this.cashierService.getActiveDepositBonus()) {
      this.onWelcomBonus();
    }

    if (
      this.zimplerMarketsList.indexOf(
        this.multiLanguageService.getLanguageCode()
      ) > -1 &&
      this.depositBonusDetailsList.length === 0
    ) {
      this.onNavigateToDeposit();
    }
  }

  onGetSortedBySelectedBonus(): void {
    this.selectedBonusIndex = _.findIndex(this.depositBonusDetailsList, {
      bonusCode: this.selectedBonusCode,
    });

    if (this.selectedBonusIndex > 0) {
      this.depositBonusDetailsList.splice(this.selectedBonusIndex, 1);

      this.depositBonusDetailsList.unshift(this.selectedBonus);
    }

    this.depositBonusDetailsList = this.depositBonusDetailsList;
  }

  onGetAllGames(): void {
    this.gamesSubscription = this.store
      .select(selectAllGames)
      .subscribe((gamesList: GamePregmatic[]) => {
        if (gamesList && gamesList.length > 0) {
          this.gamesList = gamesList;

          this.depositBonusDetailsList = this.depositBonusDetailsList.filter(
            (eligibleBonusItem: EligibleBonusItem) => {
              if (
                eligibleBonusItem.gameIds &&
                eligibleBonusItem.gameIds.length > 0
              ) {
                let filteredGame: GamePregmatic = _.findWhere(gamesList, {
                  gameCode: eligibleBonusItem.gameIds[0],
                });

                if (filteredGame && Object.keys(filteredGame).length > 0) {
                  eligibleBonusItem.gameData = filteredGame;
                }
              }

              return eligibleBonusItem;
            }
          );
        }
      });
  }

  onGetUserAffiliateId(): void {
    if (this.profileDetails && this.profileDetails.affiliateLinkID) {
      this.userAffialiteId = this.profileDetails.affiliateLinkID;
    }
  }

  onGetPromoCashback(): void {
    this.userCampaignsSubscription = this.cashbackPromoService
      .onGetUserCampaigns()
      .pipe(takeUntil(this.destroy$))
      .subscribe((userCampaign: UserCampaign) => {
        if (userCampaign && userCampaign.status === StatusResponse.SUCCESS) {
          this.campaignDetailsList = userCampaign.campaignDetails;

          this.campaignDetailsList = this.campaignDetailsList.filter(
            (campaignDetails: CampaignDetails) => {
              let currentTime: Date = new Date();

              let promoStartTime: Date = new Date(
                campaignDetails.campaignStartDate
              );

              let promoOfferExpiryDate: Date = new Date(
                campaignDetails.campaignEndDate
              );

              if (
                campaignDetails &&
                campaignDetails.campaignType === "CASHBACK_PROMO" &&
                campaignDetails.optInRequired &&
                campaignDetails.depositRequired &&
                !campaignDetails.minDepositDone &&
                currentTime >= promoStartTime &&
                currentTime < promoOfferExpiryDate
              ) {
                if (
                  this.rewardSize === "shorter" ||
                  (this.rewardSize === "basic" &&
                    !campaignDetails.playerOptinTime)
                ) {
                  campaignDetails.bonusTypeFD = "cashback-promotion";

                  return campaignDetails;
                }
              }
            }
          );

          this.campaignDetailsList = _.flatten(this.campaignDetailsList);
        }
      });
  }

  // -----------------------------------------------------------------
  // On Destroy
  ngOnDestory(): void {
    this.destroy$.next(true);

    this.destroy$.unsubscribe();

    if (this.userCampaignsSubscription)
      this.userCampaignsSubscription.unsubscribe();

    if (this.rewardsSubscription) this.rewardsSubscription.unsubscribe();

    if (this.gamesSubscription) this.gamesSubscription.unsubscribe();

    this.subscriptions.forEach((subscription: Subscription) =>
      subscription.unsubscribe()
    );
  }
}
