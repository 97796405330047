import { Observable, Subject, throwError } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { catchError, map } from "rxjs/operators";
import { Injectable } from "@angular/core";

// API Interactors
import { ApiInteractors } from "src/app/models/interactors/api.interactor";

// Enums
import { Project } from "src/app/models/environments/project.enum";

// Models
import { UserProfileBalance } from "src/app/modules/shared/models/profiles/user-profile-balance.model";
import { ProfileBalance } from "src/app/modules/auth/models/profile-balance.model";

// Services
import { UserDetailsService } from "src/app/modules/user/services/user-details.service";

@Injectable({
  providedIn: "root",
})
export class ProfileService {
  // API Interactions
  apiInteractor: ApiInteractors;

  // Strings
  userRegisteredLanguage: string = "";
  userJurisdiction: string = "";
  userCountryCode: string = "";
  languageCode: string = "";

  // --------------------------------------------------------
  // Subject and Behaviour Subject
  private userJurisdictionSubject: Subject<string> = new Subject<string>();
  public userJurisdictionSubject$: Observable<string> =
    this.userJurisdictionSubject.asObservable();

  constructor(
    private userDetailsService: UserDetailsService,
    private httpClient: HttpClient
  ) {
    this.apiInteractor = new ApiInteractors(this.httpClient);
  }

  // -----------------------------------------------------------------
  // Get Methods
  getUserJurisdiction(): string {
    return this.userJurisdiction;
  }

  // -----------------------------------------------------------------
  // Get Observables
  onGetProfileBalanceCurrency(): Observable<ProfileBalance> {
    return this.apiInteractor
      .get<null, ProfileBalance>(
        `/ajax/profile/getProfileBalanceCurrency`,
        null,
        Project.Shotz
      )
      .pipe(
        map((profileBalanceCurrency: ProfileBalance) => {
          if (profileBalanceCurrency) {
            this.onSetUserJurisdiction(
              profileBalanceCurrency.profile.jurisdiction
            );

            this.userDetailsService.onSetUserCurrencyCode(
              profileBalanceCurrency.currency.code
            );

            this.userDetailsService.onSetCurrencySymbol(
              profileBalanceCurrency.currency.symbol
            );

            this.userDetailsService.onSetUserCurrencyDetails(
              profileBalanceCurrency.currency
            );

            this.userDetailsService.onSetUserProfileDetails(
              profileBalanceCurrency.profile
            );

            sessionStorage.setItem(
              "_player",
              btoa(profileBalanceCurrency.profile.playerID)
            );
          }

          return profileBalanceCurrency;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  onGetUserBalanceByPockets(): Observable<UserProfileBalance> {
    return this.apiInteractor
      .get<null, UserProfileBalance>(
        `/ajax/profile/getBalanceByPocket`,
        null,
        Project.Shotz
      )
      .pipe(
        map((userProfileBalance: UserProfileBalance) => {
          if (userProfileBalance) {
            this.userDetailsService.onSetUserBalanceDetails(userProfileBalance);
          }

          return userProfileBalance;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  // -----------------------------------------------------------------
  // Set Methods
  onSetUserJurisdiction(jurisdiction: string): void {
    this.userJurisdiction = jurisdiction;

    this.userJurisdictionSubject.next(jurisdiction);
  }
}
