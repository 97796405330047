import { SimpleChange, Component, OnChanges, Input } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { Store } from "@ngrx/store";

// Environments
import { environment } from "src/environments/environment";

// Libraries
import { SwiperConfigInterface } from "ngx-swiper-wrapper";
import * as _ from "underscore";

// Models
import { SwiperLazy } from "src/app/modules/game-groups/models/swiper/swiper-lazy.model";
import { GameProviders } from "src/app/modules/game-groups/models/game-providers.model";
import { ProviderPregmatic } from "src/app/modules/game-groups/models/provider.model";
import { GamePregmatic } from "src/app/modules/game-groups/models/game.model";

// Reducers
import { AppState } from "src/app/store/reducers";

// Selectors
import { selectLanguageCode } from "src/app/modules/multi-languages/store/selectors/languages.selectors";

// Services
import { UtilityService } from "src/app/modules/shared/services/utility.service";

// Utilities
import { swiperLazyConfigurations } from "src/app/modules/game-groups/utilities/swiper.utility";

@Component({
  selector: "app-game-provider",
  templateUrl: "./game-provider.component.html",
  styleUrls: ["./game-provider.component.scss"],
})
export class GameProviderComponent implements OnChanges {
  // Inputs
  @Input() gameGroupData: ProviderPregmatic;
  @Input() callingFromlobby: string = "";

  // Strings
  mediaUrlPath: string = environment.mediaUrlPath;
  languageCode: string = "";
  imgixParams: string = "";

  // Booleans
  isLoading: boolean = false;

  // Arrays
  providerList: GameProviders[] = [];
  gamesList: GamePregmatic[] = [];

  // Swiper Configurations
  swiperProviders: SwiperConfigInterface = {
    slidesPerView: 6,
    slidesPerGroup: 6,
    spaceBetween: 16,
    freeMode: true,
    navigation: true,
    watchSlidesVisibility: true,
    observer: true,
    observeParents: true,
    resistanceRatio: 0,
    breakpoints: {
      // breakpoints works like max-width css
      2000: {
        slidesPerView: 7,
        slidesPerGroup: 7,
        spaceBetween: 16,
        touchRatio: 2,
      },
      1599: {
        slidesPerView: 6,
        slidesPerGroup: 6,
        spaceBetween: 16,
        touchRatio: 2,
      },
      1365: {
        slidesPerView: 5,
        slidesPerGroup: 5,
        spaceBetween: 16,
        touchRatio: 2,
      },
      767: {
        slidesPerView: 4,
        slidesPerGroup: 4,
        spaceBetween: 8,
        touchRatio: 2,
      },
      480: {
        slidesPerView: 3,
        slidesPerGroup: 3,
        spaceBetween: 8,
        touchRatio: 2,
      },
    },
  };
  swiperLazyConfigurations: SwiperLazy = swiperLazyConfigurations;

  // Subscriptions
  subscription: Subscription;

  constructor(
    private utilityService: UtilityService,
    private store: Store<AppState>,
    private router: Router
  ) {}

  // -----------------------------------------------------------------
  // Lifecycle Hooks
  ngOnInit(): void {
    this.imgixParams = this.utilityService.getImgixParams();

    this.subscription = this.store
      .select(selectLanguageCode)
      .subscribe((languageCode: string) => {
        this.languageCode = languageCode;
      });
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
    if (
      changes["gameGroupData"] &&
      changes["gameGroupData"].previousValue !==
        changes["gameGroupData"].currentValue
    ) {
      this.gameGroupData = changes["gameGroupData"].currentValue;

      this.gamesList = this.gameGroupData.games;

      this.onSetProviderList(this.gamesList);
    }

    if (
      changes["callingFromlobby"] &&
      changes["callingFromlobby"].previousValue !==
        changes["callingFromlobby"].currentValue
    ) {
      this.callingFromlobby = changes["callingFromlobby"].currentValue;
    }
  }

  // -----------------------------------------------------------------
  // Set Methods
  onSetProviderList(gamesList: GamePregmatic[]): void {
    if (gamesList && gamesList.length > 0) {
      this.providerList =
        this.utilityService.getSortedUniqueProviderList(gamesList);

      if (this.providerList && this.providerList.length > 0) {
        this.swiperLazyConfigurations.totalNumberOfGamesExist =
          this.providerList.length;
      }
    }
  }

  onNavigateAllGamesPage(gameProvider?: string): void {
    this.utilityService.onNavigateAllGamesPage(
      this.gameGroupData,
      gameProvider
    );
  }

  onNavigateToStudio(vendorDisplayName: string): void {
    vendorDisplayName =
      this.utilityService.convertGameNameToUrl(vendorDisplayName);

    this.router.navigate([`${this.languageCode}/studio/${vendorDisplayName}`]);
  }

  /*
    Function for Lazy loading of games goes here
    on every next click of silde below function will be trigerred
    & calculated how many game have to load amount that total game present

    This works on bases of swiperLazyConfig defined above...
  */
  onSwiperNextEvent(): void {
    if (this.swiperLazyConfigurations.backwardslideIndex) {
      this.swiperLazyConfigurations.backwardslideIndex =
        this.swiperLazyConfigurations.backwardslideIndex - 1;
      return;
    }

    if (
      this.swiperLazyConfigurations.indexNumberOfGameTilesToshow <
      this.swiperLazyConfigurations.totalNumberOfGamesExist
    ) {
      this.swiperLazyConfigurations.indexNumberOfGameTilesToshow =
        this.swiperLazyConfigurations.indexNumberOfGameTilesToshow +
        this.swiperLazyConfigurations.numberOfExtraGamesToLoadForSlide;

      this.swiperLazyConfigurations.forwardslideIndex =
        this.swiperLazyConfigurations.forwardslideIndex + 1;
    }
  }

  onSwiperPrevEvent(): void {
    this.swiperLazyConfigurations.backwardslideIndex =
      this.swiperLazyConfigurations.backwardslideIndex + 1;
  }

  // -----------------------------------------------------------------
  // On Destroy
  ngOnDestroy(): void {
    if (this.subscription) this.subscription.unsubscribe();
  }
}
