<div class="storyly-container">
  <app-storyly *ngIf="isLoggedIn"></app-storyly>
</div>

<section class="gameLobbyNav">
  <div
    class="gameLobbyNav__list-wrapper swiper__outer clearfix float-left"
  >
    <div
      class="swiper__inner swiper__full atGameLobbySwiper"
      [ngClass]="callingFrom === 'liveCasinoLobby' ? 'liveCasinoNav' : 'casinoNav'"
    >
      <swiper
        class="mySwiper swiper-control clearfix"
        [config]="swiperGameLobby"
        watchSlidesProgress
      >
        <ng-container
          *ngFor="let lobby of menuConfigurationType"
        >
          {{ menuConfigurationType.onlyAfterLogin }}
          <div
            class="gameLobbyNav__list btn-lobbyNav"
            [ngClass]="callingFrom === 'liveCasinoLobby' ? 'liveCasinoNav' : 'casinoNav'"
            *ngIf="!lobby?.onlyAfterLogin || (lobby?.onlyAfterLogin === isLoggedIn && isLastPlayedLiveGamesExist)"
            (click)="onNavigateToLobby(lobby?.filterKey,'list')"
            [class.active]="lobby?.filterKey === activeLobby"
          >
            {{lobby.linkText | translate}}
          </div>
        </ng-container>
      </swiper>
    </div>
  </div>
  <div
    class="gameLobbyNav__search-wrapper searchAtlobbynav"
    id="searchAtlobbynav"
    (click)="onOpenSearchResultsAtDesktop()"
  >
    <app-recent-search-games
      [callingFrom]="callingFrom"
    ></app-recent-search-games>
  </div>

  <!----Mobile Search -->
  <div
    *ngIf="isSearchRequired"
    class="searchlobby-at-mobile"
  >
    <div
      class="search-box"
      (click)="onOpenSearchResultsAtMobile()"
      [class.liveCasinoSearch]="callingFrom === 'liveCasinoLobby'"
    >
      <span class="prefix-icon m-r-4 search-box--title"
        ><i class="icon-search"></i
      ></span>
      <span class="search-box--subtitle casino">
        <ng-container
          *ngIf="callingFrom != 'liveCasinoLobby'"
          >{{'general.search-placeholder' | translate}}
        </ng-container>
        <ng-container
          *ngIf="callingFrom === 'liveCasinoLobby'"
          >{{'livecasino.search-placeholder' | translate}}
        </ng-container>
      </span>
    </div>
  </div>
</section>

<div
  class="searchAtMobile padding"
  *ngIf="isShowSearchResult"
  [class.liveCasinoSearch]="callingFrom === 'liveCasinoLobby'"
>
  <div
    class="searchAtMobile__header flex-a-j-center clearfix"
  >
    <div class="searchAtMobile__header--title">
      {{'general.search' | translate}}
    </div>

    <button
      aria-label="Close"
      class="btn btn-circle btn-secondary searchAtMobile--close-btn"
      (click)="onCloseSearchResultsAtMobile()"
    >
      <i class="icon-close"></i>
    </button>
  </div>
  <div class="searchAtMobile__body clearfix">
    <app-recent-search-games
      *ngIf="isSearchRequired"
      [callingFrom]="callingFrom"
      (closeSearchResults)="onCloseSearchResultsAtMobile()"
    ></app-recent-search-games>
  </div>
</div>
