import { Observable, of, Subject, Subscription, throwError } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

// API Interactors
import { ApiInteractors } from "src/app/models/interactors/api.interactor";

// Enums
import { Project } from "src/app/models/environments/project.enum";

// Libraries
import * as _ from "underscore";

// Models
import { BannerDataZone } from "src/app/modules/banner/models/banner-data-zone.model";
import { BannerRequest } from "src/app/modules/banner/models/banner-request.model";
import { Banner } from "src/app/modules/banner/models/banner.model";

// Services
import { EmitterService } from "src/app/modules/shared/services/emitter.service";

@Injectable({
  providedIn: "root",
})
export class CustomBannerService {
  // API Interactions
  apiInteractor: ApiInteractors;

  // Arrays
  bannersList: Banner[] = [];

  // Objects
  bannerDataByZoneId: BannerDataZone = {};

  // Booleans
  isProfileRefreshRequired: boolean = false;

  // --------------------------------------------------------
  // Subject and Behaviour Subject
  private isBannerAvailableSubject: Subject<boolean> = new Subject<boolean>();
  public isBannerAvailableSubject$: Observable<boolean> =
    this.isBannerAvailableSubject.asObservable();

  // --------------------------------------------------------
  // Subscriptions
  subscription: Subscription;

  constructor(
    private emitterService: EmitterService,
    private httpClient: HttpClient
  ) {
    this.apiInteractor = new ApiInteractors(this.httpClient);

    this.subscription =
      this.emitterService.isSuccessfulDepositSubject$.subscribe(() => {
        this.bannerDataByZoneId = {};
      });
  }

  // -----------------------------------------------------------------
  // Get Methods
  getIsProfileRefreshRequired(): boolean {
    return this.isProfileRefreshRequired;
  }

  // -----------------------------------------------------------------
  // Get Methods - Promises
  onBannerList(
    bannerRequest: BannerRequest,
    isForce?: boolean
  ): Observable<Banner[]> {
    if (!_.isEmpty(this.bannersList) || isForce) {
      return of(this.bannersList);
    } else {
      return this.apiInteractor
        .get<BannerRequest, Banner[]>(
          `/ajax/banner/getBanners`,
          bannerRequest,
          Project.Shotz
        )
        .pipe(
          tap((bannersList: Banner[]) => {
            if (bannersList && bannersList.length > 0) {
              this.bannersList = bannersList;
            }
          }),
          map((bannersList: Banner[]) => {
            return bannersList;
          }),
          catchError((error) => {
            return throwError(error);
          })
        );
    }
  }

  // -----------------------------------------------------------------
  // Set Methods
  onBroadCastIsBannerAvialable(isBannerAvailable: boolean): void {
    this.isBannerAvailableSubject.next(isBannerAvailable);
  }

  onSetIsProfileRefreshRequired(isProfileRefreshRequired: boolean): void {
    this.isProfileRefreshRequired = isProfileRefreshRequired;
  }

  // -----------------------------------------------------------------
  // On Destroy
  ngOnDestroy(): void {
    if (this.subscription) this.subscription.unsubscribe();
  }
}
