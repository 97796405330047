<ng-container *ngIf="isCookieMessagePopup">
  <div class="notifyMsg">
    <div class="notifyMsg__container">
      <p class="notifyMsg--text m-b-16">
        {{ "cookieMsg.cookieMsgText" | translate }}
      </p>

      <div class="notifyMsg--btn-group">
        <button
          class="btn btn-sm btn-secondary btn-round btn-50 m-r-8 cookie-policy-button"
          aria-label="Cookie Policy"
          (click)="onOpenCookiePolicy()"
        >
          {{ "cookieMsg.cookiePolicy" | translate }}
        </button>

        <button
          class="btn btn-sm btn-primary btn-round btn-50"
          aria-label="I Understand"
          (click)="onAcceptedCookiePolicy()"
        >
          {{ "cookieMsg.iUnderstand" | translate }}
        </button>
      </div>
    </div>
  </div>
</ng-container>
