import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { Subscription } from "rxjs";
import {
  EventEmitter,
  ElementRef,
  Component,
  ViewChild,
  Output,
} from "@angular/core";

// Components
import { FormValidationComponent } from "src/app/modules/shared/components/form-validation/form-validation.component";

// Enums
import { StatusResponse } from "src/app/models/api/status.response";

// Environments
import { environment } from "src/environments/environment";

// Libraries
import intlTelInput from "intl-tel-input";

// Models
import { IntiTelephoneInputCountryData } from "src/app/modules/shared/models/inti-telephone-input/inti-telephone-input-country-data.model";
import { UpdateUserCredentialsResponse } from "src/app/modules/shared/models/update-user-credentials-response.model";
import { UpdateUserCredentialsRequest } from "src/app/modules/shared/models/update-user-credentials-request.model";
import { StaticPageRequest } from "src/app/modules/shared/models/static-page-request.model";
import { Faq } from "src/app/modules/shared/models/faq/faq.model";

// Services
import { TranslationService } from "src/app/modules/multi-languages/services/translation.service";
import { StaticPageService } from "src/app/modules/static-pages/services/static-page.service";
import { CommonService } from "src/app/modules/shared/services/common.service";

// Validators
import { UniqueFieldsValidator } from "src/app/modules/validators/validators/unique.validators";
import { CharactersValidator } from "src/app/modules/validators/validators/characters.validators";

@Component({
  selector: "app-subscribe-offers",
  templateUrl: "./subscribe-offers.component.html",
  styleUrls: ["./subscribe-offers.component.scss"],
})
export class SubscribeOffersComponent extends FormValidationComponent {
  // View Childs
  @ViewChild("dynamicContent", { static: false }) el: ElementRef;

  // Outputs
  @Output() callOnEmailSubscriptionUpdate: EventEmitter<void> =
    new EventEmitter<void>();

  // Numbers
  maximumAmount: number = 10;
  minimumAmount: number = 5;

  // Strings
  errorMessage: string = "";

  // Booleans
  isOpenTermsPopup: boolean = false;
  isPageAvailable: boolean = false;
  isButtonLoader: boolean = false;
  isPopupLoader: boolean = false;

  // Objects
  intlTelInputInstance: intlTelInput;

  // Forms
  emailUpdateSubscribeFrom: FormGroup;

  // Subscriptions
  userProfileDetailsSubscription: Subscription;
  updateUserSubscription: Subscription;
  faqSubscription: Subscription;

  constructor(
    private translationService: TranslationService,
    private staticPageService: StaticPageService,
    private commonService: CommonService,
    private formBuilder: FormBuilder
  ) {
    super();

    this.onLoad();
  }

  // -----------------------------------------------------------------
  // Lifecycle Hooks
  ngOnInit(): void {
    this.onInitializeTelInput();
  }

  // -----------------------------------------------------------------
  // Set Methods
  onLoad(): void {
    this.emailUpdateSubscribeFrom = this.formBuilder.group({
      txtEmail: new FormControl("", [
        UniqueFieldsValidator.createValidator(
          "txtEmail",
          this.commonService,
          false
        ),
      ]),
      areaCode: ["", [Validators.required]],
      phone: ["", CharactersValidator.validatePhoneNumber()],
      emailandMobileSubscribed: [false],
      termsConditions: [false, [Validators.required]],
    });
  }

  onUpdateUserCredentials(): void {
    this.isButtonLoader = true;

    this.errorMessage = undefined;

    if (this.emailUpdateSubscribeFrom.valid) {
      const updateUserCredentialsRequest: UpdateUserCredentialsRequest = {
        email: this.emailUpdateSubscribeFrom.controls["txtEmail"].value,
        areaCode: this.emailUpdateSubscribeFrom.controls["areaCode"].value,
        phone: this.emailUpdateSubscribeFrom.controls["phone"].value,
        emailSubscribed:
          this.emailUpdateSubscribeFrom.controls["emailandMobileSubscribed"]
            .value,
        mobileSubscribed:
          this.emailUpdateSubscribeFrom.controls["emailandMobileSubscribed"]
            .value,
      };

      this.updateUserSubscription = this.commonService
        .updateUserCredentials(updateUserCredentialsRequest)
        .subscribe(
          (updateUserCredentialsResponse: UpdateUserCredentialsResponse) => {
            if (
              updateUserCredentialsResponse &&
              updateUserCredentialsResponse.status === StatusResponse.SUCCESS
            ) {
              this.userProfileDetailsSubscription = this.commonService
                .onForceGetUserProfileDetails()
                .subscribe();

              this.callOnEmailSubscriptionUpdate.emit();
            } else {
              this.onUserUpdatedFailureHandler(updateUserCredentialsResponse);
            }
          }
        );

      setTimeout(() => {
        this.isButtonLoader = false;
      }, 2000);
    }
  }

  onUserUpdatedFailureHandler(
    updateUserCredentialsResponse: UpdateUserCredentialsResponse
  ): void {
    if (
      updateUserCredentialsResponse &&
      updateUserCredentialsResponse.status === StatusResponse.FAILURE &&
      updateUserCredentialsResponse.errorCode === 100170
    ) {
      this.errorMessage = this.translationService.get(
        "subscribeOffersModal.error_100170_emailAlreadyExit"
      );
    } else if (
      updateUserCredentialsResponse &&
      updateUserCredentialsResponse.errors &&
      updateUserCredentialsResponse.errors.errorCode === "100461"
    ) {
      this.errorMessage = this.translationService.get(
        "subscribeOffersModal.error_100461"
      );
    } else if (
      updateUserCredentialsResponse &&
      updateUserCredentialsResponse.errors &&
      updateUserCredentialsResponse.errors.hasOwnProperty("email")
    ) {
      this.errorMessage = this.translationService.get(
        "subscribeOffersModal.valid_email"
      );
    } else if (
      updateUserCredentialsResponse &&
      updateUserCredentialsResponse.errors &&
      updateUserCredentialsResponse.errors.hasOwnProperty("password")
    ) {
      this.errorMessage = this.translationService.get(
        "subscribeOffersModal.valid_password"
      );
    } else {
      this.errorMessage = this.translationService.get(
        "subscribeOffersModal.something_went_wrong"
      );
    }
  }

  onInitializeTelInput(initialSelected?: string): void {
    // can put any country to be excluded here
    let excludedCountries: string[] = [];

    initialSelected = initialSelected ? initialSelected : "FI";

    const input: HTMLElement = document.getElementById("mobileNumber");

    this.intlTelInputInstance = intlTelInput(input, {
      initialCountry: initialSelected,
      nationalMode: false,
      excludeCountries: excludedCountries,
      preferredCountries: [],
      autoPlaceholder: false,
      separateDialCode: true,
    });

    if (this.intlTelInputInstance.getSelectedCountryData()) {
      const countryData: IntiTelephoneInputCountryData =
        this.intlTelInputInstance.getSelectedCountryData();

      this.emailUpdateSubscribeFrom.controls["areaCode"].setValue(
        countryData.dialCode
      );
    }

    input.addEventListener("countrychange", () => {
      let countryData: IntiTelephoneInputCountryData =
        this.intlTelInputInstance.getSelectedCountryData();

      this.emailUpdateSubscribeFrom.controls["areaCode"].setValue(
        countryData.dialCode
      );
    });
  }

  onOpenSubscribeTermsPopup(): void {
    let pathURL: string = this.translationService.get("url.terms_conditions");

    this.isOpenTermsPopup = true;

    this.isPopupLoader = true;

    this.onGetFaqStaticPage(pathURL);
  }

  onSubscribeOffersToCloseButton(): void {
    this.isPageAvailable = false;

    this.isOpenTermsPopup = false;
  }

  onGetFaqStaticPage(urlPath: string): void {
    let staticRequest: StaticPageRequest = {
      urlPath,
      isForce: false,
    };

    this.faqSubscription = this.staticPageService
      .onGetStaticContent<StaticPageRequest, Faq>(staticRequest)
      .subscribe((faqResponse: Faq) => {
        this.isPopupLoader = false;

        /*
          Block to handle if no content is configured for this
          Specific path
        */
        if (!Object.keys(faqResponse).length || faqResponse.errorCode) {
          this.isPageAvailable = false;
        } else {
          /*
            block to handle successfull content from cms...
          */
          this.isPageAvailable = true;

          const staticContent: string = faqResponse.content.replace(
            new RegExp("{{site_url}}", "g"),
            environment.siteUrl
          );

          setTimeout(() => {
            if (this.el && this.el.nativeElement) {
              this.el.nativeElement.innerHTML = staticContent;
            }
          });
        }
      });
  }

  // -----------------------------------------------------------------
  // On Destroy
  ngOnDestroy(): void {
    this.isPageAvailable = false;

    if (this.userProfileDetailsSubscription)
      this.userProfileDetailsSubscription.unsubscribe();

    if (this.updateUserSubscription) this.updateUserSubscription.unsubscribe();

    if (this.faqSubscription) this.faqSubscription.unsubscribe();

    if (this.intlTelInputInstance) this.intlTelInputInstance.destroy();
  }
}
