<div
  class="game-group__container"
  [ngClass]="{'continue-games': (gameGroupData && gameGroupData.group_type === 'automatic'
&& gameGroupData.group_sub_type === 'continue_playing'),
'favourite-games':(gameGroupData && gameGroupData.group_type === 'automatic'
&& gameGroupData.group_sub_type === 'favourite')}"
>
  <div class="game-group--title">
    <span
      class="gameGroupName-link"
      (click)="onNavigateAllGamesPage()"
    >
      {{gameGroupData.name}}
    </span>
    <span
      class="viewall-link"
      (click)="onNavigateAllGamesPage()"
    >
      {{'gamegroup.view_all'| translate }}
    </span>
  </div>

  <div class="swiper__outer hoverspace">
    <div class="swiper__inner swiper__full">
      <swiper
        class="mySwiper swiper-control"
        [config]="swiperCasino"
        (slidePrevTransitionEnd)="onSwiperPrevEvent()"
        (slideNextTransitionEnd)="onSwiperNextEvent()"
        watchSlidesProgress
      >
        <div
          class="game-card casino"
          *ngFor="let game of gamesList | slice:0: swiperLazyConfigurations.indexNumberOfGameTilesToshow; let i = index"
        >
          <div class="game-card-inner">
            <app-game-card
              [gameData]="game"
              [isJackpot]="false"
              [gameGroupName]="gameGroupData.name"
              [lobbyName]="callingFromlobby"
              [game_asset_style]="gameGroupData.game_asset_style"
            >
            </app-game-card>
            <div class="game-card__footer">
              <div class="game-title">{{game.name}}</div>
            </div>
          </div>
        </div>
      </swiper>
    </div>
  </div>
</div>
