import { createFeatureSelector, createSelector } from "@ngrx/store";
// Reducers
import { UserStateFeatureKey, } from "src/app/modules/user/store/reducers/user.reducer";
// Select the Main State
const selectUserReducerState = createFeatureSelector(UserStateFeatureKey);
const ɵ0 = (state) => {
    return {
        userData: state.userData,
        isLoaded: state.isLoaded,
        isError: state.isError,
    };
};
// User Data
export const selectAuthUserDataLoaded = createSelector(selectUserReducerState, ɵ0);
const ɵ1 = (state) => {
    return state.userData;
};
export const selectUserData = createSelector(selectUserReducerState, ɵ1);
export { ɵ0, ɵ1 };
