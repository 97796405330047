<div class="rewardProgress">
  <p class="userlevel">
    <span
      *ngIf="rewardsIndicator.levelDetails.currentLevelID > 0; else elseBlock"
      [class.isGamePlay]="isGamePlayWindow"
    >
      {{'rewards.level' | translate}} {{
      rewardsIndicator.levelDetails.currentLevelID }}
    </span>
    <ng-template #elseBlock>
      <ng-container *ngIf="!isDisableLevelUpLoader">
        <span class="level-loader" #elseBlock></span>
      </ng-container>
    </ng-template>
  </p>

  <div
    class="rewardProgress__outer"
    (click)="(notificationCount > 0 && !isPnpFlow && rewardsIndicator.pointsDetails.currentSpinCount <= playButtonCutOffValue)
            ? onOpenCongratulationsPopUp() : onOpenAccount('rewards')"
  >
    <span class="levelUpNotify" *ngIf="notificationCount > 0">
      {{notificationCount}}
    </span>

    <svg
      class="rewardProgress__svg"
      width="96"
      height="96"
      viewBox="0 0 96 96"
      [class.hide]="((notificationCount > 0 && rewardsIndicator.pointsDetails.currentSpinCount <= playButtonCutOffValue))"
    >
      <circle
        class="rewardProgress__meter"
        cx="48"
        cy="48"
        [attr.r]="radius"
        stroke-width="6"
      />
      <circle
        id="rewardProgressValue"
        class="rewardProgress__value"
        [style.strokeDasharray]="circumference"
        [style.strokeDashoffset]="dashOffset"
        cx="48"
        cy="48"
        [attr.r]="radius"
        stroke-width="6"
      />
    </svg>

    <div
      class="rewardProgress__img rewardProgress__cirlceImgInProgress"
      *ngIf="(progressBarValue > 0 && rewardsIndicator.pointsDetails.currentSpinCount > playButtonCutOffValue) || notificationCount === 0"
    >
      <img src="/assets/img/rewards/reward-man.svg" alt="reward-progress" />
    </div>

    <div
      class="rewardProgress__img rewardProgress__cirlceImgReachedProgress"
      *ngIf="(notificationCount > 0 && rewardsIndicator.pointsDetails.currentSpinCount <= playButtonCutOffValue)"
    >
      <img
        src="/assets/img/rewards/reward-play.svg"
        alt="reward-progress-play"
      />
    </div>
  </div>
</div>
