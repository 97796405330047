<app-side-menu-modal
  *ngIf="userCurrentLevelUpDetails"
  [customClass]="'rewardsCongrats'"
  [showBackBtn]="false"
  [sideMenuTitle]=""
>
  <!-- modal body -->
  <div class="rewards-congrats" sideMenuBody>
    <button
      aria-label="Close"
      class="sideMenu__action-btn btn-secondary float-right"
      (click)="onCloseComponent()"
    >
      <i class="icon-close"></i>
    </button>

    <ng-container *ngIf="!isError">
      <div class="rays"></div>
      <div class="confettiRain"></div>

      <div class="rewards-content">
        <h2 class="text-center">
          {{'rewards.congrats' | translate}}<br />{{'rewards.you_levelledUp' |
          translate}}
        </h2>
        <h5 class="m-t-8 text-center">
          {{'rewards.collect_reward' | translate}}
        </h5>

        <div class="reward-top-space">
          <ng-container *ngIf="!isLevelUpClaimed">
            <div id="coin" [class.rotate-card]="isCoin">
              <div class="side-a">
                <div
                  class="rewardBonusCard no-shadow"
                  (click)="onGetClaimLevelUpBonus(userCurrentLevelUpDetails.newLevelId)"
                  [class.isbuttonDisable]="isButtonDisable"
                >
                  <div class="rewardBonusCard__bg">
                    <img
                      src="/assets/img/rewards/bonus-img/unlock-reward.svg"
                    />
                  </div>
                  <div class="rewardBonusCard__container">
                    <div class="rewardBonusCard__icon">
                      <img
                        src="/assets/img/rewards/unlock-reward-icon.png"
                        class="rewardBonusCardImg no-shadow"
                      />
                    </div>
                    <div class="rewardBonusCard__details no-justify-content">
                      <div class="rewardBonusCard--title">
                        {{'rewards.level' | translate}}
                        {{userCurrentLevelUpDetails.newLevelId}}
                      </div>
                      <div class="rewardBonusCard--desc">
                        {{'rewards.level_up' | translate}}
                        <br />
                        {{'rewards.to_unlock_reward' | translate}}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="side-b">
                <div class="rewardBonusCard clearfix">
                  <div class="rewardBonusCard__bg">
                    <img
                      src="/assets/img/rewards/bonus-img/shotz-rewards.svg"
                      alt="bonus-card-bg"
                    />
                  </div>
                </div>
              </div>
            </div>
          </ng-container>

          <div
            class=""
            *ngIf="isLevelUpClaimed && userCurrentLevelUpDetails.gameData"
          >
            <app-free-spin-reward-card
              [callingFrom]="'reward-congrats'"
              (callOpenRewardTCPopUp)="onOpenRewardTCPopUp($event)"
              [bonusDetails]="userCurrentLevelUpDetails"
            ></app-free-spin-reward-card>
          </div>
          <div class="clearfix openSpinBtns-c text-center">
            <button
              aria-label="Open rewards"
              *ngIf="!isLevelUpClaimed"
              [setLoader]="isButtonDisable"
              [disabled]="isButtonDisable"
              [class.isbuttonDisable]="isButtonDisable"
              class="btn btn-lg btn-primary btn-round btn-rewards reward-cta m-t-44"
              (click)="onGetClaimLevelUpBonus(userCurrentLevelUpDetails.newLevelId)"
            >
              {{'rewards.open_reward' | translate}}
            </button>
            
            <button
              aria-label="Save for later"
              *ngIf="isLevelUpClaimed && userCurrentLevelUpDetails.gameData"
              class="btn btn-lg btn-ghost btn-round btn-rewards reward-cta m-t-44"
              (click)="onCloseComponent()"
            >
              {{'rewards.save_for_later' | translate}}
            </button>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="isError">
      <div class="rewards-fail-msg">
        <div class="rewards-msg-box">
          <h2 class="m-b-24">{{'rewards.something_wrong' | translate}}</h2>
          <p>
            {{'rewards.keep_calm' | translate}}<br />

            <strong>{{'rewards.will_sort' | translate}}</strong>
          </p>

          <button
            aria-label="Chat now"
            class="btn btn-lg btn-round btn-primary btn-rewards m-t-32"
            (click)="onInitializeLiveChat()"
          >
            {{'rewards.chat_now' | translate}}</button
          ><br />
          <button
            aria-label="Chat later"
            class="btn btn-lg btn-round btn-ghost btn-rewards m-t-16"
            (click)="onCloseComponent()"
          >
            {{'rewards.chat_later' | translate}}
          </button>
        </div>
      </div>
    </ng-container>
  </div>
</app-side-menu-modal>
