import { Observable, Subscription, BehaviorSubject } from "rxjs";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";

// Libraries
import * as _ from "underscore";

// Models
import { MicroGamingJackpot } from "src/app/modules/game-groups/models/micro-gaming-jackpot.model";
import { JackpotFeed } from "src/app/modules/game-groups/models/jackpot/jackpot-feed.model";
import { GamePregmatic } from "src/app/modules/game-groups/models/game.model";

// Services
import { GameGroupsService } from "src/app/modules/game-groups/services/game-groups.service";
import { UserDetailsService } from "src/app/modules/user/services/user-details.service";
import { MainService } from "src/app/modules/shared/services/main.service";

@Injectable({
  providedIn: "root",
})
export class JackpotService {
  // Arrays
  microGamingJackpotFeed?: JackpotFeed[] = [];

  // Others
  mircroGameJackpots?: MicroGamingJackpot = {};

  // ---------------------------------------------------------//
  // Behaviour Subjects
  public mircoGamingJackpotDataBehavourSubject: BehaviorSubject<MicroGamingJackpot> =
    new BehaviorSubject<MicroGamingJackpot>(this.mircroGameJackpots);
  public mircoGamingJackpotDataBehavourSubject$: Observable<MicroGamingJackpot> =
    this.mircoGamingJackpotDataBehavourSubject.asObservable();

  // Subscriptions
  jackpotSubscription: Subscription;
  subscription: Subscription;

  constructor(
    private userDetailsService: UserDetailsService,
    private gameGroupsService: GameGroupsService,
    private mainService: MainService
  ) {
    this.onGetMessagesJackpots();

    this.subscription =
      this.userDetailsService.userBalanceUpdatedSubject$.subscribe(() =>
        this.onGetMessagesJackpots()
      );
  }

  // -----------------------------------------------------------------
  // Get Methods
  onGetJackpotFeedList(): Observable<JackpotFeed[]> {
    return this.mainService
      .onGetMicroGamingJackpotFeed(this.userDetailsService.getCurrencyCode())
      .pipe(
        map((jackpotFeedList: JackpotFeed[]) => {
          return jackpotFeedList;
        })
      );
  }

  // -----------------------------------------------------------------
  // Set Methods
  onGetMessagesJackpots(): void {
    this.jackpotSubscription = this.onGetJackpotFeedList().subscribe(
      (jackpotFeedList: JackpotFeed[]) => {
        this.microGamingJackpotFeed = jackpotFeedList;

        this.mircroGameJackpots = {};

        if (jackpotFeedList.length > 0) {
          this.gameGroupsService
            .onGetGamePregmaticList()
            .subscribe((gamesList: GamePregmatic[]) => {
              let microGamingGamesList: GamePregmatic[] = _.where(gamesList, {
                gameType: "progressive_slots",
              });

              jackpotFeedList.filter((jackpotFilter: JackpotFeed) => {
                let gameName: string = jackpotFilter.friendlyName
                  .replace(/[\W_]+/g, " ")
                  .trim();

                microGamingGamesList.filter(
                  (filterGamePregmatic: GamePregmatic) => {
                    if (
                      filterGamePregmatic.name
                        .toUpperCase()
                        .indexOf(gameName.toUpperCase()) > -1 &&
                      jackpotFilter.gamePayId == 0
                    ) {
                      this.mircroGameJackpots[filterGamePregmatic.gameCode] =
                        jackpotFilter.startAtValue;
                    }
                  }
                );
              });

              this.onBroadCastMicroGamingJackpotSuccess();
            });
        }
      }
    );
  }

  onBroadCastMicroGamingJackpotSuccess(): void {
    this.mircoGamingJackpotDataBehavourSubject.next(this.mircroGameJackpots);
  }

  // -----------------------------------------------------------------
  // On Destroy
  ngOnDestroy(): void {
    if (this.jackpotSubscription) this.jackpotSubscription.unsubscribe();

    if (this.subscription) this.subscription.unsubscribe();
  }
}
