<main class="page-body landing-page">
  <div class="top-gradient"></div>
  <section class="welcome-bonus">
    <div class="welcome-bonus-container">
      <div class="padding welcome-bonus-text">
        <ng-container *ngIf="isAffiliate">
          <h2
            class="w-title"
            [innerHtml]="affiliateOfferJsonText | safeHtml"
          ></h2>
          <p class="w-desc m-t-16">
            {{'affiliate.' + userAffiliateId + '_free_spins' | translate}}
          </p>
        </ng-container>

        <ng-container *ngIf="!isAffiliate">
          <h2 class="w-title">
            {{ "landingpage.get_your" | translate }} <br />
            {{ "landingpage.get_your_bonus" | translate }}
          </h2>
          <p class="w-desc">{{ "landingpage.free_spins" | translate }}</p>
        </ng-container>

        <button
          aria-label="Join now"
          class="btn btn-primary btn-join m-t-20"
          (click)="onOpenRegistrationPopup()"
          *ngIf="deviceType === 'desktop'"
        >
          {{ "landingpage.join_now" | translate }}
        </button>
      </div>

      <div class="join-button-mobile">
        <button
          aria-label="Join now"
          class="btn btn-primary join-2-row-btn m-t-20 m-auto"
          (click)="onOpenRegistrationPopup()"
          *ngIf="!isLoggedIn && deviceType === 'mobile'"
        >
          {{ "landingpage.join_now_mobile-text" | translate }}
          <span (click)="onOpenRegistrationPopup()"
            >{{ "landingpage.cta_caption_mobile-text" | translate }}</span
          >
        </button>
      </div>

      <div class="jackpot-container">
        {{ jackpotTotalAmount }}
      </div>
    </div>
  </section>

  <div class="clearfix"></div>

  <section class="surprise-chance">
    <div class="surprise-container">
      <div class="surprise-content">
        <h3>This surprise</h3>
        <h3>is no surprise</h3>
        <p>
          Get exciting instant rewards with our Brand Booster while you are
          enjoying your favourite games from the best game providers!
        </p>
      </div>

      <div class="surprise-image">
        <img src="/assets/img/landing-page/shotz/High Striker Machine 1.png" />
      </div>
    </div>

    <div class="surprise-chance-rollercoaster">
      <img src="/assets/img/landing-page/shotz/roller coaster.png" />
    </div>

    <div class="chance-container">
      <div class="chance-image">
        <img src="/assets/img/landing-page/shotz/ShotzCart_Goldlever 3.png" />
      </div>

      <div class="chance-content">
        <h3>A chance</h3>
        <h3>to win big</h3>
        <p>
          Try some jackpot games that can land you a big win. Buckle up, spin
          the reels and enjoy the ride; you never know!
        </p>
      </div>

      <div class="chance-payments">
        <img src="/assets/img/landing-page/shotz/payments.png" />
        <a (click)="onGoToAllProvidersPage()">{{'landingpage.more' | translate}}</a>
      </div>
    </div>
  </section>

  <div class="clearfix"></div>
  <!--VIP Section Start-->
  <section class="vip-wrap">
    <div class="container">
      <div class="vip-wrap__bg">
        <div class="vip-outer-wrap">
          <div class="vip-inner-wrap">
            <div class="vip-card">
              <div class="vip-card__inner">
                <span class="icon">
                  <img
                    src="/assets/img/landing-page/shotz/Piggy_Bank_Gold.png"
                    alt="{{ 'landingpage.weekly_cashback' | translate }}"
                    title="{{ 'landingpage.weekly_cashback' | translate }}"
                  />
                </span>

                <div class="vip-text-container">
                  <h4>{{ "landingpage.weekly_cashback" | translate }}</h4>
                  <p>
                    {{ "landingpage.we_provide_gold_standard" | translate }}
                  </p>
                </div>
              </div>
            </div>

            <div class="vip-card">
              <div class="vip-card__inner">
                <span class="icon"
                  ><img
                    src="/assets/img/landing-page/shotz/Gold_Wallet_Icon.png"
                    alt="{{ 'landingpage.personal_account_manager' | translate }}"
                    title="{{ 'landingpage.personal_account_manager' | translate }}"
                /></span>

                <div class="vip-text-container">
                  <h4>
                    {{ "landingpage.personal_account_manager" | translate }}
                  </h4>
                  <p>{{ "landingpage.personal_gold_standard" | translate }}</p>
                </div>
              </div>
            </div>

            <div class="vip-card">
              <div class="vip-card__inner">
                <span class="icon"
                  ><img
                    src="/assets/img/landing-page/shotz/Gold_Gift_Icon.png"
                    alt="{{ 'landingpage.personalized_bonuses' | translate }}"
                    title="{{ 'landingpage.personalized_bonuses' | translate }}"
                /></span>
                <div class="vip-text-container">
                  <h4>{{ "landingpage.personalized_bonuses" | translate }}</h4>
                  <p>
                    {{ "landingpage.personalized_gold_standard" | translate }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--VIP Section End-->
  </section>

  <!--Feel care Section start-->
  <section class="feel-care padding">
    <div class="container">
      <div class="feel-care__wrapper">
        <h2
          class="s-title show-desktop text-center"
          *ngIf="deviceType === 'desktop'"
        >
          {{'landingpage.feel' | translate}} {{'landingpage.cared_for' |
          translate}}
        </h2>
        <h2
          class="s-title show-desktop text-center"
          *ngIf="deviceType === 'mobile'"
        >
          {{'landingpage.feel' | translate}} <br />{{'landingpage.cared_for' |
          translate}}
        </h2>
        <div class="feel-care__list-wrap">
          <div class="feel-care__list-wrap-inner">
            <div class="feel-care__list">
              <h4>{{'landingpage.live_support'| translate}}</h4>
              <p>{{'landingpage.casino_representatives'| translate}}</p>
            </div>

            <div class="feel-care__list">
              <h4>{{'landingpage.liveChat_email' | translate}}</h4>
              <p>{{'landingpage.service_en_ger_nor_fin_fn'| translate}}</p>
            </div>

            <div class="feel-care__list">
              <h4>{{'landingpage.ready_help' | translate}}</h4>
              <p>{{'landingpage.we_reg_dep_withdraws' | translate}}</p>
            </div>
          </div>
        </div>
        <div class="feel-care__faq" id="faq-sec">
          <div class="feel-care__faq-accordion">
            <h3>{{'landingpage.faqs'| translate}}</h3>
            <accordion
              class="accordion"
              [showArrows]="true"
              [closeOthers]="true"
              [expandAll]="false"
            >
              <accordion-group>
                <accordion-heading class="accoredion-title">
                  {{'landingpage.faq_q1'| translate}}
                </accordion-heading>
                <div class="accordion-content">
                  <p>{{'landingpage.faq_a1'| translate}}</p>
                </div>
              </accordion-group>

              <accordion-group>
                <accordion-heading class="accoredion-title">
                  {{'landingpage.faq_q2'| translate}}
                </accordion-heading>
                <div class="accordion-content">
                  <p>{{'landingpage.faq_a2'| translate}}</p>
                </div>
              </accordion-group>

              <accordion-group>
                <accordion-heading class="accoredion-title">
                  {{'landingpage.faq_q3'| translate}}
                </accordion-heading>
                <div class="accordion-content">
                  <p>{{'landingpage.faq_a3'| translate}}</p>
                </div>
              </accordion-group>

              <accordion-group>
                <accordion-heading class="accoredion-title">
                  {{'landingpage.faq_q4'| translate}}
                </accordion-heading>
                <div class="accordion-content">
                  <p>{{'landingpage.faq_a4'| translate}}</p>
                </div>
              </accordion-group>
            </accordion>
          </div>
          <div class="feel-care__faq-dog">
            <img src="/assets/img/landing-page/shotz/faq-dog.png" />
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- Payment Section Start -->
  <div id="FixedFooter" #observerElementHeight></div>
  <section
    tabindex="4"
    class="padding fixed-payment-bar fade-in"
    id="fixed-bar"
    (click)="deviceType === 'desktop' && !isLoggedIn ? onOpenRegistrationPopup() : ''"
    [ngClass]="isFixedBar ? 'sticky' : 'stickyNo'"
    [class.cursor-hand]="!isLoggedIn"
  >
    <div
      class="container fixed-payment-bar__inner"
      [ngClass]="isFixedBar ? 'sticky' : 'stickyNo'"
    >
      <div class="payment-features">
        <div class="payment-features__list">
          <span class="icon">
            <img
              src="/assets/img/landing-page/shotz/no-lag.svg"
              alt="{{ 'landingpage.fast_gameplay' | translate }}y"
              title="{{ 'landingpage.fast_gameplay' | translate }}"
            />
          </span>
          <span class="text">
            <strong>{{ "landingpage.no_lag" | translate }}</strong>
            <span class="feature-desc"
              >{{ "landingpage.fast_gameplay" | translate }}</span
            >
          </span>
        </div>

        <div class="payment-features__list">
          <span class="icon">
            <img
              src="/assets/img/landing-page/shotz/no-hassle.svg"
              alt="{{ 'landingpage.fast_deposits' | translate }}"
              title="{{ 'landingpage.fast_deposits' | translate }}"
            />
          </span>
          <span class="text">
            <strong>{{ "landingpage.no_hassle" | translate }}</strong>
            <span class="feature-desc"
              >{{ "landingpage.fast_deposits" | translate }}</span
            >
          </span>
        </div>

        <div class="payment-features__list">
          <span class="icon">
            <img
              src="/assets/img/landing-page/shotz/no-waiting.svg"
              alt="{{ 'landingpage.fast_withdrawals' | translate }}"
              title="{{ 'landingpage.fast_withdrawals' | translate }}"
            />
          </span>
          <span class="text">
            <strong>{{ "landingpage.no_waiting" | translate }}</strong>
            <span class="feature-desc"
              >{{ "landingpage.fast_withdrawals" | translate }}</span
            >
          </span>
        </div>
      </div>

      <div class="payment-methods">
        <ng-container *ngIf="isScrolled">
          <button
            class="btn btn-primary btn-at-bottom"
            (click)="onOpenRegistrationPopup()"
            *ngIf="!isLoggedIn"
          >
            {{ "landingpage.join_now" | translate }}
          </button>
        </ng-container>

        <span class="payment-types" *ngIf="!isScrolled">
          <img
            src="{{ mediaUrlPathHir }}/LP/payment_{{ languageCode }}.png{{ imgixParams }}"
            alt="{{ 'landingpage.payment_methods' | translate }}"
          />
        </span>
      </div>
    </div>
  </section>
  <!--Payment Section End -->
</main>
<router-outlet></router-outlet>
