<div
  class="rewardBonusCard clearfix cursor-hand"
  *ngIf=" bonusDetails && bonusDetails?.gameData"
  (click)="onLaunchGame(bonusDetails?.gameData.name)"
>
  <div class="rewardBonusCard__bg">
    <img
      src="/assets/img/rewards/bonus-img/shotz-rewards.png"
      *ngIf="callingFrom === 'all-rewards'"
      alt="bonus-card-bg"
    />
    <ng-container *ngIf="callingFrom === 'reward-congrats'">
      <img
        src="/assets/img/rewards/bonus-img/shotz-rewards.png"
        alt="bonus-card-bg"
      />
    </ng-container>
  </div>
  <div class="rewardBonusCard__container">
    <div class="rewardBonusCard__icon">
      <img
        src="/assets/img/rewards/bonus-img/freespins-bonus.png"
        class="rewardBonusCardImg"
        alt="freespins-bonus"
      />

      <div
        class="countdown-timer"
        *ngIf="bonusDetails?.freespinExpiryDate && callingFrom === 'all-rewards'"
      >
        <app-countdown-timer
          [expiryDate]="bonusDetails?.freespinExpiryDate"
        ></app-countdown-timer>
      </div>
    </div>
    <div class="rewardBonusCard__details">
      <div class="rewardBonusCard__text-inside">
        <div class="rewardBonusCard--title">
          {{'rewards.free_spin' | translate}}
        </div>

        <div class="rewardBonusCard--desc">
          {{'rewards.freespinCt_at_spinvalue'| translate :( {
          'freeSpinCount':(bonusDetails?.freeSpinCount),
          'freeSpinValue':((bonusDetails?.freeSpinValue / 100) |
          currencyformat:currencySymbol:false) } )}}
          <ng-container *ngIf="bonusDetails?.gameData"
            ><br />
            {{'rewards.no_game' | translate :({'game':
            bonusDetails?.gameData.name })}}
          </ng-container>
        </div>
      </div>
      <button
        aria-label="Play now"
        class="btn btn-primary btn-round btn-xs"
        (click)="onLaunchGame(bonusDetails?.gameData.name)"
      >
        {{'rewards.play_now' | translate}}
      </button>
      <button aria-label="Terms" class="btn btn-info">
        <i class="icon-info" (click)="onOpenRewardTCPopUp($event)"></i>
      </button>
    </div>
    <div class="game-img">
      <img
        [src]="gamesUrlPath + '/' + bonusDetails?.gameData.gameCode + '/' + bonusDetails?.gameData.gameCode + '_casino.jpg' + imgixParams"
        alt="{{bonusDetails?.gameData.name}}"
        title="{{bonusDetails?.gameData.name}}"
      />
    </div>
  </div>
</div>
