<div class="notifyMsg" *ngIf="isLoggedIn && isOpenLimitsRegulatoryPopup">
  <div class="notifyMsg__container">
    <button
      class="notifyMsg--coloseBtn btn"
      aria-label="Close"
      (click)="onCloseRegulatoryPopup()"
    >
      <i class="icon-close"></i>
    </button>

    <h3 class="notifyMsg--title">
      {{'LimitsRegulatoryMsg.LimitsRegulatoryMsgTitle' | translate}}
    </h3>

    <p class="notifyMsg--text m-t-8 m-b-16">
      {{'LimitsRegulatoryMsg.LimitsRegulatoryMsgText' | translate}}
    </p>

    <ng-container *ngTemplateOutlet="buttonGroupsTemplate"></ng-container>
  </div>
</div>

<!-- 
  ------------------------------------------------------
  
  Templates 
  
  ------------------------------------------------------
-->
<!-- Button Groups Template -->
<ng-template #buttonGroupsTemplate>
  <div class="notifyMsg--btn-group">
    <button
      class="btn btn-sm btn-secondary btn-round btn-50 m-r-8"
      [attr.aria-label]="'LimitsRegulatoryMsg.setLmits' | translate"
      (click)="onNavigateToLimits()"
    >
      {{'LimitsRegulatoryMsg.setLmits' | translate}}
    </button>

    <button
      class="btn btn-sm btn-primary btn-round btn-50"
      [attr.aria-label]="'LimitsRegulatoryMsg.later' | translate"
      (click)="onCloseRegulatoryPopup()"
    >
      {{'LimitsRegulatoryMsg.later' | translate}}
    </button>
  </div>
</ng-template>
