<div class="subscribeOffers">
  <h2>{{'subscribeOffersModal.modal_title' | translate}}</h2>

  <div class="subscribeOffers__formBox">
    <ng-container *ngIf="errorMessage">
      <div class="alert-danger alert">{{errorMessage}}</div>
    </ng-container>

    <form [formGroup]="emailUpdateSubscribeFrom">
      <div class="form-group">
        <label class="labelText">
          {{'subscribeOffersModal.email' | translate}}
          <span class="smallLabel">
            {{'subscribeOffersModal.required' | translate}}
          </span>
        </label>

        <br />

        <input
          type="email"
          class="labelInput"
          formControlName="txtEmail"
          placeholder="{{'subscribeOffersModal.enter_email' | translate}}"
        />

        <ng-container *ngTemplateOutlet="emailErrorTemplate"></ng-container>
      </div>

      <div class="form-group flexCenter noBottomMargin">
        <label class="labelText" for="emailSubscribed">
          {{'subscribeOffersModal.telephone_number' | translate}}
        </label>
      </div>

      <div class="form-group telInput">
        <input
          type="tel"
          id="mobileNumber"
          class="labelInput noTopMargin"
          formControlName="phone"
          placeholder=""
        />

        <ng-container *ngTemplateOutlet="phoneErrorTemplate"></ng-container>
      </div>

      <div class="form-group termsBox">
        <span class="checkBoxLabel">
          {{'subscribeOffersModal.sms' | translate}}
        </span>

        <label class="labelText" for="mobileSubscribe">
          <input
            type="checkbox"
            id="mobileSubscribe"
            name="mobileSubscribe"
            formControlName="emailandMobileSubscribed"
          />

          <span class="checkmark"></span>
        </label>
      </div>

      <div class="form-group termsBox">
        <span class="checkBoxLabel">
          {{'subscribeOffersModal.i_accept' | translate}}
          <a class="link" (click)="onOpenSubscribeTermsPopup()">
            {{'subscribeOffersModal.terms_and_condtion' | translate}}
          </a>
        </span>

        <label class="labelText" for="terms">
          <input
            type="checkbox"
            id="terms"
            name="terms"
            formControlName="termsConditions"
          />
          <span class="checkmark"></span>
        </label>
      </div>

      <p class="form-group text-center">
        {{'subscribeOffersModal.modal_para1' | translate}}
      </p>

      <div class="form-group text-center">
        <button
          class="btn btn-cta"
          [setLoader]="isButtonLoader"
          [disabled]="emailUpdateSubscribeFrom.invalid || emailUpdateSubscribeFrom.pending || isButtonLoader"
          (click)="onUpdateUserCredentials()"
        >
          {{'subscribeOffersModal.continue' | translate}}
        </button>
      </div>
    </form>
  </div>
</div>

<div class="clearfix"></div>

<ng-container *ngIf="isOpenTermsPopup">
  <ng-container *ngTemplateOutlet="openTermsModalTemplate"></ng-container>
</ng-container>

<!-- 
  ------------------------------------------------------
  
  Error Templates 
  
  ------------------------------------------------------
-->
<ng-template #emailErrorTemplate>
  <div
    class="error-msg fade-in-down"
    *ngIf="
      emailUpdateSubscribeFrom.controls['txtEmail'].errors
        && 
      emailUpdateSubscribeFrom.controls['txtEmail'].touched
    "
  >
    <ng-container>
      <!-- 
        {{ forgotPasswordForm.controls['txtEmail'].errors | json }} 
      -->
      <div
        [hidden]="!emailUpdateSubscribeFrom.controls['txtEmail'].errors.required"
      >
        {{ "errors.error1" | translate }}
      </div>
      <div
        [hidden]="!emailUpdateSubscribeFrom.controls['txtEmail'].errors.invalidEmail"
      >
        {{ "errors.error22" | translate }}
      </div>
      <div
        [hidden]="!emailUpdateSubscribeFrom.controls['txtEmail'].errors.cannotBeLessThan"
      >
        {{ "errors.error61" | translate }}
      </div>
      <div
        [hidden]="!emailUpdateSubscribeFrom.controls['txtEmail'].errors.mustContainMinimum"
      >
        {{ "errors.error23" | translate }}
      </div>
      <div
        [hidden]="!emailUpdateSubscribeFrom.controls['txtEmail'].errors?.invalidEmailResponse"
      >
        {{ "errors.error28" | translate }}
      </div>
      <div
        [hidden]="!emailUpdateSubscribeFrom.controls['txtEmail'].errors?.invalidNicknameResponse"
      >
        {{ "errors.error25" | translate }}
      </div>
      <div
        [hidden]="!emailUpdateSubscribeFrom.controls['txtEmail'].errors?.notUniqueEmail"
      >
        {{ "errors.error29" | translate }}
      </div>
      <div
        [hidden]="!emailUpdateSubscribeFrom.controls['txtEmail'].errors?.notUniqueNickname"
      >
        {{ "errors.error30" | translate }}
      </div>
    </ng-container>
  </div>
</ng-template>

<ng-template #phoneErrorTemplate>
  <div
    class="error-msg fade-in-down"
    *ngIf="
      emailUpdateSubscribeFrom.controls['phone'].errors
        && 
      emailUpdateSubscribeFrom.controls['phone'].touched
    "
  >
    <ng-container>
      <!-- 
        {{ forgotPasswordForm.controls['phone'].errors | json }} 
      -->
      <div
        [hidden]="!emailUpdateSubscribeFrom.controls['phone'].errors.required"
      >
        {{ "errors.error1" | translate }}
      </div>
      <div
        [hidden]="!emailUpdateSubscribeFrom.controls['phone'].errors.onlyNumbers"
      >
        {{ "errors.error18" | translate }}
      </div>
      <div
        [hidden]="!emailUpdateSubscribeFrom.controls['phone'].errors.minimumNumbers"
      >
        {{ "errors.error19" | translate }} {{ minimumAmount }} {{
        "errors.error21" | translate }}
      </div>
      <div
        [hidden]="!emailUpdateSubscribeFrom.controls['phone'].errors.maximumNumbers"
      >
        {{ "errors.error20" | translate }} {{ maximumAmount }} {{
        "errors.error21" | translate }}
      </div>
    </ng-container>
  </div>
</ng-template>

<!-- 
  ------------------------------------------------------
  
  Modal Templates 
  
  ------------------------------------------------------
-->
<ng-template #openTermsModalTemplate>
  <section class="modal-popup" id="subscribeOffersTC">
    <div
      class="modal-popup__backdrop"
      (click)="onSubscribeOffersToCloseButton()"
    ></div>

    <div class="modal-popup__wrapper pointer-events-allow">
      <div class="modal-popup__container">
        <button
          class="btn modal-btn btn-modal-control modal-btn-right modal-popup--close-btn"
          aria-label="Close"
          (click)="onSubscribeOffersToCloseButton()"
        >
          <i class="icon-close"></i>
        </button>

        <div
          id="subscribeOffersTcContent"
          class="modal-popup__body"
          [setLoader]="isPopupLoader"
          [class.height100]="!isPageAvailable"
        >
          <div #dynamicContent></div>

          <ng-container *ngIf="!isPageAvailable">
            <p class="text-center">
              {{'subscribeOffersModal.pageNotAvailable' | translate}}
            </p>
          </ng-container>
        </div>
      </div>
    </div>
  </section>
</ng-template>
