/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./form-button.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./form-button.component";
var styles_FormButtonComponent = [i0.styles];
var RenderType_FormButtonComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_FormButtonComponent, data: {} });
export { RenderType_FormButtonComponent as RenderType_FormButtonComponent };
function View_FormButtonComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "icon-plus"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["+"]))], null, null); }
function View_FormButtonComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [["class", "fee m-t-16"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Fee \u20AC1.25"]))], null, null); }
function View_FormButtonComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "btn-wrapper text-center m-t-8"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "button", [["aria-label", "CTA"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onButtonClick(_co.config.key) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(4, { "has-error": 0 }), (_l()(), i1.ɵted(5, null, [" ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FormButtonComponent_2)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FormButtonComponent_3)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.config.class, ""); var currVal_1 = _ck(_v, 4, 0, _co.getShouldShowErrors(_co.config.key, _co.formGroup)); _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_3 = (_co.config.id === "addNewCard"); _ck(_v, 7, 0, currVal_3); var currVal_4 = _co.config.paymentFee; _ck(_v, 9, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.config.name; _ck(_v, 5, 0, currVal_2); }); }
export function View_FormButtonComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_FormButtonComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.isUsedAccount && _co.config.visibleOnRepeatPayment) || (!_co.isUsedAccount && _co.config.visibleOnNewPayment)); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_FormButtonComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-form-button", [], null, null, null, View_FormButtonComponent_0, RenderType_FormButtonComponent)), i1.ɵdid(1, 49152, null, 0, i3.FormButtonComponent, [], null, null)], null, null); }
var FormButtonComponentNgFactory = i1.ɵccf("app-form-button", i3.FormButtonComponent, View_FormButtonComponent_Host_0, {}, { buttonClicked: "buttonClicked" }, []);
export { FormButtonComponentNgFactory as FormButtonComponentNgFactory };
