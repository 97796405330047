import { Router, ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
import { Store } from "@ngrx/store";
import {
  ViewEncapsulation,
  HostListener,
  ElementRef,
  Component,
  ViewChild,
} from "@angular/core";

// Environments
import { environment } from "src/environments/environment";

// Libraries
import * as _ from "underscore";

// Models
import { NavigationRoute } from "src/app/modules/shared/models/navigation/navigation.model";
import { Promotion } from "src/app/modules/promotions/models/promotion.model";

// Reducers
import { AppState } from "src/app/store/reducers";

// Selectors
import { selectLanguageCode } from "src/app/modules/multi-languages/store/selectors/languages.selectors";
import {
  selectAuthLoginIsLoggedOut,
  selectAuthLoginIsLoggedIn,
} from "src/app/modules/auth/store/selectors/auth.selectors";

// Services
import { MultiLanguageService } from "src/app/modules/multi-languages/services/multi-language.service";
import { TranslationService } from "src/app/modules/multi-languages/services/translation.service";
import { PromotionsService } from "src/app/modules/promotions/services/promotions.service";
import { EmitterService } from "src/app/modules/shared/services/emitter.service";
import { UtilityService } from "src/app/modules/shared/services/utility.service";
import { CommonService } from "src/app/modules/shared/services/common.service";

// Utilities
import { replaceAllUtility } from "src/app/modules/shared/utilities/replace-all.utilities";

@Component({
  selector: "app-all-promotions",
  templateUrl: "./all-promotions.component.html",
  styleUrls: ["./all-promotions.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class AllPromotionsComponent {
  // View Child
  @ViewChild("pageHeader", { static: false }) pageHeader: ElementRef;
  @ViewChild("popupOver", { static: false }) popupOver: ElementRef;

  // Strings
  languageCode: string = "";

  // Booleans
  isPageHeaderBg: boolean = false;
  isLoggedIn: boolean = false;
  isLoading: boolean = false;

  // Arrays
  promotionList: Promotion[] = [];

  // Subscriptions
  promotionSubscription: Subscription;

  subscriptions: Subscription[] = [];

  constructor(
    private multiLanguageService: MultiLanguageService,
    private translationService: TranslationService,
    private promotionsService: PromotionsService,
    private emitterService: EmitterService,
    private activatedRoute: ActivatedRoute,
    private utilityService: UtilityService,
    private commonService: CommonService,
    private store: Store<AppState>,
    private router: Router
  ) {}

  // -----------------------------------------------------------------
  // Lifecycle Hooks
  ngOnInit(): void {
    window["prerenderReady"] = false;

    this.onUpdateSEOContent();

    this.languageCode = this.multiLanguageService.getLanguageCode();

    this.subscriptions = [
      this.activatedRoute.params.subscribe(() => {
        this.onAllPromotionCards();
      }),
      this.store
        .select(selectLanguageCode)
        .subscribe((languageCode: string) => {
          this.languageCode = languageCode;

          this.onUpdateSEOContent();

          if (this.languageCode) {
            this.onAllPromotionCards(true);
          }
        }),
      /*
        AllPromotionCards call is not required bacause on logout, because
        we navigate user back to home page
      */
      this.store
        .select(selectAuthLoginIsLoggedIn)
        .subscribe((isLoggedIn: boolean) => {
          this.isLoggedIn = isLoggedIn;

          if (this.isLoggedIn) {
            this.onAllPromotionCards(true);
          }
        }),
      this.store
        .select(selectAuthLoginIsLoggedOut)
        .subscribe((isLoggedOut: boolean) => {
          if (isLoggedOut) {
            this.isLoggedIn = false;

            this.onAllPromotionCards(true);
          }
        }),
      this.emitterService.isSuccessfulDepositSubject$.subscribe(() => {
        this.onAllPromotionCards(true);
      }),
    ];
  }

  // -----------------------------------------------------------------
  // Host Listeners
  @HostListener("window:scroll")
  onScroll(): void {
    this.isPageHeaderBg = this.utilityService.scrollAddClass();
  }

  // -----------------------------------------------------------------
  // Set Methods
  onUpdateSEOContent(): void {
    this.utilityService.setSEO({
      metaDescription: this.translationService.get(
        "SEO.promotions_metaDescription"
      ),
      metaTags: this.translationService.get("SEO.promotions_metaTag"),
      metaTitle: this.translationService.get("SEO.promotions_metaTitle"),
      title: this.translationService.get("SEO.promotions_title"),
    });

    this.multiLanguageService.onSetCanonicalURL("promotionspage");
  }

  onAllPromotionCards(isForce?: boolean): void {
    this.isLoading = true;

    this.promotionSubscription = this.promotionsService
      .onGetPromotionList(false)
      .subscribe((promotionList: Promotion[]) => {
        this.isLoading = false;

        if (promotionList && promotionList.length > 0) {
          this.promotionList = promotionList;

          _.each(this.promotionList, (promotion: Promotion) => {
            promotion.snippet = replaceAllUtility(promotion.snippet, {
              "{{site_url}}": environment.siteUrl,
              "{{media_url}}": environment.mediaUrl,
            });
          });
        } else {
          this.promotionList = [];
        }

        if (this.isLoggedIn) {
          this.onNavigateRoute();
        }

        window["prerenderReady"] = true;
      });
  }

  onNavigateRoute(): void {
    const navigationRoute: NavigationRoute =
      this.commonService.getNavigateAfterLogin();

    if (
      this.promotionList &&
      this.promotionList.length > 0 &&
      navigationRoute &&
      navigationRoute.data
    ) {
      let filterdPromotionList: Promotion[] = _.filter(
        this.promotionList,
        (promotion: Promotion) => {
          let promotionUrl: string = "";

          /*
            need to remove this replace function after CMS change
            (Their an issues from CMS),It's a hack
          */
          if (this.translationService.get("url.promotions") !== "promotions") {
            promotionUrl = promotion.url;

            promotionUrl = promotionUrl.replace("promotions", "");
          } else {
            promotionUrl = promotion.url;
          }

          return navigationRoute.data.path.endsWith(promotionUrl);
        }
      );

      if (filterdPromotionList.length > 0) {
        this.router.navigate([navigationRoute.data.path]);
      }
    }
  }

  onGoToPromotionPage(routeURL: string): void {
    this.utilityService.closeAccountComponent("");

    if (routeURL && routeURL.length > 0) {
      /*
        need to remove this replace function after CMS change.
        It's a hack 
      */
      if (this.translationService.get("url.promotions") !== "promotions") {
        routeURL = routeURL.replace("promotions", "");
      }

      this.router.navigate([`${this.languageCode}/${routeURL}`]);
    }
  }

  onBackToHome(): void {
    this.utilityService.closeAccountComponent("");

    this.router.navigate([`${this.languageCode}/casino`]);
  }

  // -----------------------------------------------------------------
  // On Destroy
  ngOnDestroy(): void {
    if (this.commonService.getNavigateAfterLogin()) {
      this.commonService.onSetNavigateAfterLogin(undefined);
    }

    this.utilityService.onRemoveClassFromAppBody("overflow-hidden");

    if (this.promotionSubscription) this.promotionSubscription.unsubscribe();

    this.subscriptions.forEach((subscription: Subscription) =>
      subscription.unsubscribe()
    );
  }
}
