<aside class="sideMenu" [ngClass]="customClass">
  <div class="sideMenu__header">
    <ng-container *ngIf="showBackBtn && isShowBackButton">
      <button
        class="sideMenu__action-btn float-left"
        aria-label="Close"
        (click)="onCloseComponent('menuOptions')"
      >
        <i class="icon-chevron-left"></i>
      </button>
    </ng-container>

    <button
      class="sideMenu__action-btn float-right"
      aria-label="Close"
      (click)="onCloseComponent()"
    >
      <i class="icon-close"></i>
    </button>

    <ng-container *ngIf="!show2LineHeaderTitle">
      <div class="sideMenu__header--title">
        {{ sideMenuTitle | translate }}
      </div>
    </ng-container>

    <ng-container *ngIf="show2LineHeaderTitle">
      <div class="sideMenu__header--title">
        <ng-content
          select="[show2LineHeaderTitle]"
        ></ng-content>
      </div>
    </ng-container>
  </div>

  <div class="sideMenu__body">
    <ng-content select="[sideMenuBody]"></ng-content>
  </div>

  <ng-container *ngIf="showFooter">
    <div class="sideMenu__footer">
      <ng-content select="[showFooter]"></ng-content>
    </div>
  </ng-container>
</aside>
