import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { of } from "rxjs";
import {
  withLatestFrom,
  catchError,
  exhaustMap,
  switchMap,
  filter,
  map,
  tap,
} from "rxjs/operators";

// Actions
import { languageCodeLoaded } from "src/app/modules/multi-languages/store/actions/languages.actions";
import {
  logoutRequestedRefresh,
  payNPlayLoginRequested,
  loginStatusRequested,
  payNPlayLoginLoaded,
  payNPlayLoginError,
  loginStatusLoaded,
  loginStatusError,
  logoutRequested,
  loginRequested,
  logoutLoaded,
  loginLoaded,
  logoutError,
  loginError,
} from "src/app/modules/auth/store/actions/auth.actions";

// Configurations
import { localStorageKeys } from "src/app/modules/multi-languages/configurations/localstorage-keys.configurations";
import { loginConfigurations } from "src/app/configurations/main.configurations";

// Models
import { LoginMarketConfigurations } from "src/app/models/configurations/general-configurations/login-configuration.model";
import { PayNPlayLoginResponse } from "src/app/modules/account/models/pay-n-play-login/pay-n-play-login-response.model";
import { LogoutResponse } from "src/app/modules/auth/models/logout/logout-response.model";
import { LoginStatus } from "src/app/modules/auth/models/login-status.model";
import { ActiveTab } from "src/app/modules/shared/models/active-tab.model";
import { LoggedIn } from "src/app/modules/auth/models/logged-in.model";

// Reducers
import { AppState } from "src/app/store/reducers";

// Selectors
import { selectLanguageCode } from "src/app/modules/multi-languages/store/selectors/languages.selectors";
import { selectAuthLoading } from "src/app/modules/auth/store/selectors/auth.selectors";

// Services
import { PayNPlayCashierService } from "src/app/modules/account/services/pay-n-play-cashier.service";
import { TranslationService } from "src/app/modules/multi-languages/services/translation.service";
import { LoginAPIService } from "src/app/modules/auth/store/services/login-api.service";
import { CommonService } from "src/app/modules/shared/services/common.service";

@Injectable()
export class AuthEffects {
  constructor(
    private payNPlayCashierService: PayNPlayCashierService,
    private translationService: TranslationService,
    private loginAPIService: LoginAPIService,
    private commonService: CommonService,
    private store: Store<AppState>,
    private actions$: Actions,
    private router: Router
  ) {}

  loginRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loginRequested),
      exhaustMap((action) => {
        return this.loginAPIService.onLogin(action.credentials).pipe(
          map((loggedIn: LoggedIn) => {
            if (loggedIn.language) {
              const language: LoginMarketConfigurations =
                loginConfigurations[loggedIn.language];

              this.translationService.onUseLanguage(language.language);

              this.store.dispatch(
                languageCodeLoaded({
                  language: language.language,
                  languageCode: language.languageCode,
                })
              );

              /*this.router.navigate([`${language.languageCode}/casino`], {
                state: { refresh: "refresh" },
              });*/

              localStorage.setItem(
                localStorageKeys.languageCode,
                language.languageCode
              );
            }

            return loginLoaded({ loggedIn });
          }),
          catchError(() => of(loginError()))
        );
      })
    )
  );

  payNPlayLoginRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(payNPlayLoginRequested),
      exhaustMap((action) => {
        return this.payNPlayCashierService
          .onPayNPlayLogin(action.payNPlayLoginRequest)
          .pipe(
            map((payNPlayLoggedIn: PayNPlayLoginResponse) =>
              payNPlayLoginLoaded({ payNPlayLoggedIn })
            ),
            catchError(() => of(payNPlayLoginError()))
          );
      })
    )
  );

  loginStatusRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loginStatusRequested),
      withLatestFrom(this.store.select(selectAuthLoading)),
      filter(([, isLoading]) => !!isLoading),
      exhaustMap(() => {
        return this.loginAPIService.onGetLoginStatus().pipe(
          map((loginStatus: LoginStatus) => loginStatusLoaded({ loginStatus })),
          catchError(() => of(loginStatusError()))
        );
      })
    )
  );

  logoutRequested$ = createEffect(() =>
    this.actions$.pipe(
      ofType(logoutRequested),
      switchMap(() => {
        return this.loginAPIService.onGetLogout().pipe(
          map((logoutResponse: LogoutResponse) =>
            logoutLoaded({ logoutResponse })
          ),
          catchError(() => of(logoutError()))
        );
      })
    )
  );

  logoutRequestedRefreshed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(logoutRequestedRefresh),
      switchMap(() => {
        return this.loginAPIService.onGetLogout().pipe(
          map((logoutResponse: LogoutResponse) => {
            logoutResponse.isSessionExpired = true;
            return logoutLoaded({ logoutResponse });
          }),
          catchError(() => of(logoutError()))
        );
      })
    )
  );

  logoutLoaded$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(logoutLoaded),
        withLatestFrom(this.store.select(selectLanguageCode)),
        filter(([, languageCode]) => !!languageCode),
        tap(([logoutResponse, languageCode]) => {
          if (this.commonService.getActiveAccountMenu()) {
            const activeTab: ActiveTab = {
              tabName: "",
              showBackButton: true,
            };

            this.commonService.onBroadcastActiveAcountView(activeTab);
          }

          localStorage.removeItem(localStorageKeys.stz_user);

          sessionStorage.removeItem("session");

          sessionStorage.removeItem("_player");

          localStorage.removeItem(localStorageKeys.isLoggedIn);

          localStorage.removeItem(localStorageKeys.loggedInTime);

          setTimeout(() => {
            if (logoutResponse.logoutResponse.isSessionExpired) {
              this.router
                .navigate([`${languageCode}/casino`], {
                  queryParams: { session: "expired" },
                })
                .then(() => {
                  window.location.reload();
                });
            } else {
              this.router.navigate([`${languageCode}/casino`]).then(() => {
                window.location.reload();
              });
            }
          });
        })
      ),
    { dispatch: false }
  );
}
