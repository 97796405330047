/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./bottom-navigation.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@ngx-translate/core";
import * as i4 from "../../../shared/directives/market-based-component-display.directive";
import * as i5 from "../../../rewards/components/rewards-indicator/rewards-indicator.component.ngfactory";
import * as i6 from "../../../rewards/components/rewards-indicator/rewards-indicator.component";
import * as i7 from "../../../multi-languages/services/multi-language.service";
import * as i8 from "../../../multi-languages/services/translation.service";
import * as i9 from "../../../rewards/services/reward.service";
import * as i10 from "../../../shared/services/utility.service";
import * as i11 from "../../../shared/services/common.service";
import * as i12 from "@ngrx/store";
import * as i13 from "@angular/router";
import * as i14 from "../../../shared/pipes/currency-format.pipe";
import * as i15 from "../../../user/services/user-details.service";
import * as i16 from "../../../auth/services/session.service";
import * as i17 from "./bottom-navigation.component";
import * as i18 from "../../../registration/services/registration.service";
import * as i19 from "../../../game-groups/services/game-play.service";
var styles_BottomNavigationComponent = [i0.styles];
var RenderType_BottomNavigationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BottomNavigationComponent, data: {} });
export { RenderType_BottomNavigationComponent as RenderType_BottomNavigationComponent };
function View_BottomNavigationComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_BottomNavigationComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BottomNavigationComponent_2)), i1.ɵdid(2, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = i1.ɵnov(_v.parent, 3); _ck(_v, 2, 0, currVal_0); }, null); }
function View_BottomNavigationComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_BottomNavigationComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_BottomNavigationComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_BottomNavigationComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_BottomNavigationComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_BottomNavigationComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BottomNavigationComponent_5)), i1.ɵdid(2, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BottomNavigationComponent_6)), i1.ɵdid(4, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BottomNavigationComponent_7)), i1.ɵdid(6, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BottomNavigationComponent_8)), i1.ɵdid(8, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BottomNavigationComponent_9)), i1.ɵdid(10, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = i1.ɵnov(_v.parent.parent, 4); _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵnov(_v.parent.parent, 5); _ck(_v, 4, 0, currVal_1); var currVal_2 = i1.ɵnov(_v.parent.parent, 6); _ck(_v, 6, 0, currVal_2); var currVal_3 = i1.ɵnov(_v.parent.parent, 7); _ck(_v, 8, 0, currVal_3); var currVal_4 = i1.ɵnov(_v.parent.parent, 8); _ck(_v, 10, 0, currVal_4); }, null); }
function View_BottomNavigationComponent_12(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "span", [["class", "text"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), i1.ɵppd(3, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent.parent.parent.parent, 0), _co.userTotalBalance, _co.currencySymbol)); _ck(_v, 2, 0, currVal_0); }); }
function View_BottomNavigationComponent_13(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "span", [["class", "text skeleton-loader"]], null, null, null, null, null))], null, null); }
function View_BottomNavigationComponent_11(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "button", [["aria-label", "Deposit"], ["class", "btn-deposit fade-in-up full-width"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onOpenDeposit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["class", "icon-circle btn-primary icon-plus m-r-8"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BottomNavigationComponent_12)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BottomNavigationComponent_13)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isBalanceLoading; _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.isBalanceLoading; _ck(_v, 6, 0, currVal_1); }, null); }
function View_BottomNavigationComponent_14(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "button", [["aria-label", "Deposit"], ["class", "btn btn-primary btn-md btn-round fade-in-up full-width isPortrait"], ["id", "openQuickDeposit"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onOpenQuickDeposit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, null, 3, "button", [["aria-label", "Quick Deposit"], ["class", "btn btn-deposit btn-round isLandscape"], ["id", "openQuickDeposit"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onOpenQuickDeposit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "i", [["class", "icon-circle btn-primary icon-plus"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "span", [["class", "img"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "img", [["alt", "visa"], ["src", "/assets/img/cc.png"]], null, null, null, null, null))], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("general.deposit")); _ck(_v, 2, 0, currVal_0); }); }
function View_BottomNavigationComponent_15(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "button", [["aria-label", "Home"], ["class", "btn-sticky fade-in-up icon-btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onMakeMinimizedGame() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["class", "icon-home"]], null, null, null, null, null))], null, null); }
function View_BottomNavigationComponent_16(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_BottomNavigationComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 16, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "bottom-sticky-nav__left p-l-16"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BottomNavigationComponent_11)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BottomNavigationComponent_14)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 6, "div", [["class", "bottom-sticky-nav__right"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(10, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BottomNavigationComponent_15)), i1.ɵdid(12, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(13, 0, null, null, 1, "button", [["aria-label", "burger-menu button"], ["class", "btn-sticky btn-menu btn-burger-menu icon-btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onOpenAccountMenuList() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 0, "i", [["class", "icon-menu"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BottomNavigationComponent_16)), i1.ɵdid(16, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "bottom-sticky-nav__left p-l-16"; var currVal_1 = (_co.isGamePlay ? "atGamePlay" : "atHome"); _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_2 = !_co.isGamePlay; _ck(_v, 5, 0, currVal_2); var currVal_3 = _co.isGamePlay; _ck(_v, 7, 0, currVal_3); var currVal_4 = "bottom-sticky-nav__right"; var currVal_5 = (_co.isGamePlay ? "atGamePlay" : "atHome"); _ck(_v, 10, 0, currVal_4, currVal_5); var currVal_6 = (_co.isGamePlay && _co.isLoggedIn); _ck(_v, 12, 0, currVal_6); var currVal_7 = i1.ɵnov(_v.parent.parent, 9); _ck(_v, 16, 0, currVal_7); }, null); }
function View_BottomNavigationComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "nav", [["class", "bottom-sticky-nav fade-in"], ["id", "bottomNavigation"]], [[2, "atGamePlay", null]], null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "bottom-sticky-nav__inner"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BottomNavigationComponent_4)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BottomNavigationComponent_10)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = "bottom-sticky-nav fade-in"; var currVal_2 = (_co.isLoggedIn ? "isLoggedIn" : "isNotLoggedIn"); _ck(_v, 2, 0, currVal_1, currVal_2); var currVal_3 = !_co.isLoggedIn; _ck(_v, 5, 0, currVal_3); var currVal_4 = _co.isLoggedIn; _ck(_v, 7, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isGamePlay; _ck(_v, 0, 0, currVal_0); }); }
function View_BottomNavigationComponent_18(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "btn-sticky float-left btn-bottom-nav"]], [[2, "devider", null]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "button", [["aria-label", "Home"], ["class", "fade-in-up btn-sticky icon-btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onMakeMinimizedGame() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "i", [["class", "icon-home"]], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.activePage === "game"); _ck(_v, 1, 0, currVal_0); }); }
function View_BottomNavigationComponent_17(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_BottomNavigationComponent_18)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.activePage === "game"); _ck(_v, 1, 0, currVal_0); }, null); }
function View_BottomNavigationComponent_19(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["aria-label", "Login"], ["class", "btn btn-sm btn-login-game btn-round btn-bottom-nav devider"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onOpenLogin() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("general.login")); _ck(_v, 1, 0, currVal_0); }); }
function View_BottomNavigationComponent_22(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["aria-label", "Join now"], ["class", "btn btn-round btn-sm btn-bottom-nav devider"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onOpenRegistrationPopup() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("general.joinnow")); _ck(_v, 1, 0, currVal_0); }); }
function View_BottomNavigationComponent_21(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_BottomNavigationComponent_22)), i1.ɵdid(2, 16384, null, 0, i4.MarketBasedComponentDisplayDirective, [i1.ViewContainerRef, i1.TemplateRef], { marketBasedComponentDisplay: [0, "marketBasedComponentDisplay"] }, null), i1.ɵpod(3, { flowType: 0, langCode: 1 }), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, _co.UserFlowTypes.NORMAL_FLOW, _co.languageCode); _ck(_v, 2, 0, currVal_0); }, null); }
function View_BottomNavigationComponent_20(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_BottomNavigationComponent_21)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isLoggedIn; _ck(_v, 1, 0, currVal_0); }, null); }
function View_BottomNavigationComponent_24(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["aria-label", "Join now"], ["class", "btn btn-round btn-sm btn-bottom-nav devider"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onOpenDeposit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("general.deposit")); _ck(_v, 1, 0, currVal_0); }); }
function View_BottomNavigationComponent_23(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 2, null, View_BottomNavigationComponent_24)), i1.ɵdid(1, 16384, null, 0, i4.MarketBasedComponentDisplayDirective, [i1.ViewContainerRef, i1.TemplateRef], { marketBasedComponentDisplay: [0, "marketBasedComponentDisplay"] }, null), i1.ɵpod(2, { flowType: 0, langCode: 1 }), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 2, 0, _co.UserFlowTypes.ZIMPLER_FLOW, _co.languageCode); _ck(_v, 1, 0, currVal_0); }, null); }
function View_BottomNavigationComponent_25(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["aria-label", "Menu"], ["class", "btn-sticky btn-menu btn-burger-menu btn-bottom-nav devider icon-btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onOpenAccountMenuList() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "icon-menu"]], null, null, null, null, null))], null, null); }
function View_BottomNavigationComponent_27(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "levels"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-rewards-indicator", [], null, [["window", "orientationchange"]], function (_v, en, $event) { var ad = true; if (("window:orientationchange" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onRotate() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_RewardsIndicatorComponent_0, i5.RenderType_RewardsIndicatorComponent)), i1.ɵdid(3, 4964352, null, 0, i6.RewardsIndicatorComponent, [i7.MultiLanguageService, i8.TranslationService, i9.RewardsService, i10.UtilityService, i11.CommonService, i12.Store, i13.Router], null, null)], function (_ck, _v) { _ck(_v, 3, 0); }, null); }
function View_BottomNavigationComponent_26(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_BottomNavigationComponent_27)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isLoggedIn; _ck(_v, 1, 0, currVal_0); }, null); }
function View_BottomNavigationComponent_28(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, null, null, 0))], null, null); }
export function View_BottomNavigationComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(131072, i14.CurrencyFormatPipe, [i7.MultiLanguageService, i15.UserDetailsService, i16.SessionService, i10.UtilityService, i11.CommonService, i2.DecimalPipe, i12.Store]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BottomNavigationComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, [["quickDepositTemplate", 2]], null, 0, null, View_BottomNavigationComponent_3)), (_l()(), i1.ɵand(0, [["minimizeGameTemplate", 2]], null, 0, null, View_BottomNavigationComponent_17)), (_l()(), i1.ɵand(0, [["loginTemplate", 2]], null, 0, null, View_BottomNavigationComponent_19)), (_l()(), i1.ɵand(0, [["openRegistrationTemplate", 2]], null, 0, null, View_BottomNavigationComponent_20)), (_l()(), i1.ɵand(0, [["openDepositTemplate", 2]], null, 0, null, View_BottomNavigationComponent_23)), (_l()(), i1.ɵand(0, [["openMenuListTemplate", 2]], null, 0, null, View_BottomNavigationComponent_25)), (_l()(), i1.ɵand(0, [["rewardsIndicatorTemplate", 2]], null, 0, null, View_BottomNavigationComponent_26)), (_l()(), i1.ɵand(0, [["noDepositTemplate", 2]], null, 0, null, View_BottomNavigationComponent_28))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isQuickDeposit; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_BottomNavigationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-bottom-navigation", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onResize() !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_BottomNavigationComponent_0, RenderType_BottomNavigationComponent)), i1.ɵdid(1, 245760, null, 0, i17.BottomNavigationComponent, [i7.MultiLanguageService, i18.RegistrationService, i15.UserDetailsService, i8.TranslationService, i19.GamePlayService, i10.UtilityService, i16.SessionService, i11.CommonService, i12.Store, i13.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BottomNavigationComponentNgFactory = i1.ɵccf("app-bottom-navigation", i17.BottomNavigationComponent, View_BottomNavigationComponent_Host_0, {}, {}, []);
export { BottomNavigationComponentNgFactory as BottomNavigationComponentNgFactory };
