<!-- Banners from in CMS created -->
<ng-container *ngIf="!isShowBannerFromAffiliate">
  <ng-container
    *ngTemplateOutlet="cmsBannersTemplate"
  ></ng-container>
</ng-container>

<!-- Banners is populated or from affiliate  -->
<ng-container
  *ngTemplateOutlet="commonTemplate"
></ng-container>

<!-- 
  Templates
-->
<!-- 
  ------------------------------------------------------
  
  Common: Templates 
  
  ------------------------------------------------------
-->
<ng-template #commonTemplate>
  <div
    class="stz-banner clearfix"
    *ngIf="(!isLoading && activeBannersList && activeBannersList.length > 0) || isShowBannerFromAffiliate"
  >
    <swiper
      class="mySwiper clearfix"
      [config]="swiperBanner"
      watchSlidesProgress
    >
      <section
        class="stz-banner clearfix"
        *ngIf="isShowBannerFromAffiliate"
      >
        <ng-container
          *ngTemplateOutlet="affiliateBannersTemplate"
        ></ng-container>
      </section>

      <div
        class="stz-banner__item"
        [ngClass]="'dynamicBannerContainer-'+banner.id"
        (click)="onJoinBanners($event)"
        [innerHtml]="banner.content | safeHtml"
        *ngFor="let banner of activeBannersList"
      ></div>
    </swiper>

    <div class="banner-pagination swipper-pagination"></div>

    <app-quick-pick-amount
      class="pnp-cashier-on--banner"
      *marketBasedComponentDisplay="{'flowType': UserFlowTypes.ZIMPLER_FLOW,'langCode':languageCode}"
    ></app-quick-pick-amount>
  </div>
</ng-template>

<!-- 
  ------------------------------------------------------
  
  Affiliate: Templates 
  
  ------------------------------------------------------
-->
<ng-template #affiliateBannersTemplate>
  <div
    class="stz-banner__item"
    *marketBasedComponentDisplay="{'flowType': UserFlowTypes.NORMAL_FLOW,'langCode':languageCode}"
  >
    <div class="{{deviceType}}-banner banner-type-1">
      <div class="banner-body">
        <div class="banner-text">
          <h5 class="text-uppercarse">
            {{ "landingpage.welcome_offer_mobile-text" |
            translate }}
          </h5>

          <h1
            class="text-uppercarse"
            [innerHtml]="affiliateOfferJsonText | safeHtml"
          ></h1>

          <h5 class="text-uppercarse">
            {{'affiliate.' + getAffiliateValue +
            '_free_spins' | translate}}
          </h5>

          <button
            class="btn btn-lg btn-primary btn-round row2-btn btn-pulse-animate"
            (click)="onOpenRegistrationPopup()"
          >
            {{'general.joinnow' | translate}}

            <span (click)="onOpenRegistrationPopup()">
              {{'landingpage.cta_caption_mobile-text' |
              translate}}
            </span>
          </button>
        </div>

        <ng-container
          *ngTemplateOutlet="affiliateDefaultBanner"
        ></ng-container>
      </div>
    </div>
  </div>

  <app-quick-pick-amount
    class="pnp-cashier-on--banner"
    *marketBasedComponentDisplay="{'flowType': UserFlowTypes.ZIMPLER_FLOW,'langCode':languageCode}"
  >
  </app-quick-pick-amount>
</ng-template>

<!-- Affiliate Default Banner -->
<ng-template #affiliateDefaultBanner>
  <div class="banner-media">
    <ng-container
      *ngTemplateOutlet="affiliateTheme"
    ></ng-container>
  </div>
</ng-template>

<!-- Affiliate Theme Default Banner -->
<ng-template #affiliateTheme>
  <ng-container [ngSwitch]="deviceType">
    <ng-container *ngSwitchCase="'mobile'">
      <img
        alt="{{ 'landingpage.welcome_offer_mobile-text' | translate }}"
        src="{{mediaUrl}}/uploads/media/HIR/banner/casino_mobile_bg.png?auto=format,compress"
      />
    </ng-container>
    <ng-container *ngSwitchDefault>
      <img
        alt="{{ 'landingpage.welcome_offer_mobile-text' | translate }}"
        src="{{mediaUrl}}/uploads/media/HIR/banner/casino_welcome_offer_desktop.png?auto=format,compress"
      />
    </ng-container>
  </ng-container>
</ng-template>

<!-- 
  ------------------------------------------------------
  
  CMS: Templates 
  
  ------------------------------------------------------
-->
<!-- CMS Banners -->
<ng-template #cmsBannersTemplate>
  <!-- loader -->
  <ng-container
    *ngTemplateOutlet="skeletonLoaderTemplate"
  ></ng-container>

  <!-- 
    default banners: are loaded either by initial or else if no banners are found 
  -->
  <ng-container
    *ngTemplateOutlet="defaultBannerTemplate"
  ></ng-container>
</ng-template>

<!-- Default Banner if a banner not configured -->
<ng-template #defaultBannerTemplate>
  <!-- default banner -->
  <div
    class="stz-banner clearfixr"
    *ngIf="(!isLoading && activeBannersList && activeBannersList.length === 0)"
  >
    <div class="stz-banner__item">
      <div class="mobile-banner">
        <div class="banner-body">
          <div class="banner-media">
            <img
              alt="Welcome offer"
              title="Welcome offer"
              src="./assets/img/banner/classic-banner-mobile.jpeg"
              *marketBasedComponentDisplay="{'flowType': UserFlowTypes.NORMAL_FLOW,'langCode':languageCode}"
            />

            <img
              alt="Welcome offer"
              src="./assets/img/banner/pnp-banner-mobile.jpeg"
              title="Welcome offer"
              *marketBasedComponentDisplay="{'flowType': UserFlowTypes.ZIMPLER_FLOW,'langCode':languageCode}"
            />
          </div>
        </div>
      </div>

      <div class="desktop-banner">
        <div class="banner-body">
          <div class="banner-media">
            <img
              alt="Welcome offer"
              title="Welcome offer"
              src="./assets/img/banner/classic-banner-desktop.jpeg"
              *marketBasedComponentDisplay="{'flowType': UserFlowTypes.NORMAL_FLOW,'langCode':languageCode}"
            />

            <img
              alt="Welcome offer"
              src="./assets/img/banner/pnp-banner-desktop.jpeg"
              title="Welcome offer"
              *marketBasedComponentDisplay="{'flowType': UserFlowTypes.ZIMPLER_FLOW,'langCode':languageCode}"
            />
          </div>
        </div>
      </div>
    </div>

    <app-quick-pick-amount
      class="pnp-cashier-on--banner"
      *marketBasedComponentDisplay="{'flowType': UserFlowTypes.ZIMPLER_FLOW,'langCode':languageCode}"
    >
    </app-quick-pick-amount>
  </div>
</ng-template>

<!-- Skeleton Loader -->
<ng-template #skeletonLoaderTemplate>
  <!-- Banner loader -->
  <div class="stz-banner clearfix" *ngIf="isLoading">
    <div class="stz-banner__item">
      <div class="mobile-banner">
        <div class="banner-media skeleton-loader"></div>
      </div>

      <div class="desktop-banner">
        <div class="banner-body">
          <div class="banner-media skeleton-loader"></div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
