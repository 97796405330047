// API Interactors
import { ApiInteractors } from "src/app/models/interactors/api.interactor";
// Configurations
import { userProfileRegisteredLanguageConfigurations, marketLocaleCurrencyMappingConfigurations, localToRegilyLanguageConfigurations, languageToRegisterConfigurations, } from "src/app/configurations/main.configurations";
// Enums
import { StatusResponse } from "src/app/models/api/status.response";
import { Project } from "src/app/models/environments/project.enum";
// Environments
import { environment } from "src/environments/environment";
import { RegilyRegistrationRequest } from "src/app/modules/registration/models/regily-registration-request.model";
import { TranslatedError } from "src/app/modules/registration/models/translated-error.model";
// Selectors
import { selectLanguageCode } from "src/app/modules/multi-languages/store/selectors/languages.selectors";
// Utilities
import { onCheckinOtp } from "src/app/modules/registration/utilities/checkin-otp.utilities";
import { getRegilyVariantByLocale, getRegilyWelcomeOffer, } from "src/app/modules/registration/utilities/regily-welcome-offer.utilities";
import * as i0 from "@angular/core";
import * as i1 from "../../multi-languages/services/multi-language.service";
import * as i2 from "../../multi-languages/services/translation.service";
import * as i3 from "../../auth/services/affiliate.service";
import * as i4 from "../../auth/store/services/login-api.service";
import * as i5 from "../../shared/services/utility.service";
import * as i6 from "../../shared/services/emitter.service";
import * as i7 from "../../user/services/profile.service";
import * as i8 from "../../shared/services/common.service";
import * as i9 from "../../shared/services/gtm.service";
import * as i10 from "@angular/common/http";
import * as i11 from "@ngrx/store";
import * as i12 from "@angular/router";
export class RegistrationService {
    constructor(multiLanguageService, translationService, affiliateService, loginAPIService, utilityService, emitterService, profileService, commonService, gtmService, httpClient, store, router) {
        this.multiLanguageService = multiLanguageService;
        this.translationService = translationService;
        this.affiliateService = affiliateService;
        this.loginAPIService = loginAPIService;
        this.utilityService = utilityService;
        this.emitterService = emitterService;
        this.profileService = profileService;
        this.commonService = commonService;
        this.gtmService = gtmService;
        this.httpClient = httpClient;
        this.store = store;
        this.router = router;
        // Strings
        this.languageCode = "";
        // Booleans
        this.isPhoneUniqueness = false;
        this.apiInteractor = new ApiInteractors(this.httpClient);
        this.onLoad();
    }
    // -----------------------------------------------------------------
    // Get Methods
    /**
      @param signupData User final Data from the regily callback
      we have this data in our backend & return promise on success
      & reject on failure to regily in order to show proper message.
    */
    onRegisterDataToBackend(signupData) {
        if (environment.features.showConsoles) {
            console.log("registerDataToBackend dddddddddddddddddddddddddd", signupData);
        }
        if (signupData) {
            delete signupData.user.birthdate;
            let userData = Object.assign({}, signupData.user, signupData.consent, signupData.mapped);
            if (signupData.sanction && signupData.sanction.category) {
                userData.pepAndSanctionDetails = {
                    category: signupData.sanction.category,
                    flag: signupData.sanction.flag,
                    program: signupData.sanction.program,
                    source: signupData.sanction.source,
                };
            }
            userData.bannerId = this.affiliateService.getAffiliateCookies("bannerId");
            userData.affiliateId = this.affiliateService.getAffiliateCookies("affId");
            userData.trackerId =
                this.affiliateService.getAffiliateCookies("trackerId");
            userData.referenceUrl =
                this.affiliateService.getAffiliateCookies("referrer");
            if (this.languageCode == "hu-hu") {
                // if environment.features.regilyCurrency.huf -> use regily response else override
                userData.currency = environment.features.regilyCurrency.huf
                    ? userData.currency
                    : marketLocaleCurrencyMappingConfigurations[this.languageCode]
                        .currencyCode;
                userData.userLang = environment.features.regilyCurrency.huf
                    ? userData.userLang
                    : userProfileRegisteredLanguageConfigurations[this.languageCode];
            }
            /*
              We mapped user language based on country he selected while registration
              instead of his locale or the language with which he landed
            */
            userData.userLang = languageToRegisterConfigurations[userData.country]
                ? languageToRegisterConfigurations[userData.country]
                : languageToRegisterConfigurations.others;
            const requestData = new RegilyRegistrationRequest(userData);
            return new Promise((resolve, reject) => {
                this.onRegistration(requestData)
                    .toPromise()
                    .then((registrationResponse) => {
                    if (registrationResponse && registrationResponse.success) {
                        /*
                            After Succesfull registration..we call login here
                            with user credentails provide on registration flow.
                            the after we resolve it as successfully(after successfull r)
                        */
                        this.gtmService.onRegistrationGTMEvent("register", {
                            eventType: "register",
                            event_context: "finish",
                            userid: registrationResponse.externalEcrId,
                        });
                        this.affiliateService.onDeleteAffiliateCookies();
                        const loginCredentials = {
                            emailId: requestData.emailId,
                            password: requestData.password,
                        };
                        this.loginAPIService
                            .onLogin(loginCredentials)
                            .subscribe((loginResponse) => {
                            if (loginResponse && loginResponse.success) {
                                resolve(null);
                                this.onProcessAfterLogin();
                            }
                            else {
                                resolve(null);
                                this.router.navigate([`${this.languageCode}/casino`]);
                            }
                        });
                    }
                    else {
                        const errorMessage = this.translationService.get("registration.register_something_wrong");
                        let error = new TranslatedError(errorMessage);
                        error.name = "something-wrong";
                        error.title = this.translationService.get("registration.register_failed");
                        reject(error);
                    }
                })
                    .catch(() => {
                    const errorMessage = this.translationService.get("registration.register_something_wrong");
                    let error = new TranslatedError(errorMessage);
                    error.name = "something-wrong";
                    error.title = this.translationService.get("registration.register_failed");
                    reject(error);
                });
            });
        }
    }
    onUniqueEmailValidation(emailRequest) {
        return new Promise((resolve, reject) => {
            this.apiInteractor
                .post(`/ajax/registration/isUniqueEmail`, emailRequest, Project.Shotz)
                .toPromise()
                .then((uniqueEmailValidationResponse) => {
                if (uniqueEmailValidationResponse &&
                    uniqueEmailValidationResponse.response === 1) {
                    resolve(null);
                }
                else {
                    const errorMessage = this.translationService.get("registration.email_already_message");
                    let error = new TranslatedError(errorMessage);
                    error.name = "custom-duplicateAccount";
                    error.title = this.translationService.get("registration.email_already_title");
                    error.action = "close";
                    reject(error);
                }
            })
                .catch(() => {
                const errorMessage = this.translationService.get("registration.email_already_error");
                let error = new TranslatedError(errorMessage);
                error.name = "custom-duplicateAccount";
                error.title = this.translationService.get("registration.email_already_error_title");
                reject(error);
            });
        });
    }
    onUniqueNameValidation(nicknameRequest) {
        return new Promise((resolve, reject) => {
            this.apiInteractor
                .post(`/ajax/registration/isUniqueNickname`, nicknameRequest, Project.Shotz)
                .toPromise()
                .then((uniqueNicknameValidationResponse) => {
                if (uniqueNicknameValidationResponse &&
                    uniqueNicknameValidationResponse.response === 1) {
                    resolve(null);
                }
                else {
                    const errorMessage = this.translationService.get("registration.username_already_message");
                    let error = new TranslatedError(errorMessage);
                    error.name = "custom-duplicateUserName";
                    error.title = this.translationService.get("registration.username_already_title");
                    error.action = "close";
                    reject(error);
                }
            })
                .catch(() => {
                const errorMessage = this.translationService.get("registration.username_already_error_message");
                let error = new TranslatedError(errorMessage);
                error.name = "custom-duplicateUserName";
                error.title = this.translationService.get("registration.username_already_title");
                reject(error);
            });
        });
    }
    onUniquePhoneValidation(regilyDoneCallbackData) {
        let phoneRequest = {
            mobileNumber: regilyDoneCallbackData.user.phone,
            mobileIsdCode: regilyDoneCallbackData.user.phonePrefix.replace("+", ""),
        };
        return new Promise((resolve, reject) => {
            this.apiInteractor
                .post(`/ajax/registration/checkUserPhoneAvailablity`, phoneRequest, Project.Shotz)
                .toPromise()
                .then((phoneAvailableResponse) => {
                if (phoneAvailableResponse &&
                    phoneAvailableResponse.status === StatusResponse.SUCCESS) {
                    this.onRegisterDataToBackend(regilyDoneCallbackData)
                        .then(() => {
                        resolve(null);
                    })
                        .catch(() => {
                        const errorMessage = this.translationService.get("registration.register_something_wrong");
                        let error = new TranslatedError(errorMessage);
                        error.name = "something-wrong";
                        error.title = this.translationService.get("registration.register_failed");
                        reject(error);
                    });
                }
                else {
                    const errorMessage = this.translationService.get("registration.phoneNumber_already_exist");
                    let error = new TranslatedError(errorMessage);
                    error.name = "custom-duplicatePhoneNumber";
                    error.title = this.translationService.get("registration.phoneNumber_already_title");
                    this.isPhoneUniqueness = false;
                    reject(error);
                }
            })
                .catch(() => {
                const errorMessage = this.translationService.get("registration.phoneNumber_already_exist");
                let error = new TranslatedError(errorMessage);
                error.name = "custom-duplicatePhoneNumber";
                error.title = this.translationService.get("registration.phoneNumber_already_title");
                this.isPhoneUniqueness = false;
                reject(error);
            });
        });
    }
    onRegistration(regilyRegistrationRequest) {
        return this.apiInteractor.post(`/ajax/registration`, regilyRegistrationRequest, Project.Shotz);
    }
    // -----------------------------------------------------------------
    // Set Methods
    onLoad() {
        this.languageCode = this.multiLanguageService.getLanguageCode();
        this.languageCodeSubscription = this.store
            .select(selectLanguageCode)
            .subscribe((languageCode) => {
            this.languageCode = languageCode;
        });
    }
    /*
      Opens third party Regily pop-up with
      Complete Registration flow in it..
    */
    onOpenRegistration() {
        this.gtmService.onRegistrationGTMEvent("register", {
            event_type: "register",
            event_context: "start",
        });
        this.onInitiatedRegistrationCallBacks();
        if (window.regily) {
            window.regily.signUp.open();
        }
        else {
            console.log(`Something went wrong with Registration flow.. Please try again after sometime`);
        }
    }
    /*
      Funcationality to set up a listener to regily regesteration
      flow using regilyDoneCallback(Triggers after final step) &
      regilyUpdateCallback(triggers after every step).
    */
    onSetRegilyDataSets() {
        let regilyWelcomeOffer = getRegilyWelcomeOffer(this.affiliateService, this.translationService, this.languageCode);
        document.documentElement.dataset.regilyCustomData =
            JSON.stringify(regilyWelcomeOffer);
        document.documentElement.dataset.regilyLanguage =
            localToRegilyLanguageConfigurations[this.languageCode];
        /*
          We have two variant on regily variant1 & varient2
          1. If use load website with /en-eu then varient2 need to pass for regilyVariant to load varient2
          2. For rest of all other languages(like /en-ca /en-nz /en-ro /fi-fi and /nb-no and /ja-jp ect..) varient1 need
             to pass for regilyVariant for varient1
          
             If nothing is passed the varient1 will be loaded as default(varient1 is the default on regily side).
        */
        document.documentElement.dataset.regilyVariant = getRegilyVariantByLocale(this.languageCode);
        this.languageCode === "en-eu" ? "v2" : "core";
        onCheckinOtp(this.languageCode);
    }
    onInitiatedRegistrationCallBacks() {
        this.onSetRegilyDataSets();
        Object.defineProperty(window, "regilyDoneCallback", {
            value: (regilyDoneCallbackData) => {
                if (environment.features.showConsoles) {
                    console.log("reggggggggggggggggggggggggggggggggg regilyDoneCallback", regilyDoneCallbackData);
                }
                if (environment.production) {
                    if (regilyDoneCallbackData &&
                        regilyDoneCallbackData.user &&
                        regilyDoneCallbackData.user.phone &&
                        regilyDoneCallbackData.user.phonePrefix) {
                        return new Promise((resolve, reject) => {
                            this.onUniquePhoneValidation(regilyDoneCallbackData)
                                .then(() => {
                                resolve(null);
                            })
                                .catch(() => {
                                const errorMessage = this.translationService.get("registration.phoneNumber_already_exist");
                                let error = new TranslatedError(errorMessage);
                                error.name = "custom-duplicatePhoneNumber";
                                error.title = this.translationService.get("registration.phoneNumber_already_title");
                                this.isPhoneUniqueness = false;
                                reject(error);
                            });
                        });
                    }
                    else {
                        const errorMessage = this.translationService.get("registration.register_something_wrong");
                        let error = new TranslatedError(errorMessage);
                        error.name = "something-wrong";
                        error.title = this.translationService.get("registration.register_failed");
                        return Promise.reject(error);
                    }
                }
                else {
                    return this.onRegisterDataToBackend(regilyDoneCallbackData);
                }
            },
        });
        Object.defineProperty(window, "regilyUpdateCallback", {
            value: (data, regilyDifference) => {
                if (environment.features.showConsoles) {
                    console.log("reggggggggggggggggggggggggggggggggg regilyUpdateCallback data", data);
                    console.log("reggggggggggggggggggggggggggggggggg regilyUpdateCallback data-diff", regilyDifference);
                }
                for (let property in regilyDifference) {
                    for (let key in regilyDifference[property]) {
                        if (key === "doneChapter" && regilyDifference[property][key]) {
                            this.gtmService.onSendRegilyAnalyticsEvent("event", "chapterDone", regilyDifference[property][key]);
                        }
                        else if (regilyDifference[property][key] &&
                            regilyDifference[property][key] !== "") {
                            this.gtmService.onSendRegilyAnalyticsEvent("event", "collectedData", key);
                        }
                    }
                }
                if (regilyDifference.credentials &&
                    regilyDifference.credentials
                        .email) {
                    /*
                      The email has been updated and validation will be triggered
                    */
                    return this.onUniqueEmailValidation({
                        txtEmail: regilyDifference
                            .credentials.email,
                    });
                }
                else if (regilyDifference
                    .credentials &&
                    regilyDifference.credentials
                        .username) {
                    return this.onUniqueNameValidation({
                        txtNickname: regilyDifference.credentials.username,
                    });
                }
                else {
                    /*
                      we need to resolve promise here..in order to handle other feilds
                      if thet are not emials..
                    */
                    return Promise.resolve();
                }
            },
        });
        Object.defineProperty(window, "regilyEventCallback", {
            value: (data) => {
                if (data &&
                    data.action === "close-module" &&
                    !data.flowStatus.complete) {
                    this.gtmService.onRegistrationGTMEvent("register", {
                        eventType: "register",
                        event_context: "closed",
                    });
                    this.commonService.onBroadcastIsGameWindowRegilyPopUpTimerUpdate();
                }
            },
        });
    }
    onProcessAfterLogin() {
        const activeTab = {
            tabName: "deposit",
            showBackButton: true,
        };
        this.utilityService.openAccountComponent(activeTab);
        if (this.profileService.getUserJurisdiction() === "estonia") {
            this.emitterService.onBroadcastLimitsRegulatoryPopup(true);
        }
        if (window.location.pathname.split("/")[2] === "live-casino") {
            this.router.navigate([`${this.languageCode}/live-casino`], {
                queryParams: { ignoreRefresh: "ignoreRefresh" },
            });
        }
        else {
            this.router.navigate([`${this.languageCode}/casino`], {
                queryParams: { ignoreRefresh: "ignoreRefresh" },
            });
        }
        /*
          Below code is to show a promotional video only for japanese user
          after their first login & it's based on language code
          not on ip address
        */
        if (this.languageCode === "ja-jp" &&
            /^\d+$/.test(this.translationService.get("configdata.afterlogin-videoId"))) {
            this.onOpenPromotionalVideo();
        }
    }
    onOpenPromotionalVideo() {
        this.emitterService.onBroadcastIsPromotionalVideoEnabled(true);
    }
    // -----------------------------------------------------------------
    // On Destroy
    ngOnDestroy() {
        if (this.languageCodeSubscription)
            this.languageCodeSubscription.unsubscribe();
    }
}
RegistrationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RegistrationService_Factory() { return new RegistrationService(i0.ɵɵinject(i1.MultiLanguageService), i0.ɵɵinject(i2.TranslationService), i0.ɵɵinject(i3.AffiliateService), i0.ɵɵinject(i4.LoginAPIService), i0.ɵɵinject(i5.UtilityService), i0.ɵɵinject(i6.EmitterService), i0.ɵɵinject(i7.ProfileService), i0.ɵɵinject(i8.CommonService), i0.ɵɵinject(i9.GtmService), i0.ɵɵinject(i10.HttpClient), i0.ɵɵinject(i11.Store), i0.ɵɵinject(i12.Router)); }, token: RegistrationService, providedIn: "root" });
