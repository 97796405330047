// Actions
import { userRequested } from "src/app/modules/user/store/actions/user.actions";
// Configurations
import { liveChatGroupsConfiguration } from "src/app/configurations/main.configurations";
// Environments
import { environment } from "src/environments/environment";
// Selectors
import { selectAuthUserDataLoaded } from "src/app/modules/user/store/selectors/user.selectors";
import { selectAuthLoginIsLoggedOut, selectAuthLoginIsLoggedIn, } from "src/app/modules/auth/store/selectors/auth.selectors";
// Utilities
import { vipTagsList } from "src/app/modules/chat/utilities/vip-tags.utilities";
import { appendInitializeLiveChatNoScript, appendInitializeLiveChatScript, } from "src/app/modules/chat/utilities/live-chat-script.utilities";
import * as i0 from "@angular/core";
import * as i1 from "../../multi-languages/services/multi-language.service";
import * as i2 from "../../user/services/user-details.service";
import * as i3 from "../../rewards/services/reward.service";
import * as i4 from "@angular/common";
import * as i5 from "@ngrx/store";
export class LiveChatService {
    constructor(multiLanguageService, userDetailsService, rewardsService, titleCasePipe, store) {
        this.multiLanguageService = multiLanguageService;
        this.userDetailsService = userDetailsService;
        this.rewardsService = rewardsService;
        this.titleCasePipe = titleCasePipe;
        this.store = store;
        // Numbers
        //Live Chat VIP group id
        this.rowVipGroupId = 34;
        this.rowGroupId = 27;
        // Strings
        this.languageCode = "";
        // Booleans
        this.isLoggedIn = false;
        this.subscriptions = [];
        this.languageCode = this.multiLanguageService.getLanguageCode();
        this.subscriptions = [
            this.store
                .select(selectAuthLoginIsLoggedIn)
                .subscribe((isLoggedIn) => {
                this.isLoggedIn = isLoggedIn;
                if (this.isLoggedIn) {
                    this.onLoadLiveChat();
                }
            }),
            this.store
                .select(selectAuthLoginIsLoggedOut)
                .subscribe((isLoggedOut) => {
                if (isLoggedOut) {
                    this.isLoggedIn = false;
                    this.onLoadLiveChat();
                }
            }),
        ];
    }
    // -----------------------------------------------------------------
    // Get Methods
    getTitleCase(value) {
        return this.titleCasePipe.transform(value);
    }
    // -----------------------------------------------------------------
    // Set Methods
    onLoadLiveChat() {
        if (!document.getElementById("lc")) {
            appendInitializeLiveChatNoScript();
            if (environment.features.liveChatVip && this.isLoggedIn) {
                // calling the onGetUserTags to get the user tags to check if the user is a VIP or not.
                this.userTagsSubscription = this.userDetailsService
                    .onGetUserTagsList()
                    .subscribe((tags) => {
                    // if the user has a tag which is part of the VIP list then pass the group id.
                    if (tags &&
                        tags.tag &&
                        tags.tag.some((r) => {
                            let tag = vipTagsList.find((x) => x.toLowerCase() == r.toLowerCase());
                            if (tag)
                                return true;
                        })) {
                        this.onAssignGroupIdBasedOnLanguage(true);
                    }
                    else {
                        this.onAssignGroupIdBasedOnLanguage(false);
                    }
                }, () => {
                    appendInitializeLiveChatScript(this.rowGroupId);
                });
            }
            else {
                this.onAssignGroupIdBasedOnLanguage(false);
            }
        }
    }
    onAssignGroupIdBasedOnLanguage(isVip) {
        if (this.languageCode && !isVip) {
            let language = liveChatGroupsConfiguration.find((liveChatGroupsConfiguration) => liveChatGroupsConfiguration.country == this.languageCode);
            language
                ? appendInitializeLiveChatScript(language.groupId)
                : appendInitializeLiveChatScript(this.rowGroupId);
        }
        else if (this.languageCode && isVip) {
            let language = liveChatGroupsConfiguration.find((liveChatGroupsConfiguration) => liveChatGroupsConfiguration.country == `${this.languageCode}-vip`);
            language
                ? appendInitializeLiveChatScript(language.groupId)
                : appendInitializeLiveChatScript(this.rowVipGroupId);
        }
        else {
            appendInitializeLiveChatScript(this.rowGroupId);
        }
    }
    onInitializeChat() {
        if (!document.getElementById("lc"))
            this.onLoadLiveChat();
        if (window.LiveChatWidget && document.getElementById("lc")) {
            if (this.isLoggedIn)
                this.onGetUserProfileData();
            window.LiveChatWidget.call("maximize");
        }
    }
    onGetUserProfileData() {
        this.userDataSubscription = this.store
            .select(selectAuthUserDataLoaded)
            .subscribe(({ userData, isLoaded }) => {
            if (isLoaded) {
                this.profileDetails = userData;
                this.onSetUserDetails(this.profileDetails);
            }
            else {
                if (this.isLoggedIn) {
                    this.store.dispatch(userRequested());
                }
            }
        });
    }
    onSetUserDetails(profileData) {
        /*
          Please lines of code is used to set customer name & customer email Id
          which will be auto populated & visisble directly when customer
          opens chat
        */
        if (profileData.email && profileData.firstName) {
            window.LiveChatWidget.call("set_customer_email", profileData.email);
            window.LiveChatWidget.call("set_customer_name", `${this.getTitleCase(profileData.firstName)} ${this.getTitleCase(profileData.lastName)}`);
        }
        if (profileData) {
            this.onSetSessionData(profileData);
        }
    }
    /*
      This set of code is used to create a user session on live chat,
      which help used in identifying  user session along
      with back on live chat back office
    */
    onSetSessionData(profileData) {
        window.LiveChatWidget.call("set_session_variables", {
            name: `${this.getTitleCase(profileData.firstName)} ${this.getTitleCase(profileData.lastName)}`,
            emailID: profileData.email,
            ecrID: profileData.playerID,
            currentLevel: this.rewardsService.getUserCurrentLevelId(),
            loginStatus: "logged In",
        });
    }
    onUpdateSessionData() {
        if (window.LiveChatWidget && document.getElementById("lc")) {
            window.LiveChatWidget.call("update_session_variables", {
                loginStatus: "logged out",
            });
        }
    }
    // -----------------------------------------------------------------
    // On Destroy
    ngOnDestroy() {
        if (this.userDataSubscription)
            this.userDataSubscription.unsubscribe();
        if (this.userTagsSubscription)
            this.userTagsSubscription.unsubscribe();
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
}
LiveChatService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LiveChatService_Factory() { return new LiveChatService(i0.ɵɵinject(i1.MultiLanguageService), i0.ɵɵinject(i2.UserDetailsService), i0.ɵɵinject(i3.RewardsService), i0.ɵɵinject(i4.TitleCasePipe), i0.ɵɵinject(i5.Store)); }, token: LiveChatService, providedIn: "root" });
