<ng-container
  *ngIf="countryBlockResponse && !countryBlockResponse.isIpBlocked && !isCountryBlockLoading; else countryBlockTemplate"
>
  <div
    id="casinoApp"
    class="main_container"
    [ngClass]="isMaintenanceOrCountryBlockEnabled ? 'maintenance__enabled' : 'application-body'"
  >
    <ng-container
      *ngIf="
        !isMaintenanceOrCountryBlockEnabled && ['estonia'].indexOf(userJurisdiction) > -1 && 
        windowType === 'mobile' && isLoggedIn
      "
    >
      <app-session-timer></app-session-timer>
    </ng-container>

    <ng-container *ngIf="windowType === 'mobile' && isLoggedIn">
      <app-promo-race-counter></app-promo-race-counter>
    </ng-container>

    <!-- <ng-container *ngIf="playerId">
      <brand-booster [playerId]="playerId"></brand-booster>
    </ng-container> -->

    <!-- Below is the reference to marketBasedComponentDisplay directive -->
    <ng-container
      *ngIf="
        !(windowType === 'mobile' && activePage ==='game') && 
        !isMaintenanceOrCountryBlockEnabled
      "
    >
      <app-header> </app-header>
    </ng-container>

    <ng-container
      *ngIf="windowType !== 'mobile' && !isMaintenanceOrCountryBlockEnabled"
    >
      <app-left-navigation></app-left-navigation>
    </ng-container>

    <ng-container *ngIf="!gameLaunched || isGameWindowMinimized">
      <div
        class="clearfix"
        [ngClass]="activePage !== 'livespins' && 'app-body'"
      >
        <router-outlet></router-outlet>
      </div>
    </ng-container>

    <ng-container *ngIf="gameLaunched && !['gamefull'].includes(activePage)">
      <app-game-play-window></app-game-play-window>
    </ng-container>

    <ng-container *ngIf="gameLaunched && ['gamefull'].includes(activePage)">
      <app-game-play-full-window></app-game-play-full-window>
    </ng-container>

    <ng-container *ngIf="countryBlockResponse">
      <ng-container
        *ngIf="
          (!['game', 'livespins', 'gamefull'].includes(activePage) || (activePage === 'livespins' ? false : isGameWindowMinimized)) &&
          !isMaintenanceOrCountryBlockEnabled &&
          languageCode
        "
      >
        <app-footer class="app-footer"></app-footer>
      </ng-container>
    </ng-container>

    <ng-container
      *ngIf="windowType === 'mobile' && !isMaintenanceOrCountryBlockEnabled"
    >
      <app-bottom-navigation
        class="show-tablet-and-below"
      ></app-bottom-navigation>
    </ng-container>

    <app-account-menu></app-account-menu>

    <!--use this below div tag to load cashier anywhere in the project-->
    <ng-container
      *marketBasedComponentDisplay="{'flowType': UserFlowTypes.ZIMPLER_FLOW,'langCode':languageCode}"
      playnPlayCashierDir
    ></ng-container>

    <ng-container
      *ngIf="activePage ==='game' && isLoggedIn && ((windowType === 'mobile' && isQuickDepositOpen) || windowType === 'device')"
    >
      <div class="qd-wrapper">
        <ng-container *ngIf="windowType === 'mobile'">
          <div
            class="qd-backdrop fade-in"
            (click)="onCloseQuickDepositBackdrop()"
          >
            <button
              aria-label="Close Backdrop"
              class="btn-close-qd valign-center fade-in-up"
              (click)="onCloseQuickDepositBackdrop()"
            >
              <i class="icon-chevron-down"></i>
            </button>
          </div>
        </ng-container>

        <app-quick-deposit
          *marketBasedComponentDisplay="{'flowType': UserFlowTypes.NORMAL_FLOW, 'langCode': languageCode}"
        ></app-quick-deposit>
      </div>
    </ng-container>

    <ng-container *ngIf="isEnableTermsAndConditions">
      <app-terms-and-conditions></app-terms-and-conditions>
    </ng-container>

    <ng-container *ngIf="!isLoggedIn && isSessionExpiryPopup">
      <section
        class="c-dialog__backdrop"
        (click)="onSetSessionExpiryBackdrop()"
      >
        <div class="c-dialog">
          <div class="c-dialog__header">
            <img
              src="/assets/img/logo.svg"
              alt="{{'general.site_name' | translate}}"
              width="100%"
              height="100%"
            />
          </div>
          <div class="c-dialog__body">
            <p>{{'limits.sessionExpiry' | translate }}</p>
          </div>
          <div class="c-dialog__footer">
            <a
              aria-label="Login"
              class="btn-yes"
              (click)="onSetSessionExpiryLogin()"
            >
              {{'general.login' | translate}}
            </a>
          </div>
        </div>
      </section>
    </ng-container>

    <ng-container *ngIf="!isLoggedIn && !isMaintenanceOrCountryBlockEnabled">
      <app-cookie-message></app-cookie-message>
    </ng-container>
  </div>

  <app-limits-regulatory></app-limits-regulatory>

  <ng-container *ngIf="isLoggedIn && isOpenRewardPopup">
    <section class="modal-popup" id="rewardTconditions">
      <div
        class="modal-popup__backdrop"
        (click)="onSetRewardTermsAndConditionsBackdrop()"
      ></div>
      <div class="modal-popup__wrapper pointer-events-allow">
        <div class="modal-popup__container">
          <button
            aria-label="Close"
            class="btn modal-popup--close-btn"
            (click)="onSetRewardTermsAndConditionsBackdrop()"
          >
            <i class="icon-close"></i>
          </button>

          <div id="rewardTCContent" class="modal-popup__body">
            <app-reward-bonus-terms-conditions
              [urlpath]="'url.reward-terms-conditions'| translate"
            >
            </app-reward-bonus-terms-conditions>
          </div>
        </div>
      </div>
    </section>
  </ng-container>

  <ng-container *ngIf="isCashbackDepositOfferPopup">
    <section class="c-dialog__backdrop">
      <div class="c-dialog">
        <div class="c-dialog__header">
          <img
            src="/assets/img/logo.svg"
            alt="{{'general.site_name' | translate}}"
            width="100%"
            height="100%"
          />
        </div>
        <div class="c-dialog__body">
          <p>
            {{'rewards.cashback_msg' | translate:({'cashBackReward':
            cashBackReward})}}
          </p>
        </div>
        <div class="c-dialog__footer">
          <a
            aria-label="Close"
            class="btn-yes"
            (click)="onCloseConfirmDialogue()"
          >
            {{'rewards.close' | translate}}
          </a>
        </div>
      </div>
    </section>
  </ng-container>

  <ng-container *ngIf="isCashbackStartedPopup">
    <section class="c-dialog__backdrop">
      <div class="c-dialog">
        <div class="c-dialog__header">
          <img
            src="/assets/img/logo.svg"
            alt="{{'general.site_name' | translate}}"
            width="100%"
            height="100%"
          />
        </div>
        <div class="c-dialog__body">
          <p>
            {{ 'rewards.optinCashback_msg'| translate: ({ 'cashBackValue':
            selectedCashbackUserSlab.cashBackPercentage, 'cbPromoStartDate':
            promoStartDate, 'cbPromoEndDate': promoEndDate, 'cbStartTime':
            startTime, 'cbEndTime': endTime })}}
          </p>
        </div>
        <div class="c-dialog__footer">
          <a aria-label="Close" class="btn-yes" (click)="onClosePopup()">
            {{'rewards.close' | translate}}
          </a>
        </div>
      </div>
    </section>
  </ng-container>
</ng-container>

<ng-template #countryBlockTemplate>
  <router-outlet></router-outlet>
</ng-template>
