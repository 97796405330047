import { createFeatureSelector, createSelector } from "@ngrx/store";
// Reducers
import { ProfileStateFeatureKey, } from "src/app/modules/user/store/reducers/profile.reducer";
// Select the Main State
const selectProfileReducerState = createFeatureSelector(ProfileStateFeatureKey);
const ɵ0 = (state) => {
    return {
        profileBalance: state.profileBalance,
        isLoaded: state.isLoaded,
    };
};
// Profile Balance
export const selectAuthProfileBalanceLoaded = createSelector(selectProfileReducerState, ɵ0);
const ɵ1 = (state) => {
    return state.profileBalance;
};
export const selectAuthProfileBalance = createSelector(selectProfileReducerState, ɵ1);
const ɵ2 = (state) => {
    return {
        userProfileBalance: state.userProfileBalance,
        isLoaded: state.userProfileBalanceIsLoaded,
    };
};
// User Profile Balance
export const selectUserProfileBalanceLoaded = createSelector(selectProfileReducerState, ɵ2);
const ɵ3 = (state) => {
    return state.userProfileBalance;
};
export const selectUserProfileBalance = createSelector(selectProfileReducerState, ɵ3);
export { ɵ0, ɵ1, ɵ2, ɵ3 };
