import { of, Subject, throwError } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";
// API Interactors
import { ApiInteractors } from "src/app/models/interactors/api.interactor";
// Enums
import { Project } from "src/app/models/environments/project.enum";
// Libraries
import * as _ from "underscore";
import * as i0 from "@angular/core";
import * as i1 from "../../shared/services/emitter.service";
import * as i2 from "@angular/common/http";
export class CustomBannerService {
    constructor(emitterService, httpClient) {
        this.emitterService = emitterService;
        this.httpClient = httpClient;
        // Arrays
        this.bannersList = [];
        // Objects
        this.bannerDataByZoneId = {};
        // Booleans
        this.isProfileRefreshRequired = false;
        // --------------------------------------------------------
        // Subject and Behaviour Subject
        this.isBannerAvailableSubject = new Subject();
        this.isBannerAvailableSubject$ = this.isBannerAvailableSubject.asObservable();
        this.apiInteractor = new ApiInteractors(this.httpClient);
        this.subscription =
            this.emitterService.isSuccessfulDepositSubject$.subscribe(() => {
                this.bannerDataByZoneId = {};
            });
    }
    // -----------------------------------------------------------------
    // Get Methods
    getIsProfileRefreshRequired() {
        return this.isProfileRefreshRequired;
    }
    // -----------------------------------------------------------------
    // Get Methods - Promises
    onBannerList(bannerRequest, isForce) {
        if (!_.isEmpty(this.bannersList) || isForce) {
            return of(this.bannersList);
        }
        else {
            return this.apiInteractor
                .get(`/ajax/banner/getBanners`, bannerRequest, Project.Shotz)
                .pipe(tap((bannersList) => {
                if (bannersList && bannersList.length > 0) {
                    this.bannersList = bannersList;
                }
            }), map((bannersList) => {
                return bannersList;
            }), catchError((error) => {
                return throwError(error);
            }));
        }
    }
    // -----------------------------------------------------------------
    // Set Methods
    onBroadCastIsBannerAvialable(isBannerAvailable) {
        this.isBannerAvailableSubject.next(isBannerAvailable);
    }
    onSetIsProfileRefreshRequired(isProfileRefreshRequired) {
        this.isProfileRefreshRequired = isProfileRefreshRequired;
    }
    // -----------------------------------------------------------------
    // On Destroy
    ngOnDestroy() {
        if (this.subscription)
            this.subscription.unsubscribe();
    }
}
CustomBannerService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CustomBannerService_Factory() { return new CustomBannerService(i0.ɵɵinject(i1.EmitterService), i0.ɵɵinject(i2.HttpClient)); }, token: CustomBannerService, providedIn: "root" });
