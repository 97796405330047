/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./proof-of-payment.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@ngx-translate/core";
import * as i4 from "../../../shared/components/button-loader/button-loader.component.ngfactory";
import * as i5 from "../../../shared/components/button-loader/button-loader.component";
import * as i6 from "./proof-of-payment.component";
import * as i7 from "../../../multi-languages/services/translation.service";
var styles_ProofOfPaymentComponent = [i0.styles];
var RenderType_ProofOfPaymentComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProofOfPaymentComponent, data: {} });
export { RenderType_ProofOfPaymentComponent as RenderType_ProofOfPaymentComponent };
function View_ProofOfPaymentComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_ProofOfPaymentComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProofOfPaymentComponent_2)), i1.ɵdid(2, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = i1.ɵnov(_v.parent, 24); _ck(_v, 2, 0, currVal_0); }, null); }
function View_ProofOfPaymentComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_ProofOfPaymentComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_ProofOfPaymentComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["class", "upload-finename"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.proofOfPaymentData.fileName; _ck(_v, 2, 0, currVal_0); }); }
function View_ProofOfPaymentComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [["class", "kyc__card"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "h5", [["class", "sub-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, null, 6, "div", [["class", "upload-content-text kyc-uploaded-file m-t-16"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [["class", "icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "i", [["class", "icon-doc"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProofOfPaymentComponent_6)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 1, "span", [["class", "closeicon"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClearField() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 0, "i", [["class", "icon-close"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 3, "button", [["aria-label", "Submit"], ["class", "btn btn-primary btn-md full-width m-t-16 fade-in btn-round"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onSubmitProofOfPayment() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_ButtonLoaderComponent_0, i4.RenderType_ButtonLoaderComponent)), i1.ɵdid(12, 573440, null, 0, i5.ButtonLoaderComponent, [], { setLoader: [0, "setLoader"] }, null), (_l()(), i1.ɵted(13, 0, [" ", " "])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.proofOfPaymentData; _ck(_v, 8, 0, currVal_1); var currVal_3 = _co.isButtonDisabled; _ck(_v, 12, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("confirmidentity.image_proof_id")); _ck(_v, 2, 0, currVal_0); var currVal_2 = _co.isButtonDisabled; _ck(_v, 11, 0, currVal_2); var currVal_4 = i1.ɵunv(_v, 13, 0, i1.ɵnov(_v, 14).transform("confirmidentity.submit_txt")); _ck(_v, 13, 0, currVal_4); }); }
function View_ProofOfPaymentComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "m-t-16 alert alert-danger"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.uploadResponseData.message; _ck(_v, 2, 0, currVal_0); }); }
function View_ProofOfPaymentComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "m-t-16 alert alert-success"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.uploadResponseData.message; _ck(_v, 2, 0, currVal_0); }); }
function View_ProofOfPaymentComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, null, null, null, null, null, null, null)), i1.ɵdid(2, 16384, null, 0, i2.NgSwitch, [], { ngSwitch: [0, "ngSwitch"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProofOfPaymentComponent_9)), i1.ɵdid(4, 278528, null, 0, i2.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i2.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProofOfPaymentComponent_10)), i1.ɵdid(6, 278528, null, 0, i2.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i2.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.uploadResponseData.status; _ck(_v, 2, 0, currVal_0); var currVal_1 = "error"; _ck(_v, 4, 0, currVal_1); var currVal_2 = "success"; _ck(_v, 6, 0, currVal_2); }, null); }
function View_ProofOfPaymentComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProofOfPaymentComponent_8)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.uploadResponseData; _ck(_v, 1, 0, currVal_0); }, null); }
function View_ProofOfPaymentComponent_12(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "m-t-16 alert alert-danger"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.sizeExceeded; _ck(_v, 2, 0, currVal_0); }); }
function View_ProofOfPaymentComponent_11(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProofOfPaymentComponent_12)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.sizeExceeded; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ProofOfPaymentComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { proofOfPymCamera: 0 }), i1.ɵqud(671088640, 2, { proofOfPym: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProofOfPaymentComponent_1)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 19, "div", [["class", "kyc__card"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "p", [["class", "upload-infomsg"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, [" ", " "])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProofOfPaymentComponent_3)), i1.ɵdid(9, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProofOfPaymentComponent_4)), i1.ɵdid(11, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null), (_l()(), i1.ɵeld(12, 0, null, null, 5, "div", [["class", "upload-btn-wrapper full-width show-mobile-tablet-ipad"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, [[1, 0], ["proofOfPymCamera", 1]], null, 0, "input", [["accept", "image/jpg, image/jpeg,image/png,.doc,.pdf"], ["capture", "camera"], ["id", "proof-of-pym-camera"], ["type", "file"]], null, [[null, "change"], [null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.onUpload($event) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.onClearField() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 3, "label", [["class", "btn btn-kyc btn-md full-width btn-round"], ["for", "proof-of-pym-camera"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 0, "i", [["class", "icon-camera"]], null, null, null, null, null)), (_l()(), i1.ɵted(16, null, [" ", " "])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(18, 0, null, null, 5, "div", [["class", "upload-btn-wrapper full-width"]], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, [[2, 0], ["proofOfPym", 1]], null, 0, "input", [["accept", "image/jpg, image/jpeg,image/png,.doc,.pdf"], ["id", "proof-of-pym"], ["type", "file"]], null, [[null, "change"], [null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.onUpload($event) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.onClearField() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 3, "label", [["class", "btn btn-kyc btn-md full-width btn-round"], ["for", "proof-of-pym"]], null, null, null, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 0, "i", [["class", "icon-doc"]], null, null, null, null, null)), (_l()(), i1.ɵted(22, null, [" ", " "])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(0, [["kycInProgressTemplate", 2]], null, 0, null, View_ProofOfPaymentComponent_5)), (_l()(), i1.ɵand(0, [["uploadedResponseTemplate", 2]], null, 0, null, View_ProofOfPaymentComponent_7)), (_l()(), i1.ɵand(0, [["sizeExceededErrorTemplate", 2]], null, 0, null, View_ProofOfPaymentComponent_11))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.proofOfPaymentData; _ck(_v, 3, 0, currVal_0); var currVal_2 = i1.ɵnov(_v, 26); _ck(_v, 9, 0, currVal_2); var currVal_3 = i1.ɵnov(_v, 25); _ck(_v, 11, 0, currVal_3); }, function (_ck, _v) { var currVal_1 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform("confirmidentity.payment_doc-desc")); _ck(_v, 6, 0, currVal_1); var currVal_4 = i1.ɵunv(_v, 16, 0, i1.ɵnov(_v, 17).transform("confirmidentity.take_photo")); _ck(_v, 16, 0, currVal_4); var currVal_5 = i1.ɵunv(_v, 22, 0, i1.ɵnov(_v, 23).transform("confirmidentity.upload_image")); _ck(_v, 22, 0, currVal_5); }); }
export function View_ProofOfPaymentComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-proof-of-payment", [], null, null, null, View_ProofOfPaymentComponent_0, RenderType_ProofOfPaymentComponent)), i1.ɵdid(1, 573440, null, 0, i6.ProofOfPaymentComponent, [i7.TranslationService], null, null)], null, null); }
var ProofOfPaymentComponentNgFactory = i1.ɵccf("app-proof-of-payment", i6.ProofOfPaymentComponent, View_ProofOfPaymentComponent_Host_0, { uploadResponseData: "uploadResponseData", isButtonDisabled: "isButtonDisabled" }, { uploadProofOfPaymentFiles: "uploadProofOfPaymentFiles" }, []);
export { ProofOfPaymentComponentNgFactory as ProofOfPaymentComponentNgFactory };
