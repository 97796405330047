export const vipTagsList: string[] = [
  "Potential L",
  "Potential H",
  "Ghost Loy1",
  "Ghost Pot",
  "Loyalty 1",
  "potential",
  "potkyc",
  "Ghost",
  "PL50",
];
