/*  
    these are all the mappers for the zones that call particular banners via API.
    these are created so we group the zones together with their respective parent zone.
    example: b_casino is the parent zone of b-slots, b_instant_wins etc..
    so the user will not see the banner flashing anytime he switches from category to another category.
*/
export const bannerZoneMappersCasinoList: string[] = [
  "b_instant-wins",
  "b_table-games",
  "b_slots",
  "b_all",
  "",
];

export const bannerZoneMappersLiveCasinoList: string[] = [
  "b_games-shows",
  "b_blackjack",
  "b_baccarat",
  "b_roulette",
  "",
];
