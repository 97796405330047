import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { NgModule } from "@angular/core";

// Components
import { ChangePasswordComponent } from "src/app/modules/auth/components/change-password/change-password.component";
import { ForgotPasswordComponent } from "src/app/modules/auth/components/forgot-password/forgot-password.component";
import { LoginFormComponent } from "src/app/modules/auth/components/login-form/login-form.component";

// Effects
import { AuthEffects } from "src/app/modules/auth/store/effects/auth.effects";

// Guard
import { AuthGaurd } from "src/app/modules/auth/guards/auth.guard";

// Modules
import { DynamicFormModule } from "src/app/modules/dynamic-form/dynamic-form.module";
import { SharedModule } from "src/app/modules/shared/shared.module";

// Reducers
import * as fromAuthReducer from "src/app/modules/auth/store/reducers/auth.reducer";

@NgModule({
  declarations: [
    ChangePasswordComponent,
    ForgotPasswordComponent,
    LoginFormComponent,
  ],
  imports: [
    ReactiveFormsModule,
    DynamicFormModule,
    CommonModule,
    SharedModule,
    FormsModule,
    EffectsModule.forFeature([AuthEffects]),
    StoreModule.forFeature(
      fromAuthReducer.AuthStateFeatureKey,
      fromAuthReducer.reducer
    ),
  ],
  providers: [AuthGaurd],
  exports: [
    ChangePasswordComponent,
    ForgotPasswordComponent,
    LoginFormComponent,
  ],
})
export class AuthModule {}
