/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./footer.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../shared/pipes/safe-html.pipe";
import * as i4 from "@angular/platform-browser";
import * as i5 from "./footer.component";
import * as i6 from "../../../multi-languages/services/multi-language.service";
import * as i7 from "../../../multi-languages/services/translation.service";
import * as i8 from "../../services/static-page.service";
import * as i9 from "../../../chat/services/live-chat.service";
import * as i10 from "../../../shared/services/utility.service";
import * as i11 from "../../../auth/services/session.service";
import * as i12 from "@ngrx/store";
import * as i13 from "@angular/router";
var styles_FooterComponent = [i0.styles];
var RenderType_FooterComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_FooterComponent, data: {} });
export { RenderType_FooterComponent as RenderType_FooterComponent };
function View_FooterComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "overlay-backdrop--footer"]], null, null, null, null, null))], null, null); }
function View_FooterComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "a", [["href", "javascript:void(0)"]], [[2, "hide", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onChangeLanguageHandler(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [], [[8, "src", 4], [8, "alt", 0]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.languageCode === _v.context.$implicit); _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵinlineInterpolate(1, "./assets/img/flag/", _v.context.$implicit, ".png"); var currVal_2 = i1.ɵinlineInterpolate(1, "", _co.localeDisplayName[_v.context.$implicit], ""); _ck(_v, 1, 0, currVal_1, currVal_2); var currVal_3 = _co.localeDisplayName[_v.context.$implicit]; _ck(_v, 3, 0, currVal_3); }); }
function View_FooterComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "language--dropdown"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(4, { "isOpen fade-in-up": 0, isClose: 1 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FooterComponent_4)), i1.ɵdid(6, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "language--dropdown"; var currVal_1 = _ck(_v, 4, 0, true, false); _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_2 = _co.acceptedLanguagesList; _ck(_v, 6, 0, currVal_2); }, null); }
function View_FooterComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 10, "div", [["class", "language--wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 7, "div", [["class", "language--container"]], [[2, "isLangOpen", null]], null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "language--active"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.isLanguageOpen = !_co.isLanguageOpen) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 3, "a", [["href", "javascript:void(0)"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "img", [["alt", "flag"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "span", [["class", ""]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FooterComponent_3)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 1, "div", [["class", "chat-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 0, "i", [["class", "icon icon-chat"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onInitializeLiveChat() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.isLanguageOpen; _ck(_v, 9, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isLanguageOpen; _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵinlineInterpolate(1, "./assets/img/flag/", _co.languageCode, ".png"); _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.localeDisplayName[_co.languageCode]; _ck(_v, 7, 0, currVal_2); }); }
export function View_FooterComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i3.SafeHtmlPipe, [i4.DomSanitizer]), i1.ɵqud(671088640, 1, { footerConatiner: 0 }), (_l()(), i1.ɵeld(2, 0, null, null, 6, "footer", [["class", "footer padding page-body footer-conatiner #footerConatiner"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FooterComponent_1)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FooterComponent_2)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 1, "div", [["id", "dynamicFooterContent"]], [[8, "innerHTML", 1]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onDynamicFooterContent($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵppd(8, 1)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isLanguageOpen; _ck(_v, 4, 0, currVal_0); var currVal_1 = (((_co.excludedLangaugesList.indexOf(_co.languageCode) <= (0 - 1)) && !_co.isLoggedIn) && _co.isShowLanguageSelectorEnabled); _ck(_v, 6, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = i1.ɵunv(_v, 7, 0, _ck(_v, 8, 0, i1.ɵnov(_v, 0), _co.staticContent)); _ck(_v, 7, 0, currVal_2); }); }
export function View_FooterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-footer", [], null, [["document", "click"], ["document", "touchend"], ["window", "popstate"]], function (_v, en, $event) { var ad = true; if (("document:click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("document:touchend" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).onClick($event) !== false);
        ad = (pd_1 && ad);
    } if (("window:popstate" === en)) {
        var pd_2 = (i1.ɵnov(_v, 1).onBrowserBackBtnClose($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, View_FooterComponent_0, RenderType_FooterComponent)), i1.ɵdid(1, 245760, null, 0, i5.FooterComponent, [i6.MultiLanguageService, i7.TranslationService, i8.StaticPageService, i9.LiveChatService, i10.UtilityService, i11.SessionService, i12.Store, i13.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FooterComponentNgFactory = i1.ɵccf("app-footer", i5.FooterComponent, View_FooterComponent_Host_0, {}, {}, []);
export { FooterComponentNgFactory as FooterComponentNgFactory };
