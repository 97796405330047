import { HttpClient } from "@angular/common/http";
import { catchError, map } from "rxjs/operators";
import { Observable, throwError } from "rxjs";
import { Injectable } from "@angular/core";

// Api Interactors
import { ApiInteractors } from "src/app/models/interactors/api.interactor";

// Models
import {
  PolicyUserDetailsUpdateResponse,
  PolicyUserDetailsResponse,
  PolicyConfigsUserDetails,
  PoliciesRequest,
  PolicyResponse,
  PolicyConfigs,
} from "src/app/modules/shared/models/policy/policy.model";

@Injectable({
  providedIn: "root",
})
export class PolicyService {
  // API Interactions
  apiInteractor: ApiInteractors;

  constructor(private httpClient: HttpClient) {
    this.apiInteractor = new ApiInteractors(this.httpClient);
  }

  // -----------------------------------------------------------------
  // Get Methods
  onGetPolicies(policyType: string): Observable<PolicyConfigs> {
    return this.apiInteractor
      .get<null, PolicyResponse>(`/ajax/policy/getPolicies`)
      .pipe(
        map((response: PolicyResponse) => {
          if (
            response &&
            response.policyConfigs &&
            response.policyConfigs.length > 0
          ) {
            let policies = response.policyConfigs.filter(
              (policy) => policy.policyType === policyType
            );

            if (policies.length > 0) {
              return policies[0];
            }

            return null;
          }

          return null;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  onGetUserPolicyDetails(
    policyType: string
  ): Observable<PolicyConfigsUserDetails> {
    return this.apiInteractor
      .get<null, PolicyUserDetailsResponse>(`/ajax/policy/getUserPolicyDetails`)
      .pipe(
        map((policyUserDetailsResponse: PolicyUserDetailsResponse) => {
          if (
            policyUserDetailsResponse &&
            policyUserDetailsResponse.policies &&
            policyUserDetailsResponse.policies.length > 0
          ) {
            let policiesList: PolicyConfigsUserDetails[] =
              policyUserDetailsResponse.policies.filter(
                (policy: PolicyConfigsUserDetails) =>
                  policy.policyType === policyType
              );

            if (policiesList.length > 0) {
              return policiesList[0];
            }

            return null;
          }

          return null;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  /*OnGetUserPolicyDetailsHistory(
    policyType: string
  ): Observable<PolicyConfigsUserDetails[]> {
    return this.apiInteractor
      .get<null, PolicyUserDetailsResponse>(
        `/ajax/policy/getUserPolicyDetailsHistory`
      )
      .pipe(
        map((response: PolicyUserDetailsResponse) => {
          return response.policyConfigs.filter(
            (policy) => policy.policyType === policyType
          );
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }*/

  onUpdateUserPolicyDetails(
    updatedPolicy: PolicyConfigsUserDetails
  ): Observable<PolicyUserDetailsUpdateResponse> {
    const payload: PoliciesRequest = {
      policies: JSON.stringify([updatedPolicy]),
    };

    return this.apiInteractor
      .post<PoliciesRequest, PolicyUserDetailsUpdateResponse>(
        `/ajax/policy/updateUserPolicyDetails`,
        payload
      )
      .pipe(
        map(
          (
            policyUserDetailsUpdateResponse: PolicyUserDetailsUpdateResponse
          ) => {
            return policyUserDetailsUpdateResponse;
          }
        ),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
}
