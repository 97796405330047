<div
  class="btn-wrapper text-center m-t-8"
  *ngIf="(isUsedAccount &&  config.visibleOnRepeatPayment) || (!isUsedAccount && config.visibleOnNewPayment)"
>
  <button
    aria-label="CTA"
    type="button"
    (click)="onButtonClick(config.key)"
    class="{{config.class}}"
    [ngClass]="{'has-error': getShouldShowErrors(config.key, formGroup)}"
  >
    {{ config.name }}
    <span *ngIf="config.id === 'addNewCard'" class="icon-plus">+</span>
  </button>
  <p class="fee m-t-16" *ngIf="config.paymentFee">Fee €1.25</p>
</div>
