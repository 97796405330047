import { createAction, props } from "@ngrx/store";

// Enums
import { UserProfileBalance } from "src/app/modules/shared/models/profiles/user-profile-balance.model";
import { ProfileBalance } from "src/app/modules/auth/models/profile-balance.model";

export enum ProfileActionTypes {
  // Profile Balance
  ProfileBalanceRequested = "[Profile API] Profile Balance Requested",
  ProfileBalanceRequestedRefresh = "[Profile API] Profile Balance Requested Refresh",
  ProfileBalanceLoaded = "[Profile API] Profile Balance Loaded",
  ProfileBalanceError = "[Profile API] Profile Balance Error",

  // User Profile Balance
  UserProfileBalanceRequested = "[Profile API] User Profile Balance Requested",
  UserProfileBalanceRequestedRefresh = "[Profile API] User Profile Balance Requested Refresh",
  UserProfileBalanceLoaded = "[Profile API] User Profile Balance Loaded",
  UserProfileBalanceError = "[Profile API] User Profile Balance Error",
}

// Profile Balance
export const profileBalanceRequested = createAction(
  ProfileActionTypes.ProfileBalanceRequested
);
export const profileBalanceRequestedRefresh = createAction(
  ProfileActionTypes.ProfileBalanceRequestedRefresh
);
export const profileBalanceLoaded = createAction(
  ProfileActionTypes.ProfileBalanceLoaded,
  props<{ profileBalance: ProfileBalance }>()
);
export const profileBalanceError = createAction(
  ProfileActionTypes.ProfileBalanceError
);

// User Profile Balance
export const userProfileBalanceRequested = createAction(
  ProfileActionTypes.UserProfileBalanceRequested
);
export const userProfileBalanceRequestedRefresh = createAction(
  ProfileActionTypes.UserProfileBalanceRequestedRefresh
);
export const userProfileBalanceLoaded = createAction(
  ProfileActionTypes.UserProfileBalanceLoaded,
  props<{ userProfileBalance: UserProfileBalance }>()
);
export const userProfileBalanceError = createAction(
  ProfileActionTypes.UserProfileBalanceError
);
