<div
  class="form-group  {{config.class}} {{getFormFieldClass(config.key, formGroup)}} clearfix"
  [formGroup]="formGroup"
  *ngIf="(isUsedAccount && config.visibleOnRepeatPayment) || (!isUsedAccount && config.visibleOnNewPayment)"
  [ngClass]="{
    'has-error': getShouldShowErrors(config.key, formGroup), 
    'has-success': (getShouldShowErrors(config.key, formGroup) === null)
  }"
  [class.has-label]="config.lableDisplay === true"
>
  <div
    class="controls"
    [ngClass]="{
     'payementKind' : config.logoDisplayOnInput}"
  >
    <label *ngIf="config.lableDisplay">
      {{config.lableDisplayText | translate}}
    </label>
    <img
      class="payementKindLogo"
      src="{{mediaUrlPath}}/payment-logo/{{selectedMethodData.providerType.toLowerCase()}}.png{{imgixParams}}"
      alt="{{selectedMethodData.providerType}}"
      title="{{selectedMethodData.providerType}}"
      *ngIf="config.logoDisplayOnInput"
    />

    <input
      [type]="config.type"
      attr.placeholder="{{config.placeholderText | translate}}"
      [formControlName]="config.key"
      [readonly]="config.readonly"
      (focus)="onFocusForElement(config.key)"
      (focusout)="onFocusOutForElement()"
      class="cashier-input"
      (keydown)="onKeyPress()"
      (input)="onFormatInputValue(config)"
      [ngClass]="{'payementKindInput' : config.logoDisplayOnInput}"
    />

    <div class="fade-in error-msg" *ngIf="getShouldShowErrors(config.key, formGroup)">
      {{getErrorMessage(config.key, formGroup)}}
    </div>
  </div>
</div>
