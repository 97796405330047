import { BehaviorSubject } from "rxjs";
import { map } from "rxjs/operators";
// Libraries
import * as _ from "underscore";
import * as i0 from "@angular/core";
import * as i1 from "../../user/services/user-details.service";
import * as i2 from "./game-groups.service";
import * as i3 from "../../shared/services/main.service";
export class JackpotService {
    constructor(userDetailsService, gameGroupsService, mainService) {
        this.userDetailsService = userDetailsService;
        this.gameGroupsService = gameGroupsService;
        this.mainService = mainService;
        // Arrays
        this.microGamingJackpotFeed = [];
        // Others
        this.mircroGameJackpots = {};
        // ---------------------------------------------------------//
        // Behaviour Subjects
        this.mircoGamingJackpotDataBehavourSubject = new BehaviorSubject(this.mircroGameJackpots);
        this.mircoGamingJackpotDataBehavourSubject$ = this.mircoGamingJackpotDataBehavourSubject.asObservable();
        this.onGetMessagesJackpots();
        this.subscription =
            this.userDetailsService.userBalanceUpdatedSubject$.subscribe(() => this.onGetMessagesJackpots());
    }
    // -----------------------------------------------------------------
    // Get Methods
    onGetJackpotFeedList() {
        return this.mainService
            .onGetMicroGamingJackpotFeed(this.userDetailsService.getCurrencyCode())
            .pipe(map((jackpotFeedList) => {
            return jackpotFeedList;
        }));
    }
    // -----------------------------------------------------------------
    // Set Methods
    onGetMessagesJackpots() {
        this.jackpotSubscription = this.onGetJackpotFeedList().subscribe((jackpotFeedList) => {
            this.microGamingJackpotFeed = jackpotFeedList;
            this.mircroGameJackpots = {};
            if (jackpotFeedList.length > 0) {
                this.gameGroupsService
                    .onGetGamePregmaticList()
                    .subscribe((gamesList) => {
                    let microGamingGamesList = _.where(gamesList, {
                        gameType: "progressive_slots",
                    });
                    jackpotFeedList.filter((jackpotFilter) => {
                        let gameName = jackpotFilter.friendlyName
                            .replace(/[\W_]+/g, " ")
                            .trim();
                        microGamingGamesList.filter((filterGamePregmatic) => {
                            if (filterGamePregmatic.name
                                .toUpperCase()
                                .indexOf(gameName.toUpperCase()) > -1 &&
                                jackpotFilter.gamePayId == 0) {
                                this.mircroGameJackpots[filterGamePregmatic.gameCode] =
                                    jackpotFilter.startAtValue;
                            }
                        });
                    });
                    this.onBroadCastMicroGamingJackpotSuccess();
                });
            }
        });
    }
    onBroadCastMicroGamingJackpotSuccess() {
        this.mircoGamingJackpotDataBehavourSubject.next(this.mircroGameJackpots);
    }
    // -----------------------------------------------------------------
    // On Destroy
    ngOnDestroy() {
        if (this.jackpotSubscription)
            this.jackpotSubscription.unsubscribe();
        if (this.subscription)
            this.subscription.unsubscribe();
    }
}
JackpotService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function JackpotService_Factory() { return new JackpotService(i0.ɵɵinject(i1.UserDetailsService), i0.ɵɵinject(i2.GameGroupsService), i0.ɵɵinject(i3.MainService)); }, token: JackpotService, providedIn: "root" });
