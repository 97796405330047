import { CommonModule } from "@angular/common";
import { StoreModule } from "@ngrx/store";
import { NgModule } from "@angular/core";

// Reducers
import * as fromLanguagesReducer from "src/app/modules/multi-languages/store/reducers/languages.reducer";

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromLanguagesReducer.LanguageStateFeatureKey,
      fromLanguagesReducer.reducer
    ),
  ],
})
export class MultiLanguagesModule {}
