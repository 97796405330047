import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

// Environments
import { environment } from "src/environments/environment";

// Utilities
import { converterObjectQueryString } from "src/app/modules/shared/utilities/converter-object-query-string.utilities";

export class PaymentInteractors {
  // Strings
  baseUrl: string = environment.apiUrl;

  // Http Clients
  private httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  // -----------------------------------------------------------------
  // Get Methods - Base Request
  /*
    @param url endpoint url
    @param queryParams optional query params
  */
  get<K, T>(url: string, queryParameters?: K): Observable<T> {
    let queryString: string = converterObjectQueryString<K>(queryParameters);

    return this.httpClient.get<T>(
      `${environment.paymentIqUrl}${url}${queryString}`,
      {
        observe: "body",
      }
    );
  }

  /*
    @param url endpoint url
    @param requestBody data that need to be saved/posted
  */
  post<K, T>(url: string, requestBody: K): Observable<T> {
    return this.httpClient.post<T>(
      `${environment.paymentIqUrl}${url}`,
      requestBody
    );
  }

  /*
    @param url endpoint url
    @param requestBody data that need to be saved/posted
  */
  delete<K, T>(url: string, queryParameters?: K): Observable<T> {
    let queryString: string = converterObjectQueryString<K>(queryParameters);

    return this.httpClient.delete<T>(
      `${environment.paymentIqUrl}${url}${queryString}`,
      {
        observe: "body",
      }
    );
  }
}
