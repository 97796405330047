<app-side-menu-modal
  [customClass]="'changePwd'"
  [sideMenuTitle]="'change_password.page_title'"
>
  <!-- modal body -->
  <ng-container sideMenuBody>
    <form
      [formGroup]="changePasswordForm"
      novalidate
      (keyup.enter)="onChangePassword()"
    >
      <ng-container *ngIf="isPasswordRequested">
        <ng-container
          *ngTemplateOutlet="changePasswordRequestTemplate"
        ></ng-container>
      </ng-container>

      <div class="form-group clearfix has-label">
        <div class="controls">
          <label for="txtPassword"
            >{{'change_password.current_password' | translate}}</label
          >
          <input
            [type]="isOldPassword ? 'password' : 'text'"
            id="oldPassword"
            name="oldPassword"
            class="isPassword"
            formControlName="old"
            autocomplete="off"
            input-trimdir
          />
          <span class="icon-suffix" (click)="isOldPassword = !isOldPassword">
            <i [className]="!isOldPassword ? 'icon-eye-hide' : 'icon-eye'"></i>
          </span>

          <ng-container *ngTemplateOutlet="oldErrorTemplate"></ng-container>
        </div>
      </div>

      <div class="form-group clearfix has-label">
        <div class="controls">
          <label for="txtPassword"
            >{{'change_password.new_password' | translate}}</label
          >
          <input
            [type]="isPassword ? 'password' : 'text'"
            id="password"
            name="password"
            class="isPassword"
            formControlName="password"
            autocomplete="off"
            input-trimdir
          />

          <span class="icon-suffix" (click)="isPassword = !isPassword">
            <i [className]="!isPassword ? 'icon-eye-hide' : 'icon-eye'"></i>
          </span>

          <ng-container
            *ngTemplateOutlet="passwordErrorTemplate"
          ></ng-container>
        </div>
      </div>

      <div class="form-group clearfix has-label">
        <div class="controls">
          <label for="txtPassword"
            >{{'change_password.confirm_new_password' | translate}}</label
          >
          <input
            [type]="isConfirmPassword ? 'password' : 'text'"
            id="confirmPassword"
            name="confirmPassword"
            class="isPassword"
            formControlName="confirmPassword"
            autocomplete="off"
            input-trimdir
          />

          <span
            class="icon-suffix"
            (click)="isConfirmPassword = !isConfirmPassword"
          >
            <i
              [className]="!isConfirmPassword ? 'icon-eye-hide' : 'icon-eye'"
            ></i>
          </span>

          <ng-container
            *ngTemplateOutlet="confirmPasswordErrorTemplate"
          ></ng-container>
        </div>
      </div>

      <button
        type="button"
        id="btnChangePassword"
        class="btn btn-primary btn-md full-width m-t-20 m-b-16 btn-round"
        [setLoader]="isButtonLoader"
        [disabled]="changePasswordForm.invalid || changePasswordForm.pending || isButtonLoader"
        aria-label="Save changes"
        (click)="onChangePassword()"
      >
        {{"change_password.save_changes" | translate }}
      </button>
    </form>
  </ng-container>
</app-side-menu-modal>

<!-- 
  ------------------------------------------------------
  
  Error Templates 
  
  ------------------------------------------------------
-->
<ng-template #oldErrorTemplate>
  <div
    class="error-msg fade-in-down"
    *ngIf="
      changePasswordForm.controls['old'].errors
        && 
      changePasswordForm.controls['old'].touched
    "
  >
    <ng-container>
      <div [hidden]="!changePasswordForm.controls['old'].errors.required">
        {{ "errors.error1" | translate }}
      </div>
    </ng-container>
  </div>
</ng-template>

<ng-template #passwordErrorTemplate>
  <div
    class="error-msg fade-in-down"
    *ngIf="
      changePasswordForm.controls['password'].errors
        && 
      changePasswordForm.controls['password'].touched
    "
  >
    <ng-container>
      <!-- 
        {{ changePasswordForm.controls['password'].errors | json }}
        {{ changePasswordForm.errors | json }} 
      -->
      <div [hidden]="!changePasswordForm.controls['password'].errors.required">
        {{ "errors.error1" | translate }}
      </div>
      <div
        [hidden]="!changePasswordForm.controls['password'].errors.passwordNotStrong"
      >
        {{ "errors.error24" | translate }}
      </div>
      <div
        [hidden]="!changePasswordForm.controls['password'].errors.passwordCannotBeStronger"
      >
        {{ "errors.error57" | translate }} 25 {{ "errors.error58" | translate }}
      </div>
    </ng-container>
  </div>
</ng-template>

<ng-template #confirmPasswordErrorTemplate>
  <div
    class="error-msg fade-in-down"
    *ngIf="
      changePasswordForm.controls['confirmPassword'].errors
        && 
      changePasswordForm.controls['confirmPassword'].touched
    "
  >
    <ng-container>
      <!-- 
        {{ changePasswordForm.errors | json }}
        {{ changePasswordForm.controls['confirmPassword'].errors | json }}
      -->
      <div
        [hidden]="!changePasswordForm.controls['confirmPassword'].errors.required"
      >
        {{ "errors.error1" | translate }}
      </div>
      <div
        [hidden]="!changePasswordForm.controls['confirmPassword'].errors.passwordNotStrong"
      >
        {{ "errors.error24" | translate }}
      </div>
      <div
        [hidden]="!changePasswordForm.controls['confirmPassword'].errors.passwordCannotBeStronger"
      >
        {{ "errors.error57" | translate }} 25 {{ "errors.error58" | translate }}
      </div>
      <div
        [hidden]="!changePasswordForm.controls['confirmPassword'].errors.passwordDoNotMatch"
      >
        {{ "errors.error2" | translate }}
      </div>
    </ng-container>
  </div>
</ng-template>

<!-- 
  ------------------------------------------------------
  
  Templates 
  
  ------------------------------------------------------
-->
<!-- Change Password Request Template -->
<ng-template #changePasswordRequestTemplate>
  <div
    class="alert alert-danger fade-in-up clearfix"
    *ngIf="!isPasswordSuccessfullyUpdated"
  >
    {{serverError}}
  </div>

  <div
    class="alert alert-success fade-in-up clearfix"
    *ngIf="isPasswordSuccessfullyUpdated"
  >
    {{'change_password.password_updated_success' | translate}}
  </div>
</ng-template>
