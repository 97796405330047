import { Component } from "@angular/core";
import { Subscription } from "rxjs";

// Environments
import { environment } from "src/environments/environment";

// Enums
import { StatusResponse } from "src/app/models/api/status.response";

// Models
import { UploadDocumentsResponse } from "src/app/modules/kyc/models/upload-documents/upload-documents-response.model";
import { UploadDocumentsRequest } from "src/app/modules/kyc/models/upload-documents/upload-documents-request.model";
import { KYCDetailsToUnblock } from "src/app/modules/kyc/models/kyc-details/kyc-details-to-unblock.model";
import { AccountDocumentStatus } from "src/app/modules/kyc/models/account-document-status.model";
import { SendDocumentResponse } from "src/app/modules/kyc/models/send-document-response.model";
import { SendDocumentRequest } from "src/app/modules/kyc/models/send-document-request.model";
import { UpdateStatusData } from "src/app/modules/kyc/models/update-status-data.model";
import { FileUploadData } from "src/app/modules/kyc/models/file-upload-data.model";
import {
  KYCStatusResponse,
  KYCStatus,
} from "src/app/modules/kyc/models/kyc-status.model";

// Services
import { TranslationService } from "src/app/modules/multi-languages/services/translation.service";
import { AccountService } from "src/app/modules/account/services/account.service";
import { KYCService } from "src/app/modules/kyc/services/kyc.service";

@Component({
  selector: "app-confirm-identity-kyc",
  templateUrl: "./confirm-identity-kyc.component.html",
  styleUrls: ["./confirm-identity-kyc.component.scss"],
})
export class ConfirmIdentityKycComponent {
  // Strings
  documentSendStatus: string = "";

  // Booleans
  isButtonDisabled: boolean = false;

  // Objects
  accountDocumentStatus: AccountDocumentStatus;
  kycLevelDetails: KYCDetailsToUnblock;
  updateStatusData: UpdateStatusData = {
    nationalid: {
      message: "",
      status: "",
    },
    utilitybill: {
      message: "",
      status: "",
    },
    "proof-of-payment": {
      message: "",
      status: "",
    },
    "source-of-fund": {
      message: "",
      status: "",
    },
  };

  // Subscriptions
  getAccountVerficationStatusSubscription: Subscription;
  userKycLevelDetailsSubscription: Subscription;
  uploadDocumentsSubscription: Subscription;
  sendDocumentsSubscription: Subscription;

  constructor(
    private translationService: TranslationService,
    private accountService: AccountService,
    private kycService: KYCService
  ) {
    if (environment.features.testing.kyc) {
      this.updateStatusData = {
        ...this.updateStatusData,
        utilitybill: {
          message: "",
          status: KYCStatus.vrfn_verified,
        },
      };
    }
  }

  // -----------------------------------------------------------------
  // Lifecycle Hooks
  ngOnInit(): void {
    this.onGetAccountVerficationStatus();

    this.onGetUserKycLevelDetails();
  }

  // -----------------------------------------------------------------
  // Set Methods
  onUploadDocument(fileUploadData: FileUploadData): void {
    if (fileUploadData) {
      let requestData: UploadDocumentsRequest = {
        file: (fileUploadData.event.target as HTMLInputElement).files[0],
        type: fileUploadData.type,
      };

      this.isButtonDisabled = true;

      this.uploadDocumentsSubscription = this.kycService
        .onUploadDocuments(requestData)
        .subscribe((uploadDocumentsResponse: UploadDocumentsResponse) => {
          if (
            uploadDocumentsResponse &&
            uploadDocumentsResponse.status === StatusResponse.success
          ) {
            if (fileUploadData.sourceOfIncome) {
              this.onSendDocument(fileUploadData, {
                listOfSources: fileUploadData.sourceOfIncome,
                fileType: "sourceOfIncome",
              });
            } else {
              this.onSendDocument(fileUploadData);
            }
          } else {
            this.onFailedDocument(fileUploadData, uploadDocumentsResponse);
          }
        });
    }
  }

  onSendDocument(
    fileUploadData: FileUploadData,
    sendDocumentRequest?: SendDocumentRequest
  ): void {
    this.sendDocumentsSubscription = this.kycService
      .onSendDocuments(sendDocumentRequest)
      .subscribe((sendDocumentResponse: SendDocumentResponse) => {
        this.isButtonDisabled = false;

        if (
          sendDocumentResponse &&
          sendDocumentResponse.status === StatusResponse.success
        ) {
          if (fileUploadData.type === "nationalid") {
            this.updateStatusData["nationalid"] = {
              message: this.translationService.get(
                "confirmidentity.file_upload_successs"
              ),
              status: sendDocumentResponse.status,
            };
          } else if (fileUploadData.type === "utilitybill") {
            this.updateStatusData["utilitybill"] = {
              message: this.translationService.get(
                "confirmidentity.file_upload_successs"
              ),
              status: sendDocumentResponse.status,
            };
          } else if (fileUploadData.type === "caf") {
            this.updateStatusData["proof-of-payment"] = {
              message: this.translationService.get(
                "confirmidentity.file_upload_successs"
              ),
              status: sendDocumentResponse.status,
            };
          } else {
            this.updateStatusData["source-of-fund"] = {
              message: this.translationService.get(
                "confirmidentity.file_upload_successs"
              ),
              status: sendDocumentResponse.status,
            };
          }

          this.documentSendStatus = this.translationService.get(
            "confirmidentity.doc_send_success"
          );

          this.onGetAccountVerficationStatus();
        } else {
          this.documentSendStatus = this.translationService.get(
            "confirmidentity.doc_send_fail"
          );

          this.onFailedDocument(fileUploadData, sendDocumentResponse);
        }
      });
  }

  onFailedDocument(
    fileUploadData: FileUploadData,
    uploadDocumentsResponse: UploadDocumentsResponse | SendDocumentResponse
  ): void {
    this.isButtonDisabled = false;

    if (fileUploadData.type === "nationalid") {
      this.updateStatusData["nationalid"] = {
        message: this.translationService.get(
          "confirmidentity.file_upload_error"
        ),
        status: uploadDocumentsResponse.status,
      };
    } else if (fileUploadData.type === "utilitybill") {
      this.updateStatusData["utilitybill"] = {
        message: this.translationService.get(
          "confirmidentity.file_upload_error"
        ),
        status: uploadDocumentsResponse.status,
      };
    } else if (fileUploadData.type === "caf") {
      this.updateStatusData["proof-of-payment"] = {
        message: this.translationService.get(
          "confirmidentity.file_upload_error"
        ),
        status: uploadDocumentsResponse.status,
      };
    } else {
      this.updateStatusData["source-of-fund"] = {
        message: this.translationService.get(
          "confirmidentity.file_upload_error"
        ),
        status: uploadDocumentsResponse.status,
      };
    }
  }

  onGetUserKycLevelDetails(): void {
    this.userKycLevelDetailsSubscription = this.kycService
      .onGetUserKycLevelDetails()
      .subscribe((kycDetailsToUnblock: KYCDetailsToUnblock) => {
        this.kycLevelDetails = kycDetailsToUnblock;
      });
  }

  onGetAccountVerficationStatus(): void {
    this.getAccountVerficationStatusSubscription = this.accountService
      .onGetAccountVerficationStatus()
      .subscribe((kycStatusResponse: KYCStatusResponse) => {
        if (kycStatusResponse && kycStatusResponse.documents) {
          this.accountDocumentStatus = {
            addressStatus: kycStatusResponse.documents.addressStatus,
            identityStatus: kycStatusResponse.documents.identityStatus,
            cafStatus: kycStatusResponse.documents.cafStatus,
            sourceOfIncomeStatus:
              kycStatusResponse.documents.sourceOfIncomeStatus,
          };
        }

        if (environment.features.testing.kyc) {
          this.accountDocumentStatus = {
            addressStatus: KYCStatus.vrfn_failed,
            identityStatus: KYCStatus.vrfn_init,
            cafStatus: KYCStatus.vrfn_verified,
            sourceOfIncomeStatus: KYCStatus.vrfn_init,
          };
        }
      });
  }

  // -----------------------------------------------------------------
  // On Destroy
  ngOnDestroy(): void {
    if (this.getAccountVerficationStatusSubscription)
      this.getAccountVerficationStatusSubscription.unsubscribe();

    if (this.userKycLevelDetailsSubscription)
      this.userKycLevelDetailsSubscription.unsubscribe();

    if (this.uploadDocumentsSubscription)
      this.uploadDocumentsSubscription.unsubscribe();

    if (this.sendDocumentsSubscription)
      this.sendDocumentsSubscription.unsubscribe();
  }
}
