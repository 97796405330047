<ng-container *ngIf="activeView === 'menuOptions'">
  <section
    [@modalInOut]
    class="sideMenu__backdrop"
    (click)="onCloseAccountComponent()"
  ></section>
</ng-container>

<ng-container *ngIf="activeView === 'menuOptions'">
  <app-side-menu-modal
    [customClass]="
      isPnpFlow
        ? 'account-menu isPNP'
        : 'account-menu isClassic'
    "
    [showBackBtn]="false"
    [showFooter]="true"
    [show2LineHeaderTitle]="true"
  >
    <!-- modal header -->
    <ng-container show2LineHeaderTitle>
      <!-- PNP -->
      <ng-container
        *marketBasedComponentDisplay="{
          flowType: UserFlowTypes.ZIMPLER_FLOW,
          langCode: languageCode
        }"
      >
        <ng-container *ngIf="windowType !== 'mobile'">
          {{ "general.my_Account" | translate }}
        </ng-container>
      </ng-container>

      <!-- Classic -->
      <ng-container
        *marketBasedComponentDisplay="{
          flowType: UserFlowTypes.NORMAL_FLOW,
          langCode: languageCode
        }"
      >
        <ng-container
          *ngIf="isLoggedIn && windowType === 'mobile'"
        >
          {{ username }}
          <br />
          <span class="user--level">
            {{ "rewards.level" | translate }}
            {{ currentLevelID }}
          </span>
        </ng-container>
      </ng-container>
    </ng-container>

    <!-- modal body -->
    <div
      sideMenuBody
      class="account-menu"
      [ngClass]="isPnpFlow ? 'isPNP' : 'isClassic'"
    >
      <ng-container
        *marketBasedComponentDisplay="{
          flowType: UserFlowTypes.ZIMPLER_FLOW,
          langCode: languageCode
        }"
      >
        <div
          class="pnp-rewards m-b-24"
          *ngIf="isLoggedIn && windowType === 'mobile'"
        >
          <app-rewards-indicator></app-rewards-indicator>
        </div>
      </ng-container>

      <ng-container *ngIf="windowType === 'mobile'">
        <div class="page-link__wrapper m-b-16">
          <ng-container
            *marketBasedComponentDisplay="{
              flowType: UserFlowTypes.ZIMPLER_FLOW,
              langCode: languageCode
            }"
          >
            <a
              href="javascript:void()"
              role="button"
              aria-label="deposit now button"
              class="page-link--btn deposit-btn"
              (click)="onOpenDeposit()"
              >{{ "general.deposit" | translate }}</a
            >

            <a
              href="javascript:void()"
              role="button"
              class="page-link--btn"
              [class.isdisabled]="
                userWithdrawableBalance <= 0
              "
              (click)="onOpenWithdraw()"
              *ngIf="isLoggedIn"
            >
              {{ "general.withdraw" | translate }}
            </a>

            <ng-container *ngIf="!isLoggedIn">
              <a
                href="javascript:void()"
                role="button"
                aria-label="Login button"
                class="page-link--btn"
                (click)="onNavigateTo('login')"
              >
                {{ "general.login" | translate }}
              </a>
            </ng-container>
          </ng-container>

          <a
            role="button"
            href="javascript:void()"
            class="page-link--btn"
            [class.active]="
              activeLeftMenuOption === 'casino'
            "
            (click)="onNavigateTo('casino')"
          >
            {{ "general.casino" | translate }}
          </a>

          <a
            role="button"
            href="javascript:void()"
            class="page-link--btn"
            [class.active]="
              activeLeftMenuOption === 'live-casino'
            "
            (click)="onNavigateTo('live-casino')"
          >
            {{ "general.live_casino" | translate }}
          </a>

          <ng-container *ngIf="isLoggedIn">
            <a
              role="button"
              href="javascript:void()"
              class="page-link--btn page-rewards"
              [class.active]="
                activeLeftMenuOption === 'rewards'
              "
              (click)="onOpenAccountComponent('rewards')"
            >
              {{ "rewards.rewards" | translate }}

              <ng-container
                *marketBasedComponentDisplay="{
                  flowType: UserFlowTypes.ZIMPLER_FLOW,
                  langCode: languageCode
                }"
              >
                <ng-container
                  *ngIf="levelUpNotificationCount > 0"
                >
                  <span class="levelup-notify">
                    {{ levelUpNotificationCount }}
                  </span>
                </ng-container>
              </ng-container>
            </a>
          </ng-container>

          <a
            role="button"
            href="javascript:void()"
            class="page-link--btn"
            [class.active]="
              activeLeftMenuOption === 'promotions'
            "
            (click)="onGoToPromotion()"
          >
            {{ "promotion.promotion" | translate }}
          </a>

          <ng-container *ngIf="countryCode === 'CA'">
            <a
              role="button"
              href="javascript:void()"
              class="page-link--btn"
              [class.active]="
                activeLeftMenuOption === 'payments'
              "
              (click)="onGoToPayments()"
            >
              {{ "common.payment_methods" | translate }}
            </a>
          </ng-container>

          <a
            role="button"
            class="page-link--btn"
            (click)="onNavigateTo('livespins')"
            [class.active]="
              activeLeftMenuOption === 'livespins'
            "
          >
            Livespins
          </a>

          <ng-container *ngIf="!isLoggedIn">
            <a
              role="button"
              href="javascript:void()"
              class="page-link--btn"
              (click)="onInitializeLiveChat()"
            >
              {{ "general.chat_with_us" | translate }}
            </a>
          </ng-container>
        </div>
      </ng-container>

      <ng-container *ngIf="isLoggedIn">
        <div
          class="my-account m-b-16"
          aria-label="Account overview"
          *marketBasedComponentDisplay="{
            flowType: UserFlowTypes.NORMAL_FLOW,
            langCode: languageCode
          }"
        >
          <p class="my-account--balance bal-text">
            {{ "general.total_balance" | translate }}
          </p>

          <p
            class="my-account--balance bal-amount"
            [class.small-amt]="
              (
                userWithdrawableBalance + userBonusBalance
              ).toString().length >= 7
            "
          >
            {{
              userWithdrawableBalance + userBonusBalance
                | currencyformat: currencySymbol
            }}
          </p>

          <button
            class="btn btn-primary btn-md btn-round m-t-16 my-account--deposit-btn btn-size"
            aria-label="Deposit"
            (click)="onInitiateDeposit()"
          >
            {{ "general.deposit_now" | translate }}
          </button>

          <img
            class="paymethods--img"
            src="{{ mediaUrlPath }}/left-menu/p-logo_{{
              languageCode
            }}_hra.png{{ imgixParams }}"
            alt="payment methods"
          />
        </div>

        <app-pending-withdraw
          class="clearfix"
          *marketBasedComponentDisplay="{
            flowType: UserFlowTypes.NORMAL_FLOW,
            langCode: languageCode
          }"
        ></app-pending-withdraw>

        <nav class="my-account__links-wrapper">
          <ng-container *ngIf="this.windowType != 'mobile'">
            <a
              *marketBasedComponentDisplay="{
                flowType: UserFlowTypes.ZIMPLER_FLOW,
                langCode: languageCode
              }"
              role="button"
              class="my-account--link"
              [class.isdisabled]="
                userWithdrawableBalance <= 0
              "
              (click)="onOpenWithdraw()"
            >
              <div class="my-account--link-inside">
                <span class="icon icon-wallet"></span>

                <span class="txt">
                  <h5>
                    {{ "general.withdraw" | translate }}
                  </h5>
                </span>
              </div>
            </a>
          </ng-container>

          <a
            *marketBasedComponentDisplay="{
              flowType: UserFlowTypes.NORMAL_FLOW,
              langCode: languageCode
            }"
            role="button"
            class="my-account--link"
            (click)="onOpenAccountComponent('withdrawal')"
          >
            <div class="my-account--link-inside">
              <span class="icon icon-wallet"></span>
              <span class="txt">
                <h5>
                  {{ "general.withdraw" | translate }}
                </h5>

                <caption>
                  {{
                    userWithdrawableBalance
                      | currencyformat: currencySymbol
                  }}
                </caption>
              </span>
            </div>
          </a>

          <a
            role="button"
            class="my-account--link"
            (click)="onOpenAccountComponent('bonus')"
          >
            <div class="my-account--link-inside">
              <span class="icon icon-smiley"></span>

              <span class="txt">
                <h5>{{ "general.bonuses" | translate }}</h5>
                <caption>
                  {{
                    userBonusBalance
                      | currencyformat: currencySymbol
                  }}
                </caption>
              </span>
            </div>
          </a>

          <a
            role="button"
            class="my-account--link"
            (click)="onInitializeLiveChat()"
          >
            <div class="my-account--link-inside">
              <span class="icon icon-chat"></span>
              <span class="txt">
                <h5>
                  {{ "general.chat_with_us" | translate }}
                </h5>

                <caption>
                  {{
                    "general.24by7" | translate
                  }}
                </caption>
              </span>
            </div>
          </a>

          <a
            *marketBasedComponentDisplay="{
              flowType: UserFlowTypes.NORMAL_FLOW,
              langCode: languageCode
            }"
            role="button"
            class="my-account--link"
            (click)="
              onOpenAccountComponent('update-profile')
            "
          >
            <div class="my-account--link-inside">
              <span class="icon icon-user"></span>
              <span class="txt">
                <h5>
                  {{ "general.my_Account" | translate }}
                </h5>
                <caption>
                  {{
                    "general.details" | translate
                  }}
                </caption>
              </span>
            </div>
          </a>

          <a
            role="button"
            class="my-account--link"
            (click)="onOpenAccountComponent('bet-history')"
          >
            <div class="my-account--link-inside">
              <span class="icon icon-777"></span>
              <span class="txt">
                <h5>{{ "general.game" | translate }}</h5>
                <caption>
                  {{
                    "general.history" | translate
                  }}
                </caption>
              </span>
            </div>
          </a>

          <a
            role="button"
            class="my-account--link"
            (click)="
              onOpenAccountComponent('my-transacts-history')
            "
          >
            <div class="my-account--link-inside">
              <span class="icon icon-credit-card"></span>
              <span class="txt">
                <h5>
                  {{ "general.transactions" | translate }}
                </h5>
                <caption>
                  {{
                    "general.history" | translate
                  }}
                </caption>
              </span>
            </div>
          </a>

          <a
            role="button"
            class="my-account--link"
            (click)="onOpenAccountComponent('time-out')"
          >
            <div class="my-account--link-inside">
              <span class="icon icon-limits"></span>
              <span class="txt">
                <h5>
                  {{ "limits.self_exclusion" | translate }}
                </h5>
                <caption>
                  {{
                    "general.play_safe" | translate
                  }}
                </caption>
              </span>
            </div>
          </a>

          <a
            *marketBasedComponentDisplay="{
              flowType: UserFlowTypes.NORMAL_FLOW,
              langCode: languageCode
            }"
            role="button"
            class="my-account--link"
            (click)="
              onOpenAccountComponent('change-password')
            "
          >
            <div class="my-account--link-inside">
              <span class="icon icon-lock"></span>
              <span class="txt">
                <h5>
                  {{ "general.password" | translate }}
                </h5>
                <caption>
                  {{
                    "general.update" | translate
                  }}
                </caption>
              </span>
            </div>
          </a>

          <a
            role="button"
            class="my-account--link"
            (click)="onOpenAccountComponent('preferences')"
          >
            <div class="my-account--link-inside">
              <span class="icon icon-info"></span>
              <span class="txt">
                <h5>
                  {{ "general.preferences" | translate }}
                </h5>
                <caption>
                  {{
                    "general.communication" | translate
                  }}
                </caption>
              </span>
            </div>
          </a>

          <a
            role="button"
            class="my-account--link"
            [class.isdisabled]="!isKycEnabled"
            (click)="
              onOpenAccountComponent('confirmidentity')
            "
          >
            <div class="my-account--link-inside">
              <span class="icon icon-doc"></span>
              <span class="txt">
                <h5>
                  {{ "general.document" | translate }}
                </h5>
                <caption>
                  {{
                    "general.verification" | translate
                  }}
                </caption>
              </span>
            </div>
          </a>
        </nav>

        <app-pending-withdraw
          class="clearfix pnp-withdraw"
          *marketBasedComponentDisplay="{
            flowType: UserFlowTypes.ZIMPLER_FLOW,
            langCode: languageCode
          }"
        ></app-pending-withdraw>

        <button
          *ngIf="isLoggedIn"
          aria-label="Logout"
          class="btn btn-sm btn-secondary btn-round logoutBtn-classic logout-btn btn-center"
          (click)="onLogout()"
        >
          {{ "common.logout" | translate }}
        </button>
      </ng-container>

      <ng-container
        *marketBasedComponentDisplay="{
          flowType: UserFlowTypes.NORMAL_FLOW,
          langCode: languageCode
        }"
      >
        <button
          aria-label="Login"
          class="btn btn-primary btn-md btn-center btn-round btn-size"
          (click)="onNavigateTo('login')"
          *ngIf="!isLoggedIn"
        >
          {{ "general.login" | translate }}
        </button>
      </ng-container>
    </div>

    <ng-container showFooter>
      <ng-container
        *marketBasedComponentDisplay="{
          flowType: UserFlowTypes.ZIMPLER_FLOW,
          langCode: languageCode
        }"
      >
        <button
          *ngIf="isLoggedIn && this.windowType != 'mobile'"
          aria-label="Logout"
          class="btn btn-sm btn-round btn-logout-pnp btn-center logoutBtn-pnp"
          (click)="onLogout()"
        >
          {{ "common.logout" | translate }}
        </button>
      </ng-container>

      <app-time
        *ngIf="this.windowType === 'mobile'"
        class="app-time"
      ></app-time>
    </ng-container>
  </app-side-menu-modal>
</ng-container>

<section
  [@modalInOut]
  class="sideMenu__backdrop"
  *ngIf="activeView != 'menuOptions' && activeView != ''"
  (click)="onCloseAccountOverlay()"
></section>

<ng-container
  *ngTemplateOutlet="menuItemsTemplate"
></ng-container>

<!-- 
  ------------------------------------------------------
  
  Templates 
  
  ------------------------------------------------------
-->
<!-- 
  The following shows the active Tab Template 

  When the player clicks on the menu item
-->
<ng-template #menuItemsTemplate>
  <ng-container [ngSwitch]="activeView">
    <ng-container *ngSwitchCase="'bet-history'">
      <app-bet-history></app-bet-history>
    </ng-container>

    <ng-container *ngSwitchCase="'my-transacts-history'">
      <app-transaction-history></app-transaction-history>
    </ng-container>

    <ng-container *ngSwitchCase="'preferences'">
      <app-preferences></app-preferences>
    </ng-container>

    <ng-container *ngSwitchCase="'limits'">
      <app-limits></app-limits>
    </ng-container>

    <ng-container *ngSwitchCase="'bonus'">
      <app-bonus></app-bonus>
    </ng-container>

    <ng-container *ngSwitchCase="'login'">
      <app-login-form></app-login-form>
    </ng-container>

    <ng-container *ngSwitchCase="'forgot-password'">
      <app-forgot-password></app-forgot-password>
    </ng-container>

    <ng-container *ngSwitchCase="'change-password'">
      <app-change-password></app-change-password>
    </ng-container>

    <ng-container *ngSwitchCase="'update-profile'">
      <app-update-profile></app-update-profile>
    </ng-container>

    <ng-container *ngSwitchCase="'confirmidentity'">
      <app-confirm-identity-kyc></app-confirm-identity-kyc>
    </ng-container>

    <ng-container *ngSwitchCase="'reward-congratulations'">
      <app-reward-congrats></app-reward-congrats>
    </ng-container>

    <ng-container *ngSwitchCase="'rewards'">
      <app-all-rewards></app-all-rewards>
    </ng-container>

    <ng-container *ngSwitchCase="'deposit'">
      <app-hosted-cashier
        [cashierType]="activeView"
        [cashierStep]="cashierStep"
        [isShowBackButton]="isShowBackButton"
      ></app-hosted-cashier>
    </ng-container>

    <ng-container *ngSwitchCase="'withdrawal'">
      <app-hosted-cashier
        [cashierType]="activeView"
        [cashierStep]="cashierStep"
      ></app-hosted-cashier>
    </ng-container>

    <ng-container *ngSwitchCase="'time-out'">
      <app-self-exclusion></app-self-exclusion>
    </ng-container>
  </ng-container>

  <ng-container
    *marketBasedComponentDisplay="{
      flowType: UserFlowTypes.ZIMPLER_FLOW,
      langCode: languageCode
    }"
  ></ng-container>
</ng-template>
