export const onCheckinOtp = (languageCode: string): void => {
  switch (languageCode) {
    case "en-ca":
    case "en-nz": {
      document.documentElement.dataset.regilyOtp = "false";
      break;
    }
    default: {
      document.documentElement.dataset.regilyOtp = "true";
      break;
    }
  }
};
