import { throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
// API Interactors
import { ApiInteractors } from "src/app/models/interactors/api.interactor";
// Enums
import { StatusResponse } from "src/app/models/api/status.response";
import { Project } from "src/app/models/environments/project.enum";
// Selectors
import { selectAuthLoginIsLoggedOut } from "src/app/modules/auth/store/selectors/auth.selectors";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@ngrx/store";
export class KYCService {
    constructor(httpClient, store) {
        this.httpClient = httpClient;
        this.store = store;
        // Others
        this.kycDetailsToUnblock = {
            address: false,
            identity: false,
            paymentInstrument: false,
            sourceOfIncome: false,
            enableKyc: false,
        };
        // Subscriptions
        this.subscriptions = [];
        this.apiInteractor = new ApiInteractors(this.httpClient);
        this.subscriptions = [
            this.store
                .select(selectAuthLoginIsLoggedOut)
                .subscribe((isLoggedOut) => {
                if (isLoggedOut) {
                    this.kycDetailsToUnblock = {
                        address: false,
                        identity: false,
                        paymentInstrument: false,
                        sourceOfIncome: false,
                        enableKyc: false,
                    };
                }
            }),
        ];
    }
    // -----------------------------------------------------------------
    // Get Methods - Observable
    onUploadDocuments(uploadDocumentsRequest) {
        let formData = new FormData();
        let type = uploadDocumentsRequest.type;
        delete uploadDocumentsRequest.type;
        formData.append("file", uploadDocumentsRequest.file);
        const url = `/ajax/account/Documents/upload?file=${type}&accountId=`;
        return this.apiInteractor
            .upload(url, formData)
            .pipe(map((uploadDocumentsResponse) => {
            return uploadDocumentsResponse;
        }), catchError((error) => {
            return throwError(error);
        }));
    }
    onSendDocuments(sendDocumentRequest) {
        return this.onGetSendDocuments(sendDocumentRequest).pipe(map((uploadDocumentsResponse) => {
            return uploadDocumentsResponse;
        }), catchError((error) => {
            return throwError(error);
        }));
    }
    onGetSendDocuments(sendDocumentRequest) {
        let url = `/ajax/account/Documents/send`;
        if (!sendDocumentRequest) {
            return this.apiInteractor.post(url, null, Project.Shotz);
        }
        else {
            return this.apiInteractor.post(url, sendDocumentRequest, Project.Shotz);
        }
    }
    onGetUserKycLevelDetails() {
        return this.apiInteractor
            .get(`/ajax/profile/getUserKYCDetails`, null, Project.Shotz)
            .pipe(map((kycUserDetailsResponse) => {
            if (kycUserDetailsResponse &&
                kycUserDetailsResponse.status === StatusResponse.SUCCESS) {
                let requiredDocumentsList = kycUserDetailsResponse.requiredDocs;
                if (requiredDocumentsList && requiredDocumentsList.length > 0) {
                    requiredDocumentsList.filter((documentName) => {
                        if (kycUserDetailsResponse.kycDetails[documentName]) {
                            this.kycDetailsToUnblock[documentName] = "vrfn_new";
                            this.kycDetailsToUnblock.enableKyc = true;
                        }
                    });
                }
            }
            return this.kycDetailsToUnblock;
        }), catchError((error) => {
            return throwError(error);
        }));
    }
    // -----------------------------------------------------------------
    // On Destroy
    ngOnDestroy() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
}
KYCService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function KYCService_Factory() { return new KYCService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.Store)); }, token: KYCService, providedIn: "root" });
