/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./side-menu-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "./side-menu-modal.component";
import * as i5 from "../../../multi-languages/services/multi-language.service";
import * as i6 from "../../../shared/services/utility.service";
import * as i7 from "@ngrx/store";
import * as i8 from "@angular/router";
var styles_SideMenuModalComponent = [i0.styles];
var RenderType_SideMenuModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SideMenuModalComponent, data: {} });
export { RenderType_SideMenuModalComponent as RenderType_SideMenuModalComponent };
function View_SideMenuModalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "button", [["aria-label", "Close"], ["class", "sideMenu__action-btn float-left"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onCloseComponent("menuOptions") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["class", "icon-chevron-left"]], null, null, null, null, null))], null, null); }
function View_SideMenuModalComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "sideMenu__header--title"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.sideMenuTitle)); _ck(_v, 2, 0, currVal_0); }); }
function View_SideMenuModalComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "sideMenu__header--title"]], null, null, null, null, null)), i1.ɵncd(null, 0)], null, null); }
function View_SideMenuModalComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "sideMenu__footer"]], null, null, null, null, null)), i1.ɵncd(null, 2)], null, null); }
export function View_SideMenuModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "aside", [["class", "sideMenu"]], null, null, null, null, null)), i1.ɵprd(512, null, i3.ɵNgClassImpl, i3.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i3.NgClass, [i3.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 8, "div", [["class", "sideMenu__header"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SideMenuModalComponent_1)), i1.ɵdid(5, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 1, "button", [["aria-label", "Close"], ["class", "sideMenu__action-btn float-right"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onCloseComponent() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "i", [["class", "icon-close"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SideMenuModalComponent_2)), i1.ɵdid(9, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SideMenuModalComponent_3)), i1.ɵdid(11, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(12, 0, null, null, 1, "div", [["class", "sideMenu__body"]], null, null, null, null, null)), i1.ɵncd(null, 1), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SideMenuModalComponent_4)), i1.ɵdid(15, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "sideMenu"; var currVal_1 = _co.customClass; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = (_co.showBackBtn && _co.isShowBackButton); _ck(_v, 5, 0, currVal_2); var currVal_3 = !_co.show2LineHeaderTitle; _ck(_v, 9, 0, currVal_3); var currVal_4 = _co.show2LineHeaderTitle; _ck(_v, 11, 0, currVal_4); var currVal_5 = _co.showFooter; _ck(_v, 15, 0, currVal_5); }, null); }
export function View_SideMenuModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-side-menu-modal", [], null, null, null, View_SideMenuModalComponent_0, RenderType_SideMenuModalComponent)), i1.ɵdid(1, 770048, null, 0, i4.SideMenuModalComponent, [i5.MultiLanguageService, i6.UtilityService, i7.Store, i8.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SideMenuModalComponentNgFactory = i1.ɵccf("app-side-menu-modal", i4.SideMenuModalComponent, View_SideMenuModalComponent_Host_0, { show2LineHeaderTitle: "show2LineHeaderTitle", isShowBackButton: "isShowBackButton", showBackBtn: "showBackBtn", showFooter: "showFooter", sideMenuTitle: "sideMenuTitle", customClass: "customClass", callingFrom: "callingFrom" }, { callBackButton: "callBackButton" }, ["[show2LineHeaderTitle]", "[sideMenuBody]", "[showFooter]"]);
export { SideMenuModalComponentNgFactory as SideMenuModalComponentNgFactory };
