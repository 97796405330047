/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./terms-and-conditions.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "./terms-and-conditions.component";
import * as i5 from "../../../multi-languages/services/multi-language.service";
import * as i6 from "../../services/utility.service";
import * as i7 from "../../../auth/services/session.service";
import * as i8 from "../../services/policy.service";
import * as i9 from "@angular/router";
var styles_TermsAndConditionsComponent = [i0.styles];
var RenderType_TermsAndConditionsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TermsAndConditionsComponent, data: {} });
export { RenderType_TermsAndConditionsComponent as RenderType_TermsAndConditionsComponent };
function View_TermsAndConditionsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "section", [["class", "c-dialog__backdrop"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 9, "div", [["class", "c-dialog"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "c-dialog__header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "img", [["height", "100%"], ["src", "/assets/img/logo.svg"], ["width", "100%"]], [[8, "alt", 0]], null, null, null, null)), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "c-dialog__body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Dear user, please be informed that our terms and conditions have been updated. In order to continue using our services, please review the changes and accept the updated terms and conditions. "])), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["class", "c-dialog__footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "button", [["class", "btn-yes"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onAcceptTermsAndConditions() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Review & Accept "]))], null, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("general.site_name")), ""); _ck(_v, 3, 0, currVal_0); }); }
export function View_TermsAndConditionsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_TermsAndConditionsComponent_1)), i1.ɵdid(1, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isTermsAndConditionsPopup; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_TermsAndConditionsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-terms-and-conditions", [], null, null, null, View_TermsAndConditionsComponent_0, RenderType_TermsAndConditionsComponent)), i1.ɵdid(1, 245760, null, 0, i4.TermsAndConditionsComponent, [i5.MultiLanguageService, i2.TranslateService, i6.UtilityService, i7.SessionService, i8.PolicyService, i9.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TermsAndConditionsComponentNgFactory = i1.ɵccf("app-terms-and-conditions", i4.TermsAndConditionsComponent, View_TermsAndConditionsComponent_Host_0, {}, {}, []);
export { TermsAndConditionsComponentNgFactory as TermsAndConditionsComponentNgFactory };
