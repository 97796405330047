<div
  class="lastPlayed lastPlayed__container"
  *ngIf="lastPlayedLiveGamesList && lastPlayedLiveGamesList.length > 0"
>
  <h4 class="lastPlayed--title">{{'livecasino.play_again'| translate}}</h4>
  <div
    class="lastPlayed--viewall-link"
    (click)="onViewAllHandler()"
    *ngIf="lastPlayedLiveGamesList.length >= 4"
  >
    {{'livecasino.view_all' | translate}}
  </div>
  <div class="lastPlayed__cards-wrapper">
    <div
      class="lastPlayed__card"
      *ngFor="let game of lastPlayedLiveGamesList | slice:0:4"
      (click)="onGameLaunch(game.name)"
    >
      <div class="lastPlayed__card--img">
        <img
          src="{{gamesUrlPath}}/{{game.gameCode}}/{{game.gameCode}}_default_live_casino.jpg{{imgixParams}}"
          alt="{{game.name}}"
          title="{{game.name}}"
        />
      </div>
      <div class="lastPlayed__card--details">
        <div class="lastPlayed__card--gamename">{{game.name}}</div>
        <div class="lastPlayed__card--gameprovider">
          {{game.vendorDisplayName}}
        </div>
      </div>
    </div>
  </div>
</div>
