import { createReducer, on } from "@ngrx/store";
// Actions
import { bannerLoaded } from "src/app/modules/banner/store/actions/banners.actions";
// Utilities
import { bannerZoneMappersLiveCasinoList, bannerZoneMappersCasinoList, } from "src/app/modules/banner/utilities/banner-zone-mappers.utilities";
export const BannerStateFeatureKey = "banner";
export const initialState = {
    bannerList: {},
    bannerError: null,
};
const ɵ0 = (state, { bannerRequest, banners }) => {
    let zoneId = bannerRequest.zoneId;
    if (bannerZoneMappersCasinoList.includes(zoneId)) {
        zoneId = "b_casino";
    }
    if (bannerZoneMappersLiveCasinoList.includes(zoneId)) {
        zoneId = "b_all-live";
    }
    return Object.assign({}, state, { bannerList: Object.assign({}, state.bannerList, { [`${zoneId}`]: {
                isLoaded: true,
                isLoading: false,
                banners,
            } }), bannerError: null });
};
// Reducer
const BannersReducer = createReducer(initialState, 
// Banners
on(bannerLoaded, ɵ0));
export function reducer(state, action) {
    return BannersReducer(state, action);
}
export { ɵ0 };
