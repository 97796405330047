import {
  EventEmitter,
  SimpleChange,
  ElementRef,
  Component,
  OnChanges,
  ViewChild,
  Output,
  Input,
} from "@angular/core";

// Configurations
import {
  maxSupportedFileSizeInBytesConfigurations,
  incomeSourceListConfigurations,
} from "src/app/configurations/main.configurations";

// Libraries
import * as _ from "underscore";

// Models
import { IncomeSourceConfigurations } from "src/app/models/configurations/general-configurations/income-source-configuration.model";
import { SourceOfIncomeResult } from "src/app/modules/kyc/models/source-of-income-result.model";
import { ResidenceProofData } from "src/app/modules/kyc/models/residence-proof-data.model";
import { UploadResponseData } from "src/app/modules/kyc/models/upload-response-data.model";
import { UploadEvent } from "src/app/modules/kyc/models/upload-event.model";

// Services
import { TranslationService } from "src/app/modules/multi-languages/services/translation.service";

@Component({
  selector: "app-source-of-fund",
  templateUrl: "./source-of-fund.component.html",
  styleUrls: ["./source-of-fund.component.scss"],
})
export class SourceOfFundComponent implements OnChanges {
  // View Child
  @ViewChild("sourceOfFund", { static: false }) sourceOfFund: ElementRef;

  // Outputs
  @Output() uploadProofOfPaymentFiles: EventEmitter<ResidenceProofData> =
    new EventEmitter<ResidenceProofData>();

  // Inputs
  @Input() uploadResponseData: UploadResponseData;
  @Input() isButtonDisabled: boolean = false;

  // Strings
  incomeSelected: string = "";
  sizeExceeded: string = "";

  // Arrays
  sourceOfIncomeList: IncomeSourceConfigurations[] = [];

  // Objects
  selectedSouceOfIncome: IncomeSourceConfigurations;
  sourceOfFundData: ResidenceProofData;

  constructor(private translationService: TranslationService) {
    this.sourceOfIncomeList = incomeSourceListConfigurations;

    this.selectedSouceOfIncome = this.sourceOfIncomeList[0];
  }

  // -----------------------------------------------------------------
  // Lifecycle Hooks
  ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
    if (
      changes["uploadResponseData"] &&
      changes["uploadResponseData"].previousValue !==
        changes["uploadResponseData"].currentValue
    ) {
      this.uploadResponseData = changes["uploadResponseData"].currentValue;

      setTimeout(() => {
        this.uploadResponseData = undefined;
      }, 5000);

      this.onClearField();
    }

    if (
      changes["isButtonDisabled"] &&
      changes["isButtonDisabled"].previousValue !==
        changes["isButtonDisabled"].currentValue
    ) {
      this.isButtonDisabled = changes["isButtonDisabled"].currentValue;
    }
  }

  // -----------------------------------------------------------------
  // Set Methods
  onIncomeChangeHandler(event: Event): void {
    const target: HTMLInputElement = event.target as HTMLInputElement;

    let incomeSourceConfigurations: IncomeSourceConfigurations =
      this.sourceOfIncomeList.find(
        (sourceIncome: IncomeSourceConfigurations) =>
          sourceIncome.value == target.value
      );

    if (incomeSourceConfigurations) {
      this.selectedSouceOfIncome = incomeSourceConfigurations;
    }
  }

  onUpload(event: UploadEvent): void {
    this.sizeExceeded = undefined;

    const target: HTMLInputElement = event.target as HTMLInputElement;

    if (target.files[0].size >= maxSupportedFileSizeInBytesConfigurations) {
      this.sizeExceeded = this.translationService.get(
        "confirmidentity.file_size_alert"
      );

      this.onClearField();
    } else {
      let sourceOfIncomeResult: SourceOfIncomeResult = {};

      sourceOfIncomeResult[this.selectedSouceOfIncome.value] = true;

      this.sourceOfFundData = {
        event: event,
        fileName: target.files[0].name,
        type: this.selectedSouceOfIncome.value,
        sourceOfIncome: JSON.stringify(sourceOfIncomeResult),
      };
    }
  }

  onClearField(callingFrom?: string): void {
    this.sourceOfFundData = undefined;

    if (this.sourceOfFund) {
      this.sourceOfFund.nativeElement.value = "";
    }

    if (callingFrom !== "input-elm") {
      this.selectedSouceOfIncome = this.sourceOfIncomeList[0];
    }

    this.incomeSelected = this.selectedSouceOfIncome.value;

    if (this.sizeExceeded) {
      setTimeout(() => {
        this.sizeExceeded = undefined;
      }, 5000);
    }
  }

  onSubmitSourceOfPayment(): void {
    if (this.sourceOfFundData.event && this.sourceOfFundData.type) {
      /*
        What even value user select from drop down, From front end we are passing value as 'others' for time been as
        functionality for this all types is pending from back end..
        Once backend functionality is done we will passing correct values.
      */
      this.sourceOfFundData.type = "others";

      this.sourceOfFundData.sourceOfIncome = JSON.stringify({
        others: true,
      });

      this.uploadProofOfPaymentFiles.emit(this.sourceOfFundData);
    }
  }
}
