/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./form-radio.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./form-radio.component";
var styles_FormRadioComponent = [i0.styles];
var RenderType_FormRadioComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_FormRadioComponent, data: {} });
export { RenderType_FormRadioComponent as RenderType_FormRadioComponent };
export function View_FormRadioComponent_0(_l) { return i1.ɵvid(0, [], null, null); }
export function View_FormRadioComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-form-radio", [], null, null, null, View_FormRadioComponent_0, RenderType_FormRadioComponent)), i1.ɵdid(1, 49152, null, 0, i2.FormRadioComponent, [], null, null)], null, null); }
var FormRadioComponentNgFactory = i1.ɵccf("app-form-radio", i2.FormRadioComponent, View_FormRadioComponent_Host_0, {}, {}, []);
export { FormRadioComponentNgFactory as FormRadioComponentNgFactory };
