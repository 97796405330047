// Environments
import { environment } from "src/environments/environment";
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
export class TranslationService {
    constructor(translateService) {
        this.translateService = translateService;
        // Strings
        this.languageCode = environment.defaultLanguage;
    }
    // -----------------------------------------------------------------
    // Get Methods
    get(key, interPolateParams) {
        if (interPolateParams) {
            return this.translateService.instant(key, interPolateParams);
        }
        else {
            return this.translateService.instant(key);
        }
    }
    getLanguages() {
        return this.translateService.getLangs();
    }
    // -----------------------------------------------------------------
    // Set Methods
    onAddLanguages(languages) {
        this.translateService.addLangs(languages);
    }
    /*
      Change the Language Currently used
      by passing Langauge Code
    */
    onUseLanguage(languageCode) {
        this.languageCode = languageCode;
        this.translateService.use(languageCode).subscribe();
    }
}
TranslationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TranslationService_Factory() { return new TranslationService(i0.ɵɵinject(i1.TranslateService)); }, token: TranslationService, providedIn: "root" });
