<section class="c-dialog__backdrop" *ngIf="isTermsAndConditionsPopup">
  <div class="c-dialog">
    <div class="c-dialog__header">
      <img
        src="/assets/img/logo.svg"
        alt="{{ 'general.site_name' | translate }}"
        width="100%"
        height="100%"
      />
    </div>
    <div class="c-dialog__body">
      <p>
        Dear user, please be informed that our terms and conditions have been
        updated. In order to continue using our services, please review the
        changes and accept the updated terms and conditions.
      </p>
    </div>
    <div class="c-dialog__footer">
      <button class="btn-yes" (click)="onAcceptTermsAndConditions()">
        Review & Accept
      </button>
    </div>
  </div>
</section>
